<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {transfersRuntimeStore} from '@/utils/store-accessor'

    @Component
    export default class TransferPageMixin extends Vue {
        async loadRuntimeData() {
            const {
                arrivalHotelCode,
                arrivalPlace,
                arrivalCityId,
                departureHotelCode,
                departurePlace,
                departureCityId,
            } = this.searchRequest
            const promises = []
            if (
                (arrivalHotelCode && arrivalHotelCode !== transfersRuntimeStore.arrivalPoint.hotelCode) ||
                (arrivalPlace === 'hotel' && arrivalCityId !== transfersRuntimeStore.arrivalPoint.id) ||
                arrivalPlace !== transfersRuntimeStore.arrivalPoint.iataCode
            ) {
                promises.push(transfersRuntimeStore.loadArrivalPoint(this.searchRequest))
            }
            if (
                (departureHotelCode && departureHotelCode !== transfersRuntimeStore.departurePoint.hotelCode) ||
                (departurePlace === 'hotel' && departureCityId !== transfersRuntimeStore.departurePoint.id) ||
                departurePlace !== transfersRuntimeStore.departurePoint.iataCode
            ) {
                promises.push(transfersRuntimeStore.loadDeparturePoint(this.searchRequest))
            }
            await Promise.all(promises)
        }
    }
</script>
