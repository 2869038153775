<template>
    <v-container fluid class="pa-0 text-center">
        <small v-if="selectedOfferKeys.length === searchRoomsCount && !canBook" class="red--text text-center d-block">
            {{ $t('multi_room_error_message') }}
        </small>
        <v-btn
            :disabled="selectedOfferKeys.length < searchRoomsCount || !canBook"
            dense
            block
            class="primary"
            @click="selectForBooking"
        >
            {{ sumPrice.amount ? $options.filters.price(sumPrice) : $t('book') }}
        </v-btn>
        <hotel-price-note
            v-if="selectedOfferKeys.length === searchRoomsCount"
            :search-request="searchRequest"
            :rooms-count="selectedOfferKeys.length"
            class="text-center"
        />
    </v-container>
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator'
    import {hotelsStore, persistentStore} from '@/store'
    import HotelPriceNote from '@/components/hotels/snippets/HotelPriceNote'

    @Component({
        components: {
            HotelPriceNote,
        },
    })
    export default class HotelMultipleRoomSelectBlock extends Vue {
        @Prop({required: true}) selectedOfferKeys
        @Prop({required: true}) searchRequest
        @Prop({required: true}) canBook
        @Prop({required: true}) offer

        selectForBooking() {
            const offerKey = this.selectedOfferKeys
            hotelsStore.ADD_TO_BASKET(offerKey)
            this.$router.push({
                name: 'hotelBooking',
                query: {offerKey},
            })
        }

        get searchRoomsCount() {
            return this.searchRequest.rooms.length
        }

        get sumPrice() {
            const sumPrice = {amount: 0, currency: persistentStore.currency}
            if (!this.selectedOfferKeys) return sumPrice
            return this.offer.rooms.reduce((sumPrice, room) => {
                if (room.groupedOffers.findIndex(({offerKey}) => this.selectedOfferKeys.includes(offerKey)) !== -1) {
                    sumPrice.amount += this.$options.filters.convertPrice(room.price).amount
                }
                return sumPrice
            }, sumPrice)
        }
    }
</script>
