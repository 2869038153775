<template>
    <service-card :service="service" icon="mdi-lightning-bolt-outline" @showInfo="showInfo">
        <template v-slot:orderInfo>
            <order-info-base
                :service="service"
                :service-image="mainImage"
                :service-check-in="serviceCheckIn"
                :is-package="isPackage"
                :order-id="orderId"
                :object-id="objectId"
                @cancelService="$emit('cancelService', $event)"
                @modifyService="$emit('modifyService', $event)"
            >
                <template v-if="location || activityType" v-slot:additionalInfo>
                    <small v-if="location" class="mb-0">{{ $t('location') }}: {{ location }}</small>
                    <small class="mb-2 activity-type">
                        {{ activityType }}
                    </small>
                </template>
                <div v-if="transport" class="d-flex">
                    <v-icon left small>
                        mdi-bus
                    </v-icon>
                    {{ transport }}
                </div>
                <div v-if="pickupLocation" class="d-flex">
                    <v-icon left small color="accent">
                        mdi-map-marker
                    </v-icon>
                    {{ pickupLocation }}
                </div>
                <div v-if="info.duration" class="d-flex">
                    <v-icon left small>
                        mdi-timer-outline
                    </v-icon>
                    {{ info.duration }} {{ $t('minutes') }}
                </div>
                <div v-if="info.themes && info.themes.length" class="d-flex align-center">
                    <v-icon left small>
                        mdi-star-circle
                    </v-icon>
                    {{ info.themes.join(', ') }}
                </div>
                <div v-if="activityCategory" class="d-flex">
                    <v-icon left small>
                        mdi-star-circle
                    </v-icon>
                    {{ activityCategory }}
                </div>
                <template v-slot:subtitle>
                    <div class="text-subtitle-1 font-weight-bold black--text">
                        {{ activityServiceName }}
                    </div>
                </template>
            </order-info-base>
        </template>
        <template v-slot:serviceInfo>
            <v-dialog v-model="modal" width="600" hide-overlay>
                <div class="modal-close-btn-block">
                    <v-icon class="v-card-close" @click="modal = false">
                        mdi-close
                    </v-icon>
                </div>
                <service-info-base :service="service" :info="info" :image="mainImage">
                    <template v-if="info" v-slot:serviceInfo>
                        <div v-if="language">
                            <strong>{{ $t('language') }}: {{ language }}</strong>
                        </div>
                        <div v-if="info.shortDescription" class="d-flex align-center mt-2">
                            {{ info.shortDescription }}
                        </div>
                        <template v-if="info.descriptions && info.descriptions.length">
                            <div v-for="(desc, index) in info.descriptions" :key="index">
                                {{ desc }}
                            </div>
                        </template>
                        <template v-if="info.comments && info.comments.length">
                            <div v-for="(comm, index) in info.comments" :key="index">
                                {{ comm }}
                            </div>
                        </template>
                    </template>
                </service-info-base>
            </v-dialog>
        </template>
    </service-card>
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator'
    import ServiceInfoBase from '~/components/account/orderServices/ServiceInfoBase'
    import ServiceCard from '@/components/account/orderServices/ServiceCard'

    @Component({
        components: {
            ServiceCard,
            OrderInfoBase: () => import('~src/components/account/orderServices/orderInfoBase.src'),
            ServiceInfoBase,
        },
    })
    export default class ActivityService extends Vue {
        @Prop({required: true}) service
        @Prop({default: false}) isPackage
        @Prop({default: null}) orderId
        @Prop({default: null}) objectId
        @Prop() itineraryService

        activityInfo = null
        modal = false

        showInfo() {
            this.modal = true
        }

        mounted() {
            this.getAdditionalInfo()
        }

        async getAdditionalInfo() {
            this.activityInfo = await this.getActivityInfo()
        }

        async getActivityInfo() {
            const processId = this.service.processId.replace(/^.{6}/, '')
            return await this.$api.activityInfo.get({processId})
        }

        get serviceCheckIn() {
            const time = this.service.serviceDetails[0].pickupTime
            const date = this.$options.filters.dateFormat(this.service.startDateTime)
            return time
                ? `<strong class="black--text">${date}</strong> <small>${this.$t('time_from')} ${time}</small>`
                : `<strong class="black--text">${date}</strong>`
        }

        get mainImage() {
            if (this.activityInfo) {
                if (this.activityInfo.images && this.activityInfo.images.length) {
                    let image = this.activityInfo.images.find(el => el.mainImage)
                    if (!image) {
                        image = this.activityInfo.images[0]
                    }
                    return image.url
                }
                return null
            }
            return null
        }

        get info() {
            return {...this.activityInfo, ...{mainImage: this.mainImage}}
        }

        get activityType() {
            return this.$t(`activities.type.${this.service.serviceDetails[0].type.toLowerCase()}`)
        }

        get activityCategory() {
            if (this.service.serviceDetails[0].activityCategories.length) {
                return this.service.serviceDetails[0].activityCategories[0].name
            }

            return null
        }

        get location() {
            if (this.service.serviceDetails[0].location) {
                return this.service.serviceDetails[0].location
            }

            return null
        }

        get activityServiceName() {
            if (this.service.serviceDetails[0].activityServiceName) {
                return this.service.serviceDetails[0].activityServiceName
            }

            return null
        }

        get transport() {
            if (this.service.serviceDetails[0].transport) {
                return this.service.serviceDetails[0].transport
            }

            return null
        }

        get pickupLocation() {
            if (this.service.serviceDetails[0].pickupLocation) {
                return this.service.serviceDetails[0].pickupLocation.name
            }

            return null
        }

        get language() {
            return this.service.serviceDetails[0].language
        }
    }
</script>

<style lang="scss" scoped>
    .thumb {
        max-width: 350px;
    }

    .col-title {
        white-space: nowrap;
        max-width: fit-content;
    }

    .payment-status {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        min-width: fit-content;
        max-width: fit-content;
    }

    .activity-type {
        text-transform: uppercase;
    }

    ::v-deep {
        .v-dialog {
            background: #fff;
            padding: 20px;
        }
    }

    .modal-close-btn-block {
        position: relative;
        top: -15px;
        right: -15px;
    }
</style>
