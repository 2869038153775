<template>
    <search-summary-layout :search-disabled="searchDisabled" :form-name="searchFormComponent">
        <template v-slot:title>
            {{ locationLabel }}
        </template>
        <template v-slot:content>
            <div>
                {{ startDate | dateFormat }} - {{ endDate | dateFormat }} ({{ nights }} {{ $tc('night', nights) }})
            </div>
            <div>
                <span v-if="rooms > 1">{{ rooms }} {{ $tc('room', rooms) }}</span> {{ touristsTotal }}
                {{ $tc('guest', touristsTotal) }}
            </div>
        </template>
    </search-summary-layout>
</template>

<script>
    import {Vue, Component, Watch, Prop} from 'vue-property-decorator'
    import {hotelsRuntimeStore} from '~/store'
    import HotelSearchForm from '~/components/search/forms/HotelSearchForm'
    import SearchSummaryLayout from '~/components/parts/SearchSummaryLayout'

    //TODO Hide if empty searchRequest
    @Component({
        components: {
            HotelSearchForm,
            SearchSummaryLayout,
        },
    })
    export default class HotelSearchSummary extends Vue {
        @Prop({default: false, type: Boolean}) searchDisabled
        @Prop({required: true}) searchRequest

        searchForm = false

        get searchFormComponent() {
            return 'hotel-search-form'
        }

        get isSearchActive() {
            return hotelsRuntimeStore.searchActive
        }

        get locationLabel() {
            return `${hotelsRuntimeStore.city.name}, ${hotelsRuntimeStore.city.countryName}`
        }

        get startDate() {
            return this.searchRequest.startDate
        }

        get endDate() {
            return this.searchRequest.endDate
        }

        get nights() {
            return this.$dateFns.differenceInDays(
                this.$dateFns.parseISO(this.searchRequest.endDate),
                this.$dateFns.parseISO(this.searchRequest.startDate)
            )
        }

        get rooms() {
            return this.searchRequest.rooms.length
        }

        get touristsTotal() {
            return hotelsRuntimeStore.searchRequestTouristsTotal(this.searchRequest)
        }

        @Watch('isSearchActive')
        onLoadingChange(searchActive) {
            if (searchActive) {
                this.searchForm = false
            }
        }
    }
</script>
