<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {activitiesRuntimeStore, activitiesStore} from '@/store'

    @Component
    export default class ActivityStoreMixin extends Vue {
        get productStore() {
            return activitiesStore
        }

        get productRuntimeStore() {
            return activitiesRuntimeStore
        }

        get productType() {
            return 'activity'
        }
    }
</script>
