<template>
    <div>
        <v-card v-if="info.checkIn || info.checkOut" class="mb-5">
            <v-list dense>
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-clock</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        <v-row>
                            <v-col v-if="info.checkIn">
                                {{ $t('check_in') }}: <strong>{{ info.checkIn }}</strong>
                            </v-col>
                            <v-col v-if="info.checkOut">
                                {{ $t('check_out') }}: <strong>{{ info.checkOut }}</strong>
                            </v-col>
                        </v-row>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-card>
    </div>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'
    import {hotelsRuntimeStore} from '~/store'

    @Component
    export default class HotelInfoTime extends Vue {
        get info() {
            return hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo
        }
    }
</script>

<style scoped></style>
