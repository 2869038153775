<template>
    <div>
        <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="refreshExpiredOffers" />
        <mobile-info-page-tabs v-if="$breakpoint.smAndDown" v-model="mobileTabs" mobile-tab1-title="search" />
        <v-container fluid>
            <tour-breadcrumbs v-if="!$breakpoint.smAndDown" />
            <v-row>
                <v-col v-show="!$breakpoint.smAndDown || ($breakpoint.smAndDown && mobileTabs === 0)" md="8">
                    <v-btn
                        v-if="(offer.price || searchActive) && !$breakpoint.smAndDown"
                        class="float-right"
                        color="primary"
                        small
                        :loading="searchActive"
                        :disabled="searchActive"
                        @click="selectForBooking"
                    >
                        {{ offer.price ? offer.price : {amount: null, currency: null} | price }}
                    </v-btn>
                    <itinerary-info :info="info">
                        <template v-slot:offerInfo>
                            <template v-if="Object.keys(offer.info).length">
                                <h6 class="text-h6">
                                    {{ $t('general_info') }}
                                </h6>
                                <tour-general-info class="mb-5" :info="offer.info" />
                            </template>
                            <v-skeleton-loader v-else-if="searchActive" type="paragraph" />
                            <div v-else-if="offersLoaded" class=" text-center text-subtitle-1 red lighten-3">
                                <span class="red--text text--darken-3">{{ $t('tours.no_tours_message') }}</span>
                            </div>
                        </template>
                    </itinerary-info>
                    <h6 class="text-h6">
                        {{ $t('details') }}
                    </h6>
                    <v-divider />
                    <v-list>
                        <template v-for="(day, dayIndex) in info.itineraryDays">
                            <v-list-item :key="dayIndex">
                                <v-list-item-icon v-if="!$breakpoint.smAndDown" class="text-capitalize">
                                    <v-icon>mdi-weather-sunny</v-icon>
                                    {{ $tc('day', 1) }} {{ day.number }}
                                </v-list-item-icon>
                                <v-list-item-avatar
                                    v-if="!$breakpoint.smAndDown"
                                    tile
                                    size="125"
                                    class="align-self-start mt-3 d-none d-md-inline-flex"
                                >
                                    <v-img
                                        v-if="info.images"
                                        :src="dayImage(day.number).url | imageUrl(125)"
                                        lazy-src="/placeholder.png"
                                    />
                                </v-list-item-avatar>
                                <v-list-item-content class="ml-md-5">
                                    <v-img
                                        v-if="info.images && $breakpoint.smAndDown"
                                        :src="dayImage(day.number).url | imageUrl(500)"
                                        lazy-src="/placeholder.png"
                                        max-height="125"
                                        class="mb-2"
                                    >
                                        <v-row
                                            align="end"
                                            class="lightbox white--text px-2 fill-height text-capitalize"
                                        >
                                            <v-col>
                                                <div class="subheading">
                                                    <v-icon color="white">
                                                        mdi-weather-sunny
                                                    </v-icon>
                                                    {{ $tc('day', 1) }} {{ day.number }}
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-img>
                                    <div class="text-body-2" v-html="day.description" />
                                    <v-list dense>
                                        <v-list-item
                                            v-for="(dayOffer, dayOfferIndex) in dayOffers[dayIndex]"
                                            :key="`${dayIndex}_${dayOfferIndex}`"
                                            class="px-0"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title class="text-uppercase">
                                                    {{ $t(`serviceType.${dayOffer.type}`) }}
                                                </v-list-item-title>
                                                <v-list-item-title
                                                    v-if="dayOffer.offerName"
                                                    v-text="dayOffer.offerName"
                                                />
                                                <v-list-item-subtitle
                                                    v-if="dayOffer.roomName"
                                                    v-text="dayOffer.roomName"
                                                />
                                                <v-skeleton-loader
                                                    v-if="searchActive"
                                                    :type="dayOffer.type === 'ACCOMMODATION' ? 'sentences' : 'text'"
                                                    max-width="50%"
                                                />
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider
                                v-if="dayIndex < info.itineraryDays.length - 1"
                                :key="`${dayIndex}_divider`"
                                :inset="!$breakpoint.smAndDown"
                                class="mb-2"
                            />
                        </template>
                    </v-list>
                    <v-btn
                        v-if="$breakpoint.smAndDown && (offer.price || searchActive)"
                        block
                        color="primary"
                        class="sticky"
                        :loading="searchActive"
                        :disabled="searchActive"
                        @click="selectForBooking"
                    >
                        {{ offer.price ? offer.price : {amount: null, currency: null} | price }}
                    </v-btn>
                </v-col>
                <v-col v-show="!$breakpoint.smAndDown || ($breakpoint.smAndDown && mobileTabs === 1)" md="4">
                    <tour-search-summary :search-request="searchRequest" />
                    <tour-datepicker class="mb-5" />
                    <tour-map ref="map" />
                </v-col>
            </v-row>
        </v-container>
        <conditions-modal />
    </div>
</template>

<script>
    import {Component, mixins, Watch} from 'nuxt-property-decorator'
    import TourStoreMixin from '@/mixins/TourStoreMixin'
    import TourBreadcrumbs from '@/components/tours/TourBreadcrumbs'
    import ConditionsModal from '@/components/modals/ConditionsModal'
    import MobileInfoPageTabs from '@/components/snippets/MobileInfoPageTabs'
    import {persistentStore, toursRuntimeStore, toursStore} from '@/utils/store-accessor'
    import {searchRequest} from '@/utils/tours/tours-blank-states'
    import TourSearchSummary from '@/components/tours/search/TourSearchSummary'
    import {isEqual} from '@/utils/helpers'
    import TourGeneralInfo from '@/components/tours/snippets/TourGeneralInfo'
    import TourMap from '@/components/tours/info/TourMap'
    import TourDatepicker from '@/components/tours/search/forms/TourDatepicker'
    import TourPageMixin from '@/components/tours/mixins/TourPageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import NuxtPageMixin from '@/mixins/NuxtPageMixin'
    import ItineraryInfo from '@/components/info/ItineraryInfo'

    @Component({
        components: {
            ItineraryInfo,
            ExpiredOffersSnackbar,
            TourDatepicker,
            TourMap,
            TourGeneralInfo,
            TourSearchSummary,
            MobileInfoPageTabs,
            ConditionsModal,
            TourBreadcrumbs,
        },
        layout: 'blank',
    })
    export default class TourPage extends mixins(TourStoreMixin, TourPageMixin, NuxtPageMixin) {
        mobileTabs = 0
        offersLoaded = false

        @Watch('$route.query')
        onQueryChange() {
            toursRuntimeStore.search(this.searchRequest)
            if (this.$breakpoint.smAndDown) {
                this.mobileTabs = 0
            }
        }

        validate({query}) {
            return query.packageTourId
        }

        async fetch() {
            await this.loadRuntimeData()
        }

        async mounted() {
            await this.fetchStatePending
            this.$refs.map.initMarkers()
            await this.loadOffers()
        }

        async loadOffers() {
            await this.$store.restored
            if (
                !isEqual(toursStore.searchRequest, this.searchRequest) ||
                this.isOffersExpired() ||
                !toursStore.hasOffers
            ) {
                await toursRuntimeStore.search(this.searchRequest)
            }
            this.offersLoaded = true
        }

        async refreshExpiredOffers() {
            await toursRuntimeStore.search(this.searchRequest)
        }

        dayImage(dayNumber) {
            return this.info.images.find(image => image.dayNumber === dayNumber) || {}
        }

        isOffersExpired() {
            return (
                this.$dateFns.isAfter(new Date(), toursStore.searchExpirationTime) &&
                !toursRuntimeStore.searchActive &&
                toursStore.hasOffers
            )
        }

        selectForBooking() {
            const offerKey = this.offer.offerKey
            toursStore.ADD_TO_BASKET(offerKey)
            this.$router.push({
                name: 'tourBooking',
                query: {offerKey},
            })
        }

        get searchRequest() {
            //TODO Need filter $route.query params
            const rq = Object.assign(searchRequest(), this.$route.query)
            if (typeof rq.childrenAges === 'string') {
                rq.childrenAges = [parseInt(rq.childrenAges, 10)]
            }
            rq.convertToCurrency = persistentStore.currency
            rq.adults = parseInt(rq.adults, 10)
            rq.packageTourId = parseInt(rq.packageTourId, 10)
            if (this.$route.query.startDateFrom) {
                rq.startDateFrom = this.$route.query.startDateFrom
                rq.startDateTo = this.$route.query.startDateFrom
            } else if (this.info.availabilities.length) {
                rq.startDateFrom = this.info.availabilities[0].startDateFrom
                rq.startDateTo = this.info.availabilities[0].startDateFrom
            }
            return rq
        }

        get info() {
            return toursRuntimeStore.packageTourInfo
        }

        get offer() {
            return toursStore.searchResponse
        }

        get searchActive() {
            return toursRuntimeStore.searchActive
        }

        get dayOffers() {
            return this.info.entries.reduce((dayOffers, entry) => {
                const {type, dayNumber, name} = entry,
                    dayIndex = dayNumber - 1
                if (!dayOffers[dayIndex]) {
                    dayOffers[dayIndex] = []
                }
                let offer, offerName, roomName
                const offerEntry = this.offer.entries.find(entry => entry.name === name)
                if (offerEntry) {
                    switch (type) {
                        case 'ACCOMMODATION':
                            offer = offerEntry.offers.find(hotel => {
                                const room = hotel.rooms.find(
                                    room =>
                                        room.groupedOffers.findIndex(
                                            groupedOffer => groupedOffer.offerKey === offerEntry.selectedOfferKey
                                        ) !== -1
                                )
                                if (room) {
                                    roomName = room.name
                                    return true
                                }
                            })
                            offerName = offer.name
                            break
                        case 'EXCURSION':
                            offer = offerEntry.offers.find(offer => offer.offerKey === offerEntry.selectedOfferKey)
                            offerName = offer.serviceName
                            break
                        case 'FLIGHT':
                            //TODO
                            break
                        case 'TRANSFER':
                            //TODO
                            offer = offerEntry.offers.find(offer => offer.offerKey === offerEntry.selectedOfferKey)
                            offerName = offer.info.tariffName
                            break
                    }
                }
                dayOffers[dayIndex].push({
                    type,
                    offerName,
                    roomName,
                })
                return dayOffers
            }, [])
        }

        get packageTourId() {
            return parseInt(this.$route.query.packageTourId, 10)
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .lightbox .col {
        background: rgba(0, 0, 0, 0.4);
    }
    .sticky {
        position: sticky;
        bottom: 8px;
        left: 0;
    }
</style>
