<template>
    <v-card
        class="my-2 hotel-card"
        :class="{'cursor-pointer': comparisonMode}"
        outlined
        :hover="comparisonMode"
        @click.native="selectToCompare(offer)"
    >
        <div class="d-flex wrapper">
            <v-img
                tile
                :src="offer.thumb | imageUrl(350)"
                :alt="offer.name"
                eager
                width="200"
                max-width="200"
                height="200"
                lazy-src="/placeholder.png"
                class="me-2"
                @error="onImgError"
            >
                <template v-slot:placeholder>
                    <v-row v-show="!imgError && offer.thumb" class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
            <div class="ma-2 flex-grow-1 flex-shrink-1 text-truncate">
                <div class="d-flex align-center flex-wrap">
                    <hotel-stars :category="offer.category" />
                    <hotel-type-label class="ms-1" :offer="offer" />
                    <hotel-own-label class="ms-1" :offer="offer" />
                </div>
                <div class="font-weight-medium text-truncate">
                    <a
                        v-if="infoInNewTab"
                        :href="$router.resolve(hotelPageLink).href"
                        target="_blank"
                        class="text-decoration-none"
                    >
                        {{ offer.name }}
                    </a>
                    <nuxt-link
                        v-else-if="$route.name === 'hotels'"
                        no-prefetch
                        :to="hotelPageLink"
                        class="text-decoration-none"
                    >
                        {{ offer.name }}
                    </nuxt-link>
                    <span v-else>
                        {{ offer.name }}
                    </span>
                </div>
                <hotel-location-label :offer="offer" />
                <hotel-address-label :address="offer.address" :offer="offer" />
                <hotel-services :offer="offer" class="mt-3" />
                <!--v-chip
                    v-for="(classification, classificationIndex) in offer.classifications"
                    :key="classificationIndex"
                    x-small
                    class="ma-1"
                    v-text="classification"
                /-->
                <template v-if="offer.tripadvisorRating">
                    <v-divider class="my-3" />
                    <tripadvisor-rating :tripadvisor-rating="offer.tripadvisorRating" />
                </template>
            </div>
            <div class="ma-4 price-col flex-shrink-0 text-right">
                <slot name="price" :room="minPriceRoomOffer">
                    <hotel-offer-price-block :offer="offer" :search-request="searchRequest" />
                </slot>
                <v-btn depressed block color="primary" class="my-2" nuxt :to="hotelPageLink">
                    {{ $t('book') }}
                </v-btn>
                <div class="primary--text cursor-pointer text-no-wrap" @click="expanded = !expanded">
                    <span class="show-rooms-label">
                        {{ expanded ? $t('hide_room_offers') : $t('view_room_offers') }}
                    </span>
                    <span class="show-rooms-label__short">
                        {{ expanded ? $t('hide_room_offers__short') : $t('view_room_offers__short') }}
                    </span>
                    <v-icon color="primary">
                        {{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                    </v-icon>
                </div>
            </div>
        </div>
        <v-expand-transition>
            <div v-if="expanded">
                <hotel-room-offers-wrapper
                    :offer="offer"
                    :compare-mode="comparisonMode"
                    :_selected-offer-keys.sync="selectedOfferKeys"
                    :full-card="false"
                    :_can-book.sync="canBook"
                    :search-rooms-count="searchRoomsCount"
                >
                    <template v-slot:price="{room}">
                        <slot name="price" :room="room" />
                    </template>
                </hotel-room-offers-wrapper>
                <v-list-item v-if="searchRoomsCount > 1">
                    <v-list-item-content>
                        <hotel-multiple-room-select-block
                            :search-request="searchRequest"
                            :offer="offer"
                            :can-book="canBook"
                            :selected-offer-keys="selectedOfferKeys"
                        />
                    </v-list-item-content>
                </v-list-item>
            </div>
        </v-expand-transition>
    </v-card>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import {hotelsRuntimeStore, hotelsStore, runtimeStore} from '~/store'
    import HotelServices from '@/components/hotels/snippets/HotelServices'
    import TripadvisorRating from '~src/components/hotels/snippets/TripadvisorRating'
    import HotelRoomOffers from '~/components/search/offers/HotelRoomOffers'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import CommissionAmount from '~/components/snippets/CommissionAmount'
    import HotelRoomOffersWrapper from '~src/components/search/offers/HotelRoomOffersWrapper'
    import HotelOfferPriceBlock from '~src/components/search/offers/hotelOfferPriceBlock.src'
    import HotelNameAndRating from '~src/components/hotels/snippets/hotelNameAndRating.src'
    import HotelPriceNote from '@/components/hotels/snippets/HotelPriceNote'
    import HotelMultipleRoomSelectBlock from '~src/components/hotels/snippets/hotelMultipleRoomSelectBlock.src'
    import HotelOwnLabel from '~src/components/hotels/snippets/HotelOwnLabel'
    import HotelStars from '@/components/hotels/snippets/HotelStars'
    import HotelTypeLabel from '@/components/hotels/snippets/HotelTypeLabel'
    import HotelLocationLabel from '@/components/hotels/snippets/HotelLocationLabel'
    import HotelAddressLabel from '@/components/hotels/snippets/HotelAddressLabel'

    @Component({
        components: {
            HotelOwnLabel,
            HotelMultipleRoomSelectBlock,
            HotelPriceNote,
            HotelNameAndRating,
            DiscountLabel,
            HotelServices,
            TripadvisorRating,
            HotelRoomOffers,
            CommissionAmount,
            HotelOfferPriceBlock,
            HotelRoomOffersWrapper,
            HotelStars,
            HotelTypeLabel,
            HotelLocationLabel,
            HotelAddressLabel,
        },
    })
    export default class HotelOfferCard extends Vue {
        @Prop({default: false}) comparisonMode
        @Prop({default: () => ({})}) offer
        @Prop({default: 1}) searchRoomsCount

        expanded = false
        imgError = false
        selectedOfferKeys = []
        canBook = false

        onImgError() {
            this.imgError = true
            return false
        }

        async selectToCompare(offer) {
            if (this.comparisonMode && this.$route.name === 'hotels') {
                await hotelsStore.selectToCompare(offer.hotelCode)
            }
        }

        get hotelPageLink() {
            return hotelsRuntimeStore.hotelPageLink(this.offer, hotelsStore.searchRequest)
        }

        get minPriceRoomOffer() {
            return hotelsRuntimeStore.minPriceRoomOffer(this.offer)
        }

        get infoInNewTab() {
            return runtimeStore.config.infoInNewTab
        }

        get searchRequest() {
            return hotelsStore.searchRequest
        }
    }
</script>

<style scoped lang="scss">
    .wrapper {
        height: 200px;
    }

    .price-col {
        width: 180px;

        .show-rooms-label__short {
            display: none;
        }
    }

    .hotel-card {
        overflow: hidden;
        cursor: unset;
    }

    //TODO Remove after layout redesign
    @media (max-width: 1440px) {
        .price-col {
            width: 130px;

            .show-rooms-label {
                display: none;

                &__short {
                    display: inline;
                }
            }
        }
    }
</style>
