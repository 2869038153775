<template>
    <v-card class="mb-5">
        <v-card-text>
            <v-row>
                <v-col cols="12" md="6">
                    <tour-general-info :info="info" />
                </v-col>
                <v-col cols="12" md="2">
                    <div v-for="traveler in travelers" :key="traveler.personId">
                        <v-icon left small>
                            mdi-account
                        </v-icon>
                        <div>
                            <span class="text-uppercase" v-html="getTravelerName(traveler)" />
                            <small>{{ traveler.citizenshipName }}</small>
                            <div v-if="traveler.passports && traveler.passports.length" class="text-caption">
                                {{ $t('document') }} {{ traveler.passports[0].number }}<br />
                                {{ $t('expire_date') }} {{ traveler.passports[0].expiryDate }}
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="4" class="justify-center justify-md-end">
                    <order-prices
                        :supplier-cancel-penalties="order.orderPackage.supplierCancelPenalties"
                        :supplier-payment-status="order.supplierPaymentStatus"
                        :supplier-price="order.orderPackage.supplierPrice"
                        :client-cancel-penalties="order.orderPackage.clientCancelPenalties"
                        :customer-payment-status="order.customerPaymentStatus"
                        :client-price="order.orderPackage.clientPrice"
                    />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import OrderPrices from '@/components/account/snippets/OrderPrices'
    import TourGeneralInfo from '@/components/tours/snippets/TourGeneralInfo'

    @Component({
        components: {
            TourGeneralInfo,
            OrderPrices,
            OrderInfoBase: () => import('~src/components/account/orderServices/orderInfoBase.src'),
        },
    })
    export default class PackageTourInfo extends Vue {
        @Prop() order

        get info() {
            const startDate = this.order.services[0].startDateTime,
                endDate = this.order.services[this.order.services.length - 1].endDateTime
            return {
                packageDuration: this.$dateFns.differenceInCalendarDays(
                    this.$dateFns.parseISO(endDate),
                    this.$dateFns.parseISO(startDate)
                ),
                packageDate: startDate,
            }
        }

        get travelers() {
            return this.order.services[0].travelers
        }

        getTravelerName(traveler) {
            return `${traveler.prefix} ${traveler.name[0].firstName} ${traveler.name[0].lastName}`
        }
    }
</script>
