<template>
    <div>
        <v-checkbox ref="option" v-model="value" :name="name" :label="label" clearable single-line hide-details dark />
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'

    @Component
    export default class CheckboxOption extends Vue {
        value = null

        @Prop({
            required: true,
        })
        name

        @Prop()
        label

        @Prop()
        defaultValue

        created() {
            if (typeof this.$route.query[this.name] !== 'undefined') {
                this.value = this.$route.query[this.name] + '' === 'true'
            } else {
                this.value = this.defaultValue
            }
        }
    }
</script>
