<template>
    <v-row dense>
        <v-col cols="12" md="3">
            <v-select
                v-model="model.serviceId"
                :items="roomTypes"
                :label="$t('supplier.room_type')"
                item-text="name"
                item-value="id"
            />
        </v-col>
        <v-col cols="12" md="4">
            <daterange
                :start-date-prop.sync="model.dateFrom"
                :end-date-prop.sync="model.dateTo"
                :popover="{placement: 'bottom'}"
                :show-icon="false"
                :single-line="false"
                :disable-min-start-date="true"
                style="margin-top: -20px; display: block;"
            />
        </v-col>
        <v-col cols="12" md="3">
            <v-select
                v-model="model.dateRangeId"
                :items="dateRange"
                :label="$t('supplier.date_range')"
                item-text="name"
                item-value="id"
                @change="updateDates"
            />
        </v-col>
        <v-col cols="12" md="2">
            <v-btn block color="primary" :elevation="0" @click="$emit('update', model)">
                {{ $t('supplier.set_period') }}
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import Daterange from '@/components/supplier/DateRange'

    @Component({
        components: {Daterange},
    })
    export default class CalendarFilters extends Vue {
        @Prop() startDate
        @Prop() endDate
        @Prop() services

        model = {
            dateFrom: null,
            dateTo: null,
            serviceId: 0,
            dateRangeId: 0,
        }

        mounted() {
            this.model.dateFrom = this.startDate
            this.model.dateTo = this.endDate
        }

        get roomTypes() {
            return [{id: 0, name: this.$t('supplier.all')}].concat(this.services)
        }

        get dateRange() {
            return [
                {id: 0, name: this.$t('supplier.choose_period')},
                {id: 1, name: this.$t('supplier.last_month')},
                {id: 2, name: this.$t('supplier.next_month')},
                {id: 3, name: this.$t('supplier.this_time_last_year')},
            ]
        }

        updateDates(val) {
            switch (val) {
                case 1:
                    this.model.dateFrom = this.getPrevMonthStartDate()
                        .toISOString()
                        .split('T')[0]
                    this.model.dateTo = this.getPrevMonthEndDate()
                        .toISOString()
                        .split('T')[0]
                    break
                case 2:
                    this.model.dateFrom = this.getNextMonthStartDate()
                        .toISOString()
                        .split('T')[0]
                    this.model.dateTo = this.getNextMonthEndDate()
                        .toISOString()
                        .split('T')[0]
                    break
                case 3:
                    this.model.dateFrom = this.getPrevYearStartDate()
                        .toISOString()
                        .split('T')[0]
                    this.model.dateTo = this.getPrevYearEndDate()
                        .toISOString()
                        .split('T')[0]
                    break
            }
        }

        getPrevMonthStartDate() {
            const now = new Date()
            const resultDate = new Date(now.getFullYear(), now.getMonth() - 1, 1)

            return new Date(resultDate.getTime() - resultDate.getTimezoneOffset() * 60000)
        }

        getPrevMonthEndDate() {
            const now = new Date()
            const resultDate = new Date(now.getFullYear(), now.getMonth(), 0)

            return new Date(resultDate.getTime() - resultDate.getTimezoneOffset() * 60000)
        }

        getNextMonthStartDate() {
            const now = new Date()
            const resultDate = new Date(now.getFullYear(), now.getMonth() + 1, 1)

            return new Date(resultDate.getTime() - resultDate.getTimezoneOffset() * 60000)
        }

        getNextMonthEndDate() {
            const now = new Date()
            const resultDate = new Date(now.getFullYear(), now.getMonth() + 2, 0)

            return new Date(resultDate.getTime() - resultDate.getTimezoneOffset() * 60000)
        }

        getPrevYearStartDate() {
            const now = new Date()
            const resultDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate())
            return new Date(resultDate.getTime() - resultDate.getTimezoneOffset() * 60000)
        }

        getPrevYearEndDate() {
            const now = new Date()
            const resultDate = new Date(now.getFullYear() - 1, now.getMonth() + 1, now.getDate())

            return new Date(resultDate.getTime() - resultDate.getTimezoneOffset() * 60000)
        }
    }
</script>

<style scoped lang="scss"></style>
