<template>
    <confirmation-page-layout v-if="loaded" :company="company">
        <template v-slot:breadcrumbs>
            <activity-breadcrumbs :search-request="searchRequest" :product="product" />
        </template>
        <template v-slot:sidebar>
            <activity-search-summary search-disabled :search-request="searchRequest" />
            <activity-order-summary :offer="offer" :info="basketItem.info" />
        </template>
    </confirmation-page-layout>
</template>

<script>
    import {mixins, Component} from 'nuxt-property-decorator'
    import ConfirmationStatus from '~src/components/booking/ConfirmationStatus'
    import ExtraServiceSearchSummary from '~src/components/extraServices/search/ExtraServiceSearchSummary'
    import ExtraServiceOrderSummary from '~src/components/extraServices/booking/ExtraServiceOrderSummary'
    import ExtraServiceStoreMixin from '@/mixins/ExtraServiceStoreMixin'
    import ConfirmationPageMixin from '@/mixins/ConfirmationPageMixin'
    import ExtraServicePageMixin from '~src/components/extraServices/mixins/ExtraServicePageMixin'
    import {persistentStore} from '@/utils/store-accessor'
    import ConfirmationPageLayout from '~src/components/parts/ConfirmationPageLayout'
    import ExtraServiceBreadcrumbs from '~src/components/extraServices/ExtraServiceBreadcrumbs'

    @Component({
        components: {
            ExtraServiceBreadcrumbs,
            ConfirmationPageLayout,
            ConfirmationStatus,
            ExtraServiceSearchSummary,
            ExtraServiceOrderSummary,
        },
    })
    export default class ExtraServiceConfirmationPage extends mixins(
        ExtraServicePageMixin,
        ExtraServiceStoreMixin,
        ConfirmationPageMixin
    ) {
        loaded = false

        validate({query}) {
            return query.orderId
        }

        async mounted() {
            await this.$store.restored
            await this.loadRuntimeData()
            if (this.$route.query.orderId !== persistentStore.bookResponse[0].orderId) {
                //TODO Redirect to 404
            }
            this.loaded = true
        }

        get basketItem() {
            return this.productStore.basketItem(this.productStore.prepareBookRequest.offerKey)
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get product() {
            return this.basketItem.product
        }

        get offer() {
            return this.product.offers.find(offer => offer.offerKey === this.basketItem.offerKey)
        }
    }
</script>
