<template>
    <activity-booking-page-layout
        v-if="loaded"
        :search-request="searchRequest"
        :offer="offer"
        :product-store="productStore"
    >
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="refreshExpiredOffers" />
            <activity-breadcrumbs :search-request="searchRequest" :product="product" />
        </template>
        <template v-slot:orderCommentsForm="{bookingKey}">
            <booking-comments-form :disabled="!!bookingKey" :product-store="productStore" />
        </template>
        <template v-slot:sidebar="{bookingKey}">
            <activity-search-summary search-disabled :search-request="searchRequest" />
            <activity-order-summary :offer="offer" :info="basketItem.info" :prebooked="!!bookingKey" />
        </template>
    </activity-booking-page-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import ActivityBreadcrumbs from '@/components/activities/ActivityBreadcrumbs'
    import ActivitySearchSummary from '@/components/activities/search/ActivitySearchSummary'
    import ActivityOrderSummary from '@/components/activities/booking/ActivityOrderSummary'
    import {runtimeStore} from '@/utils/store-accessor'
    import ActivityBookingPageLayout from '~/components/activities/booking/ActivityBookingPageLayout'
    import ActivityStoreMixin from '@/mixins/ActivityStoreMixin'
    import ActivityPageMixin from '@/components/activities/mixins/ActivityPageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import {UTCToDate} from '@/utils/helpers'
    import BookingCommentsForm from '@/components/booking/forms/BookingCommentsForm'

    @Component({
        components: {
            ExpiredOffersSnackbar,
            ActivityBreadcrumbs,
            ActivitySearchSummary,
            ActivityOrderSummary,
            ActivityBookingPageLayout,
            BookingCommentsForm,
        },
        layout: 'blank',
    })
    export default class ActivityBookingPage extends mixins(ActivityStoreMixin, ActivityPageMixin) {
        loaded = false

        validate({query}) {
            return query.offerKey
        }

        async mounted() {
            await this.$store.restored
            await this.loadRuntimeData()
            await runtimeStore.loadTouristBookingFields(this.$route.query.offerKey)
            this.productStore.clearBookingAdditionalOptions()
            await this.productStore.getExtraServiceAdditionalOptions(this.$route.query.offerKey)
            this.loaded = true
        }

        isOffersExpired() {
            return (
                this.$dateFns.isAfter(new Date(), UTCToDate(this.$dateFns.parseISO(this.basketItem.expirationTime))) &&
                !runtimeStore.bookingActive &&
                !this.productStore.prepareBookResponse.bookingKey
            )
        }

        refreshExpiredOffers() {
            this.$router.push(this.productRuntimeStore.searchPageLink(this.searchRequest))
        }

        get basketItem() {
            return this.productStore.basketItem(this.$route.query.offerKey)
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get product() {
            return this.basketItem.product
        }

        get offer() {
            return this.product.offers.find(offer => offer.offerKey === this.$route.query.offerKey)
        }
    }
</script>
