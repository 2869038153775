<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import BookingPageBase from '~src/components/parts/bookingPageBase.src'
    import ExtraServiceStoreMixin from '@/mixins/ExtraServiceStoreMixin'

    @Component
    export default class ActivityBookingPageLayout extends mixins(BookingPageBase, ExtraServiceStoreMixin) {
        get productSearchPageName() {
            return 'extraServices'
        }

        get productConfirmationPageName() {
            return 'extraServiceConfirmation'
        }

        get productName() {
            return 'extraService'
        }

        get productBookingPageName() {
            return 'extraServiceBooking'
        }

        get corporatePolicyType() {
            return 'extraService'
        }
    }
</script>
