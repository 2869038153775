<template>
    <v-card class="mb-5 pa-md-3">
        <v-row>
            <v-col cols="3">
                <v-img class="thumb" :src="image | imageUrl(600)" lazy-src="/placeholder.png" @error="onImgError">
                    <template v-slot:placeholder>
                        <v-row v-show="!imgError && image" class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
            </v-col>
            <v-col cols="9">
                <h3>
                    {{ info.name }}
                </h3>
            </v-col>
        </v-row>
        <div v-if="info.shortDescription">
            {{ info.shortDescription }}
        </div>
        <small class="mt-2">
            <conditions-label :offer-key="offer.offerKey" :price="offer.price" @conditions="showConditionsInfo" />
        </small>
        <original-currency-notification :price="totalPrice" class="mt-5" />
        <v-list-item class="text-right">
            <v-list-item-title>
                <h2 class="py-2">{{ $t('total') }}: {{ totalPrice | price }}</h2>
            </v-list-item-title>
        </v-list-item>
        <conditions-modal>
            <template v-slot:info>
                <activity-conditions-modal-info />
            </template>
        </conditions-modal>
    </v-card>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {activitiesStore} from '~/store'
    import ConditionsModal from '~/components/modals/ConditionsModal'
    import ConditionsLabel from '~/components/snippets/ConditionsLabel'
    import {EventBus, SHOW_ACTIVITY_CONDITIONS_INFO} from '@/utils/event-bus'
    import ActivityConditionsModalInfo from '@/components/activities/snippets/ActivityConditionsModalInfo'
    import OriginalCurrencyNotification from '@/components/booking/snippets/OriginalCurrencyNotification'

    @Component({
        components: {
            OriginalCurrencyNotification,
            ActivityConditionsModalInfo,
            ConditionsModal,
            ConditionsLabel,
        },
    })
    export default class ActivityOrderSummary extends Vue {
        @Prop({required: true}) offer
        @Prop({required: true}) info
        @Prop({default: true}) prebooked

        imgError = false

        onImgError() {
            this.imgError = true
            return false
        }

        showConditionsInfo() {
            EventBus.$emit(SHOW_ACTIVITY_CONDITIONS_INFO, this.offer)
        }

        get image() {
            if (!this.info.images) return null
            let main = this.info.images.find(image => image.mainImage)
            if (!main && this.info.images.length > 0) {
                main = this.info.images[0]
            }
            return main && main.url ? main.url : null
        }

        get totalPrice() {
            return activitiesStore.orderTotalPrice(this.offer, this.prebooked)
        }
    }
</script>
