<template>
    <tbody>
        <tr>
            <td class="primary--text" @click="expanded = !expanded">
                {{ tariff.name }}
                <v-icon v-if="!expanded" class="primary--text" x-small>
                    mdi-arrow-right-bold
                </v-icon>
                <v-icon v-else class="primary--text" x-small>
                    mdi-arrow-up-bold
                </v-icon>
            </td>
            <td
                v-for="tariffDay in tariff.dailyRates"
                :key="'tariff-day-' + serviceId + '-' + tariff.id + tariffDay.date"
                :class="rateStatusColor(tariffDay)"
            >
                <v-menu
                    v-if="rateStatusColor(tariffDay) !== 'yellow'"
                    :close-on-content-click="true"
                    :nudge-width="150"
                    offset-y
                    absolute
                >
                    <template v-slot:activator="{on}">
                        <div class="fill" v-on="on" />
                    </template>
                    <v-card class="pa-1 pl-1">
                        <v-card-subtitle class="pa-0 text-center font-weight-bold">
                            {{ tariffDay.date | dateShortFormat }}
                        </v-card-subtitle>
                        <v-radio-group
                            :value="rateStatus(tariffDay)"
                            class="pl-2"
                            @change="changeRateStatus(tariffDay.date, $event)"
                        >
                            <v-radio value="bookable">
                                <template v-slot:label>
                                    <div class="d-flex align-center justify-space-between full-width">
                                        {{ $t('supplier.bookable') }}
                                        <v-icon class="green--text">
                                            mdi-checkbox-blank-circle
                                        </v-icon>
                                    </div>
                                </template>
                            </v-radio>
                            <v-radio value="closed">
                                <template v-slot:label>
                                    <div class="d-flex align-center justify-space-between full-width">
                                        {{ $t('supplier.closed') }}
                                        <v-icon class="red--text">
                                            mdi-checkbox-blank-circle
                                        </v-icon>
                                    </div>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-card>
                </v-menu>
            </td>
        </tr>
        <tr v-show="expanded">
            <td>{{ $t('price') }} {{ currency }}</td>
            <td
                v-for="tariffDay in tariff.dailyRates"
                :key="'tariff-day-price-' + serviceId + '-' + tariff.id + tariffDay.date"
            >
                <v-text-field
                    class="text-center"
                    :value="tariffDay.rate.amount"
                    flat
                    hide-details
                    type="number"
                    min="0"
                    :disabled="!tariffDay.rate.active && !!tariffDay.rate.amount"
                    @change="updateRatePrice(tariffDay.date, $event)"
                />
            </td>
        </tr>
        <tr v-show="expanded">
            <td>{{ $t('supplier.min_length_of_stay') }}</td>
            <td
                v-for="tariffDay in tariff.dailyRates"
                :key="'tariff-min-nights-' + serviceId + '-' + tariff.id + tariffDay.date"
            >
                <v-text-field
                    class="text-center"
                    :value="tariffDay.rate.minNights"
                    flat
                    hide-details
                    type="number"
                    min="0"
                    :disabled="!tariffDay.rate.active && !!tariffDay.rate.amount"
                    @change="updateRateMinNights(tariffDay.date, $event)"
                />
            </td>
        </tr>
        <tr v-show="expanded">
            <td>{{ $t('supplier.max_length_of_stay') }}</td>
            <td
                v-for="tariffDay in tariff.dailyRates"
                :key="'tariff-max-nights-' + serviceId + '-' + tariff.id + tariffDay.date"
            >
                <v-text-field
                    class="text-center"
                    :value="tariffDay.rate.maxNights"
                    flat
                    hide-details
                    type="number"
                    min="0"
                    :disabled="!tariffDay.rate.active && !!tariffDay.rate.amount"
                    @change="updateRateMaxNights(tariffDay.date, $event)"
                />
            </td>
        </tr>
    </tbody>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {supplierStore} from '@/utils/store-accessor'

    @Component({})
    export default class TariffRows extends Vue {
        @Prop() tariff
        @Prop() serviceId

        expanded = false

        rateStatus(tariffDay) {
            switch (this.rateStatusColor(tariffDay)) {
                case 'green':
                    return 'bookable'
                case 'red':
                    return 'closed'
                default:
                    return 'no_room'
            }
        }

        rateStatusColor(tariffDay) {
            if (!tariffDay.rate.amount) {
                return 'grey'
            }

            if (tariffDay.rate.active) {
                return 'green'
            }

            return 'red'
        }

        get currency() {
            return supplierStore.contract.transactionsCurrency
        }

        changeRateStatus(date, value) {
            this.$emit('update', {
                dateFrom: date,
                dateTo: date,
                tariffId: this.tariff.id,
                serviceId: this.serviceId,
                operation: value === 'bookable' ? 'CLEAR_BLOCK' : 'BLOCK',
                dayOfWeeks: [true, true, true, true, true, true, true],
            })
        }

        updateRatePrice(date, value) {
            this.$emit('update', {
                dateFrom: date,
                dateTo: date,
                tariffId: this.tariff.id,
                serviceId: this.serviceId,
                dayOfWeeks: [true, true, true, true, true, true, true],
                amount: value,
            })
        }

        updateRateMinNights(date, value) {
            this.$emit('update', {
                dateFrom: date,
                dateTo: date,
                tariffId: this.tariff.id,
                serviceId: this.serviceId,
                dayOfWeeks: [true, true, true, true, true, true, true],
                minNights: value,
            })
        }

        updateRateMaxNights(date, value) {
            this.$emit('update', {
                dateFrom: date,
                dateTo: date,
                tariffId: this.tariff.id,
                serviceId: this.serviceId,
                dayOfWeeks: [true, true, true, true, true, true, true],
                maxNights: value,
            })
        }
    }
</script>

<style scoped lang="scss">
    .fill {
        height: 100%;
    }
    .full-width {
        width: 100%;
    }
</style>
