<template>
    <v-row class="px-2">
        <template v-for="(item, index) in routesQuantity">
            <v-col
                v-if="routesQuantity.length > 1"
                :key="`delete-${item}`"
                cols="1"
                class="px-0 d-flex justify-center align-center"
            >
                <v-btn text @click="deleteRoute(index)">
                    <v-icon color="error">
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-col>
            <v-col :key="item" :cols="routesQuantity.length > 1 ? 11 : 12" class="pa-0">
                <one-way :form-index="index" :init-search="initSearch" @form-data="addRoute(index, $event)" />
            </v-col>
        </template>
        <v-col offset="1" class="pa-0">
            <v-btn text class="white--text h6" @click="addNewRoute">
                {{ $t('flights.add_route') }}
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
    import {Component, Watch} from 'vue-property-decorator'
    import OneWay from '~/components/flights/search/forms/OneWay'
    import FlightBaseForm from './FlightBaseForm.vue'
    import {flightsRuntimeStore} from '~/store'

    @Component({
        components: {
            OneWay,
        },
    })
    export default class MultiCity extends FlightBaseForm {
        routesQuantity = ['route-0']
        routes = []

        @Watch('initSearch')
        onSearchInit() {
            this.routes = []
        }

        @Watch('routes', {deep: true})
        onRoutesChange() {
            if (this.routes.length === this.routesQuantity.length) {
                this.$emit('form-data', this.routes)
            }
        }

        created() {
            for (let i = 1; i < flightsRuntimeStore.routes.length; i++) {
                this.addNewRoute()
            }
        }

        addNewRoute() {
            this.routesQuantity.push(`route-${this.routesQuantity.length}`)
        }

        addRoute(index, route) {
            this.routes.push(route[0])
        }

        deleteRoute(index) {
            this.routesQuantity.splice(index, 1)
        }
    }
</script>
