<template>
    <v-dialog v-model="searchForm" content-class="search-form-dialog" :fullscreen="$breakpoint.smAndDown">
        <template v-slot:activator="{on}">
            <v-card class="mb-5" outlined v-on="checkDisabled(on)">
                <v-card-title class="title font-weight-light word-no-wrap">
                    <slot name="title" />
                </v-card-title>
                <v-card-text>
                    <slot name="content" />
                </v-card-text>
                <v-card-actions v-if="!searchDisabled">
                    <strong class="primary--text float-right ml-auto">{{ $t('change') }}</strong>
                </v-card-actions>
            </v-card>
        </template>
        <component
            :is="formName"
            style="width: 100%"
            :popup="true"
            :variant="searchRequest.type"
            @close-popup="searchForm = false"
        />
    </v-dialog>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'

    @Component({
        components: {
            ActivitySearchForm: () => import('~src/components/activities/search/forms/activitySearchForm.src'),
            HotelSearchForm: () => import('~src/components/search/forms/hotelSearchForm.src'),
            HotelInfoSearchForm: () => import('~/components/info/forms/HotelInfoSearchForm'),
            TransferSearchForm: () => import('~src/components/transfers/search/forms/transferSearchForm.src'),
            FlightSearchForm: () => import('~src/components/flights/search/forms/flightSearchForm.src'),
            PackagesSearchForm: () => import('~/components/packages/search/forms/PackagesSearchForm'),
            TourSearchForm: () => import('~/components/tours/search/forms/TourSearchForm'),
            ExtraServiceSearchForm: () => import('~src/components/extraServices/search/forms/ExtraServiceSearchForm'),
        },
    })
    export default class SearchSummaryLayout extends Vue {
        @Prop({default: false, type: Boolean}) searchDisabled
        @Prop({required: true}) formName
        @Prop({default: () => ({})}) searchRequest

        searchForm = false

        checkDisabled(on) {
            if (this.searchDisabled) return
            return on
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .v-dialog.search-form-dialog {
        overflow: visible;

        > .v-card > .v-card__text {
            padding-top: 24px;
        }
    }

    @media (max-width: 960px) {
        ::v-deep .v-dialog.search-form-dialog {
            overflow-y: scroll;
        }
    }

    .word-no-wrap {
        word-break: break-word;
    }
</style>
