<script>
    import {Component, Prop} from 'vue-property-decorator'
    import {transfersRuntimeStore} from '~/store'
    import Breadcrumbs from '~/components/Breadcrumbs'

    @Component
    export default class TransferBreadcrumbs extends Breadcrumbs {
        @Prop({required: true}) searchRequest
        @Prop({default: () => {}}) product

        get items() {
            const getSearchPageLabel = () =>
                `${transfersRuntimeStore.departurePoint.name}, ${transfersRuntimeStore.departurePoint.countryName} - ${transfersRuntimeStore.arrivalPoint.name}, ${transfersRuntimeStore.arrivalPoint.countryName}`
            const addSearchPageLink = items => {
                items.push({
                    text: getSearchPageLabel(),
                    to: transfersRuntimeStore.searchPageLink(this.searchRequest),
                    nuxt: true,
                    exact: true,
                })
            }
            const items = [this.homeItem]
            switch (this.$route.name) {
                case 'transfers':
                    items.push({
                        text: getSearchPageLabel(),
                        disabled: true,
                    })
                    break
                case 'transfer':
                    addSearchPageLink(items)
                    items.push({
                        text: decodeURIComponent(this.$route.query.name),
                        disabled: true,
                    })
                    break
                case 'transferBooking':
                    addSearchPageLink(items)
                    items.push({
                        text: this.product.info.name,
                        disabled: true,
                    })
                    items.push({
                        text: this.$t('reservation'),
                        disabled: true,
                    })
                    break
                case 'transferConfirmation':
                    addSearchPageLink(items)
                    items.push({
                        text: this.product.info.name,
                        disabled: true,
                    })
                    items.push({
                        text: this.$t('confirmation'),
                        disabled: true,
                    })
                    break
            }
            return items
        }
    }
</script>
