<template>
    <div @click="emitClick">
        <client-only>
            <v-alert v-if="$breakpoint.smAndDown && visible" class="white--text d-flex flex-nowrap" color="primary">
                <span v-if="mobileButtonInfo">
                    {{ $tc('found_offers', count) }}
                </span>
                <div v-else class="d-flex flex-nowrap">
                    {{ $t('delete_all_filters') }}?
                    <v-spacer />
                    <v-icon right color="success" @click.stop="clear">
                        mdi-check
                    </v-icon>
                    <v-icon right color="error" @click.stop="mobileButtonInfo = !mobileButtonInfo">
                        mdi-close
                    </v-icon>
                </div>
                <template v-slot:close>
                    <v-icon
                        v-show="mobileButtonInfo"
                        right
                        color="white"
                        @click.stop="mobileButtonInfo = !mobileButtonInfo"
                    >
                        mdi-close
                    </v-icon>
                </template>
            </v-alert>
        </client-only>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator'

    @Component
    export default class FilteredMobileButton extends Vue {
        mobileButtonInfo = true

        @Prop({required: true}) count
        @Prop({required: true}) visible

        clear() {
            this.$emit('clear-filters')
            this.mobileButtonInfo = true
        }

        emitClick() {
            this.$emit('change-mode')
        }
    }
</script>

<style scoped>
    >>> .v-alert__wrapper {
        width: 100%;
    }
</style>
