<template>
    <search-summary-layout :search-disabled="searchDisabled" form-name="tour-search-form">
        <template v-slot:content>
            {{ date | dateFormat }}
            <br />
            {{ touristsTotal }}
            {{ $tc('guest', touristsTotal) }}
        </template>
    </search-summary-layout>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import SearchSummaryLayout from '@/components/parts/SearchSummaryLayout'
    import {toursRuntimeStore} from '@/utils/store-accessor'
    import {searchRequest} from '~/utils/tours/tours-blank-states'

    @Component({
        components: {
            SearchSummaryLayout,
        },
    })
    export default class TourSearchSummary extends Vue {
        @Prop({default: false, type: Boolean}) searchDisabled
        @Prop({default: () => searchRequest()}) searchRequest

        get date() {
            return this.searchRequest.startDateFrom
        }

        get touristsTotal() {
            return toursRuntimeStore.touristsTotal(this.searchRequest)
        }
    }
</script>
