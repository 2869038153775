<template>
    <v-snackbar v-model="offersExpired" :timeout="-1" top color="error">
        {{ $t('offers_expired') }}
        <template v-slot:action="{attrs}">
            <v-btn
                text
                v-bind="attrs"
                @click="
                    $emit('refresh')
                    offersExpired = false
                    start()
                "
            >
                {{ $t('refresh') }}
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import {EventBus, OFFER_EXPIRED_EVENT} from '@/utils/event-bus'

    @Component
    export default class ExpiredOffersSnackbar extends Vue {
        @Prop() isOffersExpired

        offersExpired = false
        alertTimer

        start() {
            this.alertTimer = setInterval(() => {
                if (this.isOffersExpired()) {
                    this.offersExpired = true
                    EventBus.$emit(OFFER_EXPIRED_EVENT)
                    clearInterval(this.alertTimer)
                }
            }, 3000)
        }

        mounted() {
            this.start()
        }

        beforeDestroy() {
            clearInterval(this.alertTimer)
        }
    }
</script>
