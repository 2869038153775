<template>
    <div>
        <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="search" />
        <mobile-info-page-tabs v-if="$breakpoint.smAndDown" v-model="mobileTabs" />
        <v-container fluid>
            <extra-service-breadcrumbs v-if="!$breakpoint.smAndDown" :search-request="searchRequest" />
            <v-row>
                <v-col v-show="!$breakpoint.smAndDown || ($breakpoint.smAndDown && mobileTabs === 0)" md="8">
                    <h1 v-if="product.info" class="title">
                        {{ product.info.name }}
                    </h1>
                    <component
                        :is="!$breakpoint.smAndDown ? 'product-gallery' : 'product-gallery-mobile'"
                        :images-data="uniqueImages"
                    />
                    <template v-if="!$breakpoint.smAndDown">
                        <extra-service-offer-card
                            v-for="offer in product.offers"
                            :key="offer.offerKey"
                            :offer="offer"
                            @images="images = [...images, ...$event]"
                            @description="setFullDescription"
                        />
                    </template>
                    <view-offers-button @showOffers="mobileTabs = 1" />
                    <div
                        v-if="(product.info && product.info.description) || fullDescription"
                        class="my-4"
                        v-html="fullDescription || product.info.description"
                    />
                </v-col>
                <v-col v-show="!$breakpoint.smAndDown || ($breakpoint.smAndDown && mobileTabs === 1)" md="4">
                    <extra-service-search-summary :search-request="searchRequest" />
                    <template v-if="$breakpoint.smAndDown">
                        <extra-service-offer-card
                            v-for="offer in product.offers"
                            :key="offer.offerKey"
                            :offer="offer"
                            :description="description"
                            @images="images = [...images, ...$event]"
                            @description="setFullDescription"
                        />
                    </template>
                    <div v-if="!$breakpoint.smAndDown" style="position: relative">
                        <client-only>
                            <extra-service-map
                                :city="city"
                                style="position: relative; max-height: 70vh; top: 0!important"
                                class="disable-top"
                            />
                        </client-only>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <conditions-modal>
            <template v-slot:info>
                <extra-service-conditions-modal-info />
            </template>
        </conditions-modal>
        <extra-service-info-modal />
    </div>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import MobileInfoPageTabs from '~src/components/snippets/MobileInfoPageTabs'
    import ConditionsModal from '~src/components/modals/ConditionsModal'
    import ExtraServiceBreadcrumbs from '~src/components/extraServices/ExtraServiceBreadcrumbs'
    import ExtraServiceOfferCard from '~src/components/extraServices/info/ExtraServiceOfferCard'
    import ExtraServiceSearchSummary from '~src/components/extraServices/search/ExtraServiceSearchSummary'
    import ExtraServiceMap from '~src/components/extraServices/search/ExtraServiceMap'
    import ExtraServiceInfoModal from '~src/components/extraServices/info/ExtraServiceInfoModal'
    import ExtraServiceStoreMixin from '@/mixins/ExtraServiceStoreMixin'
    import ViewOffersButton from '~src/components/snippets/ViewOffersButton'
    import ProductGallery from '~src/components/info/ProductGallery'
    import ProductGalleryMobile from '~src/components/info/ProductGalleryMobile'
    import ExtraServicePageMixin from '~src/components/extraServices/mixins/ExtraServicePageMixin'
    import ExpiredOffersSnackbar from '~src/components/search/ExpiredOffersSnackbar'
    import ExtraServiceConditionsModalInfo from '@/components/extraServices/snippets/ExtraServiceConditionsModalInfo'

    @Component({
        components: {
            ExtraServiceConditionsModalInfo,
            ExpiredOffersSnackbar,
            ViewOffersButton,
            ExtraServiceOfferCard,
            ExtraServiceBreadcrumbs,
            MobileInfoPageTabs,
            ExtraServiceSearchSummary,
            ExtraServiceMap,
            ConditionsModal,
            ExtraServiceInfoModal,
            ProductGallery,
            ProductGalleryMobile,
        },
        layout: 'blank',
    })
    export default class ExtraServicePage extends mixins(ExtraServicePageMixin, ExtraServiceStoreMixin) {
        mobileTabs = 0
        images = []
        fullDescription = null
        groupBy = 'serviceName'

        validate({query}) {
            return query.name
        }

        async mounted() {
            await this.$store.restored
            await this.loadRuntimeData()
        }

        search() {
            this.$router.push(this.productRuntimeStore.searchPageLink(this.searchRequest))
        }

        isOffersExpired() {
            return this.productStore.isOffersExpired()
        }

        setFullDescription(serviceDescription) {
            this.fullDescription = serviceDescription
        }

        get product() {
            return (
                this.productStore.searchResponse.products.find(
                    product => product.info.name === decodeURIComponent(this.$route.query.name)
                ) || {}
            )
        }

        get uniqueImages() {
            return this.images.filter((v, i, a) => a.findIndex(t => t.url === v.url) === i)
        }

        get searchRequest() {
            return this.productStore.searchRequest
        }

        get city() {
            return this.productRuntimeStore.city
        }

        get description() {
            return this.product.info.description
        }
    }
</script>

<style lang="scss">
    .disable-top {
        top: 0 !important;
    }
</style>
