<template>
    <v-snackbar v-if="orderId" v-model="orderId" :timeout="-1" top primary>
        <span>
            {{ $t('adding_service_to_order') }}&nbsp;
            <n-link no-prefetch :to="{name: 'order-details', params: {id: orderId}}">#{{ orderId }}</n-link>
        </span>
        <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-snackbar>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'
    import {persistentStore} from '~/store'

    @Component
    export default class AddServiceSnackbar extends Vue {
        get orderId() {
            return persistentStore.selectedOrderId
        }

        close() {
            persistentStore.SET_SELECTED_ORDER_ID(null)
        }
    }
</script>
