<template>
    <div v-if="order">
        <v-snackbar v-model="snackbar.show" color="error">
            {{ snackbar.message }}
        </v-snackbar>
        <v-row>
            <v-col cols="12" md="6">
                <div class="text-h6 d-flex">
                    <div class="font-weight-bold">{{ $t('order') }} #{{ order.orderId }}</div>
                    <span v-if="!orderTitleEdit" class="ms-2">
                        {{ order.title }}
                        <v-icon
                            v-if="isAgency || isTourOperator"
                            small
                            color="primary"
                            @click="
                                orderTitleEdit = true
                                orderTitle = order.title
                            "
                        >
                            mdi-pencil
                        </v-icon>
                    </span>
                    <div v-else class="d-flex ms-2 flex-grow-1">
                        <v-text-field v-model="orderTitle" dense hide-details class="me-1" />
                        <v-btn depressed small color="primary" @click="saveOrderTitle">
                            {{ $t('save') }}
                        </v-btn>
                    </div>
                </div>
            </v-col>
            <v-col v-if="itineraryViewServices.length" cols="12" md="3">
                <div class="d-flex justify-start justify-md-end">
                    <v-btn
                        v-if="itineraryLink && itineraryView"
                        icon
                        :href="itineraryLink"
                        target="_blank"
                        color="primary"
                        class="me-3"
                    >
                        <v-icon>mdi-link</v-icon>
                    </v-btn>
                    <v-btn depressed :class="itineraryView ? 'grey lighten-1' : ''" @click="openItineraryView(true)">
                        <v-icon>mdi-map-legend</v-icon>
                    </v-btn>
                    <v-btn depressed :class="!itineraryView ? 'grey lighten-1' : ''" @click="openItineraryView(false)">
                        <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="!$breakpoint.smAndDown || !showOnMobile" md="9">
                <div :class="{'order-wrapper': order.orderPackage}">
                    <v-alert
                        v-if="order.orderPackage"
                        border="bottom"
                        color="info"
                        class="white--text text-uppercase mb-0"
                        v-text="order.orderPackage.packageName"
                    />
                    <div class="services">
                        <div v-if="order.orderPackage">
                            <component
                                :is="
                                    order.orderPackage.packageType === 'DYNAMIC_PACKAGE'
                                        ? 'package-info'
                                        : 'package-tour-info'
                                "
                                :order="order"
                                @cancelService="$refs.cancelServiceModal.show($event)"
                                @issueTicket="$refs.issueTicketModal.show($event)"
                            />
                        </div>
                        <template v-if="itineraryView">
                            <itinerary-info v-if="orderItinerary.description" :info="orderItinerary" />
                            <div v-for="(date, index) in itineraryViewServices" :key="date.date + index">
                                <div>
                                    <v-card outlined class="mb-2">
                                        <v-alert color="grey lighten-4" class="black--text mb-0">
                                            <div
                                                class="d-flex flex-wrap flex-md-nowrap"
                                                :class="$breakpoint.smAndDown ? 'flex-column' : ''"
                                            >
                                                <div class="d-flex">
                                                    <strong class="text-body-1 text-capitalize me-2 font-weight-medium">
                                                        <v-icon>mdi-weather-sunny</v-icon>
                                                        {{ $tc('day', 1) }} {{ index + 1 }}
                                                    </strong>
                                                    <span class="text-body-1 text-capitalize d-flex">
                                                        {{ date.date | dateFormat }}
                                                    </span>
                                                </div>
                                            </div>
                                        </v-alert>
                                    </v-card>
                                    <v-row v-if="itineraryDay(index)" class="mb-0">
                                        <v-col cols="9" v-html="itineraryDay(index).description" />
                                        <v-col cols="3">
                                            <order-itinerary-day-images
                                                :images="itineraryDayImages(index)"
                                                @show-photo-swipe="showPhotoSwipe($event)"
                                            />
                                        </v-col>
                                    </v-row>
                                    <div
                                        v-for="(time, timeIndex) in date.times"
                                        :key="time + timeIndex"
                                        class="d-md-flex"
                                    >
                                        <div class="d-flex justify-center mr-4 text-subtitle-1 font-weight-regular">
                                            <span class="time">
                                                {{ time.type.text ? time.type.text() : '' }}
                                                {{ time.type.textFlight ? time.type.textFlight() : '' }} {{ time.time }}
                                            </span>
                                        </div>
                                        <div class="flex-grow-1">
                                            <component
                                                :is="serviceComponent(service.serviceType)"
                                                v-for="(service, i) in time.service"
                                                :key="service.serviceId + i"
                                                :service="service"
                                                :itinerary-service="time"
                                                :is-package="!!order.orderPackage"
                                                class="mb-5"
                                                :order-id="order.orderId"
                                                :object-id="order.objectId"
                                                @cancelService="$refs.cancelServiceModal.show($event)"
                                                @issueTicket="$refs.issueTicketModal.show($event)"
                                                @modifyService="$emit('modifyService')"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <component
                                :is="serviceComponent(service.serviceType)"
                                v-for="service in order.services"
                                :key="service.serviceId"
                                :service="service"
                                :is-package="!!order.orderPackage"
                                class="mb-5"
                                :order-id="order.orderId"
                                :object-id="order.objectId"
                                @cancelService="$refs.cancelServiceModal.show($event)"
                                @issueTicket="$refs.issueTicketModal.show($event)"
                                @modifyService="$emit('modifyService')"
                            />
                        </template>
                    </div>
                </div>
            </v-col>
            <v-col v-if="!$breakpoint.smAndDown || ($breakpoint.smAndDown && showOnMobile)" md="3">
                <v-card outlined class="mb-2 order-summary">
                    <v-card-text class=" black--text subtitle-2">
                        <v-row no-gutters>
                            <v-col :cols="12" md="12">
                                <v-row v-if="!$breakpoint.smAndDown" no-gutters>
                                    <v-col :cols="6" md="6" class="font-weight-regular">{{ $t('order') }}:</v-col>
                                    <v-col :cols="6" md="6" class="font-weight-regular">
                                        {{ order.orderId }}
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col :cols="6" md="6" class="font-weight-regular">
                                        {{ $t('order_status') }}:
                                    </v-col>
                                    <v-col :cols="6" md="6">
                                        <strong :class="`${statusColor}--text`">
                                            {{ orderStatus }}
                                        </strong>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col :cols="6" md="6" class="font-weight-regular">
                                        {{ $t('payment_status') }}:
                                    </v-col>
                                    <v-col :cols="6" md="6" class="font-weight-regular">
                                        {{ statusForAgency }}
                                    </v-col>
                                </v-row>
                                <div v-if="order.clientCompanyName || tripAgentName" class="mt-4">
                                    <v-row v-if="order.clientCompanyName" no-gutters class="mt-4">
                                        <v-col :cols="6" md="6" class="font-weight-regular">
                                            {{ $t('company') }}:
                                        </v-col>
                                        <v-col :cols="6" md="6" class="font-weight-regular">
                                            {{ order.clientCompanyName }}
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="tripAgentName" no-gutters>
                                        <v-col :cols="6" md="6" class="font-weight-regular">{{ $t('agent') }}:</v-col>
                                        <v-col :cols="6" md="6" class="font-weight-regular">
                                            {{ tripAgentName }}
                                        </v-col>
                                    </v-row>
                                </div>
                                <div v-if="tripOrganaizerName || clientName || managerName" class="mt-4">
                                    <v-row v-if="tripOrganaizerName" no-gutters>
                                        <v-col :cols="6" md="6" class="font-weight-regular">
                                            {{ $t('trip_organaizer') }}:
                                        </v-col>
                                        <v-col :cols="6" md="6" class="font-weight-regular">
                                            {{ tripOrganaizerName }}
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="clientName" no-gutters>
                                        <v-col :cols="6" md="6" class="font-weight-regular">
                                            {{ $t('client') }}:
                                        </v-col>
                                        <v-col :cols="6" md="6" class="font-weight-regular">
                                            {{ clientName }}
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="managerName" no-gutters>
                                        <v-col :cols="6" md="6" class="font-weight-regular">
                                            {{ $t('manager') }}:
                                        </v-col>
                                        <v-col :cols="6" md="6" class="font-weight-regular">
                                            {{ managerName }}
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-row no-gutters class="mt-4">
                                    <v-col :cols="6" md="6" class="font-weight-regular">{{ $t('dates') }}:</v-col>
                                    <v-col :cols="6" md="6">
                                        <strong>
                                            {{ minDate | dateFormat }} <br />
                                            {{ maxDate | dateFormat }}
                                        </strong>
                                    </v-col>
                                </v-row>
                                <v-row v-if="nightsQty" no-gutters>
                                    <v-col :cols="6" md="6" class="font-weight-regular">
                                        {{ $t('nights_qty') }}:
                                    </v-col>
                                    <v-col :cols="6" md="6">
                                        <strong>
                                            {{ nightsQty }}
                                        </strong>
                                    </v-col>
                                </v-row>
                                <v-row v-if="isTourOperator" no-gutters class="my-4">
                                    <v-col :cols="6" md="6">
                                        <total-order-prices
                                            title="supplier_price"
                                            :order-prices="orderPrices('SUPPLIER')"
                                            :payment-status="supplierPaymentStatus"
                                            :service-card-guarantee="gotPayAtHotelService"
                                        />
                                    </v-col>
                                    <v-col :cols="6" md="6">
                                        <total-order-prices
                                            title="client_price"
                                            :order-prices="orderPrices('CLIENT')"
                                            :payment-status="customerPaymentStatus"
                                            :service-card-guarantee="gotPayAtHotelService"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row v-else no-gutters class="my-4 font-weight-regular">
                                    <v-col :cols="6" md="6">{{ $t('price') }}:</v-col>
                                    <v-col :cols="6" md="6">
                                        <div
                                            v-for="(orderPrice, index) in orderPrices(isAgency ? 'SUPPLIER' : 'CLIENT')"
                                            :key="index"
                                        >
                                            <strong>
                                                {{ orderPrice.amount | priceFormat(orderPrice.currency) }}
                                            </strong>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row v-if="isAgency" no-gutters class="my-4 font-weight-regular">
                                    <v-col :cols="6" md="6">{{ $t('commission') }}:</v-col>
                                    <v-col :cols="6" md="6">
                                        <div v-for="(orderPrice, index) in orderPrices('SUPPLIER')" :key="index">
                                            <strong>
                                                {{ orderPrice.commission | priceFormat(orderPrice.currency) }}
                                            </strong>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-if="!isSupplier && !isGuest" :cols="12" md="12">
                                <div class="d-flex flex-wrap justify-center mt-2">
                                    <v-btn
                                        v-if="canPayOrder && !gotPayAtHotelService"
                                        depressed
                                        class="mb-2 white--text"
                                        color="primary"
                                        block
                                        :small="$breakpoint.smAndDown"
                                        @click="payOrder"
                                    >
                                        {{ $t('pay_order') }}
                                    </v-btn>
                                    <v-btn
                                        v-if="canDownloadVouchers"
                                        depressed
                                        class="mb-2 white--text"
                                        color="primary"
                                        block
                                        :small="$breakpoint.smAndDown"
                                        @click="downloadVouchers"
                                    >
                                        {{ $t('voucher') }}
                                    </v-btn>
                                    <chat-modal v-if="order" :order-id="order.orderId" />
                                    <v-btn
                                        v-if="!gotPayAtHotelService"
                                        outlined
                                        class="mb-2 black--text"
                                        color="grey darken-1"
                                        block
                                        :small="$breakpoint.smAndDown"
                                        @click="isAgency ? showInvoices('SUPPLIER') : showInvoices('CUSTOMER')"
                                    >
                                        {{ $t('invoices.client_download') }}
                                    </v-btn>
                                    <v-btn
                                        v-if="!$config.account.hideAddServiceBtn"
                                        outlined
                                        class="mb-2 black--text"
                                        color="grey darken-1"
                                        block
                                        data-cy="add-service-button"
                                        :small="$breakpoint.smAndDown"
                                        @click="addService"
                                    >
                                        {{ $t('add_service') }}
                                    </v-btn>
                                    <v-btn
                                        v-if="!$config.account.hideItineraryBtn"
                                        outlined
                                        class="mb-2 black--text"
                                        color="grey darken-1"
                                        block
                                        :small="$breakpoint.smAndDown"
                                        :disabled="loadItinerary"
                                        :loading="loadItinerary"
                                        @click="getItinerary"
                                    >
                                        {{ $t('itinerary') }}
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-btn
            v-if="$breakpoint.smAndDown"
            depressed
            class="view-summary-button"
            color="primary"
            width="100%"
            @click="showOnMobile = !showOnMobile"
        >
            {{ !showOnMobile ? $t('view_summary') : $t('hide_summary') }}
        </v-btn>
        <conditions-modal />
        <cancel-service-modal ref="cancelServiceModal" @cancelService="$emit('cancelService')" />
        <invoices-modal @issuedInvoice="$emit('issuedInvoice', $event)" />
        <modify-modal @modifyService="$emit('modifyService')" />
        <invoices-pay-modal @issuedInvoice="$emit('issuedInvoice', $event)" />
        <issue-ticket-modal ref="issueTicketModal" @issuedTicket="$emit('issuedTicket')" />
        <v-photo-swipe
            v-if="photoSwipeIsOpen"
            :is-open.sync="photoSwipeIsOpen"
            :items="photoSwipeItems"
            :photo-swipe-options="photoSwipeOptions"
        />
    </div>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import {authStore, runtimeStore, persistentStore} from '@/store'
    import FlightService from '~/components/account/orderServices/FlightService'
    import AccommodationService from '~/components/account/orderServices/AccommodationService'
    import TransferService from '~/components/account/orderServices/TransferService'
    import ActivityService from '~/components/account/orderServices/ActivityService'
    import ConditionsModal from '@/components/modals/ConditionsModal'
    import CancelServiceModal from '@/components/modals/CancelServiceModal'
    import {EventBus, PAY_ORDER_EVENT, SHOW_INVOICES_MODAL_EVENT} from '@/utils/event-bus'
    import InvoicesModal from '@/components/modals/InvoicesModal'
    import OrderPrice from '@/components/account/snippets/OrderPrice'
    import {downloadData} from '@/utils/helpers'
    import ModifyModal from '@/components/account/modify/ModifyModal'
    import InvoicesPayModal from '@/components/modals/InvoicesPayModal'
    import IssueTicketModal from '@/components/modals/IssueTicketModal'
    import ChatModal from '@/components/modals/ChatModal'
    import {clone} from '@/utils/helpers'
    import TotalOrderPrices from '@/components/account/snippets/TotalOrderPrices'
    import ItineraryInfo from '@/components/info/ItineraryInfo'
    import VPhotoSwipe from '@/components/snippets/VPhotoSwipe'
    import OrderItineraryDayImages from '@/components/account/snippets/OrderItineraryDayImages'

    @Component({
        components: {
            OrderItineraryDayImages,
            ItineraryInfo,
            TotalOrderPrices,
            InvoicesPayModal,
            CancelServiceModal,
            ConditionsModal,
            FlightService,
            AccommodationService,
            TransferService,
            ActivityService,
            PackageInfo: () => import('~/components/account/orderServices/PackageInfo'),
            PackageTourInfo: () => import('~/components/account/orderServices/PackageTourInfo'),
            ExtraserviceService: () => import('~/components/account/orderServices/ExtraserviceService'),
            InvoicesModal,
            OrderPrice,
            ModifyModal,
            IssueTicketModal,
            ChatModal,
            VPhotoSwipe,
        },
    })
    export default class OrderDetails extends Vue {
        snackbar = {
            show: false,
            message: '',
        }
        showOnMobile = false
        loadItinerary = false
        orderTitleEdit = false
        orderTitle = ''
        itineraryView = false
        orderItinerary = {}
        photoSwipeIsOpen = false
        photoSwipeOptions = {index: 0}
        photoSwipeItems = 0
        itineraryLink = ''

        async mounted() {
            if ((this.isCorporateEmployee || this.isInternararyView) && this.itineraryViewServices.length) {
                this.itineraryView = true
            }
            if (this.itineraryView) {
                await this.loadOrderItinerary()
            }
        }

        async getItinerary() {
            this.loadItinerary = true
            try {
                const data = await this.$api.itinerary.get({
                    orderId: this.order.orderId,
                })
                downloadData(data, `Itinerary-${this.order.orderId}.pdf`)
            } catch (error) {
                this.$toast.error(error.errors[0].message)
            } finally {
                this.loadItinerary = false
            }
        }

        async openItineraryView(param) {
            persistentStore.SET_INTERNERARY_VIEW(true)
            this.itineraryView = param
            await this.loadOrderItinerary()
        }

        async loadOrderItinerary() {
            const orderId = this.order.orderId
            try {
                await Promise.all([
                    (async () => {
                        this.orderItinerary = await this.$api.orderItinerary.get({orderId})
                    })(),
                    /*(async () => {
                        const key = await this.$api.itineraryKey.get(orderId)
                        this.itineraryLink =
                            window.location.origin +
                            this.$router.resolve({
                                name: 'order-details',
                                params: {id: orderId},
                                query: {key},
                            }).href
                    })(),*/
                ])
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        addService() {
            persistentStore.SET_SELECTED_ORDER_ID(this.order.orderId)
            this.$router.push({name: 'home'})
        }

        async saveOrderTitle() {
            this.orderTitleEdit = false
            const data = {title: this.orderTitle}
            try {
                await this.$api.orders.put(this.order.orderId, data)
                runtimeStore.SET_ORDER_DETAILS({...this.order, ...data})
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        get gotPayAtHotelService() {
            if (this.order.services && this.order.services.length === 1) {
                return this.order.services.some(service =>
                    service.serviceDetailsContainer.accommodation
                        ? service.serviceDetailsContainer.accommodation.cardGuarantee
                        : false
                )
            }
            return false
        }

        get order() {
            return runtimeStore.orderDetails
        }

        get minDate() {
            if (this.order.services) {
                const dates = this.order.services.map(service => {
                    return new Date(service.startDateTime.slice(0, 10)).getTime()
                })
                return dates.sort()[0]
            }
            return null
        }

        get maxDate() {
            if (this.order.services) {
                const dates = this.order.services.map(service => {
                    return new Date(service.endDateTime.slice(0, 10)).getTime()
                })
                return dates.sort()[dates.length - 1]
            }
            return null
        }

        get managerName() {
            if (this.order.manager && this.order.manager.lastName) {
                return `${this.order.manager.prefix} ${this.order.manager.lastName}`
            }
            return null
        }

        get tripOrganaizerName() {
            if (this.order.tripOrganizer && this.order.tripOrganizer.firstName) {
                return `${this.order.tripOrganizer.prefix} ${this.order.tripOrganizer.firstName} ${this.order.tripOrganizer.lastName}`
            }
            return null
        }

        get tripAgentName() {
            if (this.order.agent && this.order.agent.firstName) {
                return `${this.order.agent.prefix} ${this.order.agent.firstName} ${this.order.agent.lastName}`
            }
            return null
        }

        get clientName() {
            if (this.order.clientPerson) {
                return `${this.order.clientPerson.prefix} ${this.order.clientPerson.firstName} ${this.order.clientPerson.lastName}`
            }
            return ''
        }

        get orderStatus() {
            if (!this.order.status) return null
            return this.$t(`order_statuses.${this.order.status.toUpperCase().replace(/[\s.]/g, '_')}`)
        }

        get nightsQty() {
            if (!this.maxDate && !this.minDate) return null
            return Math.ceil((this.maxDate - this.minDate) / 8.64e7)
        }

        orderPrices(type = 'CLIENT') {
            return this.order.orderPrices.find(orderPrice => orderPrice.type === type).orderPrices
        }

        showInvoices(invoiceType) {
            EventBus.$emit(SHOW_INVOICES_MODAL_EVENT, {
                orderId: this.order.orderId,
                objectId: this.order.objectId,
                invoiceType: invoiceType,
                order: this.order,
            })
        }

        serviceComponent(serviceType) {
            if (serviceType === 'OWNCHARTER') {
                serviceType = 'FLIGHT'
            } else if (serviceType === 'OWNEXTRASERVICE') {
                serviceType = 'EXTRASERVICE'
            }
            return `${serviceType.toLowerCase()}-service`
        }

        get gotMoreTwoServices() {
            return this.order.services?.length >= 2
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isCorporateEmployee() {
            return authStore.isCorporate && authStore.user.isEmployee && this.gotMoreTwoServices
        }

        get isTourOperator() {
            return authStore.isTourOperator
        }

        get isSupplier() {
            return authStore.companyType === 'INTERNAL_SUPPLIER'
        }

        get isGuest() {
            return authStore.isGuest
        }

        get supplierPaymentStatus() {
            return this.order.supplierPaymentStatus
        }

        get customerPaymentStatus() {
            return this.order.customerPaymentStatus
        }

        get statusColor() {
            return runtimeStore.orderStatusColor(this.order.status)
        }

        async downloadVouchers() {
            this.order.services.map(async service => {
                await this.downloadVoucher(service)
            })
        }

        async downloadVoucher(service) {
            try {
                const data = await this.$api.voucher.get({
                    processId: service.processId.replace(/^.{6}/, ''),
                })
                downloadData(data, `${service.serviceName}.pdf`)
            } catch (error) {
                this.$toast.error(error.errors[0].message)
            }
        }

        get canDownloadVouchers() {
            return false
        }

        get canPayOrder() {
            return (
                authStore.isAgency &&
                ['COMPLETED', 'IN_PROGRESS'].includes(this.order.status) &&
                !['PAID', 'PARTLY_PAID'].includes(this.order.supplierPaymentStatus)
            )
        }

        get statusForAgency() {
            return this.isAgency
                ? this.gotPayAtHotelService
                    ? this.$t('paymentStatus.PAY_AT_HOTEL')
                    : this.$t(`paymentStatus.${this.supplierPaymentStatus}`)
                : this.gotPayAtHotelService
                ? this.$t('paymentStatus.PAY_AT_HOTEL')
                : this.$t(`paymentStatus.${this.customerPaymentStatus}`)
        }

        get tourLead() {
            const tourLead =
                this.order && this.order.services && this.order.services[0].travelers.find(t => t.isTourLead === true)
            return tourLead
                ? `${tourLead.prefix} ${tourLead.name[0].firstName} ${tourLead.name[0].lastName} (${tourLead.citizenshipName})`
                : false
        }

        get isInternararyView() {
            return persistentStore.isInternararyView
        }

        get itineraryViewServices() {
            const showService = service =>
                    !['ERROR', 'CANCELED', 'REJECTED'].includes(runtimeStore.orderServiceStatus(service.status)),
                buildItineraryForFlights = () => {
                    let segments,
                        rebuildServiceSegments = []
                    this.order.services
                        .filter(s => showService(s))
                        .forEach(service => {
                            if (service.serviceType === 'FLIGHT') {
                                segments = service.serviceDetails[0].itineraries.map(
                                    ({flightSegments}) => flightSegments
                                )
                                segments = segments.flat()
                                rebuildServiceSegments = rebuildServiceSegments.concat(
                                    segments.map((segment, index) => {
                                        return {
                                            ...segment,
                                            ...service,
                                            startDateTime: segment.departureDate,
                                            endDateTime: segment.arrivalDate,
                                            firstFlightSegment: index === 0,
                                        }
                                    })
                                )
                            }
                        })
                    return this.buildItineraryForAccommodation(rebuildServiceSegments)
                }
            if (this.order.services) {
                const services = clone(this.order.services).filter(service => showService(service))
                services.forEach(s => (s.icon = this.getServiceTypeIcon(s.serviceType)))
                const restServices = this.buildItineraryForAccommodation(
                    services.filter(s => s.serviceType !== 'FLIGHT' && showService(s))
                )
                const dateTimeFlights = buildItineraryForFlights(
                    services.filter(s => s.serviceType === 'FLIGHT' && showService(s))
                )
                const uniqKeys = Array.from(new Set([...Object.keys(restServices), ...Object.keys(dateTimeFlights)]))
                const dirtyResult = {...restServices, ...dateTimeFlights}
                uniqKeys.forEach(key => {
                    if (restServices[key] && dateTimeFlights[key]) {
                        dirtyResult[key].times = dirtyResult[key].times.concat(restServices[key].times)
                    }
                })
                const result = Object.values(dirtyResult)
                result.forEach(date => {
                    date.times.sort((a, b) => {
                        return a.time > b.time ? 1 : -1
                    })
                })
                const sortByDate = (a, b) => {
                    return this.$dateFns.isAfter(new Date(a.date), new Date(b.date)) ? 1 : -1
                }
                return result.sort(sortByDate)
            }
            return []
        }

        getServiceTypeIcon(serviceType) {
            switch (serviceType) {
                case 'ACCOMMODATION':
                    return 'mdi-office-building'
                case 'FLIGHT':
                    return 'mdi-airplane'
                case 'OWNCHARTER':
                    return 'mdi-airplane-takeoff'
                case 'CARRENT':
                    return 'mdi-car'
                case 'ACTIVITY':
                    return 'mdi-lightning-bolt-outline'
                case 'TRANSFER':
                    return 'mdi-bus'
                case 'EXTRASERVICE':
                case 'OWNEXTRASERVICE':
                    return 'mdi-domain-plus'
                case 'PACKAGE_TOUR':
                    return 'mdi-gift-outline'
                case 'DYNAMIC_PACKAGE':
                    return 'mdi-wallet-travel'
                case 'TRAIN':
                    return 'mdi-train'
                case 'INSURANCE':
                    return 'mdi-file-document-outline'
            }
        }

        buildItineraryForAccommodation(services) {
            const createNewProp = (dateTime, sourceDateTime, service, firstPart) => {
                dateTime[sourceDateTime[0]] = {
                    date: sourceDateTime[0],
                    times: [
                        {
                            type: {
                                text:
                                    service.serviceType === 'ACCOMMODATION'
                                        ? () => {
                                              return firstPart ? this.$t('check_in') : this.$t('check_out')
                                          }
                                        : null,
                                textFlight:
                                    service.serviceType === 'FLIGHT'
                                        ? () => {
                                              return firstPart
                                                  ? this.$t('flights.departure')
                                                  : this.$t('flights.arrival')
                                          }
                                        : null,
                                start: !!firstPart && service.firstFlightSegment !== false,
                            },
                            time: sourceDateTime[1],
                            service: [service],
                        },
                    ],
                }
            }
            const addToPropNewTime = (dateTime, sourceDateTime, service, firstPart) => {
                dateTime[sourceDateTime[0]].times.push({
                    type: {
                        text:
                            service.serviceType === 'ACCOMMODATION'
                                ? () => {
                                      return firstPart ? this.$t('check_in') : this.$t('check_out')
                                  }
                                : null,
                        textFlight:
                            service.serviceType === 'FLIGHT'
                                ? () => {
                                      return firstPart ? this.$t('flights.departure') : this.$t('flights.arrival')
                                  }
                                : null,
                        start: !!firstPart,
                    },
                    time: sourceDateTime[1],
                    service: [service],
                })
            }
            const addToExistTime = (dateTime, sourceDateTime, service, firstPart, index) => {
                dateTime[sourceDateTime[0]].times[index].service.push(service)
            }
            const getNormalizedStartTime = (start, service) => {
                if (service.serviceType === 'TRANSFER') {
                    start[1] = service.serviceDetailsContainer.transfer.pickupTime
                        ? service.serviceDetailsContainer.transfer.pickupTime
                        : start[1]
                }

                if (service.serviceType === 'ACTIVITY') {
                    start[1] = service.serviceDetails[0].pickupTime ? service.serviceDetails[0].pickupTime : start[1]
                }
            }
            const dateTime = {}
            services.forEach(service => {
                const start = service.startDateTime.split(' ')
                getNormalizedStartTime(start, service)
                const end = service.endDateTime.split(' ')
                if (!dateTime[start[0]]) {
                    createNewProp(dateTime, start, service, true)
                } else {
                    const gotSameTimeStart = dateTime[start[0]].times.findIndex(time => time.time === start[1])
                    if (gotSameTimeStart > -1) {
                        addToExistTime(dateTime, start, service, true, gotSameTimeStart)
                    } else {
                        addToPropNewTime(dateTime, start, service, true)
                    }
                }
                if (end[1] !== '00:00') {
                    const serviceEndCopy = clone(service)
                    serviceEndCopy.end = true
                    if (!dateTime[end[0]]) {
                        createNewProp(dateTime, end, serviceEndCopy, false)
                    } else {
                        const gotSameTimeEnd = dateTime[end[0]].times.findIndex(time => time.time === end[1])
                        if (gotSameTimeEnd > -1) {
                            addToExistTime(dateTime, end, serviceEndCopy, false, gotSameTimeEnd)
                        } else {
                            addToPropNewTime(dateTime, end, serviceEndCopy, false)
                        }
                    }
                }
            })
            return dateTime
        }

        payOrder() {
            EventBus.$emit(PAY_ORDER_EVENT, {orderId: this.order.orderId, objectId: this.order.objectId})
        }

        itineraryDay(index) {
            return this.orderItinerary.itineraryDays?.find(({number}) => number === index + 1)
        }

        itineraryDayImages(dayIndex) {
            return this.orderItinerary.images?.filter(({dayNumber}) => dayNumber === dayIndex + 1)
        }

        showPhotoSwipe({images, index}) {
            this.photoSwipeItems = images.map(({url, linkedEntityName}) => ({
                src: this.$options.filters.imageUrl(url, 800),
                w: 0,
                h: 0,
                title: linkedEntityName,
            }))
            this.photoSwipeOptions.index = index
            this.photoSwipeIsOpen = true
        }
    }
</script>

<style lang="scss" scoped>
    @import '~vuetify/src/styles/settings/_variables.scss';

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
        .time {
            width: 90px;
        }
    }

    .order-wrapper {
        border: 3px solid rgb(52, 150, 243);
        border-radius: 5px;
        margin-bottom: 10px;

        & .services {
            padding: 10px;
        }
    }

    @media (min-width: 991px) {
        .order-summary {
            position: sticky;
            position: -webkit-sticky;
            top: 0;
        }
    }

    .view-summary-button {
        position: sticky;
        bottom: 0;
        z-index: 200;
    }

    .order-summary::v-deep .commission-block {
        font-size: 12px !important;
    }
</style>
