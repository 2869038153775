<template>
    <div>
        <v-select
            v-if="autocompleteDisabled"
            ref="option"
            v-model="value"
            :name="name"
            :items="items"
            :label="$attrs.label"
            :counter="$attrs.counter"
            item-text="name"
            :small-chips="$attrs.multiple"
            :search-input.sync="search"
            deletable-chips
            item-value="value"
            :single-line="singleLine"
            :outlined="outlined"
            hide-details
            :multiple="$attrs.multiple"
            :clearable="clearable"
            light
            dark
            @change="$refs.option.blur()"
        />
        <v-autocomplete
            v-else
            ref="option"
            v-model="value"
            :name="name"
            :items="items"
            :label="$attrs.label"
            :counter="$attrs.counter"
            item-text="name"
            :small-chips="$attrs.multiple"
            :search-input.sync="search"
            deletable-chips
            item-value="value"
            :single-line="singleLine"
            :outlined="outlined"
            hide-details
            :multiple="$attrs.multiple"
            :clearable="clearable"
            light
            dark
            @change="$refs.option.blur()"
        />
    </div>
</template>

<script>
    import {Component, Vue, Prop, Watch} from 'nuxt-property-decorator'

    @Component
    export default class SelectOption extends Vue {
        @Prop({default: false, type: Boolean}) autocompleteDisabled
        @Prop({default: false, type: Boolean}) outlined
        @Prop({default: true}) singleLine
        @Prop({default: true}) clearable
        @Prop({required: true}) name
        @Prop({default: () => []}) items
        @Prop() defaultValue

        value = null
        search = ''

        @Watch('value')
        onValueChange() {
            this.search = ''
        }

        created() {
            this.value = this.$route.query[this.name] || this.defaultValue
        }
    }
</script>

<style scoped>
    ::v-deep .v-list-item__title {
        font-size: 14px;
    }

    ::v-deep input {
        pointer-events: none;
    }
</style>
