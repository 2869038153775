<template>
    <client-only>
        <photo-swipe
            :is-open="photoSwipeIsOpen"
            :items="items"
            :options="photoSwipeOptions"
            @gettingData="gettingData"
            @close="photoSwipeIsOpen = false"
        />
    </client-only>
</template>

<script>
    import {Vue, Component, Prop, PropSync} from 'nuxt-property-decorator'

    const components = {}
    if (process.client) {
        const {PhotoSwipe} = require('v-photoswipe')
        components.PhotoSwipe = PhotoSwipe
    }

    @Component({
        components,
    })
    export default class VPhotoSwipe extends Vue {
        @PropSync('isOpen') photoSwipeIsOpen
        @Prop({required: true}) items
        @Prop({default: () => ({index: 0})}) photoSwipeOptions

        gettingData(data) {
            const item = data[2],
                pswp = data[0].pswp
            if (item.src && (item.w < 1 || item.h < 1)) {
                // unknown size
                const img = new Image()
                img.onload = function() {
                    // will get size after load
                    item.w = this.width // set image width
                    item.h = this.height // set image height
                    //pswp.invalidateCurrItems(); // reinit Items
                    pswp.updateSize(true) // reinit Items
                }
                img.src = item.src // let's download image
            }
        }
    }
</script>
