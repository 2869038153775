<template>
    <div v-if="locales.length > 1" class="language-switcher" :class="$breakpoint.mdAndUp ? 'ma-2' : 'full-width mx-4'">
        <v-menu v-model="menu" :close-on-content-click="false" offset-y>
            <template v-slot:activator="{on}">
                <v-btn text block class="justify-space-between" v-on="on">
                    <span>
                        <span class="flag-icon me-2" :class="`flag-icon-${flagIconCode(locale)}`" />
                        {{ $t('lang') }}
                    </span>
                    <v-icon right>
                        mdi-menu-down
                    </v-icon>
                </v-btn>
            </template>
            <v-list dense>
                <v-list-item
                    v-for="code in locales"
                    :key="code"
                    @click="
                        switchLanguage(code)
                        menu = false
                    "
                >
                    <v-list-item-icon>
                        <span class="flag-icon" :class="`flag-icon-${flagIconCode(code)}`" />
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t(`languages.${code}`) }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'
    import {runtimeStore} from '~/store'
    import 'flag-icon-css/css/flag-icon.css'
    import {CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'
    import {axiosInstance} from '@/utils/axios-accessor'

    @Component
    export default class LanguageSwitcher extends Vue {
        menu = false

        switchLanguage(localeCode) {
            this.$cookies.set('locale', localeCode)
            axiosInstance.setHeader('Accept-Language', localeCode)
            this.$i18n.locale = localeCode
            this.$vuetify.lang.current = localeCode
            this.$vuetify.rtl = localeCode === 'ar'
            EventBus.$emit(CHANGE_LOCALE_EVENT, localeCode)
        }

        flagIconCode(code) {
            switch (code) {
                case 'en':
                    return 'gb'
                case 'ar':
                    return 'sa'
                default:
                    return code
            }
        }

        get locales() {
            return Object.values(runtimeStore.config.locales)
        }

        get locale() {
            return this.$i18n.locale
        }
    }
</script>

<style>
    .language-switcher {
        display: flex;
        padding: 1rem;
    }

    .languages {
        display: flex;
        justify-content: flex-end;
    }

    .language {
        padding-left: 0.25rem;
        cursor: pointer;
    }

    .language.active {
        text-decoration: underline;
    }

    .language:hover span {
        text-decoration: underline;
    }

    .language:not(:last-child):after {
        content: '|';
        padding-left: 0.25rem;
    }

    @media (max-width: 991px) {
        .language-switcher {
            padding: 0;
        }
    }
</style>
