<template>
    <div v-if="tourists.length">
        <v-form ref="form" v-model="valid">
            <div v-for="(tourist, touristIndex) in tourists" :key="tourist.personId">
                <v-row>
                    <v-col cols="12" :md="ownProduct ? 6 : 12">
                        <v-row class="align-end text-center">
                            <v-col cols="12" md="3" class="d-md-flex align-end">
                                <div class="me-2 text-no-wrap">
                                    <v-icon color="black" small>
                                        mdi-account-multiple
                                    </v-icon>
                                    <span class="font-weight-bold"> {{ touristIndex + 1 }}: </span>
                                </div>
                                <v-select
                                    v-model="tourists[touristIndex].prefix"
                                    :items="['Mr', 'Ms', 'Mrs']"
                                    :label="`${$t('salutation')}`"
                                    :rules="
                                        validationRules('salutation', [getRequiredRule(touristIndex, 'salutation')])
                                    "
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    v-model="tourists[touristIndex].firstName"
                                    :label="`${$t('first_name')}`"
                                    :rules="
                                        validationRules('firstName', [
                                            latinRule,
                                            getRequiredRule(touristIndex, 'firstName'),
                                        ])
                                    "
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    v-model="tourists[touristIndex].lastName"
                                    :label="`${$t('last_name')}`"
                                    :rules="
                                        validationRules('lastName', [
                                            latinRule,
                                            getRequiredRule(touristIndex, 'lastName'),
                                        ])
                                    "
                                    hide-details
                                />
                            </v-col>
                            <v-col v-if="ownProduct" cols="12" md="3">
                                <v-autocomplete
                                    v-model="tourists[touristIndex].citizenshipId"
                                    :label="$t('citizenship')"
                                    :items="countries"
                                    item-text="name"
                                    item-value="id"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="ownProduct" cols="12" md="6">
                        <v-row class="align-end text-center">
                            <v-col cols="12" md="3">
                                <date-picker
                                    v-model="tourists[touristIndex].birthdate"
                                    :label="$t('birthdate')"
                                    :max="$dateFns.format(new Date())"
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="tourists[touristIndex].passport.number"
                                    :label="`${$t('document_number')}`"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="12" md="3">
                                <date-picker
                                    v-model="tourists[touristIndex].passport.expiryDate"
                                    :label="$t('expire_date')"
                                    :min="$dateFns.format(new Date())"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class="mt-8 mb-4" />
            </div>
        </v-form>
    </div>
</template>

<script>
    import {Component, mixins, PropSync, Prop} from 'nuxt-property-decorator'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import {runtimeStore} from '@/utils/store-accessor'
    import DatePicker from '@/components/snippets/forms/DatePicker'
    import {clone} from '@/utils/helpers'

    @Component({
        components: {DatePicker},
    })
    export default class ModifyTravelers extends mixins(FormValidationRulesMixin) {
        @PropSync('_tourists', {default: () => []}) tourists
        @PropSync('_valid', {default: true}) valid
        @Prop({default: false, type: Boolean}) ownProduct

        initialTourists = []

        beforeMount() {
            this.initialTourists = clone(this.tourists)
        }

        getRequiredRule(touristIndex, prop) {
            return this.initialTourists[touristIndex][prop] ? this.requiredRule : () => true
        }

        get countries() {
            return runtimeStore.countries
        }
    }
</script>
