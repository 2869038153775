<template>
    <v-card v-if="offer" outlined>
        <v-card-title>
            {{ $t('flights.flight_details') }}
        </v-card-title>
        <v-card-text>
            <flight-itinerary v-for="(itinerary, index) in offer.itinerary" :key="index" :itinerary="itinerary" />
            <v-dialog v-model="detailModal" max-width="600px" :fullscreen="$breakpoint.smAndDown">
                <template v-slot:activator="{on}">
                    <v-btn text color="info" small v-on="on">
                        {{ $t('flights.view_details') }}
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title class="d-flex justify-end">
                        <v-icon @click="detailModal = false">
                            mdi-close
                        </v-icon>
                    </v-card-title>
                    <flight-detail :offer="offer" />
                </v-card>
            </v-dialog>
            <fare-rules :offer-key="offer.offerKey" />
            <flight-conditions-label
                :offer-key="offer.offerKey"
                :price="offer.price"
                :refundable="offer.refundable"
                :regular="offer.regular"
                @conditions="showConditionsInfo"
            />
            <div v-if="extraBaggageCount" class="d-flex mt-5">
                <span class="caption">{{ $t('flights.extra_baggage') }}</span>
                <strong class="ml-auto pl-2">
                    {{ extraBaggageCount }} {{ $tc('flights.baggage_item', extraBaggageCount) }}
                </strong>
            </div>
            <div v-if="seatsCount" class="d-flex mt-5">
                <span class="caption">{{ $t('flights.selected_seats') }}</span>
                <strong class="ml-auto pl-2">{{ seatsCount }} {{ $tc('flights.seat', seatsCount) }}</strong>
            </div>
        </v-card-text>
        <v-divider />
        <original-currency-notification :price="totalPrice" />
        <v-card-actions>
            <v-spacer />
            <span class="text-h6">{{ $t('total') }}: {{ totalPrice | price }}</span>
        </v-card-actions>
        <conditions-modal>
            <template v-slot:info>
                <flight-conditions-modal-info />
            </template>
        </conditions-modal>
    </v-card>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import FlightItinerary from '@/components/flights/snippets/FlightItinerary'
    import {flightsRuntimeStore, flightsStore, persistentStore} from '~/store'
    import FareRules from '~/components/flights/FareRules'
    import FlightConditionsLabel from '@/components/flights/snippets/FlightConditionsLabel'
    import ConditionsModal from '@/components/modals/ConditionsModal'
    import {EventBus, SHOW_FLIGHT_CONDITIONS_INFO} from '@/utils/event-bus'
    import FlightConditionsModalInfo from '@/components/flights/snippets/FlightConditionsModalInfo'
    import FlightDetail from '~/components/flights/search/offers/FlightDetail'
    import CorporatePolicyViolation from '~/components/snippets/CorporatePolicyViolation'
    import OriginalCurrencyNotification from '@/components/booking/snippets/OriginalCurrencyNotification'

    @Component({
        components: {
            OriginalCurrencyNotification,
            FlightConditionsModalInfo,
            ConditionsModal,
            FlightConditionsLabel,
            FlightItinerary,
            FlightDetail,
            FareRules,
            CorporatePolicyViolation,
        },
    })
    export default class FlightOrderSummary extends Vue {
        @Prop({required: true}) offer
        @Prop({default: true}) prebooked

        detailModal = false

        showConditionsInfo() {
            EventBus.$emit(SHOW_FLIGHT_CONDITIONS_INFO, this.offer)
        }

        get violatedPolicies() {
            return this.offer.corporatePolicy
        }

        get corporatePolicyViolated() {
            return this.offer.corporatePolicyViolation
        }

        get currency() {
            return persistentStore.currency
        }

        get extraBaggagePrice() {
            const extraBaggagePrice = {amount: 0, currency: persistentStore.currency, originalAmount: 0}
            if (!flightsStore.prepareBookRequest.extraBaggage) return extraBaggagePrice
            return flightsStore.prepareBookRequest.extraBaggage.reduce((segmentTotalPrice, extraBaggage) => {
                const extraBaggageOption = flightsRuntimeStore.extraBaggageOptions.find(
                    extraBaggageOption => extraBaggage.serviceRPH === extraBaggageOption.serviceRPH
                )
                if (!extraBaggageOption || !extraBaggageOption.price) return segmentTotalPrice
                segmentTotalPrice.amount +=
                    this.$options.filters.convertPrice(extraBaggageOption.price).amount * extraBaggage.quantity

                const originalAmount = extraBaggageOption.price.originalAmount || extraBaggageOption.price.amount,
                    originalCurrency = extraBaggageOption.price.originalCurrency || extraBaggageOption.price.currency
                if (!segmentTotalPrice.originalCurrency) {
                    segmentTotalPrice.originalCurrency = originalCurrency
                }
                if (segmentTotalPrice.originalCurrency === originalCurrency) {
                    segmentTotalPrice.originalAmount += originalAmount * extraBaggage.quantity
                }

                return segmentTotalPrice
            }, extraBaggagePrice)
        }

        get seatsPrice() {
            const seatsPrice = {amount: 0, currency: persistentStore.currency, originalAmount: 0}
            if (
                !flightsStore.prepareBookRequest.seats ||
                !flightsStore.prepareBookRequest.tourists ||
                flightsStore.prepareBookRequest.offerKey !== this.offer.offerKey
            )
                return seatsPrice
            return flightsStore.prepareBookRequest.seats.reduce(
                (seatsPrice, {rowNumber, seatNumber, touristIndex, segmentRPH}) => {
                    if (!flightsRuntimeStore.flightSeatsMap[segmentRPH]) return seatsPrice
                    flightsRuntimeStore.flightSeatsMap[segmentRPH].forEach(seatsCabinClass => {
                        const seatRow = seatsCabinClass.seatRows.find(seatRow => seatRow.rowNumber === +rowNumber)
                        if (seatRow) {
                            const seat = seatRow.seats.find(seat => seat.seatNumber === seatNumber)
                            if (seat) {
                                const passengerType =
                                    flightsStore.prepareBookRequest.tourists[touristIndex].type === 'adult'
                                        ? 'ADT'
                                        : 'CHD'
                                const passengerFare = seat.passengerFares.find(
                                    passengerFare => passengerFare.passengerType === passengerType
                                )
                                if (passengerFare) {
                                    seatsPrice.amount += this.$options.filters.convertPrice(passengerFare.price).amount

                                    const originalAmount =
                                            passengerFare.price.originalAmount || passengerFare.price.amount,
                                        originalCurrency =
                                            passengerFare.price.originalCurrency || passengerFare.price.currency
                                    if (!seatsPrice.originalCurrency) {
                                        seatsPrice.originalCurrency = originalCurrency
                                    }
                                    if (seatsPrice.originalCurrency === originalCurrency) {
                                        seatsPrice.originalAmount += originalAmount
                                    }
                                }
                            }
                        }
                    })
                    return seatsPrice
                },
                seatsPrice
            )
        }

        get totalPrice() {
            const totalPrice = flightsStore.orderTotalPrice(this.offer, this.prebooked)
            if (this.prebooked) return totalPrice
            if (this.seatsPrice.amount) {
                totalPrice.amount += this.seatsPrice.amount
                if (this.seatsPrice.originalAmount && totalPrice.originalCurrency) {
                    if (this.seatsPrice.originalCurrency === totalPrice.originalCurrency) {
                        totalPrice.originalAmount += this.seatsPrice.originalAmount
                    } else {
                        delete totalPrice.originalCurrency
                    }
                }
            }
            if (this.extraBaggagePrice.amount) {
                totalPrice.amount += this.extraBaggagePrice.amount
                if (this.extraBaggagePrice.originalAmount && totalPrice.originalCurrency) {
                    if (this.extraBaggagePrice.originalCurrency === totalPrice.originalCurrency) {
                        totalPrice.originalAmount += this.extraBaggagePrice.originalAmount
                    } else {
                        delete totalPrice.originalCurrency
                    }
                }
            }
            return totalPrice
        }

        get seatsCount() {
            return flightsStore.prepareBookRequest.seats ? flightsStore.prepareBookRequest.seats.length : 0
        }

        get extraBaggageCount() {
            return flightsStore.prepareBookRequest.extraBaggage
                ? flightsStore.prepareBookRequest.extraBaggage.reduce(
                      (count, extraBaggage) => count + extraBaggage.quantity,
                      0
                  )
                : 0
        }
    }
</script>
