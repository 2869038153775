<template>
    <div>
        <v-row v-if="mandatoryExtraServices.length">
            <v-col cols="12">
                <h4 class="text-subtitle-1 font-weight-bold">
                    {{ $t('extra_services_included') }}
                </h4>
            </v-col>
        </v-row>
        <v-row
            v-for="(extraService, extraServiceIndex) in mandatoryExtraServices"
            :key="`${extraServiceIndex}-${extraService.serviceRPH}`"
        >
            <extra-service :extra-service="extraService" :hide-action="true" />
        </v-row>
        <v-row v-if="extraServices.length">
            <v-col cols="12">
                <h4 class="text-subtitle-1 font-weight-bold">
                    {{ $t('extra_services') }}
                </h4>
            </v-col>
        </v-row>
        <v-row
            v-for="(extraService, extraServiceIndex) in extraServices"
            :key="`${extraServiceIndex}-${extraService.serviceRPH}`"
        >
            <extra-service :extra-service="extraService" :hide-action="isDisabled" @update="setExtraService" />
        </v-row>
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import ExtraService from '@/components/booking/forms/fields/ExtraService'
    @Component({
        components: {ExtraService},
    })
    export default class ExtraServicesForm extends Vue {
        @Prop() roomIndex
        @Prop() pStore
        @Prop({default: false}) isDisabled

        get extraServices() {
            if (!this.pStore.bookingAdditionalOptions.extraServices) {
                return []
            }
            return (
                this.pStore.bookingAdditionalOptions.extraServices.filter(extraService => !extraService.mandatory) || []
            )
        }

        get mandatoryExtraServices() {
            if (!this.pStore.bookingAdditionalOptions.extraServices) {
                return []
            }
            return (
                this.pStore.bookingAdditionalOptions.extraServices.filter(extraService => extraService.mandatory) || []
            )
        }

        setExtraService(data) {
            const type = `extra-service-${data.service.serviceRPH}`
            if (!data.operation) {
                this.pStore.DELETE_EXTRA_SERVICE(type)
            } else {
                this.pStore.SET_BOOKING_EXTRA_SERVICE({
                    type,
                    name: data.service.name,
                    serviceRPH: data.service.serviceRPH,
                    quantity: parseInt(data.qty),
                    price: data.service.salesTerms.find(salesTerm => salesTerm.type === 'CLIENT'),
                    mandatory: data.service.mandatory,
                })
            }
            this.$emit('updateExtraServices')
        }
    }
</script>
