<template>
    <div>
        <breadcrumbs />
        <user-settings />
    </div>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'
    import Breadcrumbs from '~/components/Breadcrumbs'
    import UserSettings from '@/components/account/UserSettings'

    @Component({
        components: {
            UserSettings,
            Breadcrumbs,
        },
    })
    export default class SettingsPage extends Vue {}
</script>
