<template>
    <v-card class="my-2" outlined>
        <v-list-item three-line class="pl-0">
            <v-list-item-avatar class="my-0" size="125" tile>
                <v-img
                    tile
                    class="thumb"
                    :src="product.mainImage | imageUrl(200)"
                    :alt="product.info.name"
                    lazy-src="/placeholder.png"
                    @error="imgError = true"
                >
                    <template v-slot:placeholder>
                        <v-row
                            v-show="!imgError && product.mainImage"
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
            </v-list-item-avatar>
            <v-list-item-content class="align-self-start">
                <v-row dense no-gutters>
                    <v-col cols="9">
                        <v-list-item-title :title="product.info.name" class="wrap-words">
                            <nuxt-link no-prefetch :to="{name: 'activity', query: {name: product.info.name}}">
                                {{ product.info.name }}
                            </nuxt-link>
                        </v-list-item-title>
                        <v-list-item-subtitle
                            v-if="product.shortDescription"
                            class="short-description mt-2"
                            v-html="$options.filters.stripHtml(product.shortDescription)"
                        />
                    </v-col>
                    <v-col cols="3" class="text-right">
                        <activity-card-price-block :offer="minPriceOffer" />
                    </v-col>
                </v-row>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn text icon @click.stop="toggleOffers">
                    <v-icon v-if="!expanded">
                        mdi-chevron-down
                    </v-icon>
                    <v-icon v-else>
                        mdi-chevron-up
                    </v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
        <v-expand-transition>
            <div v-if="expanded">
                <activity-grouped-offers
                    :offers="product.offers"
                    :full-card="false"
                    :_group-by.sync="groupBy"
                    @sortSelected="selectedSortBy = $event"
                    @dateFilter="dateFilter = $event"
                />
                <template v-if="groupBy === 'none'">
                    <activity-offer-card
                        v-for="(offer, offerIndex) in sortedOffers"
                        :key="offerIndex"
                        :offer="offer"
                        :full-card="false"
                    />
                    <v-list-item
                        v-if="expanded === expandedLimit && product.offers.length > expandedLimit"
                        dense
                        class="d-flex justify-center"
                        @click.stop="expanded = product.offers.length"
                    >
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-list-item>
                </template>
            </div>
        </v-expand-transition>
    </v-card>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import DiscountLabel from '@/components/snippets/DiscountLabel'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import ActivityOfferCard from '@/components/activities/info/ActivityOfferCard'
    import ActivityGroupedOffers from '@/components/activities/search/offers/ActivityGroupedOffers'
    @Component({
        components: {
            ActivityGroupedOffers,
            CommissionAmount,
            DiscountLabel,
            ActivityOfferCard,
            ActivityCardPriceBlock: () => import('~src/components/activities/search/offers/activityCardPriceBlock.src'),
        },
    })
    export default class ActivityCard extends Vue {
        @Prop({default: () => []}) product

        groupBy = 'serviceName'
        expanded = 0
        imgError = false
        selectedSortBy = 'price_asc'
        dateFilter = null

        sortFns = {
            price_desc: (a, b) => a.price.amount - b.price.amount,
            price_asc: (a, b) => b.price.amount - a.price.amount,
        }

        get sortedOffers() {
            let offers = [...this.product.offers]
            if (this.dateFilter) {
                offers = offers.filter(offer => offer.date === this.dateFilter)
            }
            if (this.expandedLimit <= offers.length) {
                this.expanded = offers.length
            }

            return offers.sort(this.sortFns[this.selectedSortBy]).slice(0, this.expanded)
        }

        get expandedLimit() {
            return 3
        }

        get minPriceOffer() {
            return this.product.offers.reduce(
                (a, b) => (a.price.amount < b.price.amount ? a : b),
                this.product.offers[0]
            )
        }

        toggleOffers() {
            let expanded = 0
            if (!this.expanded) {
                expanded = this.expandedLimit
            }
            this.expanded = expanded
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .offer-price {
        width: 20%;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
        .offer-price {
            width: 25%;
        }
    }

    .wrap-words {
        white-space: normal;
    }

    .short-description {
        -webkit-line-clamp: 4;
    }
</style>
