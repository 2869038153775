<template>
    <div>
        <v-data-table :headers="headers" :items="templates" :loading="loading" disable-sort class="elevation-1">
            <template v-slot:item.edit="{item}">
                <v-btn nuxt :to="{name: 'emailEditor', params: {id: item.id}}" icon small color="primary">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import {adminRuntimeStore} from '@/store'
    import AdminNavigation from '~/components/admin/AdminNavigation'

    @Component({
        components: {
            AdminNavigation,
        },
        middleware: 'auth',
        layout: 'admin',
    })
    export default class EmailTemplatesPage extends Vue {
        loading = true

        async mounted() {
            await adminRuntimeStore.loadNotificationTemplates()
            this.loading = false
        }

        get headers() {
            return [
                {text: '#', value: 'id'},
                {text: this.$t('type'), value: 'notificationType'},
                {text: this.$t('edit'), value: 'edit'},
            ]
        }

        get templates() {
            return adminRuntimeStore.notificationTemplates
        }
    }
</script>
