<template>
    <v-navigation-drawer permanent expand-on-hover absolute>
        <!--v-list>
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon>
                        mdi-cog
                    </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title class="title">{{ $t('admin.settings') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-divider /-->
        <v-list nav dense>
            <v-list-item :to="{name: 'emailTemplates'}">
                <v-list-item-icon>
                    <v-icon>mdi-email</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('admin.email_templates') }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'

    @Component
    export default class AdminNavigation extends Vue {}
</script>
