<template>
    <search-form-layout :popup="popup" title="activities.activities" @close-popup="$emit('close-popup')">
        <template v-slot:form>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col md="4" cols="12">
                        <city-autocomplete
                            v-model="city"
                            single-line
                            filled
                            :rules="[v => !!v || `${$t('destination')} ${$t('validation.required')}`]"
                            :default-cities="popularDestinations"
                            product-type="ACTIVITY"
                        />
                    </v-col>
                    <v-col md="4" cols="12">
                        <daterange-m :start-date-prop.sync="startDate" :end-date-prop.sync="endDate" />
                    </v-col>
                    <v-col md="2" cols="12">
                        <tourists-select-menu
                            :_adults.sync="adults"
                            :_children-ages.sync="childrenAges"
                            token="guest"
                        />
                    </v-col>
                    <v-col class="hidden-sm-and-down" md="2" cols="12">
                        <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                            {{ $t('search') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <additional-options-wrapper ref="addForm" :options="['activity-type', 'promocode', 'citizenship']" />
            <v-row class="hidden-md-and-up">
                <v-col md="2" cols="12">
                    <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                        {{ $t('search') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </search-form-layout>
</template>

<script>
    import {Component, Prop, Mixins} from 'vue-property-decorator'
    import CityAutocomplete from '~/components/search/forms/CityAutocomplete'
    import {cmsStore, activitiesStore, activitiesRuntimeStore, authStore} from '@/store'
    import SearchFormLayout from '~/components/parts/SearchFormLayout'
    import TouristsSelectMenu from '~src/components/search/forms/touristsSelectMenu.src'
    import AdditionalSearchOptionsMixin from '@/mixins/AdditionalSearchOptionsMixin'
    import AdditionalOptionsWrapper from '~src/components/snippets/AdditionalSearchOptions/additionalOptionsWrapper.src'
    import DaterangeM from '~src/components/search/forms/daterangeM.src'
    import {CHANGE_TAB_MOBILE_EVENT, CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'

    @Component({
        components: {
            TouristsSelectMenu,
            CityAutocomplete,
            DaterangeM,
            SearchFormLayout,
            AdditionalOptionsWrapper,
        },
    })
    export default class ActivitySearchForm extends Mixins(AdditionalSearchOptionsMixin) {
        @Prop({default: false}) popup
        @Prop() cityId

        city = {}
        startDate = null
        endDate = null
        adults = 2
        childrenAges = []
        valid = false

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, this.reload)
        }

        async reload() {
            if (this.city.id) {
                try {
                    const {
                        cities: [city],
                    } = await this.$api.locations.get({id: this.city.id, limitCities: 1})
                    this.city = city
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
        }

        newQuery() {
            return {
                cityId: this.city.id,
                startDate: this.startDate,
                endDate: this.endDate,
                adults: this.adults,
                childrenAges: this.childrenAges,
            }
        }

        async search() {
            const query = this.newQuery()
            activitiesRuntimeStore.SET_CITY(this.city)
            this.$emit('close-popup')
            if (this.$route.name !== 'activities') {
                activitiesRuntimeStore.newSearch()
            }
            try {
                await this.$router.push({name: 'activities', query})
            } catch (e) {
                await activitiesRuntimeStore.search(activitiesStore.searchRequest)
            }
            EventBus.$emit(CHANGE_TAB_MOBILE_EVENT, 1)
        }

        async mounted() {
            await this.$store.restored
            if (this.cityId && activitiesRuntimeStore.city.id !== this.cityId) {
                await activitiesRuntimeStore.loadCity(this.cityId)
            }
            this.city = Object.keys(activitiesRuntimeStore.city).length ? activitiesRuntimeStore.city : null
            if (this.$dateFns.isAfter(this.$dateFns.parseISO(activitiesStore.searchRequest.startDate), new Date())) {
                this.startDate = activitiesStore.searchRequest.startDate
                this.endDate = activitiesStore.searchRequest.endDate
            } else {
                this.startDate = this.$dateFns.format(this.$dateFns.addDays(new Date(), 1))
                this.endDate = this.$dateFns.format(this.$dateFns.addDays(new Date(), 7))
            }
            //TODO Need refactoring with other products
            this.adults = authStore.user.isEmployee ? 1 : activitiesStore.searchRequest.adults
            this.childrenAges = authStore.user.isEmployee ? [] : [...activitiesStore.searchRequest.childrenAges]
        }

        //TODO Move to global
        get popularDestinations() {
            return cmsStore.popularDestinations
        }
    }
</script>
