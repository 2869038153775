<template>
    <div>
        <breadcrumbs class="mb-3" />
        <v-btn nuxt :to="{name: 'company-client-profile', params: {id: 0}}" class="primary my-4">
            {{ $t('new_corporate_client_profile') }}
        </v-btn>
        <companies-table :company-type="type" />
    </div>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'
    import Breadcrumbs from '@/components/Breadcrumbs'
    import CompaniesTable from '@/components/account/CompaniesTable'

    @Component({
        components: {
            Breadcrumbs,
            CompaniesTable,
        },
        middleware: 'auth',
    })
    export default class CompanyCorporateClientsPage extends Vue {
        type = ['CORPORATOR']
    }
</script>
