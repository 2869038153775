<template>
    <service-card :service="service" icon="mdi-lightning-bolt-outline" @showInfo="showInfo">
        <template v-slot:orderInfo>
            <order-info-base
                :service-check-in="service.startDateTime | dateTimeFormat"
                :service-check-out="
                    service.startDateTime !== service.endDateTime
                        ? $options.filters.dateTimeFormat(service.endDateTime)
                        : null
                "
                :service="service"
                :service-image="mainImage"
                :is-package="isPackage"
                :order-id="orderId"
                :object-id="objectId"
                @cancelService="$emit('cancelService', $event)"
                @modifyService="$emit('modifyService', $event)"
            >
                <template v-if="variant" v-slot:additionalInfo>
                    <small class="d-block hotel-address mt-2">
                        {{ variant }}
                    </small>
                </template>
                <div v-if="quantity" class="d-flex">{{ $t('quantity') }}: {{ quantity }}</div>
                <div class="mt-4" />
            </order-info-base>
        </template>
        <template v-slot:serviceInfo>
            <v-dialog v-model="modal" width="600" hide-overlay>
                <div class="modal-close-btn-block">
                    <v-icon class="v-card-close" @click="modal = false">
                        mdi-close
                    </v-icon>
                </div>
                <service-info-base :service="service" :info="info">
                    <template v-slot:serviceInfo>
                        <div v-if="info.productDescription" v-html="info.productDescription" />
                    </template>
                </service-info-base>
            </v-dialog>
        </template>
    </service-card>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import ServiceCard from '@/components/account/orderServices/ServiceCard'
    import ServiceInfoBase from '@/components/account/orderServices/ServiceInfoBase'

    @Component({
        components: {
            ServiceInfoBase,
            OrderInfoBase: () => import('~src/components/account/orderServices/orderInfoBase.src'),
            ServiceCard,
        },
    })
    export default class ExtraserviceService extends Vue {
        @Prop({required: true}) service
        @Prop({default: false}) isPackage
        @Prop({default: null}) orderId
        @Prop({default: null}) objectId

        info = {}

        modal = false

        showInfo() {
            this.modal = true
        }

        async mounted() {
            const processId = this.service.processId.replace(/^.{6}/, '')
            try {
                this.info = await this.$api.ownExtraServiceInfo.get({processId})
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        get mainImage() {
            if (this.info.images && this.info.images.length) {
                let image = this.info.images.find(el => el.mainImage)
                if (!image) {
                    image = this.info.images[0]
                }
                return image.url
            }
            return null
        }

        get quantity() {
            return this.service.serviceDetails.length ? this.service.serviceDetails[0].quantity : ''
        }

        get variant() {
            return this.service.serviceDetails.length ? this.service.serviceDetails[0].productVariantName : ''
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .v-dialog {
            background: #fff;
            padding: 20px;
        }
    }

    .modal-close-btn-block {
        position: relative;
        top: -15px;
        right: -15px;
    }
</style>
