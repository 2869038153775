<template>
    <div>
        <h1 class="title">
            {{ info.name }}
        </h1>
        <component
            :is="!$breakpoint.smAndDown ? 'product-gallery' : 'product-gallery-mobile'"
            :images-data="info.images"
            class="mb-5"
        />
        <template v-if="info.description">
            <h6 class="text-h6">
                {{ $t('description') }}
            </h6>
            <div v-html="info.description" />
        </template>
        <slot name="offerInfo" />
        <v-row v-if="info.includedInfo || info.notIncludedInfo" class="mb-5">
            <v-col v-if="info.includedInfo" cols="12" md="6">
                <h6 class="text-body-1 text-uppercase">
                    {{ $t('included') }}
                </h6>
                <v-divider class="mb-2" />
                <v-card outlined color="green lighten-3">
                    <v-card-text class="green--text text--darken-3" v-html="info.includedInfo" />
                </v-card>
            </v-col>
            <v-col v-if="info.notIncludedInfo" cols="12" md="6">
                <h6 class="text-body-1 text-uppercase">
                    {{ $t('not_included') }}
                </h6>
                <v-divider class="mb-2" />
                <v-card outlined color="red lighten-3">
                    <v-card-text class="red--text text--darken-3" v-html="info.notIncludedInfo" />
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import ProductGallery from '@/components/info/ProductGallery'
    import ProductGalleryMobile from '@/components/info/ProductGalleryMobile'

    @Component({
        components: {ProductGallery, ProductGalleryMobile},
    })
    export default class ItineraryInfo extends Vue {
        @Prop({required: true}) info
    }
</script>
