<template>
    <div>
        <v-form ref="form" v-model="valid" data-cy="forgot-password-form">
            <v-text-field
                v-model="email"
                :placeholder="$t('email')"
                type="email"
                :rules="validationRules('email', [emailRule, requiredRule])"
                required
                data-cy="authorization-email-input"
            />
        </v-form>
        <v-btn
            :loading="isLoading"
            :disabled="isLoading || !valid"
            color="primary"
            class="mr-2"
            data-cy="forgot-password-button"
            @click="forgotPassword"
        >
            {{ $t('submit') }}
        </v-btn>
        <v-btn outlined color="primary" class="text-capitalize" @click="$emit('switch-login')">
            {{ $t('sign_in') }}?
        </v-btn>
    </div>
</template>

<script>
    import {Component, Mixins} from 'vue-property-decorator'
    import {authStore, runtimeStore} from '@/store'
    import {host} from '~src/utils/host-adapter'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'

    @Component
    export default class ForgotPasswordForm extends Mixins(FormValidationRulesMixin) {
        email = null
        valid = false

        mounted() {
            this.$refs.form.resetValidation()
        }

        async forgotPassword() {
            try {
                //TODO Get clientName from separate param when dynamic titles for different pages was implemented
                const rq = {
                    email: this.email,
                    redirectUrl: `${host}/restore-password`,
                    clientName: this.$meta().refresh().metaInfo.title,
                }
                await this.restorePassword(rq)
                this.$toast.success(this.$t('messages.success.forgot_password'))
                this.$emit('forgot-password-success')
            } catch (e) {
                this.$toast.warning(e.msg || e.errors[0].message)
            }
        }

        async restorePassword(rq) {
            return await this.$api.restorePassword.post(rq)
        }

        get isLoading() {
            return runtimeStore.authActive
        }

        get person() {
            return authStore.person
        }
    }
</script>
