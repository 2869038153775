<template>
    <search-form-layout :popup="popup" title="transfers.transfers" @close-popup="$emit('close-popup')">
        <template v-slot:form>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col md="3" cols="12">
                        <transfer-point-autocomplete
                            v-model="departurePoint"
                            label="transfers.departure_location"
                            single-line
                            filled
                            :rules="[v => !!v || `${$t('transfers.departure_location')} ${$t('validation.required')}`]"
                        />
                    </v-col>
                    <v-col md="3" cols="12">
                        <transfer-point-autocomplete
                            v-model="arrivalPoint"
                            label="transfers.arrival_location"
                            single-line
                            filled
                            :rules="[v => !!v || `${$t('transfers.arrival_location')} ${$t('validation.required')}`]"
                        />
                    </v-col>
                    <v-col md="2" cols="12">
                        <vc-date-picker v-model="dateTimeData" :locale="$i18n.locale" :min-date="minDateTime">
                            <v-text-field
                                slot-scope="{inputProps, inputEvents}"
                                :class="{caption: $breakpoint.smAndDown}"
                                :label="$t('transfers.departure_date')"
                                readonly
                                single-line
                                filled
                                hide-details
                                background-color="white"
                                prepend-inner-icon="mdi-calendar"
                                v-bind="formatDatepickerInput(inputProps)"
                                v-on="inputEvents"
                            />
                        </vc-date-picker>
                    </v-col>
                    <v-col md="2" cols="12">
                        <tourists-select-menu
                            :_adults.sync="adults"
                            :_children-ages.sync="childrenAges"
                            token="transfers.passenger"
                        />
                    </v-col>
                    <v-col md="2" cols="12" class="hidden-sm-and-down">
                        <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                            {{ $t('search') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <additional-options-wrapper ref="addForm" :options="['transfer-type', 'promocode', 'citizenship']" />
            <v-row class="hidden-md-and-up">
                <v-col md="2" cols="12">
                    <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                        {{ $t('search') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </search-form-layout>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import TransferPointAutocomplete from '@/components/transfers/search/forms/TransferPointAutocomplete'
    import {authStore, persistentStore, transfersRuntimeStore, transfersStore} from '@/store'
    import AdditionalSearchOptionsMixin from '~/mixins/AdditionalSearchOptionsMixin'
    import AdditionalOptionsWrapper from '~src/components/snippets/AdditionalSearchOptions/additionalOptionsWrapper.src'
    import SearchFormLayout from '~/components/parts/SearchFormLayout'
    import TouristsSelectMenu from '~src/components/search/forms/touristsSelectMenu.src'
    import {CHANGE_TAB_MOBILE_EVENT, EventBus} from '@/utils/event-bus'

    @Component({
        components: {
            TouristsSelectMenu,
            TransferPointAutocomplete,
            AdditionalOptionsWrapper,
            SearchFormLayout,
        },
        mixins: [AdditionalSearchOptionsMixin],
    })
    export default class TransferSearchForm extends Vue {
        @Prop({default: false}) popup

        valid = false
        departurePoint = {}
        arrivalPoint = {}
        //TODO Add time picker
        dateTime = null
        adults = 2
        childrenAges = []

        //TODO Add CHANGE_LOCALE_EVENT handler

        formatDatepickerInput(props) {
            let result = ''
            if (this.dateTime !== null) {
                result = this.$options.filters.dateFormat(this.dateTime)
            }
            props.value = result
            return props
        }

        newQuery() {
            const query = {
                dateTime: this.dateTime,
                adults: this.adults,
                childrenAges: this.childrenAges,
                convertToCurrency: persistentStore.currency,
            }
            const setRequestPoint = (point, pointPlace, pointCityId, pointCountryId, pointHotelCode) => {
                switch (point.type) {
                    case 'hotel':
                        query[pointPlace] = 'hotel'
                        query[pointCityId] = point.cityId
                        query[pointCountryId] = point.countryId
                        query[pointHotelCode] = point.hotelCode
                        break
                    case 'city':
                        query[pointPlace] = 'hotel'
                        query[pointCityId] = point.id
                        query[pointCountryId] = point.countryId
                        break
                    case 'airport':
                        query[pointPlace] = point.iataCode
                        query[pointCityId] = point.cityId
                        query[pointCountryId] = point.countryId
                        break
                    case 'trainStation':
                        query[pointPlace] = point.id
                        query[pointCityId] = point.cityId
                        query[pointCountryId] = point.countryId
                        break
                }
            }
            setRequestPoint(this.arrivalPoint, 'arrivalPlace', 'arrivalCityId', 'arrivalCountryId', 'arrivalHotelCode')
            setRequestPoint(
                this.departurePoint,
                'departurePlace',
                'departureCityId',
                'departureCountryId',
                'departureHotelCode'
            )
            return query
        }

        async search() {
            const query = this.newQuery()
            this.$emit('close-popup')
            transfersRuntimeStore.SET_ARRIVAL_POINT(this.arrivalPoint)
            transfersRuntimeStore.SET_DEPARTURE_POINT(this.departurePoint)
            const name = 'transfers'
            if (this.$route.name !== name) {
                transfersRuntimeStore.newSearch()
            }
            try {
                await this.$router.push({name, query})
            } catch (e) {
                await transfersRuntimeStore.search(transfersStore.searchRequest)
            }
            EventBus.$emit(CHANGE_TAB_MOBILE_EVENT, 1)
        }

        async mounted() {
            await this.$store.restored
            this.arrivalPoint = Object.keys(transfersRuntimeStore.arrivalPoint).length
                ? transfersRuntimeStore.arrivalPoint
                : null
            this.departurePoint = Object.keys(transfersRuntimeStore.departurePoint).length
                ? transfersRuntimeStore.departurePoint
                : null
            if (this.$dateFns.isAfter(this.$dateFns.parseISO(transfersStore.searchRequest.dateTime), new Date())) {
                this.dateTime = transfersStore.searchRequest.dateTime
            } else {
                this.dateTime = this.$dateFns.format(this.$dateFns.addDays(new Date(), 1))
            }
            this.adults = authStore.user.isEmployee ? 1 : transfersStore.searchRequest.adults
            this.childrenAges = authStore.user.isEmployee ? [] : [...transfersStore.searchRequest.childrenAges]
        }

        get dateTimeData() {
            return this.$dateFns.parseISO(this.dateTime)
        }

        set dateTimeData(val) {
            this.dateTime = val ? this.$dateFns.format(val) : null
        }

        get minDateTime() {
            return this.$dateFns.format(new Date())
        }
    }
</script>
