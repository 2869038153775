var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"hide-overlay":"","width":"500"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('div',{staticClass:"modal-close-btn-block"},[_c('v-icon',{staticClass:"v-card-close",on:{"click":function($event){_vm.modal = false}}},[_vm._v("\n            mdi-close\n        ")])],1),_vm._v(" "),_c('v-card',[_c('v-card-title',{staticClass:"mb-4"},[_vm._v("\n            "+_vm._s(_vm.$t('passport'))+"\n        ")]),_vm._v(" "),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-select',{attrs:{"items":_vm.passportTypes,"label":_vm.$t('document_type'),"clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                        "+_vm._s(_vm.$t(("documentTypes." + item)))+"\n                    ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                        "+_vm._s(_vm.$t(("documentTypes." + item)))+"\n                    ")]}}]),model:{value:(_vm.passport.type),callback:function ($$v) {_vm.$set(_vm.passport, "type", $$v)},expression:"passport.type"}}),_vm._v(" "),_c('v-text-field',{attrs:{"label":_vm.$t('document_number')},model:{value:(_vm.passport.number),callback:function ($$v) {_vm.$set(_vm.passport, "number", $$v)},expression:"passport.number"}}),_vm._v(" "),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm._f("dateShortFormat")(_vm.passport.issueDate),"label":_vm.$t('issue_date'),"clearable":"","readonly":"","background-color":"white","prepend-inner-icon":"mdi-calendar"}},on))]}}]),model:{value:(_vm.showIssueDate),callback:function ($$v) {_vm.showIssueDate=$$v},expression:"showIssueDate"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"locale":_vm.$i18n.locale,"max":_vm.today},on:{"change":function($event){_vm.showIssueDate = false}},model:{value:(_vm.passport.issueDate),callback:function ($$v) {_vm.$set(_vm.passport, "issueDate", $$v)},expression:"passport.issueDate"}})],1),_vm._v(" "),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm._f("dateShortFormat")(_vm.passport.validTill),"label":_vm.$t('expire_date'),"clearable":"","readonly":"","background-color":"white","prepend-inner-icon":"mdi-calendar"}},on))]}}]),model:{value:(_vm.showExpireDatePicker),callback:function ($$v) {_vm.showExpireDatePicker=$$v},expression:"showExpireDatePicker"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"locale":_vm.$i18n.locale,"min":_vm.today},on:{"change":function($event){_vm.showExpireDatePicker = false}},model:{value:(_vm.passport.validTill),callback:function ($$v) {_vm.$set(_vm.passport, "validTill", $$v)},expression:"passport.validTill"}})],1),_vm._v(" "),_c('v-switch',{attrs:{"color":"primary","label":_vm.passport.active ? _vm.$t('active') : _vm.$t('inactive')},model:{value:(_vm.passport.active),callback:function ($$v) {_vm.$set(_vm.passport, "active", $$v)},expression:"passport.active"}}),_vm._v(" "),_c('v-btn',{staticClass:"mt-4",attrs:{"disabled":_vm.isLoading,"block":"","color":"primary"},on:{"click":_vm.save}},[_vm._v("\n                    "+_vm._s(_vm.$t('save'))+"\n                ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }