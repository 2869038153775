<template>
    <v-card class="my-2" outlined>
        <n-link
            no-prefetch
            :to="{name: 'activity', query: {name: product.info.name}}"
            class="subtitle-2 text-decoration-none"
        >
            <v-img
                tile
                class="white--text align-end"
                height="200px"
                :src="product.mainImage | imageUrl(200)"
                :alt="product.info.name"
                lazy-src="/placeholder.png"
                @error="imgError = true"
            >
                <template v-slot:placeholder>
                    <v-row
                        v-show="!imgError && product.mainImage"
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
                <v-card-title>
                    {{ product.info.name }}
                </v-card-title>
            </v-img>
        </n-link>
        <v-card-text>
            <n-link
                no-prefetch
                :to="{name: 'activity', query: {name: product.info.name}}"
                class="title text-decoration-none"
            >
                <strong class="black--text">{{ $t('from') }} {{ minPriceOffer.price | price }}</strong>
            </n-link>
            <discount-label :price="minPriceOffer.price" />
            <commission-amount :commission="minPriceOffer.price.commission" />
        </v-card-text>
        <v-expand-transition>
            <div v-if="expanded">
                <activity-grouped-offers
                    :offers="product.offers"
                    :full-card="false"
                    :_group-by.sync="groupBy"
                    @sortSelected="selectedSortBy = $event"
                    @dateFilter="dateFilter = $event"
                />
                <template v-if="groupBy === 'none'">
                    <activity-offer-card
                        v-for="(offer, offerIndex) in sortedOffers"
                        :key="offerIndex"
                        :offer="offer"
                        :full-card="false"
                    />
                    <v-list-item
                        v-if="expanded === expandedLimit && product.offers.length > expandedLimit"
                        dense
                        class="d-flex justify-center"
                        @click.stop="expanded = product.offers.length"
                    >
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-list-item>
                </template>
            </div>
        </v-expand-transition>
        <v-card-actions>
            <v-list-item dense class="d-flex justify-center" @click.stop="open">
                <v-icon v-text="`mdi-chevron-${!expanded || expanded < product.offers.length ? 'down' : 'up'}`" />
            </v-list-item>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import CommissionAmount from '~/components/snippets/CommissionAmount'
    import ActivityCard from '@/components/activities/search/offers/ActivityCard'
    import ActivityOfferCard from '@/components/activities/info/ActivityOfferCard'
    import ActivityGroupedOffers from '~/components/activities/search/offers/ActivityGroupedOffers'

    @Component({
        components: {
            ActivityGroupedOffers,
            DiscountLabel,
            CommissionAmount,
            ActivityOfferCard,
        },
    })
    export default class ActivityCardMobile extends ActivityCard {
        open() {
            if (!this.expanded || this.expanded >= this.product.offers.length) {
                this.toggleOffers()
            } else {
                this.showAllOffers()
            }
        }

        showAllOffers() {
            this.expanded = this.product.offers.length
        }
    }
</script>

<style scoped lang="scss">
    .v-card__title {
        background: rgba(0, 0, 0, 0.5);

        a {
            color: #ffffff;
            text-decoration: none;
        }
    }
</style>
