<template>
    <confirmation-page-layout v-if="loaded" :company="company">
        <template v-slot:breadcrumbs>
            <transfer-breadcrumbs :search-request="searchRequest" :product="product" />
        </template>
        <template v-slot:sidebar>
            <transfer-search-summary search-disabled :search-request="searchRequest" />
            <transfer-order-summary :offer="offer" :search-request="searchRequest" />
        </template>
    </confirmation-page-layout>
</template>

<script>
    import {mixins, Component} from 'nuxt-property-decorator'
    import Breadcrumbs from '~/components/Breadcrumbs'
    import ConfirmationStatus from '@/components/booking/ConfirmationStatus'
    import TransferSearchSummary from '@/components/transfers/search/TransferSearchSummary'
    import TransferOrderSummary from '@/components/transfers/booking/TransferOrderSummary'
    import ConfirmationPageMixin from '@/mixins/ConfirmationPageMixin'
    import TransferPageMixin from '@/components/transfers/mixins/TransferPageMixin'
    import ConfirmationPageLayout from '@/components/parts/ConfirmationPageLayout'
    import TransferBreadcrumbs from '@/components/transfers/TransferBreadcrumbs'
    import {persistentStore} from '@/utils/store-accessor'
    import TransferStoreMixin from '@/mixins/TransferStoreMixin'

    @Component({
        components: {
            TransferBreadcrumbs,
            ConfirmationPageLayout,
            ConfirmationStatus,
            Breadcrumbs,
            TransferSearchSummary,
            TransferOrderSummary,
        },
    })
    export default class TransferConfirmationPage extends mixins(
        TransferPageMixin,
        ConfirmationPageMixin,
        TransferStoreMixin
    ) {
        loaded = false

        validate({query}) {
            return query.orderId
        }

        async mounted() {
            await this.$store.restored
            await this.loadRuntimeData()
            if (this.$route.query.orderId !== persistentStore.bookResponse[0].orderId) {
                //TODO Redirect to 404
            }
            this.loaded = true
        }

        get basketItem() {
            return this.productStore.basketItem(this.productStore.prepareBookRequest.offerKey)
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get product() {
            return this.basketItem.product
        }

        get offer() {
            return this.product.offers.find(offer => offer.offerKey === this.basketItem.offerKey)
        }
    }
</script>
