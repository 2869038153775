var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showPassport)?_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":_vm.passportTypes,"label":_vm.fieldLabel('document_type', 'PASSPORT_TYPE'),"clearable":"","rules":[_vm.requiredRule('document_type', 'PASSPORT_TYPE')],"disabled":_vm.isDisabled},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                    "+_vm._s(_vm.$t(("documentTypes." + item)))+"\n                ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                    "+_vm._s(_vm.$t(("documentTypes." + item)))+"\n                ")]}}],null,false,883664348),model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_vm._v(" "),(!_vm.isFieldDisabled('PASSPORT_NUMBER'))?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":_vm.fieldLabel('document_number', 'PASSPORT_NUMBER'),"rules":[_vm.requiredRule('document_number', 'PASSPORT_NUMBER')],"disabled":_vm.isDisabled},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}})],1):_vm._e(),_vm._v(" "),(!_vm.isFieldDisabled('PASSPORT_ISSUE_DATE'))?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm._f("dateShortFormat")(_vm.issueDate),"label":_vm.fieldLabel('issue_date', 'PASSPORT_ISSUE_DATE'),"clearable":"","readonly":"","background-color":"white","prepend-inner-icon":"mdi-calendar","rules":[_vm.requiredRule('issue_date', 'PASSPORT_ISSUE_DATE')],"disabled":_vm.isDisabled}},on))]}}],null,false,2249263045),model:{value:(_vm.showIssueDate),callback:function ($$v) {_vm.showIssueDate=$$v},expression:"showIssueDate"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"locale":_vm.$i18n.locale,"max":_vm.today},on:{"change":function($event){_vm.showIssueDate = false}},model:{value:(_vm.issueDate),callback:function ($$v) {_vm.issueDate=$$v},expression:"issueDate"}})],1)],1):_vm._e(),_vm._v(" "),(!_vm.isFieldDisabled('PASSPORT_EXPIRE_DATE'))?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm._f("dateShortFormat")(_vm.expiryDate),"label":_vm.fieldLabel('expire_date', 'PASSPORT_EXPIRE_DATE'),"clearable":"","readonly":"","background-color":"white","prepend-inner-icon":"mdi-calendar","rules":[_vm.requiredRule('expire_date', 'PASSPORT_EXPIRE_DATE')],"disabled":_vm.isDisabled}},on))]}}],null,false,1115856883),model:{value:(_vm.showExpireDatePicker),callback:function ($$v) {_vm.showExpireDatePicker=$$v},expression:"showExpireDatePicker"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"locale":_vm.$i18n.locale,"min":_vm.today},on:{"change":function($event){_vm.showExpireDatePicker = false}},model:{value:(_vm.expiryDate),callback:function ($$v) {_vm.expiryDate=$$v},expression:"expiryDate"}})],1)],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }