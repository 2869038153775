<template>
    <div>
        <v-data-table
            v-if="Object.keys(flights).length"
            :items="items"
            :headers="headers"
            :hide-default-footer="true"
            disable-sort
        >
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>{{ $t('flights.flexible') }}</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical />
                </v-toolbar>
            </template>

            <template v-for="head in headers" v-slot:[`header.${head.value}`]="{header}">
                <span v-if="header.value === 'date'" :key="`${head.text}-date`">
                    {{ $t(`${header.text}`) }}
                </span>
                <span v-else :key="`${head.text}`">
                    {{ header.text | dateFormat }}
                </span>
            </template>
            <template v-slot:item.date="{item}">
                {{ item.date | dateFormat }}
            </template>
            <template v-for="date in dates.endDates" v-slot:[`item.${date}`]="{item}">
                <div
                    :key="`${item.date}-${date}`"
                    class="d-flex flex-column align-center pa-2"
                    style="cursor:pointer;"
                    :style="isSearchDate(item, date) ? 'background-color: #fb8c00; color: white ' : ''"
                    @click="search(item.date, date)"
                >
                    <span v-if="item[date]">
                        {{ item[date] | price }}
                    </span>
                    <!-- <v-chip
                        v-if="
                            ((item.date && item.date === searchDates.startDate) || !item.date) &&
                                date === searchDates.endDate
                        "
                        color="warning"
                        x-small
                    >
                        {{ $t('flights.your_dates') }}
                    </v-chip> -->
                    <v-chip v-if="item[date] === minPrice" color="success" x-small>
                        {{ $t('flights.best_price') }}
                    </v-chip>
                    <v-chip v-if="item[date] === maxPrice" color="error" x-small>
                        {{ $t('flights.expensive') }}
                    </v-chip>
                </div>
            </template>
            <template v-slot:footer>
                <div class="pa-4 d-flex">
                    <div style="background-color: #fb8c00; width: 20px; height: 20px" class="mr-2" />
                    {{ $t('flights.your_dates') }}
                </div>
            </template>
        </v-data-table>
        <div v-else class="pa-5 d-flex justify-center align-center">
            <v-progress-circular :size="70" :width="7" color="primary" indeterminate />
        </div>
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'

    @Component
    export default class FlexibleFlights extends Vue {
        @Prop({default: () => []}) flights
        @Prop({}) dates

        getDayFlights(day) {
            const flights = this.flights[day]
            return this.dates.endDates.map(date => {
                let flight = flights[date]
                if (flight && day === this.searchDates.startDate && date === this.searchDates.endDate) {
                    flight[0].isSearchDate = true
                }
                return flight || null
            })
        }

        get searchDates() {
            let routes = this.$route.query.routes
            if (!Array.isArray(routes)) {
                routes = [routes]
            }
            const dates = routes.map(route => {
                const splited = route.split(',')
                return splited[1]
            })
            dates.sort((a, b) => {
                return this.$dateFns.compareAsc(this.$dateFns.parseISO(a), this.$dateFns.parseISO(b))
            })
            return {
                startDate: dates[0],
                endDate: dates[dates.length - 1],
            }
        }

        isSearchDate(item, date) {
            return (
                (!item.date || (item.date && item.date === this.searchDates.startDate)) &&
                date === this.searchDates.endDate
            )
        }

        get minPrice() {
            const prices = this.prices
            prices.sort((a, b) => a.amount - b.amount)
            return prices[0]
        }

        get prices() {
            const prices = new Set()
            Object.values(this.flights).forEach(dayFlights => {
                Object.values(dayFlights).forEach(flights => {
                    prices.add(flights[0].price)
                })
            })
            return Array.from(prices)
        }

        get maxPrice() {
            const prices = this.prices
            prices.sort((a, b) => b.amount - a.amount)
            return prices[0]
        }

        get headers() {
            const routes = this.$route.query.routes

            const headers = this.dates.endDates.map(date => {
                return {
                    sortable: false,
                    text: date,
                    align: 'center',
                    value: date,
                }
            })
            return [
                ...[
                    {
                        text: Array.isArray(routes) ? 'flights.departure_arrival_date' : 'flights.date',
                        value: 'date',
                        sortable: false,
                    },
                ],
                ...headers,
            ]
        }

        get items() {
            const items = []
            if (!Array.isArray(this.$route.query.routes)) {
                const result = {}
                this.dates.startDates.forEach(day => {
                    const flights = this.flights[day]
                    Object.entries(flights).forEach(values => {
                        result[day] = values[1][0].price
                        result['date'] = null
                    })
                    items.push(result)
                })
                return [items[0]]
            }
            this.dates.startDates.forEach(day => {
                const flights = this.flights[day]
                const result = {}
                Object.entries(flights).forEach(values => {
                    result[values[0]] = values[1][0].price
                    result['date'] = day
                    result['offerKey'] = values[1][0].offerKey
                })
                items.push(result)
            })
            return items
        }

        async search(startDate, endDate) {
            const query = {...this.$route.query}
            let routes = query.routes
            if (!Array.isArray(routes)) {
                routes = [routes]
            }
            query.routes = routes.map(route => {
                const splited = route.split(',')
                if (splited[1] === this.searchDates.startDate) {
                    splited[1] = startDate
                }
                if (splited[1] === this.searchDates.endDate) {
                    splited[1] = endDate
                }
                if (this.searchDates.startDate === this.searchDates.endDate) {
                    splited[1] = endDate
                }
                return splited.join(',')
            })
            try {
                await this.$router.push({name: 'flights', query})
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }
    }
</script>
