<template>
    <v-form v-model="value">
        <v-row>
            <v-col cols="12" md="6">
                <order-create-daterange
                    :_start-date.sync="startDate"
                    :_end-date.sync="endDate"
                    :start-date-label="$t('statement_of_account.transaction_start_date')"
                    :end-date-label="$t('statement_of_account.transaction_end_date')"
                    :single-line="false"
                    :no-title="true"
                />
            </v-col>
            <v-col cols="12" md="6">
                <v-autocomplete
                    v-model="transactionType"
                    :items="transactionTypes"
                    outlined
                    clearable
                    hide-details
                    :label="$t('statement_of_account.transaction_type')"
                    item-value="id"
                    item-text="name"
                    multiple
                    deletable-chips
                />
            </v-col>
        </v-row>
        <v-row v-if="isClient">
            <v-col cols="12" md="6">
                <v-autocomplete
                    v-model="selectedClientType"
                    :items="clientTypes"
                    outlined
                    clearable
                    hide-details
                    :label="$t('statement_of_account.client_type')"
                    item-value="id"
                    item-text="name"
                    @change="onChangeSelectedClientType"
                />
            </v-col>
            <v-col cols="12" md="6">
                <v-autocomplete
                    v-model="client"
                    :items="clients"
                    :loading="isLoading"
                    :search-input.sync="search"
                    outlined
                    clearable
                    hide-no-data
                    item-text="label"
                    item-value="clientId"
                    hide-selected
                    hide-details
                    :label="$t('statement_of_account.client')"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <v-text-field
                    v-model="refNumber"
                    :label="$t('statement_of_account.refNumber')"
                    clearable
                    outlined
                    hide-details
                />
            </v-col>
            <v-col v-if="!isClient" cols="12" md="6">
                <v-autocomplete
                    v-model="supplier"
                    :items="suppliers"
                    outlined
                    clearable
                    hide-details
                    :label="$tc('suppliers', 1)"
                    item-value="id"
                    item-text="name"
                />
            </v-col>
            <v-col cols="12" md="6">
                <v-checkbox
                    v-model="showZeroTransactions"
                    class="ma-0"
                    hide-details
                    :label="$t('statement_of_account.showZeroTransactions')"
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, Watch} from 'vue-property-decorator'
    import ReportParametersForm from '~src/components/account/reports/ReportParametersForm'
    import {authStore} from '@/utils/store-accessor'
    import OrderCreateDaterange from '@/components/account/filters/OrderCreateDaterange'

    @Component({
        components: {OrderCreateDaterange},
    })
    export default class StatementOfAccountReportParametersForm extends ReportParametersForm {
        entries = []
        isLoading = false
        search = null
        timer = null
        suppliers = []
        selectedClientType = null

        async created() {
            try {
                const data = await this.$api.companies.get({relationship: 'SUPPLIER', shortResponse: true})
                this.suppliers = data.object.sort((s1, s2) => (s1.name > s2.name ? 1 : -1))
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        onChangeSelectedClientType(clientType) {
            if (clientType) {
                this.entries = []
                this.setParam('clientId', null)
                this.setParam('clientType', clientType)
            } else if (!this.getParam('clientId')) {
                this.setParam('clientType', null)
            }
        }

        get startDate() {
            return this.getParam('transactionDateFrom')
        }

        set startDate(val) {
            if (val) val = this.$dateFns.format(val)
            this.setParam('transactionDateFrom', val)
        }

        get endDate() {
            return this.getParam('transactionDateTo')
        }

        set endDate(val) {
            if (val) val = this.$dateFns.format(val)
            this.setParam('transactionDateTo', val)
        }

        get transactionType() {
            return this.getParam('transactionTypes')
        }

        set transactionType(val) {
            this.setParam('transactionTypes', val)
        }

        get transactionTypes() {
            return [
                {id: 'BOOK', name: this.$t('statement_of_account.transaction_types.BOOK')},
                {id: 'INVOICE', name: this.$t('statement_of_account.transaction_types.INVOICE')},
                {id: 'CANCEL', name: this.$t('statement_of_account.transaction_types.CANCEL')},
                {id: 'MODIFY', name: this.$t('statement_of_account.transaction_types.MODIFY')},
                {id: 'PENALTY', name: this.$t('statement_of_account.transaction_types.PENALTY')},
                {id: 'TRANSFER', name: this.$t('statement_of_account.transaction_types.TRANSFER')},
                {id: 'PENDING', name: this.$t('statement_of_account.transaction_types.PENDING')},
                {id: 'BANK_PAYMENT', name: this.$t('statement_of_account.transaction_types.BANK_PAYMENT')},
                {id: 'DEPOSIT_PAYMENT', name: this.$t('statement_of_account.transaction_types.DEPOSIT_PAYMENT')},
                {id: 'CREDIT_NOTE', name: this.$t('statement_of_account.transaction_types.CREDIT_NOTE')},
                {id: 'CANCEL_INVOICE', name: this.$t('statement_of_account.transaction_types.CANCEL_INVOICE')},
            ]
        }

        get clientTypes() {
            if (authStore.isTourOperator) {
                return [
                    {id: 'TO', name: this.$t('statement_of_account.client_types.to')},
                    {id: 'TA', name: this.$t('statement_of_account.client_types.ta')},
                    {id: 'CC', name: this.$t('statement_of_account.client_types.cc')},
                    {id: 'PC', name: this.$t('statement_of_account.client_types.pc')},
                ]
            }

            if (authStore.isAgency) {
                return [
                    {id: 'TA', name: this.$t('statement_of_account.client_types.ta')},
                    {id: 'CC', name: this.$t('statement_of_account.client_types.cc')},
                    {id: 'PC', name: this.$t('statement_of_account.client_types.pc')},
                ]
            }

            if (authStore.isCorporate) {
                return [
                    {id: 'CC', name: this.$t('statement_of_account.client_types.cc')},
                    {id: 'PC', name: this.$t('statement_of_account.client_types.pc')},
                ]
            }

            return []
        }

        get client() {
            return this.getParam('clientId')
        }

        set client(clientId) {
            this.setParam('clientId', clientId)
            const client = this.clients.find(client => client.clientId === clientId)
            if (!client) return
            let clientType = 'PC'
            if (client.type === 'CORPORATOR') {
                clientType = 'CC'
            } else if (client.type === 'TOUR_OPERATOR') {
                clientType = 'TO'
            } else if (client.type === 'TOUR_AGENCY') {
                clientType = 'TA'
            }
            this.setParam('clientType', clientType)
        }

        get clients() {
            return this.entries.map(entry => {
                const label = this.label(entry),
                    clientId = entry.personId || entry.id
                return Object.assign({}, entry, {label, clientId})
            })
        }

        label({name, firstName, firstNameLocalized, lastName, lastNameLocalized}) {
            return name || `${firstName || firstNameLocalized || ''} ${lastName || lastNameLocalized || ''}`
        }

        @Watch('search')
        async onSearchChange(val) {
            if (this.timer) {
                clearTimeout(this.timer)
            }
            this.timer = window.setTimeout(() => {
                this.initSearch(val)
            }, 500)
        }

        async initSearch(val) {
            if (this.isLoading || !val) return
            this.isLoading = true
            if (this.selectedClientType) {
                if (this.selectedClientType === 'PC') {
                    this.entries = await this.loadPrivateClients(val)
                } else {
                    this.entries = this.companiesByType()
                }
            } else {
                const persons = await this.loadPrivateClients(val)
                this.entries = [...this.companiesByType(), ...persons]
            }
            this.isLoading = false
        }

        async loadPrivateClients(val) {
            try {
                const {persons} = await this.$api.privateClients.get({
                    pattern: val,
                    companyId: this.user.companyId,
                    limit: 10,
                })
                return persons
            } catch (e) {
                return []
            }
        }

        get clientMethod() {
            switch (this.selectedClientType) {
                case 'CC':
                    return 'persons'
                case 'PC':
                    return 'privateClients'
                default:
                    return 'privateClients'
            }
        }

        companiesByType() {
            switch (this.selectedClientType) {
                case 'TO':
                    return this.companies.filter(company => {
                        return company.type === 'TOUR_OPERATOR'
                    })
                case 'TA':
                    return this.companies.filter(company => {
                        return company.type === 'TOUR_AGENCY'
                    })
                case 'CC':
                    return this.companies.filter(company => {
                        return company.type === 'CORPORATOR'
                    })
                default:
                    if (authStore.isTourOperator) {
                        return this.companies.filter(company => {
                            return (
                                company.type === 'TOUR_AGENCY' ||
                                company.type === 'TOUR_OPERATOR' ||
                                company.type === 'CORPORATOR'
                            )
                        })
                    } else if (authStore.isAgency) {
                        return this.companies.filter(company => {
                            return company.type === 'TOUR_AGENCY' || company.type === 'CORPORATOR'
                        })
                    } else if (authStore.isCorporate) {
                        return this.companies.filter(company => {
                            return company.type === 'CORPORATOR'
                        })
                    }
                    return []
            }
        }

        get user() {
            return authStore.user
        }

        get refNumber() {
            return this.getParam('referenceNumber')
        }

        set refNumber(val) {
            this.setParam('referenceNumber', val)
        }

        get showZeroTransactions() {
            return this.getParam('showZeroTransactions')
        }

        set showZeroTransactions(val) {
            this.setParam('showZeroTransactions', val)
        }

        get supplier() {
            return this.getParam('supplierId')
        }

        set supplier(val) {
            this.setParam('supplierId', val)
        }

        getParam(paramName) {
            return this.params[paramName]
        }

        setParam(paramName, value) {
            this.params[paramName] = value
        }

        get isClient() {
            return this.params['type'] === 'CLIENT'
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep {
        .v-input--checkbox label {
            font-size: 14px;
        }
        .v-list-item__action:first-child {
            margin-right: 10px;
        }
    }
</style>
