<template>
    <confirmation-page-layout :company="company">
        <template v-slot:breadcrumbs>
            <tour-breadcrumbs />
        </template>
        <template v-slot:sidebar>
            <tour-search-summary search-disabled :search-request="searchRequest" />
            <tour-order-summary :offer="offer" />
        </template>
    </confirmation-page-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import ConfirmationPageLayout from '@/components/parts/ConfirmationPageLayout'
    import ConfirmationPageMixin from '@/mixins/ConfirmationPageMixin'
    import TourBreadcrumbs from '@/components/tours/TourBreadcrumbs'
    import TourSearchSummary from '@/components/tours/search/TourSearchSummary'
    import TourOrderSummary from '@/components/tours/booking/TourOrderSummary'
    import TourPageMixin from '@/components/tours/mixins/TourPageMixin'
    import {toursStore} from '@/utils/store-accessor'

    @Component({
        components: {TourOrderSummary, TourSearchSummary, TourBreadcrumbs, ConfirmationPageLayout},
    })
    export default class TourConfirmationPage extends mixins(ConfirmationPageMixin, TourPageMixin) {
        validate({query}) {
            return query.orderId
        }

        async mounted() {
            await this.$store.restored
            await this.loadRuntimeData()
        }

        get packageTourId() {
            return this.searchRequest.packageTourId
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get basketItem() {
            return toursStore.basketItem(toursStore.prepareBookRequest.offerKey)
        }

        get offer() {
            return this.basketItem.offer
        }
    }
</script>
