<template>
    <div>
        <div v-show="selectedFilters.length">
            <div class="selected_title">
                {{ $t('selected_filters') }}
            </div>
            <div v-for="item in selectedFilters" :key="item.key" class="mt-1">
                <div class="d-flex flex-wrap">
                    <div class="mr-1 body-2">
                        {{ $t(`filterTitles.${item.key}`) }}
                        :
                    </div>
                    <v-chip
                        v-for="value in item.values"
                        :key="value"
                        color="primary"
                        close
                        small
                        @click:close="deleteFilter(item.key, value)"
                    >
                        {{ item.translatable ? $t(`filters_${item.key}.${value}`) : value }}
                    </v-chip>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'

    @Component
    export default class SelectedFilters extends Vue {
        @Prop({default: []}) selected
        @Prop({default: []}) translatable

        deleteFilter(key, value) {
            this.$emit('delete-filter', {key, value})
        }

        get selectedFilters() {
            const result = []
            const hidden = [
                'price',
                'name',
                'shortDescription',
                'duration',
                'startTime',
                'endTime',
                'flightNumber',
                'supplierCode',
            ]
            for (let [key, values] of Object.entries(this.selected)) {
                if (!hidden.includes(key) && values.length > 0) {
                    const index = this.translatable.indexOf(key)
                    result.push({key, values, translatable: index >= 0})
                }
            }
            return result
        }
    }
</script>
