<template>
    <div>
        <v-row dense>
            <v-col cols="6" class="text-center">
                <label>{{ $t('adults') }}</label>
                <v-text-field
                    v-mask="mask"
                    :value="adultsCount"
                    append-outer-icon="mdi-plus"
                    prepend-icon="mdi-minus"
                    data-cy="adults-selector"
                    :disabled="disabled"
                    class="pt-0"
                    @click:append-outer="incrementAdults"
                    @click:prepend="decrementAdults"
                    @input="inputAdults"
                />
            </v-col>
            <v-col cols="6" class="text-center">
                <label>{{ $t('children') }}</label>
                <v-text-field
                    v-mask="mask"
                    :value="childrenAges.length"
                    append-outer-icon="mdi-plus"
                    prepend-icon="mdi-minus"
                    data-cy="children-selector"
                    :disabled="disabled"
                    class="pt-0"
                    @click:append-outer="changeChildren(childrenAges.length + 1)"
                    @click:prepend="changeChildren(childrenAges.length - 1)"
                    @input="changeChildren"
                />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col v-show="childrenAges.length > 0" :cols="12">
                <label>
                    {{ $t('children_ages') }}
                </label>
            </v-col>
            <v-col v-for="(age, index) in childrenAges" :key="index" cols="4" md="3">
                <v-select
                    :key="index"
                    class="pt-0"
                    :value="age"
                    :items="Array.from(Array(18).keys())"
                    data-cy="children-age-selector"
                    @change="changeChildAge($event, index)"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Vue, Component, PropSync} from 'vue-property-decorator'
    import {mask} from 'vue-the-mask'
    import {authStore} from '~/store'

    @Component({
        directives: {mask},
    })
    export default class TouristsSelect extends Vue {
        @PropSync('adultsProp') adults
        @PropSync('childrenProp') childrenAges

        changeChildren(childrenCount) {
            if (childrenCount < 0 || childrenCount > 5) return
            if (this.childrenAges.length < childrenCount) {
                for (let i = 0; i <= childrenCount - this.childrenAges.length; i++) {
                    this.childrenAges.push(2)
                }
            } else {
                this.childrenAges = this.childrenAges.slice(0, childrenCount)
            }
        }

        changeChildAge(age, index) {
            this.childrenAges[index] = age
        }

        incrementAdults() {
            if (this.adults === 5) return
            this.adults = this.adults + 1
        }

        decrementAdults() {
            if (this.adults === 1) return
            this.adults = this.adults - 1
        }

        inputAdults(v) {
            this.adults = parseInt(v, 10)
        }

        get adultsCount() {
            return authStore.user.isEmployee ? 1 : this.adults
        }

        get disabled() {
            return authStore.user.isEmployee
        }

        mask = {
            mask: 'z',
            tokens: {
                z: {
                    pattern: /[0-5]/,
                    transform: v => parseInt(v, 10),
                },
            },
        }
    }
</script>

<style scoped>
    label {
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
    }
</style>
