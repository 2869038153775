<template>
    <v-tabs v-if="$breakpoint.smAndDown" v-model="mobileTabs" fixed-tabs class="sticky-bar">
        <v-tab>
            <v-icon>mdi-office-building</v-icon>
        </v-tab>
        <v-tab>
            <v-icon>mdi-bed</v-icon>
        </v-tab>
        <v-tab>
            <v-icon>mdi-map-marker</v-icon>
        </v-tab>
    </v-tabs>
</template>

<script>
    import {Vue, Component, VModel} from 'nuxt-property-decorator'

    @Component
    export default class HotelInfoMobileTabs extends Vue {
        @VModel() mobileTabs
    }
</script>
