<template>
    <div>
        <template v-if="offer.services.length && icons.length">
            <v-icon v-for="(icon, index) in icons" :key="index" color="success" class="me-3" :title="$t(icon.title)">
                {{ icon.ico }}
            </v-icon>
        </template>
        <div v-else>
            <span>&nbsp;</span>
        </div>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    //TODO Make mapping on api response transform for size optimization
    const mapping = {
        66: {
            ico: 'mdi-pool',
            title: 'service.pool',
        },
        5: {
            ico: 'mdi-air-conditioner',
            title: 'service.air_conditioning',
        },
        68: {
            ico: 'mdi-parking',
            title: 'service.parking',
        },
        42: {
            ico: 'mdi-parking',
            title: 'service.parking',
        },
        79: {
            ico: 'mdi-spa',
            title: 'service.spa',
        },
        228: {
            ico: 'mdi-briefcase-variant',
            title: 'service.business_center',
        },
        76: {
            ico: 'mdi-silverware',
            title: 'service.restaurant',
        },
        179: {
            ico: 'mdi-wifi',
            title: 'service.internet',
        },
        217: {
            ico: 'mdi-human-male-female',
            title: 'service.adults_only',
        },
    }

    @Component
    export default class HotelServices extends Vue {
        @Prop() offer

        get icons() {
            return Object.values(
                this.offer.services.reduce((icons, groupName) => {
                    const serviceWithCode = this.offer.otaCodeServices.find(e => e.name === groupName)
                    if (serviceWithCode && mapping[serviceWithCode.otaCode]) {
                        icons[serviceWithCode.otaCode] = mapping[serviceWithCode.otaCode]
                    }
                    return icons
                }, {})
            )
        }
    }
</script>
