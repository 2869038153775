<template>
    <v-row>
        <v-col cols="12" md="4">
            <city-airport-autocomplete
                v-model="from"
                single-line
                filled
                label="departure_location"
                :rules="[v => !!v || `${$t('departure_location')} ${$t('validation.required')}`]"
                :default-cities="[]"
            />
        </v-col>
        <v-col cols="12" md="4">
            <city-airport-autocomplete
                v-model="to"
                single-line
                filled
                label="arrival_location"
                :rules="[v => !!v || `${$t('arrival_location')} ${$t('validation.required')}`]"
                :default-cities="[]"
            />
        </v-col>
        <v-col cols="12" md="4">
            <v-menu
                v-model="dateTimePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{on}">
                    <v-text-field
                        :value="dateTime | dateFormat"
                        :label="$t('transfers.departure_date')"
                        readonly
                        single-line
                        filled
                        hide-details
                        background-color="white"
                        prepend-inner-icon="mdi-calendar"
                        :rules="[v => !!v || `${$t('transfers.departure_date')} ${$t('validation.required')}`]"
                        required
                        v-on="on"
                    />
                </template>
                <v-date-picker
                    ref="birthDatePicker"
                    v-model="dateTime"
                    :locale="locale"
                    :min="minDateTime"
                    @change="dateTimePicker = false"
                />
            </v-menu>
        </v-col>
    </v-row>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import CityAirportAutocomplete from '~/components/flights/search/forms/CityAirportAutocomplete'
    import FlightBaseForm from './FlightBaseForm.vue'
    import {flightsRuntimeStore} from '~/store'

    // import FlightSearchFormMixin from '~/mixins/Flights/FlightSearchFormMixin'

    @Component({
        components: {CityAirportAutocomplete},
    })
    export default class OneWay extends FlightBaseForm {
        dateTimePicker = false
        dateTime = null

        get locale() {
            return this.$i18n.locale
        }

        get minDateTime() {
            return this.$dateFns.format(new Date())
        }

        initDates() {
            if (
                !this.isEmptyRoute &&
                this.$dateFns.isAfter(
                    this.$dateFns.parseISO(flightsRuntimeStore.routes[this.formIndex].date),
                    new Date()
                )
            ) {
                this.dateTime = flightsRuntimeStore.routes[this.formIndex].date
            } else {
                this.dateTime = this.$dateFns.format(this.$dateFns.addDays(new Date(), 1))
            }
        }

        get isEmptyRoute() {
            return flightsRuntimeStore.routes.length < this.formIndex + 1
        }

        getRoutes() {
            return [
                {
                    departure: this.from,
                    arrival: this.to,
                    date: this.dateTime,
                },
            ]
        }
    }
</script>
