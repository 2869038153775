<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import BookingPageBase from '~src/components/parts/bookingPageBase.src'
    import TourStoreMixin from '@/mixins/TourStoreMixin'

    @Component
    export default class TourBookingPageLayout extends mixins(BookingPageBase, TourStoreMixin) {
        prepareBookRequest() {
            const prepareBookRequest = BookingPageBase.options.methods.prepareBookRequest.call(this)
            if (this.productRuntimeStore.hasTransferOffers) {
                prepareBookRequest.entryParams = this.productStore.searchResponse.entries.reduce(
                    (entryParams, entry) => {
                        if (entry.type === 'TRANSFER') {
                            entryParams.push({
                                entryName: entry.name,
                                entryType: entry.type,
                            })
                        }
                        return entryParams
                    },
                    []
                )
            }
            return prepareBookRequest
        }

        get productSearchPageName() {
            return 'tour'
        }

        productConfirmationPageQuery(orderId) {
            return {
                orderId,
                packageTourId: this.$route.query.packageTourId,
            }
        }

        get productConfirmationPageName() {
            return 'tourConfirmation'
        }

        get productName() {
            return 'tour'
        }

        productBookingPageQuery(paymentStatus) {
            return {
                paymentStatus,
                offerKey: this.$route.query.offerKey,
                packageTourId: this.$route.query.packageTourId,
            }
        }

        get productBookingPageName() {
            return 'tourBooking'
        }

        get corporatePolicyType() {
            return null
        }
    }
</script>
