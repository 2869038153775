<template>
    <v-breadcrumbs class="pa-0 mb-3" :items="items" divider=">" />
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import {runtimeStore} from '~/store'

    @Component
    export default class Breadcrumbs extends Vue {
        @Prop() text

        get homeItem() {
            if (runtimeStore.config.mainPageUrl) {
                return {
                    text: this.$t('home'),
                    href: runtimeStore.config.mainPageUrl,
                    link: true,
                }
            }
            return {
                text: this.$t('home'),
                to: {name: 'home'},
                nuxt: true,
                exact: true,
            }
        }

        get items() {
            const items = [this.homeItem]
            switch (this.$route.name) {
                case 'register':
                case 'b2b-register':
                    items.push({
                        text: this.$t('registration'),
                        disabled: true,
                    })
                    break
                case 'orders':
                    items.push({
                        text: this.$t('orders'),
                        disabled: true,
                    })
                    break
                case 'user-settings':
                    items.push({
                        text: this.$t('my_profile'),
                        disabled: true,
                    })
                    break
                case 'order-details':
                    items.push({
                        text: this.$t('orders'),
                        disabled: false,
                        to: {name: 'orders'},
                        nuxt: true,
                        exact: true,
                    })
                    items.push({
                        text: this.$t('order_details'),
                        disabled: true,
                    })
                    break
                case 'company-profile':
                    items.push({
                        text: this.$t('my_company'),
                        disabled: true,
                    })
                    break
                case 'company-client-profile':
                    items.push({
                        text: this.$t('company'),
                        disabled: true,
                    })
                    break
                case 'companyPersons':
                    items.push({
                        text: this.$t('users'),
                        disabled: true,
                    })
                    break
                case 'companyPerson':
                    items.push({
                        text: this.$t('users'),
                        disabled: false,
                        to: {name: 'companyPersons'},
                        nuxt: true,
                        exact: true,
                    })
                    items.push({
                        text: this.text,
                        disabled: true,
                    })
                    break
                case 'companyClients':
                    items.push({
                        text: this.$t('clients'),
                        disabled: true,
                    })
                    break
                case 'companyClient':
                    items.push({
                        text: this.$t('clients'),
                        disabled: false,
                        to: {name: 'companyClients'},
                        nuxt: true,
                        exact: true,
                    })
                    items.push({
                        text: this.text,
                        disabled: true,
                    })
                    break
                case 'companyCorporateClients':
                    items.push({
                        text: this.$t('corporate_clients'),
                        disabled: true,
                    })
                    break
                case 'companyAgencies':
                    items.push({
                        text: this.$t('agencies'),
                        disabled: true,
                    })
                    break
                case 'members':
                    items.push({
                        text: this.membersText,
                        disabled: true,
                    })
                    break
                case 'member':
                    items.push({
                        text: this.membersText,
                        disabled: false,
                        to: {name: 'members'},
                        nuxt: true,
                        exact: true,
                    })
                    items.push({
                        text: this.text,
                        disabled: true,
                    })
                    break
                case 'infoPage':
                    items.push({
                        text: this.text,
                        disabled: true,
                    })
                    break
                case 'reports':
                    items.push({
                        text: this.$t('reports'),
                        disabled: true,
                    })
                    break
                case 'supplierProducts':
                    items.push({
                        text: this.$t('products'),
                        disabled: true,
                    })
                    break
                case 'supplierContract':
                    items.push({
                        text: this.$t('products'),
                        disabled: false,
                        to: {name: 'supplierProducts'},
                        nuxt: true,
                        exact: true,
                    })
                    items.push({
                        text: this.text,
                        disabled: true,
                    })
                    break
            }
            return items
        }

        get membersText() {
            return this.$t('members')
        }
    }
</script>
