<template>
    <div>
        <v-row no-gutters class="mb-5" :class="{'ml-0 ml-md-6': !fullCard, 'px-4': $breakpoint.smAndDown && !fullCard}">
            <v-col cols="12" md="4" class="mr-0 mr-md-3">
                <v-select
                    v-model="sortBy"
                    :label="$t('sort.sort')"
                    :item-text="item => $t(`sort.${item}`)"
                    :items="['price_asc', 'price_desc']"
                    hide-details
                    @change="$emit('sortSelected', $event)"
                >
                    <template v-slot:item="{item}">
                        {{ $t(`sort.${item}`) }}
                    </template>
                    <template v-slot:selection="{item}">
                        {{ $t(`sort.${item}`) }}
                    </template>
                </v-select>
            </v-col>
            <v-col cols="12" md="3" class="mr-0 mr-md-3">
                <v-select
                    v-model="groupBy"
                    :label="$t('group_by')"
                    :items="['none', 'serviceName']"
                    hide-details
                    @change="$emit('groupSelected', $event)"
                >
                    <template v-slot:item="{item}">
                        <div>
                            {{ $t(`groupBy.${item}`) }}
                        </div>
                    </template>
                    <template v-slot:selection="{item}">
                        <div>
                            {{ $t(`groupBy.${item}`) }}
                        </div>
                    </template>
                </v-select>
            </v-col>
            <v-col cols="12" md="3">
                <v-menu
                    v-model="dateFilterPicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{on}">
                        <v-text-field
                            :value="dateFilter | dateFormat"
                            :label="$t('date')"
                            clearable
                            readonly
                            background-color="white"
                            prepend-inner-icon="mdi-calendar"
                            v-on="on"
                            @click:clear="
                                dateFilter = null
                                $emit('dateFilter', null)
                            "
                        />
                    </template>
                    <v-date-picker
                        v-model="dateFilter"
                        :locale="$i18n.locale"
                        :allowed-dates="allowedDates"
                        :events="allowedDates"
                        :min="minDate"
                        event-color="green lighten-1"
                        @change="
                            dateFilterPicker = false
                            $emit('dateFilter', $event)
                        "
                    />
                </v-menu>
            </v-col>
        </v-row>
        <v-expansion-panels v-if="groupBy !== 'none'" v-model="panels" multiple flat focusable accordion>
            <v-expansion-panel v-for="(offers, groupIndex) in groupedOffers" :key="`group-${groupIndex}`">
                <v-expansion-panel-header
                    class="text-nobreak "
                    :class="$breakpoint.smAndDown ? 'body-2' : 'subtitle-1'"
                    color="blue-grey lighten-5"
                >
                    <div>
                        {{ offers[0][groupBy] }}
                    </div>
                    <div class="d-flex align-center justify-space-between font-weight-bold text-right pr-4">
                        <v-chip
                            label
                            x-small
                            :color="minPriceOffer(offers).available ? 'green darken-1' : 'orange accent-3'"
                            class="flex-grow-0 ml-6 white--text"
                        >
                            {{ minPriceOffer(offers).available ? $t('available') : $t('on_request') }}
                        </v-chip>
                        <template v-if="minPriceOffer(offers).price">
                            {{ $t('from') }} {{ minPriceOffer(offers).price | price }}
                        </template>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <activity-offer-card
                        v-for="(offer, offerIndex) in offers"
                        :key="offerIndex"
                        :offer="offer"
                        :full-card="fullCard"
                        :grouped-by="groupBy"
                        @images="$emit('images', $event)"
                        @info="$emit('info', $event)"
                    />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    import {Component, Prop, PropSync, Vue, Watch} from 'vue-property-decorator'
    import ActivityOfferCard from '~src/components/activities/info/activityOfferCard.src'

    @Component({
        components: {ActivityOfferCard},
    })
    export default class ActivityGroupedOffers extends Vue {
        @Prop({default: () => []}) offers
        @Prop({default: true}) fullCard
        @PropSync('_groupBy', {default: 'serviceName'}) groupBy

        sortBy = 'price_desc'
        panels = [0]
        dateFilterPicker = false
        dateFilter = null

        @Watch('groupBy')
        onGroupByChange() {
            this.panels = [0]
        }

        allowedDates(date) {
            return this.offers.findIndex(offer => offer.date === date) !== -1
        }

        get groupedOffers() {
            const sortFns = {
                price_desc: (a, b) => a.price.amount - b.price.amount,
                price_asc: (a, b) => b.price.amount - a.price.amount,
            }
            let offers = [...this.offers]
            if (this.dateFilter) {
                offers = offers.filter(offer => offer.date === this.dateFilter)
            }
            offers.sort(sortFns[this.sortBy])
            return offers.reduce((groupedOffers, offer) => {
                let group = groupedOffers.find(group => group[0][this.groupBy] === offer[this.groupBy])
                if (!group) {
                    group = []
                    groupedOffers.push(group)
                }
                group.push(offer)
                return groupedOffers
            }, [])
        }

        minPriceOffer(offers) {
            return offers.reduce((a, b) => (a.price.amount < b.price.amount ? a : b), offers[0])
        }

        get minDate() {
            if (!this.offers.length) return this.$dateFns.format(new Date())
            return this.$dateFns.format(this.$dateFns.min(this.offers.map(offer => new Date(offer.date))))
        }
    }
</script>

<style scoped>
    >>> .v-expansion-panel-content__wrap {
        padding-left: 0;
        padding-right: 0;
    }
</style>
