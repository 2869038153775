<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {hotelsRuntimeStore} from '@/utils/store-accessor'
    import {isEqual} from '@/utils/helpers'

    @Component
    export default class HotelPageMixin extends Vue {
        async loadRuntimeData() {
            const promises = []
            if (!isEqual(hotelsRuntimeStore.hotelInfoRequest, this.hotelInfoRequest))
                promises.push(hotelsRuntimeStore.loadHotelInfo(this.hotelInfoRequest))
            if (hotelsRuntimeStore.city.id !== this.cityId) promises.push(hotelsRuntimeStore.loadCity(this.cityId))
            await Promise.all(promises)
        }
    }
</script>
