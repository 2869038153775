<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import {cmsStore} from '@/store'

    @Component
    export default class BaseLayout extends Vue {
        head() {
            return cmsStore.layoutSettings.head
        }

        created() {
            this.$vuetify.rtl = this.$i18n.locale === 'ar'
        }
    }
</script>
