<template>
    <search-layout v-if="loaded" :map-expanded="mapExpanded">
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="search" />
            <extra-service-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:left-sidebar>
            <extra-service-search-summary :search-request="searchRequest" />
            <sort-select v-model="sortKey" :items="sortItems" />
            <extra-service-filters
                :filter-values="filterValues"
                :filters="searchResponse.filters"
                :filtered-offers-count="filteredOffers.length"
                :currency="currency"
                @change="changeFilter"
                @reset="resetFilters"
            />
        </template>
        <template v-slot:main-content>
            <search-progress
                :filtered-offers-count="filteredOffers.length"
                :total-offers-count="filterOffersByType(searchResponse.products).length"
                :search-active="searchActive"
                @stop="stopSearch"
            />
            <extra-service-offers :offers="offers" />
            <pagination :page-prop.sync="page" :pages="pages" />
            <conditions-modal>
                <template v-slot:info>
                    <extra-service-conditions-modal-info />
                </template>
            </conditions-modal>
            <extra-service-info-modal />
        </template>
        <template v-slot:right-sidebar>
            <client-only>
                <extra-service-map :_expanded.sync="mapExpanded" :city="city" />
            </client-only>
        </template>
    </search-layout>
</template>

<script>
    import {Component, mixins, Watch} from 'nuxt-property-decorator'
    import MobileSearchPageTabs from '~src/components/snippets/MobileSearchPageTabs'
    import Pagination from '~src/components/search/Pagination'
    import ConditionsModal from '~src/components/modals/ConditionsModal'
    import ExtraServiceBreadcrumbs from '~src/components/extraServices/ExtraServiceBreadcrumbs'
    import ExtraServiceOffers from '~src/components/extraServices/search/offers/ExtraServiceOffers'
    import ExtraServiceFilters from '~src/components/extraServices/search/ExtraServiceFilters'
    import ExtraServiceSearchSummary from '~src/components/extraServices/search/ExtraServiceSearchSummary'
    import ExtraServiceMap from '@/components/extraServices/search/ExtraServiceMap'
    import ExtraServiceInfoModal from '~src/components/extraServices/info/ExtraServiceInfoModal'
    import SearchPageBase from '@/mixins/SearchPageBase'
    import {searchRequest} from '@/utils/extraServices/extra-services-blank-states'
    import ExtraServicesStoreMixin from '@/mixins/ExtraServiceStoreMixin'
    import {persistentStore} from '~/store'
    import ExtraServicePageMixin from '~src/components/extraServices/mixins/ExtraServicePageMixin'
    import {isEqual} from '~src/utils/helpers'
    import ExpiredOffersSnackbar from '~src/components/search/ExpiredOffersSnackbar'
    import SearchProgress from '~src/components/search/searchProgress.src'
    import SortSelect from '~src/components/search/SortSelect'
    import ExtraServiceConditionsModalInfo from '@/components/extraServices/snippets/ExtraServiceConditionsModalInfo'

    @Component({
        components: {
            ExtraServiceConditionsModalInfo,
            SortSelect,
            SearchProgress,
            ExpiredOffersSnackbar,
            ExtraServiceOffers,
            Pagination,
            MobileSearchPageTabs,
            ExtraServiceBreadcrumbs,
            ExtraServiceFilters,
            ExtraServiceSearchSummary,
            ExtraServiceMap,
            ExtraServiceInfoModal,
            ConditionsModal,
            SearchLayout: () => import('~src/components/parts/searchLayout.src'),
        },
        layout: 'blank',
    })
    export default class ExtraServicesPage extends mixins(
        ExtraServicePageMixin,
        ExtraServicesStoreMixin,
        SearchPageBase
    ) {
        loaded = false

        @Watch('$route.query')
        onQueryChange() {
            this.search()
        }

        validate({query}) {
            return query.cityId
        }

        async fetch() {
            await this.loadRuntimeData()
        }

        async mounted() {
            await this.$store.restored
            this.loaded = true
            if (
                !isEqual(this.productStore.searchRequest, this.searchRequest) ||
                this.isOffersExpired() ||
                !this.productStore.hasOffers
            ) {
                await this.search()
            } else if (this.productRuntimeStore.offers.length === 0) {
                this.resetPageLock = true
                this.filter()
            }
        }

        async search() {
            await this.productRuntimeStore.search(this.searchRequest)
        }

        filter() {
            this.productRuntimeStore.filter()
        }

        changeFilter(data) {
            this.productStore.SET_FILTER(data)
            this.filter()
        }

        resetFilters() {
            this.productStore.RESET_FILTERS()
            this.productRuntimeStore.sort(this.searchResponse.product)
        }

        isOffersExpired() {
            return this.productStore.isOffersExpired()
        }

        stopSearch() {
            this.productRuntimeStore.stopSearch()
        }

        get searchRequest() {
            //TODO Need filter $route.query params
            const rq = Object.assign(searchRequest(), this.$route.query)
            if (typeof rq.childrenAges === 'string') {
                rq.childrenAges = [parseInt(rq.childrenAges, 10)]
            }
            rq.convertToCurrency = persistentStore.currency
            rq.adults = parseInt(rq.adults, 10)
            rq.countryId = parseInt(rq.countryId, 10)
            rq.cityId = parseInt(rq.cityId, 10)
            if (rq.citizenshipId) {
                rq.citizenshipId = parseInt(rq.citizenshipId, 10)
            }
            return rq
        }

        get mapPoints() {
            return [{lat: this.productRuntimeStore.city.latitude, lng: this.productRuntimeStore.city.longitude}]
        }

        get sortKey() {
            return this.productStore.sortFnName
        }

        set sortKey(sortKey) {
            this.productStore.SET_SORT(sortKey)
            this.productRuntimeStore.sort(this.filteredOffers)
        }

        get filteredOffers() {
            return this.filterOffersByType(this.productRuntimeStore.offers)
        }

        filterOffersByType(offers) {
            if (!this.$route.query.type) {
                return offers
            }

            return offers.filter(offer => offer.info.name.toLowerCase().includes(this.$route.query.type))
        }

        get filterValues() {
            return this.productStore.filters
        }

        get searchActive() {
            return this.productRuntimeStore.searchActive
        }

        get searchResponse() {
            return this.productStore.searchResponse
        }

        get city() {
            return this.productRuntimeStore.city
        }

        get sortItems() {
            return [
                {
                    text: this.$t('sort.price_desc'),
                    value: 'priceAsc',
                },
                {
                    text: this.$t('sort.price_asc'),
                    value: 'priceDesc',
                },
                {
                    text: this.$t('sort.name'),
                    value: 'name',
                },
            ]
        }

        get currency() {
            return this.productStore.searchRequest.convertToCurrency
        }

        get offers() {
            const index = this.resultsOnPage * (this.page - 1)
            return this.filteredOffers.slice(index, index + this.resultsOnPage)
        }

        get pages() {
            return Math.ceil(this.filteredOffers.length / this.resultsOnPage)
        }
    }
</script>
