<template>
    <div v-if="price">
        <v-expansion-panels v-model="expanded" flat style="background-color: transparent" tile>
            <v-expansion-panel expand>
                <v-expansion-panel-header class="v-subheader pl-0 pr-0 theme--light">
                    {{ labelText }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row no-gutters class="mb-5">
                        <v-col>
                            <v-text-field
                                :value="{amount: price[0], currency} | price"
                                readonly
                                single-line
                                outlined
                                hide-details
                                dense
                                background-color="white"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                :value="{amount: price[1], currency} | price"
                                readonly
                                single-line
                                outlined
                                hide-details
                                dense
                                background-color="white"
                            />
                        </v-col>
                    </v-row>
                    <v-range-slider
                        v-model="price"
                        :min="min"
                        :max="max"
                        :thumb-label="false"
                        :disabled="max === Infinity"
                        @start="start"
                        @end="end"
                    />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-divider />
    </div>
</template>

<script>
    import {Vue, Component, Model, Prop} from 'vue-property-decorator'
    import {persistentStore} from '~/store'

    @Component
    export default class PriceFilter extends Vue {
        @Model() value
        @Prop() filters
        @Prop({default: null}) label
        @Prop({default: null}) nights
        @Prop({required: true}) currency

        sliderPrice = [0, Infinity]
        sliderStart = false
        expanded = 0

        start() {
            this.sliderStart = true
        }

        end() {
            this.$emit('change', {key: 'price', value: this.sliderPrice})
            this.sliderStart = false
        }

        get price() {
            return this.sliderStart ? this.sliderPrice : this.value['price']
        }

        set price(value) {
            this.sliderPrice = value
        }

        get min() {
            return Math.floor(this.filters['price'][0])
        }

        get max() {
            return Math.ceil(this.filters['price'][1])
        }

        get selectedCurrency() {
            return persistentStore.currency
        }

        get labelText() {
            return (
                this.label ||
                `${this.$t('price_for')}  ${this.nights} ${this.$tc('night', this.nights)} ${this.$t('in')} ${
                    this.selectedCurrency
                }`
            )
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .v-expansion-panel-content__wrap {
        padding: 0;
    }
</style>
