<template>
    <div>
        <tourist-form
            :index="index"
            label="transfers.passenger"
            :disabled="disabled"
            :phone-required="true"
            :corporate-policy-violation="offer.corporatePolicyViolation"
        >
            <template v-if="!isPackage" v-slot:extraFields v-bind="{index, disabled}">
                <v-col v-if="mealTypes || seatAllocations" cols="12">
                    <v-row dense>
                        <v-col cols="12" md="6">
                            <v-select
                                v-if="mealTypes && mealTypes.length"
                                :items="mealTypes"
                                item-text="name"
                                item-value="code"
                                :label="`${$t('flights.meal_type')}`"
                                clearable
                                :disabled="disabled"
                                @change="
                                    code => {
                                        changeExtraOptions(index, 'mealTypeCode', code)
                                    }
                                "
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-if="seatAllocations && seatAllocations.length"
                                item-text="name"
                                item-value="code"
                                :items="seatAllocations"
                                :label="`${$t('flights.seat_allocation')}`"
                                clearable
                                :disabled="disabled"
                                @change="
                                    code => {
                                        changeExtraOptions(index, 'seatAllocationCode', code)
                                    }
                                "
                            />
                        </v-col>
                    </v-row>
                </v-col>
                <v-row>
                    <v-col v-if="airlines.length" cols="12" md="6">
                        <v-autocomplete
                            :items="airlines"
                            :label="`${$t('frequent_flyer_card_type')}`"
                            :placeholder="`${$t('frequent_flyer_card_type')}`"
                            item-text="name"
                            item-value="value"
                            :disabled="disabled"
                            clearable
                            :value="(getFrequentFlyerCard[index] && getFrequentFlyerCard[index].airlineCode) || ''"
                            @change="
                                code => {
                                    frequentFlyerCard(index, 'frequentFlyerCardType', code)
                                }
                            "
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            :label="`${$t('frequent_flyer_card_number')}`"
                            :placeholder="`${$t('frequent_flyer_card_number')}`"
                            :disabled="disabled || isNumberDisabled(index)"
                            :value="(getFrequentFlyerCard[index] && getFrequentFlyerCard[index].number) || ''"
                            @input="
                                code => {
                                    frequentFlyerCard(index, 'frequentFlyerCardNumber', code)
                                }
                            "
                        />
                    </v-col>
                </v-row>
            </template>
        </tourist-form>
        <tourist-passport-form
            :index="index"
            :disabled="disabled"
            :corporate-policy-violation="offer.corporatePolicyViolation"
        />
        <extra-baggage-form v-if="!isPackage" :index="index" :disabled="disabled" :offer="offer" />
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import TouristForm from '~/components/booking/forms/TouristForm'
    import TouristPassportForm from '~src/components/booking/forms/touristPassportForm.src'
    import ExtraBaggageForm from '@/components/flights/booking/ExtraBaggageForm'
    import {flightsStore} from '@/store'
    import {clone} from '@/utils/helpers'

    @Component({
        components: {
            ExtraBaggageForm,
            TouristForm,
            TouristPassportForm,
        },
    })
    export default class FlightTouristForm extends Vue {
        @Prop({required: true}) index
        @Prop({required: true}) disabled
        @Prop({required: true}) offer
        @Prop({default: false, type: Boolean}) isPackage

        async mounted() {
            await this.$store.restored
            if (!this.isPackage && this.airlines.length === 0) {
                await flightsStore.loadAirlines()
            }
        }

        changeExtraOptions(touristIndex, type, code) {
            if (code) {
                flightsStore.SET_MEAL_TYPE_SEAT_ALLOWANCE({touristIndex, type, code})
            } else {
                flightsStore.DELETE_MEAL_TYPE_SEAT_ALLOWANCE({touristIndex, type})
            }
        }

        frequentFlyerCard(index, type, code) {
            if (type === 'frequentFlyerCardType') {
                this.frequentFlyerCardType(index, code)
            } else {
                this.frequentFlyerCardNumber(index, code)
            }
        }

        frequentFlyerCardType(index, code) {
            if (code) {
                flightsStore.SET_FREQUENT_FLYER_CARD_TYPE({index, code})
            } else {
                flightsStore.DELETE_FREQUENT_FLYER_CARD_TYPE({index})
            }
        }

        frequentFlyerCardNumber(index, code) {
            flightsStore.SET_FREQUENT_FLYER_CARD_NUMBER({index, code})
        }

        get mealTypes() {
            return flightsStore.flightPreferences?.mealTypes
        }

        get seatAllocations() {
            return flightsStore.flightPreferences?.seatAllocations
        }

        get airlines() {
            const airlines = clone(
                flightsStore.airlines.map(({codes, name}) => {
                    return {
                        name: name[this.$i18n.locale] || name['en'],
                        value: codes.IATA,
                    }
                })
            )
            const marketingAirlineName = this.marketingAirlineName.trim()
            const gotName = airlines.find(a => a.name.trim() === marketingAirlineName)
            if (gotName) {
                return [{...gotName}, ...airlines]
            }
            return airlines
        }

        isNumberDisabled(index) {
            return !flightsStore.frequentFlyerCard[index]?.airlineCode
        }

        get getFrequentFlyerCard() {
            return flightsStore.frequentFlyerCard
        }

        get marketingAirlineName() {
            return this.offer.itinerary[0].segments[0].marketingAirlineName.trim()
        }
    }
</script>
