<script>
    import {Component} from 'vue-property-decorator'
    import HotelMap from '../../search/offers/HotelMap.vue'

    @Component
    export default class ActivityMap extends HotelMap {
        async load() {
            await this.initMap()
        }

        async setMap() {
            this.map = new this.google.maps.Map(this.$refs.map, this.getMapOptions())
        }

        getMapOptions() {
            return {
                disableDefaultUI: true,
                gestureHandling: 'greedy',
                mapTypeControl: true,
                zoomControl: true,
                fullscreenControl: true,
                center: {lat: this.city.latitude, lng: this.city.longitude},
                zoom: 12,
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .map {
        position: fixed;

        @media #{map-get($display-breakpoints, 'xs-only')} {
            margin-left: -$container-padding-x;
        }

        > div {
            height: 100%;
        }
    }
</style>
