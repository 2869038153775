<template>
    <div class="position: relative">
        <selected-filters
            :selected="filterValues"
            :translatable="['distance', 'mealTypes', 'availability']"
            @delete-filter="clearFilter"
        />
        <price-filter
            v-if="filters.price"
            v-model="filterValues"
            :filters="filters"
            :nights="nights"
            :currency="currency"
            @change="change($event)"
        />
        <name-filter
            v-model="filterValues"
            :filters="filters"
            filter="name"
            :placeholder="$t('filterHotelNamePlaceholder')"
            @change="change($event)"
        />
        <supplier-filter
            v-if="filters.supplierCode.length"
            v-model="filterValues"
            :filters="filters"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.category && filters.category.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="category"
            :translate-labels="true"
            :pluralization="true"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.type && filters.type.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="type"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.mealTypes && filters.mealTypes.length > 1"
            v-model="filterValues"
            :filters="filters"
            :translate-labels="true"
            filter="mealTypes"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.location && filters.location.length > 1"
            v-model="filterValues"
            :filters="filters"
            :translate-labels="true"
            :panel-expanded="false"
            filter="location"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.district && filters.district.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="district"
            :panel-expanded="false"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.distance && filters.distance.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="distance"
            :translate-labels="true"
            :panel-expanded="false"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.chain && filters.chain.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="chain"
            :panel-expanded="false"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.services && filters.services.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="services"
            :panel-expanded="false"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.classifications && filters.classifications.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="classifications"
            :panel-expanded="false"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.availability && filters.availability.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="availability"
            :translate-labels="true"
            :panel-expanded="false"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.shared && filters.shared.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="shared"
            :panel-expanded="false"
            @change="change($event)"
        />
        <checkbox-filter
            v-if="filters.roomTypes && filters.roomTypes.length > 1"
            v-model="filterValues"
            :filters="filters"
            filter="roomTypes"
            :panel-expanded="false"
            @change="change($event)"
        />
        <tripadvisor-filter
            v-if="filters.tripadvisorRating && filters.tripadvisorRating.length > 1"
            v-model="filterValues"
            :filters="filters"
            :translate-labels="true"
            filter="tripadvisorRating"
            :panel-expanded="false"
            @change="change($event)"
        />
        <filtered-mobile-button
            v-if="$breakpoint.smAndDown"
            class="sticky-button "
            :count="filteredOffersCount"
            :visible="Object.keys(filterValues).length > 0"
            @change-mode="goToOffers"
            @clear-filters="reset"
        />
    </div>
</template>

<script>
    import {Component, Prop} from 'vue-property-decorator'
    import CheckboxFilter from '@/components/search/filters/CheckboxFilter'
    import TripadvisorFilter from '@/components/search/filters/TripadvisorFilter.vue'
    import FilteredMobileButton from '~/components/snippets/FilteredMobileButton'
    import SelectedFilters from '~/components/snippets/SeletedFilters'
    import PriceFilter from '~src/components/search/filters/priceFilter.src'
    import NameFilter from '~/components/filters/NameFilter'
    import FiltersBase from '~/components/search/FiltersBase'
    import SupplierFilter from '~src/components/search/filters/SupplierFilter'

    @Component({
        components: {
            SupplierFilter,
            PriceFilter,
            CheckboxFilter,
            TripadvisorFilter,
            FilteredMobileButton,
            SelectedFilters,
            NameFilter,
        },
    })
    export default class HotelFilters extends FiltersBase {
        @Prop({required: true, default: 1}) nights
    }
</script>
