<template>
    <div>
        <v-text-field
            v-if="!loaded"
            :class="textFieldClass"
            :label="$t('check_in')"
            readonly
            :single-line="singleLine"
            :outlined="outlined"
            filled
            hide-details
            background-color="white"
            :prepend-inner-icon="showIcon ? 'mdi-calendar' : null"
        />
        <client-only>
            <vc-date-picker
                v-model="range"
                mode="range"
                :locale="locale"
                :min-date="minStartDate"
                :max-date="maxEndDate"
                :columns="$breakpoint.smAndDown ? 1 : 2"
                :rows="1"
                :popover="popover"
                @dayclick="dayclick"
                @popoverWillShow="popoverWillShow"
            >
                <v-text-field
                    ref="startDateInput"
                    slot-scope="{inputProps, inputEvents}"
                    :class="textFieldClass"
                    :label="$t('check_in')"
                    readonly
                    :single-line="singleLine"
                    :outlined="outlined"
                    filled
                    hide-details
                    background-color="white"
                    :prepend-inner-icon="showIcon ? 'mdi-calendar' : null"
                    v-bind="format(inputProps)"
                    v-on="inputEvents"
                />
            </vc-date-picker>
        </client-only>
    </div>
</template>

<script>
    import {Vue, Component, PropSync, Prop} from 'vue-property-decorator'

    @Component
    export default class DaterangeM extends Vue {
        @PropSync('startDateProp') startDate
        @PropSync('endDateProp') endDate
        @Prop({default: false}) outlined
        @Prop({default: true}) singleLine
        @Prop({default: true}) showIcon
        @Prop({default: () => ({placement: 'bottom'})}) popover
        @Prop({default: 'hotels'}) product

        selectedStartDate = null
        loaded = false

        mounted() {
            this.loaded = true
        }

        get range() {
            return {
                start: this.startDate ? this.$dateFns.parseISO(this.startDate) : null,
                end: this.endDate ? this.$dateFns.parseISO(this.endDate) : null,
            }
        }

        set range(val) {
            if (!val) return
            this.startDate = this.$dateFns.format(val.start)
            this.endDate = this.$dateFns.format(val.end)
        }

        get locale() {
            return this.$i18n.locale
        }

        get minStartDate() {
            return this.$dateFns.format(new Date())
        }

        get maxEndDate() {
            if (!this.selectedStartDate) return null
            return this.addDays(this.selectedStartDate, 60)
        }

        format(props) {
            let result = ''
            if (this.range.start !== null && this.range.end !== null) {
                result = `${this.$options.filters.dateFormat(this.range.start)} -  ${this.$options.filters.dateFormat(
                    this.range.end
                )}`
            }
            props.value = result
            return props
        }

        addDays(date, days) {
            return this.$dateFns.format(this.$dateFns.addDays(date, days))
        }

        dayclick(day) {
            if (!this.selectedStartDate) {
                this.selectedStartDate = this.$dateFns.parseISO(day.id)
            }
        }

        popoverWillShow() {
            this.selectedStartDate = null
        }

        get textFieldClass() {
            let className = ''
            if (this.$breakpoint.smAndDown) {
                className += 'caption'
            }
            className += ' date-range-input ' + this.product

            return className
        }
    }
</script>

<style scoped lang="scss">
    .date-range-input::v-deep {
        @media (min-width: 1400px) and (max-width: 1635px) {
            input {
                font-size: 13px;
            }
        }
        @media (min-width: 1325px) and (max-width: 1399px) {
            input {
                font-size: 12px;
            }
        }
        @media (min-width: 1245px) and (max-width: 1324px) {
            input {
                font-size: 11px;
            }
        }
        @media (min-width: 1165px) and (max-width: 1244px) {
            input {
                font-size: 10px;
            }
        }
        @media (min-width: 1090px) and (max-width: 1164px) {
            input {
                font-size: 9px;
            }
        }
        @media (min-width: 961px) and (max-width: 1089px) {
            input {
                font-size: 8px;
            }
        }
    }

    .date-range-input.flights::v-deep {
        @media (min-width: 1636px) and (max-width: 1840px) {
            input {
                font-size: 14px;
            }
        }
        @media (min-width: 1400px) and (max-width: 1635px) {
            input {
                font-size: 11px;
            }
        }
        @media (min-width: 1325px) and (max-width: 1399px) {
            input {
                font-size: 10px;
            }
        }
        @media (min-width: 1245px) and (max-width: 1324px) {
            input {
                font-size: 9px;
            }
        }
        @media (min-width: 1165px) and (max-width: 1244px) {
            input {
                font-size: 9px;
            }
        }
        @media (min-width: 1090px) and (max-width: 1164px) {
            input {
                font-size: 8px;
            }
        }
        @media (min-width: 961px) and (max-width: 1089px) {
            input {
                font-size: 7px;
            }
        }
    }
</style>
