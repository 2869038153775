var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('tourist-form',{attrs:{"index":_vm.index,"label":"transfers.passenger","disabled":_vm.disabled,"phone-required":true,"corporate-policy-violation":_vm.offer.corporatePolicyViolation},scopedSlots:_vm._u([(!_vm.isPackage)?{key:"extraFields",fn:function(){return [(_vm.mealTypes || _vm.seatAllocations)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.mealTypes && _vm.mealTypes.length)?_c('v-select',{attrs:{"items":_vm.mealTypes,"item-text":"name","item-value":"code","label":("" + (_vm.$t('flights.meal_type'))),"clearable":"","disabled":_vm.disabled},on:{"change":function (code) {
                                    _vm.changeExtraOptions(_vm.index, 'mealTypeCode', code)
                                }}}):_vm._e()],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.seatAllocations && _vm.seatAllocations.length)?_c('v-select',{attrs:{"item-text":"name","item-value":"code","items":_vm.seatAllocations,"label":("" + (_vm.$t('flights.seat_allocation'))),"clearable":"","disabled":_vm.disabled},on:{"change":function (code) {
                                    _vm.changeExtraOptions(_vm.index, 'seatAllocationCode', code)
                                }}}):_vm._e()],1)],1)],1):_vm._e(),_vm._v(" "),_c('v-row',[(_vm.airlines.length)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.airlines,"label":("" + (_vm.$t('frequent_flyer_card_type'))),"placeholder":("" + (_vm.$t('frequent_flyer_card_type'))),"item-text":"name","item-value":"value","disabled":_vm.disabled,"clearable":"","value":(_vm.getFrequentFlyerCard[_vm.index] && _vm.getFrequentFlyerCard[_vm.index].airlineCode) || ''},on:{"change":function (code) {
                                _vm.frequentFlyerCard(_vm.index, 'frequentFlyerCardType', code)
                            }}})],1):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":("" + (_vm.$t('frequent_flyer_card_number'))),"placeholder":("" + (_vm.$t('frequent_flyer_card_number'))),"disabled":_vm.disabled || _vm.isNumberDisabled(_vm.index),"value":(_vm.getFrequentFlyerCard[_vm.index] && _vm.getFrequentFlyerCard[_vm.index].number) || ''},on:{"input":function (code) {
                                _vm.frequentFlyerCard(_vm.index, 'frequentFlyerCardNumber', code)
                            }}})],1)],1)]},proxy:true}:null],null,true)}),_vm._v(" "),_c('tourist-passport-form',{attrs:{"index":_vm.index,"disabled":_vm.disabled,"corporate-policy-violation":_vm.offer.corporatePolicyViolation}}),_vm._v(" "),(!_vm.isPackage)?_c('extra-baggage-form',{attrs:{"index":_vm.index,"disabled":_vm.disabled,"offer":_vm.offer}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }