<script>
    import {Component} from 'vue-property-decorator'
    import {hotelsStore} from '~/store'
    import HotelRoomOffers from '~/components/search/offers/HotelRoomOffers'

    //TODO Need refactoring and remove it
    @Component
    export default class HotelInfoRoomOffers extends HotelRoomOffers {
        get searchRequest() {
            return hotelsStore.singleHotelSearchRequest
        }

        get searchRequestTourists() {
            return hotelsStore.singleHotelSearchRequestTourists
        }
    }
</script>
