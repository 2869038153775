<template>
    <v-card outlined class="mb-2">
        <v-alert color="grey lighten-4" class="black--text mb-0">
            <div class="d-flex flex-wrap flex-md-nowrap" :class="$breakpoint.smAndDown ? 'flex-column' : ''">
                <div class="d-flex justify-md-end flex-column">
                    <span class="body-1 text-capitalize" style="line-height: 25px;">
                        {{ $t(`productType.${service.serviceType.toLowerCase()}`) }}
                        <v-icon class="infoIcon" right small color="black" @click="serviceInfo">
                            mdi-information-outline
                        </v-icon>
                    </span>
                    <small v-if="service.refNum">Ref # {{ service.refNum }}</small>
                </div>
                <v-spacer class="hidden-sm-and-down" />
                <div class="d-flex justify-md-end">
                    <div class="pt-2" :class="{'text-right': !$breakpoint.smAndDown}">
                        <v-icon v-if="service.status.toUpperCase() === 'CONFIRMED'" :color="statusColor">
                            mdi-checkbox-marked-circle
                        </v-icon>
                        <v-icon v-else :color="statusColor">
                            mdi-checkbox-blank-circle
                        </v-icon>
                        {{ $t(`bookingStatus.${status}`) }}
                        <small v-if="lastTicketingDate" class="d-block">
                            {{ $t('flights.last_ticketing_date') }}:
                            <strong>{{ lastTicketingDate | dateTimeFormat }}</strong>
                        </small>
                    </div>
                </div>
            </div>
        </v-alert>
        <v-card-text>
            <slot name="orderInfo" />
            <v-divider class="mt-3" />
            <slot name="serviceInfo" />
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import {runtimeStore} from '@/store'

    @Component
    export default class ServiceCard extends Vue {
        @Prop() icon
        @Prop() service

        serviceInfo() {
            this.$emit('showInfo')
        }

        get statusColor() {
            return runtimeStore.orderServiceStatusColor(this.status)
        }

        get status() {
            return runtimeStore.orderServiceStatus(this.service.status)
        }

        get lastTicketingDate() {
            return this.service.serviceDetails[0].lastTicketingDate
        }
    }
</script>

<style lang="scss" scoped>
    .thumb {
        max-width: 350px;
    }

    .col-title {
        white-space: nowrap;
        max-width: fit-content;
    }

    .info-text {
        color: black;
        font-size: 1rem;
        font-style: italic;
    }

    .payment-status {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        min-width: fit-content;
        max-width: fit-content;
    }
</style>
