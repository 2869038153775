<template>
    <v-form class="filters-form elevation-1 px-4 py-2 mb-4" @keyup.native.enter="filter">
        <v-row dense>
            <v-col cols="12" md="12">
                <v-radio-group v-model="filters.active" dense :row="!isXS" hide-details class="ma-0 pa-0">
                    <v-radio :label="$t('all_bookings')" value="all" />
                    <v-radio :label="$t('future_trips')" :value="true" />
                    <v-radio :label="$t('past_trips')" :value="false" />
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="4">
                <order-create-daterange
                    :_start-date.sync="filters.createdDateFrom"
                    :_end-date.sync="filters.createdDateTo"
                    :start-date-label="$t('create_date_from')"
                    :end-date-label="$t('create_date_to')"
                    clearable
                    dense
                    :outlined="false"
                    :single-line="false"
                    :no-title="true"
                />
            </v-col>
            <v-col md="2" cols="12">
                <v-text-field v-model="filters.orderId" dense :label="$t('order_number')" />
            </v-col>
            <v-col md="2" cols="12">
                <v-text-field v-model="filters.travelerName" dense :label="$t('traveler_name')" />
            </v-col>
            <v-col md="2" cols="12">
                <v-select
                    v-model="filters.processStatuses"
                    :items="processStatuses"
                    clearable
                    multiple
                    dense
                    :label="$t('process_statuses')"
                />
            </v-col>
            <v-col v-if="isTourOperator" md="2" cols="12">
                <v-select
                    v-model="filters.paymentMethod"
                    :items="paymentMethods"
                    clearable
                    dense
                    item-text="name"
                    item-value="id"
                    return-object
                    :label="$t('payment_method')"
                />
            </v-col>
            <v-col v-else-if="isAgency || isCorporate" md="2" cols="12">
                <v-text-field v-model="filters.paymentMethod" dense :label="$t('payment_method')" />
            </v-col>
            <v-col v-else md="2" cols="12">
                <city-autocomplete v-model="filters.city" dense single-line />
            </v-col>
        </v-row>
        <v-expansion-panels flat tile dense>
            <v-expansion-panel>
                <v-expansion-panel-header class="pl-1 width-auto">
                    {{ $t('additional_parameters') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row dense>
                        <v-col cols="12" md="12">
                            <v-switch
                                v-model="filters.filterServices"
                                :label="$t('apply_filter_to_services')"
                                hide-details
                            />
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col md="2" cols="12">
                            <v-select
                                v-model="filters.serviceTypes"
                                :items="serviceTypes"
                                clearable
                                multiple
                                dense
                                :label="$t('service_types')"
                            />
                        </v-col>
                        <v-col md="2" cols="12">
                            <v-text-field v-model="filters.serviceName" dense :label="$t('service_name')" />
                        </v-col>
                        <v-col md="4" cols="12">
                            <order-service-daterange
                                :_start-date.sync="filters.serviceStartDateFrom"
                                :_end-date.sync="filters.serviceStartDateTo"
                                :start-date-label="$t('start_date_from')"
                                :end-date-label="$t('start_date_to')"
                                clearable
                                dense
                                :outlined="false"
                                :single-line="false"
                                :no-title="true"
                            />
                        </v-col>
                        <v-col md="4" cols="12">
                            <order-service-daterange
                                :_start-date.sync="filters.serviceEndDateFrom"
                                :_end-date.sync="filters.serviceEndDateTo"
                                :start-date-label="$t('end_date_from')"
                                :end-date-label="$t('end_date_to')"
                                clearable
                                dense
                                :outlined="false"
                                :single-line="false"
                                :no-title="true"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="isTourOperator || isAgency" dense>
                        <v-col md="2" cols="12">
                            <v-select
                                v-model="filters.customerPaymentStatuses"
                                :items="paymentStatuses"
                                clearable
                                multiple
                                dense
                                :label="$t('customer_payment_statuses')"
                            />
                        </v-col>
                        <v-col md="2" cols="12">
                            <v-select
                                v-model="filters.supplierPaymentStatuses"
                                :items="paymentStatuses"
                                clearable
                                multiple
                                dense
                                :label="$t('supplier_payment_statuses')"
                            />
                        </v-col>
                        <v-col md="2" cols="12">
                            <v-select
                                v-model="filters.customerPenalty"
                                :items="penalties"
                                clearable
                                multiple
                                dense
                                :label="$t('customer_penalty')"
                            />
                        </v-col>
                        <v-col md="2" cols="12">
                            <v-select
                                v-model="filters.supplierPenalty"
                                :items="penalties"
                                clearable
                                multiple
                                dense
                                :label="$t('supplier_penalty')"
                            />
                        </v-col>
                        <v-col md="2" cols="12">
                            <v-text-field v-model="filters.customerCompany" dense :label="$t('company')" />
                        </v-col>
                        <v-col md="2" cols="12">
                            <city-autocomplete v-model="filters.city" dense single-line />
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-row dense>
            <v-col md="2" cols="12">
                <v-btn class="white" small @click="filter">
                    {{ $t('filter') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import CityAutocomplete from '~/components/search/forms/CityAutocomplete'
    import OrderCreateDaterange from '~/components/account/filters/OrderCreateDaterange'
    import OrderServiceDaterange from '~/components/account/filters/OrderServiceDaterange'
    import {authStore, persistentStore} from '@/utils/store-accessor'
    import LoadMixin from '@/mixins/LoadMixin'

    @Component({
        components: {
            CityAutocomplete,
            OrderCreateDaterange,
            OrderServiceDaterange,
        },
        mixins: [LoadMixin],
    })
    export default class OrderFilters extends Vue {
        modal = false
        filters = {}
        paymentMethods = []

        async created() {
            this.paymentMethods = await this.$api.paymentMethods.get({'is active': true})
        }

        load() {
            this.filters = Object.assign({}, persistentStore.orderFilters)
        }

        get stateFilters() {
            return persistentStore.orderFilters
        }

        get isXS() {
            return this.$breakpoint.xs
        }

        get serviceTypes() {
            const serviceTypes = [
                'productType.accommodation',
                'productType.flight',
                'productType.transfer',
                'productType.train',
                'productType.aeroexpressTransfer',
                'productType.carRent',
                'productType.insurance',
                'productType.ownCharter',
                'productType.excursion',
                'productType.ownCruise',
                'productType.ownVisa',
                'productType.tour',
                'productType.extraService',
            ]
            return serviceTypes.map(value => ({value, text: this.$t(value)}))
        }

        get processStatuses() {
            const processStatuses = [
                'CONFIRMATION_PENDING',
                'CONFIRMED',
                'CONFIRMATION_PROCESSING',
                'CANCELLATION_PENDING',
                'MODIFICATION_PENDING',
                'CANCELED',
                'REJECTED',
                'PRECONFIRMED',
                'PENDING_TICKETING',
                'ESTIMATED',
            ]
            return processStatuses.map(value => ({value, text: this.$t(`bookingStatus.${value}`)}))
        }

        get paymentStatuses() {
            const paymentStatuses = [
                'NO_BILL',
                'PAID',
                'BILL',
                'IN_TRANSIT',
                'OVERDUE',
                'NO_FULLY_BILL',
                'PARTIAL_PAID',
                'INVOICE_WAITING',
            ]
            return paymentStatuses.map(value => ({value, text: this.$t(`paymentStatus.${value}`)}))
        }

        get penalties() {
            const penalties = ['FREE', 'PAID', 'INFO', 'WARNING', 'ERROR']
            return penalties.map(value => ({value, text: this.$t(`penalty.${value}`)}))
        }

        filter() {
            persistentStore.SET_ORDER_FILTERS(Object.assign({}, this.filters))
            this.modal = false
            this.$emit('filter')
        }

        get isTourOperator() {
            return authStore.isTourOperator
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isCorporate() {
            return authStore.isCorporate
        }
    }
</script>

<style scoped lang="scss">
    .filters-form::v-deep {
        .v-expansion-panel-header {
            min-height: auto;
            padding: 0;
            margin: 0 0 10px;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.6);
        }
        .v-expansion-panel-content__wrap {
            padding: 0;
        }

        .v-text-field .v-label,
        .v-radio .v-label,
        .v-input--switch .v-label,
        .v-text-field input,
        .v-select__selections {
            font-size: 12px;
        }
    }
</style>
