<template>
    <v-row v-if="earlyCheckIn || lateCheckOut">
        <v-col cols="12">
            <h4 class="text-subtitle-1 font-weight-bold mb-2">
                {{ $t('earlyCheckInTitle') + '/' + $t('lateCheckOutTitle') }}
            </h4>
            <div class="d-flex">
                <div v-if="checkInFrom || checkInTo" class="d-flex">
                    <div class="font-weight-bold me-1">
                        {{ `${$t('earlyCheckInTitle')}:` }}
                    </div>
                    <div v-if="checkInFrom" class="mx-1">
                        {{ `${$t('time_from')} ${checkInFrom}` }}
                    </div>
                    <div v-if="checkInTo" class="mx-1">
                        {{ `${$t('time_until')} ${checkInTo}` }}
                    </div>
                </div>
                <div v-if="checkOutFrom || checkOutTo" class="ml-4 d-flex">
                    <div class="font-weight-bold me-1">
                        {{ `${$t('lateCheckOutTitle')}:` }}
                    </div>
                    <div v-if="checkOutFrom" class="mx-1">
                        {{ `${$t('time_from')} ${checkOutFrom}` }}
                    </div>
                    <div v-if="checkOutTo" class="mx-1">
                        {{ `${$t('time_until')} ${checkOutTo}` }}
                    </div>
                </div>
            </div>
        </v-col>
        <v-col v-if="earlyCheckIn" cols="12" md="6">
            <v-select
                :label="$t('earlyCheckIn')"
                :items="earlyCheckIn"
                item-value="time"
                item-text="name"
                :value="checkInExtraService"
                clearable
                :disabled="disabled"
                :class="'pt-0'"
                @change="
                    value => {
                        updateEarlyCheckInOut(value, 'earlyCheckIn')
                    }
                "
            />
        </v-col>
        <v-col v-if="lateCheckOut" cols="12" md="6">
            <v-select
                :label="$t('lateCheckOut')"
                :items="lateCheckOut"
                item-value="time"
                item-text="name"
                clearable
                :value="checkOutExtraService"
                :disabled="disabled"
                :class="'pt-0'"
                @change="
                    value => {
                        updateEarlyCheckInOut(value, 'lateCheckOut')
                    }
                "
            />
        </v-col>
    </v-row>
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator'
    import {hotelsStore, hotelsRuntimeStore} from '~/store'

    function selectOptions(optionKey) {
        if (hotelsStore.bookingAdditionalOptions[0]?.[optionKey]) {
            return hotelsStore.bookingAdditionalOptions[0][optionKey].map(opt => {
                return {
                    ...opt,
                    name: `${opt.time} (+${this.$options.filters.price({
                        amount: opt.price.amount,
                        currency: opt.price.currency,
                    })})`,
                }
            })
        }
        return null
    }

    @Component
    export default class EarlyCheckInLateCheckOut extends Vue {
        @Prop({default: false}) disabled
        @Prop({required: true}) roomIndex

        updateEarlyCheckInOut(value, type) {
            const data =
                type === 'earlyCheckIn'
                    ? this.earlyCheckIn.find(el => el.time === value)
                    : this.lateCheckOut.find(el => el.time === value)
            if (!data) {
                hotelsStore.DELETE_CHECK_IN_OUT_EXTRA_SERVICE({roomIndex: this.roomIndex, type})
                return
            }
            hotelsStore.SET_CHECK_IN_OUT_EXTRA_SERVICE({
                type,
                ...data,
                roomIndex: this.roomIndex,
            })
        }

        get earlyCheckIn() {
            return selectOptions.call(this, 'earlyCheckIn')
        }

        get lateCheckOut() {
            return selectOptions.call(this, 'lateCheckOut')
        }

        get checkInExtraService() {
            return hotelsStore.checkInOutExtraService[this.roomIndex]?.earlyCheckIn?.time
        }

        get checkOutExtraService() {
            return hotelsStore.checkInOutExtraService[this.roomIndex]?.lateCheckOut?.time
        }

        get checkInFrom() {
            return hotelsRuntimeStore.hotelInfoResponse?.hotelDescriptionInfo.checkInFrom
        }

        get checkInTo() {
            return hotelsRuntimeStore.hotelInfoResponse?.hotelDescriptionInfo.checkInTo
        }

        get checkOutFrom() {
            return hotelsRuntimeStore.hotelInfoResponse?.hotelDescriptionInfo.checkOutFrom
        }

        get checkOutTo() {
            return hotelsRuntimeStore.hotelInfoResponse?.hotelDescriptionInfo.checkOutTo
        }
    }
</script>
