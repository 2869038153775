<template>
    <v-rating
        color="amber"
        background-color="grey lighten-2"
        empty-icon="mdi-star"
        small
        dense
        readonly
        :value="parseInt(category)"
    />
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class HotelStars extends Vue {
        @Prop({required: true}) category
    }
</script>
