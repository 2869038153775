<script>
    import {Component, Watch, Prop} from 'nuxt-property-decorator'
    import AirportsSearchMixin from '~/mixins/AirportsSearchMixin'
    import TrainStationsSearchMixin from '~/mixins/TrainStationsSearchMixin'
    import CityHotelAutocomplete from '~/components/search/forms/CityHotelAutocomplete'

    @Component({
        mixins: [AirportsSearchMixin, TrainStationsSearchMixin],
    })
    export default class TransferPointAutocomplete extends CityHotelAutocomplete {
        @Prop({default: 'TRANSFER'}) productType

        @Watch('value')
        onChangeValue(val) {
            this.city = val
            if (val && Object.keys(val).length > 0) {
                this.entries = [val]
            }
        }

        async initSearch(val) {
            if (this.loading || (this.labels.length > 0 && this.labels.indexOf(val) !== -1) || !val) return
            this.loading = true
            try {
                const cities = await this.searchCities(val)
                this.entries = cities
                const citiesWithAirportsAndTrainStations = await Promise.all(cities.map(this.cityMapper))
                this.entries = citiesWithAirportsAndTrainStations.flat()
            } finally {
                this.loading = false
            }
        }

        async cityMapper(city) {
            const [airports, trainStations] = await Promise.all([
                this.searchAirports({
                    locationId: city.id,
                    limit: 0,
                }),
                this.searchTrainStations({
                    locationId: city.id,
                    limit: 0,
                }),
            ])
            trainStations.map(trainStation => {
                trainStation.cityName = city.name
                trainStation.countryName = city.countryName
                trainStation.parentName = city.countryName
                trainStation.countryId = city.countryId
            })
            return [...[city], ...airports, ...trainStations]
        }

        icon(type) {
            switch (type) {
                case 'hotel':
                    return this.hotelIcon
                case 'airport':
                    return this.airportIcon
                case 'trainStation':
                    return this.trainStationIcon
                default:
                    return this.locationIcon
            }
        }

        get airportIcon() {
            return 'mdi-airplane-takeoff'
        }

        get trainStationIcon() {
            return 'mdi-train'
        }
    }
</script>
