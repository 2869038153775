<script>
    import {Component, Prop} from 'vue-property-decorator'
    import {activitiesRuntimeStore} from '~/store'
    import Breadcrumbs from '~/components/Breadcrumbs'

    @Component
    export default class ActivityBreadcrumbs extends Breadcrumbs {
        @Prop({required: true}) searchRequest
        @Prop({default: () => {}}) product

        get items() {
            const getSearchPageLabel = () =>
                `${activitiesRuntimeStore.city.name}, ${activitiesRuntimeStore.city.countryName}`
            const addSearchPageLink = items => {
                items.push({
                    text: getSearchPageLabel(),
                    to: activitiesRuntimeStore.searchPageLink(this.searchRequest),
                    nuxt: true,
                    exact: true,
                })
            }

            const items = [this.homeItem]
            //TODO Add all routes
            switch (this.$route.name) {
                case 'activities':
                    items.push({
                        text: getSearchPageLabel(),
                        disabled: true,
                    })
                    break
                case 'activity':
                    addSearchPageLink(items)
                    items.push({
                        text: decodeURIComponent(this.$route.query.name),
                        disabled: true,
                    })
                    break
                case 'activityBooking':
                    addSearchPageLink(items)
                    items.push({
                        text: this.product.info.name,
                        disabled: true,
                    })
                    items.push({
                        text: this.$t('reservation'),
                        disabled: true,
                    })
                    break
                case 'activityConfirmation':
                    addSearchPageLink(items)
                    items.push({
                        text: this.product.info.name,
                        disabled: true,
                    })
                    items.push({
                        text: this.$t('confirmation'),
                        disabled: true,
                    })
                    break
            }
            return items
        }
    }
</script>
