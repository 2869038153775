<template>
    <client-only>
        <div>
            <h6 class="text-h6">
                {{ $t('tours.available_travel_start_dates') }}
            </h6>
            <vc-date-picker
                v-model="date"
                :locale="$i18n.locale"
                :columns="$breakpoint.lgAndDown ? 1 : 2"
                is-expanded
                is-inline
                :available-dates="availableDates"
            />
        </div>
    </client-only>
</template>

<script>
    import {Component, Mixins} from 'vue-property-decorator'
    import TourDatepickerMixin from '@/components/tours/mixins/TourDatepickerMixin'
    import {toursStore} from '@/utils/store-accessor'

    @Component
    export default class TourDatepicker extends Mixins(TourDatepickerMixin) {
        get date() {
            return this.$dateFns.parseISO(toursStore.searchRequest.startDateFrom)
        }

        set date(val) {
            try {
                this.$router.push({
                    name: 'tour',
                    query: Object.assign({}, this.$route.query, {
                        startDateFrom: this.$dateFns.format(val),
                    }),
                })
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }
    }
</script>
