<template>
    <v-tabs v-model="mobileTabs" fixed-tabs class="sticky-bar">
        <v-tab v-for="tab in tabs" :key="tab">
            <v-icon>{{ tab }}</v-icon>
        </v-tab>
    </v-tabs>
</template>

<script>
    import {Vue, Component, Model, Watch} from 'vue-property-decorator'

    @Component
    export default class MobileSearchPageTabs extends Vue {
        @Model('change') value
        mobileTabs = 1
        defaultTabs = ['mdi-format-list-checks', 'mdi-format-list-text', 'mdi-map-marker']

        @Watch('value')
        onChangeValue(val) {
            this.mobileTabs = val
        }

        get tabs() {
            return this.defaultTabs
        }

        @Watch('mobileTabs')
        onChangeMobileTabs(val) {
            this.$emit('change', val)
        }
    }
</script>
