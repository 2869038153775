<template>
    <v-dialog v-model="modal" hide-overlay width="500">
        <div class="modal-close-btn-block">
            <v-icon class="v-card-close" @click="modal = false">
                mdi-close
            </v-icon>
        </div>
        <v-card>
            <v-card-title class="mb-4">
                {{ $t('passport') }}
            </v-card-title>
            <v-card-text>
                <v-form ref="form">
                    <v-select v-model="passport.type" :items="passportTypes" :label="$t('document_type')" clearable>
                        <template v-slot:item="{item}">
                            {{ $t(`documentTypes.${item}`) }}
                        </template>
                        <template v-slot:selection="{item}">
                            {{ $t(`documentTypes.${item}`) }}
                        </template>
                    </v-select>
                    <v-text-field v-model="passport.number" :label="$t('document_number')" />
                    <v-menu
                        v-model="showIssueDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{on}">
                            <v-text-field
                                :value="passport.issueDate | dateShortFormat"
                                :label="$t('issue_date')"
                                clearable
                                readonly
                                background-color="white"
                                prepend-inner-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker
                            v-model="passport.issueDate"
                            :locale="$i18n.locale"
                            :max="today"
                            @change="showIssueDate = false"
                        />
                    </v-menu>
                    <v-menu
                        v-model="showExpireDatePicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{on}">
                            <v-text-field
                                :value="passport.validTill | dateShortFormat"
                                :label="$t('expire_date')"
                                clearable
                                readonly
                                background-color="white"
                                prepend-inner-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker
                            v-model="passport.validTill"
                            :locale="$i18n.locale"
                            :min="today"
                            @change="showExpireDatePicker = false"
                        />
                    </v-menu>
                    <v-switch
                        v-model="passport.active"
                        color="primary"
                        :label="passport.active ? $t('active') : $t('inactive')"
                    />
                    <v-btn :disabled="isLoading" block color="primary" class="mt-4" @click="save">
                        {{ $t('save') }}
                    </v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {EventBus, SHOW_PASSPORT_MODAL_EVENT} from '@/utils/event-bus'

    @Component
    export default class PassportModal extends Vue {
        personId = null
        passport = {
            id: null,
            type: null,
            number: null,
            issueDate: null,
            validTill: null,
            active: true,
        }
        modal = false
        showIssueDate = false
        showExpireDatePicker = false
        isLoading = false
        type = null

        mounted() {
            EventBus.$on(SHOW_PASSPORT_MODAL_EVENT, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_PASSPORT_MODAL_EVENT, this.showModal)
        }

        showModal(type, passport, personId) {
            this.type = type
            this.passport = passport
                ? passport
                : {
                      type: null,
                      number: null,
                      issueDate: null,
                      validTill: null,
                      active: true,
                  }
            this.personId = personId ? personId : null
            this.modal = true
        }

        get passportTypes() {
            return ['PASSPORT', 'PASSPORT_EXTERNAL', 'BIRTH_CERTIFICATE', 'OTHER']
        }

        get today() {
            return new Date().toISOString()
        }

        async save() {
            this.isLoading = true
            const {type, number, issueDate, validTill, active} = this.passport,
                passport = {
                    type,
                    number,
                    issueDate,
                    validTill,
                    active,
                }
            try {
                if (this.type == 'crmperson') {
                    if (this.passport.id) {
                        await this.$api.crmPersonPassport.put(this.personId, this.passport.id, passport)
                    } else {
                        await this.$api.crmPersonPassport.post(this.personId, passport)
                    }
                }
                if (this.type == 'person') {
                    if (this.passport.id) {
                        await this.$api.personPassport.put(this.personId, this.passport.id, passport)
                    } else {
                        await this.$api.personPassport.post(this.personId, passport)
                    }
                }
                this.$toast.success(this.$t('messages.success.passport_saved'))
                this.$emit('saved')
            } catch (e) {
                this.$toast.error(this.$t('error_message.save_error'))
            } finally {
                this.isLoading = false
                this.modal = false
            }
        }
    }
</script>

<style scoped lang="scss">
    .modal-close-btn-block {
        position: relative;
    }
</style>
