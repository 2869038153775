<template>
    <v-card class="mb-5 pa-md-3">
        <v-row>
            <v-col cols="3">
                <v-img class="thumb" :src="image | imageUrl(600)" lazy-src="/placeholder.png" @error="onImgError">
                    <template v-slot:placeholder>
                        <v-row v-show="!imgError && image" class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
            </v-col>
            <v-col cols="9">
                <h3>
                    {{ info.productName }}
                </h3>
                <small v-if="info.serviceDescription" v-html="info.serviceDescription" />
            </v-col>
        </v-row>
        <small>
            <strong> {{ info.serviceName }} ({{ info.tariff }}) </strong>
        </small>
        <small class="mt-2">
            <conditions-label :offer-key="offer.offerKey" :price="offer.price" @conditions="showConditionsInfo" />
        </small>
        <original-currency-notification :price="totalPrice" class="mt-5" />
        <div v-if="extraServices.length" class="mt-6">
            <h4 class="body-2 full-width mb-2">
                {{ $t('extra_services') }}
            </h4>
            <v-row class="flex-column">
                <v-col v-for="service in extraServices" :key="service.type" class="py-0 d-flex align-center">
                    <span>{{ service.name }}</span>
                    <span v-if="service.quantity > 1">&nbsp;* {{ service.quantity }} </span>
                </v-col>
            </v-row>
        </div>
        <v-list-item class="text-right pa-0">
            <v-list-item-title>
                <h2 class="py-2">{{ $t('total') }}: {{ totalPrice | price }}</h2>
            </v-list-item-title>
        </v-list-item>
        <conditions-modal>
            <template v-slot:info>
                <extra-service-conditions-modal-info />
            </template>
        </conditions-modal>
    </v-card>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {extraServicesStore} from '~/store'
    import ConditionsModal from '~/components/modals/ConditionsModal'
    import ConditionsLabel from '~/components/snippets/ConditionsLabel'
    import {EventBus, SHOW_EXTRA_SERVICE_CONDITIONS_INFO} from '@/utils/event-bus'
    import ExtraServiceConditionsModalInfo from '@/components/extraServices/snippets/ExtraServiceConditionsModalInfo'
    import OriginalCurrencyNotification from '@/components/booking/snippets/OriginalCurrencyNotification'

    @Component({
        components: {
            OriginalCurrencyNotification,
            ExtraServiceConditionsModalInfo,
            ConditionsModal,
            ConditionsLabel,
        },
    })
    export default class ExtraServiceOrderSummary extends Vue {
        @Prop({required: true}) offer
        @Prop({required: true}) info
        @Prop({default: true}) prebooked

        imgError = false

        onImgError() {
            this.imgError = true
            return false
        }

        showConditionsInfo() {
            EventBus.$emit(SHOW_EXTRA_SERVICE_CONDITIONS_INFO, this.offer)
        }

        get image() {
            if (!this.info.images) return null
            let mainImage = this.info.images.find(image => image.mainImage) || {url: null}
            if (!mainImage.url && this.info.images.length > 0) {
                mainImage = this.info.images[0]
            }
            if (
                !mainImage.linkedEntityName ||
                (mainImage.linkedEntityName && mainImage.linkedEntityName !== this.info.serviceName)
            ) {
                mainImage =
                    this.info.images.find(image => image.linkedEntityName === this.info.serviceName) || mainImage
            }
            return mainImage && mainImage.url ? mainImage.url : null
        }

        get extraServices() {
            return extraServicesStore.bookingExtraServices
        }

        get totalPrice() {
            return extraServicesStore.orderTotalPrice(this.offer, this.prebooked)
        }
    }
</script>
