<template>
    <div>
        <v-card class="main-page-bg pt-0 d-flex align-center" :min-height="350" tile flat :img="resort.background">
            <v-container>
                <div class="main-page-tabs-container">
                    <v-tabs v-model="productTabs" :show-arrows="false" :hide-slider="true">
                        <v-tab v-for="product in activeProducts" :key="product + '_tab'" :ripple="false">
                            <v-icon>
                                {{ products[product].ico }}
                            </v-icon>
                            <span class="hidden-sm-and-down">{{ $t(products[product].title) }}</span>
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="productTabs" class="overflow--visible">
                        <v-tab-item v-for="product in activeProducts" :key="product + '_form'">
                            <main-page-form-wrapper>
                                <template v-slot:form>
                                    <component :is="products[product].form" :city-id="resort.cityId" />
                                </template>
                            </main-page-form-wrapper>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </v-container>
        </v-card>
        <v-container>
            <h1 class="h-light mt-12 mb-6">
                {{ city.name }}
            </h1>
            <template v-if="$breakpoint.mdAndUp">
                <v-tabs v-model="descriptionTabIndex" grow center-active class="description-tabs mb-6">
                    <v-tab v-for="(descriptionTab, index) in descriptionTabs" :key="index">
                        {{ descriptionTab.title }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="descriptionTabIndex" class="overflow--visible">
                    <v-tab-item v-for="(descriptionTab, index) in descriptionTabs" :key="index">
                        <vue-markdown v-if="descriptionTab.key !== 'gallery'" :source="descriptionTab.content" />
                        <cms-gallery v-else :items="[...resort.video, ...descriptionTab.content]" />
                    </v-tab-item>
                </v-tabs-items>
            </template>
            <template v-else>
                <v-expansion-panels accordion>
                    <v-expansion-panel v-for="(descriptionTab, index) in descriptionTabs" :key="index">
                        <v-expansion-panel-header>
                            {{ descriptionTab.title }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <vue-markdown v-if="descriptionTab.key !== 'gallery'" :source="descriptionTab.content" />
                            <cms-gallery v-else :items="[...resort.video, ...descriptionTab.content]" />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </template>
            <popular-destinations
                v-if="resort.popular_destinations && resort.popular_destinations.length"
                :destinations="resort.popular_destinations"
                class="mt-14"
            />
            <blog v-if="resort.blogs && resort.blogs.length" :items="resort.blogs" class="mt-14" />
        </v-container>
    </div>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import MainPageFormWrapper from '~src/components/parts/mainPageFormWrapper.src'
    import PopularDestinations from '~src/components/parts/popularDestinations.src'
    import Blog from '~~/mods/asistour-test/components/snippets/Blog'
    import HotelSearchForm from '~src/components/search/forms/hotelSearchForm.src'
    import TransferSearchForm from '~src/components/transfers/search/forms/transferSearchForm.src'
    import ActivitySearchForm from '~src/components/activities/search/forms/activitySearchForm.src'
    import {CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'
    import {cmsStore, runtimeStore} from '@/utils/store-accessor'
    import VueMarkdown from 'vue-markdown'
    import CmsGallery from '@/components/snippets/CmsGallery'
    import {clone} from '@/utils/helpers'

    @Component({
        components: {
            CmsGallery,
            HotelSearchForm,
            TransferSearchForm,
            ActivitySearchForm,
            MainPageFormWrapper,
            Blog,
            PopularDestinations,
            VueMarkdown,
        },
        layout: 'blank',
    })
    export default class ResortPage extends Vue {
        resort = {}
        productTabs = 0
        descriptionTabIndex = 0
        city = {}

        async asyncData({$api, params: {link}, error}) {
            try {
                const resort = await $api.resort.get(link),
                    {
                        cities: [city],
                    } = await $api.locations.get({id: resort.cityId, limitCities: 1})
                return {resort, city}
            } catch (e) {
                error({statusCode: e.status, message: e.msg})
            }
        }

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, this.load)
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, this.load)
        }

        head() {
            if (!cmsStore.layoutSettings.head) return null
            const head = clone(cmsStore.layoutSettings.head)
            if (this.$config.host) {
                head.link = [
                    {
                        rel: 'canonical',
                        href: this.$config.host + this.$route.path,
                    },
                ]
            }
            if (this.resort.seo?.title) {
                head.titleTemplate = this.resort.seo.title
            }
            this.resort.seo?.meta.forEach(meta => {
                const metaIndex = head.meta.findIndex(({hid}) => hid === meta.hid)
                const {hid, name, content} = meta
                if (metaIndex !== -1) {
                    this.$set(head.meta, metaIndex, {hid, name, content})
                } else {
                    head.meta.push({hid, name, content})
                }
            })
            return head
        }

        async load() {
            try {
                this.resort = await this.$api.resort.get(this.$route.params.link)
                const {
                    cities: [city],
                } = await this.$api.locations.get({id: this.resort.cityId, limitCities: 1})
                this.city = city
            } catch (e) {
                this.resort = {}
                this.city = {}
            }
        }

        get activeProducts() {
            return Object.values(runtimeStore.config.activeProducts).filter(product =>
                ['hotels', 'transfers', 'activities'].includes(product)
            )
        }

        get products() {
            return {
                hotels: {
                    ico: 'mdi-office-building',
                    title: 'hotels',
                    form: 'hotel-search-form',
                },
                transfers: {
                    ico: 'mdi-bus',
                    title: 'transfers.transfers',
                    form: 'transfer-search-form',
                },
                activities: {
                    ico: 'mdi-lightning-bolt-outline',
                    title: 'activities.activities',
                    form: 'activity-search-form',
                },
            }
        }

        get descriptionTabs() {
            return ['description', 'specialization', 'spa', 'leisure', 'gallery']
                .filter(
                    key =>
                        (key !== 'gallery' && this.resort[key]) ||
                        (key === 'gallery' &&
                            (this.resort.gallery?.length || this.resort.video?.length) &&
                            this.resort[`${key}Active`])
                )
                .map(key => ({
                    key,
                    content: this.resort[key],
                    title: this.resort[`${key}Title`] || this.$t('resortTab')[key],
                }))
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .v-slide-group__prev {
        display: none !important;
    }

    .main-page-tabs-container .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
        > .v-icon,
        > span {
            color: white;
        }
    }

    .main-form::v-deep > .v-card__title {
        padding-top: 0;
        padding-bottom: 0;
    }

    .v-expansion-panels {
        z-index: 1500;
    }
</style>
