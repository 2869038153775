<template>
    <v-tooltip top>
        <template v-slot:activator="{on}">
            <v-icon right color="red" v-on="on">
                mdi-bag-checked
            </v-icon>
        </template>
        <slot>
            <v-subheader class="white--text px-0 pt-0">
                {{ $t('corporate_policy_not_match') }}
            </v-subheader>
            <div v-if="policies.length">
                {{ $t('violated_conditions') }}
                <div v-for="policy in policies" :key="policy.corporatePolicyId">
                    <span>
                        <span class="px-2">&#183;</span>
                        {{ policy.corporatePolicyName }}
                    </span>
                    <span>{{ policy.corporatePolicyDescription }}</span>
                </div>
            </div>
        </slot>
    </v-tooltip>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'

    @Component
    export default class CorporatePolicyViolation extends Vue {
        @Prop({
            default: () => [],
        })
        policies
    }
</script>
