<template>
    <div>
        <slot name="breadcrumbs">
            <breadcrumbs />
        </slot>
        <v-row>
            <v-col cols="12" md="8" class="text-center">
                <confirmation-status :company="company" />
                <v-btn color="primary" nuxt :to="{name: 'home'}">
                    {{ $t('book_other_services') }}
                </v-btn>
            </v-col>
            <v-col cols="12" md="4">
                <slot name="sidebar" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'nuxt-property-decorator'
    import Breadcrumbs from '@/components/Breadcrumbs'
    import ConfirmationStatus from '@/components/booking/ConfirmationStatus'

    @Component({
        components: {ConfirmationStatus, Breadcrumbs},
    })
    export default class ConfirmationPageLayout extends Vue {
        @Prop() company
    }
</script>
