<template>
    <search-summary-layout :search-disabled="searchDisabled" form-name="flight-search-form">
        <template v-slot:title>
            <v-row>
                <v-col v-for="location in locationLabel" :key="location" cols="12" class="body-2">
                    {{ location }}
                </v-col>
            </v-row>
        </template>
        <template v-slot:content>
            <div>
                {{ touristsTotal }}
                {{ $tc('flights.passenger', touristsTotal) }}
            </div>
        </template>
    </search-summary-layout>
</template>

<script>
    import {Vue, Component, Watch, Prop} from 'vue-property-decorator'
    import {flightsRuntimeStore} from '~/store'
    import SearchSummaryLayout from '~/components/parts/SearchSummaryLayout'
    import {searchRequest} from '~/utils/flights/flights-blank-states'

    //TODO Hide if empty searchRequest
    @Component({
        components: {
            SearchSummaryLayout,
        },
    })
    export default class FlightSearchSummary extends Vue {
        @Prop({default: false, type: Boolean}) searchDisabled
        @Prop({default: () => searchRequest()}) searchRequest
        @Prop({default: false, type: Boolean}) searchActive

        searchForm = false

        get searchFormComponent() {
            return 'activity-search-form'
        }

        get locale() {
            return this.$i18n.locale
        }

        get locationLabel() {
            return flightsRuntimeStore.routes.map(({departure, arrival, date}) => {
                return `${this.$options.filters.dateFormat(date)} | ${departure.cityName}, ${departure.countryName} (${
                    departure.iataCode
                }) - ${arrival.cityName}, ${arrival.countryName} (${arrival.iataCode})`
            })
        }

        get date() {
            return this.searchRequest.dateTime
        }

        get touristsTotal() {
            return this.searchRequest.adults + this.searchRequest.childrenAges.length
        }

        @Watch('searchActive')
        onLoadingChange(searchActive) {
            if (searchActive) {
                this.searchForm = false
            }
        }

        checkDisabled(on) {
            if (this.searchDisabled) return
            return on
        }
    }
</script>
