var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-card-title',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t(("transfers." + _vm.pointType + "_location")))+" - "+_vm._s(_vm.$t('airport')))]),_vm._v(" "),_c('v-card-text',{staticClass:"white--text"},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":((_vm.$t('transfers.flight_number')) + "*"),"rules":[
                    function (v) { return !!v || ((_vm.$t('transfers.flight_number')) + " " + (_vm.$t('validation.required'))); },
                    function (v) { return /^[A-Za-z]{2}[0-9]{3,4}$/.test(v) || ("" + (_vm.$t('transfers.flight_number_error'))); } ],"disabled":_vm.disabled},model:{value:(_vm.flightNumber),callback:function ($$v) {_vm.flightNumber=$$v},expression:"flightNumber"}}),_vm._v(" "),(!_vm.ownProduct)?_c('airports-search-input',{attrs:{"label":_vm.$t(
                        _vm.pointType === 'arrival'
                            ? 'transfers.departure_to_airport'
                            : 'transfers.arriving_from_airport'
                    ),"disabled":_vm.disabled},model:{value:(_vm.otherAirportCode),callback:function ($$v) {_vm.otherAirportCode=$$v},expression:"otherAirportCode"}}):_vm._e(),_vm._v(" "),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm._f("dateFormat")(_vm.date),"label":((_vm.$t('date')) + "*"),"clearable":"","readonly":"","background-color":"white","prepend-inner-icon":"mdi-calendar","rules":[function (v) { return !!v || ((_vm.$t('date')) + " " + (_vm.$t('validation.required'))); }],"disabled":_vm.disabled}},on))]}}]),model:{value:(_vm.showDatePicker),callback:function ($$v) {_vm.showDatePicker=$$v},expression:"showDatePicker"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"locale":_vm.$i18n.locale,"min":_vm.minDate,"max":_vm.maxDate},on:{"change":function($event){_vm.showDatePicker = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_vm._v(" "),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.time,"label":((_vm.$t('time')) + "*"),"readonly":"","background-color":"white","prepend-inner-icon":"mdi-clock","rules":[function (v) { return !!v || ((_vm.$t('time')) + " " + (_vm.$t('validation.required'))); }],"disabled":_vm.disabled}},on))]}}]),model:{value:(_vm.showTimePicker),callback:function ($$v) {_vm.showTimePicker=$$v},expression:"showTimePicker"}},[_vm._v(" "),_c('time-picker',{on:{"update":function($event){_vm.showTimePicker = false}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }