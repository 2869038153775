<template>
    <search-summary-layout :search-disabled="searchDisabled" form-name="transfer-search-form">
        <template v-slot:title>
            {{ locationLabel }}
        </template>
        <template v-slot:content>
            <div>
                {{ date | dateFormat }}
            </div>
            <div>
                {{ touristsTotal }}
                {{ $tc('transfers.passenger', touristsTotal) }}
            </div>
        </template>
    </search-summary-layout>
</template>

<script>
    import {Vue, Component, Watch, Prop} from 'vue-property-decorator'
    import {transfersRuntimeStore} from '~/store'
    import SearchSummaryLayout from '~/components/parts/SearchSummaryLayout'

    //TODO Hide if empty searchRequest
    @Component({
        components: {
            SearchSummaryLayout,
        },
    })
    export default class TransferSearchSummary extends Vue {
        @Prop({default: false, type: Boolean}) searchDisabled
        @Prop({required: true}) searchRequest

        @Watch('isSearchActive')
        onLoadingChange(searchActive) {
            if (searchActive) {
                this.searchForm = false
            }
        }

        get isSearchActive() {
            return transfersRuntimeStore.searchActive
        }

        get locationLabel() {
            return `${transfersRuntimeStore.departurePoint.name}, ${transfersRuntimeStore.departurePoint.parentName} -
            ${transfersRuntimeStore.arrivalPoint.name}, ${transfersRuntimeStore.arrivalPoint.parentName}`
        }

        get date() {
            return this.searchRequest.dateTime
        }

        get touristsTotal() {
            return this.searchRequest.adults + this.searchRequest.childrenAges.length
        }
    }
</script>
