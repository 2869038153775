<template>
    <v-container ref="container" fill-height fluid :style="containerStyle">
        <v-card outlined tile class="mx-auto v-card-transparent" min-width="320">
            <v-card-title class="white--text title word-break">
                {{ $t('sign_in') }}
            </v-card-title>
            <v-card-text class="white--text">
                <authorization-form @switch-forgot-password="forgotPasswordModal = true" dark />
            </v-card-text>
        </v-card>
        <v-dialog v-model="forgotPasswordModal" hide-overlay width="500">
            <v-card>
                <v-card-title class="title font-weight-light">
                    {{ $t('forgot_your_password') }}
                </v-card-title>
                <v-card-text>
                    <forgot-password-form
                        @switch-login="forgotPasswordModal = false"
                        @forgot-password-success="forgotPasswordModal = false"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import ForgotPasswordForm from '@/components/account/forms/ForgotPasswordForm'
    import AuthorizationForm from '@/components/account/forms/AuthorizationForm'
    import {AUTH_EVENT, EventBus} from '@/utils/event-bus'

    @Component({
        components: {AuthorizationForm, ForgotPasswordForm},
        layout: 'blank',
    })
    export default class B2cAuthorizationPage extends Vue {
        forgotPasswordModal = false

        mounted() {
            EventBus.$on(AUTH_EVENT, this.onAuth)
        }

        beforeDestroy() {
            EventBus.$off(AUTH_EVENT, this.onAuth)
        }

        onAuth() {
            this.$router.push({name: 'home'})
        }

        get containerStyle() {
            return {
                'background-image': 'url(/b2c-login-bg.jpg)',
                'background-size': 'cover',
                'background-position': '50%',
            }
        }
    }
</script>

<style scoped lang="scss">
    .v-card-transparent {
        border: 0;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.6);

        .v-input .v-input__slot {
            border-radius: 4px;
        }
    }

    .word-break {
        word-break: break-word;
    }
</style>
