<template>
    <div>
        <hotel-room-offers
            v-if="!needUpdateRooms && updateRoomsActive !== true"
            :offer="offer"
            :compare-mode="compareMode"
            :_expanded.sync="expanded"
            :_selected-offer-keys.sync="selectedOfferKeys"
            :full-card="false"
            :show-room-image="true"
            :_can-book.sync="canBook"
            :hotel-info="hotelInfo"
            :search-rooms-count="searchRoomsCount"
            @more-rooms="showAllRooms"
        >
            <template v-slot:price="{room}">
                <slot name="price" :room="room" />
            </template>
        </hotel-room-offers>
        <template v-else>
            <v-card-title v-if="!offer" class="title font-weight-light">
                {{ $t('loading_room_offers') }}...
            </v-card-title>
            <v-skeleton-loader transition="fade-transition" height="100" type="list-item-three-line" />
        </template>
    </div>
</template>

<script>
    import {Vue, Component, Prop, PropSync} from 'vue-property-decorator'
    import {hotelsRuntimeStore, packagesRuntimeStore, packagesStore} from '@/store'
    import HotelRoomOffers from '~/components/search/offers/HotelRoomOffers'
    import {hotelInfoResponse} from '@/utils/hotels/hotels-blank-states'

    @Component({
        components: {
            HotelRoomOffers,
        },
    })
    export default class HotelRoomOffersWrapper extends Vue {
        @Prop() offer
        @Prop({default: false}) compareMode
        @PropSync('_selectedOfferKeys', {default: () => []}) selectedOfferKeys
        @PropSync('_canBook', {default: () => false}) canBook
        @Prop({default: true}) fullCard
        @Prop({default: 1}) searchRoomsCount

        expanded = []
        hotelInfo = hotelInfoResponse()

        created() {
            for (let roomIndex = 1; roomIndex <= this.searchRoomsCount; roomIndex++) {
                this.$set(this.expanded, roomIndex, 5)
            }
        }

        //TODO Try to cache component in parent - keep-alive
        async mounted() {
            await this.$store.restored
            const {supplierCode, cityCode, hotelCode} = this.offer
            const hotelInfoRq = {supplierCode, cityCode, hotelCode}
            if (this.needUpdateRooms && !this.updateRoomsActive) {
                if (this.$route.name === 'packages') {
                    await packagesRuntimeStore.updatePackageOffers({
                        ...hotelInfoRq,
                        ...{offerId: this.offer.rooms[0].groupedOffers[0].offerKey},
                    })
                } else {
                    await hotelsRuntimeStore.updateRoomOffers(hotelInfoRq)
                }
            }
            try {
                this.hotelInfo = await this.$api.hotelInfo.get(hotelInfoRq)
            } catch (e) {
                this.hotelInfo = hotelInfoResponse()
            }
        }

        showAllRooms(roomGroupIndex) {
            this.$set(this.expanded, roomGroupIndex, this.expanded[roomGroupIndex] + 5)
        }

        get needUpdateRooms() {
            if (this.$route.name === 'packages') {
                return packagesStore.needUpdateRooms(this.offer)
            } else {
                return hotelsRuntimeStore.needUpdateRooms(this.offer)
            }
        }

        get updateRoomsActive() {
            if (this.$route.name === 'packages') {
                return packagesRuntimeStore.updateRoomsActive(this.offer)
            } else {
                return hotelsRuntimeStore.updateRoomsActive(this.offer)
            }
        }
    }
</script>
