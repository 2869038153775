<template>
    <flight-booking-page-layout v-if="loaded" :offer="offer" :search-request="searchRequest">
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="refreshExpiredOffers" />
            <flight-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:tourists="{options}">
            <flight-tourist-form :disabled="options.disabled" :index="options.index" :offer="offer" />
        </template>
        <template v-slot:orderCommentsForm="{bookingKey}">
            <booking-comments-form :disabled="!!bookingKey" :product-store="productStore" />
        </template>
        <template v-slot:forms="{bookingKey}">
            <flight-seats-map :offer="offer" :disabled="!!bookingKey" :search-request="searchRequest" />
        </template>
        <template v-slot:sidebar="{bookingKey}">
            <flight-search-summary :search-request="searchRequest" search-disabled />
            <flight-order-summary :offer="offer" :prebooked="!!bookingKey" />
        </template>
    </flight-booking-page-layout>
</template>

<script>
    import {Component, mixins, Watch} from 'nuxt-property-decorator'
    import {flightsRuntimeStore, flightsStore, runtimeStore} from '@/store'
    import FlightBookingPageLayout from '~/components/flights/booking/FlightBookingPageLayout'
    import FlightTouristForm from '@/components/booking/forms/FlightTouristForm'
    import FlightSearchSummary from '~/components/flights/search/FlightSearchSummary'
    import FlightOrderSummary from '~/components/flights/booking/FlightOrderSummary'
    import FlightBreadcrumbs from '~/components/flights/FlightBreadcrumbs'
    import FlightPageMixin from '~/components/flights/mixins/FlightPageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import {UTCToDate} from '@/utils/helpers'
    import FlightSeatsMap from '@/components/flights/booking/FlightSeatsMap'
    import BookingCommentsForm from '@/components/booking/forms/BookingCommentsForm'

    @Component({
        components: {
            FlightSeatsMap,
            ExpiredOffersSnackbar,
            FlightSearchSummary,
            FlightBookingPageLayout,
            FlightTouristForm,
            FlightOrderSummary,
            FlightBreadcrumbs,
            BookingCommentsForm,
        },
        layout: 'blank',
    })
    export default class FlightBookingPage extends mixins(FlightPageMixin) {
        loaded = false

        validate({query}) {
            return query.offerKey
        }

        @Watch('locale')
        loadFlightPreferences() {
            const {offerKey} = this.$route.query
            flightsStore.loadFlightPreferences(offerKey)
        }

        async mounted() {
            const {offerKey} = this.$route.query
            await this.$store.restored
            await Promise.all([
                this.loadRuntimeData(),
                runtimeStore.loadTouristBookingFields(offerKey),
                flightsRuntimeStore.loadAdditionalFlightOptions(offerKey),
                flightsStore.clearBookingAdditionalOptions(),
                flightsStore.getExtraServiceAdditionalOptions(offerKey),
                flightsStore.loadFlightPreferences(offerKey),
            ])
            this.loaded = true
        }

        refreshExpiredOffers() {
            this.$router.push(flightsRuntimeStore.flightsPageLink(this.searchRequest))
        }

        isOffersExpired() {
            return (
                this.$dateFns.isAfter(new Date(), UTCToDate(this.$dateFns.parseISO(this.offer.expirationTime))) &&
                !runtimeStore.bookingActive &&
                !flightsStore.prepareBookResponse.bookingKey
            )
        }

        get basketItem() {
            return flightsStore.basketItem(this.$route.query.offerKey)
        }

        get offer() {
            return this.basketItem.offer
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get locale() {
            return this.$i18n.locale
        }

        get productStore() {
            return flightsStore
        }
    }
</script>
