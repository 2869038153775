<template>
    <div>
        <breadcrumbs class="mb-8" />
        <order-selected-filters
            @filter="
                $refs.orders.filter()
                $refs.filters.load()
            "
        />
        <order-filters ref="filters" @filter="$refs.orders.filter()" />
        <v-row v-if="!isSupplier" dense>
            <v-col md="2" cols="12" class="mt-2">
                <v-btn class="primary" small to="/">
                    {{ $t('new_order') }}
                </v-btn>
            </v-col>
        </v-row>
        <orders-download-snackbar v-model="selectedOrdersFlag" :_order-ids.sync="orderIds" />
        <v-row>
            <v-col cols="12">
                <orders
                    ref="orders"
                    :_order-ids.sync="orderIds"
                    :selectable="selectable"
                    :_select-all-flag.sync="selectAllFlag"
                    @selectAllToggle="selectedOrdersFlag = false"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import Breadcrumbs from '~/components/Breadcrumbs'
    import Orders from '@/components/account/Orders'
    import OrderSelectedFilters from '@/components/account/filters/OrderSelectedFilters'
    import OrderFilters from '~/components/account/filters/OrderFilters'
    import {authStore, persistentStore, runtimeStore} from '@/store'
    import OrdersDownloadSnackbar from '@/components/account/OrdersDownloadSnackbar'
    import {Watch} from 'vue-property-decorator'

    @Component({
        components: {
            OrdersDownloadSnackbar,
            Orders,
            Breadcrumbs,
            OrderSelectedFilters,
            OrderFilters,
        },
        middleware: 'auth',
    })
    export default class OrdersPage extends Vue {
        orderIds = []
        selectAllFlag = false
        selectedOrdersFlag = false

        @Watch('orderIds', {deep: true})
        onOrderIdsChange() {
            this.selectedOrdersFlag = this.orderIds.length > 0
            this.selectAllFlag = this.orderIds.length === 15
        }

        @Watch('selectAllFlag', {deep: true})
        onSelectAllFlagChange() {
            if (this.selectAllFlag) {
                this.orderIds = runtimeStore.ordersResponse.orders.map(order => order.orderId)
            } else {
                this.orderIds = this.selectedOrdersFlag ? this.orderIds : []
            }
        }

        async mounted() {
            await this.$store.restored
            if (persistentStore.selectedOrderId) {
                persistentStore.SET_SELECTED_ORDER_ID(null)
            }
        }

        get selectable() {
            return authStore.isTourOperator || authStore.isAgency
        }

        get isSupplier() {
            return authStore.companyType == 'INTERNAL_SUPPLIER'
        }
    }
</script>
