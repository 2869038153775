<template>
    <v-card outlined class="mb-5 hotel-order-summary">
        <v-list-item three-line class="pl-0">
            <v-list-item-avatar class="my-0" size="90" tile>
                <v-img
                    class="thumb"
                    :src="offer.thumb | imageUrl(600)"
                    :alt="offer.name"
                    lazy-src="/placeholder.png"
                    @error="onImgError"
                >
                    <template v-slot:placeholder>
                        <v-row
                            v-show="!imgError && offer.thumb"
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
            </v-list-item-avatar>
            <v-list-item-content class="align-self-start">
                <hotel-stars :category="offer.category" />
                <v-list-item-title :title="offer.name">
                    {{ offer.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                    <v-icon small color="accent">
                        mdi-map-marker
                    </v-icon>
                    <span>{{ offer.address }}</span>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-list dense>
            <template v-for="(room, roomIndex) in roomOffers">
                <v-list-item :key="roomIndex + '_0'" tree-line>
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-icon v-if="room.available" :title="$t('available')" left small class="success--text">
                                mdi-checkbox-marked-circle
                            </v-icon>
                            <v-icon v-else :title="$t('on_request')" left small class="warning--text">
                                mdi-alert-circle
                            </v-icon>
                            {{ room.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <hotel-meal-type-label :hotel="offer" :room="room" />
                            <tariff-label :room="room" :hotel="offer" />
                            <conditions-label
                                v-if="room"
                                :offer-key="room.groupedOffers[0].offerKey"
                                :price="room.price"
                                @conditions="showConditionsInfo(room)"
                            />
                        </v-list-item-subtitle>
                        <v-list-item-title class="room-price">
                            <strong>{{ roomPrice(roomIndex) | price }}</strong>
                            <commission-amount :commission="room.price.commission" />
                            <rate-note :room="room" prepend-label />
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="roomExtraServices(roomIndex).length" :key="roomIndex + '_1'" class="d-block my-4">
                    <h4 class="body-2 full-width mb-2">
                        {{ $t('extra_services') }}
                    </h4>
                    <v-row class="flex-column">
                        <v-col
                            v-for="service in roomExtraServices(roomIndex)"
                            :key="service.type"
                            class="py-0 d-flex align-center caption"
                        >
                            <span>{{ service.name }}</span>
                            <span v-if="service.quantity > 1">&nbsp;* {{ service.quantity }} </span>
                        </v-col>
                    </v-row>
                </v-list-item>
                <v-divider :key="roomIndex + '_2'" />
                <v-list-item
                    v-if="roomCheckInOutExtraServices(roomIndex).length"
                    :key="roomIndex + '_3'"
                    class="d-block my-4"
                >
                    <v-row class="flex-column">
                        <v-col
                            v-for="service in roomCheckInOutExtraServices(roomIndex)"
                            :key="service.time + service.text"
                            class="py-0 d-flex align-center caption"
                        >
                            <span>{{ `${$t(service.text)} ` }}{{ service.time }}</span>
                        </v-col>
                    </v-row>
                </v-list-item>
                <v-divider :key="roomIndex + '_4'" />
            </template>
            <original-currency-notification :price="totalPrice" />
            <v-list-item class="text-right">
                <v-list-item-title class="d-flex flex-column justify-end">
                    <h2 class="py-2">{{ $t('total') }}: {{ totalPrice | price }}</h2>
                    <corporate-policy-violation v-if="corporatePolicyViolated" :policies="violatedPolicies" />
                    <div v-if="!isNaN(totalPrice.loyaltyPoints)" class="text-caption text--secondary">
                        <div>{{ $t('loyalty') }} : {{ totalPrice.loyaltyPoints }}</div>
                    </div>
                </v-list-item-title>
            </v-list-item>
        </v-list>
        <conditions-modal>
            <template v-slot:info>
                <hotel-conditions-modal-info />
            </template>
            <template v-slot:hotelPriceNote>
                <hotel-price-note :search-request="searchRequest" />
            </template>
        </conditions-modal>
        <hotel-meal-type-info-modal />
        <hotel-room-info-modal />
        <additional-fees-modal />
    </v-card>
</template>

<script>
    import {mixins, Component, Prop} from 'nuxt-property-decorator'
    import CommissionAmount from '~/components/snippets/CommissionAmount'
    import ConditionsLabel from '~/components/snippets/ConditionsLabel'
    import ConditionsModal from '~/components/modals/ConditionsModal'
    import {hotelsStore} from '~/store'
    import HotelCorporatePolicyViolationMixin from '@/mixins/HotelCorporatePolicyViolationMixin'
    import HotelConditionsModalInfo from '@/components/hotels/snippets/HotelConditionsModalInfo'
    import {EventBus, SHOW_HOTEL_CONDITIONS_INFO} from '@/utils/event-bus'
    import HotelMealTypeLabel from '@/components/hotels/snippets/HotelMealTypeLabel'
    import HotelMealTypeInfoModal from '@/components/hotels/modals/HotelMealTypeInfoModal'
    import OriginalCurrencyNotification from '@/components/booking/snippets/OriginalCurrencyNotification'
    import CorporatePolicyViolation from '~/components/snippets/CorporatePolicyViolation'
    import RateNote from '~src/components/snippets/RateNote'
    import HotelRoomInfoModal from '@/components/modals/HotelRoomInfoModal'
    import AdditionalFeesModal from '~src/components/modals/AdditionalFeesModal'
    import HotelPriceNote from '@/components/hotels/snippets/HotelPriceNote'
    import TariffLabel from '~src/components/hotels/snippets/tariffLabel.src'
    import HotelStars from '@/components/hotels/snippets/HotelStars'

    @Component({
        components: {
            HotelStars,
            AdditionalFeesModal,
            HotelRoomInfoModal,
            RateNote,
            OriginalCurrencyNotification,
            HotelMealTypeInfoModal,
            HotelMealTypeLabel,
            HotelConditionsModalInfo,
            ConditionsLabel,
            ConditionsModal,
            CommissionAmount,
            CorporatePolicyViolation,
            HotelPriceNote,
            TariffLabel,
        },
    })
    export default class HotelOrderSummary extends mixins(HotelCorporatePolicyViolationMixin) {
        @Prop({required: true}) offer
        @Prop({required: true}) roomOffers
        @Prop({required: true}) searchRequest
        @Prop({default: true}) prebooked

        imgError = false

        onImgError() {
            this.imgError = true
            return false
        }

        roomPrice(roomIndex) {
            if (this.prebooked && this.roomOffers[roomIndex].currentPrice)
                return this.roomOffers[roomIndex].currentPrice
            const totalCountExtraServices = this.roomExtraServices(roomIndex).reduce((total, service) => {
                let serviceAmount = this.$options.filters.convertPrice(service.price).amount
                if (service.quantity) {
                    serviceAmount = serviceAmount * service.quantity
                }
                total.amount += serviceAmount
                total.amount = Math.round(total.amount * 1e2) / 1e2
                return total
            }, this.$options.filters.convertPrice(this.roomOffers[roomIndex].price))

            if (this.roomCheckInOutExtraServices(roomIndex)) {
                Object.values(this.roomCheckInOutExtraServices(roomIndex)).reduce((total, current) => {
                    const amount = this.$options.filters.convertPrice(current.price).amount
                    const serviceRPH = current.serviceRPH || 1
                    total.amount += amount * serviceRPH
                    return total
                }, totalCountExtraServices)
            }

            return totalCountExtraServices
        }

        showConditionsInfo(room) {
            EventBus.$emit(SHOW_HOTEL_CONDITIONS_INFO, this.searchRequest, this.offer, room)
        }

        roomExtraServices(roomIndex) {
            return hotelsStore.bookingExtraServices.filter(
                bookingExtraService => bookingExtraService.roomIndex === roomIndex
            )
        }

        roomCheckInOutExtraServices(roomIndex) {
            return hotelsStore.checkInOutExtraService[roomIndex]
                ? Object.values(hotelsStore.checkInOutExtraService[roomIndex])
                : []
        }

        get totalPrice() {
            return hotelsStore.orderTotalPrice(this.roomOffers, this.prebooked)
        }
    }
</script>

<style scoped>
    .full-width {
        width: 100%;
    }
</style>
