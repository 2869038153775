<template>
    <td :class="roomStatusColor" class="room-cell">
        <v-menu v-if="roomStatusColor !== 'grey'" :close-on-content-click="true" :nudge-width="150" offset-y absolute>
            <template v-slot:activator="{on}">
                <div class="fill" v-on="on" />
            </template>
            <v-card class="pa-1 pl-1">
                <v-card-subtitle class="pa-0 text-center font-weight-bold">
                    {{ date | dateShortFormat }}
                </v-card-subtitle>
                <v-radio-group :value="roomStatus" class="pl-2" @change="changeRoomStatus">
                    <v-radio value="bookable">
                        <template v-slot:label>
                            <div class="d-flex align-center justify-space-between full-width">
                                {{ $t('supplier.bookable') }}
                                <v-icon class="green--text">
                                    mdi-checkbox-blank-circle
                                </v-icon>
                            </div>
                        </template>
                    </v-radio>
                    <v-radio value="closed">
                        <template v-slot:label>
                            <div class="d-flex align-center justify-space-between full-width">
                                {{ $t('supplier.closed') }}
                                <v-icon class="red--text">
                                    mdi-checkbox-blank-circle
                                </v-icon>
                            </div>
                        </template>
                    </v-radio>
                </v-radio-group>
            </v-card>
        </v-menu>
    </td>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'

    @Component({})
    export default class RoomStatusCell extends Vue {
        @Prop() date
        @Prop() service

        get roomStatus() {
            switch (this.roomStatusColor) {
                case 'green':
                    return 'bookable'
                case 'red':
                    return 'closed'
                default:
                    return 'no_room'
            }
        }

        get roomStatusColor() {
            if (this.service.blockout) {
                return 'red lighten-2'
            }
            switch (this.service.status) {
                case 'NOT_AVAILABLE':
                    return 'grey lighten-2'
                case 'BLOCKED':
                    return 'red lighten-2'
                case 'AVAILABLE':
                    return 'green lighten-2'
                case 'RELEASED':
                case 'UNLIMITED':
                case 'OVERBOOKING':
                    if (this.service.availableQuantity > 0) {
                        return 'green lighten-2'
                    } else {
                        return 'grey lighten-2'
                    }
                default:
                    return 'grey lighten-2'
            }
        }

        changeRoomStatus(value) {
            this.$emit('update', {
                dateFrom: this.date,
                dateTo: this.date,
                categoryId: this.service.categoryId,
                serviceId: this.service.serviceId,
                operation: value === 'bookable' ? 'CLEAR_BLOCK' : 'BLOCK',
                dayOfWeek: [true, true, true, true, true, true, true],
            })
        }
    }
</script>

<style scoped lang="scss">
    .fill {
        height: 100%;
    }
    .full-width {
        width: 100%;
    }
</style>
