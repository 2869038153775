<script>
    import {Component, Vue} from 'vue-property-decorator'

    @Component
    export default class TrainStationsSearchMixin extends Vue {
        async searchTrainStations(rq) {
            const defaults = {
                pattern: null,
                limit: 0,
                productType: 'TRANSFER',
            }

            const rs = await this.$api.trainStations.get({...defaults, ...rq})
            return rs
                .filter(trainStation => Object.prototype.hasOwnProperty.call(trainStation, 'name'))
                .map(trainStation => {
                    return Object.assign(trainStation, {type: 'trainStation', parentName: trainStation.countryName})
                })
        }
    }
</script>
