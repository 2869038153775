<template>
    <div>
        <breadcrumbs class="mb-3" />
        <v-btn depressed nuxt :to="{name: 'companyClient', params: {id: 0}}" class="primary mb-4">
            {{ $t('new_client_profile') }}
        </v-btn>
        <clients-table />
    </div>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import Breadcrumbs from '~src/components/breadcrumbs.src'
    import ClientsTable from '~src/components/account/clientsTable.src'

    @Component({
        components: {
            Breadcrumbs,
            ClientsTable,
        },
        middleware: 'auth',
    })
    export default class CompanyClientsPage extends Vue {}
</script>
