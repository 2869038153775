<template>
    <v-card outlined tile>
        <v-card-title class="title ">{{ $t(`transfers.${pointType}_location`) }} - {{ $t('address') }}</v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col cols="12" md="8">
                        <v-text-field v-model="address" :label="$t('address')" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            v-if="!ownProduct"
                            v-model="postalCode"
                            :label="$t('transfers.postal_code')"
                            :rules="postalCodeRules"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Vue, Prop, PropSync} from 'vue-property-decorator'
    import {transfersRuntimeStore, transfersStore} from '@/utils/store-accessor'

    @Component
    export default class TransferAddressForm extends Vue {
        @Prop({required: true}) pointType
        @Prop() product
        @PropSync('_valid', {default: false}) valid

        get address() {
            return this.getProp('address')
        }

        set address(value) {
            this.setProp('address', value)
        }

        get postalCode() {
            return this.getProp('postalCode')
        }

        set postalCode(value) {
            this.setProp('postalCode', value)
        }

        getProp(prop) {
            return transfersStore.prepareBookRequest[this.pointType][prop]
        }

        setProp(prop, val) {
            transfersStore.SET_PREPARE_BOOK_LOCATION_PROP({prop, val, pointType: this.pointType})
        }

        get postalCodeRules() {
            return this.ownProduct
                ? []
                : [v => !!v || `${this.$t('transfers.postal_code')} ${this.$t('validation.required')}`]
        }

        get ownProduct() {
            return transfersRuntimeStore.ownProduct(this.product)
        }
    }
</script>
