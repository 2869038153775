<template>
    <vc-date-picker
        v-model="range"
        mode="range"
        :locale="locale"
        :min-date="minStartDate"
        :max-date="maxEndDate"
        :columns="$breakpoint.smAndDown ? 1 : 2"
        :rows="1"
        :popover="{placement: 'bottom'}"
        @dayclick="dayclick"
        @popoverWillShow="popoverWillShow"
    >
        <v-text-field
            ref="startDateInput"
            slot-scope="{inputProps, inputEvents}"
            :class="textFieldClass"
            :label="$t('check_in') + ' - ' + $t('check_in')"
            readonly
            :single-line="singleLine"
            :outlined="outlined"
            filled
            hide-details
            background-color="white"
            :prepend-inner-icon="showIcon ? 'mdi-calendar' : null"
            v-bind="format(inputProps)"
            v-on="inputEvents"
        />
    </vc-date-picker>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import DaterangeM from '~src/components/search/forms/daterangeM.src'

    @Component
    export default class ModifyDaterange extends DaterangeM {}
</script>
