<template>
    <div class="d-flex flex-wrap">
        <v-icon v-if="mapping" small :title="$t(mapping.title)">
            {{ mapping.ico }}
        </v-icon>
        <v-chip v-else x-small class="mr-1 mt-1" v-text="groupName" />
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator'

    //TODO Make mapping on api response transform for size optimization
    const mapping = {
        66: {
            ico: 'mdi-pool',
            title: 'service.pool',
        },
        5: {
            ico: 'mdi-air-conditioner',
            title: 'service.air_conditioning',
        },
        68: {
            ico: 'mdi-parking',
            title: 'service.parking',
        },
        42: {
            ico: 'mdi-parking',
            title: 'service.parking',
        },
        79: {
            ico: 'mdi-spa',
            title: 'service.spa',
        },
        228: {
            ico: 'mdi-briefcase-variant',
            title: 'service.business_center',
        },
        76: {
            ico: 'mdi-silverware',
            title: 'service.restaurant',
        },
        179: {
            ico: 'mdi-wifi',
            title: 'service.internet',
        },
        217: {
            ico: 'mdi-human-male-female',
            title: 'service.adults_only',
        },
    }

    @Component
    export default class HotelServiceIcon extends Vue {
        @Prop() groupName
        @Prop() map

        get mapping() {
            const serviceWithCode = this.map.find(e => e.name === this.groupName)
            if (serviceWithCode) {
                return mapping[serviceWithCode.otaCode]
            } else {
                return false
            }
        }
    }
</script>
