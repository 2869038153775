<template>
    <v-form ref="form" v-model="valid">
        <h4 class="title text-capitalize">
            {{ $tc(label, 1) }} {{ index + 1 }} <span v-if="isChild">({{ $t('child') }})</span>
        </h4>
        <div v-if="personNameOnLatin" class="subtitle-2 accent--text">
            {{ $t('notes.fill_in_english') }}
        </div>
        <v-row v-if="showTouristSelector" dense>
            <v-col cols="12" md="6">
                <tourist-selector
                    :tourist="form"
                    :selected-tourists="selectedTourists"
                    :required="corporatePolicyViolation"
                    :disabled="isDisabled"
                    @selected="onPersonSelected"
                />
            </v-col>
        </v-row>
        <v-row v-if="!showTouristSelector || !corporatePolicyViolation || form.personId" dense>
            <v-col cols="12" md="6">
                <v-row dense>
                    <v-col v-if="!isFieldDisabled('PREFIX')" cols="12" md="2">
                        <v-select
                            v-model="form.prefix"
                            :items="['Mr', 'Ms', 'Mrs']"
                            :label="fieldLabel('salutation', 'PREFIX')"
                            :rules="[requiredRule('salutation', 'PREFIX')]"
                            required
                            :disabled="isDisabled"
                        />
                    </v-col>
                    <v-col cols="12" :md="!isFieldDisabled('PREFIX') ? 4 : 5">
                        <v-text-field
                            v-model="form.firstName"
                            :label="`${$t('first_name')}*`"
                            :rules="validationRules('first_name', [latinRule, requiredRule])"
                            required
                            :disabled="isDisabled"
                        />
                    </v-col>
                    <v-col cols="12" :md="!isFieldDisabled('PREFIX') ? 6 : 7">
                        <v-text-field
                            v-model="form.lastName"
                            :label="`${$t('last_name')}*`"
                            :rules="validationRules('last_name', [latinRule, requiredRule])"
                            required
                            :disabled="isDisabled"
                        />
                    </v-col>
                    <v-col v-if="!isFieldDisabled('PHONE')" cols="12" md="8">
                        <v-text-field
                            v-model="form.phone"
                            :disabled="isDisabled"
                            type="tel"
                            :label="fieldLabel('phone', 'PHONE')"
                            :rules="phoneRules"
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-row dense>
                    <v-col v-if="!isFieldDisabled('BIRTHDATE')" cols="5">
                        <v-menu
                            v-model="showBirthDatePicker"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{on}">
                                <v-text-field
                                    :value="form.birthdate | dateShortFormat"
                                    :label="fieldLabel('birthdate', 'BIRTHDATE')"
                                    clearable
                                    readonly
                                    background-color="white"
                                    prepend-inner-icon="mdi-calendar"
                                    :rules="[requiredRule('birthdate', 'BIRTHDATE')]"
                                    required
                                    :disabled="isDisabled"
                                    v-on="on"
                                />
                            </template>
                            <v-date-picker
                                ref="birthDatePicker"
                                v-model="form.birthdate"
                                :locale="$i18n.locale"
                                :min="minBirthDate"
                                :max="maxBirthDate"
                                @change="showBirthDatePicker = false"
                            />
                        </v-menu>
                    </v-col>
                    <v-col v-if="!hideCitizenshipInput && !isFieldDisabled('CITIZENSHIP')" cols="7">
                        <v-autocomplete
                            v-model="form.citizenshipId"
                            :disabled="isDisabled"
                            :label="fieldLabel('citizenship', 'CITIZENSHIP')"
                            :items="countries"
                            item-text="name"
                            item-value="id"
                            :rules="[requiredRule('citizenship', 'CITIZENSHIP')]"
                            required
                        />
                    </v-col>
                    <v-col v-if="!isFieldDisabled('EMAIL')" cols="12" md="8">
                        <v-text-field
                            v-model="form.email"
                            :label="fieldLabel('email', 'EMAIL')"
                            :disabled="isDisabled"
                            type="email"
                            :rules="validationRules('email', [emailRule, requiredRule], 'EMAIL')"
                            required
                        />
                    </v-col>
                </v-row>
            </v-col>
            <slot name="extraFields" :index="{index}" :disabled="{disabled}" />
        </v-row>
    </v-form>
</template>

<script>
    import MealTypeSelector from '~/components/booking/forms/fields/MealTypeSelector'
    import {mixins, Component, Prop, PropSync, Watch} from 'nuxt-property-decorator'
    import {persistentStore, authStore, runtimeStore} from '@/store'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import TouristSelector from '@/components/parts/Booking/TouristSelector'
    import BookingFieldsMixin from '@/components/booking/mixins/BookingFieldsMixin'

    @Component({
        components: {
            TouristSelector,
            MealTypeSelector,
        },
    })
    export default class TouristForm extends mixins(FormValidationRulesMixin, BookingFieldsMixin) {
        @Prop({default: 'guest'}) label
        @Prop({required: true}) index
        @Prop() disabled
        @PropSync('_valid', {default: false}) valid
        @Prop() corporatePolicyViolation

        showBirthDatePicker = false
        form = {
            prefix: null,
            firstName: null,
            lastName: null,
            citizenshipId: null,
            phone: null,
            email: null,
            birthdate: null,
            type: 'adult',
        }

        @Watch('showBirthDatePicker')
        onShowBirthDatePicker(val) {
            val && setTimeout(() => (this.$refs.birthDatePicker.activePicker = 'YEAR'))
        }

        @Watch('locale')
        onLocaleChange() {
            this.$refs.form.validate()
        }

        created() {
            const index = this.index
            // eslint-disable-next-line no-unused-vars
            const {passport, ...data} = persistentStore.tourists[this.index]
            this.form = {...this.form, ...data}
            this.form = new Proxy(this.form, {
                set: (target, prop, val) => {
                    target[prop] = val
                    persistentStore.SET_TOURIST_PROP({prop, val, index})
                    return true
                },
                get: (target, prop) => {
                    return persistentStore.tourists[index][prop]
                },
            })
        }

        onPersonSelected(person) {
            const index = this.index
            if (person) {
                const val = runtimeStore.touristFromPerson(person)
                persistentStore.SET_TOURIST({index, val})
            } else {
                persistentStore.SET_TOURIST_PROP({index, prop: 'personId', val: null})
            }
        }

        get phoneRules() {
            const rules = [this.phoneRule('phone')]
            if (this.isFieldMandatory('PHONE')) {
                rules.push(this.requiredRule('phone', 'PHONE'))
            }
            return rules
        }

        get locale() {
            return this.$i18n.locale
        }

        get countries() {
            return runtimeStore.countries
        }

        get age() {
            return this.form.age
        }

        get isDisabled() {
            return authStore.user.isEmployee || this.disabled
        }

        get personNameOnLatin() {
            return runtimeStore.config.personNameOnLatin
        }

        get showTouristSelector() {
            return authStore.person && ['corp.trip_coordinator', 'corp.director'].includes(authStore.person.role)
        }

        get selectedTourists() {
            return persistentStore.tourists
        }

        get hideCitizenshipInput() {
            return runtimeStore.config.hideCitizenshipInput
        }
    }
</script>
