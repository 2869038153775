<template>
    <v-layout :fill-height="!loaded" column>
        <hotel-booking-page-layout
            v-if="loaded"
            ref="layout"
            :search-request="searchRequest"
            :room-offers="roomOffers"
            :offer-keys="offerKeys"
            :offer="offer"
        >
            <template v-slot:breadcrumbs>
                <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="refreshExpiredOffers" />
                <hotel-breadcrumbs :search-request="searchRequest" :offer="offer" />
            </template>
            <template v-slot:touristsCard="{tourists, bookingKey, pageLock}">
                <v-card v-for="(roomTourists, roomIndex) in tourists" :key="roomIndex" outlined class="mb-5">
                    <v-card-title v-if="tourists.length > 1" class="text-capitalize">
                        {{ $tc('room', 1) }} {{ roomIndex + 1 }}
                    </v-card-title>
                    <v-card-text>
                        <template v-for="(tourist, index) in roomTourists">
                            <hotel-tourist-form
                                :key="roomIndex + '_' + index"
                                :room-index="roomIndex"
                                :index="index"
                                :disabled="!!bookingKey"
                                :show-meal-types="ownProduct"
                                :standard-meal-name="getMealName(roomIndex)"
                                :corporate-policy-violation="roomOffers[roomIndex].corporatePolicyViolation"
                            />
                            <v-divider
                                v-if="roomTourists.length > index + 1"
                                :key="roomIndex + '_' + index + '_divider'"
                                class="my-4"
                            />
                        </template>
                        <early-check-in-late-check-out :room-index="roomIndex" :disabled="!!bookingKey" />
                        <hotel-extra-services-form
                            :room-index="roomIndex"
                            :is-disabled="!!bookingKey"
                            class="mb-5"
                            @updateExtraServices="bookingKey = false"
                        />
                        <hotel-comments-form
                            :index="roomIndex"
                            :disabled="!!bookingKey"
                            :product-store="productStore"
                        />
                    </v-card-text>
                    <v-btn
                        v-if="bookingKey && !pageLock"
                        absolute
                        dark
                        fab
                        bottom
                        right
                        small
                        color="accent"
                        @click="$refs.layout.cancelFlow"
                    >
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </v-card>
            </template>
            <template v-slot:sidebar="{bookingKey}">
                <hotel-info-search-summary search-disabled :search-request="searchRequest" />
                <hotel-order-summary
                    :offer="offer"
                    :room-offers="roomOffers"
                    :search-request="searchRequest"
                    :prebooked="!!bookingKey"
                />
                <hotel-info-time />
            </template>
        </hotel-booking-page-layout>
        <v-layout v-else class="align-center justify-center">
            <v-progress-circular indeterminate size="64" color="primary" />
        </v-layout>
    </v-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import {hotelsRuntimeStore, hotelsStore, runtimeStore} from '@/store'
    import HotelBookingPageLayout from '@/components/hotels/booking/HotelBookingPageLayout'
    import HotelTouristForm from '@/components/booking/forms/HotelTouristForm'
    import HotelCommentsForm from '@/components/booking/forms/HotelCommentsForm'
    import HotelInfoSearchSummary from '@/components/info/HotelInfoSearchSummary'
    import HotelOrderSummary from '~src/components/booking/hotelOrderSummary.src'
    import HotelInfoTime from '@/components/info/HotelInfoTime'
    import {UTCToDate} from '@/utils/helpers'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import HotelTouristPassportForm from '@/components/hotels/booking/form/HotelTouristPassportForm'
    import HotelPageMixin from '@/components/hotels/mixins/HotelPageMixin'
    import HotelBreadcrumbs from '@/components/hotels/HotelBreadcrumbs'
    import HotelExtraServicesForm from '@/components/booking/forms/HotelExtraServicesForm'
    import EarlyCheckInLateCheckOut from '~/components/booking/forms/fields/EarlyCheckInLateCheckOut'

    @Component({
        components: {
            HotelExtraServicesForm,
            HotelBreadcrumbs,
            HotelTouristPassportForm,
            ExpiredOffersSnackbar,
            HotelInfoTime,
            HotelOrderSummary,
            HotelInfoSearchSummary,
            HotelCommentsForm,
            HotelTouristForm,
            HotelBookingPageLayout,
            EarlyCheckInLateCheckOut,
        },
        layout: 'blank',
    })
    export default class HotelBookingPage extends mixins(HotelPageMixin) {
        loaded = false

        validate({query}) {
            return query.offerKey
        }

        async mounted() {
            await this.$store.restored
            const promises = []
            promises.push(this.loadRuntimeData())
            promises.push(runtimeStore.loadTouristBookingFields(this.offerKeys[0]))
            hotelsStore.clearBookingAdditionalOptions()
            this.offerKeys.forEach(offerKey => {
                promises.push(hotelsStore.loadExtraMealTypes({offerKey}))
            })
            await Promise.all(promises)
            this.loaded = true
        }

        getMealName(index) {
            return this.roomOffers.length && this.roomOffers[index] ? this.roomOffers[index].mealTypeName : null
        }

        refreshExpiredOffers() {
            this.$router.push(hotelsRuntimeStore.hotelPageLink(this.offer, this.searchRequest))
        }

        isOffersExpired() {
            return (
                this.$dateFns.isAfter(new Date(), UTCToDate(this.$dateFns.parseISO(this.offer.expirationTime))) &&
                !runtimeStore.bookingActive &&
                !hotelsStore.prepareBookResponse.bookingKey
            )
        }

        get offerKeys() {
            return !Array.isArray(this.$route.query.offerKey)
                ? [this.$route.query.offerKey]
                : this.$route.query.offerKey
        }

        get basketItem() {
            return hotelsStore.basketItem(this.offerKeys)
        }

        get offer() {
            return this.basketItem.offer
        }

        get roomOffers() {
            return this.basketItem.roomOffers
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get ownProduct() {
            return hotelsRuntimeStore.ownProduct(this.offer)
        }

        get hotelInfoRequest() {
            const {supplierCode, cityCode, hotelCode, hotelId} = this.offer
            return {supplierCode, cityCode, hotelCode, hotelId}
        }

        get cityId() {
            return this.searchRequest.cityId
        }

        get productStore() {
            return hotelsStore
        }
    }
</script>
