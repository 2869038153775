<template>
    <v-card outlined>
        <div class="d-flex">
            <v-avatar v-if="imageSrc" class="ma-4 mr-0" size="125" tile>
                <v-img :src="imageSrc | imageUrl(600)" lazy-src="/placeholder.png" @error="imgError = true">
                    <template v-slot:placeholder>
                        <v-row v-show="!imgError && imageSrc" class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
            </v-avatar>
            <div>
                <v-card-title class="headline" v-text="info.name" />
                <v-card-subtitle>
                    <tour-general-info :info="offer.info" />
                </v-card-subtitle>
            </div>
        </div>
        <v-card-text v-text="info.shortDescription" />
        <v-divider />
        <original-currency-notification :price="totalPrice" class="mt-5" />
        <v-card-actions>
            <v-spacer />
            <span class="text-h6">{{ $t('total') }}: {{ totalPrice | price }}</span>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {toursRuntimeStore, toursStore} from '~/store'
    import ConditionsModal from '~/components/modals/ConditionsModal'
    import ConditionsLabel from '~/components/snippets/ConditionsLabel'
    import TourGeneralInfo from '@/components/tours/snippets/TourGeneralInfo'
    import OriginalCurrencyNotification from '@/components/booking/snippets/OriginalCurrencyNotification'

    @Component({
        components: {
            OriginalCurrencyNotification,
            TourGeneralInfo,
            ConditionsModal,
            ConditionsLabel,
        },
    })
    export default class TourOrderSummary extends Vue {
        @Prop({required: true}) offer
        @Prop({default: true}) prebooked

        imgError = false

        get info() {
            return toursRuntimeStore.packageTourInfo
        }

        get imageSrc() {
            const images = this.info.images
            if (!images.length) return null
            const image = images.find(image => image.mainImage)
            return image ? image.url : images[0].url
        }

        get totalPrice() {
            return toursStore.orderTotalPrice(this.offer, this.prebooked)
        }
    }
</script>
