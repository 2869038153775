<template>
    <div class="text-center">
        <h1 class="text-center my-5" :class="[5, 6].includes(status) ? 'warning--text' : 'success--text'">
            <v-icon v-if="[5, 6].includes(status)" large class="warning--text">
                mdi-exclamation-thick
            </v-icon>
            <v-icon v-else large class="success--text">
                mdi-checkbox-marked-circle-outline
            </v-icon>
            <span v-if="status === 6 && !isTO1">{{ $t('booking_status')[0] }}</span>
            <span v-else>{{ $t('booking_status')[status] }}</span>
        </h1>
        <template v-if="status === 6 && !isTO1">
            <div>
                <strong>{{ $t('thanks_for_your_booking') }}</strong>
            </div>
            <div>
                <strong class="warning--text">{{ $t('order_need_manual_processing') }}</strong>
            </div>
        </template>
        <template v-else-if="errors && errors.length">
            <div v-for="(error, errorIndex) in errors" :key="errorIndex">
                <strong class="warning--text" v-html="parseErrorMessage(error.message)" />
            </div>
        </template>
        <p>
            {{ $t('your_booking_number') }} <strong>{{ orderId }}</strong>
        </p>
        <p>
            {{ $t('booking_details_sent_to') }} <strong>{{ personEmail }}</strong>
        </p>
        <p>
            {{ $t('confirm_questions') }}
            <span v-if="company.email">
                <strong>{{ company.email }}</strong> {{ $t('or') }}
            </span>
            <strong>{{ company.phones.join(',') }}</strong>
        </p>
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import {authStore, persistentStore} from '@/store'

    @Component
    export default class ConfirmationStatus extends Vue {
        @Prop({default: () => ({phones: []})}) company

        parseErrorMessage(message) {
            if (message.indexOf('Extra seats were not added to booking') !== -1) {
                let message = this.$t('flights.warnings.seats_no_added')
                if (!this.isTO1) {
                    message += ' ' + this.$t('flights.warnings.please_contact_your_manager')
                }
                return message
            }
            if (message.indexOf('Extra services were not added to booking') !== -1) {
                let message = this.$t('flights.warnings.extra_services_no_added')
                if (!this.isTO1) {
                    message += ' ' + this.$t('flights.warnings.please_contact_your_manager')
                }
                return message
            }
            return message
        }

        get personEmail() {
            return authStore.person
                ? authStore.person.email
                : authStore.registeredPerson
                ? authStore.registeredPerson.email
                : null
        }

        get orderId() {
            return persistentStore.bookResponse[0].orderId
        }

        get status() {
            return parseInt(persistentStore.bookResponse[0].status, 10)
        }

        get errors() {
            return persistentStore.bookResponse[0].errors
        }

        get isTO1() {
            return authStore.isTO1
        }
    }
</script>
