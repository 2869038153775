<template>
    <service-card :service="service" icon="mdi-car" @showInfo="showInfo">
        <template v-slot:orderInfo>
            <order-info-base
                :service="service"
                :service-check-in="serviceCheckIn"
                :service-check-out="serviceCheckOut"
                :service-image="mainImage"
                :is-package="isPackage"
                :order-id="orderId"
                :object-id="objectId"
                @cancelService="$emit('cancelService', $event)"
                @modifyService="$emit('modifyService', $event)"
            >
                <div class="mt-4">
                    <v-icon left small>
                        {{ departureIcon }}
                    </v-icon>
                    <strong>
                        {{ $t('from') }}
                    </strong>
                    {{ departurePoint.name }}
                    {{ departurePlaceName ? `(${departurePlaceName})` : '' }}
                </div>
                <div>
                    <v-icon left small>
                        {{ arrivalIcon }}
                    </v-icon>
                    <strong>
                        {{ $t('to') }}
                    </strong>
                    {{ arrivalPoint.name }}
                    ({{ arrivalPlaceName }})
                </div>
                <div v-if="pickUpSign" class="mt-4">
                    <v-icon left small>
                        mdi-card-bulleted-outline
                    </v-icon>
                    <strong>{{ $t('transfers.pickup_sign') }}:</strong>
                    {{ pickUpSign }}
                </div>
                <div v-if="serviceDetails.transport">
                    <v-icon left small>
                        mdi-bus
                    </v-icon>
                    <strong>{{ $t('transport') }}:</strong>
                    {{ serviceDetails.transport }}
                </div>
                <template v-slot:extraTime>
                    <div v-if="info.duration" class="d-flex">
                        <v-icon left small>
                            mdi-timer-outline
                        </v-icon>
                        <strong class="black--text"> {{ info.duration }} {{ $t('minutes') }} </strong>
                    </div>
                </template>
            </order-info-base>
        </template>
        <template v-slot:serviceInfo>
            <v-dialog v-model="modal" width="600" hide-overlay>
                <div class="modal-close-btn-block">
                    <v-icon class="v-card-close" @click="modal = false">
                        mdi-close
                    </v-icon>
                </div>
                <service-info-base :service="service" :info="info" :image="mainImage">
                    <template v-if="transferInfo" v-slot:serviceInfo>
                        <div v-if="serviceDetails.languages && serviceDetails.languages.length">
                            <strong>{{ $t('language') }}: {{ serviceDetails.languages.join(', ') }}</strong>
                        </div>
                        <div v-if="info.shortDescription" class="d-flex align-center">
                            {{ info.shortDescription }}
                        </div>
                        <div v-if="info.fullDescription" v-html="info.fullDescription" />
                        <div v-if="transferInfo.descriptions.length">
                            <div v-for="(desc, index) in transferInfo.descriptions" :key="index">
                                {{ desc }}
                            </div>
                        </div>
                        <div v-if="transferInfo.comments && transferInfo.comments.length">
                            <div v-for="(comm, index) in transferInfo.comments" :key="index">
                                {{ comm }}
                            </div>
                        </div>
                    </template>
                </service-info-base>
            </v-dialog>
        </template>
    </service-card>
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator'
    import ServiceInfoBase from '@/components/account/orderServices/ServiceInfoBase'
    import ServiceCard from '@/components/account/orderServices/ServiceCard'

    @Component({
        components: {
            ServiceCard,
            OrderInfoBase: () => import('~src/components/account/orderServices/orderInfoBase.src'),
            ServiceInfoBase,
        },
    })
    export default class TransferService extends Vue {
        @Prop({required: true}) service
        @Prop({default: false}) isPackage
        @Prop({default: null}) orderId
        @Prop({default: null}) objectId

        airportsData = {}
        transferInfo = null
        modal = false

        DEPARTURE_POINT = 'departure'
        ARRIVAL_POINT = 'arrival'

        showInfo() {
            this.modal = true
        }

        mounted() {
            this.getAdditionalInfo()
        }

        async getAdditionalInfo() {
            this.transferInfo = await this.getTransferInfo()
        }

        async getAirportsData() {
            const airportCodes = []
            const promises = []
            if (this.serviceDetails.departurePlace.airportCode) {
                airportCodes.push(this.serviceDetails.departurePlace.airportCode)
            }
            if (this.serviceDetails.arrivalPlace.airportCode) {
                airportCodes.push(this.serviceDetails.arrivalPlace.airportCode)
            }
            airportCodes.forEach(code => {
                promises.push(this.$api.airports.get({pattern: code}))
            })

            const data = await Promise.all(promises)
            const result = {}
            airportCodes.forEach((code, index) => {
                result[code] = data[index]
            })
            return result
        }

        async getTransferInfo() {
            const processId = this.service.processId.replace(/^.{6}/, '')
            return await this.$api.transferInfo.get({processId})
        }

        get serviceCheckIn() {
            const pickUpTime = this.serviceDetails.pickupTime
            const date = this.$options.filters.dateFormat(this.service.startDateTime)
            return `<strong class="black--text"> ${this.$t(
                'transfers.pick_up'
            )}: ${date}</strong> <small>${pickUpTime}</small>`
        }

        get serviceCheckOut() {
            const date = this.$options.filters.dateFormat(this.service.endDateTime)
            return `<strong class="black--text">${this.$t('transfers.drop_off')}: ${date}</strong>`
        }

        get serviceDetails() {
            return this.service.serviceDetails[0]
        }

        get departureIcon() {
            return this.getIcon('departurePlace')
        }

        get arrivalIcon() {
            return this.getIcon('arrivalPlace')
        }

        getIcon(place) {
            switch (this.getPointType(place)) {
                case 'airport':
                    return 'mdi-airport'
                case 'hotel':
                    return 'mdi-hotel'
                case 'train':
                    return 'mdi-train'
                default:
                    return 'mdi-city'
            }
        }

        getPointType(place) {
            if (this.serviceDetails[place].airportCode) {
                return 'airport'
            } else if (this.serviceDetails[place].hotelCode) {
                return 'hotel'
            } else if (this.serviceDetails[place].trainNumber) {
                return 'train'
            }
            return 'address'
        }

        get departurePoint() {
            if (this.transferInfo) {
                return this.transferInfo.transferPoints.find(el => el.pointType === this.DEPARTURE_POINT)
            }
            return ''
        }

        get arrivalPoint() {
            if (this.transferInfo) {
                return this.transferInfo.transferPoints.find(el => el.pointType === this.ARRIVAL_POINT)
            }
            return ''
        }

        get arrivalPlaceName() {
            return this.getPlaceName('arrivalPlace')
        }

        get departurePlaceName() {
            return this.getPlaceName('departurePlace')
        }

        getPlaceName(place) {
            switch (this.getPointType(place)) {
                case 'airport':
                    return `${this.$t('flight_number')}: ${this.serviceDetails[place].flightNumber}`
                case 'hotel':
                    return this.serviceDetails[place].hotelName
                case 'train':
                    return `${this.$t('train_number')}: ${this.serviceDetails[place].trainNumber}`
                default:
                    return this.serviceDetails[place].address
            }
        }

        get pickUpSign() {
            return this.service.travelers[0].pickUpSign
        }

        get mainImage() {
            if (this.transferInfo && this.transferInfo.images && this.transferInfo.images.length) {
                let image = this.transferInfo.images.find(el => el.mainImage)
                if (!image) {
                    image = this.transferInfo.images[0]
                }
                return image.url
            }
            return null
        }

        get info() {
            return {...this.transferInfo, ...{mainImage: this.mainImage}}
        }
    }
</script>

<style lang="scss" scoped>
    .thumb {
        max-width: 350px;
    }

    .col-title {
        white-space: nowrap;
        max-width: fit-content;
    }

    .info-text {
        color: black;
        font-size: 1rem;
        font-style: italic;
    }

    .payment-status {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        min-width: fit-content;
        max-width: fit-content;
    }

    ::v-deep {
        .v-dialog {
            background: #fff;
            padding: 20px;
        }
    }

    .modal-close-btn-block {
        position: relative;
        top: -15px;
        right: -15px;
    }
</style>
