<template>
    <v-layout :fill-height="$fetchState.pending" column>
        <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="refreshExpiredOffers" />
        <hotel-info-mobile-tabs v-model="mobileTabs" />
        <v-container v-if="!$fetchState.pending" fluid>
            <hotel-breadcrumbs v-if="!isMobile" :search-request="searchRequest" />
            <v-row>
                <v-col v-show="!isMobile || (isMobile && mobileTabs === 0)" md="8">
                    <hotel-info class="mb-3" :search-request="searchRequest" @showMapTab="showMapTab" />
                    <component
                        :is="!$breakpoint.smAndDown ? 'product-gallery' : 'product-gallery-mobile'"
                        :images-data="info.images.image"
                        class="mb-3"
                    />
                    <hotel-info-room-offers-wrapper v-if="!isMobile" class="mb-5" :search-request="searchRequest" />
                    <hotel-info-descriptions class="mb-5" />
                    <hotel-info-services />
                    <view-offers-button @showOffers="mobileTabs = 1" />
                </v-col>
                <v-col v-show="!isMobile || (isMobile && mobileTabs === 1)" md="4">
                    <slot name="sidebar" />
                    <hotel-info-search-summary :search-request="searchRequest" />
                    <hotel-info-time />
                    <hotel-info-room-offers-wrapper v-if="isMobile" class="mb-5" :search-request="searchRequest" />
                    <div v-if="!isMobile">
                        <client-only>
                            <hotel-info-map :city="city" />
                        </client-only>
                        <hotel-info-relative-positions />
                    </div>
                </v-col>
                <v-col v-show="isMobile && mobileTabs === 2" :cols="12">
                    <client-only>
                        <hotel-info-map :city="city" />
                    </client-only>
                </v-col>
            </v-row>
        </v-container>
        <v-layout v-else class="align-center justify-center">
            <v-progress-circular indeterminate size="64" color="primary" />
        </v-layout>
    </v-layout>
</template>

<script>
    import {Component, mixins, Watch} from 'nuxt-property-decorator'
    import {cmsStore, hotelsRuntimeStore, hotelsStore, persistentStore} from '~/store'
    import {decodeSupplierCode, isEqual} from '~/utils/helpers'
    import HotelInfo from '~/components/info/HotelInfo'
    import HotelInfoSearchSummary from '@/components/info/HotelInfoSearchSummary'
    import HotelInfoRoomOffersWrapper from '@/components/info/offers/HotelInfoRoomOffersWrapper'
    import HotelInfoTime from '@/components/info/HotelInfoTime'
    import HotelInfoRelativePositions from '@/components/info/HotelInfoRelativePositions'
    import HotelInfoServices from '@/components/info/HotelInfoServices'
    import HotelInfoDescriptions from '@/components/info/HotelInfoDescriptions'
    import ViewOffersButton from '@/components/snippets/ViewOffersButton'
    import HotelStoreMixin from '@/mixins/HotelStoreMixin'
    import ProductGallery from '@/components/info/ProductGallery'
    import ProductGalleryMobile from '@/components/info/ProductGalleryMobile'
    import HotelPageMixin from '@/components/hotels/mixins/HotelPageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import HotelBreadcrumbs from '@/components/hotels/HotelBreadcrumbs'
    import {searchRequest} from '@/utils/hotels/hotels-blank-states'
    import NuxtPageMixin from '@/mixins/NuxtPageMixin'
    import OverlayProgress from '@/components/OverlayProgress'
    import HotelInfoMobileTabs from '~src/components/hotels/info/snippets/hotelInfoMobileTabs.src'

    @Component({
        components: {
            OverlayProgress,
            HotelBreadcrumbs,
            ExpiredOffersSnackbar,
            ViewOffersButton,
            HotelInfoServices,
            HotelInfoRelativePositions,
            HotelInfo,
            HotelInfoSearchSummary,
            HotelInfoRoomOffersWrapper,
            HotelInfoMap: () => import('~src/components/info/hotelInfoMap.src'),
            HotelInfoTime,
            HotelInfoDescriptions,
            ProductGallery,
            ProductGalleryMobile,
            HotelInfoMobileTabs,
        },
        layout: 'blank',
    })
    export default class HotelPage extends mixins(HotelStoreMixin, HotelPageMixin, NuxtPageMixin) {
        mobileTabs = 0

        @Watch('$route.query')
        onQueryChange() {
            hotelsRuntimeStore.singleHotelSearch(this.searchRequest)
        }

        async fetch() {
            await this.loadRuntimeData()
            if (!cmsStore.hotelSettingsLoaded) {
                await cmsStore.loadHotelSettings()
            }
        }

        async mounted() {
            await this.fetchStatePending
            await this.loadOffers()
        }

        async loadOffers() {
            await this.$store.restored
            if (!isEqual(hotelsStore.singleHotelSearchRequest, this.searchRequest) || this.isOffersExpired()) {
                await hotelsRuntimeStore.singleHotelSearch(this.searchRequest)
            }
        }

        beforeRouteLeave(to, from, next) {
            hotelsRuntimeStore.stopSingleHotelSearch()
            next()
        }

        async refreshExpiredOffers() {
            await hotelsRuntimeStore.singleHotelSearch(this.searchRequest)
        }

        isOffersExpired() {
            return hotelsStore.isSingleHotelOffersExpired()
        }

        showMapTab() {
            this.mobileTabs = 2
        }

        get searchRequest() {
            const rq = Object.assign(searchRequest(), this.$route.query)
            if (typeof rq.rooms === 'string') {
                rq.rooms = [decodeURIComponent(rq.rooms)]
            } else {
                rq.rooms = rq.rooms.map(room => decodeURIComponent(room))
            }
            rq.convertToCurrency = persistentStore.currency
            rq.cityId = parseInt(rq.cityId, 10)
            if (rq.citizenshipId) {
                rq.citizenshipId = parseInt(rq.citizenshipId, 10)
            }
            delete rq.supplierCode
            delete rq.cityCode
            const hotelDescriptionInfo = hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo
            rq.hotelId = hotelDescriptionInfo.localMappedHotelId || hotelDescriptionInfo.hotelId
            rq.freeOnly = rq.freeOnly === 'true' || rq.freeOnly === true
            return rq
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get hotelGalleryComponent() {
            return this.isMobile ? 'hotel-gallery-mobile' : 'hotel-gallery'
        }

        get info() {
            return hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo
        }

        get city() {
            return hotelsRuntimeStore.city
        }

        get hotelInfoRequest() {
            let {supplierCode, cityCode, hotelCode, hotelId} = this.$route.query
            if (supplierCode) supplierCode = decodeSupplierCode(supplierCode)
            return {supplierCode, cityCode, hotelCode, hotelId}
        }

        get cityId() {
            return parseInt(this.$route.query.cityId, 10)
        }
    }
</script>
