<template>
    <div>
        <div v-if="title">
            <span class="font-weight-regular"> {{ $t(title) }} </span>
        </div>
        <div>
            <div class="info-text title text-no-wrap black--text font-weight-bold">
                {{ price.amount | priceFormat(price.currency) }}
            </div>
            <span v-if="showCommission && typeof price.commission !== 'undefined'" class="commission-block">
                <span class="font-weight-regular"> {{ $t('commission') }}: </span>
                {{ price.commission | priceFormat(price.currency) }}
            </span>
            <div v-if="loyaltyPoints" class="commission-block">
                <span class="font-weight-regular">
                    {{
                        loyaltyPoints > 0
                            ? `${$t('loyalty_accum')}: ${loyaltyPoints}`
                            : `${$t('loyalty_spend')}: ${Math.abs(loyaltyPoints)}`
                    }}
                </span>
            </div>
            <slot name="violation" />
        </div>
        <cancel-penalty-label
            v-if="cancelPenalties"
            :price="price.amount"
            :currency="price.currency"
            :cancel-penalty="cancelPenalties"
            class="d-block mt-4"
        />
        <div :class="`${getPaymentColor(paymentStatus)}--text`" class="payment-status mt-1">
            {{ serviceCardGuarantee ? $t(`paymentStatus.PAY_AT_HOTEL`) : $t(`paymentStatus.${paymentStatus}`) }}
        </div>
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import CancelPenaltyLabel from '@/components/snippets/CancelPenaltyLabel'

    @Component({
        components: {
            CancelPenaltyLabel,
        },
    })
    export default class OrderPrice extends Vue {
        @Prop() title
        @Prop() price
        @Prop() paymentStatus
        @Prop() cancelPenalties
        @Prop({default: false}) showCommission
        @Prop({default: false}) violation
        @Prop({default: () => []}) corporatePolicies
        @Prop({default: true}) paymentColor
        @Prop() serviceCardGuarantee
        @Prop({default: ''}) loyaltyPoints

        getPaymentColor(status) {
            if (this.serviceCardGuarantee) {
                return 'info'
            }
            if (!this.paymentColor) {
                return 'black'
            }
            const failed = ['NOT_PAID', 'NO_BILL', 'OVERDUE', 'NO_SHOW']
            if (failed.includes(status)) {
                return 'error'
            }
            return 'success'
        }
    }
</script>

<style scoped>
    .payment-status {
        line-height: 1;
    }
</style>
