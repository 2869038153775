<script>
    import HeaderBar from '~/components/HeaderBar'

    export default class HeaderBarMod extends HeaderBar {}
</script>

<style scoped lang="scss">
    .theme--light.v-app-bar.v-toolbar.v-sheet.header {
        background-color: #1b1d22;
        border-bottom: 1px #eeeeee solid;

        .theme--light.v-list {
            background-color: #1b1d22;
        }
    }
</style>
