<template>
    <v-card shaped style="border-top: 3px solid blue">
        <v-card-title class="pa-2 d-flex flex-column justify-start align-start">
            <v-subheader class="pa-0">
                <strong>
                    {{
                        Object.keys(offer.tariff).length > 1
                            ? Object.keys(offer.tariff).join(' + ')
                            : Object.keys(offer.tariff)[0]
                    }}
                </strong>
                /
                {{ allClasses }}
            </v-subheader>
            <div>
                <strong>
                    {{ clientPrice.price | price }}
                </strong>
            </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="black--text">
            <div v-for="(tarif, index) in offer.tariff" :key="`${offer.offerKey}-tarif-${index}`">
                <strong class="green--text">
                    <span>
                        {{ tarif.name }}
                    </span>
                    <span class="caption grey--text "> / {{ tarif.classes.join(' + ') }} </span>
                </strong>
                <v-row no-gutters class="d-flex flex-column">
                    <template v-for="(route, routeIndex) in tarif.routes">
                        <v-col :key="route.departureDate" cols="12">
                            <v-row no-gutters>
                                <v-col cols="12">
                                    {{ route.departureAirportDescription.cityName }} -
                                    {{ route.arrivalAirportDescription.cityName }}
                                </v-col>

                                <v-col cols="12" class="caption grey--text">
                                    {{ route.departureDate }} - {{ route.arrivalDate }}
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-divider
                            v-if="routeIndex !== tarif.routes.length - 1"
                            :key="`devider-${route.departureDate}`"
                        />
                    </template>
                </v-row>
                <v-row no-gutters class="py-3">
                    <v-list dense>
                        <v-list-item
                            v-for="(desc, descIndex) in tarif.description"
                            :key="`${desc.type}-${descIndex}`"
                            cols="12"
                            class="disabled caption"
                            dense
                        >
                            <v-icon left :color="!desc.notOffered ? 'info' : 'grey'" small>
                                {{ getIcon(desc.type, !desc.notOffered) }}
                            </v-icon>
                            <span :class="{'grey--text': desc.notOffered}"> {{ desc.text }} </span>
                            <v-tooltip v-if="!desc.notOffered && !desc.included" top>
                                <template v-slot:activator="{on}">
                                    <v-icon right x-small color="error" v-on="on">
                                        mdi-star
                                    </v-icon>
                                </template>
                                <span class="caption">
                                    {{ $t('flights.extra_price_available') }}
                                </span>
                            </v-tooltip>
                            <span v-if="desc.notOffered" class="grey--text caption">
                                &nbsp;({{ $t('not_available') }})
                            </span>
                        </v-list-item>
                    </v-list>
                    <!-- <v-col v-for="desc in tarif.description" :key="desc.TYPE" cols="12" class="disabled caption">
                    </v-col> -->
                </v-row>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-btn color="info" block @click="selectForBooking">
                {{ $t('book') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import {flightsStore} from '~/store'
    import searchFlightRsTransformer from '~/api/flights/searchFlightRsTransformer'

    @Component
    export default class FareOffer extends Vue {
        @Prop() offer

        PRICE_TYPES = {client: 'CLIENT'}

        get clientPrice() {
            return this.offer.salesTerms.find(({type}) => type === this.PRICE_TYPES.client)
        }

        get allClasses() {
            const classes = new Set([])
            Object.values(this.offer.tariff).forEach(tarif => {
                tarif.classes.forEach(classType => {
                    classes.add(classType)
                })
            })
            return Array.from(classes).join(' + ')
        }

        selectForBooking() {
            const {offers} = searchFlightRsTransformer({offers: [this.offer]})
            flightsStore.ADD_TO_BASKET(offers[0])
            this.$router.push({name: 'flightBooking', query: {offerKey: this.offer.offerKey}})
        }

        getIcon(type, isAvailable) {
            if (!isAvailable) {
                return 'mdi-minus-circle'
            }
            switch (type) {
                case 'BG':
                    return 'mdi-bag-checked'
                case 'TS':
                    return 'mdi-cog'
                case 'ML':
                    return 'mdi-food'

                default:
                    return 'mdi-star-circle-outline'
            }
        }
    }
</script>
