<template>
    <div class="d-flex align-start">
        <v-list-item-title :title="offer.name" data-cy="hotel-link" class="hotel-link d-inline-block text-truncate">
            <a v-if="infoInNewTab" :href="$router.resolve(hotelPageLink).href" target="_blank"> {{ offer.name }} </a>
            <nuxt-link v-else-if="$route.name === 'hotels'" no-prefetch :to="hotelPageLink">
                {{ offer.name }}
            </nuxt-link>
            <span v-else>
                {{ offer.name }}
            </span>
        </v-list-item-title>
        <hotel-stars class="ms-2" :category="offer.category" />
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import HotelStars from '@/components/hotels/snippets/HotelStars'

    @Component({components: {HotelStars}})
    export default class HotelNameAndRating extends Vue {
        @Prop({required: true}) offer
        @Prop({required: true}) infoInNewTab
        @Prop({required: true}) hotelPageLink
    }
</script>
