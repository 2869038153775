<template>
    <v-snackbar v-model="value" :timeout="-1" top primary>
        {{ $t('selected_orders') }}: {{ orderIds.length }}
        <v-btn
            small
            outlined
            @click="
                orderIds = []
                $emit('change', false)
            "
        >
            {{ $t('close') }}
        </v-btn>
        <div v-if="orderIds.length" class="d-flex flex-nowrap">
            {{ $t('download') }}:
            <v-tooltip v-for="format in formats" :key="format.fileType" bottom>
                <template v-slot:activator="{on}">
                    <v-icon right color="white" class="ml-3" @click="download(format)" v-on="on">
                        {{ format.icon }}
                    </v-icon>
                </template>
                <span>{{ format.fileType }}</span>
            </v-tooltip>
        </div>
    </v-snackbar>
</template>

<script>
    import {Component, Vue, PropSync, Model} from 'vue-property-decorator'
    import {downloadData} from '@/utils/helpers'
    import {authStore} from '@/utils/store-accessor'

    //TODO Need refactoring with hotels
    @Component
    export default class OrdersDownloadSnackbar extends Vue {
        @Model('change', {default: false}) value
        @PropSync('_orderIds', {default: () => []}) orderIds

        formats = [
            {
                fileType: 'EXCEL',
                icon: 'mdi-file-excel',
                extension: 'xlsx',
            },
        ]

        async download(format) {
            try {
                const data = await this.$api.downloadOrders.get({
                    orderIds: this.orderIds,
                    fileType: format.fileType,
                    orderType: authStore.orderType,
                })
                downloadData(data, `orders.${format.extension}`)
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }
    }
</script>
