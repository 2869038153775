<template>
    <search-layout v-if="searchResponse" :map-expanded="mapExpanded" :tabs="mobileTabs">
        <template v-slot:breadcrumbs>
            <flight-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:mobileTabs>
            <package-mobile-search-page-tab v-if="isMobile" ref="mobileTabs" v-model="mobileTabs" />
        </template>
        <template v-if="offers.length" v-slot:preinfo>
            <v-col cols="12">
                <selected-schedule-offers :active-route="routeIndex" @change-route="changeRoute" />
            </v-col>
        </template>
        <template v-slot:left-sidebar>
            <flight-search-summary :search-request="searchRequest" :search-active="searchActive" />
            <sort-select v-model="sortKey" :items="sortItems" />
            <flight-filters
                :filter-values="filterValues"
                :filters="searchResponse.filters"
                :filtered-offers-count="filteredOffers.length"
                :currency="currency"
                @change="changeFilter"
                @reset="resetFilters"
            />
        </template>
        <template v-slot:main-content>
            <search-progress
                :filtered-offers-count="filteredOffers.length"
                :total-offers-count="searchResponse.offers.length"
                :search-active="searchActive"
                hide-stop-btn
            />
            <flight-offers :offers="offers">
                <template v-slot:price="{offer}">
                    <schedule-flight-selector :offer="offer" :index="routeIndex" @select-offer="selectOffer" />
                </template>
            </flight-offers>
            <pagination :page-prop.sync="page" :pages="pages" />
            <conditions-modal>
                <template v-slot:info>
                    <flight-conditions-modal-info />
                </template>
            </conditions-modal>
        </template>
        <template v-slot:right-sidebar>
            <client-only>
                <flight-map :_expanded.sync="mapExpanded" :points="mapPoints" />
            </client-only>
        </template>
    </search-layout>
</template>

<script>
    import {Component, mixins, Watch} from 'nuxt-property-decorator'
    import MobileSearchPageTabs from '@/components/snippets/MobileSearchPageTabs'
    import Pagination from '~/components/search/Pagination'
    import ConditionsModal from '~/components/modals/ConditionsModal'
    import FlightBreadcrumbs from '~/components/flights/FlightBreadcrumbs'
    import FlightSearchSummary from '~/components/flights/search/FlightSearchSummary'
    import SearchProgress from '~src/components/search/searchProgress.src'
    import FlightMap from '~/components/flights/search/FlightMap'
    import FlightOffers from '~/components/flights/search/offers/FlightOffers'
    import FlightBestOffers from '~/components/parts/Flights/FlightBestOffers'
    import SearchPageBase from '~/mixins/SearchPageBase'
    import {searchRequest} from '@/utils/flights/flights-blank-states'
    import FlightStoreMixin from '@/mixins/FlightStoreMixin'
    import {flightsStore, flightsRuntimeStore, persistentStore} from '~/store'
    import SearchLayout from '~/components/parts/SearchLayout'
    import FlightFilters from '~/components/flights/search/FlightFilters'
    import SelectedScheduleOffers from '~/components/flights/search/schedule/SelectedScheduleOffers'
    import ScheduleFlightSelector from '~/components/flights/search/schedule/ScheduleFlightSelector'
    import FlightPageMixin from '@/components/flights/mixins/FlightPageMixin'
    import {isEqual} from '@/utils/helpers'
    import SortSelect from '@/components/search/SortSelect'
    import FlightConditionsModalInfo from '@/components/flights/snippets/FlightConditionsModalInfo'

    @Component({
        components: {
            FlightConditionsModalInfo,
            SortSelect,
            FlightSearchSummary,
            SearchProgress,
            FlightMap,
            FlightOffers,
            Pagination,
            MobileSearchPageTabs,
            FlightBreadcrumbs,
            ConditionsModal,
            SearchLayout,
            FlightBestOffers,
            FlightFilters,
            SelectedScheduleOffers,
            ScheduleFlightSelector,
            PackageMobileSearchPageTab: () => import('~/components/packages/search/PackageMobileSearchPageTab'),
        },
        layout: 'blank',
    })
    export default class FlightsSchedulePage extends mixins(FlightPageMixin, SearchPageBase, FlightStoreMixin) {
        routeIndex = 0
        mobileTabs = 1

        @Watch('$route.query')
        onQueryChange() {
            this.search()
        }

        @Watch('routeIndex')
        async onRouteIndexChange(val) {
            flightsRuntimeStore.SET_ACTIVE_ROUTE(val)
            await this.$nextTick()
            this.filter()
        }

        async fetch() {
            await this.loadRuntimeData()
        }

        async mounted() {
            await this.$store.restored
            if (
                this.sortKey === 'priceAsc' ||
                this.sortKey === 'priceDesc' ||
                this.sortKey === 'priceDeltaAsc' ||
                this.sortKey === 'priceDeltaDesc'
            ) {
                flightsStore.SET_SORT('best')
            }
            if (
                !isEqual(this.productStore.scheduleSearchRequest, this.searchRequest) ||
                !this.productStore.hasScheduleOffers
            ) {
                await this.search()
            } else {
                this.resetPageLock = true
                this.filter()
            }
        }

        async search() {
            await this.productRuntimeStore.scheduleSearch(this.searchRequest)
        }

        get searchRequest() {
            //TODO Need filter $route.query params
            const rq = Object.assign(searchRequest(), this.$route.query)
            if (typeof rq.routes === 'string') {
                rq.routes = [rq.routes]
            }
            if (typeof rq.childrenAges === 'string') {
                rq.childrenAges = [parseInt(rq.childrenAges, 10)]
            }
            rq.convertToCurrency = persistentStore.currency
            rq.adults = parseInt(rq.adults, 10)
            rq.partialResponse = false
            if (rq.citizenshipId) {
                rq.citizenshipId = parseInt(rq.citizenshipId, 10)
            }
            return rq
        }

        beforeDestroy() {
            this.productRuntimeStore.SET_OFFERS([])
        }

        selectOffer() {
            if (this.routeIndex < this.routes.length - 1) {
                this.routeIndex++
            } else {
                this.mobileTabs = 3
            }
        }

        changeRoute(route) {
            this.routeIndex = route
            this.mobileTabs = 1
        }

        get mapPoints() {
            let result = []
            flightsRuntimeStore.routes.forEach(({arrival, departure}) => {
                result = [...result, departure, arrival]
            })
            return result
        }

        get selectedOffers() {
            return flightsStore.selectedScheduleOffers
        }

        get routes() {
            return flightsRuntimeStore.routes
        }

        filter() {
            flightsRuntimeStore.filter({
                filters: this.filterValues,
                offers: this.searchResponse.offers,
            })
        }

        changeFilter(data) {
            Object.assign(data, {routeIndex: this.routeIndex})
            flightsStore.SET_SCHEDULE_FILTER(data)
            this.filter()
        }

        resetFilters() {
            flightsStore.RESET_SCHEDULE_FILTERS(this.routeIndex)
            flightsRuntimeStore.sort(this.searchResponse.offers)
        }

        get sortKey() {
            return flightsStore.sortFnName
        }

        set sortKey(sortKey) {
            flightsStore.SET_SORT(sortKey)
            flightsRuntimeStore.sort(this.filteredOffers)
        }

        get searchResponse() {
            return flightsStore.searchScheduleResponse.routes[this.routeIndex]
        }

        get filterValues() {
            return flightsStore.scheduleFilters[this.routeIndex]
        }

        get filteredOffers() {
            return flightsRuntimeStore.offers
        }

        get searchActive() {
            return flightsRuntimeStore.scheduleSearchActive
        }

        get sortItems() {
            return [
                {
                    text: this.$t('sort.best'),
                    value: 'best',
                },
                {
                    text: this.$t('sort.duration_desc'),
                    value: 'durationDesc',
                },
                {
                    text: this.$t('sort.duration_asc'),
                    value: 'durationAsc',
                },
                {
                    text: this.$t('sort.airline'),
                    value: 'airline',
                },
                {
                    text: this.$t('sort.departure_time'),
                    value: 'departureTime',
                },
                {
                    text: this.$t('sort.arrival_time'),
                    value: 'arrivalTime',
                },
            ]
        }

        get currency() {
            return flightsStore.scheduleSearchRequest.convertToCurrency
        }
    }
</script>
