<template>
    <div v-if="!loaded || !noData">
        <div v-if="loaded">
            <div class="text-h6 text--secondary pb-2 ml-5">
                {{ $t('flights.select_seat') }}
            </div>
            <v-expansion-panels v-model="opennedPanels" accordion class="mb-5" focusable>
                <v-expansion-panel
                    v-for="(seatsCabinClasses, rph) in flightSeatsMap"
                    :key="rph"
                    @change="toggleSegment(rph, $event)"
                >
                    <v-expansion-panel-header v-slot="{open}">
                        <div class="d-flex flex-wrap">
                            {{ segment(rph).departure.airportDescription.airportName }},
                            {{ segment(rph).departure.airportDescription.cityName }}
                            ({{ segment(rph).departure.airportCode }})&nbsp;-&nbsp;
                            {{ segment(rph).arrival.airportDescription.airportName }},
                            {{ segment(rph).arrival.airportDescription.cityName }}
                            ({{ segment(rph).arrival.airportCode }})
                            <template v-if="segment(rph).aircraft && segment(rph).aircraft.name">
                                , {{ segment(rph).aircraft.name }}
                            </template>
                        </div>
                        <div class="d-flex flex-column justify-center align-center mr-5">
                            <template v-if="segmentSeats(rph).length">
                                <v-chip
                                    v-for="(seat, index) in segmentSeats(rph)"
                                    :key="index"
                                    small
                                    :disabled="disabled"
                                    label
                                    class="ma-1 text-capitalize"
                                    color="info"
                                    close
                                    @click:close="unselectSeat(seat)"
                                >
                                    <span
                                        v-if="
                                            tourists[seat.touristIndex].prefix && tourists[seat.touristIndex].lastName
                                        "
                                    >
                                        {{ tourists[seat.touristIndex].prefix }}
                                        {{ tourists[seat.touristIndex].lastName }}:
                                    </span>
                                    <span v-else>{{ $tc('flights.passenger', 1) }} {{ seat.touristIndex + 1 }}: </span>
                                    <span>
                                        {{ seat.seatNumber }}{{ seat.rowNumber }}
                                        <span v-if="seatPrice(seat)"> (+{{ seatPrice(seat) | price }})</span>
                                    </span>
                                </v-chip>
                            </template>
                            <span v-else-if="open">
                                {{ $t('flights.select_seat') }}
                            </span>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-item-group
                            v-if="loaded"
                            v-model="selected[rph]"
                            multiple
                            :max="selectableSeatsCount"
                            @change="selectSeat"
                        >
                            <div class="d-flex flex-column justify-center mx-2 mt-2">
                                <v-item v-for="tarif in Object.keys(seatColorMap)" :key="tarif" v-slot:default>
                                    <v-card
                                        v-if="seatColorMap[tarif].flight.indexOf(rph) > -1"
                                        :color="seatColorMap[tarif].color"
                                        class="d-flex align-center justify-center seat-number mx-1"
                                        :height="maxSeatWidth"
                                    >
                                        <div class="caption">
                                            {{ tarif }}
                                        </div>
                                    </v-card>
                                </v-item>
                            </div>
                            <div
                                v-for="seatsCabinClass in seatsCabinClasses"
                                :key="seatsCabinClass.bookingClass"
                                class="seats-cabin-class"
                                :style="{width: seatsCabinWidth(seatsCabinClasses)}"
                            >
                                <div class="text--h6 text-center pt-5">
                                    {{ seatsCabinClass.cabinClass }}
                                </div>
                                <div
                                    v-for="seatRow in seatsCabinClass.seatRows"
                                    :key="seatRow.rowNumber"
                                    class="my-3"
                                    :style="{height: maxSeatWidth + 'px'}"
                                >
                                    <div class="row-number" :style="{height: maxSeatWidth + 'px'}">
                                        {{ seatRow.rowNumber }}
                                    </div>
                                    <div class="d-flex justify-space-between">
                                        <div
                                            v-for="(seatGroup, seatGroupIndex) in seatRow.seatGroups"
                                            :key="seatGroupIndex"
                                            class="d-flex"
                                            :style="{width: seatGroupWidth(seatRow.seatGroups.length)}"
                                        >
                                            <template v-for="seat in seatGroup">
                                                <v-item
                                                    v-if="seat.seatAvailability"
                                                    :key="seat.seatNumber"
                                                    v-slot="{active, toggle}"
                                                    :value="`${rph}_${seatRow.rowNumber}_${seat.seatNumber}`"
                                                >
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{on, attrs}">
                                                            <v-card
                                                                :ref="`seatNumber-${rph}`"
                                                                :disabled="disabled"
                                                                :color="active ? 'primary' : makeSeatMapColor(seat)"
                                                                class="d-flex align-center justify-center seat-number mx-1"
                                                                :height="maxSeatWidth"
                                                                :width="seatWidth(seatGroup.length)"
                                                                v-bind="attrs"
                                                                @click="clickSeat(toggle, $event, rph)"
                                                                v-on="on"
                                                            >
                                                                <div class="caption">
                                                                    {{ seat.seatNumber }}
                                                                </div>
                                                            </v-card>
                                                        </template>
                                                        <span>{{ createNameForColorMap(seat.passengerFares) }}</span>
                                                    </v-tooltip>
                                                </v-item>
                                                <v-card
                                                    v-else
                                                    :key="seat.seatNumber"
                                                    ref="seatNumber"
                                                    color="grey lighten-3"
                                                    class="d-flex align-center justify-center seat-number mx-1"
                                                    :height="maxSeatWidth"
                                                    :width="seatWidth(seatGroup.length)"
                                                    outlined
                                                >
                                                    <div class="caption">
                                                        {{ seat.seatNumber }}
                                                    </div>
                                                </v-card>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-item-group>
                        <v-container v-else class="loader">
                            <v-skeleton-loader type="table-row@16" :elevation="0" />
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
        <div v-else>
            <span class="text-h6 text--secondary pb-2 ml-5">
                {{ $t('loadind_seats') }}
            </span>
            <v-skeleton-loader :type="`list-item@${segmentRphs.length}`" :elevation="0" class="mb-5" />
        </div>
    </div>
</template>

<script>
    import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
    import {flightsRuntimeStore, flightsStore, persistentStore} from '@/utils/store-accessor'
    import {clone, isEqual} from '@/utils/helpers'

    @Component
    export default class FlightSeatsMap extends Vue {
        @Prop({required: true}) offer
        @Prop({required: true}) searchRequest
        @Prop({default: false, type: Boolean}) disabled

        selected = {}
        maxSeatWidth = 25
        loaded = false
        segmentRphs = []
        seatColorMap = {}
        opennedPanels = false

        async mounted() {
            const {offerKey} = this.offer
            this.segmentRphs = this.offer.itinerary.reduce((segmentRphs, {segments}) => {
                segments.forEach(({rph}) => {
                    segmentRphs.push(rph)
                    let selectedValues = []
                    if (
                        flightsStore.prepareBookRequest.seats &&
                        flightsStore.prepareBookRequest.offerKey === offerKey
                    ) {
                        selectedValues = flightsStore.prepareBookRequest.seats
                            .filter(seat => seat.segmentRPH === rph)
                            .map(seat => `${rph}_${seat.rowNumber}_${seat.seatNumber}`)
                    } else if (flightsStore.prepareBookRequest.offerKey !== offerKey) {
                        flightsStore.SET_PREPARE_BOOK_REQUEST_PROP({
                            prop: 'seats',
                            val: [],
                        })
                    }
                    this.$set(this.selected, rph, selectedValues)
                })
                return segmentRphs
            }, [])
            await flightsRuntimeStore.loadFlightSeatsMap({offerKey, segmentRphs: this.segmentRphs})
            this.makeSeatColorMap(flightsRuntimeStore.flightSeatsMap)
            this.loaded = true
        }

        @Watch('disabled')
        closeAllExpandPanels(n) {
            if (n) {
                this.opennedPanels = false
            }
        }

        makeSeatColorMap(map) {
            const keys = Object.keys(map)
            Object.values(map).forEach((init, index) => {
                init.forEach(cabin => {
                    if (cabin.seatRows.length) {
                        cabin.seatRows.forEach(row => {
                            if (row.seats.length) {
                                row.seats.forEach(seat => {
                                    if (seat.passengerFares.length) {
                                        let name = this.createNameForColorMap(seat.passengerFares)
                                        if (!this.seatColorMap[name]) {
                                            this.seatColorMap[name] = {
                                                color: this.getRandomColor(),
                                                flight: [keys[index]],
                                            }
                                        } else {
                                            if (this.seatColorMap[name].flight.indexOf(keys[index]) === -1) {
                                                this.seatColorMap[name].flight.push(keys[index])
                                            }
                                        }
                                    }
                                })
                            }
                        })
                    }
                })
            })
        }

        createNameForColorMap(tarifs) {
            let name = ''
            if (tarifs.length) {
                tarifs.forEach(tarif => {
                    if (name.length) {
                        name += '\\ '
                    }
                    name += `${tarif.passengerType} - ${tarif.price.amount} ${tarif.price.currency}`
                })
            }
            return name ? name : this.$t('no_tarif')
        }

        segment(rph) {
            let segmentIndex
            const itineraryIndex = this.offer.itinerary.findIndex(({segments}) => {
                const index = segments.findIndex(segment => segment.rph === rph)
                if (index !== -1) {
                    segmentIndex = index
                    return true
                } else {
                    return false
                }
            })
            return this.offer.itinerary[itineraryIndex].segments[segmentIndex]
        }

        segmentSeats(rph) {
            return this.seats.filter(seat => seat.segmentRPH === rph)
        }

        selectSeat() {
            flightsStore.SET_PREPARE_BOOK_REQUEST_PROP({
                prop: 'seats',
                val: this.seats,
            })
            if (flightsStore.prepareBookRequest.offerKey !== this.offer.offerKey) {
                flightsStore.SET_PREPARE_BOOK_REQUEST_PROP({
                    prop: 'offerKey',
                    val: this.offer.offerKey,
                })
            }
        }

        unselectSeat(seat) {
            this.selected[seat.segmentRPH] = this.selected[seat.segmentRPH].filter(
                key => key !== `${seat.segmentRPH}_${seat.rowNumber}_${seat.seatNumber}`
            )
            const seats = this.seats.filter(seatItem => !isEqual(seatItem, seat))
            flightsStore.SET_PREPARE_BOOK_REQUEST_PROP({
                prop: 'seats',
                val: seats,
            })
        }

        seatGroupWidth(count) {
            return (100 - (count - 1) * 10) / count + '%'
        }

        seatWidth(count) {
            return (100 - (count - 1) * 2) / count + '%'
        }

        seatsCabinWidth(seatsCabinClasses) {
            const maxRowSeatsCount = seatsCabinClasses.reduce((maxRowSeatsCount, seatsCabinClass) => {
                seatsCabinClass.seatRows.forEach(seatRow => {
                    const rowSeatsCount = seatRow.seatGroups.reduce((rowSeatsCount, seatGroup) => {
                        rowSeatsCount += seatGroup.length
                        return rowSeatsCount
                    }, 0)
                    if (rowSeatsCount > maxRowSeatsCount) {
                        maxRowSeatsCount = rowSeatsCount
                    }
                })
                return maxRowSeatsCount
            }, 0)
            return (maxRowSeatsCount <= 4 ? 200 : 400) + 'px'
        }

        toggleSegment(rph, show) {
            if (show) {
                this.maxSeatWidth = Math.max(
                    ...this.$refs[`seatNumber-${rph}`].map(seatNumber => seatNumber.$el.clientWidth)
                )
            }
        }

        seatPrice({segmentRPH, rowNumber, seatNumber, touristIndex}) {
            let price
            flightsRuntimeStore.flightSeatsMap[segmentRPH].forEach(seatsCabinClass => {
                const seatRow = seatsCabinClass.seatRows.find(seatRow => {
                    return seatRow.rowNumber === +rowNumber
                })
                if (seatRow) {
                    const seat = seatRow.seats.find(seat => seat.seatNumber === seatNumber)
                    if (seat) {
                        const passengerType = persistentStore.tourists[touristIndex].type === 'adult' ? 'ADT' : 'CHD'
                        const passengerFare = seat.passengerFares.find(
                            passengerFare => passengerFare.passengerType === passengerType
                        )
                        if (passengerFare) {
                            price = passengerFare.price
                        }
                    }
                }
            })
            return price
        }

        async clickSeat(toggle, $event, rph) {
            if (this.disabled) return
            if (this.segmentSeats(rph).length === this.selectableSeatsCount) {
                this.selected[rph] = []
                await this.$nextTick()
            }
            toggle($event)
        }

        makeSeatMapColor(seat) {
            if (seat.passengerFares.length) {
                return this.seatColorMap[this.createNameForColorMap(seat.passengerFares)].color
            }
            return ''
        }

        getRandomColor() {
            const randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1))
            const r = randomBetween(120, 255)
            const g = randomBetween(120, 255)
            const b = randomBetween(120, 255)
            const rgb = `rgba(${r},${g},${b}, 0.3)`
            return rgb
        }

        get seats() {
            return Object.values(this.selected).reduce((seats, selectedValues) => {
                selectedValues.forEach((selectedValue, touristIndex) => {
                    const data = selectedValue.split('_')
                    seats.push({
                        segmentRPH: data[0],
                        touristIndex,
                        rowNumber: data[1],
                        seatNumber: data[2],
                    })
                })
                return seats
            }, [])
        }

        get flightSeatsMap() {
            const flightSeatsMap = clone(flightsRuntimeStore.flightSeatsMap)
            const noAvailableSeatsSegments = []
            Object.values(flightSeatsMap).forEach((segmentSeatsMap, index) => {
                let noAvailableSeats = true
                segmentSeatsMap.forEach(seatsCabinClasses =>
                    seatsCabinClasses.seatRows.forEach(seatRow => {
                        let seatGroupIndex = 0,
                            nextGroup = false
                        seatRow.seatGroups = seatRow.seats.reduce(
                            (seatGroups, seat) => {
                                if (seat.seatCharDescription.includes('Aisle')) {
                                    if (nextGroup) {
                                        seatGroupIndex++
                                        nextGroup = false
                                    } else {
                                        seatGroups[seatGroupIndex + 1] = []
                                        nextGroup = true
                                    }
                                }
                                seatGroups[seatGroupIndex].push(seat)
                                if (seat.seatAvailability) noAvailableSeats = false
                                return seatGroups
                            },
                            [[]]
                        )
                    })
                )
                if (noAvailableSeats) noAvailableSeatsSegments.push(Object.keys(flightSeatsMap)[index])
            })
            noAvailableSeatsSegments.forEach(rph => {
                delete flightSeatsMap[rph]
            })
            return flightSeatsMap
        }

        get selectableSeatsCount() {
            return this.searchRequest.adults + this.searchRequest.childrenAges.filter(age => age > 2).length
        }

        get tourists() {
            return persistentStore.tourists
        }

        get noData() {
            return Object.values(this.flightSeatsMap).every(segmentSeatsMap => !segmentSeatsMap.length)
        }
    }
</script>

<style scoped lang="scss">
    .seats-cabin-class {
        position: relative;
        max-width: 400px;
        margin: 0 auto;
    }
    .row-number {
        position: absolute;
        left: -30px;
        align-items: center;
        display: flex;
    }
    .seat-number {
        &.v-item--active {
            color: white;
        }
    }
    .loader {
        width: 200px;
    }
</style>
