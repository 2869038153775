<template>
    <v-autocomplete
        ref="input"
        v-model="city"
        :items="items"
        :loading="loading"
        :search-input.sync="search"
        hide-no-data
        hide-selected
        item-value="id"
        :dense="dense"
        :label="$t(label)"
        :placeholder="showPlaceholder ? $t(label) : ''"
        :no-filter="true"
        :clearable="true"
        return-object
        :single-line="singleLine"
        :outlined="outlined"
        :filled="filled"
        :disabled="disabled"
        :hide-details="!tryResearch.status"
        :error="tryResearch.status"
        :error-messages="tryResearch.status ? [tryResearch.message] : []"
        background-color="white"
        :prepend-inner-icon="showIcon ? 'mdi-map-marker' : null"
        :rules="rules"
        :class="textFieldClass"
        data-cy="destination-input"
        @change="
            $emit('change', $event)
            $refs.input.blur()
        "
        @click:clear="tryResearch.status = false"
        @input="clearResearch"
    >
        <template v-slot:item="{item}">
            <v-icon small left class="city-autocomplete-icon">
                {{ icon(item.type) }}
            </v-icon>
            <span v-html="item.label" />
        </template>
        <template v-slot:selection="{item}">
            {{ item.selectedLabel || item.label }}
        </template>
    </v-autocomplete>
</template>

<script>
    import {Vue, Component, Watch, Model, Prop} from 'vue-property-decorator'

    @Component
    export default class CityAutocomplete extends Vue {
        @Model('change') value
        @Prop() rules
        @Prop({default: false, type: Boolean}) outlined
        @Prop({default: true}) singleLine
        @Prop({default: false}) filled
        @Prop({default: () => []}) defaultCities
        @Prop({default: 'destination'}) label
        @Prop({default: true}) showPlaceholder
        @Prop({default: true}) showIcon
        @Prop({default: false}) disabled
        @Prop() countryId
        @Prop({default: false}) dense
        @Prop() productType

        entries = []
        loading = false
        search = null
        city = null
        tryResearch = {
            status: false,
            message: '',
        }
        timer = null

        mounted() {
            if (!this.search && !this.city && this.entries.length === 0) {
                this.entries = this.defaultCities
            }
        }

        clearResearch() {
            if (this.tryResearch.status) {
                this.tryResearch.status = false
            }
        }

        get labels() {
            return this.entries.map(entry => {
                return (
                    `${entry.name}, ${entry.parentName}` +
                    (entry.parentName !== entry.countryName ? `, ${entry.countryName}` : '')
                )
            })
        }

        get items() {
            return this.entries.map(entry => {
                const label =
                    `${entry.name}, ${entry.parentName}` +
                    (entry.parentName !== entry.countryName ? `, ${entry.countryName}` : '')
                return Object.assign({}, entry, {label})
            })
        }

        @Watch('search')
        onSearch(val) {
            if (this.timer) {
                clearTimeout(this.timer)
            }
            this.timer = window.setTimeout(() => {
                this.initSearch(val)
            }, 500)
        }

        async searchCities(val) {
            const rq = {
                pattern: val.trim() + '%',
                limitCities: 10,
                orderBy: 'POPULARITY',
                ...(this.countryId && {countryId: this.countryId}),
                ...(this.productType && {productType: this.productType}),
                ...(this.productType === 'ACCOMMODATION' && {hotelsQuantity: 1}),
            }
            const rs = await this.$api.locations.get(rq)
            if (rs.cities.length === 0) {
                this.tryResearch.status = true
                this.tryResearch.message = this.$t('try_rewrite_destination')
            }
            return rs.cities
        }

        async initSearch(val) {
            if (this.loading || (this.labels.length > 0 && this.labels.indexOf(val) !== -1) || !val) return
            this.loading = true
            try {
                this.entries = await this.searchCities(val)
            } finally {
                this.loading = false
            }
        }

        @Watch('value')
        onChangeValue(val) {
            this.city = val
            if (val && Object.keys(val).length) {
                this.entries = [val]
            }
        }

        get locationIcon() {
            return 'mdi-earth'
        }

        icon() {
            return this.locationIcon
        }

        get textFieldClass() {
            return this.$breakpoint && this.$breakpoint.smAndDown ? 'caption' : ''
        }
    }
</script>
