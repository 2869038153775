<template>
    <v-list-item ref="infoWindow" three-line class="pl-0">
        <v-list-item-avatar v-if="offer.thumb" class="my-0" :size="isCompactMode ? '50' : '125'" tile>
            <v-img
                tile
                class="thumb"
                :src="offer.thumb | imageUrl(250)"
                :alt="offer.name"
                lazy-src="/placeholder.png"
                @error="onImgError"
                @load="load"
            >
                <template v-slot:placeholder>
                    <v-row v-show="!imgError && offer.thumb" class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
        </v-list-item-avatar>
        <div class="align-self-start" :class="isCompactMode ? 'small-content' : ''">
            <v-rating class="d-flex mr-3" x-small dense readonly :value="offer.category" />
            <v-list-item-title :title="offer.name">
                <n-link v-if="hotelPageLink" no-prefetch :to="hotelPageLink">
                    <span class="text-wrap">{{ offer.name }}</span>
                </n-link>
                <span v-else class="text-wrap">
                    {{ offer.name }}
                </span>
            </v-list-item-title>
            <v-list-item-subtitle>
                <div v-if="offer.address" class="text-wrap">
                    <v-icon small>
                        mdi-map-marker
                    </v-icon>
                    {{ offer.address }}
                </div>
                <div v-if="offer.distance">
                    <strong>{{ $t('distance') }}:</strong>
                    {{ offer.distance }} km from center
                </div>
                <div v-if="offer.district || offer.district === 'other'">
                    <strong>{{ $t('district') }}:</strong>
                    {{ offer.district }}
                </div>
            </v-list-item-subtitle>
            <template v-if="minPriceRoomOffer">
                <div v-if="minPriceRoomOffer.price" class="subtitle-1" :class="isCompactMode ? 'small-content' : ''">
                    <span>
                        {{ $t('from') }}
                    </span>
                    {{ minPriceRoomOffer.price | price }}
                </div>
                <offer-delta-button v-else :room="minPriceRoomOffer" />
            </template>
        </div>
    </v-list-item>
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator'
    import {hotelsRuntimeStore, hotelsStore} from '@/utils/store-accessor'

    @Component({
        components: {
            OfferDeltaButton: () => import('~/components/packages/search/OfferDeltaButton'),
        },
    })
    export default class HotelMapInfoWindow extends Vue {
        @Prop() offer
        @Prop({default: true}) withLink

        imgError = false
        clientWidth = 201

        load() {
            this.setSize()
        }

        onImgError() {
            this.imgError = true
            return false
        }

        setSize() {
            this.clientWidth = this.$refs.infoWindow.$el.clientWidth
        }

        get hotelPageLink() {
            if (this.withLink) {
                return hotelsRuntimeStore.hotelPageLink(this.offer, hotelsStore.searchRequest)
            }
            return null
        }

        get minPriceRoomOffer() {
            return hotelsRuntimeStore.minPriceRoomOffer(this.offer)
        }

        get isCompactMode() {
            return this.clientWidth < 200
        }
    }
</script>

<style scoped>
    .small-content {
        font-size: 0.8rem !important;
    }
</style>
