<template>
    <span v-if="visible" class="caption red--text text-no-nowrap d-block">
        {{ $t('commission') }}:
        {{ commission | price }}
    </span>
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator'
    import {authStore} from '~/store'

    @Component
    export default class CommissionAmount extends Vue {
        @Prop({required: true}) commission

        get visible() {
            return (
                this.commission &&
                authStore.companyType &&
                authStore.companyType !== 'CORPORATE' &&
                this.$route.query.showCommission
            )
        }
    }
</script>
