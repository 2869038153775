<template>
    <div v-if="room.tariff && ownProduct">
        {{ room.tariff }}
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import {hotelsRuntimeStore} from '@/utils/store-accessor'

    @Component
    export default class TariffLabel extends Vue {
        @Prop({required: true}) room
        @Prop({required: true}) hotel

        get ownProduct() {
            return hotelsRuntimeStore.ownProduct(this.hotel)
        }
    }
</script>
