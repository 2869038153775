<template>
    <search-form-layout
        data-cy="hotel-search-form"
        :popup="popup"
        title="hotels"
        :width="isIframe ? '100%' : undefined"
        @close-popup="$emit('close-popup')"
    >
        <template v-slot:form>
            <v-form ref="form" v-model="valid" data-cy="hotels-form">
                <v-row>
                    <v-col v-if="!isSingleHotelSearch" md="4" cols="12">
                        <city-hotel-autocomplete
                            v-model="city"
                            single-line
                            filled
                            :rules="[v => !!v || `${$t('destination')} ${$t('validation.required')}`]"
                            :default-cities="popularDestinations"
                        />
                    </v-col>
                    <v-col :md="isSingleHotelSearch ? 5 : 3" cols="12">
                        <daterange-m
                            :start-date-prop.sync="startDate"
                            :end-date-prop.sync="endDate"
                            :popover="isIframe ? {placement: 'top'} : {placement: 'bottom'}"
                        />
                    </v-col>
                    <v-col :md="isSingleHotelSearch ? 5 : 3" cols="12">
                        <rooms-select v-model="rooms" />
                    </v-col>
                    <v-col class="hidden-sm-and-down" md="2" cols="12">
                        <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                            {{ $t('search') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <additional-options-wrapper
                ref="addForm"
                :options="['promocode', 'meal', 'citizenship', 'rating', 'freeOnly']"
            />
            <v-row class="hidden-md-and-up">
                <v-col md="2" cols="12">
                    <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                        {{ $t('search') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </search-form-layout>
</template>

<script>
    import {Mixins, Component, Prop} from 'vue-property-decorator'
    import CityHotelAutocomplete from '~/components/search/forms/CityHotelAutocomplete'
    import RoomsSelect from '~src/components/search/forms/roomsSelect.src'
    import {hotelsStore, hotelsRuntimeStore, cmsStore, authStore, persistentStore} from '~/store'
    import AdditionalSearchOptionsMixin from '~/mixins/AdditionalSearchOptionsMixin'
    import AdditionalOptionsWrapper from '~src/components/snippets/AdditionalSearchOptions/additionalOptionsWrapper.src'
    import SearchFormLayout from '~/components/parts/SearchFormLayout'
    import DaterangeM from '~src/components/search/forms/daterangeM.src'
    import TouristsSelect from '~/components/search/forms/TouristsSelect'
    import {CHANGE_LOCALE_EVENT, CHANGE_TAB_MOBILE_EVENT, EventBus} from '@/utils/event-bus'

    @Component({
        components: {
            CityHotelAutocomplete,
            DaterangeM,
            TouristsSelect,
            RoomsSelect,
            AdditionalOptionsWrapper,
            SearchFormLayout,
        },
    })
    export default class HotelSearchForm extends Mixins(AdditionalSearchOptionsMixin) {
        @Prop({default: false, type: Boolean}) popup
        @Prop({default: false, type: Boolean}) isIframe
        @Prop() cityId

        city = {}
        startDate = null
        endDate = null
        rooms = []
        valid = false
        loaded = false

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, this.reload)
        }

        async reload() {
            if (this.city && this.city.id) {
                try {
                    const {
                        cities: [city],
                    } = await this.$api.locations.get({id: this.city.id, limitCities: 1})
                    this.city = city
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
        }

        async mounted() {
            await this.load()
        }

        async load() {
            await this.$store.restored
            this.loaded = true
            await this.$nextTick()
            const cityId = this.cityId || this.searchRequest.cityId
            if (cityId && hotelsRuntimeStore.city.id !== cityId) {
                await hotelsRuntimeStore.loadCity(cityId)
            }
            this.city = Object.keys(hotelsRuntimeStore.city).length ? hotelsRuntimeStore.city : null
            if (this.$dateFns.isAfter(this.$dateFns.parseISO(this.searchRequest.startDate), new Date())) {
                this.startDate = this.searchRequest.startDate
                this.endDate = this.searchRequest.endDate
            } else {
                this.startDate = this.$dateFns.format(this.$dateFns.addDays(new Date(), 1))
                this.endDate = this.$dateFns.format(this.$dateFns.addDays(new Date(), 7))
            }
            this.rooms = !this.onlySinglePassenger ? this.searchRequestTourists : [{adults: 1, childrenAges: []}]
        }

        newQuery() {
            const query = {
                cityId: this.city.id,
                startDate: this.startDate,
                endDate: this.endDate,
                rooms: this.rooms.map(room => hotelsRuntimeStore.roomQueryString(room)),
                convertToCurrency: persistentStore.currency,
            }
            if (this.city.hotelCode) {
                Object.assign(query, {
                    hotelCode: this.city.hotelCode,
                    cityId: this.city.cityId,
                    hotelId: this.city.id,
                    cityCode: this.city.id,
                    supplierCode: null,
                })
            }
            return query
        }

        async search() {
            this.$emit('close-popup')
            const query = this.newQuery()
            hotelsRuntimeStore.SET_CITY(this.city)
            if (this.$route.name === 'hotels') {
                try {
                    await this.$router.push({name: 'hotels', query})
                } catch (e) {
                    await hotelsRuntimeStore.search(this.searchRequest)
                }
            } else {
                let name
                if (this.city.hotelCode) {
                    name = 'hotel'
                    hotelsRuntimeStore.newSingleHotelSearch()
                } else {
                    name = 'hotels'
                    hotelsRuntimeStore.newSearch()
                }
                if (this.isIframe) {
                    window.top.location.href = window.location.origin + this.$router.resolve({name, query}).href
                } else {
                    await this.$router.push({name, query})
                }
            }
            EventBus.$emit(CHANGE_TAB_MOBILE_EVENT, 1)
        }

        get searchRequest() {
            return hotelsStore.searchRequest
        }

        get searchRequestTourists() {
            return hotelsStore.searchRequestTourists
        }

        get isSingleHotelSearch() {
            return false
        }

        get popularDestinations() {
            return cmsStore.popularDestinations
        }

        get onlySinglePassenger() {
            return authStore.user.isEmployee
        }
    }
</script>
