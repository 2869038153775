<template>
    <v-select
        v-if="mealTypes.length"
        :label="$t('change_extra_meal_type')"
        :items="mealTypes"
        item-value="id"
        :item-text="mealTypeLabel"
        :prepend-item="{name: '', value: null}"
        clearable
        :value="$attrs.value"
        :disabled="disabled"
        @change="update"
    />
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator'
    import {hotelsStore} from '~/store'

    @Component
    export default class MealTypeSelector extends Vue {
        @Prop() roomIndex
        @Prop() standardMealName
        @Prop({default: false, type: Boolean}) disabled
        @Prop({default: false, type: Boolean}) isTourLead

        mounted() {
            this.update(this.$attrs.value)
        }

        mealTypeLabel({name, prices}) {
            return `${name} (${this.$options.filters.price(prices[0].price)})`
        }

        update(value) {
            const mealType = this.mealTypes.find(el => el.id === value)
            if (!mealType) {
                value = null
            }
            this.$emit('input', value)
            this.$emit('update', mealType)
        }

        get mealTypes() {
            const meals = hotelsStore.extraMealTypes
            return meals[this.roomIndex] ? meals[this.roomIndex].filter(el => el.name !== this.standardMealName) : []
        }
    }
</script>
