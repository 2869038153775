<template>
    <div>
        <v-form ref="form" v-model="valid" data-cy="booking-form">
            <h4 class="title text-capitalize">
                {{ $tc('guest', 1) }} {{ index + 1 }} <span v-if="isChild">({{ $t('child') }})</span>
            </h4>
            <div v-if="personNameOnLatin" class="subtitle-2 accent--text">
                {{ $t('notes.fill_in_english') }}
            </div>
            <v-row v-if="showTouristSelector" dense>
                <v-col cols="12" md="6">
                    <tourist-selector
                        :tourist="tourist"
                        :selected-tourists="selectedTourists"
                        :required="corporatePolicyViolation"
                        :disabled="isDisabled"
                        @selected="onPersonSelected"
                    />
                </v-col>
            </v-row>
            <v-row v-if="!showTouristSelector || !corporatePolicyViolation || personId" dense>
                <v-col cols="12" md="6">
                    <v-row dense>
                        <v-col v-if="!isFieldDisabled('PREFIX')" cols="12" md="4">
                            <v-select
                                v-model="prefix"
                                :items="['Mr', 'Ms', 'Mrs']"
                                :label="fieldLabel('salutation', 'PREFIX')"
                                :rules="[requiredRule('salutation', 'PREFIX')]"
                                required
                                :disabled="isDisabled"
                                data-cy="tourist-field-prefix"
                            />
                        </v-col>
                        <v-col cols="12" :md="!isFieldDisabled('PREFIX') ? 4 : 6">
                            <v-text-field
                                v-model="firstName"
                                :label="`${$t('first_name')}*`"
                                :rules="validationRules('first_name', [latinRule, requiredRule])"
                                required
                                :disabled="isDisabled || lockEditPrivateClientName"
                                data-cy="tourist-field-first-name"
                            />
                        </v-col>
                        <v-col cols="12" :md="!isFieldDisabled('PREFIX') ? 4 : 6">
                            <v-text-field
                                v-model="lastName"
                                :label="`${$t('last_name')}*`"
                                :rules="validationRules('last_name', [latinRule, requiredRule])"
                                required
                                :disabled="isDisabled || lockEditPrivateClientName"
                                data-cy="tourist-field-last-name"
                            />
                        </v-col>
                        <v-col
                            v-if="
                                showSameContactDataCheckbox ||
                                    (!isFieldDisabled('PHONE') &&
                                        (roomLength === 1 || !sameContactDataForAll || isTourLead))
                            "
                            cols="12"
                        >
                            <v-text-field
                                v-if="
                                    !isFieldDisabled('PHONE') &&
                                        (roomLength === 1 || !sameContactDataForAll || isTourLead)
                                "
                                v-model="phone"
                                :disabled="isDisabled"
                                :label="fieldLabel('phone', 'PHONE')"
                                type="tel"
                                data-cy="tourist-field-phone"
                                :rules="[phoneRule('phone', 'PHONE')]"
                            />
                            <v-checkbox
                                v-if="showSameContactDataCheckbox"
                                :input-value="sameContactDataForAll"
                                :label="`${$t('same_contact_data')}`"
                                @change="changeSameContactData"
                            />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6">
                    <v-row dense>
                        <v-col v-if="!isFieldDisabled('CITIZENSHIP')" cols="12">
                            <v-autocomplete
                                v-model="citizenshipId"
                                :disabled="isDisabled"
                                :label="fieldLabel('citizenship', 'CITIZENSHIP')"
                                :items="countries"
                                item-text="name"
                                item-value="id"
                                :rules="[requiredRule('citizenship', 'CITIZENSHIP')]"
                                required
                                data-cy="tourist-field-citizenship"
                            />
                        </v-col>
                        <v-col
                            v-if="
                                !isFieldDisabled('EMAIL') && (roomLength === 1 || !sameContactDataForAll || isTourLead)
                            "
                            cols="12"
                        >
                            <v-text-field
                                v-model="email"
                                :label="fieldLabel('email', 'EMAIL')"
                                :disabled="isDisabled"
                                type="email"
                                :rules="validationRules('email', [emailRule, requiredRule], 'EMAIL')"
                                persistent-hint
                                :hint="$t('notes.email_for_voucher')"
                                required
                                data-cy="tourist-field-email"
                            />
                        </v-col>
                        <v-col v-if="!isFieldDisabled('BIRTHDATE')" cols="12">
                            <v-menu
                                v-model="showBirthDatePicker"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{on}">
                                    <v-text-field
                                        :value="birthdate | dateShortFormat"
                                        :label="fieldLabel('birthdate', 'BIRTHDATE')"
                                        :disabled="isDisabled"
                                        clearable
                                        readonly
                                        background-color="white"
                                        prepend-inner-icon="mdi-calendar"
                                        :rules="[requiredRule('birthdate', 'BIRTHDATE')]"
                                        required
                                        data-cy="tourist-field-birth-date"
                                        v-on="on"
                                    />
                                </template>
                                <v-date-picker
                                    ref="birthDatePicker"
                                    v-model="birthdate"
                                    :locale="locale"
                                    :min="minBirthDate"
                                    :max="maxBirthDate"
                                    @change="showBirthDatePicker = false"
                                />
                            </v-menu>
                        </v-col>
                        <v-col v-if="showMealTypes" cols="12">
                            <meal-type-selector
                                v-model="alternativeMealTypeId"
                                :room-index="roomIndex"
                                :standard-meal-name="standardMealName"
                                :disabled="disabled"
                                data-cy="tourist-field-meal-type"
                                :is-tour-lead="isTourLead"
                                @update="setExtraMealType"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
        <hotel-tourist-passport-form
            :room-index="roomIndex"
            :index="index"
            :disabled="disabled"
            :corporate-policy-violation="corporatePolicyViolation"
        />
    </div>
</template>

<script>
    //TODO Improve input labels
    import MealTypeSelector from '~/components/booking/forms/fields/MealTypeSelector'
    import {mixins, Component, Prop, PropSync, Watch} from 'nuxt-property-decorator'
    import {hotelsStore, authStore, runtimeStore} from '~/store'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import TouristSelector from '@/components/parts/Booking/TouristSelector'
    import HotelTouristPassportForm from '@/components/hotels/booking/form/HotelTouristPassportForm'
    import {EventBus, SAME_CONTACT_DATA_FOR_ALL} from '@/utils/event-bus'
    import BookingFieldsMixin from '@/components/booking/mixins/BookingFieldsMixin'

    //TODO Need some refactoring with TouristForm
    @Component({
        components: {
            HotelTouristPassportForm,
            TouristSelector,
            MealTypeSelector,
        },
    })
    export default class HotelTouristForm extends mixins(FormValidationRulesMixin, BookingFieldsMixin) {
        @Prop() roomIndex
        @Prop() index
        @Prop() disabled
        @Prop({default: false}) showMealTypes
        @Prop() standardMealName
        @PropSync('_valid', {default: false}) valid
        @Prop() corporatePolicyViolation

        sameContactDataForAll = true
        showBirthDatePicker = false

        mounted() {
            EventBus.$on(SAME_CONTACT_DATA_FOR_ALL, this.setSameContactData)
        }

        @Watch('showBirthDatePicker')
        onShowBirthDatePicker(val) {
            val && setTimeout(() => (this.$refs.birthDatePicker.activePicker = 'YEAR'))
        }

        @Watch('locale')
        onLocaleChange() {
            this.$refs.form.validate()
        }

        onPersonSelected(person) {
            const index = this.index,
                roomIndex = this.roomIndex
            if (person) {
                const val = runtimeStore.touristFromPerson(person)
                hotelsStore.SET_TOURIST({roomIndex, index, val})
            } else {
                hotelsStore.SET_TOURIST_PROP({roomIndex, index, prop: 'personId', val: null})
            }
        }

        getProp(prop) {
            return hotelsStore.tourists[this.roomIndex][this.index][prop]
        }

        setProp(prop, val) {
            const roomIndex = this.roomIndex,
                index = this.index
            if (this.sameContactDataForAll && (prop === 'phone' || prop === 'email')) {
                this.setCameContactDataForAll(prop, val)
            }
            hotelsStore.SET_TOURIST_PROP({prop, val, roomIndex, index})
        }

        setCameContactDataForAll(prop, val) {
            const allRooms = hotelsStore.tourists.length
            for (let i = 0; i < allRooms; i++) {
                hotelsStore.SET_TOURIST_PROP({prop, val, roomIndex: i, index: 0})
            }
        }

        changeSameContactData(value) {
            EventBus.$emit(SAME_CONTACT_DATA_FOR_ALL, value)
            this.setCameContactDataForAll('email', hotelsStore.tourists[0][0].email)
            this.setCameContactDataForAll('phone', hotelsStore.tourists[0][0].phone)
        }

        setSameContactData(value) {
            this.sameContactDataForAll = value
        }

        setExtraMealType(value) {
            const type = `mealType-tourist-${this.roomIndex}-${this.index}`
            if (!value) {
                hotelsStore.DELETE_EXTRA_SERVICE(type)
            } else {
                hotelsStore.SET_BOOKING_EXTRA_SERVICE({
                    type,
                    roomIndex: this.roomIndex,
                    name: value.code,
                    price: value.prices[0].price,
                    quantity: 1,
                    isMealType: true,
                })
            }
        }

        get tourist() {
            return hotelsStore.tourists[this.roomIndex][this.index]
        }

        get roomLength() {
            return hotelsStore.tourists.length
        }

        get selectedTourists() {
            return hotelsStore.tourists.reduce(
                (selectedTourists, roomTourists) => [...selectedTourists, ...roomTourists],
                []
            )
        }

        get isTourLead() {
            return this.roomIndex === 0 && this.index === 0
        }

        get locale() {
            return this.$i18n.locale
        }

        get countries() {
            return runtimeStore.countries
        }

        get prefix() {
            return this.getProp('prefix')
        }

        set prefix(val) {
            this.setProp('prefix', val)
        }

        get firstName() {
            return this.getProp('firstName')
        }

        set firstName(val) {
            this.setProp('firstName', val)
        }

        get lastName() {
            return this.getProp('lastName')
        }

        set lastName(val) {
            this.setProp('lastName', val)
        }

        get citizenshipId() {
            return this.getProp('citizenshipId')
        }

        set citizenshipId(val) {
            this.setProp('citizenshipId', parseInt(val))
        }

        get phone() {
            return this.getProp('phone')
        }

        set phone(val) {
            this.setProp('phone', val)
        }

        get email() {
            return this.getProp('email')
        }

        set email(val) {
            this.setProp('email', val)
        }

        get alternativeMealTypeId() {
            return this.getProp('alternativeMealTypeId')
        }

        set alternativeMealTypeId(value) {
            this.setProp('alternativeMealTypeId', value)
        }

        get birthdate() {
            return this.getProp('birthdate')
        }

        set birthdate(val) {
            this.setProp('birthdate', val)
        }

        get age() {
            return this.getProp('age')
        }

        get isChild() {
            return this.getProp('type') === 'child'
        }

        get isDisabled() {
            return authStore.user.isEmployee || this.disabled
        }

        get personNameOnLatin() {
            return runtimeStore.config.personNameOnLatin
        }

        get showTouristSelector() {
            return authStore.person && ['corp.trip_coordinator', 'corp.director'].includes(authStore.person.role)
        }

        get personId() {
            return this.getProp('personId')
        }

        get lockEditPrivateClientName() {
            return runtimeStore.config.lockEditPrivateClientName && this.index === 0 && this.roomIndex === 0
        }

        get showSameContactDataCheckbox() {
            return (
                this.isTourLead &&
                this.roomLength > 1 &&
                (!this.isFieldDisabled('PHONE') || !this.isFieldDisabled('EMAIL'))
            )
        }
    }
</script>
