<template>
    <v-dialog v-model="modal" hide-overlay width="100%" max-width="1000">
        <v-card v-if="!loaded" color="primary" dark>
            <v-card-text>
                {{ $t('stand_by') }}
                <v-progress-linear indeterminate color="white" class="mb-0" />
            </v-card-text>
        </v-card>
        <v-card v-if="loaded" justify-center align-center>
            <div class="mb-4">
                <v-icon class="v-card-close" @click="modal = false">
                    mdi-close
                </v-icon>
                <v-card-title class="justify-center">
                    {{ $t('invoices.title') }} {{ requestInfo.orderId }}
                </v-card-title>
            </div>
            <v-row no-gutters class="mx-4">
                <v-col v-if="isTourOperator" cols="12" sm="3">
                    <v-select
                        v-model="invoiceType"
                        :items="invoiceTypes"
                        label="Invoice type"
                        solo
                        @change="getInvoices"
                    />
                </v-col>
                <v-col cols="12" sm="3" offset-sm="6" class="d-flex justify-end">
                    <v-btn color="primary" text @click="toggleActive">
                        <v-icon left> mdi-archive-arrow-{{ active ? 'down' : 'up' }} </v-icon>
                        {{ $t(active ? 'archive' : 'standard_mode') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-data-table
                v-if="invoicesInfo.invoices && invoicesInfo.invoices.length"
                :headers="headers"
                :items="invoicesInfo.invoices"
                :items-per-page="5"
                class="elevation-1 invoices-table"
            >
                <template v-slot:item.invoiceName="{item}">
                    <div class="invoice-name-block">
                        <a @click="printInvoice(item.invoiceId, item.invoiceName)">
                            {{ item.invoiceName }}
                            <v-icon color="blue darken-1">
                                mdi-file-pdf
                            </v-icon>
                        </a>
                    </div>
                </template>
                <template v-slot:item.paymentStatus="{item}">
                    <div>
                        {{ $t(`paymentStatus.${item.paymentStatus}`) }}
                    </div>
                </template>
                <template v-slot:item.amount="{item}">
                    <div>
                        {{ item.amount | priceFormat(item.amountCurrency) }}
                    </div>
                </template>
                <template v-slot:item.amountToPay="{item}">
                    <div>
                        {{ item.amountToPay | priceFormat(item.amountToPayCurrency) }}
                    </div>
                </template>
                <template v-slot:item.billDate="{item}">
                    <div>
                        {{ item.billDate | dateFormat }}
                    </div>
                </template>
                <template v-slot:item.dueDate="{item}">
                    <div>
                        {{ item.dueDate | dateFormat }}
                    </div>
                </template>
                <template v-slot:item.payDate="{item}">
                    <div>
                        {{ item.payDate | dateFormat }}
                    </div>
                </template>
            </v-data-table>

            <v-card-text v-else>
                <div class="pt-5">
                    {{ $t('invoices.empty_message') }}
                </div>
            </v-card-text>
            <v-card-actions v-if="canIssueInvoice" class="justify-end pa-6">
                <v-btn class="mb-2 white--text" color="primary" @click="issueInvoices(invoiceType)">
                    {{ $t('invoices.get_invoice_button') }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <invoices-issue-modal @issuedInvoice="$emit('issuedInvoice', $event)" />
    </v-dialog>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'
    import {EventBus, SHOW_INVOICES_MODAL_EVENT, ISSUE_INVOICES_MODAL_EVENT} from '@/utils/event-bus'
    import {invoices} from '~/api'
    import {authStore} from '@/utils/store-accessor'
    import {downloadData} from '@/utils/helpers'
    import InvoicesIssueModal from '@/components/modals/InvoicesIssueModal'

    @Component({
        components: {InvoicesIssueModal},
    })
    export default class InvoicesModal extends Vue {
        invoicesInfo = {}
        modal = false
        loaded = false
        requestInfo = {}
        invoiceType = null
        active = true

        created() {
            EventBus.$on(SHOW_INVOICES_MODAL_EVENT, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_INVOICES_MODAL_EVENT, this.showModal)
        }

        async showModal(rq) {
            this.loaded = false
            this.modal = true
            this.requestInfo = rq
            this.invoiceType = rq.invoiceType
            await this.getInvoices()
        }

        async getInvoices() {
            this.loaded = false
            try {
                this.invoicesInfo = await invoices.get({
                    orderId: this.requestInfo.objectId,
                    invoiceType: this.invoiceType,
                    active: this.active,
                })
            } catch (error) {
                this.snackbar.message = error.response.data.errors[0].message
                this.snackbar.show = true
            } finally {
                this.loaded = true
            }
        }

        toggleActive() {
            this.active = !this.active
            this.getInvoices()
        }

        get headers() {
            return [
                {text: this.$t('invoices.name'), align: 'left', value: 'invoiceName'},
                {text: this.$t('invoices.status'), align: 'left', value: 'paymentStatus'},
                {text: this.$t('invoices.amount'), align: 'left', value: 'amount'},
                {text: this.$t('invoices.amount_to_pay'), align: 'left', value: 'amountToPay'},
                {text: this.$t('invoices.bill_date'), align: 'left', value: 'billDate'},
                {text: this.$t('invoices.due_date'), align: 'left', value: 'dueDate'},
                {text: this.$t('invoices.pay_date'), align: 'left', value: 'payDate'},
            ]
        }

        get invoiceTypes() {
            return [
                {text: this.$t('invoices.supplier'), value: 'SUPPLIER'},
                {text: this.$t('invoices.client'), value: 'CUSTOMER'},
            ]
        }

        async printInvoice(invoiceId, invoiceName) {
            try {
                const data = await this.$api.printInvoice.get({invoiceId})
                downloadData(data, `${invoiceName}.pdf`)
            } catch (error) {
                this.snackbar.message = error.response.data.errors[0].message
                this.snackbar.show = true
            }
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isTourOperator() {
            return authStore.isTourOperator
        }

        issueInvoices(invoiceType) {
            EventBus.$emit(ISSUE_INVOICES_MODAL_EVENT, {
                orderId: this.requestInfo.orderId,
                objectId: this.requestInfo.objectId,
                invoiceType: invoiceType,
                order: this.requestInfo.order,
            })
            this.modal = false
        }

        get canIssueInvoice() {
            const failed = ['NO_BILL', 'NO_FULLY_BILL']

            if (this.invoiceType == 'CUSTOMER') {
                return failed.includes(this.requestInfo.order.customerPaymentStatus)
            }

            if (this.invoiceType == 'SUPPLIER') {
                return failed.includes(this.requestInfo.order.supplierPaymentStatus)
            }

            return false
        }
    }
</script>

<style scoped>
    .invoice-name-block {
        max-width: 150px;
    }
</style>
