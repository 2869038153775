<template>
    <v-card outlined class="mb-5">
        <v-list>
            <v-list-item>
                <v-img
                    tile
                    class="thumb float-left me-5"
                    max-width="150"
                    :src="mainImage.url | imageUrl(250)"
                    lazy-src="/placeholder.png"
                    @error="onImgError"
                >
                    <template v-slot:placeholder>
                        <v-row
                            v-show="!imgError && mainImage.url"
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
                <v-list-item-content class="align-self-start">
                    <v-list-item-title :title="product.info.name">
                        {{ product.info.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="mb-4">
                        {{ offer.info.description }}
                    </v-list-item-subtitle>
                    <conditions-label
                        :offer-key="offer.offerKey"
                        :price="offer.price"
                        @conditions="showConditionsInfo"
                    />
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-divider />
        <original-currency-notification :price="totalPrice" class="mt-5" />
        <v-list-item class="text-right">
            <v-list-item-title class="d-flex flex-column justify-end">
                <h2 class="py-2">{{ $t('total') }}: {{ totalPrice | price }}</h2>
            </v-list-item-title>
        </v-list-item>
        <conditions-modal>
            <template v-slot:info>
                <transfer-conditions-modal-info />
            </template>
        </conditions-modal>
    </v-card>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {transfersRuntimeStore, transfersStore} from '~/store'
    import ConditionsModal from '~/components/modals/ConditionsModal'
    import ConditionsLabel from '~/components/snippets/ConditionsLabel'
    import {EventBus, SHOW_TRANSFER_CONDITIONS_INFO} from '@/utils/event-bus'
    import TransferConditionsModalInfo from '@/components/transfers/snippets/TransferConditionsModalInfo'
    import OriginalCurrencyNotification from '@/components/booking/snippets/OriginalCurrencyNotification'

    @Component({
        components: {
            OriginalCurrencyNotification,
            TransferConditionsModalInfo,
            ConditionsModal,
            ConditionsLabel,
        },
    })
    export default class TransferOrderSummary extends Vue {
        @Prop({required: true}) offer
        @Prop({required: true}) info
        @Prop({required: true}) product
        @Prop({required: true}) searchRequest
        @Prop({default: true}) prebooked

        imgError = false

        onImgError() {
            this.imgError = true
            return false
        }

        showConditionsInfo() {
            EventBus.$emit(SHOW_TRANSFER_CONDITIONS_INFO, this.searchRequest, this.offer)
        }

        get image() {
            if (!this.offer.images) return null
            let main = this.offer.images.find(image => image.mainImage)
            if (!main && this.offer.images.length > 0) {
                main = this.offer.images[0]
            }
            return main && main.url ? main.url : null
        }

        get totalPrice() {
            return transfersStore.orderTotalPrice(this.offer, this.prebooked)
        }

        get mainImage() {
            return transfersRuntimeStore.offerMainImage(this.offer, this.info)
        }
    }
</script>
