<template>
    <v-dialog v-model="modal" width="800px" max-width="100%">
        <v-card v-if="info" width="800px" max-width="100%" class="pa-md-3">
            <v-card-text class="black--text">
                <h2 class="pt-5 pt-md-2">
                    {{ info.name }}
                </h2>
                <v-subheader v-if="offer.info.description">
                    {{ offer.info.description }}
                </v-subheader>
                <v-tabs hide-slider>
                    <v-tab>
                        {{ $t('description') }}
                    </v-tab>
                    <v-tab>
                        {{ $t('arrival_departure') }}
                    </v-tab>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-carousel
                                    height="170px"
                                    hide-delimiters
                                    show-arrows-on-hover
                                    :show-arrows="images.length > 1"
                                >
                                    <v-carousel-item v-for="(image, imageIndex) in images" :key="imageIndex">
                                        <v-img :src="image.url | imageUrl(300)" contain height="170px" />
                                    </v-carousel-item>
                                </v-carousel>
                            </v-col>
                            <v-col cols="12" md="8">
                                <template v-if="showShortDescription(info)">
                                    <strong> {{ $t('description_type.short') }} </strong><br />
                                    <div v-html="info.shortDescription" />
                                </template>
                                <template v-if="info.fullDescription">
                                    <strong> {{ $t('description_type.general') }} </strong><br />
                                    <div v-html="info.fullDescription" />
                                </template>
                                <template v-if="info.descriptions && info.descriptions.length">
                                    <strong>{{ $t('description') }} </strong><br />
                                    <div
                                        v-for="(desc, descIndex) in info.descriptions"
                                        :key="descIndex"
                                        v-html="desc"
                                    />
                                </template>
                                <div v-if="info.comments && info.comments.length">
                                    <strong> {{ $t('comments') }} </strong><br />
                                    <div v-for="(comment, commentIndex) in info.comments" :key="commentIndex">
                                        {{ comment }}
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <div>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <strong>{{ $t('transfers.departure_location') }} </strong><br />
                                    <div class="mb-1">
                                        <v-icon left>
                                            {{ `mdi-${departurePoint.type}` }}
                                        </v-icon>
                                        {{ departurePoint.name }}, {{ departurePoint.parentName }}
                                    </div>
                                    <v-autocomplete
                                        v-if="info.allowedHotelsDeparture"
                                        outlined
                                        :label="$t('transfers.possible_hotels')"
                                        hide-details
                                        dense
                                        single-line
                                        :items="info.allowedHotelsDeparture"
                                        item-text="hotelName"
                                        :item-value="item => item"
                                        @change="departurePoint = $event"
                                    />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <strong>{{ $t('transfers.arrival_location') }} </strong><br />
                                    <div class="mb-1">
                                        <v-icon left>
                                            {{ `mdi-${arrivalPoint.type}` }}
                                        </v-icon>
                                        {{ arrivalPoint.name }}, {{ arrivalPoint.parentName }}
                                    </div>
                                    <v-autocomplete
                                        v-if="info.allowedHotelsArrival"
                                        outlined
                                        :label="$t('transfers.possible_hotels')"
                                        hide-details
                                        dense
                                        single-line
                                        :items="info.allowedHotelsArrival"
                                        item-text="hotelName"
                                        :item-value="item => item"
                                        @change="arrivalPoint = $event"
                                    />
                                </v-col>
                            </v-row>
                        </div>
                        <div style="position: relative">
                            <client-only>
                                <transfer-map
                                    style="max-height: 300px; position: unset;"
                                    :points="mapPoints"
                                    :parent="true"
                                />
                            </client-only>
                        </div>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import TransferMap from '@/components/transfers/search/TransferMap'
    import {transfersRuntimeStore} from '@/store'
    import {EventBus, SHOW_TRANSFER_INFO_MODAL} from '@/utils/event-bus'

    //TODO Need refactoring with other products
    @Component({
        components: {
            TransferMap,
        },
    })
    export default class TransferInfoModal extends Vue {
        info = null
        offer = null
        conditionsInfo = {}
        modal = false

        created() {
            EventBus.$on(SHOW_TRANSFER_INFO_MODAL, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_TRANSFER_INFO_MODAL, this.showModal)
        }

        showModal({offer, info}) {
            this.offer = offer
            this.info = info
            this.modal = true
        }

        showShortDescription(info) {
            return (
                info.shortDescription &&
                (!info.fullDescription || info.fullDescription.indexOf(info.shortDescription) === -1)
            )
        }

        get images() {
            if (!this.info.images) return []
            if (this.info.images.length && this.info.images[0].linkedEntityName) {
                const images = this.info.images.filter(image => image.linkedEntityName === this.offer.info.description)
                if (images.length) {
                    return images
                }
            }
            return this.info.images
        }

        get mapPoints() {
            return [transfersRuntimeStore.departurePoint, transfersRuntimeStore.arrivalPoint]
        }

        get arrivalPoint() {
            return transfersRuntimeStore.arrivalPoint
        }

        //TODO ???
        set arrivalPoint(point) {}

        get departurePoint() {
            return transfersRuntimeStore.departurePoint
        }

        //TODO ???
        set departurePoint(point) {}
    }
</script>

<style scoped>
    >>> .v-slide-group__prev.v-slide-group__prev--disabled {
        display: none !important;
    }
</style>
