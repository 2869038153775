<template>
    <div>
        <v-dialog v-model="error" max-width="400">
            <v-card class="pt-4 pb-2">
                <v-card-text>
                    <p class="text-center">
                        {{ $t('file_download_error') }}
                    </p>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn class="primary" small @click.stop="download">
                        {{ $t('try_again') }}
                    </v-btn>
                    <v-btn class="ml-auto error" small @click.stop="error = !error">
                        {{ $t('close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <div class="d-flex flex-nowrap align-center">
            {{ $t('download') }}:
            <v-tooltip v-for="format in formats" :key="format.format" bottom>
                <template v-slot:activator="{on}">
                    <v-icon right color="white" class="ml-3" @click="downloadResults(format.format)" v-on="on">
                        {{ format.icon }}
                    </v-icon>
                </template>
                <span>{{ format.format }}</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator'
    import {hotelsStore} from '~/store'
    import {downloadData} from '~/utils/helpers'

    @Component
    export default class DownloadSearchResults extends Vue {
        @Prop({required: true}) product

        downloadFormat = 'PDF'
        error = false
        formats = [
            {
                format: 'PDF',
                icon: 'mdi-file-pdf',
                extension: 'pdf',
            },
            {
                format: 'Excel',
                icon: 'mdi-file-excel',
                extension: 'xls',
            },
            {
                format: 'Word',
                icon: 'mdi-file-word',
                extension: 'doc',
            },
        ]

        downloadResults(format) {
            this.downloadFormat = format
            this.download()
        }

        async download() {
            const offerKeys = await this.getOfferKeys(),
                fileType = this.downloadFormat
            try {
                const format = this.formats.find(el => el.format === this.downloadFormat)
                const data = await this.$api.searchAccommodationResults.get({offerKeys, fileType})
                downloadData(data, `Search Results.${format.extension}`)
                this.error = false
            } catch (e) {
                this.error = true
            }
        }

        async getOfferKeys() {
            let keys
            switch (this.product) {
                case 'accommodation':
                    keys = await this.getAccommodationOfferKeys()
                    break

                default:
                    keys = []
            }
            return keys
        }

        async getAccommodationOfferKeys() {
            let result = []
            const offers = await this.$localForage.getItem('hotels')
            hotelsStore.offersToCompare.forEach(offer => {
                if (!offer.rooms.length) {
                    const searchOffer = offers.find(el => {
                        return el.hotelCode === offer.hotelCode
                    })
                    const offerKeys = searchOffer.rooms.map(room => {
                        return room.groupedOffers[0].offerKey
                    })
                    result = [...result, ...Array.from(new Set(offerKeys))]
                } else {
                    result = [...result, ...offer.rooms]
                }
            })
            return result
        }
    }
</script>
