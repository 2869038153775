<template>
    <div class="mx-auto">
        <v-progress-circular
            :color="!error ? 'primary' : 'error'"
            :indeterminate="!error"
            size="180"
            width="10"
            class="text-center"
        >
            <strong v-if="!error" class="px-5">
                {{ paymentComplete ? $t('booking') : $t('payment_confirmation') }}...
            </strong>
            <strong v-else class="px-5">{{ $t('error') }}</strong>
        </v-progress-circular>
    </div>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import {authStore, persistentStore} from '@/store'
    import LoadMixin from '@/mixins/LoadMixin'
    import ConfirmationRedirectMixin from '~src/mixins/confirmationRedirectMixin.src'

    @Component({
        layout: 'centered',
    })
    export default class PaymentCompletePage extends mixins(LoadMixin, ConfirmationRedirectMixin) {
        error = false
        paymentComplete = false

        async load() {
            const {
                paymentMethodId,
                bookingKey,
                paymentNumber,
                product,
                corporatePolicyViolationCodeId,
            } = this.$route.query
            let pspResponse = this.$route.query
            delete pspResponse.status
            delete pspResponse.paymentMethodId
            delete pspResponse.bookingKey
            delete pspResponse.product
            delete pspResponse.corporatePolicyViolationCodeId
            pspResponse = new URLSearchParams(pspResponse).toString()
            try {
                const paymentCompleteRs = await persistentStore.paymentComplete({
                    paymentMethodId: parseInt(paymentMethodId, 10),
                    bookingKey,
                    paymentNumber,
                    pspResponse,
                })
                if (paymentCompleteRs.result === 'Success') {
                    this.paymentComplete = true
                    try {
                        const bookRs = await persistentStore.book({
                            bookingKey,
                            paymentMethodId,
                            corporatePolicyViolationCodeId,
                        })
                        if (!persistentStore.selectedOrderId && !authStore.person) {
                            await this.$router.push({
                                name: `${product}Confirmation`,
                                query: {orderId: bookRs[0].orderId},
                            })
                        } else {
                            persistentStore.SET_SELECTED_ORDER_ID(null)
                            this.orderDetailsRedirect(bookRs[0].orderId)
                        }
                    } catch (e) {
                        this.$toast.error('Something wrong on booking step')
                        this.error = true
                    }
                } else {
                    this.$toast.error('Something wrong on payment complete step')
                    console.error(paymentCompleteRs)
                    this.error = true
                }
            } catch (e) {
                this.$toast.error('Something wrong on payment complete step')
                this.error = true
            }
        }
    }
</script>
