<template>
    <div class="text-center">
        <v-btn
            v-if="searchRoomsCount === 1"
            color="primary"
            small
            block
            max-height="36px"
            class="body-2"
            @click="selectForBooking"
        >
            {{ room.price | price }}
            <corporate-policy-violation
                v-if="room.corporatePolicyViolation && !$breakpoint.smAndDown"
                :policies="room.corporatePolicy"
            />
        </v-btn>
        <div v-else>
            {{ room.price | price }}
            <corporate-policy-violation
                v-if="room.corporatePolicyViolation && !$breakpoint.smAndDown"
                :policies="room.corporatePolicy"
            />
        </div>
        <hotel-price-note :loyalty-points="room.loyaltyPoints" :search-request="searchRequest" :rph="rph" />
        <discount-label :price="room.price" class="d-inline-block mt-1" />
        <corporate-policy-violation
            v-if="room.corporatePolicyViolation && $breakpoint.smAndDown"
            :policies="room.corporatePolicy"
        />
        <commission-amount :commission="room.price.commission" />
        <rate-note :room="room" />
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator'
    import {hotelsStore} from '@/store'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import CommissionAmount from '~/components/snippets/CommissionAmount'
    import CorporatePolicyViolation from '~/components/snippets/CorporatePolicyViolation'
    import HotelPriceNote from '@/components/hotels/snippets/HotelPriceNote'
    import RateNote from '~src/components/snippets/RateNote'

    @Component({
        components: {
            HotelPriceNote,
            DiscountLabel,
            CommissionAmount,
            CorporatePolicyViolation,
            RateNote,
        },
    })
    export default class HotelRoomOfferPriceBlock extends Vue {
        @Prop({default: () => ({})}) room
        @Prop({required: true}) searchRequest
        @Prop({default: () => ({})}) offer
        @Prop({default: 0}) rph

        selectForBooking() {
            const offerKey = [this.room.groupedOffers[0].offerKey]
            hotelsStore.ADD_TO_BASKET(offerKey)
            this.$router.push({
                name: 'hotelBooking',
                query: {offerKey},
            })
        }

        get searchRoomsCount() {
            return this.searchRequest.rooms.length
        }
    }
</script>
