<script>
    import {Component, Vue} from 'vue-property-decorator'

    @Component
    export default class AirportsSearchMixin extends Vue {
        async searchAirports(rq) {
            const defaults = {
                pattern: null,
                limit: 10,
                orderBy: 'POPULARITY',
            }

            //TODO Fix limit in API
            const rs = await this.$api.airports.get({...defaults, ...rq})
            return rs.map(airport => {
                return Object.assign(airport, {parentName: airport.countryName, type: 'airport'})
            })
        }
    }
</script>
