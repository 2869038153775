<template>
    <v-row no-gutters>
        <v-col v-if="showImage" :cols="12" md="2" class="mr-0 pr-md-5">
            <v-img
                tile
                class="thumb"
                :src="serviceImage | imageUrl(350)"
                lazy-src="/placeholder.png"
                min-height="100"
                :eager="true"
                :max-height="$breakpoint.smAndDown ? 350 : undefined"
                @error="onImgError"
            >
                <template v-slot:placeholder>
                    <v-row v-show="!imgError && serviceImage" class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
        </v-col>
        <v-col
            v-if="serviceCheckIn || serviceCheckOut"
            :cols="12"
            :md="isFlight ? (showImage ? 4 : 6) : showImage ? 5 : 7"
        >
            <strong class="info-text title black--text font-weight-bold d-flex align-center pt-1">
                {{ service.serviceName }}
                <hotel-stars
                    v-if="service.serviceDetails.length && service.serviceDetails[0].category"
                    class="ms-2"
                    :category="service.serviceDetails[0].category"
                />
            </strong>
            <div class="mb-3">
                <slot name="additionalInfo" />
            </div>
            <div v-if="serviceCheckIn" class="d-flex">
                <v-icon left class="date-icon" small>
                    mdi-clock-in
                </v-icon>
                <span class="info-text" v-html="serviceCheckIn" />
            </div>
            <div v-if="serviceCheckOut" class="d-flex">
                <v-icon left class="date-icon" small>
                    mdi-clock-out
                </v-icon>
                <span class="info-text" v-html="serviceCheckOut" />
            </div>
            <slot name="extraTime" />
            <slot />
        </v-col>
        <template v-if="!notItineraryStart">
            <slot name="price">
                <v-col v-if="!isPackage" cols="12" md="4" class="ml-auto mb-2">
                    <order-prices
                        :service-card-guarantee="isPayAtHotel"
                        :supplier-cancel-penalties="supplierPrice.cancelPenalty"
                        :supplier-payment-status="service.supplierPaymentStatus"
                        :loyalty-points="!isGuest ? service.loyaltyPoints : null"
                        :supplier-price="supplierPrice.price"
                        :client-cancel-penalties="clientPrice.cancelPenalty"
                        :customer-payment-status="service.customerPaymentStatus"
                        :client-price="clientPrice.price"
                        :alignment="isSupplier && !isConfirmationPendingStatus ? 'justify-end' : 'justify-space-around'"
                    >
                        <template v-if="!isSupplier" v-slot:violation>
                            <div v-if="service.violation">
                                <corporate-policy-violation :policies="service.corporatePolicies" />
                            </div>
                        </template>
                    </order-prices>
                </v-col>
            </slot>
            <v-col
                v-if="!isPackage && !isSupplier && !isGuest"
                :md="isFlight ? 2 : 1"
                class="ml-auto flex-column text-center"
            >
                <v-btn
                    v-if="canPayService && !isPayAtHotel"
                    depressed
                    color="primary"
                    :block="$breakpoint.smAndDown"
                    :small="$breakpoint.smAndDown"
                    class="mb-4 white--text order-info-btn"
                    @click="payService"
                >
                    {{ $t('pay_service') }}
                </v-btn>
                <v-btn
                    v-if="canDownloadVoucher && !isFlight"
                    depressed
                    color="grey"
                    :block="$breakpoint.smAndDown"
                    :small="$breakpoint.smAndDown"
                    class="mb-4 white--text order-info-btn"
                    @click="downloadVoucher"
                >
                    {{ $t('voucher') }}
                </v-btn>
                <v-btn
                    v-if="isFlight && canDownloadVoucher"
                    depressed
                    color="grey"
                    :block="$breakpoint.smAndDown"
                    :small="$breakpoint.smAndDown"
                    class="mb-4 white--text order-info-btn"
                    :loading="loading"
                    :disabled="loading"
                    @click="downloadEticket"
                >
                    {{ canDownloadEticket ? $t('flights.e_ticket') : $t('flights.issue_ticket') }}
                </v-btn>
                <v-btn
                    v-if="isFlight && canRetrieve && !isCharter"
                    :block="$breakpoint.smAndDown"
                    :small="$breakpoint.smAndDown"
                    class="mb-4 order-info-btn"
                    plain
                    :loading="checkStatusActive"
                    :disabled="checkStatusActive"
                    @click="checkStatus"
                >
                    {{ $t('check_status') }}
                </v-btn>
                <a
                    v-if="service.serviceType === 'ACCOMMODATION' && service.modificationAllowed"
                    class="black--text modify-link mb-3"
                    :block="$breakpoint.smAndDown"
                    :small="$breakpoint.smAndDown"
                    @click="modifyService"
                >
                    {{ $t('edit') }}
                </a>
                <a
                    v-if="
                        service.cancellationAllowed || (packageFlightStatus && packageFlightStatus === 'PRECONFIRMED')
                    "
                    class="red--text cancel-link"
                    :block="$breakpoint.smAndDown"
                    :small="$breakpoint.smAndDown"
                    @click="$emit('cancelService', service)"
                >
                    {{ $t('cancel') }}
                </a>
            </v-col>
            <v-col v-if="isSupplier && isConfirmationPendingStatus" md="1" class="ml-auto flex-column text-center">
                <v-btn
                    depressed
                    color="primary"
                    :block="$breakpoint.smAndDown"
                    :small="$breakpoint.smAndDown"
                    class="mb-4 white--text order-info-btn"
                    :class="locale === 'ru' ? 'supplier-action-btn' : ''"
                    @click="supplierAction('CONFIRM')"
                >
                    {{ $t('supplier.confirm') }}
                </v-btn>
                <v-btn
                    depressed
                    color="red"
                    :block="$breakpoint.smAndDown"
                    :small="$breakpoint.smAndDown"
                    class="mb-4 white--text order-info-btn"
                    :class="locale === 'ru' ? 'supplier-action-btn' : ''"
                    @click="supplierAction('REJECT')"
                >
                    {{ $t('supplier.reject') }}
                </v-btn>
            </v-col>
            <v-col v-if="service.extraServices.length" cols="12" md="12" class="mt-2">
                <div class="text-subtitle-1">
                    {{ $t('extra_services') }}
                </div>
                <div v-for="extraService in service.extraServices" :key="extraService.id">
                    <span>
                        {{ extraService.name }}
                        <span v-if="extraService.quantity"> - {{ getServiceDescription(extraService) }} </span>
                        <span v-if="extraService.prices">
                            ({{
                                extraServiceClientPrice(extraService.prices).amount
                                    | priceFormat(extraServiceClientPrice(extraService.prices).currency)
                            }})
                        </span>
                        <span
                            v-if="'EARLY_CHECKIN' === extraService.type"
                            :class="`${statusColor(service.serviceDetails[0].earlyCheckIn.status)}--text`"
                        >
                            {{ $t(`bookingStatus.${status(service.serviceDetails[0].earlyCheckIn.status)}`) }}
                        </span>
                        <span
                            v-if="'LATE_CHECKOUT' === extraService.type"
                            :class="`${statusColor(service.serviceDetails[0].lateCheckOut.status)}--text`"
                        >
                            {{ $t(`bookingStatus.${status(service.serviceDetails[0].lateCheckOut.status)}`) }}
                        </span>
                    </span>
                </div>
            </v-col>
            <v-col v-if="service.comments" cols="12" md="12">
                <div class="text-subtitle-1">
                    {{ $t('comments') }}
                </div>
                <div>
                    {{ service.comments }}
                </div>
            </v-col>
            <v-col cols="12" md="12" class="mt-3">
                <slot name="subtitle" />
                <slot name="travelers" :emptyAdditionalFields="touristEmptyAdditionalFields">
                    <travelers
                        :travelers="service.travelers"
                        :product="service.serviceType"
                        :details="service.serviceDetails"
                        :empty-additional-fields="touristEmptyAdditionalFields"
                    >
                        <template
                            v-if="service.serviceDetails.length && service.serviceDetails[0].rooms"
                            v-slot:mealType
                        >
                            <v-col cols="12" md="2" class="pb-0">
                                <small class="d-block traveler-label">{{ $t('filterTitles.mealTypes') }}</small>
                                <span class="text-uppercase black--text font-weight-bold">
                                    {{ service.serviceDetails[0].rooms[0].mealTypeName }}
                                </span>
                            </v-col>
                        </template>
                    </travelers>
                </slot>
            </v-col>
        </template>
    </v-row>
</template>

<script>
    import {Component, Prop, mixins} from 'nuxt-property-decorator'
    import {downloadData} from '@/utils/helpers'
    import OrderPrices from '@/components/account/snippets/OrderPrices'
    import {EventBus, SHOW_MODIFY_MODAL_EVENT, PAY_SERVICE_EVENT} from '@/utils/event-bus'
    import {authStore, runtimeStore} from '@/store'
    import FlightOrderInfoBaseMixin from '@/components/account/mixins/FlightOrderInfoBaseMixin'
    import Travelers from '~/components/account/orderServices/Travelers'
    import CorporatePolicyViolation from '~/components/snippets/CorporatePolicyViolation'
    import HotelStars from '@/components/hotels/snippets/HotelStars'

    @Component({
        components: {
            HotelStars,
            OrderPrices,
            CorporatePolicyViolation,
            Travelers,
        },
    })
    export default class OrderInfoBase extends mixins(FlightOrderInfoBaseMixin) {
        @Prop() service
        @Prop({default: false}) isPackage
        @Prop() serviceCheckIn
        @Prop() serviceCheckOut
        @Prop({default: null}) serviceImage
        @Prop({default: false}) info
        @Prop({default: null}) orderId
        @Prop({default: null}) objectId
        @Prop({default: null}) packageFlightStatus
        @Prop({default: false, type: Boolean}) notItineraryStart

        imgError = false
        loading = false

        get clientPrice() {
            return this.service.salesTerms.find(price => {
                return price.type === 'CLIENT'
            })
        }

        get supplierPrice() {
            return this.service.salesTerms.find(price => {
                return price.type === 'SUPPLIER'
            })
        }

        async downloadVoucher() {
            try {
                const data = await this.$api.voucher.get({
                    processId: this.service.processId.replace(/^.{6}/, ''),
                })
                downloadData(data, 'Voucher.pdf')
            } catch (error) {
                this.$toast.error(error.errors[0].message)
            }
        }

        get canDownloadVoucher() {
            const failed = ['Error', 'No show', 'Rejected', 'Canceled', 'Cancellation pending', 'Confirmation pending']
            return !failed.includes(this.service.status)
        }

        get canDownloadEticket() {
            return ['Confirmed', 'Issued'].includes(this.service.status)
        }

        async downloadEticket() {
            if (this.canDownloadEticket) {
                this.loading = true
                try {
                    const data = await this.$api.voucher.get({
                        processId: this.service.processId.replace(/^.{6}/, ''),
                    })
                    downloadData(data, 'E-ticket.pdf')
                } catch (error) {
                    this.$toast.error(error.errors[0].message)
                }
                this.loading = false
            } else {
                this.$emit('issueTicket', this.service)
            }
        }

        getServiceDescription(extraService) {
            if (extraService.type === 'EARLY_CHECKIN') {
                return this.service.serviceDetailsContainer.accommodation.earlyCheckIn.time
            }
            if (extraService.type === 'LATE_CHECKOUT') {
                return this.service.serviceDetailsContainer.accommodation.lateCheckOut.time
            }
            return extraService.quantity
        }

        onImgError() {
            this.imgError = true
            return false
        }

        modifyService() {
            EventBus.$emit(SHOW_MODIFY_MODAL_EVENT, this.service)
        }

        get canPayService() {
            return (
                authStore.isAgency &&
                this.service.status === 'Confirmed' &&
                !['PAID', 'PARTLY_PAID'].includes(this.service.supplierPaymentStatus)
            )
        }

        payService() {
            EventBus.$emit(PAY_SERVICE_EVENT, {orderId: this.orderId, objectId: this.objectId})
        }

        extraServiceClientPrice(prices) {
            return prices.find(({priceType}) => priceType === 'CLIENT')
        }

        statusColor(status) {
            return runtimeStore.orderServiceStatusColor(status)
        }

        status(status) {
            return runtimeStore.orderServiceStatus(status)
        }

        get isSupplier() {
            return authStore.companyType === 'INTERNAL_SUPPLIER'
        }

        get isGuest() {
            return authStore.isGuest
        }

        get isConfirmationPendingStatus() {
            return this.service.status === 'Confirmation pending'
        }

        async supplierAction(action) {
            try {
                await this.$api.modifyStatus.put({
                    processId: this.service.processId.replace(/^.{6}/, ''),
                    status: action,
                })
                this.$emit('modifyService')
            } catch (error) {
                this.$toast.error(this.$t('error_message.something_went_wrong'))
            }
        }

        get locale() {
            return this.$i18n.locale
        }

        get isPayAtHotel() {
            return this.service?.serviceDetailsContainer.accommodation
                ? this.service.serviceDetailsContainer.accommodation.cardGuarantee
                : false
        }

        get touristEmptyAdditionalFields() {
            return false
        }

        get showImage() {
            return true
        }
    }
</script>

<style scoped lang="scss">
    .date-icon {
        display: block;
        float: left;
    }

    .traveler-label,
    .title {
        line-height: 1;
    }

    .cancel-link,
    .modify-link {
        font-size: 14px;
        font-weight: normal;
        display: block;
        text-align: center;
    }

    .order-info-btn {
        width: 100%;
        font-size: 0.75rem;
    }

    .supplier-action-btn {
        font-size: 0.6rem;
    }
</style>
