<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import {authStore, persistentStore, runtimeStore} from '@/utils/store-accessor'

    @Component
    export default class BookingFieldsMixin extends Vue {
        isFieldMandatory(fieldKey) {
            return (
                runtimeStore.isTouristBookingField(fieldKey, 'MANDATORY', this.isMainTourist, this.isChild) ||
                (fieldKey === 'EMAIL' && this.isTourLead && !authStore.person)
            )
        }

        isFieldDisabled(fieldKey) {
            return runtimeStore.isTouristBookingFieldDisabled(fieldKey, this.isMainTourist, this.isChild)
        }

        fieldLabel(token, fieldKey) {
            return `${this.$t(token)}${this.isFieldMandatory(fieldKey) ? '*' : ''}`
        }

        requiredRule(token, fieldKey) {
            return v =>
                !!v ||
                (fieldKey && !this.isFieldMandatory(fieldKey)) ||
                `${this.$t(token)} ${this.$t('validation.required')}`
        }

        get isMainTourist() {
            return this.index === 0
        }

        get isTourLead() {
            return this.index === 0
        }

        get isChild() {
            return persistentStore.tourists[this.index].type === 'child'
        }

        get minBirthDate() {
            return !this.isChild ? null : this.$dateFns.format(this.$dateFns.subYears(new Date(), this.age + 1))
        }

        get maxBirthDate() {
            return !this.isChild
                ? this.$dateFns.format(new Date())
                : this.$dateFns.format(this.$dateFns.subYears(new Date(), this.age))
        }
    }
</script>
