<template>
    <v-dialog v-model="modal" hide-overlay width="600">
        <v-card v-if="infoLoading" color="primary" dark>
            <v-card-text>
                {{ $t('loading') }}
                <v-progress-linear indeterminate color="white" class="mb-0" />
            </v-card-text>
        </v-card>
        <v-card v-else>
            <v-card-title class="justify-space-between">
                {{ mealTypeName }}
                <v-icon class="justify-end" @click="modal = false">
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-card-text v-if="mealType && (mealType.description || mealType.shortDescription)">
                <template
                    v-if="
                        mealType.shortDescription &&
                            (!mealType.description || mealType.description.indexOf(mealType.shortDescription) === -1)
                    "
                >
                    <h4>{{ $t('description_type.short') }}</h4>
                    <div v-html="mealType.shortDescription" />
                </template>
                <template v-if="mealType.description">
                    <h4>{{ $t('description_type.general') }}</h4>
                    <div v-html="mealType.description" />
                </template>
            </v-card-text>
            <v-card-text v-else>
                {{ $t('no_details') }}
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'
    import {hotelsRuntimeStore} from '@/store'
    import {isEqual} from '@/utils/helpers'
    import {EventBus, SHOW_MEAL_TYPE_INFO_EVENT} from '@/utils/event-bus'

    @Component
    export default class HotelMealTypeInfoModal extends Vue {
        infoLoading = false
        modal = false
        mealTypeName = null

        mounted() {
            EventBus.$on(SHOW_MEAL_TYPE_INFO_EVENT, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_MEAL_TYPE_INFO_EVENT, this.showModal)
        }

        async showModal(hotel, mealTypeName) {
            this.mealTypeName = mealTypeName
            this.modal = true
            this.infoLoading = true
            const {supplierCode, cityCode, hotelCode} = hotel,
                rq = {supplierCode, cityCode, hotelCode}
            if (!isEqual(rq, hotelsRuntimeStore.hotelInfoRequest)) {
                await hotelsRuntimeStore.loadHotelInfo(rq)
            }
            this.infoLoading = false
        }

        get mealType() {
            return hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo.mealTypes.mealType.find(
                mealType => mealType.name === this.mealTypeName
            )
        }
    }
</script>
