<template>
    <v-card outlined tile>
        <v-card-title class="title">
            {{ $t('transfers.trip_details') }}
        </v-card-title>
        <v-card-text class="white--text">
            <v-form ref="form" v-model="valid">
                <v-menu
                    v-model="showTimePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{on}">
                        <v-text-field
                            :value="time"
                            :label="`${$t('transfers.pickup_time')}*`"
                            readonly
                            background-color="white"
                            prepend-inner-icon="mdi-clock"
                            :rules="[v => !!v || `${$t('transfers.pickup_time')} ${$t('validation.required')}`]"
                            :disabled="disabled"
                            v-on="on"
                        />
                    </template>
                    <!--v-time-picker v-model="time" :locale="$i18n.locale" @change="showTimePicker = false" /-->
                    <time-picker v-model="time" @update="showTimePicker = false" />
                </v-menu>
                <v-text-field v-model="pickupSign" :label="$t('transfers.pickup_sign')" />
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Prop, PropSync, Vue} from 'vue-property-decorator'
    import {transfersStore} from '@/utils/store-accessor'
    import TimePicker from '@/components/snippets/forms/TimePicker'

    @Component({
        components: {TimePicker},
    })
    export default class TransferBookingForm extends Vue {
        @Prop() disabled
        @PropSync('_valid', {default: false}) valid

        showTimePicker = false

        get time() {
            return transfersStore.prepareBookRequest.time
        }

        set time(val) {
            transfersStore.SET_PREPARE_BOOK_REQUEST_PROP({prop: 'time', val})
        }

        get pickupSign() {
            return transfersStore.prepareBookRequest.pickupSign
        }

        set pickupSign(val) {
            transfersStore.SET_PREPARE_BOOK_REQUEST_PROP({prop: 'pickupSign', val})
        }

        //TODO Add additional fields
    }
</script>
