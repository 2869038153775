<script>
    import {Component} from 'vue-property-decorator'
    import PopularDestinations from '~/components/parts/PopularDestinations'

    @Component({})
    export default class PopularDestinationsMod extends PopularDestinations {
        get showCount() {
            return this.$breakpoint.smAndDown ? 1 : 2
        }
    }
</script>
