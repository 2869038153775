<template>
    <confirmation-page-layout :company="company">
        <template v-slot:breadcrumbs>
            <flight-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:sidebar>
            <flight-search-summary :search-request="searchRequest" search-disabled />
            <flight-order-summary :offer="offer" />
        </template>
    </confirmation-page-layout>
</template>

<script>
    import {mixins, Component} from 'nuxt-property-decorator'
    import ConfirmationStatus from '@/components/booking/ConfirmationStatus'
    import FlightPageMixin from '@/components/flights/mixins/FlightPageMixin'
    import ConfirmationPageMixin from '@/mixins/ConfirmationPageMixin'
    import FlightSearchSummary from '~/components/flights/search/FlightSearchSummary'
    import FlightOrderSummary from '~/components/flights/booking/FlightOrderSummary'
    import ConfirmationPageLayout from '@/components/parts/ConfirmationPageLayout'
    import FlightBreadcrumbs from '@/components/flights/FlightBreadcrumbs'
    import {flightsStore, persistentStore} from '@/store'

    //TODO Single confirmation page layout for all services with summary slot
    @Component({
        components: {
            FlightBreadcrumbs,
            ConfirmationPageLayout,
            ConfirmationStatus,
            FlightSearchSummary,
            FlightOrderSummary,
        },
    })
    export default class FlightConfirmationPage extends mixins(FlightPageMixin, ConfirmationPageMixin) {
        validate({query}) {
            return query.orderId
        }

        async mounted() {
            await this.$store.restored
            await this.loadRuntimeData()
            if (this.$route.query.orderId !== persistentStore.bookResponse[0].orderId) {
                //TODO Redirect to 404
            }
        }

        get basketItem() {
            return flightsStore.basketItem(flightsStore.prepareBookRequest.offerKey)
        }

        get offer() {
            return this.basketItem.offer
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }
    }
</script>
