<template>
    <div v-if="info">
        <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="search" />
        <v-tabs v-if="isMobile" ref="mobileTabs" v-model="mobileTabs" fixed-tabs class="sticky-bar">
            <v-tab>
                <v-icon>mdi-office-building</v-icon>
            </v-tab>
            <v-tab>
                <v-icon>mdi-bed</v-icon>
            </v-tab>
        </v-tabs>
        <v-container fluid>
            <package-breadcrumbs v-if="!isMobile" :search-request="searchRequest" />
            <v-row>
                <v-col v-show="!isMobile || (isMobile && mobileTabs === 0)" sm="8">
                    <hotel-info :info-prop="info" :offer-prop="selectedHotel" :search-request="searchRequest" />
                    <component
                        :is="!$breakpoint.smAndDown ? 'product-gallery' : 'product-gallery-mobile'"
                        :images-data="info.images.image"
                    />
                    <hotel-info-room-offers-wrapper
                        v-if="!isMobile && selectedHotel"
                        class="mb-5"
                        :offer-prop="selectedHotel"
                    >
                        <template v-slot:price="{room}">
                            <offer-delta-button :room="room" />
                        </template>
                    </hotel-info-room-offers-wrapper>
                    <hotel-info-descriptions
                        class="mb-5"
                        :descriptions-prop="info.descriptions ? info.descriptions.description : []"
                    />
                    <hotel-info-services :services-prop="info" />
                    <!-- <div v-if="isMobile">
                        <client-only>
                            <hotel-info-map />
                        </client-only>
                        <hotel-info-relative-positions />
                    </div> -->
                    <view-offers-button @showOffers="mobileTabs = 1" />
                </v-col>
                <v-col v-show="!isMobile || (isMobile && mobileTabs === 1)" sm="4">
                    <selected-offers
                        :direction="'column'"
                        :hotel="selectedHotel"
                        :room="selectedRoom"
                        :flight="selectedFlight"
                        :price="initialPrice"
                        :search-request="searchRequest"
                    />
                    <hotel-info-room-offers-wrapper
                        v-if="isMobile && selectedHotel"
                        class="mb-5"
                        :offer-prop="selectedHotel"
                    >
                        <template v-slot:price="{room}">
                            <offer-delta-button :room="room" />
                        </template>
                    </hotel-info-room-offers-wrapper>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import {packagesRuntimeStore, packagesStore} from '~/store'
    import {appInstance} from '@/utils/app-accessor'
    import HotelInfo from '~/components/info/HotelInfo'
    import HotelInfoRoomOffersWrapper from '@/components/info/offers/HotelInfoRoomOffersWrapper'
    import HotelInfoServices from '@/components/info/HotelInfoServices'
    import HotelInfoDescriptions from '@/components/info/HotelInfoDescriptions'
    import SelectedOffers from '~/components/packages/search/SelectedOffers'
    import PackageBreadcrumbs from '~/components/packages/PackageBreadcrumbs'
    import ViewOffersButton from '@/components/snippets/ViewOffersButton'
    import ProductGallery from '@/components/info/ProductGallery'
    import ProductGalleryMobile from '@/components/info/ProductGalleryMobile'
    import PackagePageMixin from '@/components/packages/mixins/PackagePageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import OfferDeltaButton from '~/components/packages/search/OfferDeltaButton'

    @Component({
        components: {
            ExpiredOffersSnackbar,
            ViewOffersButton,
            HotelInfo,
            HotelInfoRoomOffersWrapper,
            SelectedOffers,
            HotelInfoDescriptions,
            OfferDeltaButton,
            HotelInfoServices,
            PackageBreadcrumbs,
            ProductGallery,
            ProductGalleryMobile,
        },
    })
    export default class PackageDetailPage extends mixins(PackagePageMixin) {
        mobileTabs = 0
        info = null

        async mounted() {
            await this.$store.restored
            await this.loadRuntimeData()
            const {hotelCode, supplierCode, cityCode} = this.selectedHotel
            const {hotelDescriptionInfo} = await appInstance.$api.hotelInfo.get({hotelCode, supplierCode, cityCode})
            this.info = hotelDescriptionInfo
        }

        search() {
            this.$router.push(packagesRuntimeStore.searchPageLink(this.searchRequest))
        }

        isOffersExpired() {
            return packagesStore.isOffersExpired()
        }

        get selectedHotel() {
            return packagesStore.selectedHotel
        }

        get selectedRoom() {
            return packagesStore.selectedRoom
        }

        get selectedFlight() {
            return packagesStore.selectedFlight
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        get infoServices() {
            return this.info.services ? this.info.services.service.filter(service => service.active) : []
        }

        get searchRequest() {
            return packagesStore.searchRequest
        }

        get initialPrice() {
            return packagesStore.initialPrice
        }
    }
</script>
