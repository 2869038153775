<template>
    <search-layout v-if="loaded" :map-expanded="mapExpanded">
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="search" />
            <hotel-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:left-sidebar>
            <hotel-search-summary :search-request="searchRequest" />
            <sort-select v-model="sortKey" :items="sortItems" />
            <hotel-filters
                :filter-values="filterValues"
                :filters="searchResponse.filters"
                :filtered-offers-count="filteredOffers.length"
                :nights="nights"
                :currency="currency"
                @change="changeFilter"
                @reset="resetFilters"
            />
            <overlay-progress :active="filterActive && !searchActive" />
        </template>
        <template v-slot:main-content>
            <search-progress
                :filtered-offers-count="filteredOffers.length"
                :total-offers-count="searchResponse.offers.length"
                :search-active="searchActive"
                :hide-stop-btn="$route.name === 'flightsAvailability'"
                product-type="hotel"
                @stop="stopSearch"
            />
            <hotel-offers :page="page" />
            <pagination :page-prop.sync="page" :pages="pages" />
        </template>
        <template v-slot:right-sidebar>
            <client-only>
                <hotel-map :city="city" :_expanded.sync="mapExpanded" :offers="filteredOffers" />
            </client-only>
        </template>
    </search-layout>
</template>

<script>
    import {mixins, Component, Watch} from 'nuxt-property-decorator'
    import HotelOffers from '~/components/search/offers/HotelOffers'
    import HotelFilters from '~/components/search/filters/HotelFilters'
    import Pagination from '~/components/search/Pagination'
    //import HotelMap from '~src/components/search/offers/HotelMap'
    import HotelSearchSummary from '@/components/search/HotelSearchSummary'
    import {hotelsStore, hotelsRuntimeStore, persistentStore, runtimeStore} from '~/store'
    import {EventBus, FILTER_EVENT} from '~/utils/event-bus'
    import {searchRequest} from '@/utils/hotels/hotels-blank-states'
    import MobileSearchPageTabs from '@/components/snippets/MobileSearchPageTabs'
    import HotelStoreMixin from '@/mixins/HotelStoreMixin'
    import {isEqual} from '@/utils/helpers'
    import SearchLayout from '~src/components/parts/searchLayout.src'
    import HotelBreadcrumbs from '@/components/hotels/HotelBreadcrumbs'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import SearchProgress from '~src/components/search/searchProgress.src'
    import SortSelect from '@/components/search/SortSelect'
    import OverlayProgress from '@/components/OverlayProgress'

    @Component({
        components: {
            OverlayProgress,
            SortSelect,
            SearchProgress,
            ExpiredOffersSnackbar,
            HotelBreadcrumbs,
            MobileSearchPageTabs,
            HotelSearchSummary,
            HotelOffers,
            HotelFilters,
            Pagination,
            HotelMap: () => import('~src/components/search/offers/hotelMap.src'),
            SearchLayout,
        },
        //middleware: 'searchHotels',
        //watchQuery: true,
        layout: 'blank',
    })
    export default class HotelsPage extends mixins(HotelStoreMixin) {
        mapExpanded = false
        loaded = false
        resetPageLock = false

        @Watch('$route.query')
        onQueryChange() {
            hotelsRuntimeStore.search(this.searchRequest)
        }

        validate({query}) {
            return query.cityId
        }

        async fetch() {
            const cityId = parseInt(this.$route.query.cityId, 10)
            if (hotelsRuntimeStore.city.id !== cityId) {
                await hotelsRuntimeStore.loadCity(cityId)
            }
        }

        async mounted() {
            await this.$store.restored
            this.loaded = true
            if (
                !isEqual(hotelsStore.searchRequest, this.searchRequest) ||
                hotelsStore.isOffersExpired() ||
                !hotelsStore.hasOffers
            ) {
                await this.search()
            } else if (hotelsRuntimeStore.filteredOffers.length === 0) {
                const offers = await this.$localForage.getItem('hotels')
                this.resetPageLock = true
                hotelsRuntimeStore.load(offers)
            }
        }

        async created() {
            EventBus.$on(FILTER_EVENT, this.resetPage)
        }

        beforeDestroy() {
            EventBus.$off(FILTER_EVENT, this.resetPage)
            hotelsRuntimeStore.SET_FILTERED_OFFERS([])
        }

        resetPage() {
            if (this.resetPageLock) {
                this.resetPageLock = false
            } else {
                this.page = 1
            }
        }

        isOffersExpired() {
            return hotelsStore.isOffersExpired()
        }

        async search() {
            await hotelsRuntimeStore.search(this.searchRequest)
        }

        changeFilter(data) {
            hotelsStore.SET_FILTER(data)
            hotelsRuntimeStore.filter()
        }

        resetFilters() {
            hotelsStore.RESET_FILTERS()
            hotelsRuntimeStore.filter()
        }

        stopSearch() {
            this.productRuntimeStore.stopSearch()
        }

        get pages() {
            return Math.ceil(hotelsRuntimeStore.filteredOffers.length / CONFIG.search.resultsOnPage)
        }

        get searchRequest() {
            const rq = Object.assign(searchRequest(), this.$route.query)
            if (typeof rq.rooms === 'string') {
                rq.rooms = [decodeURIComponent(rq.rooms)]
            } else {
                rq.rooms = rq.rooms.map(room => decodeURIComponent(room))
            }
            rq.convertToCurrency = persistentStore.currency
            rq.cityId = parseInt(rq.cityId, 10)
            if (runtimeStore.config.searchAccommodationByDistance && this.city.latitude && this.city.longitude) {
                Object.assign(rq, {
                    distance: runtimeStore.config.searchAccommodationByDistance,
                    latitude: this.city.latitude,
                    longitude: this.city.longitude,
                })
            }
            if (rq.citizenshipId) {
                rq.citizenshipId = parseInt(rq.citizenshipId, 10)
            }
            rq.freeOnly = rq.freeOnly === 'true' || rq.freeOnly === true
            return rq
        }

        get city() {
            return hotelsRuntimeStore.city
        }

        get sortKey() {
            return hotelsStore.sortFnName
        }

        set sortKey(sortKey) {
            hotelsStore.SET_SORT_FN_NAME(sortKey)
            hotelsRuntimeStore.sort(this.filteredOffers)
        }

        get nights() {
            return this.$dateFns.differenceInDays(
                this.$dateFns.parseISO(this.searchRequest.endDate),
                this.$dateFns.parseISO(this.searchRequest.startDate)
            )
        }

        get searchResponse() {
            return hotelsStore.searchResponse
        }

        get filteredOffers() {
            return hotelsRuntimeStore.filteredOffers
        }

        get filterValues() {
            return hotelsStore.filters
        }

        get page() {
            return hotelsStore.searchPage
        }

        set page(page) {
            hotelsStore.SET_SEARCH_PAGE(page)
        }

        get searchActive() {
            return this.productRuntimeStore.searchActive
        }

        get filterActive() {
            return this.productRuntimeStore.filterActive
        }

        get sortItems() {
            return [
                {
                    text: this.$t('sort.price_desc'),
                    value: 'priceAsc',
                },
                {
                    text: this.$t('sort.price_asc'),
                    value: 'priceDesc',
                },
                {
                    text: this.$t('sort.hotel_name'),
                    value: 'name',
                },
                {
                    text: this.$t('sort.category_desc'),
                    value: 'categoryAsc',
                },
                {
                    text: this.$t('sort.category_asc'),
                    value: 'categoryDesc',
                },
            ]
        }

        get currency() {
            return hotelsStore.searchRequest.convertToCurrency
        }
    }
</script>
