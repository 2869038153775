<template>
    <v-dialog v-model="detailModal" max-width="600px" :fullscreen="$breakpoint.smAndDown">
        <template v-slot:activator="{on}">
            <v-btn text color="primary" small v-on="on" @click.stop="detailModal = true">
                {{ $t('flights.view_details') }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="d-flex justify-end">
                <v-icon @click="detailModal = false">
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-card-text>
                <flight-detail :offer="offer" />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'

    @Component({
        components: {
            FlightDetail: () => import('~/components/flights/search/offers/FlightDetail'),
        },
    })
    export default class DetailModal extends Vue {
        @Prop()
        offer

        detailModal = false
    }
</script>
