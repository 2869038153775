<template>
    <v-card outlined hover nuxt :to="{name: 'tour', query: {packageTourId: item.packageTourId}}">
        <v-img
            :src="item.mainImageUrl | imageUrl(800)"
            class="white--text align-end"
            lazy-src="/placeholder.png"
            eager
            :height="250"
        >
            <v-card-title class="word-break">
                {{ item.name }}
            </v-card-title>
            <v-card-subtitle class="white--text"> {{ item.duration }} {{ $tc('day', item.duration) }} </v-card-subtitle>
        </v-img>
    </v-card>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'

    @Component
    export default class TopTourCard extends Vue {
        @Prop() item
    }
</script>

<style scoped lang="scss">
    ::v-deep .v-responsive__content {
        background: rgba(0, 0, 0, 0.4);
    }

    .word-break {
        word-break: break-word;
    }
</style>
