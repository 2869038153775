<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import BookingPageBase from '~src/components/parts/bookingPageBase.src'
    import TransferStoreMixin from '@/mixins/TransferStoreMixin'

    @Component
    export default class TransferBookingPageLayout extends mixins(BookingPageBase, TransferStoreMixin) {
        get productSearchPageName() {
            return 'transfers'
        }

        get productConfirmationPageName() {
            return 'transferConfirmation'
        }

        get productName() {
            return 'transfer'
        }

        get productBookingPageName() {
            return 'transferBooking'
        }

        get corporatePolicyType() {
            return 'transfer'
        }
    }
</script>
