<template>
    <search-layout v-if="loaded" :map-expanded="mapExpanded">
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="search" />
            <transfer-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:left-sidebar>
            <transfer-search-summary :search-request="searchRequest" />
            <sort-select v-model="sortKey" :items="sortItems" />
            <transfer-filters
                :filter-values="filterValues"
                :filters="searchResponse.filters"
                :filtered-offers-count="filteredOffers.length"
                :currency="currency"
                @change="changeFilter"
                @reset="resetFilters"
            />
        </template>
        <template v-slot:main-content>
            <search-progress
                :filtered-offers-count="filteredOffers.length"
                :total-offers-count="searchResponse.product.length"
                :search-active="searchActive"
                @stop="stopSearch"
            />
            <transfer-offers :offers="offers" />
            <pagination :page-prop.sync="page" :pages="pages" />
            <transfer-info-modal />
        </template>
        <template v-slot:right-sidebar>
            <client-only>
                <transfer-map :_expanded.sync="mapExpanded" :points="mapPoints" />
            </client-only>
        </template>
    </search-layout>
</template>

<script>
    import {Component, mixins, Watch} from 'nuxt-property-decorator'
    import MobileSearchPageTabs from '@/components/snippets/MobileSearchPageTabs'
    import Pagination from '~/components/search/Pagination'
    import TransferBreadcrumbs from '@/components/transfers/TransferBreadcrumbs'
    import TransferOffers from '@/components/transfers/search/offers/TransferOffers'
    import TransferFilters from '@/components/transfers/search/TransferFilters'
    import TransferSearchSummary from '@/components/transfers/search/TransferSearchSummary'
    import TransferMap from '@/components/transfers/search/TransferMap'
    import TransferInfoModal from '@/components/transfers/info/TransferInfoModal'
    import SearchPageBase from '@/mixins/SearchPageBase'
    import {transfersStore, transfersRuntimeStore, persistentStore} from '~/store'
    import {searchRequest} from '@/utils/transfers/transfers-blank-states'
    import TransferPageMixin from '@/components/transfers/mixins/TransferPageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import {isEqual} from '@/utils/helpers'
    import SearchProgress from '~src/components/search/searchProgress.src'
    import TransferStoreMixin from '@/mixins/TransferStoreMixin'
    import SortSelect from '@/components/search/SortSelect'

    @Component({
        components: {
            SortSelect,
            SearchProgress,
            ExpiredOffersSnackbar,
            TransferOffers,
            Pagination,
            MobileSearchPageTabs,
            TransferBreadcrumbs,
            TransferFilters,
            TransferSearchSummary,
            TransferMap,
            TransferInfoModal,
            SearchLayout: () => import('~src/components/parts/searchLayout.src'),
        },
        layout: 'blank',
    })
    export default class TransfersPage extends mixins(SearchPageBase, TransferPageMixin, TransferStoreMixin) {
        loaded = false

        @Watch('$route.query')
        onQueryChange() {
            this.search()
        }

        async fetch() {
            await this.loadRuntimeData()
        }

        async mounted() {
            await this.$store.restored
            this.loaded = true
            if (
                !isEqual(transfersStore.searchRequest, this.searchRequest) ||
                this.isOffersExpired() ||
                !transfersStore.hasOffers
            ) {
                await this.search()
            } else if (transfersRuntimeStore.offers.length === 0) {
                this.resetPageLock = true
                this.filter()
            }
        }

        async search() {
            await transfersRuntimeStore.search(this.searchRequest)
        }

        filter() {
            transfersRuntimeStore.filter()
        }

        changeFilter(data) {
            transfersStore.SET_FILTER(data)
            this.filter()
        }

        resetFilters() {
            transfersStore.RESET_FILTERS()
            transfersRuntimeStore.sort(this.searchResponse.product)
        }

        isOffersExpired() {
            return transfersStore.isOffersExpired()
        }

        stopSearch() {
            transfersRuntimeStore.stopSearch()
        }

        get searchRequest() {
            //TODO Need filter $route.query params
            const rq = Object.assign(searchRequest(), this.$route.query)
            if (typeof rq.childrenAges === 'string') {
                rq.childrenAges = [parseInt(rq.childrenAges, 10)]
            }
            rq.convertToCurrency = persistentStore.currency
            rq.adults = parseInt(rq.adults, 10)
            rq.departureCityId = parseInt(rq.departureCityId, 10)
            rq.departureCountryId = parseInt(rq.departureCountryId, 10)
            rq.arrivalCityId = parseInt(rq.arrivalCityId, 10)
            rq.arrivalCountryId = parseInt(rq.arrivalCountryId, 10)
            rq.privateTransfer = rq.privateTransfer === 'true' || rq.privateTransfer === true
            rq.sharedTransfer = rq.sharedTransfer === 'true' || rq.sharedTransfer === true
            if (rq.citizenshipId) {
                rq.citizenshipId = parseInt(rq.citizenshipId, 10)
            }
            return rq
        }

        get mapPoints() {
            return [transfersRuntimeStore.departurePoint, transfersRuntimeStore.arrivalPoint]
        }

        get sortKey() {
            return transfersStore.sortFnName
        }

        set sortKey(sortKey) {
            transfersStore.SET_SORT(sortKey)
            transfersRuntimeStore.sort(this.filteredOffers)
        }

        get filteredOffers() {
            return transfersRuntimeStore.offers
        }

        get filterValues() {
            return transfersStore.filters
        }

        get searchActive() {
            return transfersRuntimeStore.searchActive
        }

        get searchResponse() {
            return transfersStore.searchResponse
        }

        get sortItems() {
            return [
                {
                    text: this.$t('sort.price_desc'),
                    value: 'priceAsc',
                },
                {
                    text: this.$t('sort.price_asc'),
                    value: 'priceDesc',
                },
            ]
        }

        get currency() {
            return transfersStore.searchRequest.convertToCurrency
        }
    }
</script>
