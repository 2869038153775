<template>
    <v-autocomplete
        :items="items"
        :search-input.sync="input"
        :loading="loading"
        item-text="name"
        item-value="cityId"
        :label="label"
        clearable
        hide-no-data
        hide-selected
        :value="_value"
        :rules="[v => !!v || 'Required']"
        @change="$emit('input', $event)"
    />
</template>

<script>
    import {Mixins, Watch, PropSync, Prop} from 'vue-property-decorator'
    import TrainStationsSearchMixin from '~/mixins/TrainStationsSearchMixin'

    export default class TrainStationsSearchInput extends Mixins(TrainStationsSearchMixin) {
        entries = []
        input = null
        loading = false
        timer = null
        @Prop()
        label
        @PropSync('_value') value

        @Watch('input')
        onSearchChange(val) {
            if (this.timer) {
                clearTimeout(this.timer)
            }
            this.timer = window.setTimeout(() => {
                this.searchTrainStation(val)
            }, 400)
        }

        async searchTrainStation(val) {
            try {
                this.loading = true
                this.entries = await this.searchTrainStations({pattern: val})
            } finally {
                this.loading = false
            }
        }

        get items() {
            return this.entries
        }
    }
</script>
