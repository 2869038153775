<template>
    <confirmation-page-layout :company="company">
        <template v-slot:breadcrumbs>
            <hotel-breadcrumbs :search-request="searchRequest" :offer="offer" />
        </template>
        <template v-slot:sidebar>
            <hotel-info-search-summary search-disabled :search-request="searchRequest" />
            <hotel-order-summary :offer="offer" :room-offers="roomOffers" :search-request="searchRequest" />
            <hotel-info-time />
        </template>
    </confirmation-page-layout>
</template>

<script>
    import {mixins, Component} from 'nuxt-property-decorator'
    import HotelInfoSearchSummary from '@/components/info/HotelInfoSearchSummary'
    import HotelOrderSummary from '~src/components/booking/hotelOrderSummary.src'
    import HotelInfoTime from '@/components/info/HotelInfoTime'
    import {hotelsStore, persistentStore} from '~/store'
    import ConfirmationPageLayout from '@/components/parts/ConfirmationPageLayout'
    import ConfirmationPageMixin from '@/mixins/ConfirmationPageMixin'
    import HotelPageMixin from '@/components/hotels/mixins/HotelPageMixin'
    import HotelBreadcrumbs from '@/components/hotels/HotelBreadcrumbs'

    @Component({
        components: {
            HotelBreadcrumbs,
            ConfirmationPageLayout,
            HotelOrderSummary,
            HotelInfoSearchSummary,
            HotelInfoTime,
        },
    })
    export default class HotelBookingConfirmationPage extends mixins(ConfirmationPageMixin, HotelPageMixin) {
        validate({query}) {
            return query.orderId
        }

        async mounted() {
            await this.$store.restored
            await this.loadRuntimeData()
            if (this.$route.query.orderId !== persistentStore.bookResponse[0].orderId) {
                //TODO Redirect to 404
                return
            }
            //TODO Move to store
            const tourists = hotelsStore.tourists.map(room => {
                return room.map(tourist => {
                    const trst = {...tourist}
                    delete trst.alternativeMealTypeId
                    return trst
                })
            })
            hotelsStore.SET_TOURISTS(tourists)
        }

        get basketItem() {
            return (
                hotelsStore.basketItem(hotelsStore.prepareBookRequest.map(({offerKey}) => offerKey)) || {
                    offer: {},
                    roomOffers: [],
                }
            )
        }

        get offer() {
            return this.basketItem.offer
        }

        get roomOffers() {
            return this.basketItem.roomOffers
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get hotelInfoRequest() {
            const {supplierCode, cityCode, hotelCode, hotelId} = this.offer
            return {supplierCode, cityCode, hotelCode, hotelId}
        }

        get cityId() {
            return this.searchRequest.cityId
        }
    }
</script>
