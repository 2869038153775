<template>
    <v-card outlined class="mb-5" data-cy="payment-methods">
        <v-card-title>{{ $t('payment') }}</v-card-title>
        <v-card-text class="px-1 px-md-3">
            <v-radio-group :value="paymentMethodId" :disabled="disabled" @change="selectPayment($event)">
                <template v-for="paymentMethod in paymentMethods">
                    <v-radio :key="paymentMethod.id" :value="paymentMethod.id">
                        <template v-slot:label>
                            <span>{{ paymentMethod.name }}</span>
                            &nbsp;
                            <strong>
                                {{ paymentMethod.totalAmount.value | priceFormat(paymentMethod.totalAmount.currency) }}
                            </strong>
                        </template>
                    </v-radio>
                    <div
                        :key="`${paymentMethod.id}_description`"
                        class="text-caption ml-8 mb-2"
                        v-html="paymentMethod.description"
                    />
                </template>
            </v-radio-group>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Vue, Component, Model, Prop} from 'vue-property-decorator'
    import {persistentStore} from '~/store'

    @Component
    export default class PaymentOptions extends Vue {
        @Model('change') value
        @Prop({default: false, type: Boolean}) disabled

        async selectPayment(paymentMethodId) {
            this.$emit('change', paymentMethodId)
        }

        get paymentMethodId() {
            return this.value
        }

        get paymentMethods() {
            return persistentStore.paymentMethods.filter(paymentMethod => paymentMethod.book)
        }
    }
</script>
