<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import BookingPageBase from '~src/components/parts/bookingPageBase.src'
    import ActivityStoreMixin from '@/mixins/ActivityStoreMixin'

    @Component
    export default class ActivityBookingPageLayout extends mixins(BookingPageBase, ActivityStoreMixin) {
        get productSearchPageName() {
            return 'activities'
        }

        get productConfirmationPageName() {
            return 'activityConfirmation'
        }

        get productName() {
            return 'activity'
        }

        get productBookingPageName() {
            return 'activityBooking'
        }

        get corporatePolicyType() {
            return 'activity'
        }
    }
</script>
