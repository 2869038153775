<template>
    <div>
        <v-subheader class="pl-0">
            {{ label }}
        </v-subheader>
        <v-row no-gutters class="mb-5">
            <v-col>
                <v-text-field
                    :value="sliderModel[0] | selectedFilter"
                    readonly
                    single-line
                    outlined
                    hide-details
                    dense
                    background-color="white"
                />
            </v-col>
            <v-col>
                <v-text-field
                    :value="sliderModel[1] | selectedFilter"
                    readonly
                    single-line
                    outlined
                    hide-details
                    dense
                    background-color="white"
                />
            </v-col>
        </v-row>
        <v-range-slider
            v-model="sliderModel"
            :min="min"
            :max="max"
            :thumb-label="false"
            :disabled="max === Infinity"
            @start="start"
            @end="end"
        />
        <v-divider />
    </div>
</template>

<script>
    import {Vue, Component, Model, Prop} from 'vue-property-decorator'

    @Component
    export default class RangeFilter extends Vue {
        @Model({required: true}) value
        @Prop({required: true}) filters
        @Prop({required: true}) label
        @Prop({required: true}) valueFilter
        @Prop({required: true}) filter

        sliderValue = [0, Infinity]
        sliderStart = false

        created() {
            Vue.filter('selectedFilter', this.$options.filters[this.valueFilter])
            this.sliderValue = [this.filters[this.filter][0], this.filters[this.filter][1]]
        }

        start() {
            this.sliderStart = true
        }

        end() {
            this.$emit('change', {key: this.filter, value: this.sliderValue})
            this.sliderStart = false
        }

        get sliderModel() {
            return this.sliderStart ? this.sliderValue : this.value[this.filter]
        }

        set sliderModel(value) {
            this.sliderValue = value
        }

        get min() {
            return this.filters[this.filter][0]
        }

        get max() {
            return this.filters[this.filter][1]
        }
    }
</script>
