<template>
    <div>
        <mobile-booking-page-tabs v-if="$breakpoint.smAndDown" v-model="mobileTabs" />
        <v-container fluid>
            <slot name="breadcrumbs" />
            <v-row>
                <v-col v-show="!$breakpoint.smAndDown || ($breakpoint.smAndDown && mobileTabs === 0)" md="8">
                    <client-only>
                        <v-card v-if="showTouristSelector" class="mb-5">
                            <v-card-text>
                                <tourist-selector @selected="setTourLead($event, true)" />
                            </v-card-text>
                        </v-card>
                        <v-form ref="mainForm">
                            <account-booking-info :booking-key="bookingKey" />
                            <template v-if="memberLevel !== 'AUTH' || person">
                                <slot
                                    name="touristsCard"
                                    :tourists="tourists"
                                    :bookingKey="bookingKey"
                                    :pageLock="pageLock"
                                >
                                    <v-card outlined class="mb-5">
                                        <v-card-text>
                                            <template v-for="(tourist, index) in tourists">
                                                <slot name="tourists" :options="{disabled: !!bookingKey, index}">
                                                    <tourist-form
                                                        :key="index"
                                                        :index="index"
                                                        label="transfers.passenger"
                                                        :disabled="!!bookingKey"
                                                        :corporate-policy-violation="
                                                            offer ? offer.corporatePolicyViolation : undefined
                                                        "
                                                    />
                                                </slot>
                                                <v-divider
                                                    v-if="tourists.length > index + 1"
                                                    :key="index + '_divider'"
                                                    class="mb-4"
                                                />
                                            </template>
                                            <slot name="orderCommentsForm" :bookingKey="bookingKey" />
                                            <extra-services-form
                                                v-if="hasExtraServices"
                                                :p-store="productStore"
                                                :is-disabled="!!bookingKey"
                                                @updateExtraServices="bookingKey = false"
                                            />
                                        </v-card-text>
                                        <v-btn
                                            v-if="bookingKey && !pageLock"
                                            absolute
                                            dark
                                            fab
                                            bottom
                                            right
                                            small
                                            color="accent"
                                            @click="bookingKey = false"
                                        >
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                    </v-card>
                                </slot>
                                <slot name="forms" :bookingKey="bookingKey" />
                                <corporate-violation-reason
                                    v-if="corporatePolicyViolated && corporatePolicyType"
                                    v-model="corporatePolicyViolationCodeId"
                                    :type="corporatePolicyType"
                                    :policies="violatedPolicies"
                                    :disabled="!!bookingKey"
                                    class="mb-2"
                                />
                                <loyalty-points
                                    v-if="isB2C"
                                    ref="loyaltyPoints"
                                    :is-loading="isLoading"
                                    :disabled="isLoading || pageLock || !!bookingKey"
                                    :resolve-loyalty.sync="resolveLoyalty"
                                />
                                <payment-options
                                    v-if="bookingKey"
                                    v-model="paymentMethodId"
                                    :disabled="isLoading || pageLock"
                                />
                                <booking-agreement-form v-if="bookingKey" :_valid.sync="isAgreementsConfirmed" />
                                <v-btn
                                    v-if="!bookingKey"
                                    :loading="isLoading"
                                    :disabled="isLoading || pageLock"
                                    block
                                    depressed
                                    color="primary"
                                    @click="prepareBook"
                                >
                                    {{ $t('next_step') }}
                                </v-btn>
                                <v-row v-if="bookingKey" dense>
                                    <v-col :cols="saveMethod && !$breakpoint.smAndDown ? 6 : 12">
                                        <v-btn
                                            :loading="isLoading"
                                            :disabled="
                                                isLoading || !paymentMethodId || pageLock || !isAgreementsConfirmed
                                            "
                                            block
                                            depressed
                                            color="primary"
                                            @click="book"
                                        >
                                            {{ $t('book') }}
                                        </v-btn>
                                    </v-col>
                                    <v-col v-if="saveMethod" cols="12" md="6">
                                        <v-btn
                                            :disabled="isLoading || pageLock || !isAgreementsConfirmed"
                                            block
                                            text
                                            color="primary"
                                            class="save-method-book-button"
                                            @click="save"
                                        >
                                            <v-icon>mdi-basket-fill</v-icon> {{ saveMethod.name }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-form>
                    </client-only>
                </v-col>
                <v-col v-show="!$breakpoint.smAndDown || ($breakpoint.smAndDown && mobileTabs === 1)" md="4">
                    <slot name="sidebar" :booking-key="bookingKey" />
                </v-col>
            </v-row>
        </v-container>
        <payment-redirect-form ref="paymentRedirectForm" />
        <vendor-messages-modal :prepare-book-response="prepareBookResponse" :offer="offer" @cancel="cancelFlow" />
    </div>
</template>

<script>
    import {Component, Prop, Watch, mixins} from 'nuxt-property-decorator'
    import MobileBookingPageTabs from '@/components/snippets/MobileBookingPageTabs'
    import TouristForm from '@/components/booking/forms/TouristForm'
    import AccountBookingInfo from '~/components/booking/AccountBookingInfo'
    import {authStore, persistentStore, runtimeStore} from '@/utils/store-accessor'
    import PaymentOptions from '@/components/booking/PaymentOptions'
    import {registeredPerson} from '@/utils/auth-blank-states'
    import {
        CHANGE_PRICE_EVENT,
        EventBus,
        OFFER_EXPIRED_EVENT,
        SHOW_LOYALTY_MODAL_EVENT,
        SHOW_NOTIFICATIONS,
    } from '@/utils/event-bus'
    import {clone} from '@/utils/helpers'
    import BookingAgreementForm from '@/components/booking/forms/BookingAgreementForm'
    import PaymentRedirectForm from '@/components/booking/forms/PaymentRedirectForm'
    import ConfirmationRedirectMixin from '~src/mixins/confirmationRedirectMixin.src'
    import ExtraServicesForm from '@/components/booking/forms/ExtraServicesForm'
    import VendorMessagesModal from '@/components/booking/modals/VendorMessagesModal'
    import LoyaltyPoints from '@/components/booking/LoyaltyPoints'
    import {loadStripe} from '@stripe/stripe-js'

    @Component({
        components: {
            VendorMessagesModal,
            ExtraServicesForm,
            PaymentRedirectForm,
            BookingAgreementForm,
            MobileBookingPageTabs,
            AccountBookingInfo,
            TouristForm,
            PaymentOptions,
            CorporateViolationReason: () => import('~/components/booking/forms/fields/CorporateViolationReason'),
            TouristSelector: () => import('~/components/parts/Booking/TouristSelector'),
            LoyaltyPoints,
        },
    })
    export default class BookingPageBase extends mixins(ConfirmationRedirectMixin) {
        @Prop({required: true}) searchRequest
        @Prop({required: true}) offer

        mobileTabs = 0
        isValid = false
        bookingKey = ''
        paymentMethodId = null
        pageLock = false
        isAgreementsConfirmed = false
        corporatePolicyViolationCodeId = null
        userRegistration = false
        selectedPersonId = null
        resolveLoyalty = true

        async mounted() {
            await this.$store.restored
            this.setTourists()
            this.checkPaymentStatus()
        }

        checkPaymentStatus() {
            switch (this.$route.query.paymentStatus) {
                case 'cancel':
                    this.$toast.warning(this.$t('warning_message.payment_was_cancelled'))
                    break
                case 'failure':
                    this.$toast.error(this.$t('error_message.payment_error'))
                    break
            }
        }

        @Watch('registeredPerson', {immediate: false, deep: true})
        onUpdateRegisteredPerson(person) {
            this.setTourLead(person)
        }

        @Watch('person', {immediate: false, deep: true})
        onUpdatePerson(person) {
            if (this.user.canBeTourlead) {
                this.setTourLead(person)
            }
        }

        get tourLead() {
            return persistentStore.tourists[0]
        }

        set tourLead(val) {
            persistentStore.SET_TOURIST({val, index: 0})
        }

        //TODO Need additional analyse how to refactoring
        touristFromPerson(person) {
            const tourist = runtimeStore.touristFromPerson(person)
            if (!tourist.citizenshipId && !tourist.personId) {
                tourist.citizenshipId = this.searchRequest.citizenshipId
            }
            return Object.assign({}, this.tourLead, tourist)
        }

        //TODO Need additional analyse how to refactoring
        setTourLead(person, selectedPerson = false) {
            if (!person || this.userRegistration || person.memberLevel === 'GUEST') return
            const tourist = this.touristFromPerson(person)
            if (!tourist) return
            this.tourLead = tourist
            if (selectedPerson) {
                this.selectedPersonId = person.personId
            }
        }

        created() {
            EventBus.$on(OFFER_EXPIRED_EVENT, this.onOfferExpired)
        }

        beforeDestroy() {
            EventBus.$off(OFFER_EXPIRED_EVENT, this.onOfferExpired)
            this.productStore.SET_PREPARE_BOOK_RESPONSE({})
        }

        onOfferExpired() {
            this.pageLock = true
        }

        makeValidation() {
            const validation = this.validNestedForms(this.$refs.mainForm)
            this.isValid = validation.validForms.every(valid => valid)
            if (!this.isValid) {
                const indexLink = validation.validForms.findIndex(el => !el)
                this.$vuetify.goTo(validation.links[indexLink], {
                    duration: 500,
                    easing: 'linear',
                })
            }
        }

        validNestedForms(comp) {
            let validForms = []
            let links = []
            if (comp.$refs.form) {
                validForms.push(comp.$refs.form.validate())
                links.push(comp)
            }
            if (comp.$children.length) {
                comp.$children.forEach(childComp => {
                    validForms = [...validForms, ...this.validNestedForms(childComp).validForms]
                    links.push(...this.validNestedForms(childComp).links)
                })
            }
            return {validForms, links}
        }

        async prepareBook() {
            if (this.isB2C && this.isLoyaltyAvailable && !this.resolveLoyalty) {
                this.$refs.loyaltyPoints.makeLoyaltyOptions()
                return
            }

            this.makeValidation()
            if (!this.isValid) return
            //1) Auth user step
            if (!authStore.person) {
                if (!(await this.registerPerson())) return
            }

            //2) Prepare book step
            const prepareBookRequest = this.prepareBookRequest()
            if (await this.sendPrepareBookRequest(prepareBookRequest)) {
                const loyaltyPointsOptions = await this.loyaltyPointsPaymentOptions(
                    this.productStore.prepareBookResponse
                )
                await this.getPaymentOptions(loyaltyPointsOptions)
                this.bookingKey = this.productStore.prepareBookResponse.bookingKey
            }
        }

        loyaltyPointsPaymentOptions(prepareBookResponse) {
            if (this.isB2C && this.isLoyaltyAvailable) {
                return new Promise(resolve => {
                    EventBus.$emit(SHOW_LOYALTY_MODAL_EVENT, {resolve, prepareBookResponse})
                })
            } else {
                return {}
            }
        }

        get isLoyaltyAvailable() {
            return this.offer.rooms?.some(room => !!room.loyaltyPoints) && authStore.person?.totalLoyaltyPoints
        }

        prepareBookRequest() {
            const prepareBookRequest = Object.assign({}, this.productStore.prepareBookRequest, {
                tourists: this.prepareBookTourists(this.tourists),
                offerKey: this.$route.query.offerKey,
            })
            if (this.isB2C) {
                const person = authStore.person || authStore.registeredPerson
                prepareBookRequest.personId = person.personId
            } else if (this.selectedPersonId) {
                prepareBookRequest.personId = this.selectedPersonId
            } else {
                delete prepareBookRequest.personId
            }
            if (persistentStore.selectedOrderId) {
                prepareBookRequest.orderId = persistentStore.selectedOrderId
            } else if (prepareBookRequest.orderId) {
                delete prepareBookRequest.orderId
            }
            if (this.productStore.bookingExtraServices && this.productStore.bookingExtraServices.length) {
                prepareBookRequest.extraServices = []
                this.productStore.bookingExtraServices.map(extraService => {
                    prepareBookRequest.extraServices.push({
                        serviceRPH: extraService.serviceRPH,
                        quantity: extraService.quantity,
                    })
                })
            } else if (prepareBookRequest.extraServices && prepareBookRequest.extraServices.length) {
                delete prepareBookRequest.extraServices
            }
            return prepareBookRequest
        }

        //TODO Need check
        prepareBookTourists(tourists) {
            const isFieldDisabled = (tourist, index, fieldKey) =>
                runtimeStore.isTouristBookingFieldDisabled(fieldKey, index === 0, tourist.type === 'child')
            return tourists.map((tourist, index) => {
                const newTourist = {...tourist}
                const {expiryDate, number, issueDate} = tourist.passport
                if (expiryDate || number || issueDate) {
                    newTourist.passport = {
                        expiryDate,
                        number,
                        issueDate,
                    }
                    if (isFieldDisabled(tourist, index, 'PASSPORT_NUMBER')) delete newTourist.passport.number
                    if (isFieldDisabled(tourist, index, 'PASSPORT_EXPIRE_DATE')) delete newTourist.passport.expiryDate
                    if (isFieldDisabled(tourist, index, 'PASSPORT_ISSUE_DATE')) delete newTourist.passport.issueDate
                } else {
                    delete newTourist.passport
                }
                if (isFieldDisabled(tourist, index, 'PHONE')) delete newTourist.phone
                if (isFieldDisabled(tourist, index, 'EMAIL')) delete newTourist.email
                if (isFieldDisabled(tourist, index, 'PREFIX')) delete newTourist.prefix
                if (isFieldDisabled(tourist, index, 'CITIZENSHIP')) delete newTourist.citizenshipId
                if (isFieldDisabled(tourist, index, 'BIRTHDATE')) delete newTourist.birthdate
                delete newTourist.age
                return newTourist
            })
        }

        async sendPrepareBookRequest(prepareBookRequest) {
            try {
                const prepareBookResponse = await this.productStore.prepareBook(prepareBookRequest)
                return this.prepareBookResponseProcessing(prepareBookResponse, this.$route.query.offerKey)
            } catch (e) {
                return this.prepareBookErrorProcessing(e)
            }
        }

        prepareBookResponseProcessing(prepareBookResponse, offerKey) {
            if (parseInt(prepareBookResponse.status, 10) > 2) {
                //TODO Setup messages UI
                if (prepareBookResponse.errors) {
                    prepareBookResponse.errors.forEach(e => {
                        if (e.message) {
                            if (e.errorType === 'external') {
                                this.$toast.error(this.$t('error_message.something_went_wrong'), {queueable: true})
                            } else {
                                this.$toast.error(e.message, {queueable: true})
                            }
                        } else {
                            this.$toast.error(this.$t('error_message.prepare_book_unknown_error'), {
                                queueable: true,
                            })
                        }
                    })
                } else if (prepareBookResponse.warnings) {
                    prepareBookResponse.warnings.forEach(warning =>
                        this.$toast.warning(warning.message, {queueable: true})
                    )
                } else {
                    this.$toast.error(this.$t('error_message.prepare_book_unknown_error'), {
                        queueable: true,
                    })
                }
                return false
            } else {
                if (prepareBookResponse.warnings?.length) {
                    prepareBookResponse.warnings.forEach(warning => {
                        if (warning.token === 'external_price_has_been_changed') {
                            EventBus.$emit(CHANGE_PRICE_EVENT, {offerKey, prepareBookResponse})
                            this.$toast.warning(this.$t(`warning_message.${warning.token}`))
                        }
                    })
                }
                if (prepareBookResponse.vendorMessages && prepareBookResponse.vendorMessages.length) {
                    //TODO Setup messages UI
                    //TODO Remove <p> in api response
                    /*prepareBookResponse.vendorMessages.forEach(msg =>
                        this.$toast.warning(msg, {
                            icon: 'mdi-information',
                            closeIcon: 'mdi-close',
                            showClose: true,
                            timeout: 0,
                        })
                    )*/
                }
            }
            return true
        }

        prepareBookErrorProcessing(e) {
            //TODO Check other API errors
            if (e.status === 404) {
                //TODO Show offers expired
            } else {
                //TODO Is need to show all API messages?
                let hasValidationMessages = false
                if (e.errors) {
                    e.errors.forEach(e => {
                        if (e.errorType === 'validation') {
                            hasValidationMessages = true
                            let errorMsg = e.message
                            if (e.token === 'api.please.specify.serviceRPH.and.quantity') {
                                errorMsg = this.$t('extraServices.errors.empty_qty')
                            }
                            this.$toast.error(errorMsg, {queueable: true})
                        }
                    })
                }
                if (!hasValidationMessages) {
                    this.$toast.error(this.$t('error_message.prepare_book_unknown_error'))
                }
            }
            return false
        }

        save() {
            this.paymentMethodId = this.saveMethod.id
            this.book()
        }

        async getPaymentOptions(loyaltyPointsOptions) {
            try {
                await persistentStore.getPaymentOptions({
                    ...loyaltyPointsOptions,
                    bookingKey: this.productStore.prepareBookResponse.bookingKey,
                    convertToCurrency: persistentStore.currency,
                })
            } catch (e) {
                this.$toast.warning(this.$t('error_message.get_payment_options_unknown_error'))
            }
        }

        async registerPerson() {
            this.userRegistration = true
            try {
                if (authStore.registeredPerson.memberLevel === 'GUEST') {
                    const {prefix, firstName, lastName, citizenshipId, email, phone} = this.tourLead
                    authStore.SET_REGISTERED_PERSON(
                        Object.assign(registeredPerson(), {
                            prefix,
                            firstName,
                            lastName,
                            citizenshipId,
                            email,
                            contactPhone: phone,
                        })
                    )
                }
                await authStore.register(authStore.registeredPerson)
                return true
            } catch (e) {
                if (e.response && e.response.status === 500) {
                    return false
                }
                if (e.errors.length && e.errors[0].token === 'api.person.already.exist') {
                    //TODO In API make tokens with _
                    this.$toast.error(this.$t(`api_error_token.${e.errors[0].token.replace(/\./g, '_')}`))
                    return false
                } else {
                    this.$toast.error(this.$t('error_message.registration_on_booking_step_error'))
                    return false
                }
            } finally {
                this.userRegistration = false
            }
        }

        async book() {
            //3) Book step
            await this.$nextTick()
            this.pageLock = true
            const paymentMethod = persistentStore.paymentMethods.find(
                paymentMethod => paymentMethod.id === this.paymentMethodId
            )
            if (!paymentMethod.onlinePSP) {
                await this.makeOfflinePayment()
            } else {
                await this.makeOnlinePayment()
            }
            this.clearCommentsAfterBooking()
        }

        clearCommentsAfterBooking() {
            this.productStore.SET_PREPARE_BOOK_REQUEST_PROP({prop: 'comments', val: ''})
        }

        cancelFlow() {
            this.bookingKey = false
            this.resolveLoyalty = true
            if (this.$refs.loyaltyPoints) {
                this.$refs.loyaltyPoints.close()
            }
        }

        async makeOfflinePayment() {
            try {
                const bookRs = await persistentStore.book({
                    bookingKey: this.bookingKey,
                    paymentMethodId: this.paymentMethodId,
                    ...(this.corporatePolicyViolationCodeId && {
                        corporatePolicyViolationCodeId: this.corporatePolicyViolationCodeId,
                    }),
                })
                if (!persistentStore.selectedOrderId && !authStore.person) {
                    await this.$router.push({
                        name: this.productConfirmationPageName,
                        query: this.productConfirmationPageQuery(bookRs[0].orderId),
                    })
                } else {
                    persistentStore.SET_SELECTED_ORDER_ID(null)
                    this.orderDetailsRedirect(bookRs[0].orderId)
                }
            } catch (e) {
                this.pageLock = true
                this.$toast.error(this.$t('error_message.unknown_booking_error'))
            }
        }

        productConfirmationPageQuery(orderId) {
            return {orderId}
        }

        async makeOnlinePayment() {
            try {
                const paymentInitiateRs = await persistentStore.paymentInitiate(this.getPaymentInitiateRq())
                if (paymentInitiateRs.simpleRedirect) {
                    window.location = paymentInitiateRs.url
                } else {
                    const paymentMethod = persistentStore.paymentMethods.find(
                        paymentMethod => paymentMethod.id === this.paymentMethodId
                    )
                    if (paymentMethod.pspCode === 'webpay' && runtimeStore.config.webpayContractDomain) {
                        const {url, parameters, paymentNumber, paymentTransactionId} = paymentInitiateRs
                        await this.$refs.paymentRedirectForm.submit({
                            url: `${runtimeStore.config.webpayContractDomain}/payment-redirect`,
                            parameters,
                            paymentNumber,
                            paymentTransactionId,
                            originUrl: url,
                        })
                    } else if (paymentMethod.pspCode === 'stripe') {
                        const {APIPublicKey, sessionId} = paymentInitiateRs.parameters.reduce(
                            (parameters, {name, value}) => {
                                parameters[name] = value
                                return parameters
                            },
                            {}
                        )
                        const stripe = await loadStripe(APIPublicKey)
                        await stripe.redirectToCheckout({sessionId})
                    } else {
                        await this.$refs.paymentRedirectForm.submit(paymentInitiateRs)
                    }
                }
            } catch (e) {
                this.pageLock = false
                EventBus.$emit(SHOW_NOTIFICATIONS, {
                    notifications: [this.$t('error_message.payment_initiate_error')],
                    color: 'error',
                })
            }
        }

        getPaymentInitiateRq() {
            const getPaymentCompleteUrl = () => {
                return (
                    window.location.origin +
                    this.$router.resolve({
                        name: 'paymentComplete',
                        query: this.paymentCompletePageQuery,
                    }).href
                )
            }
            const getPaymentCancelUrl = paymentStatus => {
                return (
                    window.location.origin +
                    this.$router.resolve({
                        name: this.productBookingPageName,
                        query: this.productBookingPageQuery(paymentStatus),
                    }).href
                )
            }
            return {
                bookingKey: this.bookingKey,
                paymentMethodId: this.paymentMethodId,
                successUrl: getPaymentCompleteUrl(),
                failureUrl: getPaymentCancelUrl('failure'),
                cancelUrl: getPaymentCancelUrl('cancel'),
            }
        }

        get paymentCompletePageQuery() {
            return {
                paymentMethodId: this.paymentMethodId,
                bookingKey: this.bookingKey,
                product: this.productName,
                ...(this.corporatePolicyViolationCodeId && {
                    corporatePolicyViolationCodeId: this.corporatePolicyViolationCodeId,
                }),
            }
        }

        productBookingPageQuery(paymentStatus) {
            return {
                paymentStatus,
                offerKey: this.$route.query.offerKey,
            }
        }

        get memberLevel() {
            return authStore.registeredPerson.memberLevel
        }

        get person() {
            return authStore.person
        }

        get tourists() {
            return persistentStore.tourists
        }

        get saveMethod() {
            return persistentStore.paymentMethods.find(paymentMethod => !paymentMethod.book)
        }

        get isLoading() {
            return runtimeStore.bookingActive || runtimeStore.authActive
        }

        get isB2C() {
            return authStore.isB2C
        }

        initTourists(rq, tourists, roomIndex = 0) {
            let i = 0
            for (i; i < rq.adults; i++) {
                if (
                    !tourists[i] ||
                    tourists[i].type !== 'adult' ||
                    !(tourists[i].firstName || tourists[i].alternativeMealTypeId)
                ) {
                    const user = authStore.user
                    if (i === 0 && roomIndex === 0 && authStore.person && (!authStore.person.role || user.isEmployee)) {
                        tourists[0] = this.touristFromPerson(authStore.person) || {
                            prefix: null,
                            firstName: null,
                            lastName: null,
                            citizenshipId: this.addCityzenship(),
                            type: 'adult',
                            phone: null,
                            email: null,
                            birthdate: null,
                            passport: {
                                type: null,
                                number: null,
                                issueDate: null,
                                expiryDate: null,
                            },
                        }
                    } else {
                        tourists[i] = {
                            prefix: null,
                            firstName: null,
                            lastName: null,
                            citizenshipId: this.addCityzenship(),
                            type: 'adult',
                            passport: {
                                type: null,
                                number: null,
                                issueDate: null,
                                expiryDate: null,
                            },
                        }
                        if (i === 0) {
                            Object.assign(tourists[i], {phone: null, email: null})
                        }
                    }
                }
            }
            rq.childrenAges.forEach((age, index) => {
                const i = index + rq.adults
                if (!tourists[i] || tourists[i].type !== 'child' || tourists[i].age !== age) {
                    tourists[i] = {
                        prefix: null,
                        firstName: null,
                        lastName: null,
                        citizenshipId: this.addCityzenship(),
                        birthdate: null,
                        age,
                        type: 'child',
                        passport: {
                            type: null,
                            number: null,
                            issueDate: null,
                            expiryDate: null,
                        },
                    }
                }
            })
            const touristsTotal = rq.adults + rq.childrenAges.length
            return tourists.slice(0, touristsTotal)
        }

        setTourists() {
            persistentStore.SET_TOURISTS(this.initTourists(this.searchRequest, clone(persistentStore.tourists)))
        }

        addCityzenship() {
            if (this.b2BAutoCompliteCitezenship) {
                return authStore.company.countryId
            } else {
                return this.searchRequest.citizenshipId || authStore.person?.countryId || null
            }
        }

        get registeredPerson() {
            return authStore.registeredPerson
        }

        /**
         * @returns {CorporateUser|TourAgency|TourOperator|User}
         */
        get user() {
            return authStore.user
        }

        get corporatePolicyViolated() {
            return this.productStore.prepareBookResponse &&
                this.productStore.prepareBookResponse.corporatePolicyViolation
                ? this.productStore.prepareBookResponse.corporatePolicyViolation
                : this.offer
                ? this.offer.corporatePolicyViolation
                : false
        }

        get violatedPolicies() {
            return this.productStore.prepareBookResponse
                ? this.productStore.prepareBookResponse.corporatePolicies
                : this.offer.corporatePolicy
        }

        get showTouristSelector() {
            return authStore.isAgency || authStore.isTourOperator
        }

        get prepareBookResponse() {
            return this.productStore.prepareBookResponse
        }

        get b2BAutoCompliteCitezenship() {
            return authStore.isTO1 || authStore.isAgency || authStore.isCorporate
        }

        get hasExtraServices() {
            return this.productStore?.bookingAdditionalOptions?.extraServices
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
        .save-method-book-button {
            padding: 0 !important;
            font-size: 0.65rem;
        }
    }
</style>
