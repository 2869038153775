<script>
    import {Component} from 'vue-property-decorator'
    import {hotelsRuntimeStore} from '~/store'
    import HotelInfoSearchForm from '@/components/info/forms/HotelInfoSearchForm'
    import HotelSearchSummary from '@/components/search/HotelSearchSummary'

    //TODO Hide if empty searchRequest
    @Component({
        components: {
            HotelInfoSearchForm,
        },
    })
    export default class HotelInfoSearchSummary extends HotelSearchSummary {
        get isSearchActive() {
            return hotelsRuntimeStore.singleHotelSearchActive
        }

        get searchFormComponent() {
            return 'hotel-info-search-form'
        }
    }
</script>
