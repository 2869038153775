<template>
    <v-dialog v-model="modal" hide-overlay width="600">
        <v-card v-if="infoLoading" color="primary" dark>
            <v-card-text>
                {{ $t('loading') }}
                <v-progress-linear indeterminate color="white" class="mb-0" />
            </v-card-text>
        </v-card>
        <v-card v-else>
            <v-icon class="v-card-close" @click="modal = false">
                mdi-close
            </v-icon>
            <v-card-title>
                {{ roomInfo.name }}
            </v-card-title>
            <v-card-text>
                <div
                    v-if="
                        !roomInfo.images.image.length &&
                            roomInfo.description === roomInfo.name &&
                            !roomInfo.services.length &&
                            !tariffDetails.loading &&
                            !tariffDetails.tariff &&
                            !tariffDetails.tariffDescription
                    "
                >
                    {{ $t('no_details') }}
                </div>
                <v-carousel
                    v-if="roomInfo.images.image.length"
                    height="300"
                    :show-arrows="roomInfo.images.image.length > 1"
                    :hide-delimiters="roomInfo.images.image.length === 1"
                    hide-delimiter-background
                    show-arrows-on-hover
                    class="mb-5"
                >
                    <v-carousel-item
                        v-for="(image, imageIndex) in roomInfo.images.image"
                        :key="imageIndex"
                        :src="image.url | imageUrl(600)"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                        @click="showPhotoSwipe(imageIndex)"
                    />
                </v-carousel>
                <template v-if="showShortDescription(roomInfo)">
                    <strong>{{ $t('description_type.short') }}</strong>
                    <br />
                    <div v-html="roomInfo.shortDescription" />
                </template>
                <template v-if="roomInfo.description && roomInfo.description !== roomInfo.name">
                    <strong>{{ $t('description_type.general') }}</strong>
                    <br />
                    <div v-html="roomInfo.description" />
                </template>
                <template v-if="roomInfo.services.length">
                    <strong>{{ $t('room_amenities') }}</strong>
                    <v-row dense class="mb-5">
                        <v-col
                            v-for="(service, index) in roomInfo.services"
                            :key="index"
                            cols="6"
                            class="text-capitalize"
                        >
                            {{ service.supplierServiceName }}
                        </v-col>
                    </v-row>
                </template>
                <v-skeleton-loader
                    v-if="tariffDetails.loading"
                    transition="fade-transition"
                    height="100"
                    type="list-item-two-line"
                />
                <template v-if="!tariffDetails.loading && (tariffDetails.tariff || tariffDetails.tariffDescription)">
                    <strong>{{ $t('tariff_description') }}</strong>
                    <div v-if="tariffDetails.tariff" v-html="tariffDetails.tariff" />
                    <div v-if="tariffDetails.tariffDescription" v-html="tariffDetails.tariffDescription" />
                </template>
            </v-card-text>
        </v-card>
        <v-photo-swipe
            v-if="photoSwipeIsOpen"
            :is-open.sync="photoSwipeIsOpen"
            :items="photoSwipeItems"
            :photo-swipe-options="photoSwipeOptions"
        />
    </v-dialog>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'
    import {hotelsRuntimeStore, hotelsStore} from '@/store'
    import {isEqual} from '@/utils/helpers'
    import {EventBus, SHOW_ROOM_INFO_MODAL_EVENT} from '@/utils/event-bus'
    import VPhotoSwipe from '@/components/snippets/VPhotoSwipe'

    @Component({components: {VPhotoSwipe}})
    export default class HotelRoomInfoModal extends Vue {
        infoLoading = false
        modal = false
        tariffDetails = {
            loading: true,
        }
        roomInfo = {
            images: {
                image: [],
            },
            services: [],
        }
        offerKey
        photoSwipeIsOpen = false
        photoSwipeOptions = {
            index: 0,
        }

        created() {
            EventBus.$on(SHOW_ROOM_INFO_MODAL_EVENT, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_ROOM_INFO_MODAL_EVENT, this.showModal)
        }

        async showModal(hotel, offerKey) {
            this.modal = true
            this.offerKey = offerKey
            this.infoLoading = true
            this.loadTariffDetails()
            const {supplierCode, cityCode, hotelCode} = hotel,
                rq = {supplierCode, cityCode, hotelCode}
            if (!isEqual(rq, hotelsRuntimeStore.hotelInfoRequest)) {
                try {
                    await hotelsRuntimeStore.loadHotelInfo(rq)
                } catch (e) {
                    this.infoLoading = false
                    return
                }
            }
            const room = hotel.rooms.find(
                room => room.groupedOffers.findIndex(go => go.offerKey === this.offerKey) !== -1
            )
            //TODO After fix API find by code
            const roomInfo = hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo.roomTypes.roomType.find(
                roomType => roomType.code === room.code
            )
            if (roomInfo) {
                this.roomInfo = roomInfo
            }
            this.infoLoading = false
        }

        async loadTariffDetails() {
            if (!hotelsStore.tariffDetails[this.offerKey]) {
                await hotelsStore.getTariffDetails({offerKey: this.offerKey})
            }
            this.tariffDetails = hotelsStore.tariffDetails[this.offerKey]
        }

        showShortDescription(info) {
            return (
                info.shortDescription &&
                info.shortDescription !== info.name &&
                (!info.description || info.description.indexOf(info.shortDescription) === -1)
            )
        }

        showPhotoSwipe(index) {
            this.photoSwipeOptions.index = index
            this.photoSwipeIsOpen = true
        }

        get images() {
            return this.roomInfo && this.roomInfo.images ? this.roomInfo.images.image : []
        }

        get photoSwipeItems() {
            return this.images.map(({url, title}) => ({
                src: this.$options.filters.imageUrl(url, 800),
                w: 0,
                h: 0,
                title,
            }))
        }
    }
</script>

<style scoped>
    .description::v-deep img {
        max-width: 300px;
        height: auto;
    }
</style>
