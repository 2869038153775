<template>
    <v-autocomplete
        v-model="model"
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        hide-no-data
        item-text="label"
        item-value="personId"
        hide-selected
        :label="$t('select_user')"
        :placeholder="$t('start_typing')"
        prepend-icon="mdi-account"
        return-object
        clearable
        :rules="required ? [requiredRule('select_user')] : []"
        :disabled="disabled"
        @change="$emit('selected', $event)"
    />
</template>

<script>
    import {Component, mixins, Watch, Prop} from 'nuxt-property-decorator'
    import {authStore} from '~/store'
    import FormValidationRulesMixin from '~/mixins/FormValidationRulesMixin'

    @Component
    export default class TouristSelector extends mixins(FormValidationRulesMixin) {
        @Prop() tourist
        @Prop({default: () => []}) selectedTourists
        @Prop({default: false, type: Boolean}) required
        @Prop({default: false, type: Boolean}) disabled

        entries = []
        isLoading = false
        model = null
        search = null
        timer = null

        @Watch('search')
        async onSearchChange(val) {
            if (this.timer) {
                clearTimeout(this.timer)
            }
            this.timer = window.setTimeout(() => {
                this.initSearch(val)
            }, 500)
        }

        async mounted() {
            await this.$store.restored
            if (this.tourist && this.tourist.personId) {
                this.entries = [this.tourist]
                this.model = this.tourist
            }
        }

        label({firstName, firstNameLocalized, lastName, lastNameLocalized}) {
            return `${firstName || firstNameLocalized || ''} ${lastName || lastNameLocalized || ''}`
        }

        async initSearch(val) {
            if (
                this.isLoading ||
                (this.entries.length > 0 && this.items.map(({label}) => label).indexOf(val) !== -1) ||
                !val
            )
                return

            this.isLoading = true

            const rs = await this.$api[this.user.takeClientMethod].get({
                pattern: val,
                companyId: this.user.companyId,
                limit: 10,
            })
            this.entries = rs.users || rs.persons
            this.isLoading = false
        }

        clearResearch() {
            if (this.tryResearch.status) {
                this.tryResearch.status = false
            }
        }

        get fields() {
            if (!this.model) return []

            return Object.keys(this.model).map(key => {
                return {
                    key,
                    value: this.model[key] || 'n/a',
                }
            })
        }

        get items() {
            return this.entries
                .filter(
                    entry =>
                        (this.model && entry.personId === this.model.personId) ||
                        this.selectedTourists.every(selectedTourist => selectedTourist.personId !== entry.personId)
                )
                .map(entry => {
                    const label = this.label(entry)
                    return Object.assign({}, entry, {label})
                })
        }

        get user() {
            return authStore.user
        }
    }
</script>
