<template>
    <v-app>
        <header-bar />
        <v-main>
            <admin-navigation />
            <v-container fluid class="pl-24">
                <nuxt />
            </v-container>
        </v-main>
        <footer-bar />
    </v-app>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import {EventBus, LOGOUT_REDIRECT_EVENT} from '@/utils/event-bus'
    import HeaderBar from '~src/components/headerBar.src'
    import FooterBar from '~src/components/footerBar.src'
    import AdminNavigation from '@/components/admin/AdminNavigation'
    import BaseLayout from '@/components/BaseLayout'

    @Component({
        components: {
            AdminNavigation,
            HeaderBar,
            FooterBar,
        },
    })
    export default class AdminLayout extends BaseLayout {
        created() {
            EventBus.$on(LOGOUT_REDIRECT_EVENT, () => {
                if (this.$toast) {
                    this.$toast.error(this.$t('authorization_timeout'))
                }
            })
        }
    }
</script>

<style scoped lang="scss">
    .pl-24 {
        padding-left: 96px;
    }
</style>
