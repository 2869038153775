<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {runtimeStore} from '@/utils/store-accessor'

    @Component
    export default class FormValidationRulesMixin extends Vue {
        requiredRule(prop) {
            return v => !!v || `${this.$t(prop)} ${this.$t('validation.required')}`
        }

        emailRule(prop) {
            return v => !v || /.+@.+\..+/.test(v) || `${this.$t(prop)} ${this.$t('validation.valid')}`
        }

        phoneRule(prop) {
            return v => (/^[\d+\-()\s]*$/.test(v) || !v ? true : `${this.$t(prop)} ${this.$t('validation.valid')}`)
        }

        latinRule(prop) {
            return v =>
                !runtimeStore.config.personNameOnLatin ||
                /^[a-z\s-]*$/i.test(v) ||
                this.$t('validation.latin', {prop: this.$t(prop)})
        }

        validationRules(prop, ruleFns, fieldKey) {
            return ruleFns.map(ruleFn => ruleFn(prop, fieldKey))
        }
    }
</script>
