<template>
    <v-card>
        <v-card-text>
            <v-form>
                <v-row>
                    <v-col cols="12" md="4">
                        <daterange
                            :start-date-prop.sync="model.dateFrom"
                            :end-date-prop.sync="model.dateTo"
                            :popover="{placement: 'bottom'}"
                            :show-icon="false"
                            :single-line="false"
                        />
                    </v-col>
                    <v-col cols="12" md="8">
                        <days-picker :_day-of-weeks.sync="model.dayOfWeek" />
                    </v-col>
                </v-row>
                <v-row v-if="model.services.length" class="mt-3">
                    <v-col cols="12" md="12">
                        <h3>
                            {{ $t('supplier.open_close_rooms_title') }}
                        </h3>
                        <div class="d-flex">
                            <v-checkbox
                                v-for="(service, index) in services"
                                :key="service.id"
                                v-model="model.services[index].checked"
                                :label="service.name"
                                class="mr-5"
                            />
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-btn
                            color="primary"
                            class="mr-4"
                            :disabled="!valid()"
                            @click="
                                model.operation = 'CLEAR_BLOCK'
                                $emit('update', model)
                            "
                        >
                            {{ $t('supplier.open') }}
                        </v-btn>
                        <v-btn
                            color="primary"
                            :disabled="!valid()"
                            @click="
                                model.operation = 'BLOCK'
                                $emit('update', model)
                            "
                        >
                            {{ $t('supplier.close') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import Daterange from '@/components/supplier/DateRange'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'
    import DaysPicker from '@/components/supplier/DaysPicker'

    @Component({
        components: {DaysPicker, Daterange},
    })
    export default class OpenCloseRooms extends Mixins(FormValidationRulesMixin) {
        @Prop() startDate
        @Prop() endDate
        @Prop() services

        model = {
            dateFrom: null,
            dateTo: null,
            dayOfWeek: [true, true, true, true, true, true, true],
            services: [],
            operation: null,
        }

        mounted() {
            this.$set(this.model, 'dateFrom', this.startDate)
            this.$set(this.model, 'dateTo', this.endDate)

            let services = []
            this.services.forEach(service => {
                services.push({
                    serviceId: service.id,
                    categoryId: service.categoryId,
                    checked: false,
                })
            })
            this.$set(this.model, 'services', services)
        }

        valid() {
            const filtered = this.model.services.filter(service => {
                return service.checked === false
            })

            return filtered.length !== this.model.services.length
        }
    }
</script>

<style scoped lang="scss"></style>
