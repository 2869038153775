<template>
    <search-layout v-if="loaded" :map-expanded="mapExpanded">
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="search" />
            <flight-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:left-sidebar>
            <flight-search-summary :search-request="searchRequest" :search-active="searchActive" />
            <sort-select v-model="sortKey" :items="sortItems" />
            <flight-filters
                :filter-values="filterValues"
                :filters="searchResponse.filters"
                :filtered-offers-count="filteredOffers.length"
                :currency="currency"
                @change="changeFilter"
                @reset="resetFilters"
            />
        </template>
        <template v-slot:main-content>
            <search-progress
                :filtered-offers-count="filteredOffers.length"
                :total-offers-count="searchResponse.offers.length"
                :search-active="searchActive"
                :hide-stop-btn="$route.name === 'flightsAvailability'"
                @stop="stopSearch"
            />
            <v-dialog v-if="$route.name === 'flights'" v-model="flexibleFlightsModal" width="100%" max-width="1200px">
                <template v-slot:activator="{on}">
                    <v-btn block @click="getFlexibleFlights()" v-on="on">
                        {{ $t('flights.flexible') }}
                    </v-btn>
                </template>
                <v-card>
                    <v-card-text>
                        <flexible-flights :flights="flexibleFlights" :dates="flexibleDates" />
                    </v-card-text>
                </v-card>
            </v-dialog>
            <flight-best-offers :total-offers="searchResponse.offers" :offers="filteredOffers" />
            <flight-offers :offers="offers" />
            <pagination :page-prop.sync="page" :pages="pages" />
            <conditions-modal>
                <template v-slot:info>
                    <flight-conditions-modal-info />
                </template>
            </conditions-modal>
        </template>
        <template v-slot:right-sidebar>
            <client-only>
                <flight-map :_expanded.sync="mapExpanded" :points="mapPoints" />
            </client-only>
        </template>
    </search-layout>
</template>

<script>
    import {Component, mixins, Watch} from 'nuxt-property-decorator'
    import Pagination from '~/components/search/Pagination'
    import ConditionsModal from '~/components/modals/ConditionsModal'
    import FlightBreadcrumbs from '~/components/flights/FlightBreadcrumbs'
    import FlightSearchSummary from '~/components/flights/search/FlightSearchSummary'
    import SearchProgress from '~src/components/search/searchProgress.src'
    import FlightMap from '~/components/flights/search/FlightMap'
    import FlightFilters from '~/components/flights/search/FlightFilters'
    import FlightOffers from '~/components/flights/search/offers/FlightOffers'
    import FlightBestOffers from '~/components/parts/Flights/FlightBestOffers'
    import SearchPageBase from '@/mixins/SearchPageBase'
    import {searchRequest} from '@/utils/flights/flights-blank-states'
    import FlightStoreMixin from '@/mixins/FlightStoreMixin'
    import {flightsStore, persistentStore} from '~/store'
    import SearchLayout from '~/components/parts/SearchLayout'
    import {flightsRuntimeStore} from '~/store'
    import FlightPageMixin from '~/components/flights/mixins/FlightPageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import {isEqual} from '@/utils/helpers'
    import SortSelect from '@/components/search/SortSelect'
    import FlightConditionsModalInfo from '@/components/flights/snippets/FlightConditionsModalInfo'

    @Component({
        components: {
            FlightConditionsModalInfo,
            SortSelect,
            ExpiredOffersSnackbar,
            FlightSearchSummary,
            SearchProgress,
            FlightMap,
            FlightFilters,
            FlightOffers,
            Pagination,
            FlightBreadcrumbs,
            ConditionsModal,
            SearchLayout,
            FlightBestOffers,
            FlexibleFlights: () => import('~/components/flights/FlexibleFlights'),
        },
        layout: 'blank',
    })
    export default class FlightsPage extends mixins(FlightPageMixin, FlightStoreMixin, SearchPageBase) {
        loaded = false
        flexibleFlightsModal = false

        @Watch('$route.query')
        onQueryChange() {
            this.search()
            this.flexibleFlightsModal = false
            this.loadRuntimeData()
        }

        async fetch() {
            flightsRuntimeStore.SET_SEARCH_TYPE(this.$route.name === 'flights' ? 'price' : 'schedule')
            await this.loadRuntimeData()
        }

        async mounted() {
            await this.load()
        }

        async load() {
            await this.$store.restored
            this.loaded = true
            if (
                !isEqual(this.productStore.searchRequest, this.searchRequest) ||
                this.isOffersExpired() ||
                !this.productStore.hasOffers
            ) {
                await this.search()
            } else if (this.productRuntimeStore.offers.length === 0) {
                this.resetPageLock = true
                this.filter()
            }
        }

        async search() {
            await this.productRuntimeStore.search(this.searchRequest)
        }

        isOffersExpired() {
            return this.productStore.isOffersExpired()
        }

        beforeDestroy() {
            this.productRuntimeStore.SET_OFFERS([])
        }

        getFlexibleFlights() {
            const rq = {...this.$route.query}
            rq.flexibleDays = 3
            rq.convertToCurrency = persistentStore.currency
            this.productRuntimeStore.searchFlexibleFlights(rq)
        }

        filter() {
            flightsRuntimeStore.filter({
                filters: this.filterValues,
                offers: this.searchResponse.offers,
            })
        }

        changeFilter(data) {
            flightsStore.SET_FILTER(data)
            this.filter()
        }

        resetFilters() {
            flightsStore.RESET_FILTERS()
            flightsRuntimeStore.sort(this.searchResponse.offers)
        }

        stopSearch() {
            flightsRuntimeStore.stopSearch()
        }

        get searchRequest() {
            //TODO Need filter $route.query params
            const rq = Object.assign(searchRequest(), this.$route.query)
            if (typeof rq.routes === 'string') {
                rq.routes = [rq.routes]
            }
            if (typeof rq.childrenAges === 'string') {
                rq.childrenAges = [parseInt(rq.childrenAges, 10)]
            }
            rq.convertToCurrency = persistentStore.currency
            rq.adults = parseInt(rq.adults, 10)
            rq.directFlight = rq.directFlight === 'true' || rq.directFlight === true
            rq.baggageOnly = rq.baggageOnly === 'true' || rq.baggageOnly === true
            rq.refundableOnly = rq.refundableOnly === 'true' || rq.refundableOnly === true
            if (rq.citizenshipId) {
                rq.citizenshipId = parseInt(rq.citizenshipId, 10)
            }
            return rq
        }

        get mapPoints() {
            let result = []
            flightsRuntimeStore.routes.forEach(({arrival, departure}) => {
                result = [...result, departure, arrival]
            })
            return result
        }

        get flexibleFlights() {
            return this.productRuntimeStore.flexibleFlights
        }

        get flexibleDates() {
            return this.productRuntimeStore.flexibleDates
        }

        get searchResponse() {
            return flightsStore.searchResponse
        }

        get filterValues() {
            return flightsStore.filters
        }

        get filteredOffers() {
            return flightsRuntimeStore.offers
        }

        get searchActive() {
            return flightsRuntimeStore.searchActive
        }

        get sortKey() {
            return flightsStore.sortFnName
        }

        set sortKey(sortKey) {
            flightsStore.SET_SORT(sortKey)
            flightsRuntimeStore.sort(this.filteredOffers)
        }

        get sortItems() {
            return [
                {
                    text: this.$t('sort.price_desc'),
                    value: 'priceAsc',
                },
                {
                    text: this.$t('sort.price_asc'),
                    value: 'priceDesc',
                },
                {
                    text: this.$t('sort.best'),
                    value: 'best',
                },
                {
                    text: this.$t('sort.duration_desc'),
                    value: 'durationDesc',
                },
                {
                    text: this.$t('sort.duration_asc'),
                    value: 'durationAsc',
                },
                {
                    text: this.$t('sort.airline'),
                    value: 'airline',
                },
                {
                    text: this.$t('sort.departure_time'),
                    value: 'departureTime',
                },
                {
                    text: this.$t('sort.arrival_time'),
                    value: 'arrivalTime',
                },
            ]
        }

        get currency() {
            return flightsStore.searchRequest.convertToCurrency
        }
    }
</script>
