<template>
    <div v-if="hasPrice">
        <div class="d-flex flex-nowrap" :class="{'justify-end': !mobile}">
            <div class="font-weight-medium" :class="{title: mobile, 'text--primary': mobile}">
                {{ $t('from') }} {{ minPriceRoomOffer.price | price }}
            </div>
            <corporate-policy-violation
                v-if="minPriceRoomOffer.corporatePolicyViolation && searchRoomsCount === 1"
                :policies="minPriceRoomOffer.corporatePolicy"
            />
        </div>
        <discount-label v-if="searchRoomsCount === 1" :price="minPriceRoomOffer.price" />
        <commission-amount v-if="searchRoomsCount === 1" :commission="minPriceRoomOffer.price.commission" />
        <rate-note :room="minPriceRoomOffer" />
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import CommissionAmount from '~/components/snippets/CommissionAmount'
    import {hotelsRuntimeStore} from '@/store'
    import RateNote from '~src/components/snippets/RateNote'

    @Component({
        components: {
            DiscountLabel,
            CommissionAmount,
            CorporatePolicyViolation: () => import('~/components/snippets/CorporatePolicyViolation'),
            RateNote,
        },
    })
    export default class HotelOfferPriceBlock extends Vue {
        @Prop({required: true}) offer
        @Prop({default: false, type: Boolean}) mobile
        @Prop({required: true}) searchRequest

        get minPriceRoomOffer() {
            return hotelsRuntimeStore.minPriceRoomOffer(this.offer)
        }

        get hasPrice() {
            return !!this.offer.rooms[0].price
        }

        get searchRoomsCount() {
            return this.searchRequest.rooms.length
        }
    }
</script>

<style scoped></style>
