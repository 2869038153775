var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"hide-overlay":"","width":"500"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[(_vm.isLoading)?_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v("\n            "+_vm._s(_vm.$t('stand_by'))+"\n            "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1):_c('v-card',[_c('v-icon',{staticClass:"v-card-close",on:{"click":function($event){_vm.modal = false}}},[_vm._v("\n            mdi-close\n        ")]),_vm._v(" "),_c('v-card-title',{staticClass:"title font-weight-light"},[_vm._v("\n            "+_vm._s(_vm.$t('cancellation'))+"\n        ")]),_vm._v(" "),_c('v-card-text',{staticClass:"text-center"},[(!_vm.cancellationAllowed)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("\n                mdi-exclamation-thick\n            ")]):_vm._e(),_vm._v(" "),(_vm.error || !_vm.cancelPrice)?_c('div',{staticClass:"error--text"},[_vm._v("\n                "+_vm._s(_vm.$t('cancelInfoMessage.error'))+"\n            ")]):(_vm.service.customerPaymentStatus === 'PARTIAL_PAID')?_c('div',{staticClass:"error--text"},[_vm._v("\n                "+_vm._s(_vm.$t('cancelInfoMessage.contact_to_agency', {
                        email: _vm.company.email,
                        phone: _vm.company.phones.join(','),
                    }))+"\n            ")]):(_vm.cancelPrice.amount >= _vm.servicePrice.amount)?_c('div',{staticClass:"error--text"},[_vm._v("\n                "+_vm._s(_vm.$t('cancelInfoMessage.not_refundable'))+"\n            ")]):(_vm.cancelPrice.amount === 0)?[_c('div',{staticClass:"success--text"},[_vm._v("\n                    "+_vm._s(_vm.$t('cancelInfoMessage.cancellation_free'))+"\n                ")]),_vm._v(" "),(!_vm.isNotPaid)?[_vm._v("\n                    "+_vm._s(_vm.$t('cancelInfoMessage.amount_paid', {
                            amount: _vm.$options.filters.priceFormat(_vm.servicePrice.amount, _vm.servicePrice.currency),
                        }))+"\n                ")]:_vm._e()]:[(_vm.isNotPaid)?[_c('div',{staticClass:"error--text"},[_vm._v("\n                        "+_vm._s(_vm.$t('cancelInfoMessage.cancellation_fee', {
                                fee: _vm.$options.filters.priceFormat(_vm.cancelPrice.amount, _vm.cancelPrice.currency),
                            }))+"\n                    ")]),_vm._v("\n                    "+_vm._s(_vm.$t('cancelInfoMessage.contact_to_agency', {
                            email: _vm.company.email,
                            phone: _vm.company.phones.join(','),
                        }))+"\n                ")]:[_c('div',{staticClass:"error--text"},[_vm._v("\n                        "+_vm._s(_vm.$t('cancelInfoMessage.cancellation_fee', {
                                fee: _vm.$options.filters.priceFormat(_vm.cancelPrice.amount, _vm.cancelPrice.currency),
                            }))+"\n                    ")]),_vm._v("\n                    "+_vm._s(_vm.$t('cancelInfoMessage.amount_paid', {
                            amount: _vm.$options.filters.priceFormat(_vm.servicePrice.amount, _vm.servicePrice.currency),
                        }))+"\n                    "),_c('br'),_vm._v("\n                    "+_vm._s(_vm.$t('cancelInfoMessage.refund_difference', {
                            difference: _vm.$options.filters.priceFormat(
                                _vm.servicePrice.amount - _vm.cancelPrice.amount,
                                _vm.servicePrice.currency
                            ),
                        }))+"\n                ")]]],2),_vm._v(" "),_c('v-card-actions',{staticClass:"justify-center"},[(_vm.cancellationAllowed)?_c('v-btn',{staticClass:"warning",on:{"click":_vm.cancel}},[_vm._v("\n                "+_vm._s(_vm.$t('confirm_cancellation'))+"\n            ")]):_vm._e(),_vm._v(" "),_c('v-btn',{on:{"click":function($event){_vm.modal = false}}},[_vm._v("\n                "+_vm._s(_vm.$t('close'))+"\n            ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }