<template>
    <v-card v-if="services.length" outlined>
        <v-card-title class="title font-weight-light">
            {{ $t('facilities') }}
        </v-card-title>
        <v-card-text>
            <v-row dense>
                <v-col v-for="(service, index) in services" :key="index" cols="6" sm="3" class="text-capitalize">
                    <hotel-info-services-label :service="service" />
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import {hotelsRuntimeStore} from '~/store'
    import HotelInfoServicesLabel from '~src/components/hotels/snippets/hotelInfoServicesLabel.src'

    @Component({components: {HotelInfoServicesLabel}})
    export default class HotelInfoServices extends Vue {
        @Prop({default: null}) servicesProp

        get services() {
            return (
                this.servicesProp ||
                (hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo.services
                    ? hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo.services.service.filter(
                          service => service.active
                      )
                    : [])
            )
        }
    }
</script>
