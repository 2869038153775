<template>
    <v-dialog v-model="modal" hide-overlay width="600" content-class="overflow--visible">
        <v-card v-if="reportParametersLoading" color="primary" dark>
            <v-card-text>
                {{ $t('loading') }}
                <v-progress-linear indeterminate color="white" class="mb-0" />
            </v-card-text>
        </v-card>
        <v-card v-else>
            <v-icon class="v-card-close" @click="modal = false">
                mdi-close
            </v-icon>
            <v-card-title class="title font-weight-light">
                {{ report.reportName }}
            </v-card-title>
            <v-card-text>
                <report-parameters-form v-model="reportParametersValid" :_params.sync="reportParameters" />
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn
                    :loading="reportLoading['PDF']"
                    :disabled="reportLoading['PDF'] || !reportParametersValid"
                    class="primary"
                    @click="download('PDF')"
                >
                    PDF
                    <v-icon right dark>
                        mdi-file-pdf
                    </v-icon>
                </v-btn>
                <v-btn
                    :loading="reportLoading['Excel']"
                    :disabled="reportLoading['Excel'] || !reportParametersValid"
                    class="primary"
                    @click="download('Excel')"
                >
                    Excel
                    <v-icon right dark>
                        mdi-file-excel
                    </v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import ReportParametersForm from '@/components/account/reports/ReportParametersForm'
    @Component({
        components: {ReportParametersForm},
    })
    export default class ReportModal extends Vue {
        modal = false
        report = {}
        reportParameters = {}
        reportParametersLoading = false
        reportParametersValid = true
        reportLoading = {
            Excel: false,
            PDF: false,
        }

        async loadReportParameters(report) {
            this.report = report
            this.reportParametersLoading = true
            this.modal = true
            try {
                this.reportParameters = (
                    await this.$api.reportParameters.get({reportId: report.reportId})
                ).reportParameters
                this.reportParametersLoading = false
            } catch (e) {
                this.$toast.error(this.$t('error_message.load_error'))
                this.modal = false
            }
        }

        async download(fileType) {
            this.reportLoading[fileType] = true
            try {
                await this.$api.report.post({
                    reportId: this.report.reportId,
                    fileType,
                    reportParameters: this.reportParameters,
                })
            } catch (e) {
                this.$toast.error(this.$t('error_message.load_error'))
            } finally {
                this.reportLoading[fileType] = false
            }
        }
    }
</script>
