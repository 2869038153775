<script>
    import {Component} from 'nuxt-property-decorator'
    import {flightsRuntimeStore, flightsStore} from '~/store'
    import FlightsPage from '@/pages/flights/FlightsPage'

    @Component
    export default class FlightsAvailabilityPage extends FlightsPage {
        loaded = false

        async fetch() {
            flightsRuntimeStore.SET_SEARCH_TYPE(this.$route.name === 'flights' ? 'price' : 'schedule')
        }

        async load() {
            await this.$store.restored
            await this.loadRuntimeData()
            this.loaded = true
            await this.search()
            this.filter()
        }

        async search() {
            await this.productRuntimeStore.checkAvailability(flightsStore.availabilityRequest)
        }

        isOffersExpired() {
            return false
        }

        changeFilter(data) {
            flightsStore.SET_AVAILABLE_OFFER_FILTER(data)
            flightsRuntimeStore.filter({
                filters: this.filterValues,
                offers: this.searchResponse.offers,
            })
        }

        resetFilters() {
            flightsStore.RESET_AVAILABLE_OFFER_FILTERS()
            flightsRuntimeStore.sort(this.searchResponse.offers)
        }

        get searchRequest() {
            return flightsStore.scheduleSearchRequest
        }

        get searchResponse() {
            return flightsStore.availabilityResponse
        }

        get filterValues() {
            return flightsStore.availableOfferFilters
        }

        get searchActive() {
            return flightsRuntimeStore.checkAvailabilityActive
        }

        get currency() {
            return flightsStore.scheduleSearchRequest.convertToCurrency
        }
    }
</script>
