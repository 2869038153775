<script>
    import {Component} from 'vue-property-decorator'
    import CityAutocomplete from '~/components/search/forms/CityAutocomplete'
    import AirportsSearchMixin from '~/mixins/AirportsSearchMixin'

    @Component({
        mixins: [AirportsSearchMixin],
    })
    export default class CityAirportAutocomplete extends CityAutocomplete {
        get items() {
            return this.entries.map(entry => {
                const label =
                    entry.type === 'city'
                        ? `${entry.cityName}, ${entry.countryName}`
                        : `${entry.name}, ${entry.cityName}, ${entry.countryName}`
                return Object.assign({}, entry, {label})
            })
        }

        async initSearch(val) {
            if (this.loading || (this.labels.length > 0 && this.labels.indexOf(val) !== -1) || !val) return
            this.loading = true
            try {
                const rs = await this.$api.airports.get({
                    pattern: val.trim(),
                    limit: 10,
                    orderBy: 'POPULARITY',
                })
                const cityGroups = rs.reduce((cityGroups, location) => {
                    let cityGroup = cityGroups.find(cityGroup => cityGroup.city.id === location.cityId)
                    if (!cityGroup) {
                        const city = location.type === 'city' ? location : {id: location.cityId}
                        cityGroup = {
                            city,
                            airports: [],
                        }
                        cityGroups.push(cityGroup)
                    }
                    if (location.type === 'city') {
                        cityGroup.city = location
                    } else {
                        cityGroup.airports.push(location)
                    }
                    return cityGroups
                }, [])
                this.entries = cityGroups.reduce((entries, cityGroup) => {
                    if (cityGroup.city.cityId) {
                        entries.push(cityGroup.city)
                    }
                    return [...entries, ...cityGroup.airports]
                }, [])
            } finally {
                this.loading = false
            }
        }

        get airportIcon() {
            return 'mdi-airplane-takeoff'
        }

        icon(type) {
            switch (type) {
                case 'airport':
                    return this.airportIcon
                default:
                    return this.locationIcon
            }
        }
    }
</script>
