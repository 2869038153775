<template>
    <tour-booking-page-layout v-if="loaded" :search-request="searchRequest" :offer="offer">
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="refreshExpiredOffers" />
            <tour-breadcrumbs />
        </template>
        <template v-slot:tourists="{options}">
            <component
                :is="hasFlightOffers ? 'flight-tourist-form' : 'tourist-form'"
                :disabled="options.disabled"
                :index="options.index"
                :offer="hasFlightOffers ? firstFlightOffer : null"
            />
        </template>
        <template v-slot:sidebar="{bookingKey}">
            <tour-search-summary search-disabled :search-request="searchRequest" />
            <tour-order-summary :offer="offer" :prebooked="!!bookingKey" />
        </template>
    </tour-booking-page-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import TourBreadcrumbs from '@/components/tours/TourBreadcrumbs'
    import TourBookingPageLayout from '@/components/tours/booking/TourBookingPageLayout'
    import {runtimeStore, toursRuntimeStore, toursStore} from '@/utils/store-accessor'
    import TouristForm from '@/components/booking/forms/TouristForm'
    import FlightTouristForm from '@/components/booking/forms/FlightTouristForm'
    import TourSearchSummary from '@/components/tours/search/TourSearchSummary'
    import TourOrderSummary from '@/components/tours/booking/TourOrderSummary'
    import TourPageMixin from '@/components/tours/mixins/TourPageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import {UTCToDate} from '@/utils/helpers'

    @Component({
        components: {
            ExpiredOffersSnackbar,
            TourOrderSummary,
            TourSearchSummary,
            TourBookingPageLayout,
            TourBreadcrumbs,
            TouristForm,
            FlightTouristForm,
        },
    })
    export default class TourBookingPage extends mixins(TourPageMixin) {
        loaded = false

        validate({query}) {
            return query.offerKey
        }

        async mounted() {
            await this.$store.restored
            await Promise.all([
                this.loadRuntimeData(),
                //TODO Not work in API
                //runtimeStore.loadTouristBookingFields(this.$route.query.offerKey),
            ])
            this.loaded = true
        }

        isOffersExpired() {
            return (
                this.$dateFns.isAfter(new Date(), UTCToDate(this.$dateFns.parseISO(this.offer.expirationTime))) &&
                !runtimeStore.bookingActive &&
                !toursStore.prepareBookResponse.bookingKey
            )
        }

        refreshExpiredOffers() {
            const {
                packageTourId,
                departureCityId,
                startDateFrom,
                startDateTo,
                durationFrom,
                adults,
                childrenAges,
            } = this.searchRequest
            this.$router.push({
                name: 'tour',
                query: {
                    packageTourId,
                    locationId: departureCityId,
                    startDateFrom,
                    startDateTo,
                    duration: durationFrom,
                    adults,
                    childrenAges,
                },
            })
        }

        get hasFlightOffers() {
            return toursRuntimeStore.hasFlightOffers(this.offer)
        }

        get firstFlightOffer() {
            return toursRuntimeStore.firstFlightOffer(this.offer)
        }

        get packageTourId() {
            return this.searchRequest.packageTourId
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get basketItem() {
            return toursStore.basketItem(this.$route.query.offerKey)
        }

        get offer() {
            return this.basketItem.offer
        }
    }
</script>
