<template>
    <v-card data-cy="hotel-room-offers">
        <v-card-title v-if="offer" class="title font-weight-light">
            {{ $t('available_room_list') }}
        </v-card-title>
        <v-card-title v-else-if="!isSearchActive && loaded" class="title font-weight-light">
            {{ $t('no_available_rooms') }}
        </v-card-title>
        <hotel-info-room-offers
            :offer="offer"
            :_expanded.sync="expanded"
            :_selected-offer-keys.sync="selectedOfferKeys"
            :_can-book.sync="canBook"
            :search-rooms-count="searchRoomsCount"
            :full-card="false"
            :hotel-info="hotelInfo"
            @more-rooms="showAllRooms"
        >
            <template v-slot:price="{room}">
                <slot name="price" :room="room" />
            </template>
        </hotel-info-room-offers>
        <template v-if="isSearchActive && (expanded === undefined || !offer)">
            <v-card-title v-if="!offer" class="title font-weight-light">
                {{ $t('loading_room_offers') }}...
            </v-card-title>
            <v-skeleton-loader transition="fade-transition" height="100" type="list-item-three-line" />
        </template>
        <div v-if="searchRoomsCount > 1 && offer">
            <v-card-actions class="flex-column">
                <hotel-multiple-room-select-block
                    :search-request="searchRequest"
                    :can-book="canBook"
                    :selected-offer-keys="selectedOfferKeys"
                    :offer="offer"
                />
            </v-card-actions>
        </div>
        <conditions-modal>
            <template v-slot:info>
                <hotel-conditions-modal-info />
            </template>
            <template v-slot:hotelPriceNote>
                <hotel-price-note :search-request="searchRequest" />
            </template>
        </conditions-modal>
        <hotel-room-info-modal />
        <hotel-meal-type-info-modal />
        <additional-fees-modal />
    </v-card>
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator'
    import {hotelsStore, hotelsRuntimeStore} from '@/store'
    import ConditionsModal from '~/components/modals/ConditionsModal'
    import HotelInfoRoomOffers from '~/components/info/offers/HotelInfoRoomOffers'
    import HotelRoomInfoModal from '~/components/modals/HotelRoomInfoModal'
    import {isEqual} from '@/utils/helpers'
    import HotelPriceNote from '@/components/hotels/snippets/HotelPriceNote'
    import HotelMealTypeInfoModal from '@/components/hotels/modals/HotelMealTypeInfoModal'
    import HotelConditionsModalInfo from '@/components/hotels/snippets/HotelConditionsModalInfo'
    import HotelMultipleRoomSelectBlock from '~src/components/hotels/snippets/hotelMultipleRoomSelectBlock.src'
    import AdditionalFeesModal from '~src/components/modals/AdditionalFeesModal'

    @Component({
        components: {
            AdditionalFeesModal,
            HotelMultipleRoomSelectBlock,
            HotelConditionsModalInfo,
            HotelMealTypeInfoModal,
            HotelPriceNote,
            ConditionsModal,
            HotelInfoRoomOffers,
            HotelRoomInfoModal,
        },
    })
    export default class HotelInfoRoomOffersWrapper extends Vue {
        @Prop({default: () => {}}) searchRequest
        @Prop({default: null}) offerProp

        expanded = []
        selectedOfferKeys = []
        loaded = false
        canBook = false
        cachedOffers = []

        async mounted() {
            await this.$store.restored
            this.cachedOffers = await this.$localForage.getItem('hotels')
            this.loaded = true
            for (let roomIndex = 1; roomIndex <= this.searchRoomsCount; roomIndex++) {
                this.$set(this.expanded, roomIndex, 5)
            }
        }

        showAllRooms(roomGroupIndex) {
            this.$set(this.expanded, roomGroupIndex, this.expanded[roomGroupIndex] + 5)
        }

        get isSearchActive() {
            return hotelsRuntimeStore.singleHotelSearchActive
        }

        //TODO Need to check and refactoring
        get offer() {
            if (this.offerProp) {
                return this.offerProp
            }
            if (hotelsStore.singleHotelSearchResponse.offers.length) {
                return hotelsStore.singleHotelSearchResponse.offers[0]
            } else {
                const matchesSearchRequests = (searchRequest, singleHotelSearchRequest) => {
                    if (!singleHotelSearchRequest) return false
                    let matches = Object.keys(searchRequest)
                        .filter(key => !['partialResponse', 'rooms'].includes(key))
                        .reduce((matches, key) => searchRequest[key] === singleHotelSearchRequest[key] && matches, true)
                    searchRequest.rooms.reduce(
                        (matches, room) => room === singleHotelSearchRequest.room && matches,
                        matches
                    )
                    return matches
                }
                if (
                    !isEqual(hotelsStore.searchRequest, this.searchRequest) ||
                    !matchesSearchRequests(hotelsStore.searchRequest, hotelsStore.singleHotelSearchRequest)
                )
                    return null
                const {supplierCode, cityCode, hotelCode} = this.$route.query
                const hotelOffer = this.cachedOffers.find(
                    offer =>
                        offer.supplierCode === supplierCode &&
                        offer.cityCode === cityCode &&
                        offer.hotelCode === hotelCode
                )
                if (hotelOffer && hotelsRuntimeStore.needUpdateRooms(hotelOffer)) {
                    return null
                }
                return hotelOffer
            }
        }

        get searchRoomsCount() {
            return this.searchRequest && this.searchRequest.rooms ? this.searchRequest.rooms.length : 1
        }

        get hotelInfo() {
            return hotelsRuntimeStore.hotelInfoResponse
        }
    }
</script>
