<template>
    <v-form ref="form" class="form justify-center d-flex">
        <v-card max-width="100%" width="500px" class="pa-1 pa-md-3 mt-5">
            <h2>{{ $t('save_new_password') }}</h2>
            <v-text-field
                v-model="password"
                outlined
                :label="$t('password')"
                dense
                class="my-3"
                :rules="[requiredRule('password')]"
                type="password"
                autocomplete="new-password"
                @change="passwordConfirm = null"
            />
            <v-text-field
                v-model="passwordConfirm"
                outlined
                :label="$t('password_confirm')"
                dense
                class="my-3"
                :rules="passwordConfirmationRules"
                type="password"
                autocomplete="new-password"
            />
            <v-card-actions class="px-0">
                <v-btn color="primary" :block="$breakpoint.smAndDown" @click="send">
                    {{ $t('submit') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import {authStore} from '~/store'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'

    @Component
    export default class RestorePasswordPage extends mixins(FormValidationRulesMixin) {
        token = null
        id = null
        b2b_id = null
        password = null
        passwordConfirm = null

        mounted() {
            this.token = this.$route.query.reset_token
            this.id = this.$route.query.crm_person
            this.b2b_id = this.$route.query.user
        }

        async send() {
            const form = this.$refs.form
            if (form.validate()) {
                const rq = {
                    token: this.token,
                    id: this.id || this.b2b_id,
                    newPassword: this.password,
                }
                try {
                    //TODO Need to add resetB2BUserPasswordWithApiKey
                    const rs = await this.$api.resetPassword.post(rq)
                    authStore.authUser(rs)
                    await this.$router.push({name: 'home'})
                } catch (e) {
                    this.$toast.warning(e.msg || e.errors[0].message)
                }
            }
        }

        get passwordConfirmationRules() {
            return [
                () =>
                    this.password === this.passwordConfirm ||
                    `${this.$t('password_confirm')} ${this.$t('validation.match')}`,
                this.requiredRule('password_confirm'),
            ]
        }
    }
</script>

<style lang="scss">
    .full {
        min-width: 100vw;
        min-height: 80vh;
    }

    .form {
        width: 100%;
    }
</style>
