<template>
    <v-card class="my-2" :class="{'elevation-0': !fullCard}">
        <v-list-item three-line class="pl-0">
            <v-list-item-avatar v-if="fullCard" class="my-0" :size="!$breakpoint.smAndDown ? '125px' : '25%'" tile>
                <v-img
                    tile
                    class="thumb"
                    :src="mainImage.url | imageUrl(200)"
                    :alt="offer.info.description"
                    lazy-src="/placeholder.png"
                    @error="onImgError"
                >
                    <template v-slot:placeholder>
                        <v-row
                            v-show="!imgError && mainImage.url"
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
            </v-list-item-avatar>
            <v-list-item-content class="align-self-start" :class="{'pl-2': !fullCard}">
                <v-list-item-title class="wrap-words">
                    <v-icon small left class="info--text" @click="offerInfo()">
                        mdi-information-outline
                    </v-icon>
                    <span class="caption">
                        {{ offer.info.description }}
                    </span>
                    <small v-if="offer.available" class="d-block caption mt-1">
                        <v-icon :title="$t('available')" left small class="success--text">
                            mdi-checkbox-marked-circle
                        </v-icon>
                        <span class="success--text" v-text="$t('available')" />
                    </small>
                    <small v-else class="d-block caption mt-1">
                        <v-icon :title="$t('on_request')" left small class="warning--text">
                            mdi-alert-circle
                        </v-icon>
                        <span class="warning--text" v-text="$t('on_request')" />
                    </small>
                    <discount-label :price="offer.price" class="d-inline-block" />
                </v-list-item-title>
                <span class="caption mt-1">
                    <conditions-label
                        v-if="offer"
                        :offer-key="offer.offerKey"
                        :price="offer.price"
                        @conditions="showConditionsInfo"
                    />
                </span>
                <span v-if="offer.info.tariffName" class="caption mt-1">
                    {{ offer.info.tariffName }}
                </span>
            </v-list-item-content>
            <v-list-item-action class="offer-price align-self-center">
                <v-btn
                    color="primary"
                    small
                    block
                    height="36px"
                    max-height="36px"
                    :loading="loading"
                    :disabled="loading"
                    @click="selectForBooking"
                >
                    {{ offer.price | price }}
                </v-btn>
                <price-note :search-request="searchRequest" tourist-label="transfers.passenger" />
                <commission-amount :commission="offer.price.commission" />
            </v-list-item-action>
        </v-list-item>
    </v-card>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import DiscountLabel from '@/components/snippets/DiscountLabel'
    import TransferInfoModal from '@/components/transfers/info/TransferInfoModal'
    import ConditionsLabel from '~/components/snippets/ConditionsLabel'
    import {EventBus, SHOW_TRANSFER_CONDITIONS_INFO, SHOW_TRANSFER_INFO_MODAL} from '@/utils/event-bus'
    import {transfersStore, transfersRuntimeStore} from '~/store'
    import PriceNote from '@/components/snippets/PriceNote'

    @Component({
        components: {PriceNote, CommissionAmount, DiscountLabel, TransferInfoModal, ConditionsLabel},
    })
    export default class TransferOfferCard extends Vue {
        @Prop({required: true}) offer
        @Prop({default: true}) fullCard

        imgError = false
        info = {
            images: [],
        }
        loading = true

        async mounted() {
            try {
                this.info = await this.$api.transferInfo.get({offerKey: this.offer.offerKey})
                if (this.fullCard && this.info.images) {
                    this.$emit('images', this.info.images)
                }
                // eslint-disable-next-line no-empty
            } catch (e) {
            } finally {
                this.loading = false
            }
        }

        onImgError() {
            if (this.mainImage.url) {
                this.imgError = true
            }
        }

        selectForBooking() {
            transfersStore.ADD_TO_BASKET({info: this.info, offerKey: this.offer.offerKey})
            this.$router.push({
                name: 'transferBooking',
                query: {offerKey: this.offer.offerKey},
            })
        }

        offerInfo() {
            EventBus.$emit(SHOW_TRANSFER_INFO_MODAL, {offer: this.offer, info: this.info})
        }

        showConditionsInfo() {
            EventBus.$emit(SHOW_TRANSFER_CONDITIONS_INFO, this.searchRequest, this.offer)
        }

        get mainImage() {
            return transfersRuntimeStore.offerMainImage(this.offer, this.info)
        }

        get searchRequest() {
            return transfersStore.searchRequest
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .offer-price {
        width: 20%;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
        .offer-price {
            width: 25%;
        }
    }

    .wrap-words {
        white-space: normal;
    }
</style>
