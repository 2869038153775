<template>
    <v-dialog v-model="modal" hide-overlay width="500">
        <v-card v-if="isLoading" color="primary" dark>
            <v-card-text>
                {{ $t('stand_by') }}
                <v-progress-linear indeterminate color="white" class="mb-0" />
            </v-card-text>
        </v-card>
        <v-card v-else>
            <v-icon class="v-card-close" @click="modal = false">
                mdi-close
            </v-icon>
            <v-card-title class="title font-weight-light">
                {{ $t('flights.issue_ticket') }}
            </v-card-title>
            <v-card-text class="text-center">
                <div v-if="error">
                    <p class="red--text mb-10">
                        {{ errorText }}
                    </p>
                </div>
                <div v-if="success">
                    <p class="mb-10">
                        {{ successText }}
                    </p>
                </div>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn @click="modal = false">
                    {{ $t('close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'

    @Component
    export default class IssueTicketModal extends Vue {
        modal = false
        isLoading = true
        error = false
        errorText = null
        success = false
        successText = null
        service = {}

        async show(service) {
            this.service = service
            this.error = false
            this.success = false
            this.isLoading = true
            this.modal = true
            try {
                const rs = await this.$api.issueFlightTicket.post({processId: service.processId})
                if (rs.object == 'Error') {
                    this.error = true
                    this.errorText = this.$t('flights.issue_ticket_error')
                } else {
                    this.error = false
                    this.success = true
                    this.successText = this.$t('flights.issue_ticket_success')
                }
            } catch (e) {
                this.error = true
                if (
                    e.response.data.errors[0].message == 'Ticket issuing is forbidden for the current payment method.'
                ) {
                    this.errorText = this.$t('flights.issue_ticket_forbidden_error')
                } else {
                    this.errorText = e.response.data.errors[0].message
                }
            } finally {
                this.isLoading = false
            }
            this.$emit('issuedTicket')
        }
    }
</script>
