<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'

    @Component
    export default class RateNote extends Vue {
        @Prop({required: true}) room
        @Prop({default: false, type: Boolean}) prependLabel

        render() {
            return null
        }
    }
</script>
