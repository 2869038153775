<template>
    <v-row no-gutters>
        <v-col :cols="12" md="12">
            <strong class="info-text title black--text font-weight-bold d-flex align-center mb-2">
                {{ service.serviceName }}
                <v-rating v-if="info && info.rating" v-model="info.rating" x-small readonly dense class="ml-2 d-flex" />
            </strong>
            <v-img
                v-if="image"
                tile
                class="thumb mb-2"
                :src="image | imageUrl(800)"
                lazy-src="/placeholder.png"
                height="250"
                :eager="true"
                @error="onImgError"
            >
                <template v-slot:placeholder>
                    <v-row v-show="!imgError && image" class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
            </v-img>
            <slot name="serviceInfo" />
        </v-col>
        <slot name="extraInfo" />
    </v-row>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'

    @Component()
    export default class ServiceInfoBase extends Vue {
        @Prop() service
        @Prop() info
        @Prop({default: null}) image

        imgError = false

        onImgError() {
            this.imgError = true
            return false
        }
    }
</script>
