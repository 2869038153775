<template>
    <div class="d-flex flex-nowrap align-center justify-end" style="width: 100%">
        <v-tooltip top>
            <template v-slot:activator="{on}">
                <v-btn
                    :block="$breakpoint.smAndDown"
                    :color="currentlySelected ? 'success' : 'primary'"
                    class="caption"
                    :loading="updateRoomsActive"
                    :disabled="updateRoomsActive === true"
                    v-on="on"
                    @click="selectProduct"
                >
                    <v-icon v-if="!packagesNotDeltaPrice && calcDelta(product.deltaPrice).amount > 0" left>
                        mdi-plus
                    </v-icon>
                    {{ packagesNotDeltaPrice ? product.notDeltaPrice : calcDelta(product.deltaPrice) | price }}
                    <v-icon v-if="currentlySelected" right>
                        mdi-check
                    </v-icon>
                </v-btn>
            </template>
            <span>
                {{ $t('packages.total_price') }}:
                <span>
                    {{ calcTotalPrice(calcDelta(product.deltaPrice)) | price }}
                </span>
            </span>
        </v-tooltip>
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import {packagesRuntimeStore, packagesStore, persistentStore, runtimeStore} from '~/store'
    import {EventBus, PACKAGE_HOTEL_SELECTED, PACKAGE_FLIGHT_SELECTED} from '~/utils/event-bus'

    @Component
    export default class OfferDeltaButton extends Vue {
        @Prop({default: null}) room
        @Prop({default: null}) flight

        selectProduct() {
            this.room ? this.selectHotel() : this.selectFlight()
        }

        async selectHotel() {
            const {offerKey} = this.room.groupedOffers[0]
            const {supplierCode, cityCode, hotelCode} = this.hotel
            if (packagesStore.needUpdateRooms(this.hotel) && !this.updateRoomsActive) {
                const hotelInfoRq = {supplierCode, cityCode, hotelCode}
                try {
                    await packagesRuntimeStore.updatePackageOffers({
                        ...hotelInfoRq,
                        ...{offerId: offerKey},
                    })
                    const newOfferKey = this.room.groupedOffers[0].offerKey
                    packagesStore.SET_SELECTED_HOTEL_KEY(newOfferKey)
                    EventBus.$emit(PACKAGE_HOTEL_SELECTED)
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            } else {
                packagesStore.SET_SELECTED_HOTEL_KEY(offerKey)
                EventBus.$emit(PACKAGE_HOTEL_SELECTED)
            }
        }

        selectFlight() {
            const {offerKey} = this.flight
            packagesStore.SET_SELECTED_FLIGHT_KEY(offerKey)
            EventBus.$emit(PACKAGE_FLIGHT_SELECTED)
        }

        calcDelta(deltaPrice) {
            const convertPrice = this.$options.filters.convertPrice,
                selectedProduct = this.room ? this.selectedRoom : this.selectedFlight
            return {
                amount: convertPrice(deltaPrice).amount - convertPrice(selectedProduct.deltaPrice).amount,
                currency: persistentStore.currency,
            }
        }

        calcTotalPrice(deltaPrice) {
            return {
                amount: deltaPrice.amount + this.totalPrice.amount,
                currency: persistentStore.currency,
            }
        }

        get selectedRoom() {
            return packagesStore.selectedRoom
        }

        get currentlySelected() {
            return this.room
                ? this.room.groupedOffers[0].offerKey === this.selectedRoom.groupedOffers[0].offerKey
                : this.flight.offerKey === this.selectedFlight.offerKey
        }

        get selectedFlight() {
            return packagesStore.selectedFlight
        }

        get product() {
            return this.room || this.flight
        }

        get selectedHotel() {
            return packagesStore.selectedHotel
        }

        get updateRoomsActive() {
            return this.room && packagesRuntimeStore.updateRoomsActive(this.hotel)
        }

        get totalPrice() {
            return packagesRuntimeStore.totalPrice(this.selectedRoom, this.selectedFlight, this.initialPrice)
        }

        get initialPrice() {
            return packagesStore.initialPrice
        }

        get hotel() {
            if (!this.room) return null
            return packagesStore.searchResponse.offers.hotels.find(
                ({rooms}) =>
                    rooms.findIndex(
                        ({groupedOffers}) => groupedOffers[0].offerKey === this.room.groupedOffers[0].offerKey
                    ) !== -1
            )
        }

        get packagesNotDeltaPrice() {
            return runtimeStore.config.packagesNotDeltaPrice
        }
    }
</script>
