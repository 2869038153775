<template>
    <v-dialog v-model="modal" width="800px" max-width="100%">
        <v-card v-if="info" width="800px" max-width="100%" class="pa-md-3">
            <v-icon class="v-card-close" @click="modal = false">
                mdi-close
            </v-icon>
            <v-card-text class="black--text">
                <h2>
                    {{ info.name }}
                </h2>
                <v-subheader v-if="info.location">
                    {{ info.location }}
                </v-subheader>
                <v-tabs
                    v-if="
                        (info.images && info.images.length) ||
                            (info.descriptions && info.descriptions.length) ||
                            (info.comments && info.comments.length) ||
                            info.fullDescription ||
                            info.shortDescription
                    "
                >
                    <v-tab>
                        {{ $t('description') }}
                    </v-tab>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-carousel
                                    height="170px"
                                    hide-delimiters
                                    show-arrows-on-hover
                                    :show-arrows="info.images && info.images.length > 1"
                                >
                                    <v-carousel-item v-for="(image, imageIndex) in info.images" :key="imageIndex">
                                        <v-img :src="image.url | imageUrl(300)" contain />
                                    </v-carousel-item>
                                </v-carousel>
                            </v-col>
                            <v-col cols="12" md="8">
                                <template v-if="offer.serviceDescription">
                                    <div class="font-weight-bold">{{ $t('description') }}</div>
                                    <div v-html="offer.serviceDescription" />
                                </template>
                                <div v-if="info.comments && info.comments.length">
                                    <strong> {{ $t('comments') }} </strong><br />
                                    <div v-for="(comment, commentIndex) in info.comments" :key="commentIndex">
                                        {{ comment.title }}: {{ comment.description }}
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import ActivityMap from '@/components/activities/search/ActivityMap'
    import {EventBus, SHOW_ACTIVITIES_INFO_MODAL} from '@/utils/event-bus'

    //TODO Need refactoring with other products
    @Component({
        components: {
            ActivityMap,
        },
    })
    export default class ActivityInfoModal extends Vue {
        info = null
        offer = null
        modal = false
        conditionsInfo = {}

        created() {
            EventBus.$on(SHOW_ACTIVITIES_INFO_MODAL, this.showModal)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_ACTIVITIES_INFO_MODAL, this.showModal)
        }

        showModal({offer, info}) {
            this.offer = offer
            this.info = info
            this.modal = true
        }

        get mapPoints() {
            return []
        }
    }
</script>
