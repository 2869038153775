<template>
    <search-form-layout :popup="popup" :title="title" @close-popup="$emit('close-popup')">
        <template v-slot:form>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col md="6" cols="12">
                        <city-autocomplete
                            v-model="city"
                            single-line
                            filled
                            :rules="[v => !!v || `${$t('destination')} ${$t('validation.required')}`]"
                            :default-cities="popularDestinations"
                        />
                    </v-col>
                    <v-col md="4" cols="12">
                        <vc-date-picker v-model="dateTimeData" :locale="$i18n.locale" :min-date="minDateTime">
                            <v-text-field
                                slot-scope="{inputProps, inputEvents}"
                                :class="$breakpoint.smAndDown ? 'caption' : ''"
                                :label="$t('date')"
                                readonly
                                single-line
                                filled
                                hide-details
                                background-color="white"
                                prepend-inner-icon="mdi-calendar"
                                v-bind="formatDatepickerInput(inputProps)"
                                v-on="inputEvents"
                            />
                        </vc-date-picker>
                    </v-col>
                    <v-col class="hidden-sm-and-down" md="2" cols="12">
                        <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                            {{ $t('search') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <additional-options-wrapper ref="addForm" :options="['citizenship']" />
            <v-row class="hidden-md-and-up">
                <v-col md="2" cols="12">
                    <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                        {{ $t('search') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </search-form-layout>
</template>

<script>
    import {Component, Prop, Mixins} from 'vue-property-decorator'
    import CityAutocomplete from '~/components/search/forms/CityAutocomplete'
    import {cmsStore, extraServicesStore, extraServicesRuntimeStore, authStore} from '@/store'
    import SearchFormLayout from '~/components/parts/SearchFormLayout'
    import TouristsSelectMenu from '~src/components/search/forms/touristsSelectMenu.src'
    import AdditionalSearchOptionsMixin from '@/mixins/AdditionalSearchOptionsMixin'
    import AdditionalOptionsWrapper from '~src/components/snippets/AdditionalSearchOptions/additionalOptionsWrapper.src'
    import {CHANGE_TAB_MOBILE_EVENT, CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'

    @Component({
        components: {
            TouristsSelectMenu,
            CityAutocomplete,
            SearchFormLayout,
            AdditionalOptionsWrapper,
        },
    })
    export default class ExtraServiceSearchForm extends Mixins(AdditionalSearchOptionsMixin) {
        @Prop({default: false}) popup
        @Prop({default: null}) variant
        @Prop({default: ''}) title

        city = {}
        startDate = null
        adults = 1
        childrenAges = []
        valid = false

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, this.reload)
        }

        async reload() {
            if (this.city.id) {
                try {
                    const {
                        cities: [city],
                    } = await this.$api.locations.get({id: this.city.id, limitCities: 1})
                    this.city = city
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
        }

        newQuery() {
            return {
                countryId: this.city.countryId,
                cityId: this.city.id,
                startDate: this.startDate,
                adults: this.adults,
                childrenAges: this.childrenAges,
            }
        }

        async search() {
            const query = this.newQuery()
            extraServicesRuntimeStore.SET_CITY(this.city)
            this.$emit('close-popup')
            if (this.variant) {
                Object.assign(query, {type: this.variant})
            }
            const name = 'extraServices'
            if (this.$route.name !== name) {
                extraServicesRuntimeStore.newSearch()
            }
            try {
                await this.$router.push({name, query})
            } catch (e) {
                await extraServicesRuntimeStore.search(extraServicesStore.searchRequest)
            }
            EventBus.$emit(CHANGE_TAB_MOBILE_EVENT, 1)
        }

        async mounted() {
            await this.$store.restored
            this.city = Object.keys(extraServicesRuntimeStore.city).length ? extraServicesRuntimeStore.city : null
            if (this.$dateFns.isAfter(this.$dateFns.parseISO(extraServicesStore.searchRequest.startDate), new Date())) {
                this.startDate = extraServicesStore.searchRequest.startDate
            } else {
                this.startDate = this.$dateFns.format(this.$dateFns.addDays(new Date(), 1))
            }
            //TODO Need refactoring with other products
            this.adults = authStore.user.isEmployee ? 1 : extraServicesStore.searchRequest.adults
            this.childrenAges = authStore.user.isEmployee ? [] : [...extraServicesStore.searchRequest.childrenAges]
        }

        //TODO Move to global
        get popularDestinations() {
            return cmsStore.popularDestinations
        }

        get dateTimeData() {
            return this.$dateFns.parseISO(this.startDate)
        }

        set dateTimeData(val) {
            this.startDate = val ? this.$dateFns.format(val) : null
        }

        get minDateTime() {
            return this.$dateFns.format(new Date())
        }

        formatDatepickerInput(props) {
            let result = ''
            if (this.startDate !== null) {
                result = this.$options.filters.dateFormat(this.startDate)
            }
            props.value = result
            return props
        }
    }
</script>
