<template>
    <div>
        <small v-if="offer.distance" class="font-weight-medium">
            <v-icon small color="accent">
                mdi-map-marker-distance
            </v-icon>
            {{ offer.distance }}
            {{ $t('km_from_center') }}
        </small>
        <small v-if="offer.district && offer.district !== 'other'" class="ms-6">
            <span class="font-weight-medium">{{ $t('area') }}:</span> {{ offer.district }}
        </small>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class HotelLocationLabel extends Vue {
        @Prop({required: true}) offer
    }
</script>
