<template>
    <v-row>
        <v-col cols="12" md="5" class="pt-10">
            <div v-if="loaded" class="elevation-1">
                <line-chart :labels="months" :chart-data="chartData" class="mb-2" />
                <line-chart :labels="months" :chart-data="chartDataQty" />
            </div>

            <template v-else>
                <v-skeleton-loader transition="fade-transition" type="image" class="mb-2" />
                <v-skeleton-loader transition="fade-transition" type="image" />
            </template>
        </v-col>
        <v-col cols="12" md="7" class="pt-6">
            <short-orders v-if="loaded" />
            <template v-else>
                <v-skeleton-loader transition="fade-transition" type="table" />
            </template>
            <div class="mt-8 elevation-1">
                <contracts :show-link="true" />
            </div>
        </v-col>
    </v-row>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import {supplierStore} from '@/store'
    import LineChart from '@/components/supplier/LineChart'
    import ShortOrders from '@/components/supplier/ShortOrders'
    import Contracts from '@/components/supplier/Contracts'

    @Component({
        components: {
            ShortOrders,
            LineChart,
            Contracts,
        },
        middleware: 'auth',
    })
    export default class SupplierPage extends Vue {
        productTabs = 0
        loaded = false
        chartData = null
        chartDataQty = null

        async mounted() {
            await supplierStore.loadOrders()

            this.chartData = {
                labels: this.months,
                datasets: [
                    {
                        label: this.$t('supplier.graph.sales_legend'),
                        data: this.calculateSales(),
                        fill: false,
                        borderColor: '#1976d2',
                        backgroundColor: '#1976d2',
                        borderWidth: 3,
                    },
                ],
            }

            this.chartDataQty = {
                labels: this.months,
                datasets: [
                    {
                        label: this.$t('supplier.graph.qty_legend'),
                        data: this.calculateQty(),
                        fill: false,
                        borderColor: 'orange',
                        backgroundColor: 'orange',
                        borderWidth: 3,
                    },
                ],
            }

            this.loaded = true
        }

        get products() {
            return {
                hotels: {
                    ico: 'mdi-office-building',
                    title: 'hotels',
                },
                transfers: {
                    ico: 'mdi-bus',
                    title: 'transfers.transfers',
                },
                activities: {
                    ico: 'mdi-lightning-bolt-outline',
                    title: 'activities.activities',
                },
                flights: {
                    ico: 'mdi-airplane',
                    title: 'flights.flights',
                },
                packages: {
                    ico: 'mdi-wallet-travel',
                    title: 'packages.packages',
                },
                extraServices: {
                    ico: 'mdi-star-box',
                    title: 'extraServices.extra_services',
                },
            }
        }

        get months() {
            let startDate = new Date()
            startDate.setMonth(startDate.getMonth() - 6)
            startDate = startDate.toISOString().split('T')[0]
            const endDate = new Date().toISOString().split('T')[0]

            const start = startDate.split('-')
            const end = endDate.split('-')
            const startYear = parseInt(start[0])
            const endYear = parseInt(end[0])
            let dates = []

            for (let i = startYear; i <= endYear; i++) {
                let endMonth = i != endYear ? 11 : parseInt(end[1]) - 1
                let startMon = i === startYear ? parseInt(start[1]) - 1 : 0
                for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
                    let month = j + 1
                    let displayMonth = month < 10 ? '0' + month : month
                    dates.push([i, displayMonth, '01'].join('-'))
                }
            }

            return dates
        }

        calculateSales() {
            let result = this.months.map(month => {
                let sum = 0
                let y = new Date(month).getFullYear()
                let m = new Date(month).getMonth()
                sum += supplierStore.orders
                    .map(order => {
                        let orderYear = new Date(order.created).getFullYear()
                        let orderMonth = new Date(order.created).getMonth()

                        return y == orderYear && m == orderMonth
                            ? parseFloat(order.orderPrices[0].orderPrices[0].amount)
                            : 0
                    })
                    .reduce((a, b) => {
                        return a + b
                    }, 0)

                return sum
            })

            return result
        }

        calculateQty() {
            let result = this.months.map(month => {
                let sum = 0
                let y = new Date(month).getFullYear()
                let m = new Date(month).getMonth()
                sum += supplierStore.orders
                    .map(order => {
                        let orderYear = new Date(order.created).getFullYear()
                        let orderMonth = new Date(order.created).getMonth()

                        return y == orderYear && m == orderMonth ? 1 : 0
                    })
                    .reduce((a, b) => {
                        return a + b
                    }, 0)

                return sum
            })

            return result
        }
    }
</script>

<style scoped></style>
