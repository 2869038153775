<template>
    <v-card class="main-form" outlined tile :width="width">
        <v-card-title v-if="$breakpoint.smAndDown && popup" class="d-flex justify-end">
            <v-icon color="white" @click="$emit('close-popup')">
                mdi-close
            </v-icon>
        </v-card-title>
        <v-card-title v-else-if="$breakpoint.smAndDown && !popup" class="white--text">
            <span v-if="activeProducts.length > 1">{{ $t(title) }}</span>
        </v-card-title>
        <v-card-text class="white--text">
            <slot name="form" />
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import {runtimeStore} from '@/utils/store-accessor'

    @Component
    export default class SearchFormLayout extends Vue {
        @Prop({default: false, type: Boolean}) popup
        @Prop() title
        @Prop() width

        get activeProducts() {
            return Object.values(runtimeStore.config.activeProducts)
        }
    }
</script>
