<template>
    <div v-scroll="onScroll" v-intersect.once="onIntersect" class="map">
        <div ref="map" />
        <v-toolbar v-if="!isMobile && !hideExpandButton" ref="toolbar" dense floating>
            <v-icon @click="toggle">
                {{ `mdi-chevron-${expanded ? 'right' : 'left'}` }}
            </v-icon>
        </v-toolbar>
    </div>
</template>

<script>
    import {Vue, mixins, Component, Watch, PropSync, Prop} from 'nuxt-property-decorator'
    import gmapsInit from '~/utils/gmaps'
    import MarkerClusterer from '@googlemaps/markerclustererplus'
    import {price} from '~/utils/filters'
    import HotelMapInfoWindow from '~/components/search/offers/HotelMapInfoWindow'
    import {
        EventBus,
        FOOTER_INTERSECT_EVENT,
        OFFER_ITEM_HOVER_EVENT,
        OFFER_ITEM_LEAVE_EVENT,
        SHOW_ON_MAP_EVENT,
    } from '~/utils/event-bus'
    import {hotelsRuntimeStore, runtimeStore} from '@/utils/store-accessor'
    import HotelMapMixin from '~src/components/hotels/mixins/hotelMapMixin.src'

    @Component
    export default class HotelMap extends mixins(HotelMapMixin) {
        @Prop({default: () => []}) offers
        @Prop({default: null}) parent
        @Prop({default: true}) linkToHotels
        @Prop({default: null}) center
        @Prop({default: null}) city
        @PropSync('_expanded', {default: false}) expanded

        hideExpandButton = false
        map = null
        geocoder = null
        markers = []
        google = null
        markerCluster = null
        isFooterIntersecting = false
        bounds = null

        toggle() {
            this.expanded = !this.expanded
            this.$nextTick(() => {
                //TODO When thrown exception???
                try {
                    this.setMapXPosition()
                    this.map.fitBounds(this.bounds)
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            })
        }

        yPos() {
            const y = !this.isMobile
                ? this.$parent.$parent.$el.parentElement.getBoundingClientRect().y
                : this.getMobileYPosition()
            return y > 0 ? y : 0
        }

        async initMap() {
            try {
                this.setMapXPosition()
                this.setMapYPosition()
                this.google = await gmapsInit()
                this.geocoder = new this.google.maps.Geocoder()
                const map = new this.google.maps.Map(this.$refs.map, this.getMapOptions())

                const controlDiv = document.createElement('div')
                if (this.$refs.toolbar) {
                    controlDiv.appendChild(this.$refs.toolbar.$el)
                }
                map.controls[this.google.maps.ControlPosition.LEFT_TOP].push(controlDiv)

                /*this.geocoder.geocode({address: hotelsStore.city.name}, (results, status) => {
                    if (status !== 'OK' || !results[0]) {
                        throw new Error(status);
                    }
                    map.setCenter(results[0].geometry.location);
                    map.fitBounds(results[0].geometry.viewport);
                });*/
                this.map = map
                EventBus.$on(FOOTER_INTERSECT_EVENT, isIntersecting => {
                    this.isFooterIntersecting = isIntersecting
                })
                if (this.city) {
                    this.map.setCenter({lat: this.city.latitude, lng: this.city.longitude})
                }
            } catch (error) {
                console.error(error)
            }
        }

        async mounted() {
            await this.$store.restored
            await this.load()
        }

        async load() {
            await this.initMap()
            this.setMarkers(this.offers)
            EventBus.$on(OFFER_ITEM_HOVER_EVENT, async offer => {
                const marker = this.markers.find(({data}) => data.hotelCode === offer.hotelCode)
                if (!marker || marker.infoWindow) return
                if (marker.prevMap === undefined) marker.prevMap = marker.getMap()
                if (!marker.prevMap) {
                    await marker.setMap(this.map)
                }
                marker.setVisible(true)
                //this.map.setZoom(16);
                //this.map.setCenter(marker.position);
                this.showInfoWindow(marker)
                //this.google.maps.event.trigger(marker, 'mouseover');
            })
            EventBus.$on(OFFER_ITEM_LEAVE_EVENT, async offer => {
                const marker = this.markers.find(({data}) => data.hotelCode === offer.hotelCode)
                if (!marker) return
                //this.map.setZoom(this.zoom);
                if (marker.infoWindow) {
                    this.google.maps.event.clearInstanceListeners(marker.infoWindow)
                    //await marker.infoWindow.close();
                    //await marker.infoWindow.close(null, marker);
                    await marker.infoWindow.close(this.map, marker)
                    marker.infoWindow = null
                }
                if (!marker.prevMap) {
                    marker.setVisible(false)
                    //marker.setMap(null);
                }
                //this.google.maps.event.trigger(marker, 'mouseout');
            })
            EventBus.$on(SHOW_ON_MAP_EVENT, offer => {
                let marker = this.markers.find(({data}) => data.hotelCode === offer.hotelCode)
                if (!marker) {
                    this.geocoder.geocode({address: offer.address}, (results, status) => {
                        if (status !== 'OK' || !results[0]) {
                            return
                        }
                        offer.coordinates = [results[0].geometry.location.lat(), results[0].geometry.location.lng()]
                        marker = this.addMarker(offer)
                        this.showInfoWindow(marker)
                        this.map.setCenter(marker.position)
                        this.map.setZoom(18)
                    })
                } else {
                    this.map.setCenter(marker.position)
                    this.map.setZoom(18)
                    this.showInfoWindow(marker)
                }
            })
        }

        setMapXPosition() {
            this.$el.style.width = !this.isMobile
                ? this.$el.parentElement.offsetWidth -
                  parseInt(window.getComputedStyle(this.$el.parentElement, null).getPropertyValue('padding-left'), 10) +
                  'px'
                : '100%'
        }

        setMapYPosition() {
            this.$el.style.top = this.yPos() + 'px'
            let footerMargin = 0
            if (this.isFooterIntersecting) {
                footerMargin = window.innerHeight - document.querySelector('footer').getBoundingClientRect().y
            }
            this.$el.style.height = window.innerHeight - this.yPos() - footerMargin + 'px'
        }

        onScroll() {
            this.setMapYPosition()
            //console.log(this.$el.parentElement.getBoundingClientRect().y);
            //console.log(e.target.documentElement.scrollTop);
        }

        @Watch('city')
        changeCity() {
            if (!this.map) return
            this.map.setCenter({lat: this.city.latitude, lng: this.city.longitude})
        }

        @Watch('offers.length')
        changeOffers() {
            //console.log(offers, !!this.google);
            if (!this.map) return
            this.setMarkers(this.offers)
        }

        setMarkers(offers) {
            this.markers.forEach(marker => marker.setMap(null))
            if (this.markerCluster) {
                this.markerCluster.clearMarkers()
            }
            this.markers = []
            if (!offers.length) return
            offers.forEach(offer => {
                this.addMarker(offer)
            })
            this.bounds = new this.google.maps.LatLngBounds()
            this.markers.forEach(marker => this.bounds.extend(marker.getPosition()))
            this.map.fitBounds(this.bounds)
            this.markerCluster = new MarkerClusterer(this.map, this.markers, {
                maxZoom: 18,
                minimumClusterSize: 4,
                clusterClass: 'cluster-marker',
                styles: [
                    {width: 116, height: 30},
                    {width: 116, height: 30},
                    {width: 116, height: 30},
                    {width: 116, height: 30},
                    {width: 116, height: 30},
                ],
            })
            this.markerCluster.setCalculator((markers, numStyles) => {
                const minPrice = this.minClusterPrice(markers)
                const count = markers.length
                let index = 0,
                    dv = count
                while (dv !== 0) {
                    dv = Math.round(dv / 10)
                    index++
                }
                index = Math.min(index, numStyles)
                const text = minPrice ? `${this.$t('from')} ${price(minPrice)}` : ''
                return {
                    text: `<span class="cluster-marker-count">${count}</span><span class="cluster-marker-price">${text}</span>`,
                    index,
                }
            })
        }

        minClusterPrice(markers) {
            let minPrice = markers[0].minPrice
            if (minPrice) {
                minPrice = markers.reduce((price, marker) => {
                    const minPriceRoomOfferPrice = marker.minPrice
                    return price.amount < minPriceRoomOfferPrice.amount ? price : minPriceRoomOfferPrice
                }, minPrice)
            }
            return minPrice
        }

        addMarker(offer) {
            let position
            if (offer.coordinates) {
                position = {
                    lat: offer.coordinates[0],
                    lng: offer.coordinates[1],
                }
            } else {
                return
                /*this.geocoder.geocode({address: offer.address}, (results, status) => {
                    if (status !== 'OK' || !results[0]) {
                        throw new Error(status);
                    }
                    this.addMarker(results[0].geometry.location);
                });*/
            }

            if (this.markers.findIndex(marker => marker.position === position) !== -1) return

            //if (this.markers.find(marker => position === marker.position)) return;
            const minPriceRoomOffer = hotelsRuntimeStore.minPriceRoomOffer(offer)
            const marker = new this.google.maps.Marker({
                position,
                icon: this.createMarkerIcon(offer),
                data: offer,
                minPrice:
                    minPriceRoomOffer.price ||
                    (runtimeStore.config.packagesNotDeltaPrice
                        ? minPriceRoomOffer.notDeltaPrice
                        : minPriceRoomOffer.deltaPrice),
            })

            marker.addListener('click', () => {
                if (!marker.infoWindow) {
                    this.showInfoWindow(marker)
                }
            })

            /*marker.addListener('click', () => {
                const {supplierCode, cityCode, hotelCode} = offer,
                    cityId = hotelsStore.city.id
                const routeData = this.$router.resolve({
                    name: 'hotel',
                    query: {supplierCode, cityCode, hotelCode, cityId},
                })
                window.open(routeData.href, '_blank')
            })*/

            /*marker.addListener('mouseover', () => {
                infoWindow.open(this.map, marker);
            });
            marker.addListener('mouseout', () => {
                infoWindow.close();
            });*/

            this.markers.push(marker)
            return marker
        }

        showInfoWindow(marker) {
            const markerWithInfoWindow = this.markers.find(marker => marker.infoWindow)
            if (markerWithInfoWindow) {
                markerWithInfoWindow.infoWindow.close()
                markerWithInfoWindow.infoWindow = null
            }
            const InfoWindowComponent = Vue.extend(HotelMapInfoWindow)
            const instance = new InfoWindowComponent({
                propsData: {
                    offer: marker.data,
                    withLink: this.linkToHotels,
                },
                parent: this,
            })
            instance.$mount()
            marker.infoWindow = new this.google.maps.InfoWindow({
                content: instance.$el,
            })
            this.google.maps.event.addListener(marker.infoWindow, 'closeclick', () => {
                marker.infoWindow.close()
                marker.infoWindow = null
            })
            marker.infoWindow.open(this.map, marker)
        }

        getMapOptions() {
            return {
                disableDefaultUI: true,
                gestureHandling: 'greedy',
                mapTypeControl: true,
                zoomControl: true,
                fullscreenControl: true,
                zoom: 8,
                restriction: {
                    latLngBounds: {north: 80, south: -70, west: -180, east: 180},
                    strictBounds: true,
                },
            }
        }

        get isMobile() {
            return this.$breakpoint.smAndDown
        }

        getMobileYPosition() {
            return !this.parent ? this.$parent.$refs.mobileTabs.$el.offsetHeight : 0
        }

        onIntersect() {}
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .map {
        position: fixed;

        @media #{map-get($display-breakpoints, 'xs-only')} {
            margin-left: -$container-padding-x;
        }

        > div {
            height: 100%;
        }
    }

    ::v-deep .cluster-marker {
        min-width: 116px;
        min-height: 30px;

        > * {
            &:active,
            &:focus {
                outline: none;
            }
        }

        > div {
            text-align: left !important;
        }

        display: flex;
        align-items: center;
        border-radius: 15px;
        white-space: nowrap;
        background: var(--v-primary-base);
        font-size: 12px;
        color: #fff;
        -webkit-transition: -webkit-transform 0.4s ease-in-out;
        transition: -webkit-transform 0.4s ease-in-out;
        -o-transition: transform 0.4s ease-in-out;

        &-count {
            display: inline-block;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            background: #fff;
            color: var(--v-primary-base);
            border-radius: 12px;
            padding: 5px;
            font-size: 11px;
            line-height: 12px;
            margin-left: 4px;
            min-width: 22px;
            min-height: 22px;
            text-align: center;
        }

        &-price {
            display: inline-block;
            background: transparent;
            border-radius: 14px;
            color: #fff;
            white-space: nowrap;
            padding: 0 8px;
            text-align: center;
        }

        &:hover {
            -webkit-box-shadow: 0 3px 7px 0 rgba(47, 112, 255, 0.32);
            box-shadow: 0 3px 7px 0 rgba(47, 112, 255, 0.32);
            background: var(--v-primary-base);

            .cluster-marker-count {
                color: var(--v-primary-base);
            }
        }
    }
</style>
