<template>
    <div>
        <v-icon v-if="room.mealTypeOTACode !== 14" class="info--text" left small @click="showInfo">
            mdi-silverware-fork-knife
        </v-icon>
        <v-icon v-else small left class="info--text" @click="showInfo">
            mdi-information-outline
        </v-icon>
        <span class="caption">{{ room.mealTypeName }}</span>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator'
    import {EventBus, SHOW_MEAL_TYPE_INFO_EVENT} from '@/utils/event-bus'

    @Component
    export default class HotelMealTypeLabel extends Vue {
        @Prop({required: true}) hotel
        @Prop({required: true}) room

        showInfo() {
            EventBus.$emit(SHOW_MEAL_TYPE_INFO_EVENT, this.hotel, this.room.mealTypeName)
        }
    }
</script>
