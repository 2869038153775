<template>
    <v-expansion-panels flat style="background-color: transparent" tile dark class="additional-options">
        <v-expansion-panel style="background-color: transparent">
            <v-expansion-panel-header class="pl-1 width-auto">
                {{ $t('extra_options') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
                <slot />
                <v-form ref="additional-form">
                    <v-row>
                        <v-col v-if="enabled('rating')" cols="12" md="2">
                            <select-option
                                name="category"
                                :items="ratingItems"
                                :label="$t('filterTitles.category')"
                                :single-line="singleLine"
                                :outlined="outlined"
                                autocomplete-disabled
                            />
                        </v-col>
                        <v-col v-if="enabled('meal')" cols="12" md="4" lg="3" xl="2">
                            <select-option
                                name="mealType"
                                :items="mealTypeItems"
                                :label="$t('filterTitles.mealTypes')"
                                multiple
                                :single-line="singleLine"
                                :outlined="outlined"
                                :clearable="false"
                                autocomplete-disabled
                            />
                        </v-col>
                        <v-col v-if="enabled('flight-class')" cols="12" md="3">
                            <select-option
                                name="flightClass"
                                :items="flightClasses"
                                :label="$t('flights.options.class')"
                                :single-line="singleLine"
                                :outlined="outlined"
                            />
                        </v-col>
                        <v-col v-if="enabled('airlines')" cols="12" md="3">
                            <select-option
                                name="airlineCodes"
                                :multiple="true"
                                :items="airlines"
                                :label="$t('flights.options.airlines')"
                                small-cheaps
                                :single-line="singleLine"
                                :outlined="outlined"
                            />
                        </v-col>
                        <template v-if="enabled('flight-options')">
                            <v-col cols="12" md="2">
                                <checkbox-option
                                    name="baggageOnly"
                                    :label="$t('flights.options.baggage_only')"
                                    :default-value="false"
                                />
                            </v-col>
                            <v-col cols="12" md="2" :class="$i18n.locale === 'ru' ? 'ref-up-on-small' : ''">
                                <checkbox-option
                                    name="refundableOnly"
                                    :label="$t('flights.options.refundable_only')"
                                    :default-value="false"
                                />
                            </v-col>
                            <v-col cols="12" md="2">
                                <checkbox-option
                                    name="directFlight"
                                    :label="$t('flights.options.direct_only')"
                                    :default-value="false"
                                />
                            </v-col>
                        </template>
                        <template v-if="enabled('transfer-type')">
                            <v-col cols="12" md="2">
                                <checkbox-option
                                    name="privateTransfer"
                                    :label="$t('transfers.private_transfer')"
                                    :default-value="true"
                                />
                            </v-col>
                            <v-col cols="12" md="2">
                                <checkbox-option
                                    name="sharedTransfer"
                                    :label="$t('transfers.shared_transfer')"
                                    :default-value="true"
                                />
                            </v-col>
                        </template>
                        <template v-if="enabled('activity-type')">
                            <v-col cols="12" md="2">
                                <checkbox-option
                                    name="privateActivity"
                                    :label="$t('activities.private_activity')"
                                    :default-value="true"
                                />
                            </v-col>
                            <v-col cols="12" md="2">
                                <checkbox-option
                                    name="sharedActivity"
                                    :label="$t('activities.shared_activity')"
                                    :default-value="true"
                                />
                            </v-col>
                            <v-col cols="12" md="2">
                                <checkbox-option
                                    name="hikingActivity"
                                    :label="$t('activities.hiking_activity')"
                                    :default-value="true"
                                />
                            </v-col>
                        </template>
                        <v-col v-if="enabled('citizenship')" cols="12" md="2">
                            <select-option
                                name="citizenshipId"
                                :items="citizenshipItems"
                                :label="$t('citizenship')"
                                :default-value="defaultCitizenshipId"
                                :single-line="singleLine"
                                :outlined="outlined"
                            />
                        </v-col>
                        <v-col v-if="enabled('promocode')" cols="12" md="2">
                            <text-option
                                name="promoCode"
                                :label="$t('promocode')"
                                :single-line="singleLine"
                                :outlined="outlined"
                            />
                        </v-col>
                        <v-col v-if="enabled('freeOnly')" cols="12" md="2">
                            <checkbox-option name="freeOnly" :label="$t('free_only')" :default-value="false" />
                        </v-col>
                        <v-col v-if="showCommissionEnabled && (isAgency || isTourOperator)" cols="12" md="2">
                            <checkbox-option
                                name="showCommission"
                                :label="$t('show_commission')"
                                :default-value="defaultShowCommissionOption"
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator'
    import {authStore, runtimeStore, flightsStore} from '~/store'
    import TextOption from '@/components/snippets/AdditionalSearchOptions/TextOption'
    import SelectOption from '@/components/snippets/AdditionalSearchOptions/SelectOption'
    import CheckboxOption from '@/components/snippets/AdditionalSearchOptions/CheckboxOption'

    @Component({
        components: {
            CheckboxOption,
            SelectOption,
            TextOption,
        },
    })
    export default class AdditionalOptionsWrapper extends Vue {
        @Prop({default: false, type: Boolean}) outlined
        @Prop({default: false}) singleLine
        @Prop({default: true}) showIcon
        @Prop({default: () => []}) options

        promocode = null
        ratingItems = []
        mealTypeItems = []
        flightClasses = []

        get citizenshipItems() {
            return runtimeStore.countries.map(({id, name}) => {
                return {
                    name,
                    value: id,
                }
            })
        }

        @Watch('locale')
        onLocaleChange() {
            this.setMealTypes()
            this.setRatings()
            this.getFlightClasses()
        }

        async mounted() {
            await this.$store.restored
            this.setMealTypes()
            this.setRatings()
            this.getFlightClasses()
            if (this.enabled('airlines')) {
                this.loadAirlines()
            }
        }

        enabled(val) {
            return this.options.indexOf(val) >= 0
        }

        loadAirlines() {
            flightsStore.loadAirlines()
        }

        getFlightClasses() {
            this.flightClasses = [
                {
                    name: this.$t('flights.classes.ECONOMY'),
                    value: 'ECONOMY',
                },
                {
                    name: this.$t('flights.classes.PREMIUM_ECONOMY'),
                    value: 'PREMIUM_ECONOMY',
                },
                {
                    name: this.$t('flights.classes.FIRST'),
                    value: 'FIRST',
                },
                {
                    name: this.$t('flights.classes.BUSINESS'),
                    value: 'BUSINESS',
                },
            ]
        }

        setMealTypes() {
            this.mealTypeItems = [
                {
                    name: this.$t('filters_mealTypes.RO'),
                    value: 'RO',
                },
                {
                    name: this.$t('filters_mealTypes.BB'),
                    value: 'BB',
                },
                {
                    name: this.$t('filters_mealTypes.HB'),
                    value: 'HB',
                },
                {
                    name: this.$t('filters_mealTypes.FB'),
                    value: 'FB',
                },
                {
                    name: this.$t('filters_mealTypes.BBT'),
                    value: 'BBT',
                },
                {
                    name: this.$t('filters_mealTypes.HBT'),
                    value: 'HBT',
                },
                {
                    name: this.$t('filters_mealTypes.FBT'),
                    value: 'FBT',
                },
                {
                    name: this.$t('filters_mealTypes.ALL'),
                    value: 'ALL',
                },
            ]
        }

        setRatings() {
            this.ratingItems = Array.from(Array(5).keys())
                .map(key => {
                    return {
                        name: this.$tc('filters_category', key + 1) + ' ' + this.$t('and_more'),
                        value: (key + 1).toString(),
                    }
                })
                .sort((a, b) => {
                    return b.value - a.value
                })
        }

        get locale() {
            return this.$i18n.locale
        }

        get defaultCitizenshipId() {
            return authStore.person?.citizenshipId || runtimeStore.config.defaultCitizenshipId
        }

        get showCommissionEnabled() {
            return runtimeStore.config.showCommissionOptionEnabled
        }

        get defaultShowCommissionOption() {
            return runtimeStore.config.showCommissionDefaultValue
        }

        get isAgency() {
            return authStore.isAgency
        }

        get isTourOperator() {
            return authStore.isTourOperator
        }

        get airlines() {
            return flightsStore.airlines.map(({codes, name}) => {
                return {
                    name: name[this.$i18n.locale] || name['en'],
                    value: codes.IATA,
                }
            })
        }
    }
</script>

<style scoped>
    >>> .v-expansion-panel-content__wrap {
        padding-left: 0;
        padding-right: 0;
    }

    @media (min-width: 1350px) and (max-width: 1450px) {
        .ref-up-on-small {
            margin-top: -8px;
        }
    }

    .additional-options form {
        margin-top: -20px;
    }
</style>
