<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import BookingPageBase from '~src/components/parts/bookingPageBase.src'
    import FlightStoreMixin from '@/mixins/FlightStoreMixin'
    import {flightsStore} from '@/store'

    @Component
    export default class FlightBookingPageLayout extends mixins(BookingPageBase, FlightStoreMixin) {
        prepareBookTourists(tourists) {
            const newTourists = BookingPageBase.options.methods.prepareBookTourists.call(this, tourists)
            return newTourists.map((tourist, index) => {
                const mealTypesSeatAllowance = this.mealTypesSeatAllowance[index],
                    frequentFlyerCard = this.frequentFlyerCard[index]
                return {
                    ...tourist,
                    ...(mealTypesSeatAllowance?.mealTypeCode && {mealTypeCode: mealTypesSeatAllowance.mealTypeCode}),
                    ...(mealTypesSeatAllowance?.seatAllocationCode && {
                        seatAllocationCode: mealTypesSeatAllowance.seatAllocationCode,
                    }),
                    ...(frequentFlyerCard && {
                        bonusCard: {
                            ...{number: frequentFlyerCard.number, airlineCode: frequentFlyerCard.airlineCode},
                        },
                    }),
                }
            })
        }

        get mealTypesSeatAllowance() {
            return flightsStore.mealTypesSeatAllowance
        }

        get frequentFlyerCard() {
            return flightsStore.frequentFlyerCard
        }

        get productSearchPageName() {
            return 'flights'
        }

        get productConfirmationPageName() {
            return 'flightConfirmation'
        }

        get productName() {
            return 'flight'
        }

        get productBookingPageName() {
            return 'flightBooking'
        }

        get corporatePolicyType() {
            return 'flight'
        }
    }
</script>
