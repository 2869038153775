<template>
    <v-card outlined tile>
        <v-card-title class="title ">
            {{ $t(`transfers.${pointType}_location`) }} - {{ $tc('hotel', 1) }}
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid">
                <v-autocomplete
                    v-model="hotelCode"
                    :items="hotels"
                    return-object
                    item-text="hotelName"
                    item-value="hotelCode"
                    :label="`${$t('transfers.transfer_hotel')}${!ownProduct ? '*' : ''}`"
                    :disabled="disabled"
                    :rules="[
                        v =>
                            !!v.hotelCode ||
                            ownProduct ||
                            `${this.$t('transfers.transfer_hotel')} ${this.$t('validation.required')}`,
                    ]"
                    clearable
                />
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Vue, Prop, PropSync} from 'vue-property-decorator'
    import {transfersRuntimeStore, transfersStore} from '@/utils/store-accessor'

    @Component
    export default class TransferHotelForm extends Vue {
        @Prop({required: true}) pointType
        @Prop({default: () => []}) hotels
        @PropSync('_valid', {default: false}) valid
        @Prop({default: false, type: Boolean}) disabled
        @Prop({required: true}) product

        getProp(prop) {
            return transfersStore.prepareBookRequest[this.pointType][prop]
        }

        setProp(prop, val) {
            transfersStore.SET_PREPARE_BOOK_LOCATION_PROP({prop, val, pointType: this.pointType})
        }

        get hotelCode() {
            return {
                hotelCode: this.getProp('hotelCode'),
                hotelName: this.getProp('hotelName'),
            }
        }

        set hotelCode(value) {
            if (value) {
                this.setProp('hotelCode', value.hotelCode)
                this.setProp('hotelName', value.hotelName)
            } else {
                this.setProp('hotelCode', null)
                this.setProp('hotelName', null)
            }
        }

        get ownProduct() {
            return transfersRuntimeStore.ownProduct(this.product)
        }
    }
</script>
