<template>
    <div v-show="selectedFilters.length" class="mb-2">
        <div class="selected_title">
            {{ $t('selected_filters') }}
        </div>
        <v-chip
            v-for="item in selectedFilters"
            :key="item.key"
            color="primary"
            close
            small
            class="mr-1"
            @click:close="deleteFilter(item.key)"
        >
            {{ item.label }}
        </v-chip>
    </div>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import CityAutocomplete from '~/components/search/forms/CityAutocomplete'
    import OrderCreateDaterange from '~/components/account/filters/OrderCreateDaterange'
    import OrderServiceDaterange from '~/components/account/filters/OrderServiceDaterange'
    import {persistentStore} from '@/utils/store-accessor'
    import {orderFilters} from '@/utils/store-blank-states'

    @Component({
        components: {
            CityAutocomplete,
            OrderCreateDaterange,
            OrderServiceDaterange,
        },
    })
    export default class OrderSelectedFilters extends Vue {
        get selectedFilters() {
            const selectedFilters = [],
                filters = persistentStore.orderFilters
            if (filters.active !== 'all') {
                selectedFilters.push({
                    key: 'active',
                    label: filters.active ? this.$t('future_trips') : this.$t('past_trips'),
                })
            }
            const setTextLabel = (key, label, translationKey) => {
                if (filters[key]) {
                    selectedFilters.push({
                        key,
                        label:
                            `${this.$t(label)}: ` +
                            (translationKey ? this.$t(`${translationKey}.${filters[key]}`) : filters[key]),
                    })
                }
            }
            const setArrayLabel = (key, label, translationKey) => {
                if (filters[key].length) {
                    selectedFilters.push({
                        key,
                        label:
                            `${this.$t(label)}: ` +
                            filters[key]
                                .map(value => this.$t(translationKey ? `${translationKey}.${value}` : value))
                                .join(', '),
                    })
                }
            }
            if (filters.filterServices) {
                selectedFilters.push({
                    key: 'filterServices',
                    label: this.$t('apply_filter_to_services'),
                })
            }
            setTextLabel('orderId', 'order_number')
            setTextLabel('serviceName', 'service_name')
            setTextLabel('travelerName', 'traveler_name')
            if (filters.city) {
                selectedFilters.push({
                    key: 'city',
                    label: filters.city.name,
                })
            }
            setTextLabel('createdDateFrom', 'create_date_from')
            setTextLabel('createdDateTo', 'create_date_to')
            setTextLabel('serviceStartDateFrom', 'start_date_from')
            setTextLabel('serviceStartDateTo', 'start_date_to')
            setTextLabel('serviceEndDateFrom', 'end_date_from')
            setTextLabel('serviceEndDateTo', 'end_date_to')
            setArrayLabel('serviceTypes', 'service_types')
            setArrayLabel('processStatuses', 'process_statuses', 'bookingStatus')
            setArrayLabel('customerPaymentStatuses', 'customer_payment_statuses', 'paymentStatus')
            setArrayLabel('customerPenalty', 'customer_penalty', 'penalty')
            setArrayLabel('supplierPaymentStatuses', 'supplier_payment_statuses', 'paymentStatus')
            setArrayLabel('supplierPenalty', 'supplier_penalty', 'penalty')
            if (filters.paymentMethod) {
                selectedFilters.push({
                    key: 'paymentMethod',
                    label: filters.paymentMethod.name,
                })
            }
            setTextLabel('customerCompany', 'customer_company')
            return selectedFilters
        }

        deleteFilter(key) {
            const filter = {}
            filter[key] = orderFilters()[key]
            persistentStore.SET_ORDER_FILTERS(Object.assign({}, persistentStore.orderFilters, filter))
            this.$emit('filter')
        }
    }
</script>
