<template>
    <div>
        <v-app-bar v-show="visible" dense flat class="header" height="70">
            <header-block />
            <div class="flex-grow-1" />
            <client-only>
                <div v-if="$breakpoint.mdAndUp" class="d-md-flex d-lg-flex align-center">
                    <template v-if="!['authorization', 'b2b-auth', 'restore-password'].includes($route.name)">
                        <account-menu />
                        <currency-switcher />
                    </template>
                    <language-switcher />
                </div>
            </client-only>
            <v-app-bar-nav-icon v-if="$breakpoint.smAndDown" @click="drawer = !drawer" />
        </v-app-bar>
        <navigation-drawer-mobile v-if="$breakpoint.smAndDown" v-model="drawer" />
    </div>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'
    import CurrencySwitcher from '~/components/CurrencySwitcher'
    import AccountMenu from '~src/components/account/accountMenu.src'
    import {EventBus, FULL_SCREEN_MODE} from '@/utils/event-bus'
    import NavigationDrawerMobile from '~src/components/snippets/navigationDrawerMobile.src'
    import LanguageSwitcher from '~/components/LanguageSwitcher'
    import HeaderBlock from '~src/components/snippets/headerBlock.src'

    @Component({
        components: {
            HeaderBlock,
            AccountMenu,
            LanguageSwitcher,
            CurrencySwitcher,
            NavigationDrawerMobile,
        },
    })
    export default class HeaderBar extends Vue {
        drawer = false
        visible = true

        created() {
            EventBus.$on(FULL_SCREEN_MODE, this.toggle)
        }

        beforeDestroy() {
            EventBus.$off(FULL_SCREEN_MODE, this.toggle)
        }

        toggle(fullScreenMode) {
            this.visible = !fullScreenMode
        }
    }
</script>

<style scoped lang="scss">
    .theme--light.v-app-bar.v-toolbar.v-sheet.header {
        background-color: #ffffff;
        border-bottom: 1px #eeeeee solid;
    }
</style>
