<template>
    <div>
        <div class="d-flex justify-space-between align-end">
            <h2 class="my-4">
                {{ title }}
            </h2>
            <v-btn
                v-if="!!company.id"
                nuxt
                :to="{name: 'companyPersons', query: {company: company.id}}"
                class="primary mb-4"
            >
                {{ $t('users') }}
            </v-btn>
        </div>
        <v-card v-if="company">
            <v-card-text class="text--primary">
                <v-form ref="form" v-model="valid">
                    <v-row no-gutters>
                        <v-col cols="12" md="9">
                            <v-row>
                                <v-col cols="12" md="3" class="title text--secondary">
                                    {{ $t('general_info') }}
                                </v-col>
                                <v-col cols="12" md="9">
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <v-text-field
                                                v-model="company.name"
                                                outlined
                                                dense
                                                :label="`${$t('company_name')}*`"
                                                :rules="[requiredRule('company_name')]"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-autocomplete
                                                v-model="company.type"
                                                outlined
                                                dense
                                                :items="mapedTypes"
                                                item-text="name"
                                                item-value="value"
                                                :label="`${$t('company_type')}*`"
                                                :rules="[requiredRule('company_type')]"
                                                :disabled="!!company.id"
                                            />
                                        </v-col>
                                        <v-col :cols="!!company.id ? 6 : 12" :md="company.id ? 2 : 4">
                                            <v-text-field
                                                v-model="company.alias"
                                                outlined
                                                dense
                                                :label="$t('company_alias')"
                                            />
                                        </v-col>
                                        <v-col v-if="!!company.id" cols="6" md="2">
                                            <v-text-field
                                                v-model="company.companyCode"
                                                readonly
                                                outlined
                                                dense
                                                :label="$t('company_code')"
                                                disabled
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-autocomplete
                                                v-model="company.countryId"
                                                outlined
                                                dense
                                                :items="countries"
                                                item-text="name"
                                                item-value="id"
                                                :label="`${$t('country')}*`"
                                                :rules="[requiredRule('country')]"
                                                @change="loadCities"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-autocomplete
                                                v-model="company.cityId"
                                                :items="cities"
                                                item-text="name"
                                                item-value="id"
                                                outlined
                                                dense
                                                :label="`${$t('city')}*`"
                                                :rules="[requiredRule('city')]"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-text-field
                                                v-model="company.region"
                                                outlined
                                                dense
                                                :label="`${$t('region')}`"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="9">
                                            <v-text-field
                                                v-model="company.address"
                                                outlined
                                                dense
                                                :label="`${$t('address')}`"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-text-field
                                                v-model="company.postalCode"
                                                outlined
                                                dense
                                                :label="`${$t('postal_code')}`"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="9">
                                            <v-text-field
                                                v-model="company.postAddress"
                                                outlined
                                                dense
                                                :label="`${$t('postal_address')}`"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="3" class="title text--secondary">
                                    {{ $t('contacts') }}
                                    <v-subheader class="px-0 font-italic">
                                        {{ $t('contact_person') }}
                                    </v-subheader>
                                </v-col>
                                <v-col cols="12" md="9">
                                    <v-row>
                                        <v-col cols="12" md="2">
                                            <v-select
                                                v-model="company.contactPerson.prefix"
                                                outlined
                                                dense
                                                :items="['Mr', 'Ms', 'Mrs']"
                                                :label="`${$t('salutation')}*`"
                                                :rules="[requiredRule('salutation')]"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="5">
                                            <v-text-field
                                                v-model="firstName"
                                                outlined
                                                dense
                                                :label="`${$t('first_name')}*`"
                                                :rules="[requiredRule('first_name')]"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="5">
                                            <v-text-field
                                                v-model="lastName"
                                                outlined
                                                dense
                                                :label="`${$t('last_name')}*`"
                                                :rules="[requiredRule('last_name')]"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <v-text-field
                                                v-model="company.phones[0]"
                                                outlined
                                                dense
                                                :label="`${$t('phone')} 1*`"
                                                :rules="[requiredRule('phone')]"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field
                                                v-model="company.phones[1]"
                                                outlined
                                                dense
                                                :label="`${$t('phone')} 2`"
                                            />
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field
                                                v-model="company.phones[2]"
                                                outlined
                                                dense
                                                :label="`${$t('phone')} 3`"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="company.fax" outlined dense :label="$t('fax')" />
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="company.url" outlined dense :label="$t('url')" />
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field
                                                v-model="company.email"
                                                outlined
                                                dense
                                                :label="`${$t('email')}*`"
                                                :rules="[requiredRule('email')]"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="3" class="title text--secondary">
                                    {{ $t('additional_info') }}
                                </v-col>
                                <v-col cols="12" md="9">
                                    <v-row>
                                        <v-col cols="12" md="8">
                                            <v-text-field
                                                v-model="company.officialCompanyName"
                                                outlined
                                                dense
                                                :label="`${$t('company_official_name')}`"
                                            />
                                        </v-col>
                                        <v-col cols="6" md="2">
                                            <v-text-field
                                                v-model="company.externalCode"
                                                outlined
                                                dense
                                                :label="$t('external_code')"
                                            />
                                        </v-col>
                                        <v-col cols="6" md="2">
                                            <v-text-field outlined dense :label="$t('company_kpp')" />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6" md="4">
                                            <v-text-field
                                                v-model="company.vatNumber"
                                                outlined
                                                dense
                                                :label="`${$t('vat_number')}*`"
                                                :rules="[requiredRule('vat_number')]"
                                            />
                                        </v-col>
                                        <v-col cols="6" md="4">
                                            <v-text-field
                                                v-model="company.iataNumber"
                                                outlined
                                                dense
                                                :label="$t('iata_number')"
                                            />
                                        </v-col>
                                        <v-col md="4">
                                            <v-text-field
                                                v-model="company.docexNumber"
                                                outlined
                                                dense
                                                :label="$t('docex_number')"
                                            />
                                        </v-col>
                                    </v-row>
                                    <!--<v-row>
                                        <v-col cols="12" md="12">
                                            <v-text-field
                                                v-model="company.taxRegistrationReason"
                                                outlined
                                                dense
                                                :label="`${$t('tax_registration_reason')}*`"
                                                :rules="[requiredRule('tax_registration_reason')]"
                                            />
                                        </v-col>
                                    </v-row>-->
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="3" class="title text--secondary">
                                    {{ $t('comment') }}
                                    <v-subheader class="px-0 font-italic">
                                        {{ $t('enter_comment') }}
                                    </v-subheader>
                                </v-col>
                                <v-col cols="12" md="9">
                                    <v-textarea v-model="company.comments" outlined />
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col v-if="company.id" cols="12" md="3" class="pl-2 order-first order-md-last">
                            <v-img :src="company.logo | imageUrl(650)" lazy-src="/placeholder.png" />
                            <v-btn v-if="false" block>
                                {{ $t('download') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions class="d-flex mb-2 pb-5 px-2">
                <v-btn
                    class="ml-auto primary"
                    :block="$breakpoint.smAndDown"
                    :loading="isLoading"
                    :disabled="isLoading || !valid"
                    @click="save"
                >
                    {{ $t('save_changes') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import {Component, Watch, Prop, Mixins} from 'vue-property-decorator'
    import {runtimeStore} from '~/store'
    import LanguageSwitcher from '@/components/LanguageSwitcher'
    import FormValidationRulesMixin from '@/mixins/FormValidationRulesMixin'

    @Component({})
    export default class CompanyProfile extends Mixins(FormValidationRulesMixin) {
        @Prop({default: null}) company

        types = ['EXTERNAL_SUPPLIER', 'INTERNAL_SUPPLIER', 'TOUR_OPERATOR', 'TOUR_AGENCY', 'CORPORATOR', 'GROUP']
        isLoading = false
        valid = false
        cities = []

        mounted() {
            if (this.company.id) {
                this.$refs.form.validate()
                this.loadCities(this.company.countryId)
            }
        }

        async save() {
            this.isLoading = true
            const data = this.mapToRequest()

            try {
                if (this.company.id) {
                    await this.$api.companies.put(this.company.id, data)
                } else {
                    await this.$api.companies.post(data)
                }
                this.$toast.success(this.$t('messages.success.company_saved'))
                if (!this.company.id) {
                    this.$router.back()
                }
            } catch (error) {
                this.$toast.error(this.$t('data_update_fail'))
            }
            this.isLoading = false
        }

        mapToRequest() {
            const locale = this.company.language ? this.company.language : 'en'
            const rq = {...this.company}
            rq.name = {}
            rq.name[locale] = this.company.name
            const {firstName, lastName, middleName} = this.company.contactPerson
            if (firstName || middleName || lastName) {
                if (firstName) {
                    if (typeof firstName === 'string') {
                        rq.contactPerson.firstName = {}
                        rq.contactPerson.firstName[locale] = firstName
                    } else {
                        rq.contactPerson.firstName = firstName
                    }
                }
                if (middleName) {
                    if (typeof middleName === 'string') {
                        rq.contactPerson.middleName = {}
                        rq.contactPerson.middleName[locale] = middleName
                    } else {
                        rq.contactPerson.middleName = middleName
                    }
                }
                if (lastName) {
                    if (typeof lastName === 'string') {
                        rq.contactPerson.lastName = {}
                        rq.contactPerson.lastName[locale] = lastName
                    } else {
                        rq.contactPerson.lastName = lastName
                    }
                }
            }
            rq.emails = [this.company.email]
            rq.officialCompanyName = {}
            rq.officialCompanyName[locale] = this.company.officialCompanyName
            rq.address = {}
            rq.address[locale] = this.company.address
            rq.postAddress = {}
            rq.postAddress[locale] = this.company.postAddress
            rq.comments = {}
            rq.comments[locale] = this.company.comments
            rq.region = {}
            rq.region[locale] = this.company.region
            rq.language = locale
            this.deleteFields(rq)
            return rq
        }

        deleteFields(rq) {
            delete rq.logo
            delete rq.totalLoyaltyPoints
            delete rq.id
            delete rq.countryName
            delete rq.cityName
            delete rq.email
            delete rq.creationDate
            if (this.company.id) {
                delete rq.type
            }
        }

        get countries() {
            return runtimeStore.countries
        }

        get mapedTypes() {
            return this.types.map(type => {
                return {value: type, name: this.$t(`companyTypes.${type.toLowerCase()}`)}
            })
        }

        get firstName() {
            const contactPerson = this.company.contactPerson
            if (typeof contactPerson.firstName === 'string') {
                return contactPerson.firstName
            } else {
                const locale = this.company.language ? this.company.language : 'en'
                return contactPerson.firstName ? contactPerson.firstName[locale] : null
            }
        }

        set firstName(value) {
            const locale = this.company.language ? this.company.language : 'en'

            this.company.contactPerson.firstName = {}
            this.company.contactPerson.firstName[locale] = value
        }

        get lastName() {
            const contactPerson = this.company.contactPerson
            if (typeof contactPerson.lastName === 'string') {
                return contactPerson.lastName
            } else {
                const locale = this.company.language ? this.company.language : 'en'
                return contactPerson.lastName ? contactPerson.lastName[locale] : null
            }
        }

        set lastName(value) {
            const locale = this.company.language ? this.company.language : 'en'
            this.company.contactPerson.lastName = {}

            this.company.contactPerson.lastName[locale] = value
        }

        @Watch('userLocale')
        onUserLocaleChange() {
            LanguageSwitcher.options.methods.switchLanguage.call(this, this.userLocale)
        }

        get userLocale() {
            return this.company ? this.company.language : null
        }

        get title() {
            if (this.$route.params.id == 0) {
                return this.$t('create_new_company')
            } else if (!this.$route.params.id) {
                return this.$t('company_profile')
            }

            return this.company.name
        }

        async loadCities(countryId) {
            const rs = await this.$api.locations.get({
                limitCities: -1,
                orderBy: 'ALPHABET',
                countryId: countryId,
            })

            this.cities = rs.cities
        }
    }
</script>
