<template>
    <div>
        <div class="float-right text-right">
            <hotel-offer-price-block v-if="offer" :offer="offer" :search-request="searchRequest" />
            <hotel-tripadvisor-rating-label
                v-if="offer && offer.tripadvisorRating"
                :tripadvisor-rating="offer.tripadvisorRating"
            />
        </div>
        <hotel-stars :category="category" />
        <h1 class="title" data-cy="hotel-name">
            {{ info.hotelName }}
            <v-chip v-if="type" small>
                {{ type }}
            </v-chip>
        </h1>
        <div v-if="address" @click="$emit('showMapTab')">
            <v-icon color="accent">
                mdi-map-marker
            </v-icon>
            {{ address }}
        </div>
        <template v-if="showContacts">
            <div v-if="info.email" class="d-sm-inline">
                <v-icon small>
                    mdi-email
                </v-icon>
                <a :href="`mailto:${info.email}`">{{ info.email }}</a>
            </div>
            <div v-if="info.phone" class="d-sm-inline">
                <v-icon small>
                    mdi-phone
                </v-icon>
                <a :href="`tel:${info.phone}`">{{ info.phone }}</a>
            </div>
            <div v-if="info.fax" class="d-sm-inline">
                <v-icon small>
                    mdi-fax
                </v-icon>
                <a :href="`tel:${info.fax}`">{{ info.fax }}</a>
            </div>
            <div v-if="info.url" class="d-sm-inline nowrap">
                <v-icon small>
                    mdi-home
                </v-icon>
                <a :href="info.url" target="_blank">{{ info.url }}</a>
            </div>
        </template>
        <div v-if="offer && offer.services.length" class="d-flex flex-wrap">
            <hotel-service-icon
                v-for="(groupName, index) in offer.services.slice(0, 3)"
                :key="index"
                class="mr-1 mt-1"
                :group-name="groupName"
                :map="offer.otaCodeServices"
            />
        </div>
        <div>
            <template v-for="(classification, classificationIndex) in classifications">
                <v-chip v-if="classification.showOnUI" :key="classificationIndex" small class="ma-1">
                    {{ classification.supplierClassificationName || classification.otaName }}
                </v-chip>
            </template>
        </div>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator'
    import {cmsStore, hotelsRuntimeStore, hotelsStore} from '~/store'
    import HotelServiceIcon from '@/components/snippets/HotelServiceIcon'
    import HotelTripadvisorRatingLabel from '~src/components/snippets/hotelTripadvisorRatingLabel.src'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import HotelOfferPriceBlock from '~src/components/search/offers/hotelOfferPriceBlock.src'
    import HotelStars from '@/components/hotels/snippets/HotelStars'

    @Component({
        components: {
            HotelStars,
            DiscountLabel,
            HotelTripadvisorRatingLabel,
            HotelServiceIcon,
            HotelOfferPriceBlock,
        },
    })
    export default class HotelInfo extends Vue {
        @Prop({default: null}) offerProp
        @Prop({default: null}) infoProp
        @Prop({required: true}) searchRequest

        get offer() {
            return (
                this.offerProp ||
                (typeof hotelsStore.singleHotelSearchResponse.offers !== 'undefined' &&
                hotelsStore.singleHotelSearchResponse.offers.length
                    ? hotelsStore.singleHotelSearchResponse.offers[0]
                    : null)
            )
        }

        get info() {
            return this.infoProp || hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo
        }

        get type() {
            return this.info.hotelInfoType
        }

        get category() {
            switch (this.info.stdCategory) {
                case 'ONE':
                    return 1
                case 'TWO':
                    return 2
                case 'THREE':
                    return 3
                case 'FOUR':
                    return 4
                case 'FIVE':
                    return 5
                default:
                    return null
            }
        }

        get address() {
            return this.info.address ? this.info.address.addressLine : null
        }

        get classifications() {
            return this.info.classifications ? this.info.classifications.classification : []
        }

        get showContacts() {
            return cmsStore.hotelSettings.showContacts
        }
    }
</script>
