<script>
    import {Component, Vue} from 'vue-property-decorator'

    @Component
    export default class ConfirmationRedirectMixin extends Vue {
        orderDetailsRedirect(orderId) {
            this.$router.push({name: 'order-details', params: {id: orderId}})
        }
    }
</script>
