<template>
    <div>
        <breadcrumbs :text="personLabel" />
        <client-form :person="client" />
        <passport-modal @saved="refresh" />
    </div>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'
    import CompanyProfile from '@/components/account/CompanyProfile'
    import Breadcrumbs from '~src/components/breadcrumbs.src'
    import ClientForm from '~src/components/account/forms/clientForm.src'
    import PassportModal from '@/components/modals/PassportModal'

    @Component({
        components: {
            PassportModal,
            ClientForm,
            CompanyProfile,
            Breadcrumbs,
        },
        middleware: 'auth',
    })
    export default class CompanyClientPage extends Vue {
        client = {}

        async asyncData({$api, params}) {
            if (!params.id) return {person: {}}
            try {
                const client = (await $api.privateClients.get({id: params.id})).persons[0]
                client.active = client.active === 'true'
                return {client}
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        get personLabel() {
            if (this.client.personId) {
                const {prefix, firstName, lastName} = this.client
                return `${prefix || ''} ${firstName || ''} ${lastName || ''}`
            }
            return this.$t('new_client_profile')
        }

        async refresh() {
            this.client = (await this.$api.privateClients.get({id: this.client.personId})).persons[0]
        }
    }
</script>
