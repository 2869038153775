<template>
    <div>
        <flight-loader :is-loading="loading" />
        <v-carousel height="fit-content" light hide-delimiters :continuous="false">
            <v-carousel-item v-for="(itinerary, index) in details.itinerary" :key="`itinerary-${index}`">
                <div class="px-0 px-md-10">
                    <flight-itinerary-detail :itinerary="itinerary" />
                </div>
            </v-carousel-item>
        </v-carousel>
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import FlightItineraryDetail from '~/components/flights/search/offers/FlightItineraryDetail'
    import FlightLoader from '@/components/flights/snippets/FlightLoader'

    @Component({
        components: {
            FlightItineraryDetail,
            FlightLoader,
        },
    })
    export default class FlightDetail extends Vue {
        @Prop() offer

        details = {itinerary: []}
        loading = false

        mounted() {
            this.getDetails()
        }

        async getDetails() {
            this.loading = true
            const rq = {}
            if (this.offer.offerKey) {
                rq.offerKey = this.offer.offerKey
            }
            if (this.offer.processId) {
                rq.processId = this.offer.processId
            }
            if (this.offer.unitKey) {
                rq.unitKey = this.offer.unitKey
            }
            try {
                this.details = await this.$api.flightInfo.get(rq)
                // eslint-disable-next-line no-empty
            } catch (e) {}
            this.loading = false
            if (this.details.itinerary.length === 0) {
                this.details.itinerary = this.offer.itinerary.map(itinerary => {
                    const segments = itinerary.segments.map(segment => {
                        return {
                            arrivalDate: segment.arrival.date,
                            departureDate: segment.departure.date,
                            arrivalAirportCode: segment.arrival.airportCode,
                            arrivalAirportDescription: segment.arrival.airportDescription,
                            departureAirportCode: segment.departure.airportCode,
                            departureAirportDescription: segment.departure.airportDescription,
                            categoryClass: segment.class,
                            operatingAirline: segment.marketingAirline,
                            marketingAirline: segment.marketingAirline,
                            flightNumber: segment.flightNumber,
                            aircraftName: segment.aircraft.name,
                            duration: segment.duration,
                            baggageInfo: segment.baggageInfo,
                            brandedFareName: segment.brandedFareName,
                        }
                    })
                    return {
                        segments,
                        duration: itinerary.duration,
                    }
                })
            } else {
                this.details.itinerary = this.details.itinerary.map((itinerary, itineraryIndex) => {
                    itinerary.segments = itinerary.segments.map((segment, segmentIndex) => {
                        return Object.assign(segment, {
                            baggageInfo: this.offer.itinerary[itineraryIndex].segments[segmentIndex].baggageInfo,
                            brandedFareName: this.offer.itinerary[itineraryIndex].segments[segmentIndex]
                                .brandedFareName,
                        })
                    })
                    return itinerary
                })
            }
        }
    }
</script>

<style scoped>
    >>> .v-window__prev,
    >>> .v-window__next {
        margin-left: 0;
        margin-right: 0;
        background-color: transparent;
    }
</style>
