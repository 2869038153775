<template>
    <transfer-booking-page-layout v-if="loaded" :search-request="searchRequest" :offer="offer">
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="refreshExpiredOffers" />
            <transfer-breadcrumbs :search-request="searchRequest" :product="product" />
        </template>
        <template v-slot:forms="{bookingKey}">
            <transfer-details-form :disabled="!!bookingKey" class="mb-5" />
            <component
                :is="departureComponent"
                point-type="departure"
                :disabled="!!bookingKey"
                :hotels="product.allowedHotelsDeparture"
                :product="product"
                :search-request="searchRequest"
                class="mb-5"
            />
            <component
                :is="arrivalComponent"
                point-type="arrival"
                :disabled="!!bookingKey"
                :hotels="product.allowedHotelsArrival"
                :product="product"
                :search-request="searchRequest"
                class="mb-5"
            />
            <booking-comments-form :product-store="productStore" class="mb-5" />
        </template>
        <template v-slot:sidebar="{bookingKey}">
            <transfer-search-summary search-disabled :search-request="searchRequest" />
            <transfer-order-summary
                :offer="offer"
                :info="info"
                :product="product"
                :search-request="searchRequest"
                :prebooked="!!bookingKey"
            />
        </template>
    </transfer-booking-page-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import TransferBreadcrumbs from '@/components/transfers/TransferBreadcrumbs'
    import TransferDetailsForm from '@/components/transfers/booking/forms/TransferDetailsForm'
    import TransferSearchSummary from '@/components/transfers/search/TransferSearchSummary'
    import TransferOrderSummary from '@/components/transfers/booking/TransferOrderSummary'
    import TransferStoreMixin from '@/mixins/TransferStoreMixin'
    import {runtimeStore, transfersRuntimeStore, transfersStore} from '@/utils/store-accessor'
    import TransferBookingPageLayout from '~/components/transfers/booking/TransferBookingPageLayout'
    import BookingCommentsForm from '@/components/booking/forms/BookingCommentsForm'
    import TransferPageMixin from '@/components/transfers/mixins/TransferPageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import {UTCToDate} from '@/utils/helpers'

    @Component({
        components: {
            ExpiredOffersSnackbar,
            BookingCommentsForm,
            TransferAirportForm: () => import('@/components/transfers/booking/forms/TransferAirportForm'),
            TransferDetailsForm,
            TransferBreadcrumbs,
            TransferHotelForm: () => import('@/components/transfers/booking/forms/TransferHotelForm'),
            TransferAddressForm: () => import('@/components/transfers/booking/forms/TransferAddressForm'),
            TransferSearchSummary,
            TransferOrderSummary,
            TransferTrainStationForm: () => import('@/components/transfers/booking/forms/TransferTrainStationForm'),
            TransferBookingPageLayout,
        },
        layout: 'blank',
    })
    export default class TransferBookingPage extends mixins(TransferPageMixin, TransferStoreMixin) {
        loaded = false
        info = {
            images: [],
        }

        validate({query}) {
            return query.offerKey
        }

        async mounted() {
            await this.$store.restored
            const offerKey = this.$route.query.offerKey
            this.productStore.clearBookingAdditionalOptions()
            await Promise.all([
                this.loadRuntimeData(),
                runtimeStore.loadTouristBookingFields(offerKey),
                this.productStore.getExtraServiceAdditionalOptions(offerKey),
                (async () => {
                    this.info = await this.$api.transferInfo.get({offerKey})
                })(),
            ])
            const setTransferLocation = (point, pointType) => {
                let val = {}
                switch (point.type) {
                    case 'airport':
                        val.type = 'airport'
                        break
                    case 'city':
                        val.type = this.product[
                            `allowedHotels${pointType.charAt(0).toUpperCase()}${pointType.slice(1)}`
                        ]
                            ? 'hotel'
                            : 'address'
                        break
                    case 'train':
                        val.type = 'train'
                        break
                    default:
                        break
                }
                if (this.productStore.prepareBookRequest[pointType].type !== val.type) {
                    this.productStore.SET_PREPARE_BOOK_REQUEST_PROP({
                        prop: pointType,
                        val,
                    })
                }
            }
            setTransferLocation(this.departurePoint, 'departure')
            setTransferLocation(this.arrivalPoint, 'arrival')
            this.loaded = true
        }

        pointComponent(point, pointType) {
            switch (point.type) {
                case 'airport':
                    return 'transfer-airport-form'
                case 'city':
                    return this.product[`allowedHotels${pointType.charAt(0).toUpperCase()}${pointType.slice(1)}`]
                        ? 'transfer-hotel-form'
                        : 'transfer-address-form'
                case 'train':
                    return 'transfer-train-station-form'
                default:
                    return 'transfer-airport-form'
            }
        }

        isOffersExpired() {
            return (
                this.$dateFns.isAfter(new Date(), UTCToDate(this.$dateFns.parseISO(this.basketItem.expirationTime))) &&
                !runtimeStore.bookingActive &&
                !transfersStore.prepareBookResponse.bookingKey
            )
        }

        refreshExpiredOffers() {
            this.$router.push(transfersRuntimeStore.searchPageLink(this.searchRequest))
        }

        get departurePoint() {
            return transfersRuntimeStore.departurePoint
        }

        get arrivalPoint() {
            return transfersRuntimeStore.arrivalPoint
        }

        get product() {
            return this.basketItem.product
        }

        get departureComponent() {
            return this.pointComponent(this.departurePoint, 'departure')
        }

        get arrivalComponent() {
            return this.pointComponent(this.arrivalPoint, 'arrival')
        }

        get basketItem() {
            return transfersStore.basketItem(this.$route.query.offerKey)
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get offer() {
            return this.product.offers.find(offer => offer.offerKey === this.$route.query.offerKey)
        }
    }
</script>
