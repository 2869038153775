<template>
    <v-row no-gutters class="pb-3">
        <v-col :cols="searchActive && !hideStopBtn ? ($breakpoint.smAndDown ? 10 : 11) : 12">
            <v-progress-linear
                :value="progress"
                color="primary"
                :height="!$breakpoint.smAndDown ? 25 : 50"
                :indeterminate="searchActive"
            >
                <template v-slot="{}">
                    <span class="progress-label">
                        {{ progressLabel }}
                    </span>
                </template>
            </v-progress-linear>
        </v-col>
        <v-col v-if="searchActive && !hideStopBtn" cols="2" sm="1" class="ps-3">
            <v-btn
                :small="!$breakpoint.smAndDown"
                :x-small="$breakpoint.smAndDown"
                block
                class="primary"
                data-cy="stop-search-button"
                :height="!$breakpoint.smAndDown ? 25 : 50"
                @click="stop"
            >
                {{ $t('stop') }}
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
    import {Component, Prop, Emit, Watch, Vue} from 'vue-property-decorator'

    @Component
    export default class SearchProgress extends Vue {
        @Prop({required: true, default: 0}) filteredOffersCount
        @Prop({required: true, default: 0}) totalOffersCount
        @Prop({required: true, default: true}) searchActive
        @Prop({default: false, type: Boolean}) hideStopBtn

        progress = 0

        @Emit()
        stop() {}

        @Watch('searchActive')
        changeLoading(searchActive) {
            if (!searchActive) {
                this.progress = 100
            }
        }

        mounted() {
            if (!this.searchActive) {
                this.progress = 100
            }
        }

        get progressLabel() {
            return this.totalOffersCount === 0 && this.searchActive
                ? this.$t('searching')
                : !this.searchActive && this.filteredOffersCount !== this.totalOffersCount
                ? `${this.filteredOffersCount} ${this.$t('out_of')} ${this.totalOffersCount} ${this.$tc(
                      'offer',
                      this.totalOffersCount
                  )} ${this.$t('match_filters')}`
                : this.searchActive
                ? `${this.totalOffersCount} ${this.$tc('offer', this.totalOffersCount)}`
                : this.$t('offer_found', {
                      offer: `${this.totalOffersCount} ${this.$tc('offer', this.totalOffersCount)}`,
                  })
        }

        get isSearchActive() {
            return this.searchActive
        }

        get offersCount() {
            return this.totalOffersCount
        }
    }
</script>

<style scoped>
    .progress-label {
        color: white;
        text-align: center;
    }
</style>
