<template>
    <div v-if="offers.length">
        <v-row>
            <v-col cols="12" md="4">
                <v-card
                    :class="{'info white--text': mode === mods.best}"
                    class="full-height d-flex flex-column"
                    @click="setMode(mods.best)"
                >
                    <v-card-title class="text-nobreak">
                        {{ $t('flights.best') }}
                    </v-card-title>
                    <v-card-text class="align-self-end">
                        <div class="title blue--text text--darken-1 font-weight-black">
                            {{ best.price | price }}
                        </div>
                        <div class="duration">
                            {{ best.sumDuration | durationFormat }}
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card
                    :class="{'info white--text': mode === mods.fastest}"
                    class="full-height"
                    @click="setMode(mods.fastest)"
                >
                    <v-card-title class="text-nobreak">
                        {{ $t('flights.fastest') }}
                    </v-card-title>
                    <v-card-text>
                        <div class="title blue--text text--darken-1 font-weight-black">
                            {{ fastest.price | price }}
                        </div>
                        <div class="duration">
                            {{ fastest.sumDuration | durationFormat }}
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card
                    :class="{'info white--text': mode === mods.cheapest}"
                    class="full-height"
                    @click="setMode(mods.cheapest)"
                >
                    <v-card-title class="text-nobreak">
                        {{ $t('flights.cheapest') }}
                    </v-card-title>
                    <v-card-text>
                        <div class="title blue--text text--darken-1 font-weight-black">
                            {{ cheapest.price | price }}
                        </div>
                        <div class="duration">
                            {{ cheapest.sumDuration | durationFormat }}
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {flightsStore, flightsRuntimeStore} from '~/store'
    import {flipArray} from '~/utils/helpers'

    @Component
    export default class FlightBestOffers extends Vue {
        @Prop({required: true, default: () => []}) offers
        @Prop({required: true, default: () => []}) totalOffers

        mods = {
            best: 'best',
            fastest: 'durationAsc',
            cheapest: 'priceAsc',
        }

        setMode(mode) {
            flightsStore.SET_SORT(mode)
            flightsRuntimeStore.sort(this.offers)
        }

        get fastest() {
            let best = null
            const offersLength = this.totalOffers.length
            for (let i = 0; i < offersLength; i++) {
                if (!best || this.totalOffers[i].sumDuration < best.sumDuration) {
                    best = this.totalOffers[i]
                }
            }
            return best
        }

        get cheapest() {
            let best = null
            const offersLength = this.totalOffers.length
            for (let i = 0; i < offersLength; i++) {
                if (!best || this.totalOffers[i].price.amount < best.price.amount) {
                    best = this.totalOffers[i]
                }
            }
            return best
        }

        get best() {
            const offersLength = this.totalOffers.length
            const prices = []
            const durations = []
            for (let i = 0; i < offersLength; i++) {
                prices.push(this.totalOffers[i].price.amount)
                durations.push(this.totalOffers[i].sumDuration)
            }
            const uniquePrices = [...new Set(prices)]
            const uniqueDurations = [...new Set(durations)]
            uniquePrices.sort((a, b) => a - b)
            uniqueDurations.sort((a, b) => a - b)
            const flippedPrices = flipArray(uniquePrices)
            const flippedDurations = flipArray(uniqueDurations)
            const offers = [...this.totalOffers]
            for (let i = 0; i < offersLength; i++) {
                offers[i].bestCoef = flippedPrices[offers[i].price.amount] + flippedDurations[offers[i].sumDuration]
            }
            offers.sort((a, b) => a.bestCoef - b.bestCoef)
            return offers[0]
        }

        get mode() {
            return flightsStore.sortFnName
        }
    }
</script>

<style scoped>
    >>> .info .blue--text {
        color: white !important;
    }

    .info .duration {
        color: white;
    }

    .full-height {
        height: 100%;
    }
</style>
