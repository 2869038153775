<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
    >
        <template v-slot:activator="{on}">
            <v-text-field
                :value="date | dateShortFormat"
                :label="label"
                clearable
                readonly
                background-color="white"
                prepend-inner-icon="mdi-calendar"
                hide-details
                v-on="on"
                @click:clear="date = ''"
            />
        </template>
        <v-date-picker
            ref="datePicker"
            v-model="date"
            :locale="$i18n.locale"
            :min="min"
            :max="max"
            @change="menu = false"
        />
    </v-menu>
</template>

<script>
    import {Vue, Component, Prop, VModel, Watch} from 'nuxt-property-decorator'

    @Component
    export default class DatePicker extends Vue {
        @VModel({type: String}) date
        @Prop() min
        @Prop() max
        @Prop() label

        menu = false

        @Watch('menu')
        onChangeMenu(val) {
            val && setTimeout(() => (this.$refs.datePicker.activePicker = 'YEAR'))
        }
    }
</script>
