<template>
    <div>
        <breadcrumbs />
        <v-container>
            <v-card outlined>
                <v-card-title v-text="$t(`page_title.${$route.name}`)" />
                <v-card-text>
                    <registration-form :_valid.sync="valid" @registered="onRegistered" />
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import Breadcrumbs from '~/components/Breadcrumbs'
    import RegistrationForm from '~/components/account/forms/RegistrationForm'
    import {authStore} from '~/store'

    @Component({
        components: {
            Breadcrumbs,
            RegistrationForm,
        },
        middleware: 'auth',
    })
    export default class RegistrationPage extends Vue {
        valid = false

        async mounted() {
            await this.$store.restored
            authStore.SET_REGISTERED_PERSON_PROP({prop: 'memberLevel', val: 'REGISTERED'})
        }

        async onRegistered() {
            await this.$router.push({name: 'home'})
            this.$toast.success(this.$t('registration_successful'))
        }
    }
</script>
