<template>
    <div>
        <component :is="component" v-for="product in offers" :key="product.info.name" :product="product" />
        <conditions-modal>
            <template v-slot:info>
                <transfer-conditions-modal-info />
            </template>
        </conditions-modal>
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import ConditionsModal from '@/components/modals/ConditionsModal'
    import TransferConditionsModalInfo from '@/components/transfers/snippets/TransferConditionsModalInfo'

    @Component({
        components: {
            TransferConditionsModalInfo,
            ConditionsModal,
            TransferCard: () => import('~/components/transfers/search/offers/TransferCard.vue'),
            TransferCardMobile: () => import('~/components/transfers/search/offers/TransferCardMobile.vue'),
        },
    })
    export default class TransferOffers extends Vue {
        @Prop({default: () => []}) offers
        /*get groupedOffers() {
            return transfersRuntimeStore.offers.reduce((groupedProducts, product) => {
                const groupedProductIndex = groupedProducts.findIndex(
                    groupedProduct => groupedProduct.name === product.info.name
                )
                if (groupedProductIndex === -1) {
                    groupedProducts.push({
                        name: product.info.name,
                        products: [product],
                    })
                } else {
                    groupedProducts[groupedProductIndex].products.push(product)
                }
                return groupedProducts
            }, [])
        }*/

        get component() {
            return !this.$breakpoint.smAndDown ? 'transfer-card' : 'transfer-card-mobile'
        }
    }
</script>
