<template>
    <div v-if="loaded">
        <search-layout :map-expanded="mapExpanded" :tabs="mobileTabs">
            <template v-slot:breadcrumbs>
                <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="search" />
                <package-breadcrumbs :search-request="searchRequest" />
            </template>
            <template v-slot:mobileTabs>
                <package-mobile-search-page-tab v-if="isMobile" ref="mobileTabs" v-model="mobileTabs" />
            </template>
            <template v-slot:preinfo>
                <v-col v-if="$breakpoint.smAndDown && searchActive" cols="12">
                    <search-progress
                        :filtered-offers-count="tab === 0 ? hotelFilteredOffers.length : flightFilteredOffers.length"
                        :total-offers-count="searchResponse.offers[tab === 0 ? 'hotels' : 'flights'].length"
                        :search-active="searchActive"
                        hide-stop-btn
                    />
                </v-col>
                <v-col cols="12">
                    <selected-offers
                        v-show="!mapExpanded"
                        v-if="selectedFlight"
                        :hotel="selectedHotel"
                        :room="selectedRoom"
                        :flight="selectedFlight"
                        :price="initialPrice"
                        :search-request="searchRequest"
                        :tab="tab"
                        product-type="package"
                        @change-tab="changeTab"
                    />
                </v-col>
            </template>
            <template v-slot:left-sidebar>
                <packages-search-summary :search-request="searchRequest" />
                <v-tabs-items v-model="tab" background-color="primary">
                    <v-tab-item>
                        <v-card>
                            <v-card-text class="px-1">
                                <sort-select v-model="hotelSortKey" :items="hotelSortItems" />
                                <hotel-filters
                                    :filter-values="hotelFilterValues"
                                    :filters="searchResponse.filters.hotels"
                                    :filtered-offers-count="hotelFilteredOffers.length"
                                    :nights="nights"
                                    :currency="currency"
                                    @change="changeHotelFilter"
                                    @reset="resetHotelFilters"
                                />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card>
                            <v-card-text class="px-0">
                                <sort-select v-model="flightSortKey" :items="flightSortItems" />
                                <flight-filters
                                    :filter-values="flightFilterValues"
                                    :filters="searchResponse.filters.flights"
                                    :filtered-offers-count="flightFilteredOffers.length"
                                    :currency="currency"
                                    @change="changeFlightFilter"
                                    @reset="resetFlightFilters"
                                />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <!-- <flight-search-summary /> -->
                </v-tabs-items>
            </template>
            <template v-slot:main-content>
                <search-progress
                    :filtered-offers-count="tab === 0 ? hotelFilteredOffers.length : flightFilteredOffers.length"
                    :total-offers-count="searchResponse.offers[tab === 0 ? 'hotels' : 'flights'].length"
                    :search-active="searchActive"
                    hide-stop-btn
                />

                <v-tabs-items v-model="tab" background-color="primary">
                    <v-tab-item>
                        <component
                            :is="!$breakpoint.smAndDown ? 'hotel-offer-card' : 'hotel-offer-card-mobile'"
                            v-for="offer in hotelOffers"
                            :key="`hotel-${offer.hotelCode}`"
                            :offer="offer"
                        >
                            <template v-slot:price="{room}">
                                <offer-delta-button :room="room" />
                            </template>
                        </component>
                        <pagination v-if="selectedRoom" :page-prop.sync="hotelsPage" :pages="pages" />
                    </v-tab-item>
                    <v-tab-item>
                        <template v-if="flightOffers">
                            <flight-card v-for="offer in flightOffers" :key="offer.offerKey" :offer="offer">
                                <template v-slot:price>
                                    <offer-delta-button :flight="offer" />
                                    <flight-conditions-label
                                        v-if="packagesNotDeltaPrice"
                                        class="mb-1"
                                        small
                                        :offer-key="offer.offerKey"
                                        :price="offer.notDeltaPrice"
                                        :refundable="offer.refundable"
                                        :regular="offer.regular"
                                        @conditions="showFlightConditionsInfo(offer)"
                                    />
                                </template>
                            </flight-card>
                        </template>
                        <pagination v-if="selectedFlight" :page-prop.sync="flightsPage" :pages="pages" />
                    </v-tab-item>
                </v-tabs-items>
            </template>
            <template v-slot:right-sidebar>
                <client-only>
                    <v-tabs-items v-model="tab" background-color="primary">
                        <v-tab-item>
                            <hotel-map
                                :_expanded.sync="mapExpanded"
                                :offers="hotelFilteredOffers"
                                :city="arrivalPoint"
                                :parent="true"
                                :link-to-hotels="false"
                            />
                        </v-tab-item>
                        <v-tab-item>
                            <flight-map
                                :_expanded.sync="mapExpanded"
                                :points="mapPoints"
                                :center="hotelMapCenter"
                                :parent="true"
                            />
                        </v-tab-item>
                    </v-tabs-items>
                </client-only>
            </template>
        </search-layout>
        <v-bottom-navigation v-if="isMobile && mobileTabs !== 3" v-model="tab" fixed grow>
            <v-btn>
                <span class="text-capitalize">
                    {{ $tc('hotel', 1) }}
                </span>
                <v-icon>
                    mdi-office-building
                </v-icon>
            </v-btn>
            <v-btn>
                <span>
                    {{ $t('flights.flight') }}
                </span>
                <v-icon>
                    mdi-airplane
                </v-icon>
            </v-btn>
        </v-bottom-navigation>
        <conditions-modal />
        <hotel-room-info-modal />
        <hotel-meal-type-info-modal />
    </div>
</template>

<script>
    import {Component, mixins, Watch} from 'nuxt-property-decorator'
    import MobileSearchPageTabs from '@/components/snippets/MobileSearchPageTabs'
    import Pagination from '~/components/search/Pagination'
    import ConditionsModal from '~/components/modals/ConditionsModal'
    import FlightMap from '~/components/flights/search/FlightMap'
    import FlightOffers from '~/components/flights/search/offers/FlightOffers'
    import SearchPageBase from '@/mixins/SearchPageBase'
    import {searchRequest} from '@/utils/packages/packages-blank-states'
    import PackagesStoreMixin from '@/mixins/PackagesStoreMixin'
    import {packagesStore, packagesRuntimeStore, persistentStore, runtimeStore} from '~/store'
    import SearchLayout from '~/components/parts/SearchLayout'
    import SelectedOffers from '~/components/packages/search/SelectedOffers'
    import OfferDeltaButton from '~/components/packages/search/OfferDeltaButton'
    import PackageBreadcrumbs from '~/components/packages/PackageBreadcrumbs'
    import PackagesSearchSummary from '~/components/packages/search/PackagesSearchSummary'
    import HotelRoomInfoModal from '~/components/modals/HotelRoomInfoModal'
    import FlightFilters from '@/components/flights/search/FlightFilters'
    import HotelFilters from '@/components/search/filters/HotelFilters'
    import {isEqual} from '@/utils/helpers'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import SearchProgress from '~src/components/search/searchProgress.src'
    import PackagePageMixin from '@/components/packages/mixins/PackagePageMixin'
    import SortSelect from '@/components/search/SortSelect'
    import HotelMealTypeInfoModal from '@/components/hotels/modals/HotelMealTypeInfoModal'
    import HotelOfferCard from '~/components/search/offers/HotelOfferCard'
    import HotelMap from '~/components/search/offers/HotelMap'
    import FlightCard from '~/components/flights/search/offers/FlightCard'
    import HotelOfferCardMobile from '~/components/search/offers/HotelOfferCardMobile'
    import PackageMobileSearchPageTab from '~/components/packages/search/PackageMobileSearchPageTab'
    import FlightConditionsLabel from '@/components/flights/snippets/FlightConditionsLabel'
    import {EventBus, SHOW_FLIGHT_CONDITIONS_INFO} from '@/utils/event-bus'

    @Component({
        components: {
            FlightConditionsLabel,
            HotelMealTypeInfoModal,
            SortSelect,
            SearchProgress,
            ExpiredOffersSnackbar,
            HotelFilters,
            FlightFilters,
            HotelRoomInfoModal,
            FlightMap,
            FlightOffers,
            Pagination,
            MobileSearchPageTabs,
            PackageBreadcrumbs,
            ConditionsModal,
            SearchLayout,
            HotelOfferCard,
            HotelMap,
            FlightCard,
            HotelOfferCardMobile,
            OfferDeltaButton,
            PackageMobileSearchPageTab,
            PackagesSearchSummary,
            SelectedOffers,
        },
        layout: 'blank',
    })
    export default class PackagesPage extends mixins(PackagesStoreMixin, SearchPageBase, PackagePageMixin) {
        tab = 0
        flightsPage = 1
        hotelsPage = 1
        mobileTabs = 1
        loaded = false

        @Watch('$route.query')
        onQueryChange() {
            this.search()
        }

        validate({query}) {
            return query.arrivalCityId && query.departureCityId
        }

        async fetch() {
            await this.loadRuntimeData()
        }

        async mounted() {
            if (this.$route.params.tab !== undefined) {
                this.tab = this.$route.params.tab
            } else {
                this.tab = this.packagesNotDeltaPrice ? 1 : 0
            }
            await this.$store.restored
            this.loaded = true
            if (
                !isEqual(packagesStore.searchRequest, this.searchRequest) ||
                this.isOffersExpired() ||
                !packagesStore.hasOffers
            ) {
                await this.search()
            } else if (
                packagesRuntimeStore.hotelOffers.length === 0 ||
                packagesRuntimeStore.flightOffers.length === 0
            ) {
                this.resetPageLock = true
                await Promise.all([packagesRuntimeStore.filterFlights(), packagesRuntimeStore.filterHotels()])
            }
        }

        async search() {
            await packagesRuntimeStore.search(this.searchRequest)
        }

        isOffersExpired() {
            return packagesStore.isOffersExpired()
        }

        changeTab(tab) {
            this.tab = tab
            this.mobileTabs = 1
        }

        resetPage() {
            if (this.resetPageLock) {
                this.resetPageLock = false
            } else if (this.tab === 0) {
                this.hotelsPage = 1
            } else {
                this.flightsPage = 1
            }
        }

        hotelDelta(deltaPrice) {
            return +(deltaPrice - this.selectedRoom.deltaPrice).toFixed(2)
        }

        flightDelta(deltaPrice) {
            return +(deltaPrice - this.selectedFlight.deltaPrice).toFixed(2)
        }

        calcTotalPrice(deltaPrice) {
            return (deltaPrice + this.totalPrice).toFixed(2)
        }

        changeHotelFilter(data) {
            packagesStore.SET_HOTELS_FILTER(data)
            packagesRuntimeStore.filterHotels()
        }

        resetHotelFilters() {
            packagesStore.RESET_HOTEL_FILTERS()
            packagesRuntimeStore.filterHotels()
        }

        changeFlightFilter(data) {
            packagesStore.SET_FLIGHT_FILTER(data)
            packagesRuntimeStore.filterFlights()
        }

        resetFlightFilters() {
            packagesStore.RESET_FLIGHT_FILTERS()
            packagesRuntimeStore.filterFlights()
        }

        selectFlight(offerKey) {
            packagesStore.SET_SELECTED_FLIGHT_KEY(offerKey)
        }

        selectHotel(offerKey) {
            packagesStore.SET_SELECTED_HOTEL_KEY(offerKey)
        }

        showFlightConditionsInfo(offer) {
            EventBus.$emit(SHOW_FLIGHT_CONDITIONS_INFO, offer)
        }

        get offers() {
            return null
        }

        get searchRequest() {
            //TODO Need filter $route.query params
            const rq = Object.assign(searchRequest(), this.$route.query)
            if (typeof rq.childrenAges === 'string') {
                rq.childrenAges = [parseInt(rq.childrenAges, 10)]
            }
            rq.convertToCurrency = persistentStore.currency
            rq.adults = parseInt(rq.adults, 10)
            rq.arrivalCityId = parseInt(rq.arrivalCityId, 10)
            rq.departureCityId = parseInt(rq.departureCityId, 10)
            rq.directFlight = rq.directFlight === 'true' || rq.directFlight === true
            if (rq.citizenshipId) {
                rq.citizenshipId = parseInt(rq.citizenshipId, 10)
            }
            rq.packageId = parseInt(rq.packageId, 10)
            return rq
        }

        get mapPoints() {
            return [packagesRuntimeStore.departurePoint, packagesRuntimeStore.arrivalPoint]
        }

        get pages() {
            const mode = this.tab === 0 ? 'hotel' : 'flight'
            return Math.ceil(this.productRuntimeStore[`${mode}Offers`].length / this.resultsOnPage)
        }

        get flightOffers() {
            const index = this.resultsOnPage * (this.flightsPage - 1)
            return this.productRuntimeStore.flightOffers.slice(index, index + this.resultsOnPage) || []
        }

        get flightFilteredOffers() {
            return packagesRuntimeStore.flightOffers
        }

        get hotelFilteredOffers() {
            return packagesRuntimeStore.hotelOffers
        }

        get hotelMapCenter() {
            return {
                lat: packagesRuntimeStore.arrivalPoint.latitude,
                lng: packagesRuntimeStore.arrivalPoint.longitude,
            }
        }

        get hotelOffers() {
            const index = this.resultsOnPage * (this.hotelsPage - 1)
            return this.productRuntimeStore.hotelOffers.slice(index, index + this.resultsOnPage) || []
        }

        get totalPrice() {
            return packagesRuntimeStore.totalPrice(this.selectedRoom, this.selectedFlight, this.initialPrice)
        }

        get initialPrice() {
            return packagesStore.initialPrice
        }

        get selectedFlight() {
            return packagesStore.selectedFlight
        }

        get selectedHotel() {
            return packagesStore.selectedHotel
        }

        get selectedRoom() {
            return packagesStore.selectedRoom
        }

        get searchActive() {
            return packagesRuntimeStore.searchActive
        }

        get flightSortKey() {
            return packagesStore.flightSortKey
        }

        set flightSortKey(sortKey) {
            packagesStore.SET_FLIGHT_SORT_KEY(sortKey)
            packagesRuntimeStore.sortFlights(packagesRuntimeStore.flightOffers)
        }

        get hotelSortKey() {
            return packagesStore.hotelSortKey
        }

        set hotelSortKey(sortKey) {
            packagesStore.SET_HOTEL_SORT_KEY(sortKey)
            packagesRuntimeStore.sortHotels(packagesRuntimeStore.hotelOffers)
        }

        get searchResponse() {
            return packagesStore.searchResponse
        }

        get flightFilterValues() {
            return packagesStore.flightFilters
        }

        get hotelFilterValues() {
            return packagesStore.hotelsFilters
        }

        get nights() {
            return this.$dateFns.differenceInDays(
                this.$dateFns.parseISO(this.searchRequest.endDate),
                this.$dateFns.parseISO(this.searchRequest.startDate)
            )
        }

        get arrivalPoint() {
            return packagesRuntimeStore.arrivalPoint
        }

        get hotelSortItems() {
            return [
                {
                    text: this.$t('sort.price_desc'),
                    value: 'priceDeltaAsc',
                },
                {
                    text: this.$t('sort.price_asc'),
                    value: 'priceDeltaDesc',
                },
                {
                    text: this.$t('sort.hotel_name'),
                    value: 'name',
                },
                {
                    text: this.$t('sort.category_desc'),
                    value: 'categoryAsc',
                },
                {
                    text: this.$t('sort.category_asc'),
                    value: 'categoryDesc',
                },
            ]
        }

        get flightSortItems() {
            return [
                {
                    text: this.$t('sort.price_desc'),
                    value: 'priceDeltaAsc',
                },
                {
                    text: this.$t('sort.price_asc'),
                    value: 'priceDeltaDesc',
                },
                {
                    text: this.$t('sort.duration_desc'),
                    value: 'durationDesc',
                },
                {
                    text: this.$t('sort.duration_asc'),
                    value: 'durationAsc',
                },
                {
                    text: this.$t('sort.airline'),
                    value: 'airline',
                },
                {
                    text: this.$t('sort.departure_time'),
                    value: 'departureTime',
                },
                {
                    text: this.$t('sort.arrival_time'),
                    value: 'arrivalTime',
                },
            ]
        }

        get currency() {
            return packagesStore.searchRequest.convertToCurrency
        }

        get packagesNotDeltaPrice() {
            return runtimeStore.config.packagesNotDeltaPrice
        }
    }
</script>
