<template>
    <v-dialog v-model="modal" hide-overlay width="500">
        <v-card v-if="isLoading" color="primary" dark>
            <v-card-text>
                {{ $t('stand_by') }}
                <v-progress-linear indeterminate color="white" class="mb-0" />
            </v-card-text>
        </v-card>
        <v-card v-else>
            <v-icon class="v-card-close" @click="modal = false">
                mdi-close
            </v-icon>
            <v-card-title class="title font-weight-light">
                {{ $t('cancellation') }}
            </v-card-title>
            <v-card-text class="text-center">
                <v-icon v-if="!cancellationAllowed" color="error">
                    mdi-exclamation-thick
                </v-icon>
                <div v-if="error || !cancelPrice" class="error--text">
                    {{ $t('cancelInfoMessage.error') }}
                </div>
                <div v-else-if="service.customerPaymentStatus === 'PARTIAL_PAID'" class="error--text">
                    {{
                        $t('cancelInfoMessage.contact_to_agency', {
                            email: company.email,
                            phone: company.phones.join(','),
                        })
                    }}
                </div>
                <div v-else-if="cancelPrice.amount >= servicePrice.amount" class="error--text">
                    {{ $t('cancelInfoMessage.not_refundable') }}
                </div>
                <template v-else-if="cancelPrice.amount === 0">
                    <div class="success--text">
                        {{ $t('cancelInfoMessage.cancellation_free') }}
                    </div>
                    <template v-if="!isNotPaid">
                        {{
                            $t('cancelInfoMessage.amount_paid', {
                                amount: $options.filters.priceFormat(servicePrice.amount, servicePrice.currency),
                            })
                        }}
                    </template>
                </template>
                <template v-else>
                    <template v-if="isNotPaid">
                        <div class="error--text">
                            {{
                                $t('cancelInfoMessage.cancellation_fee', {
                                    fee: $options.filters.priceFormat(cancelPrice.amount, cancelPrice.currency),
                                })
                            }}
                        </div>
                        {{
                            $t('cancelInfoMessage.contact_to_agency', {
                                email: company.email,
                                phone: company.phones.join(','),
                            })
                        }}
                    </template>
                    <template v-else>
                        <div class="error--text">
                            {{
                                $t('cancelInfoMessage.cancellation_fee', {
                                    fee: $options.filters.priceFormat(cancelPrice.amount, cancelPrice.currency),
                                })
                            }}
                        </div>
                        {{
                            $t('cancelInfoMessage.amount_paid', {
                                amount: $options.filters.priceFormat(servicePrice.amount, servicePrice.currency),
                            })
                        }}
                        <br />
                        {{
                            $t('cancelInfoMessage.refund_difference', {
                                difference: $options.filters.priceFormat(
                                    servicePrice.amount - cancelPrice.amount,
                                    servicePrice.currency
                                ),
                            })
                        }}
                    </template>
                </template>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn v-if="cancellationAllowed" class="warning" @click="cancel">
                    {{ $t('confirm_cancellation') }}
                </v-btn>
                <v-btn @click="modal = false">
                    {{ $t('close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Mixins} from 'vue-property-decorator'
    import {authStore} from '@/store'
    import LoadMixin from '@/mixins/LoadMixin'

    @Component
    export default class CancelServiceModal extends Mixins(LoadMixin) {
        modal = false
        isLoading = true
        error = false
        service = {}
        cancelPrice = null

        load() {
            if (!Object.keys(authStore.company).length) {
                authStore.loadCompany()
            }
        }

        async show(service) {
            this.service = service
            this.error = false
            this.isLoading = true
            this.cancelPrice = null
            this.modal = true
            try {
                const rs = await this.$api.currentCancelInfo.get({processId: service.processId})
                this.cancelPrice = rs.cancelPrice
                // eslint-disable-next-line no-empty
            } catch (e) {
                this.error = true
            } finally {
                this.isLoading = false
            }
        }

        get servicePrice() {
            return this.service.salesTerms.find(salesTerm => salesTerm.type === 'CLIENT').price
        }

        get isNotPaid() {
            return ![
                'PAID',
                'PARTIAL_PAID',
                'request.service.payment_status.in_transit',
                'request.service.payment_status.invoice_waiting',
                'request.service.payment_status.overdue',
            ].includes(this.service.customerPaymentStatus)
        }

        get cancellationAllowed() {
            return (
                !this.error &&
                this.cancelPrice &&
                this.service.customerPaymentStatus !== 'PARTIAL_PAID' &&
                (this.cancelPrice.amount === 0 ||
                    (this.cancelPrice.amount < this.servicePrice.amount && !this.isNotPaid))
            )
        }

        get company() {
            return authStore.company
        }

        async cancel() {
            this.isLoading = true
            try {
                const rq = this.service.serviceContainerId
                    ? {containerId: this.service.serviceContainerId}
                    : {processId: this.service.processId}
                const status = (await this.$api.cancelService.post(rq))[0].status
                switch (status) {
                    case 'CANCELLATION_PENDING':
                        this.$toast.info(
                            `${this.$t('cancelInfoMessage.cancellation_pending')}<br />${this.$t(
                                'cancelInfoMessage.expect_cancel_from_supplier'
                            )}`
                        )
                        break
                    case 'CANCELED':
                        if (this.isNotPaid) {
                            this.$toast.success(
                                `${this.$t('cancelInfoMessage.cancel_success')}<br />${this.$t(
                                    'cancelInfoMessage.status_changed_to_cancelled'
                                )}`
                            )
                        } else {
                            this.$toast.success(
                                `${this.$t('cancelInfoMessage.cancel_success')}<br />${this.$t(
                                    'cancelInfoMessage.refund_pending'
                                )}`
                            )
                        }
                        break
                    case 'CONFIRMED':
                    case 'PRECONFIRMED':
                    case 'UNDEFINED':
                        this.$toast.error(
                            `${this.$t('cancelInfoMessage.error')}<br />${this.$t(
                                'cancelInfoMessage.contact_to_agency',
                                {email: this.company.email, phone: this.company.phones.join(',')}
                            )}`
                        )
                        break
                }
                this.$emit('cancelService')
            } catch (e) {
                this.$toast.error(
                    `${this.$t('cancelInfoMessage.error')}<br />${this.$t('cancelInfoMessage.contact_to_agency', {
                        email: this.company.email,
                        phone: this.company.phones.join(','),
                    })}`
                )
            } finally {
                this.modal = false
            }
        }
    }
</script>
