<template>
    <div>
        <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="search" />
        <mobile-info-page-tabs v-if="$breakpoint.smAndDown" v-model="mobileTabs" />
        <v-container fluid>
            <activity-breadcrumbs v-if="!$breakpoint.smAndDown" :search-request="searchRequest" />
            <v-row>
                <v-col v-show="!$breakpoint.smAndDown || ($breakpoint.smAndDown && mobileTabs === 0)" md="8">
                    <h1 v-if="product.info" class="title">
                        {{ product.info.name }}
                    </h1>
                    <component
                        :is="!$breakpoint.smAndDown ? 'product-gallery' : 'product-gallery-mobile'"
                        :images-data="uniqueImages"
                    />
                    <template v-if="!$breakpoint.smAndDown">
                        <activity-grouped-offers
                            :offers="product.offers"
                            :_group-by.sync="groupBy"
                            class="mt-4"
                            @images="images = [...images, ...$event]"
                            @info="offersInfo = [...offersInfo, ...$event]"
                        />
                        <template v-if="groupBy === 'none'">
                            <activity-offer-card
                                v-for="offer in product.offers"
                                :key="offer.offerKey"
                                :offer="offer"
                                @images="images = [...images, ...$event]"
                                @info="offersInfo = [...offersInfo, ...$event]"
                            />
                        </template>
                    </template>
                    <template v-if="showShortDescription">
                        <div class="font-weight-bold mt-2">
                            {{ $t(`description_type.short`) }}
                        </div>
                        <div v-html="shortDescription" />
                    </template>
                    <template v-if="fullDescription">
                        <div class="font-weight-bold mt-2">
                            {{ $t(`description_type.general`) }}
                        </div>
                        <div v-html="fullDescription" />
                    </template>
                    <template v-if="descriptions && descriptions.length">
                        <strong>{{ $t('description') }} </strong><br />
                        <div v-for="(desc, descIndex) in descriptions" :key="descIndex" v-html="desc" />
                    </template>
                    <view-offers-button @showOffers="mobileTabs = 1" />
                </v-col>
                <v-col v-show="!$breakpoint.smAndDown || ($breakpoint.smAndDown && mobileTabs === 1)" md="4">
                    <activity-search-summary :search-request="searchRequest" />
                    <template v-if="$breakpoint.smAndDown">
                        <activity-grouped-offers
                            :offers="product.offers"
                            :_group-by.sync="groupBy"
                            @images="images = [...images, ...$event]"
                            @info="offersInfo = [...offersInfo, ...$event]"
                        />
                        <template v-if="groupBy === 'none'">
                            <activity-offer-card
                                v-for="offer in product.offers"
                                :key="offer.offerKey"
                                :offer="offer"
                                @images="images = [...images, ...$event]"
                                @info="offersInfo = [...offersInfo, ...$event]"
                            />
                        </template>
                    </template>
                    <div v-if="!$breakpoint.smAndDown" style="position: relative">
                        <client-only>
                            <activity-map
                                :city="city"
                                style="position: relative; max-height: 70vh; top: 0!important"
                                class="disable-top"
                            />
                        </client-only>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <conditions-modal>
            <template v-slot:info>
                <activity-conditions-modal-info />
            </template>
        </conditions-modal>
        <activity-info-modal />
    </div>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import MobileInfoPageTabs from '@/components/snippets/MobileInfoPageTabs'
    import ConditionsModal from '~/components/modals/ConditionsModal'
    import ActivityBreadcrumbs from '@/components/activities/ActivityBreadcrumbs'
    import ActivityOfferCard from '@/components/activities/info/ActivityOfferCard'
    import ActivityInfo from '@/components/activities/info/ActivityInfo'
    import ActivitySearchSummary from '@/components/activities/search/ActivitySearchSummary'
    import ActivityMap from '@/components/activities/search/ActivityMap'
    import ActivityInfoModal from '@/components/activities/info/ActivityInfoModal'
    import ActivityStoreMixin from '@/mixins/ActivityStoreMixin'
    import ActivityGroupedOffers from '~/components/activities/search/offers/ActivityGroupedOffers'
    import ViewOffersButton from '@/components/snippets/ViewOffersButton'
    import ProductGallery from '@/components/info/ProductGallery'
    import ProductGalleryMobile from '@/components/info/ProductGalleryMobile'
    import ActivityPageMixin from '@/components/activities/mixins/ActivityPageMixin'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import ActivityConditionsModalInfo from '@/components/activities/snippets/ActivityConditionsModalInfo'
    import {handleNewLine} from '@/utils/filters'

    @Component({
        components: {
            ActivityConditionsModalInfo,
            ExpiredOffersSnackbar,
            ViewOffersButton,
            ActivityGroupedOffers,
            ActivityOfferCard,
            ActivityBreadcrumbs,
            MobileInfoPageTabs,
            ActivityInfo,
            ActivitySearchSummary,
            ActivityMap,
            ConditionsModal,
            ActivityInfoModal,
            ProductGallery,
            ProductGalleryMobile,
        },
        layout: 'blank',
    })
    export default class ActivityPage extends mixins(ActivityPageMixin, ActivityStoreMixin) {
        mobileTabs = 0
        images = []
        offersInfo = []
        groupBy = 'serviceName'

        validate({query}) {
            return query.name
        }

        async mounted() {
            await this.$store.restored
            await this.loadRuntimeData()
        }

        search() {
            this.$router.push(this.productRuntimeStore.searchPageLink(this.searchRequest))
        }

        isOffersExpired() {
            return this.productStore.isOffersExpired()
        }

        get fullDescription() {
            return handleNewLine(this.offersInfo.find(({fullDescription}) => fullDescription)?.fullDescription)
        }

        get shortDescription() {
            return handleNewLine(this.offersInfo.find(({shortDescription}) => shortDescription)?.shortDescription)
        }

        get descriptions() {
            return this.offersInfo.find(({descriptions}) => descriptions.length)?.descriptions
        }

        get showShortDescription() {
            return (
                this.shortDescription &&
                (!this.fullDescription || this.fullDescription.indexOf(this.shortDescription) === -1)
            )
        }

        get product() {
            return (
                this.productStore.searchResponse.product.find(
                    product => product.info.name === decodeURIComponent(this.$route.query.name)
                ) || {}
            )
        }

        get uniqueImages() {
            return this.images.filter((v, i, a) => a.findIndex(t => t.url === v.url) === i)
        }

        get searchRequest() {
            return this.productStore.searchRequest
        }

        get city() {
            return this.productRuntimeStore.city
        }
    }
</script>

<style lang="scss">
    .disable-top {
        top: 0 !important;
    }
</style>
