<template>
    <div class="checkbox-wrapper">
        <v-expansion-panels v-model="expanded" flat style="background-color: transparent" tile>
            <v-expansion-panel expand>
                <v-expansion-panel-header class="v-subheader pl-0 pr-0 theme--light">
                    {{ $t(`filterTitles.${filter}`) }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row class="checkbox-container">
                        <v-col
                            v-for="(item, index) in partOfValues"
                            :key="`${index}-${item.value}`"
                            :cols="12"
                            class="py-1 pr-4"
                        >
                            <v-checkbox
                                v-model="checked"
                                small
                                :hide-details="true"
                                :class="{'pb-3': index + 1 === partOfValues.length}"
                                class="py-0 my-0"
                                :value="item.value"
                                :ripple="!$breakpoint.smAndDown"
                            >
                                <template v-slot:label style="width: 100%">
                                    <div class="d-flex label-wrapper">
                                        <div class="label-text">
                                            {{ getValue(item) }}
                                        </div>
                                        <div class="ms-auto filter-qty font-weight-bold">
                                            {{ `${item.count}` }}
                                        </div>
                                    </div>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-btn v-if="values.length > showLimit" block x-small text @click="showAll = !showAll">
                        {{ showAll ? $t('hide') : $t('show') }} {{ $t('all') }}
                    </v-btn>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-divider />
    </div>
</template>

<script>
    import {Vue, Component, Prop, Model} from 'vue-property-decorator'

    @Component
    export default class CheckboxFilter extends Vue {
        @Model() value
        @Prop({required: true}) filters
        @Prop({default: false, type: Boolean}) pluralization
        @Prop() filter
        @Prop({default: false, type: Boolean}) translateLabels
        @Prop({default: 0}) panelExpanded
        @Prop({default: false, type: Boolean}) dateFilter

        showLimit = 6
        showAll = false
        expanded = false

        mounted() {
            this.expanded = this.panelExpanded
        }

        getTranslation(value) {
            return this.pluralization
                ? this.$tc(`filters_${this.filter}`, Number(value))
                : this.$t(`filters_${this.filter}.${value}`)
        }

        getValue(item) {
            if (this.dateFilter) {
                return this.$options.filters.dateFormat(item.value)
            }
            return this.translateLabels || item.value === 'other' ? this.getTranslation(item.value) : item.value
        }

        get checked() {
            return this.value[this.filter]
        }

        set checked(value) {
            this.$emit('change', {key: this.filter, value})
        }

        get values() {
            return this.filters[this.filter]
        }

        get partOfValues() {
            if (!this.showAll && this.values.length > this.showLimit) {
                return this.values.slice(0, this.showLimit)
            }
            return this.values
        }

        get notMobile() {
            return !this.$breakpoint.smAndDown
        }
    }
</script>

<style lang="scss" scoped>
    .label-wrapper {
        width: 100%;
    }

    .label-text {
        font-size: 13px;
    }

    .checkbox-container {
        max-height: 500px;
        overflow: auto;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #ddd;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #6699cc;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #b3d4fc;
    }

    .v-input__append-outer::v-deep {
        margin-left: auto;
    }

    .filter-qty {
        color: grey;
        font-size: 10px;
    }

    ::v-deep .v-expansion-panel-content__wrap {
        padding: 0;
    }
</style>
