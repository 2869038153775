<template>
    <v-form v-if="showPassport" ref="form">
        <v-row>
            <v-col cols="12" md="3">
                <v-select
                    v-model="type"
                    :items="passportTypes"
                    :label="fieldLabel('document_type', 'PASSPORT_TYPE')"
                    clearable
                    :rules="[requiredRule('document_type', 'PASSPORT_TYPE')]"
                    :disabled="isDisabled"
                >
                    <template v-slot:item="{item}">
                        {{ $t(`documentTypes.${item}`) }}
                    </template>
                    <template v-slot:selection="{item}">
                        {{ $t(`documentTypes.${item}`) }}
                    </template>
                </v-select>
            </v-col>
            <v-col v-if="!isFieldDisabled('PASSPORT_NUMBER')" cols="12" md="3">
                <v-text-field
                    v-model="number"
                    :label="fieldLabel('document_number', 'PASSPORT_NUMBER')"
                    :rules="[requiredRule('document_number', 'PASSPORT_NUMBER')]"
                    :disabled="isDisabled"
                />
            </v-col>
            <v-col v-if="!isFieldDisabled('PASSPORT_ISSUE_DATE')" cols="12" md="3">
                <v-menu
                    v-model="showIssueDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{on}">
                        <v-text-field
                            :value="issueDate | dateShortFormat"
                            :label="fieldLabel('issue_date', 'PASSPORT_ISSUE_DATE')"
                            clearable
                            readonly
                            background-color="white"
                            prepend-inner-icon="mdi-calendar"
                            :rules="[requiredRule('issue_date', 'PASSPORT_ISSUE_DATE')]"
                            :disabled="isDisabled"
                            v-on="on"
                        />
                    </template>
                    <v-date-picker
                        v-model="issueDate"
                        :locale="$i18n.locale"
                        :max="today"
                        @change="showIssueDate = false"
                    />
                </v-menu>
            </v-col>
            <v-col v-if="!isFieldDisabled('PASSPORT_EXPIRE_DATE')" cols="12" md="3">
                <v-menu
                    v-model="showExpireDatePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{on}">
                        <v-text-field
                            :value="expiryDate | dateShortFormat"
                            :label="fieldLabel('expire_date', 'PASSPORT_EXPIRE_DATE')"
                            clearable
                            readonly
                            background-color="white"
                            prepend-inner-icon="mdi-calendar"
                            :rules="[requiredRule('expire_date', 'PASSPORT_EXPIRE_DATE')]"
                            :disabled="isDisabled"
                            v-on="on"
                        />
                    </template>
                    <v-date-picker
                        v-model="expiryDate"
                        :locale="$i18n.locale"
                        :min="today"
                        @change="showExpireDatePicker = false"
                    />
                </v-menu>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
    import {Component, mixins, Prop} from 'nuxt-property-decorator'
    import {authStore, persistentStore, runtimeStore} from '@/store'
    import BookingFieldsMixin from '@/components/booking/mixins/BookingFieldsMixin'

    @Component
    export default class TouristPassportForm extends mixins(BookingFieldsMixin) {
        @Prop({required: true}) index
        @Prop() disabled
        @Prop() corporatePolicyViolation

        showIssueDate = false
        showExpireDatePicker = false

        getProp(prop) {
            return persistentStore.tourists[this.index].passport[prop]
        }

        setProp(prop, val) {
            const index = this.index
            persistentStore.SET_TOURIST_PASSPORT_PROP({prop, val, index})
        }

        get passportTypes() {
            return ['PASSPORT', 'PASSPORT_EXTERNAL', 'BIRTH_CERTIFICATE', 'OTHER']
        }

        get today() {
            return new Date().toISOString()
        }

        get type() {
            return this.getProp('type')
        }

        set type(val) {
            this.setProp('type', val)
        }

        get number() {
            return this.getProp('number')
        }

        set number(val) {
            this.setProp('number', val)
        }

        get issueDate() {
            return this.getProp('issueDate')
        }

        set issueDate(val) {
            this.setProp('issueDate', val)
        }

        get expiryDate() {
            return this.getProp('expiryDate')
        }

        set expiryDate(val) {
            this.setProp('expiryDate', val)
        }

        get personId() {
            return persistentStore.tourists[this.index].personId
        }

        get showTouristSelector() {
            return authStore.person && ['corp.trip_coordinator', 'corp.director'].includes(authStore.person.role)
        }

        get isDisabled() {
            return authStore.user.isEmployee || this.disabled
        }

        get showPassport() {
            const bookingFields = runtimeStore.touristBookingFields.bookingFields
            const passportFieldKeys = [
                //'PASSPORT_ISSUE_COUNTRY',
                'PASSPORT_EXPIRE_DATE',
                'PASSPORT_ISSUE_DATE',
                'PASSPORT_NUMBER',
            ]
            return (
                bookingFields &&
                passportFieldKeys.findIndex(fieldKey => !this.isFieldDisabled(fieldKey)) !== -1 &&
                (!this.showTouristSelector || !this.corporatePolicyViolation || this.personId)
            )
        }
    }
</script>
