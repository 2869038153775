var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('client-only',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.services,"items-per-page":_vm.ordersOnPage,"server-items-length":_vm.ordersCount,"loading":_vm.isLoading,"options":_vm.options,"footer-props":{'items-per-page-options': [10, _vm.ordersOnPage, 100]},"group-by":"orderId","disable-sort":"","no-data-text":_vm.noDataText,"data-cy":"orders-table"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([(_vm.selectable)?{key:"header.id",fn:function(ref){
var header = ref.header;
return [_c('v-checkbox',{attrs:{"dense":"","ripple":false,"hide-details":"","value":false},on:{"change":function($event){return _vm.$emit('selectAllToggle', $event)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(header.text))])]},proxy:true}],null,true),model:{value:(_vm.selectAllFlag),callback:function ($$v) {_vm.selectAllFlag=$$v},expression:"selectAllFlag"}})]}}:null,{key:"group.header",fn:function(ref){
var group = ref.group;
return [_c('td',{staticClass:"text-start",attrs:{"colspan":_vm.headers.length}},[_c('div',{staticClass:"d-flex align-center"},[(_vm.selectable)?_c('v-checkbox',{attrs:{"dense":"","ripple":false,"hide-details":"","value":group},model:{value:(_vm.orderIds),callback:function ($$v) {_vm.orderIds=$$v},expression:"orderIds"}}):_vm._e(),_vm._v(" "),(_vm.selectable)?[_c('n-link',{attrs:{"no-prefetch":"","to":{name: 'order-details', params: {id: group}}}},[_c('strong',[_vm._v(_vm._s(group))])]),_vm._v(" "),(!_vm.orderTitleEdit[group])?_c('span',{staticClass:"ml-2"},[_vm._v("\n                            "+_vm._s(_vm.getOrder(group).title)+"\n                            "),_c('v-icon',{attrs:{"small":"","color":"primary"},on:{"click":function($event){_vm.$set(_vm.orderTitleEdit, group, true)
                                    _vm.$set(_vm.orderTitle, group, _vm.getOrder(group).title)}}},[_vm._v("\n                                mdi-pencil\n                            ")])],1):_c('div',{staticClass:"d-flex align-center ml-4"},[_c('v-text-field',{staticClass:"order-title-edit",attrs:{"dense":"","hide-details":""},model:{value:(_vm.orderTitle[group]),callback:function ($$v) {_vm.$set(_vm.orderTitle, group, $$v)},expression:"orderTitle[group]"}}),_vm._v(" "),_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.saveOrderTitle(group)}}},[_vm._v("\n                                "+_vm._s(_vm.$t('save'))+"\n                            ")])],1)]:_c('n-link',{attrs:{"no-prefetch":"","to":{name: 'order-details', params: {id: group}}}},[_c('strong',[_vm._v(_vm._s(group))])])],2)])]}},{key:"item.created",fn:function(ref){
                                    var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap",domProps:{"innerHTML":_vm._s(item.created)}})]}},{key:"item.title",fn:function(ref){
                                    var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-flex justify-end justify-md-start"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(_vm._s(_vm.getServiceTypeIcon(item.serviceType)))]),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(item.pureTitle)}})],1),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(item.title + item.location)}})])]}},{key:"item.date",fn:function(ref){
                                    var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap",domProps:{"innerHTML":_vm._s(item.date)}})]}},{key:"item.travellers",fn:function(ref){
                                    var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.travellers)}})]}},{key:"item.status",fn:function(ref){
                                    var item = ref.item;
return [_c('span',{class:((item.statusColor) + "--text")},[_vm._v(_vm._s(item.status))]),_vm._v(" "),(_vm.isTO1)?_c('div',[_c('span',{staticClass:"commission"},[_vm._v("\n                    "+_vm._s(item.supplierCompanyName)+"\n                ")])]):_vm._e()]}},{key:"item.supplierPrice",fn:function(ref){
                                    var item = ref.item;
return [(item.supplier.price)?_c('span',[_vm._v("\n                "+_vm._s(_vm._f("priceFormat")(item.supplier.price.amount,item.supplier.price.currency))+"\n            ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"text-no-wrap",class:_vm.paymentStatusClass(
                        item.status,
                        item.supplier.cardGuarantee ? 'pay_at_hotel' : item.supplier.paymentStatus
                    )},[_vm._v("\n                "+_vm._s(item.supplier.cardGuarantee
                        ? _vm.$t("paymentStatus.PAY_AT_HOTEL")
                        : _vm.$t(("paymentStatus." + (item.supplier.paymentStatus))))+"\n            ")])]}},{key:"item.price",fn:function(ref){
                        var item = ref.item;
return [(item.violation && item.customer.price)?_c('div',[_c('span',[_vm._v("\n                    "+_vm._s(_vm._f("priceFormat")(item.customer.price.amount,item.customer.price.currency))+"\n                ")]),_vm._v(" "),_c('span',[_c('corporate-policy-violation',{attrs:{"policies":item.corporatePolicies}})],1)]):_c('span',[_vm._v("\n                "+_vm._s(_vm._f("priceFormat")(item.customer.price.amount,item.customer.price.currency))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"text-no-wrap",class:_vm.paymentStatusClass(item.status, item.customer.paymentStatus)},[_vm._v("\n                "+_vm._s(_vm.$t(("paymentStatus." + (item.customer.paymentStatus))))+"\n            ")]),_vm._v(" "),(_vm.isAgency || _vm.isTO2)?_c('div',{staticClass:"commission"},[_vm._v("\n                "+_vm._s(_vm.$t('commission'))+":\n                "+_vm._s(_vm._f("priceFormat")(item.customer.price.commission,item.customer.price.currency))+"\n            ")]):_vm._e()]}},{key:"item.clientCompanyName",fn:function(ref){
                        var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.clientCompanyName))]),_vm._v(" "),_c('small',[_vm._v(_vm._s(item.agent))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }