<template>
    <v-form v-model="valid" class="mb-5">
        <v-checkbox v-model="termsOfUse" :rules="[v => !!v || $t('confirmation_is_required')]" hide-details>
            <template v-slot:label>
                <div class="confirmation-text" @click.stop v-html="$t('confirmation_terms_of_use')" />
            </template>
        </v-checkbox>
        <v-checkbox v-model="privacyPolicy" :rules="[v => !!v || $t('confirmation_is_required')]" hide-details>
            <template v-slot:label>
                <div class="confirmation-text" @click.stop v-html="$t('confirmation_privacy_policy')" />
            </template>
        </v-checkbox>
    </v-form>
</template>

<script>
    import {Vue, Component, PropSync} from 'vue-property-decorator'

    @Component
    export default class BookingAgreementForm extends Vue {
        @PropSync('_valid', {default: false}) valid
        privacyPolicy = false
        termsOfUse = false
    }
</script>

<style scoped lang="scss">
    .confirmation-text {
        font-size: 0.875rem;
    }
</style>
