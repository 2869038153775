<template>
    <v-container ref="container" fill-height fluid :style="containerStyle">
        <v-card outlined tile class="mx-auto v-card-transparent" min-width="290">
            <v-card-title class="white--text title word-break">
                {{ $t('sign_in_as_b2b') }}
            </v-card-title>
            <v-card-text class="white--text">
                <b2b-authorization-form
                    @auth-success="
                        $event == 'INTERNAL_SUPPLIER' ? $router.push({name: 'supplier'}) : $router.push({name: 'home'})
                    "
                    @switch-forgot-password="forgotPasswordModal = true"
                />
            </v-card-text>
        </v-card>
        <v-dialog v-model="forgotPasswordModal" hide-overlay width="500">
            <v-card>
                <v-card-title class="title font-weight-light">
                    {{ $t('forgot_your_password') }}
                </v-card-title>
                <v-card-text>
                    <forgot-b2-b-password-form
                        @switch-login="forgotPasswordModal = false"
                        @forgot-password-success="forgotPasswordModal = false"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import B2bAuthorizationForm from '@/components/account/forms/B2bAuthorizationForm'
    import ForgotB2BPasswordForm from '@/components/account/forms/ForgotB2BPasswordForm'

    @Component({
        components: {ForgotB2BPasswordForm, B2bAuthorizationForm},
        layout: 'blank',
        middleware: 'auth',
    })
    export default class B2bAuthorizationPage extends Vue {
        forgotPasswordModal = false

        get containerStyle() {
            return {
                'background-image': 'url(/b2b-login-bg.jpg)',
                'background-size': 'cover',
                'background-position': '50%',
            }
        }
    }
</script>

<style scoped lang="scss">
    .v-card-transparent {
        border: 0;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.6);

        .v-input .v-input__slot {
            border-radius: 4px;
        }
    }

    .word-break {
        word-break: break-word;
    }
</style>
