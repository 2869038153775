var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('flight-booking-page-layout',{attrs:{"offer":_vm.offer,"search-request":_vm.searchRequest},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(){return [_c('expired-offers-snackbar',{attrs:{"is-offers-expired":_vm.isOffersExpired},on:{"refresh":_vm.refreshExpiredOffers}}),_vm._v(" "),_c('flight-breadcrumbs',{attrs:{"search-request":_vm.searchRequest}})]},proxy:true},{key:"tourists",fn:function(ref){
var options = ref.options;
return [_c('flight-tourist-form',{attrs:{"disabled":options.disabled,"index":options.index,"offer":_vm.offer}})]}},{key:"orderCommentsForm",fn:function(ref){
var bookingKey = ref.bookingKey;
return [_c('booking-comments-form',{attrs:{"disabled":!!bookingKey,"product-store":_vm.productStore}})]}},{key:"forms",fn:function(ref){
var bookingKey = ref.bookingKey;
return [_c('flight-seats-map',{attrs:{"offer":_vm.offer,"disabled":!!bookingKey,"search-request":_vm.searchRequest}})]}},{key:"sidebar",fn:function(ref){
var bookingKey = ref.bookingKey;
return [_c('flight-search-summary',{attrs:{"search-request":_vm.searchRequest,"search-disabled":""}}),_vm._v(" "),_c('flight-order-summary',{attrs:{"offer":_vm.offer,"prebooked":!!bookingKey}})]}}],null,false,204060260)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }