<template>
    <search-form-layout :popup="popup" title="hotels" @close-popup="$emit('close-popup')">
        <template v-slot:form>
            <v-form ref="form" v-model="valid" data-cy="hotels-form">
                <v-row>
                    <v-col md="5" cols="12">
                        <daterange-m :start-date-prop.sync="startDate" :end-date-prop.sync="endDate" />
                    </v-col>
                    <v-col md="5" cols="12">
                        <rooms-select v-model="rooms" />
                    </v-col>
                    <v-col md="2" cols="12">
                        <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                            {{ $t('search') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <additional-options-wrapper ref="addForm" :options="['promocode', 'meal', 'rating']" />
        </template>
    </search-form-layout>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import HotelSearchForm from '~/components/search/forms/HotelSearchForm'
    import {hotelsStore} from '@/store'
    import CityHotelAutocomplete from '~/components/search/forms/CityHotelAutocomplete'
    import TouristsSelect from '~/components/search/forms/TouristsSelect'
    import RoomsSelect from '~src/components/search/forms/roomsSelect.src'
    import AdditionalOptionsWrapper from '~/components/snippets/AdditionalSearchOptions/AdditionalOptionsWrapper'
    import SearchFormLayout from '~/components/parts/SearchFormLayout'
    import DaterangeM from '~src/components/search/forms/daterangeM.src'

    @Component({
        components: {
            CityHotelAutocomplete,
            DaterangeM,
            TouristsSelect,
            RoomsSelect,
            AdditionalOptionsWrapper,
            SearchFormLayout,
        },
    })
    export default class HotelSearchFormMod extends HotelSearchForm {
        async load() {
            await HotelSearchForm.options.methods.load.call(this)
            if (!hotelsStore.city || hotelsStore.city.id !== CONFIG.defaultCityId) {
                this.city = (await this.$api.locations.get({id: CONFIG.defaultCityId, limitCities: 1})).cities[0]
                await hotelsStore.setCity(this.city)
            }
        }
    }
</script>
