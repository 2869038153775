<template>
    <v-dialog
        v-if="ownProduct"
        :value="!!vendorMessages.length"
        overlay-opacity="0"
        width="100%"
        max-width="600"
        persistent
    >
        <v-card>
            <v-card-text class="text-center pt-8">
                <div class="warning--text text-h5 mb-2">
                    <v-icon large color="warning">
                        mdi-alert-outline
                    </v-icon>
                    {{ $t('important_information') }}
                </div>
                <div class="mb-2">
                    {{ $t('vendor_message_note') }}
                </div>
                <div
                    v-for="(vendorMessage, index) in vendorMessages"
                    :key="index"
                    class="font-weight-bold"
                    v-html="vendorMessage"
                />
            </v-card-text>
            <v-divider />
            <v-card-actions class="justify-center">
                <v-btn color="primary" text @click="vendorMessages = []">
                    {{ $t('continue') }}
                </v-btn>
                <v-btn text @click="cancel">
                    {{ $t('cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue, Prop, Emit, Watch} from 'vue-property-decorator'
    import {runtimeStore} from '@/utils/store-accessor'

    @Component
    export default class VendorMessagesModal extends Vue {
        @Prop({required: true}) prepareBookResponse
        @Prop({required: true}) offer

        vendorMessages = []

        @Emit()
        cancel() {
            this.vendorMessages = []
        }

        @Watch('prepareBookResponse')
        changePrepareBookResponse() {
            if (this.prepareBookResponse.prepareBookResponses) {
                this.vendorMessages = this.prepareBookResponse.prepareBookResponses.reduce(
                    (vendorMessages, prepareBookResponse) => [
                        ...vendorMessages,
                        ...(prepareBookResponse.vendorMessages || []),
                    ],
                    []
                )
            } else {
                this.vendorMessages = this.prepareBookResponse.vendorMessages || []
            }
        }

        get ownProduct() {
            return runtimeStore.ownProduct(this.offer.supplierCode)
        }
    }
</script>
