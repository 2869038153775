<script>
    import {Component, Prop} from 'vue-property-decorator'
    import {hotelsRuntimeStore} from '~/store'
    import Breadcrumbs from '~/components/Breadcrumbs'

    function addHotelsPageLink(items) {
        items.push({
            text: `${hotelsRuntimeStore.city.name}, ${hotelsRuntimeStore.city.countryName}`,
            to: hotelsRuntimeStore.hotelsPageLink(this.searchRequest),
            nuxt: true,
            exact: true,
        })
    }

    function addHotelPageLink(items) {
        items.push({
            text: this.offer.name,
            to: hotelsRuntimeStore.hotelPageLink(this.offer, this.searchRequest),
            nuxt: true,
            exact: true,
        })
    }

    @Component
    export default class HotelBreadcrumbs extends Breadcrumbs {
        @Prop({required: true}) searchRequest
        @Prop({default: () => {}}) offer

        get items() {
            const items = [this.homeItem]
            switch (this.$route.name) {
                case 'hotels':
                    items.push({
                        text: `${hotelsRuntimeStore.city.name}, ${hotelsRuntimeStore.city.countryName}`,
                        disabled: true,
                    })
                    break
                case 'comparison':
                    addHotelsPageLink.call(this, items)
                    items.push({
                        text: this.$t('compare_hotels'),
                        disabled: true,
                    })
                    break
                case 'hotel':
                    addHotelsPageLink.call(this, items)
                    items.push({
                        text: hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo.hotelName,
                        disabled: true,
                    })
                    break
                case 'hotelBooking':
                    addHotelsPageLink.call(this, items)
                    addHotelPageLink.call(this, items)
                    items.push({
                        text: this.$t('reservation'),
                        disabled: true,
                    })
                    break
                case 'hotelConfirmation':
                    addHotelsPageLink.call(this, items)
                    addHotelPageLink.call(this, items)
                    items.push({
                        text: this.$t('confirmation'),
                        disabled: true,
                    })
                    break
            }
            return items
        }
    }
</script>
