<template>
    <v-card outlined tile>
        <v-card-title class="title">
            {{ $t(`transfers.${pointType}_location`) }} - {{ $t('train_station') }}
        </v-card-title>
        <v-card-text class="white--text">
            <v-form ref="form" v-model="valid">
                <train-stations-search-input
                    v-model="cityId"
                    :outlined="true"
                    :label="
                        $t(
                            pointType === 'arrival'
                                ? 'transfers.departure_to_train_station'
                                : 'transfers.arriving_from_train_station'
                        )
                    "
                />
                <v-text-field
                    v-model="trainStation"
                    :label="`${$t('transfers.train_number')}*`"
                    :rules="[v => !!v || `${$t('transfers.train_number')} ${$t('validation.required')}`]"
                    :disabled="disabled"
                    required
                />
                <v-menu
                    v-model="showDatePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    :disabled="disabled"
                >
                    <template v-slot:activator="{on}">
                        <v-text-field
                            :value="date | dateFormat"
                            :label="`${$t('date')}*`"
                            clearable
                            readonly
                            background-color="white"
                            prepend-inner-icon="mdi-calendar"
                            :rules="[v => !!v || `${$t('date')} ${$t('validation.required')}`]"
                            required
                            :disabled="disabled"
                            v-on="on"
                        />
                    </template>
                    <v-date-picker
                        v-model="date"
                        :locale="$i18n.locale"
                        :min="minDate"
                        :max="maxDate"
                        @change="showDatePicker = false"
                    />
                </v-menu>
                <v-menu
                    v-model="showTimePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{on}">
                        <v-text-field
                            :value="time"
                            :label="`${$t('time')}*`"
                            readonly
                            background-color="white"
                            prepend-inner-icon="mdi-clock"
                            :rules="[v => !!v || `${$t('time')} ${$t('validation.required')}`]"
                            required
                            :disabled="disabled"
                            v-on="on"
                        />
                    </template>
                    <!--v-time-picker v-model="time" :locale="$i18n.locale" @change="showTimePicker = false" /-->
                    <time-picker v-model="time" @update="showTimePicker = false" />
                </v-menu>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    import {Component, Vue, Prop, PropSync} from 'vue-property-decorator'
    import {transfersStore} from '@/utils/store-accessor'
    import TrainStationsSearchInput from '~/components/snippets/TrainStationsSearchInput'
    import TimePicker from '@/components/snippets/forms/TimePicker'

    //TODO Need mixin with TransferAirportForm
    @Component({
        components: {TimePicker, TrainStationsSearchInput},
    })
    export default class TransferTrainStationForm extends Vue {
        @Prop() pointType
        @Prop() disabled
        @Prop() searchRequest
        @PropSync('_valid', {default: false}) valid

        showDatePicker = false
        showTimePicker = false

        getProp(prop) {
            return transfersStore.prepareBookRequest[this.pointType][prop]
        }

        setProp(prop, val) {
            transfersStore.SET_PREPARE_BOOK_LOCATION_PROP({prop, val, pointType: this.pointType})
        }

        splitDateTime() {
            let dateTime = this.getProp('dateTime')
            if (dateTime) {
                dateTime = dateTime.split('T')
            } else {
                dateTime = []
            }
            return dateTime
        }

        get trainNumber() {
            return this.getProp('trainNumber')
        }

        set trainNumber(val) {
            this.setProp('trainNumber', val)
        }

        get date() {
            const dateTime = this.getProp('dateTime')
            if (!dateTime) return dateTime
            return dateTime.split('T')[0]
        }

        set date(val) {
            const dateTime = this.splitDateTime()
            dateTime[0] = val
            this.setProp('dateTime', dateTime.join('T'))
        }

        get time() {
            const dateTime = this.getProp('dateTime')
            if (!dateTime) return dateTime
            return dateTime.split('T')[1]
        }

        set time(val) {
            const dateTime = this.splitDateTime()
            dateTime[1] = val
            this.setProp('dateTime', dateTime.join('T'))
        }

        get cityId() {
            return this.getProp('cityId')
        }

        set cityId(val) {
            this.setProp('cityId', val)
        }

        get minDate() {
            if (this.pointType === 'arrival') {
                return this.searchRequest.dateTime
            } else {
                return this.$dateFns.format(this.$dateFns.subDays(this.searchRequest.dateTime, 1))
            }
        }

        get maxDate() {
            if (this.pointType === 'arrival') {
                return this.$dateFns.format(this.$dateFns.addDays(this.searchRequest.dateTime, 1))
            } else {
                return this.searchRequest.dateTime
            }
        }
    }
</script>
