<template>
    <search-layout v-if="loaded" :map-expanded="mapExpanded">
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="search" />
            <activity-breadcrumbs :search-request="searchRequest" />
        </template>
        <template v-slot:left-sidebar>
            <activity-search-summary :search-request="searchRequest" />
            <sort-select v-model="sortKey" :items="sortItems" />
            <activity-filters
                :filter-values="filterValues"
                :filters="searchResponse.filters"
                :filtered-offers-count="filteredOffers.length"
                :currency="currency"
                @change="changeFilter"
                @reset="resetFilters"
            />
        </template>
        <template v-slot:main-content>
            <search-progress
                :filtered-offers-count="filteredOffers.length"
                :total-offers-count="searchResponse.product.length"
                :search-active="searchActive"
                @stop="stopSearch"
            />
            <activity-offers :offers="offers" />
            <pagination :page-prop.sync="page" :pages="pages" />
            <conditions-modal>
                <template v-slot:info>
                    <activity-conditions-modal-info />
                </template>
            </conditions-modal>
            <activity-info-modal />
        </template>
        <template v-slot:right-sidebar>
            <client-only>
                <activity-map :_expanded.sync="mapExpanded" :city="city" />
            </client-only>
        </template>
    </search-layout>
</template>

<script>
    import {Component, mixins, Watch} from 'nuxt-property-decorator'
    import MobileSearchPageTabs from '@/components/snippets/MobileSearchPageTabs'
    import Pagination from '~/components/search/Pagination'
    import ConditionsModal from '~/components/modals/ConditionsModal'
    import ActivityBreadcrumbs from '@/components/activities/ActivityBreadcrumbs'
    import ActivityOffers from '@/components/activities/search/offers/ActivityOffers'
    import ActivityFilters from '@/components/activities/search/ActivityFilters'
    import ActivitySearchSummary from '@/components/activities/search/ActivitySearchSummary'
    import ActivityMap from '@/components/activities/search/ActivityMap'
    import ActivityInfoModal from '@/components/activities/info/ActivityInfoModal'
    import SearchPageBase from '@/mixins/SearchPageBase'
    import {searchRequest} from '@/utils/activities/activities-blank-states'
    import ActivitiesStoreMixin from '@/mixins/ActivityStoreMixin'
    import {activitiesRuntimeStore, persistentStore} from '~/store'
    import ActivityPageMixin from '@/components/activities/mixins/ActivityPageMixin'
    import {isEqual} from '@/utils/helpers'
    import ExpiredOffersSnackbar from '@/components/search/ExpiredOffersSnackbar'
    import SearchProgress from '~src/components/search/searchProgress.src'
    import SortSelect from '@/components/search/SortSelect'
    import ActivityConditionsModalInfo from '@/components/activities/snippets/ActivityConditionsModalInfo'

    @Component({
        components: {
            ActivityConditionsModalInfo,
            SortSelect,
            SearchProgress,
            ExpiredOffersSnackbar,
            ActivityOffers,
            Pagination,
            MobileSearchPageTabs,
            ActivityBreadcrumbs,
            ActivityFilters,
            ActivitySearchSummary,
            ActivityMap,
            ActivityInfoModal,
            ConditionsModal,
            SearchLayout: () => import('~src/components/parts/searchLayout.src'),
        },
        layout: 'blank',
    })
    export default class ActivitiesPage extends mixins(ActivityPageMixin, ActivitiesStoreMixin, SearchPageBase) {
        loaded = false

        @Watch('$route.query')
        onQueryChange() {
            this.search()
        }

        validate({query}) {
            return query.cityId
        }

        async fetch() {
            await this.loadRuntimeData()
        }

        async mounted() {
            await this.$store.restored
            this.loaded = true
            if (
                !isEqual(this.productStore.searchRequest, this.searchRequest) ||
                this.isOffersExpired() ||
                !this.productStore.hasOffers
            ) {
                await this.search()
            } else if (this.productRuntimeStore.offers.length === 0) {
                this.resetPageLock = true
                this.filter()
            }
        }

        async search() {
            await this.productRuntimeStore.search(this.searchRequest)
        }

        filter() {
            this.productRuntimeStore.filter()
        }

        changeFilter(data) {
            this.productStore.SET_FILTER(data)
            this.filter()
        }

        resetFilters() {
            this.productStore.RESET_FILTERS()
            this.productRuntimeStore.sort(this.searchResponse.product)
        }

        isOffersExpired() {
            return this.productStore.isOffersExpired()
        }

        stopSearch() {
            this.productRuntimeStore.stopSearch()
        }

        get searchRequest() {
            //TODO Need filter $route.query params
            const rq = Object.assign(searchRequest(), this.$route.query)
            if (typeof rq.childrenAges === 'string') {
                rq.childrenAges = [parseInt(rq.childrenAges, 10)]
            }
            rq.convertToCurrency = persistentStore.currency
            rq.adults = parseInt(rq.adults, 10)
            rq.cityId = parseInt(rq.cityId, 10)
            rq.privateActivity = rq.privateActivity === 'true' || rq.privateActivity === true
            rq.sharedActivity = rq.sharedActivity === 'true' || rq.sharedActivity === true
            rq.hikingActivity = rq.hikingActivity === 'true' || rq.hikingActivity === true
            if (rq.citizenshipId) {
                rq.citizenshipId = parseInt(rq.citizenshipId, 10)
            }
            return rq
        }

        get mapPoints() {
            return [{lat: activitiesRuntimeStore.city.latitude, lng: activitiesRuntimeStore.city.longitude}]
        }

        get sortKey() {
            return this.productStore.sortFnName
        }

        set sortKey(sortKey) {
            this.productStore.SET_SORT(sortKey)
            this.productRuntimeStore.sort(this.filteredOffers)
        }

        get filteredOffers() {
            return this.productRuntimeStore.offers
        }

        get filterValues() {
            return this.productStore.filters
        }

        get searchActive() {
            return this.productRuntimeStore.searchActive
        }

        get searchResponse() {
            return this.productStore.searchResponse
        }

        get city() {
            return this.productRuntimeStore.city
        }

        get sortItems() {
            return [
                {
                    text: this.$t('sort.price_desc'),
                    value: 'priceAsc',
                },
                {
                    text: this.$t('sort.price_asc'),
                    value: 'priceDesc',
                },
            ]
        }

        get currency() {
            return this.productStore.searchRequest.convertToCurrency
        }
    }
</script>
