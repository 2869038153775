<template>
    <div>
        <v-row no-gutters class="mb-5" :class="{'ml-0 ml-md-6': !fullCard, 'px-4': $breakpoint.smAndDown && !fullCard}">
            <v-col cols="12" md="4" class="mr-0 mr-md-3">
                <v-select
                    v-model="selectedSort"
                    label=""
                    :item-text="item => $t(`sort.${item}`)"
                    :items="['price_asc', 'price_desc']"
                    hide-details
                    @change="$emit('sortSelected', $event)"
                >
                    <template v-slot:item="{item}">
                        {{ $t(`sort.${item}`) }}
                    </template>
                    <template v-slot:selection="{item}">
                        {{ $t(`sort.${item}`) }}
                    </template>
                </v-select>
            </v-col>
            <v-col cols="12" md="3">
                <v-select
                    v-model="selectedGroupBy"
                    :label="$t('group_by')"
                    :items="['none', 'name', 'mealTypeStandardCode']"
                    hide-details
                    @change="$emit('groupSelected', $event)"
                >
                    <template v-slot:item="{item}">
                        <div>
                            {{ $t(`roomGroupBy.${item}`) }}
                        </div>
                    </template>
                    <template v-slot:selection="{item}">
                        <div>
                            {{ $t(`roomGroupBy.${item}`) }}
                        </div>
                    </template>
                </v-select>
            </v-col>
        </v-row>
        <v-expansion-panels v-if="selectedGroupBy !== 'none'" v-model="panels" multiple flat focusable accordion>
            <v-expansion-panel v-for="(rooms, name, index) in groupedRooms" :key="`group-${index}`">
                <v-expansion-panel-header
                    class="text-nobreak"
                    :class="$breakpoint.smAndDown ? 'body-2' : 'subtitle-1'"
                    color="blue-grey lighten-5"
                >
                    <div>
                        <v-icon
                            v-if="selectedGroupBy === 'name'"
                            left
                            small
                            class="info--text"
                            @click.native.stop="roomInfo(offer, rooms[0].groupedOffers[0].offerKey)"
                        >
                            mdi-information-outline
                        </v-icon>
                        {{ selectedGroupBy === 'name' ? name : $t(`filters_mealTypes.${name}`) }}
                    </div>
                    <strong class="text-right pr-4">
                        <v-chip
                            v-if="selectedGroupBy !== 'mealTypeStandardCode'"
                            label
                            x-small
                            :color="minPriceRoomOffer(rooms).available ? 'green darken-1' : '#d79600'"
                            class="flex-grow-0 mr-4 white--text"
                        >
                            {{ minPriceRoomOffer(rooms).available ? $t('available') : $t('on_request') }}
                        </v-chip>
                        <template v-if="minPriceRoomOffer(rooms).price">
                            {{ $t('from') }} {{ minPriceRoomOffer(rooms).price | price }}
                        </template>
                    </strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-col v-if="getRoomImage(rooms[0])" cols="2">
                            <v-img :src="getRoomImage(rooms[0]) | imageUrl(600)" />
                        </v-col>
                        <v-col :cols="getRoomImage(rooms[0]) ? 10 : 12">
                            <div v-for="roomGroupIndex in searchRoomsCount" :key="roomGroupIndex" class="my-2">
                                <v-card-title
                                    v-if="searchRoomsCount > 1 && findGroupInRoomOffers(roomGroupIndex, rooms).length"
                                    class="title font-weight-light pl-0"
                                >
                                    {{ $tc('room', 1) }} {{ roomGroupIndex }}
                                </v-card-title>
                                <template
                                    v-for="(room, roomIndex) in findGroupInRoomOffers(roomGroupIndex, rooms).slice(
                                        0,
                                        expanded[roomGroupIndex]
                                    )"
                                >
                                    <div :key="`parent-${roomIndex}-${index}`" :class="roomGroupClass(room)">
                                        <v-row no-gutters>
                                            <v-col cols="12" md="10" class="mb-3 mb-md-0">
                                                <v-row
                                                    no-gutters
                                                    :class="{caption: $breakpoint.smAndDown}"
                                                    class="mb-3"
                                                >
                                                    <v-col cols="10" md="8">
                                                        <div>
                                                            <v-row no-gutters class="caption">
                                                                <v-col
                                                                    v-if="selectedGroupBy === 'mealTypeStandardCode'"
                                                                    cols="12"
                                                                >
                                                                    <v-icon
                                                                        v-if="room.available"
                                                                        :title="$t('available')"
                                                                        small
                                                                        left
                                                                        class="success--text"
                                                                    >
                                                                        mdi-checkbox-marked-circle
                                                                    </v-icon>
                                                                    <v-icon
                                                                        v-else
                                                                        :title="$t('on_request')"
                                                                        small
                                                                        left
                                                                        class="warning--text"
                                                                    >
                                                                        mdi-alert-circle
                                                                    </v-icon>
                                                                    <span
                                                                        v-if="
                                                                            room.availableRooms &&
                                                                                room.availableRooms === 1
                                                                        "
                                                                        class="success--text"
                                                                        v-text="$t('available_one_room')"
                                                                    />
                                                                    <span
                                                                        v-else-if="
                                                                            room.availableRooms &&
                                                                                room.availableRooms < 5
                                                                        "
                                                                        class="success--text"
                                                                        v-text="
                                                                            $tc('available_rooms', room.availableRooms)
                                                                        "
                                                                    />
                                                                    <span
                                                                        v-else-if="room.availableRooms"
                                                                        class="success--text"
                                                                        v-text="$t('enough_available_rooms')"
                                                                    />
                                                                    <span
                                                                        v-else-if="!room.available"
                                                                        class="warning--text"
                                                                        v-text="$t('on_request')"
                                                                    />
                                                                    <span
                                                                        v-else
                                                                        class="success--text"
                                                                        v-text="$t('available')"
                                                                    />
                                                                </v-col>
                                                                <v-col cols="12" class="mt-1">
                                                                    <hotel-meal-type-label
                                                                        :room="room"
                                                                        :hotel="offer"
                                                                    />
                                                                    <v-icon
                                                                        v-if="selectedGroupBy !== 'name'"
                                                                        left
                                                                        small
                                                                        class="info--text"
                                                                        @click="
                                                                            roomInfo(
                                                                                offer,
                                                                                room.groupedOffers[0].offerKey
                                                                            )
                                                                        "
                                                                    >
                                                                        mdi-information-outline
                                                                    </v-icon>
                                                                    <template v-if="selectedGroupBy !== 'name'">
                                                                        {{ room.name }}
                                                                    </template>
                                                                    <tariff-label
                                                                        class="mt-1"
                                                                        :room="room"
                                                                        :hotel="offer"
                                                                    />
                                                                </v-col>
                                                                <v-col cols="12" class="mt-1">
                                                                    <conditions-label
                                                                        v-if="
                                                                            (room && room.price) ||
                                                                                (packagesNotDeltaPrice &&
                                                                                    room.notDeltaPrice)
                                                                        "
                                                                        :offer-key="room.groupedOffers[0].offerKey"
                                                                        :price="room.price || room.notDeltaPrice"
                                                                        @conditions="showConditionsInfo(room)"
                                                                    />
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        md="4"
                                                        class="ml-0 d-flex flex-column align-md-end align-start px-md-2 pl-0 mt-2"
                                                        :class="
                                                            showDiscount(room) ? 'justify-md-top' : 'justify-md-center'
                                                        "
                                                    >
                                                        <supplier-price :supplier-price="room.supplierPrice" />
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12" md="2" class="d-flex align-center justify-end text-center">
                                                <slot v-if="searchRoomsCount === 1" name="price" :room="room">
                                                    <hotel-room-offer-price-block
                                                        :room="room"
                                                        :search-request="searchRequest"
                                                    />
                                                </slot>
                                                <div
                                                    v-if="searchRoomsCount > 1"
                                                    :key="room.groupedOffers[0].offerKey + '_1'"
                                                    class="pb-3 px-3 text-center"
                                                >
                                                    <hotel-room-offer-price-block
                                                        :room="room"
                                                        :search-request="searchRequest"
                                                        :rph="roomGroupIndex"
                                                    />
                                                    <v-chip
                                                        v-for="groupedOffer in findGroup(room, roomGroupIndex)"
                                                        :key="groupedOffer.offerKey"
                                                        small
                                                        label
                                                        :disabled="checkRestrictions(room.hotelSupplierId)"
                                                        class="mr-1 mt-1"
                                                        :class="{
                                                            primary: isRoomOfferSelected(groupedOffer.offerKey),
                                                            'text-capitalize': true,
                                                        }"
                                                        @click="selectRoomOffer(room, groupedOffer)"
                                                    >
                                                        {{ $t('select') }}
                                                        <v-icon
                                                            v-for="adult in searchRequestTourists[groupedOffer.rph - 1]
                                                                .adults"
                                                            :key="adult"
                                                            right
                                                            small
                                                        >
                                                            mdi-account
                                                        </v-icon>
                                                        <v-icon
                                                            v-for="(child, childIndex) in searchRequestTourists[
                                                                groupedOffer.rph - 1
                                                            ].childrenAges"
                                                            :key="childIndex"
                                                            right
                                                            x-small
                                                        >
                                                            mdi-account
                                                        </v-icon>
                                                    </v-chip>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <v-divider
                                        v-if="roomIndex !== rooms.length - 1"
                                        :key="`room-${roomIndex}-${index}`"
                                        class="my-2"
                                    />
                                </template>
                                <v-list-item
                                    v-if="roomsTotal(roomGroupIndex, rooms) > expanded[roomGroupIndex]"
                                    dense
                                    class="d-flex justify-center"
                                    @click.stop="$emit('more-rooms', roomGroupIndex)"
                                >
                                    <v-icon>mdi-chevron-down</v-icon>
                                </v-list-item>
                            </div>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    import {Component, Vue, Prop, Watch, PropSync} from 'vue-property-decorator'
    import {EventBus, SEARCH_EVENT, SHOW_HOTEL_CONDITIONS_INFO, SHOW_ROOM_INFO_MODAL_EVENT} from '@/utils/event-bus'
    import {hotelsRuntimeStore, hotelsStore, runtimeStore} from '~/store'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import ConditionsLabel from '~src/components/snippets/conditionsLabel.src'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import CorporatePolicyViolation from '@/components/snippets/CorporatePolicyViolation'
    import HotelRoomOfferPriceBlock from '~src/components/search/offers/hotelRoomOfferPriceBlock.src'
    import SupplierPrice from '~/components/snippets/SupplierPrice'
    import HotelPriceNote from '@/components/hotels/snippets/HotelPriceNote'
    import HotelMealTypeLabel from '@/components/hotels/snippets/HotelMealTypeLabel'
    import TariffLabel from '~src/components/hotels/snippets/tariffLabel.src'

    @Component({
        components: {
            HotelMealTypeLabel,
            HotelPriceNote,
            DiscountLabel,
            ConditionsLabel,
            CommissionAmount,
            CorporatePolicyViolation,
            HotelRoomOfferPriceBlock,
            SupplierPrice,
            TariffLabel,
        },
    })
    export default class HotelGroupedRoomOffers extends Vue {
        @Prop({default: () => []}) rooms
        @Prop() offer
        @Prop({default: true}) fullCard
        @Prop({required: true}) searchRequest
        @PropSync('_selectedOfferKeys', {default: () => []}) selectedOfferKeys
        @PropSync('_expanded', {default: () => []}) expanded
        @PropSync('_canBook', {default: () => false}) canBook

        @Watch('selectedGroupBy')
        onGroupByChanged() {
            this.panels = [0]
        }

        getRoomImage(room) {
            if (hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo.roomTypes) {
                const roomInfo = hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo.roomTypes.roomType.find(
                    roomType => roomType.code === room.code
                )
                const images = roomInfo && roomInfo.images ? roomInfo.images.image : []
                return images[0] ? images[0].url : null
            }
            return null
        }
        panels = [0]
        selectedSort = 'price_desc'
        selectedGroupBy = 'none'
        selectedOffers = []
        roomGroupIds = []
        firstChosenRoom = null
        canBookCheck = []

        sortFns = {
            price_desc: (a, b) => {
                const priceA = a.price || a.deltaPrice
                const priceB = b.price || b.deltaPrice
                return priceA.amount - priceB.amount
            },
            price_asc: (a, b) => {
                const priceA = a.price || a.deltaPrice
                const priceB = b.price || b.deltaPrice
                return priceB.amount - priceA.amount
            },
            name: (offer1, offer2) => {
                if (offer1.name < offer2.name) return -1
                if (offer1.name > offer2.name) return 1
                return 0
            },
        }

        created() {
            EventBus.$on(SEARCH_EVENT, this.clearSelectedOfferKeys)
        }

        beforeDestroy() {
            EventBus.$off(SEARCH_EVENT, this.clearSelectedOfferKeys)
        }

        clearSelectedOfferKeys() {
            this.selectedOfferKeys = []
            this.canBook = false
        }

        showConditionsInfo(room) {
            EventBus.$emit(SHOW_HOTEL_CONDITIONS_INFO, this.searchRequest, this.offer, room)
        }

        get searchRoomsCount() {
            return hotelsStore.searchRequest.rooms.length
        }

        get searchRequestTourists() {
            return hotelsStore.searchRequestTourists
        }

        isRoomOfferSelected(offerKey) {
            return this.selectedOfferKeys.indexOf(offerKey) !== -1
        }

        selectRoomOffer(selectedRoom, {offerKey, rph}) {
            this.rooms.forEach(room => {
                const selectedIndex = room.groupedOffers.findIndex(
                    groupedOffer => this.isRoomOfferSelected(groupedOffer.offerKey) && groupedOffer.rph === rph
                )
                if (selectedIndex !== -1) {
                    const selectionIndex = this.selectedOfferKeys.indexOf(room.groupedOffers[selectedIndex].offerKey)
                    this.$delete(this.selectedOfferKeys, selectionIndex)
                    this.$delete(this.selectedOffers, selectionIndex)
                }
            })
            this.selectedOfferKeys.push(offerKey)
            this.selectedOffers.push(selectedRoom)
            if (
                !this.firstChosenRoom ||
                (this.firstChosenRoom === rph && this.roomGroupIds !== selectedRoom.roomGroupIds)
            ) {
                this.roomGroupIds = selectedRoom.roomGroupIds
                this.firstChosenRoom = rph
                this.canBookCheck = []
            }
            this.canBookCheck[rph] = this.canBookForMultiRoom(selectedRoom)
            let canBookArr = this.canBookCheck.filter(el => {
                return el === true
            })
            this.canBook = canBookArr.length === this.searchRoomsCount
        }

        roomInfo(hotel, offerKey) {
            EventBus.$emit(SHOW_ROOM_INFO_MODAL_EVENT, hotel, offerKey)
        }

        selectForBooking(room) {
            const offerKey = [room.groupedOffers[0].offerKey]
            hotelsStore.ADD_TO_BASKET(offerKey)
            this.$router.push({
                name: 'hotelBooking',
                query: {offerKey},
            })
        }

        get offersToCompare() {
            return hotelsStore.offersToCompare
        }

        get selectedRooms() {
            const selectedOffer = this.offersToCompare.find(el => {
                return el.hotelCode === this.offer.hotelCode
            })
            return selectedOffer ? selectedOffer.rooms : []
        }

        set selectedRooms(offerKey) {
            hotelsStore.ADD_ROOM_TO_COMPARE({hotelCode: this.offer.hotelCode, rooms: offerKey})
        }

        findGroupInRoomOffers(groupIndex, rooms) {
            let result = []
            rooms.forEach(room => {
                const findGroup = room.groupedOffers.findIndex(groupedOffer => groupedOffer.rph === groupIndex)
                if (findGroup !== -1) {
                    result.push(room)
                }
            })

            return result
        }

        findGroup(room, groupIndex) {
            return [room.groupedOffers.filter(roomGroup => roomGroup.rph === groupIndex)[0]]
        }

        checkRestrictions(hotelSupplierId) {
            if (!this.selectedOffers.length) return false
            return hotelSupplierId && this.selectedOffers.hotelSupplierId === hotelSupplierId
        }

        roomsTotal(groupIndex, rooms) {
            return this.offer ? this.findGroupInRoomOffers(groupIndex, rooms).length : 0
        }

        get sortedRooms() {
            const rooms = [...this.rooms]
            return rooms.sort(this.sortFns[this.selectedSort])
        }

        get groupedRooms() {
            const result = {}
            this.sortedRooms.forEach(room => {
                if (!result[room[this.selectedGroupBy]]) {
                    result[room[this.selectedGroupBy]] = []
                }
                result[room[this.selectedGroupBy]].push(room)
            })
            return result
        }

        minPriceRoomOffer(rooms) {
            return hotelsRuntimeStore.minPriceRoomOffer({rooms})
        }

        canBookForMultiRoom(room) {
            if (!room.roomGroupIds) return true
            let arrIntersect = this.roomGroupIds.filter(function(n) {
                return room.roomGroupIds.indexOf(n) !== -1
            })

            return arrIntersect.length > 0
        }

        roomGroupClass(room) {
            return this.searchRoomsCount > 1 &&
                this.roomGroupIds &&
                this.roomGroupIds.length &&
                this.canBookForMultiRoom(room.groupedOffers[0])
                ? 'green lighten-5'
                : ''
        }

        showDiscount(room) {
            return room.price && room.price.discount && !room.price.hiddenDiscount
        }

        get packagesNotDeltaPrice() {
            return runtimeStore.config.packagesNotDeltaPrice
        }
    }
</script>

<style scoped lang="scss">
    .title {
        text-transform: capitalize;
    }

    ::v-deep .v-select__selections {
        font-size: 13px;
    }

    ::v-deep .v-list-item--link {
        font-size: 12px;
    }

    ::v-deep .v-list {
        padding: 0;
    }

    ::v-deep .v-expansion-panel-content__wrap {
        padding-bottom: 0;
    }
</style>
