<template>
    <v-content>
        <v-app-bar app dense flat class="header" height="70">
            <nuxt-link to="/">
                <img src="/GP_Travel_Enterprise.png" alt="" class="d-block" />
            </nuxt-link>
            <div class="flex-grow-1" />
            <div>
                <v-btn color="primary" @click="save">
                    {{ $t('save_changes') }}
                </v-btn>
                <v-btn color="secondary" nuxt :to="{name: 'emailTemplates'}">
                    {{ $t('cancel') }}
                </v-btn>
            </div>
        </v-app-bar>
        <client-only>
            <div class="d-flex full-height">
                <keep-alive>
                    <email-editor ref="editor" class="flex-grow-1" @load="editorLoaded" />
                </keep-alive>
            </div>
        </client-only>
    </v-content>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import {EmailEditor} from 'vue-email-editor'
    import {adminRuntimeStore} from '@/utils/store-accessor'

    @Component({
        components: {
            EmailEditor,
        },
        middleware: 'auth',
        layout: 'pure',
    })
    export default class EmailEditorPage extends Vue {
        template = {}

        //TODO Need fix editor init after second page mount or reload this page
        async editorLoaded() {
            let template = adminRuntimeStore.notificationTemplates.find(
                template => template.id === this.$route.params.id
            )
            if (!template) {
                try {
                    const data = await this.$api.notificationTemplates.get(this.$route.params.id)
                    template = data.templates[0]
                } catch (e) {
                    this.$router.push({name: 'emailTemplates'})
                }
            }
            this.template = template
            this.$refs.editor.loadDesign(JSON.parse(this.template.jsonTemplate))
        }

        save() {
            this.$refs.editor.exportHtml(async data => {
                try {
                    await this.$api.notificationTemplates.put(this.$route.params.id, {
                        jsonTemplate: JSON.stringify(data.design),
                        htmlTemplate: data.html,
                        info: this.template.info,
                    })
                    this.$toast.success(this.$t('success_message.saved_successfully'))
                    this.$router.push({name: 'emailTemplates'})
                } catch (e) {
                    this.$toast.error(this.$t('error_message.save_error'))
                }
            })
        }

        beforeDestroy() {
            this.$refs.editor = null
        }
    }
</script>

<style scoped lang="scss">
    .full-height {
        height: 100%;
    }

    .header {
        border-bottom: 1px #eeeeee solid;
    }
</style>
