<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {transfersRuntimeStore, transfersStore} from '@/store'

    @Component
    export default class TransferStoreMixin extends Vue {
        get productStore() {
            return transfersStore
        }

        get productRuntimeStore() {
            return transfersRuntimeStore
        }

        get productType() {
            return 'transfer'
        }
    }
</script>
