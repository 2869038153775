var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('search-form-layout',{attrs:{"popup":_vm.popup,"title":"packages.packages"},on:{"close-popup":function($event){return _vm.$emit('close-popup')}},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-row',[_c('v-col',{attrs:{"md":"4","xl":"3","cols":"12"}},[_c('city-autocomplete',{attrs:{"label":"transfers.departure_location","single-line":"","filled":"","rules":[
                                    function (v) { return !!v || ((_vm.$t('transfers.departure_location')) + " " + (_vm.$t('validation.required'))); } ],"product-type":"PACKAGE"},model:{value:(_vm.departurePoint),callback:function ($$v) {_vm.departurePoint=$$v},expression:"departurePoint"}})],1),_vm._v(" "),_c('v-col',{attrs:{"md":"4","xl":"3","cols":"12"}},[_c('city-autocomplete',{attrs:{"label":"transfers.arrival_location","single-line":"","filled":"","rules":[
                                    function (v) { return !!v || ((_vm.$t('transfers.arrival_location')) + " " + (_vm.$t('validation.required'))); } ],"product-type":"PACKAGE"},model:{value:(_vm.arrivalPoint),callback:function ($$v) {_vm.arrivalPoint=$$v},expression:"arrivalPoint"}})],1),_vm._v(" "),_c('v-col',{attrs:{"md":"4","xl":"2","cols":"12"}},[_c('vc-date-picker',{attrs:{"locale":_vm.$i18n.locale,"min-date":_vm.minDateTime},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var inputProps = ref.inputProps;
                                var inputEvents = ref.inputEvents;
return _c('v-text-field',_vm._g(_vm._b({class:{caption: _vm.$breakpoint.smAndDown},attrs:{"readonly":"","single-line":"","filled":"","hide-details":"","background-color":"white","prepend-inner-icon":"mdi-calendar"}},'v-text-field',_vm.formatDatepickerInput(inputProps),false),inputEvents))}}]),model:{value:(_vm.dateTimeData),callback:function ($$v) {_vm.dateTimeData=$$v},expression:"dateTimeData"}})],1),_vm._v(" "),_c('v-col',{attrs:{"xl":"2","md":"4","cols":"12"}},[_c('v-select',{attrs:{"items":_vm.nightsCount,"label":"Nights","single-line":"","filled":"","background-color":"white","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                                var item = ref.item;
return [_vm._v("\n                                    "+_vm._s(item)+"\n                                     \n                                    "),_c('span',[_vm._v("\n                                        "+_vm._s(_vm.$tc('night', item))+"\n                                    ")])]}}]),model:{value:(_vm.nights),callback:function ($$v) {_vm.nights=$$v},expression:"nights"}})],1),_vm._v(" "),_c('v-col',{attrs:{"md":"4","xl":"2","cols":"12"}},[_c('tourists-select-menu',{attrs:{"_adults":_vm.adults,"_children-ages":_vm.childrenAges,"token":"guest"},on:{"update:_adults":function($event){_vm.adults=$event},"update:_childrenAges":function($event){_vm.childrenAges=$event},"update:_children-ages":function($event){_vm.childrenAges=$event}}})],1)],1)],1),_vm._v(" "),_c('v-col',{staticClass:"hidden-sm-and-down",attrs:{"md":"2","cols":"12"}},[_c('v-btn',{attrs:{"block":"","dark":"","x-large":"","color":"primary","disabled":!_vm.valid},on:{"click":_vm.search}},[_vm._v("\n                        "+_vm._s(_vm.$t('search'))+"\n                    ")])],1)],1)],1),_vm._v(" "),_c('additional-options-wrapper',{ref:"addForm",attrs:{"options":['citizenship']}}),_vm._v(" "),_c('v-row',{staticClass:"hidden-md-and-up"},[_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('v-btn',{attrs:{"block":"","dark":"","x-large":"","color":"primary","disabled":!_vm.valid},on:{"click":_vm.search}},[_vm._v("\n                    "+_vm._s(_vm.$t('search'))+"\n                ")])],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }