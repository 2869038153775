<template>
    <search-form-layout :popup="popup" title="tours.tours" @close-popup="$emit('close-popup')">
        <template v-slot:form>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col md="5" cols="12">
                        <vc-date-picker v-model="date" :locale="$i18n.locale" :available-dates="availableDates">
                            <v-text-field
                                slot-scope="{inputProps, inputEvents}"
                                :class="{caption: $breakpoint.smAndDown}"
                                :label="$t('start_date_from')"
                                readonly
                                single-line
                                filled
                                hide-details
                                background-color="white"
                                prepend-inner-icon="mdi-calendar"
                                v-bind="formatDatepickerInput(inputProps)"
                                v-on="inputEvents"
                            />
                        </vc-date-picker>
                    </v-col>
                    <v-col md="5" cols="12">
                        <tourists-select-menu
                            :_adults.sync="adults"
                            :_children-ages.sync="childrenAges"
                            token="guest"
                        />
                    </v-col>
                    <v-col md="2" cols="12">
                        <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                            {{ $t('search') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </template>
    </search-form-layout>
</template>

<script>
    import {Component, Mixins, Prop, Watch} from 'vue-property-decorator'
    import CityAutocomplete from '~/components/search/forms/CityAutocomplete'
    import {authStore, toursRuntimeStore, toursStore} from '@/store'
    import SearchFormLayout from '~/components/parts/SearchFormLayout'
    import TouristsSelectMenu from '@/components/search/forms/TouristsSelectMenu'
    import TourDatepickerMixin from '@/components/tours/mixins/TourDatepickerMixin'
    import {CHANGE_TAB_MOBILE_EVENT, EventBus} from '@/utils/event-bus'

    @Component({
        components: {
            TouristsSelectMenu,
            CityAutocomplete,
            SearchFormLayout,
        },
    })
    export default class TourSearchForm extends Mixins(TourDatepickerMixin) {
        @Prop({default: false}) popup

        startDateFrom = null
        adults = 2
        childrenAges = []
        valid = false

        @Watch('searchRequestStartDateFrom')
        onChangeDate(val) {
            this.date = val
        }

        async search() {
            this.$emit('close-popup')
            const query = Object.assign({}, this.$route.query, {
                startDateFrom: this.startDateFrom,
                adults: this.adults,
                childrenAges: this.childrenAges,
            })
            const name = 'tour'
            if (this.$route.name !== name) {
                toursRuntimeStore.newSearch()
            }
            try {
                await this.$router.push({name, query})
            } catch (e) {
                await toursRuntimeStore.search(toursStore.searchRequest)
            }
            EventBus.$emit(CHANGE_TAB_MOBILE_EVENT, 1)
        }

        async mounted() {
            await this.$store.restored
            if (this.$dateFns.isAfter(this.$dateFns.parseISO(toursStore.searchRequest.startDateFrom), new Date())) {
                this.startDateFrom = toursStore.searchRequest.startDateFrom
            } else {
                this.startDateFrom = this.$dateFns.format(this.$dateFns.addDays(new Date(), 1))
            }
            //TODO Need refactoring with other products
            this.adults = authStore.user.isEmployee ? 2 : toursStore.searchRequest.adults
            this.childrenAges = authStore.user.isEmployee ? [] : [...toursStore.searchRequest.childrenAges]
        }

        formatDatepickerInput(props) {
            props.value = this.startDateFrom !== null ? this.$options.filters.dateFormat(this.startDateFrom) : ''
            return props
        }

        get searchRequestStartDateFrom() {
            return toursStore.searchRequest.startDateFrom
        }

        get date() {
            return this.$dateFns.parseISO(this.startDateFrom)
        }

        set date(val) {
            this.startDateFrom = val ? this.$dateFns.format(val) : null
        }
    }
</script>
