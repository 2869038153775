<template>
    <v-card
        class="my-2 hotel-card"
        :class="{pointer: comparisonMode}"
        outlined
        :hover="comparisonMode"
        @click.native="selectToCompare(offer)"
    >
        <component
            :is="comparisonMode ? 'span' : 'n-link'"
            no-prefetch
            :to="hotelPageLink"
            class="title text-decoration-none"
        >
            <v-img
                class="white--text align-end"
                height="200"
                :src="offer.thumb | imageUrl(600)"
                :alt="offer.name"
                lazy-src="/placeholder.png"
                @error="onImgError"
            >
                <template v-slot:placeholder>
                    <v-row v-show="!imgError && offer.thumb" class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
                <v-card-title>
                    <hotel-stars class="me-3" :category="offer.category" />
                    <div class="break-word text-subtitle-1">
                        {{ offer.name }}
                    </div>
                </v-card-title>
            </v-img>
        </component>
        <div>
            <div class="d-flex align-center mt-1">
                <hotel-type-label :offer="offer" />
                <!--v-chip
                    v-for="(classification, classificationIndex) in offer.classifications"
                    :key="classificationIndex"
                    x-small
                    class="ma-1"
                    v-text="classification"
                /-->
                <hotel-own-label class="ms-1" :offer="offer" />
            </div>
            <hotel-location-label :offer="offer" />
            <hotel-address-label :address="offer.address" :offer="offer" />
            <hotel-services :offer="offer" class="mt-3" />
            <v-divider class="my-3" />
            <tripadvisor-rating v-if="offer.tripadvisorRating" :tripadvisor-rating="offer.tripadvisorRating" />
            <div class="d-flex justify-space-between mt-3">
                <slot name="price" :room="minPriceRoomOffer">
                    <hotel-offer-price-block :offer="offer" :search-request="searchRequest" mobile />
                </slot>
                <v-btn depressed color="primary" nuxt :to="hotelPageLink">
                    {{ $t('book') }}
                </v-btn>
            </div>
        </div>
        <v-expand-transition>
            <div v-if="expanded">
                <hotel-room-offers-wrapper
                    :offer="offer"
                    :compare-mode="comparisonMode"
                    :_selected-offer-keys.sync="selectedOfferKeys"
                    :full-card="false"
                    :search-rooms-count="searchRoomsCount"
                    :_can-book.sync="canBook"
                >
                    <template v-slot:price="{room}">
                        <slot name="price" :room="room" />
                    </template>
                </hotel-room-offers-wrapper>
                <v-list-item v-if="searchRoomsCount > 1">
                    <v-list-item-content>
                        <hotel-multiple-room-select-block
                            :search-request="searchRequest"
                            :offer="offer"
                            :can-book="canBook"
                            :selected-offer-keys="selectedOfferKeys"
                        />
                    </v-list-item-content>
                </v-list-item>
            </div>
        </v-expand-transition>
        <v-btn block depressed class="mt-3" data-cy="toggle-rooms-button-mobile" @click="expanded = !expanded">
            {{ expanded ? $t('hide_room_offers') : $t('view_room_offers') }}
            <v-icon>mdi-chevron-{{ expanded ? 'up' : 'down' }}</v-icon>
        </v-btn>
    </v-card>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import HotelOfferCard from '~/components/search/offers/HotelOfferCard'

    @Component
    export default class HotelOfferCardMobile extends HotelOfferCard {}
</script>

<style scoped lang="scss">
    .hotel-card {
        border: none !important;
    }

    .v-card__title {
        background: rgba(0, 0, 0, 0.5);

        a {
            color: #ffffff;
            text-decoration: none;
        }
    }

    .break-word {
        word-break: break-word;
    }
</style>
