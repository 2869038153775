<template>
    <confirmation-page-layout v-if="loaded" :company="company">
        <template v-slot:breadcrumbs>
            <activity-breadcrumbs :search-request="searchRequest" :product="product" />
        </template>
        <template v-slot:sidebar>
            <activity-search-summary search-disabled :search-request="searchRequest" />
            <activity-order-summary :offer="offer" :info="basketItem.info" />
        </template>
    </confirmation-page-layout>
</template>

<script>
    import {mixins, Component} from 'nuxt-property-decorator'
    import ConfirmationStatus from '@/components/booking/ConfirmationStatus'
    import ActivitySearchSummary from '@/components/activities/search/ActivitySearchSummary'
    import ActivityOrderSummary from '@/components/activities/booking/ActivityOrderSummary'
    import ActivityStoreMixin from '@/mixins/ActivityStoreMixin'
    import ConfirmationPageMixin from '@/mixins/ConfirmationPageMixin'
    import ActivityPageMixin from '@/components/activities/mixins/ActivityPageMixin'
    import {persistentStore} from '@/utils/store-accessor'
    import ConfirmationPageLayout from '@/components/parts/ConfirmationPageLayout'
    import ActivityBreadcrumbs from '@/components/activities/ActivityBreadcrumbs'

    @Component({
        components: {
            ActivityBreadcrumbs,
            ConfirmationPageLayout,
            ConfirmationStatus,
            ActivitySearchSummary,
            ActivityOrderSummary,
        },
    })
    export default class ActivityConfirmationPage extends mixins(
        ActivityPageMixin,
        ActivityStoreMixin,
        ConfirmationPageMixin
    ) {
        loaded = false

        validate({query}) {
            return query.orderId
        }

        async mounted() {
            await this.$store.restored
            await this.loadRuntimeData()
            if (this.$route.query.orderId !== persistentStore.bookResponse[0].orderId) {
                //TODO Redirect to 404
            }
            this.loaded = true
        }

        get basketItem() {
            return this.productStore.basketItem(this.productStore.prepareBookRequest.offerKey)
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get product() {
            return this.basketItem.product
        }

        get offer() {
            return this.product.offers.find(offer => offer.offerKey === this.basketItem.offerKey)
        }
    }
</script>
