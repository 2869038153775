<template>
    <div>
        <v-tabs v-if="activeProducts.length > 1" v-model="productTabs" :show-arrows="false">
            <v-tab v-for="product in activeProducts" :key="product + '_tab'">
                <v-icon class="me-1">
                    {{ products[product].ico }}
                </v-icon>
                <span class="hidden-sm-and-down">{{ $t(products[product].title) }}</span>
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="productTabs" class="overflow--visible">
            <v-tab-item v-for="product in activeProducts" :key="product + '_form'">
                <main-page-form-wrapper :img="products[product].background">
                    <template v-slot:form>
                        <component
                            :is="products[product].form"
                            class="my-7 my-md-0"
                            :variant="products[product].variant"
                            :title="products[product].title"
                        />
                    </template>
                </main-page-form-wrapper>
            </v-tab-item>
        </v-tabs-items>
        <v-container fluid>
            <vue-markdown v-if="homePageSettings.topBlock" :source="homePageSettings.topBlock" />
            <v-sheet v-if="$config.hotelHomeMap" width="85%" class="mx-auto mb-10">
                <hotel-home-map />
            </v-sheet>
            <popular-destinations v-if="popularDestinations.length" :destinations="popularDestinations" />
            <top-tours />
            <vue-markdown v-if="homePageSettings.bottomBlock" :source="homePageSettings.bottomBlock" />
        </v-container>
    </div>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import PopularDestinations from '~src/components/parts/popularDestinations.src'
    import MainPageFormWrapper from '~src/components/parts/mainPageFormWrapper.src'
    import {cmsStore, persistentStore, runtimeStore} from '@/store'
    import {CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'
    import VueMarkdown from 'vue-markdown'
    import TopTours from '~src/components/tours/topTours.src'
    import HotelSearchForm from '~src/components/search/forms/hotelSearchForm.src'
    import TransferSearchForm from '~src/components/transfers/search/forms/transferSearchForm.src'
    import ActivitySearchForm from '~src/components/activities/search/forms/activitySearchForm.src'
    import FlightSearchForm from '~src/components/flights/search/forms/flightSearchForm.src'
    import PackagesSearchForm from '~/components/packages/search/forms/PackagesSearchForm'
    import ExtraServiceSearchForm from '~/components/extraServices/search/forms/ExtraServiceSearchForm'
    import HotelHomeMap from '@/components/hotels/HotelHomeMap'

    @Component({
        components: {
            HotelHomeMap,
            TopTours,
            HotelSearchForm,
            TransferSearchForm,
            ActivitySearchForm,
            FlightSearchForm,
            PackagesSearchForm,
            ExtraServiceSearchForm,
            PopularDestinations,
            MainPageFormWrapper,
            VueMarkdown,
        },
        layout: 'blank',
        middleware: 'supplier',
    })
    export default class IndexPage extends Vue {
        productTabs = 0

        async fetch() {
            await Promise.all([cmsStore.loadPopularDestinations(), cmsStore.loadHomePageSettings()])
        }

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, cmsStore.loadPopularDestinations)
            EventBus.$on(CHANGE_LOCALE_EVENT, cmsStore.loadHomePageSettings)
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, cmsStore.loadPopularDestinations)
            EventBus.$off(CHANGE_LOCALE_EVENT, cmsStore.loadHomePageSettings)
        }

        mounted() {
            persistentStore.SET_TOURISTS([])
        }

        get popularDestinations() {
            return cmsStore.popularDestinations
        }

        get homePageSettings() {
            return cmsStore.homePageSettings
        }

        get searchFormBackgrounds() {
            return cmsStore.homePageSettings.searchFormBackgrounds
        }

        get activeProducts() {
            return Object.values(runtimeStore.config.activeProducts)
        }

        get products() {
            const bg = (caption, defaultBackground) =>
                this.searchFormBackgrounds[caption]
                    ? this.$options.filters.srcHost(this.searchFormBackgrounds[caption])
                    : defaultBackground
            return {
                hotels: {
                    ico: 'mdi-office-building',
                    title: 'hotels',
                    background: bg('hotel', '/home-bg.jpg'),
                    form: 'hotel-search-form',
                },
                transfers: {
                    ico: 'mdi-bus',
                    title: 'transfers.transfers',
                    background: bg('transfer', '/home-bg-transfers.jpg'),
                    form: 'transfer-search-form',
                },
                activities: {
                    ico: 'mdi-lightning-bolt-outline',
                    title: 'activities.activities',
                    background: bg('activity', '/home-bg-activities.jpg'),
                    form: 'activity-search-form',
                },
                flights: {
                    ico: 'mdi-airplane',
                    title: 'flights.flights',
                    background: bg('flight', '/home-bg-flights.jpg'),
                    form: 'flight-search-form',
                },
                packages: {
                    ico: 'mdi-wallet-travel',
                    title: 'packages.packages',
                    background: bg('package', '/packages-bg.jpg'),
                    form: 'packages-search-form',
                },
                extraServices: {
                    ico: 'mdi-star-box',
                    title: 'extraServices.extra_services',
                    background: bg('extraService', '/home-bg-extra-services.jpg'),
                    form: 'extra-service-search-form',
                },
                conferenceHall: {
                    ico: 'mdi-monitor-dashboard',
                    title: 'extraServices.conference_hall',
                    background: bg('conference_hall', '/home-bg-conference-hall.jpg'),
                    form: 'extra-service-search-form',
                    variant: 'conference',
                },
                catering: {
                    ico: 'mdi-cup',
                    title: 'extraServices.catering',
                    background: bg('catering', '/home-bg-catering.jpg'),
                    form: 'extra-service-search-form',
                    variant: 'catering',
                },
            }
        }
    }
</script>

<style scoped>
    >>> .v-slide-group__prev {
        display: none !important;
    }
</style>
