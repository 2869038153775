<template>
    <v-dialog v-model="modal" overlay-opacity="0" width="100%" max-width="600" @click:outside="closeModal">
        <v-card v-if="!loaded" color="primary" dark>
            <v-card-text>
                {{ $t('stand_by') }}
                <v-progress-linear indeterminate color="white" class="mb-0" />
            </v-card-text>
        </v-card>
        <v-card v-if="loaded" justify-center align-center>
            <div class="mb-4">
                <v-icon class="v-card-close" @click="closeModal">
                    mdi-close
                </v-icon>
                <v-card-title class="justify-center">
                    {{ $t('invoices.pay_invoices_title') }} {{ orderId }}
                </v-card-title>
            </div>
            <v-card-subtitle v-if="paymentMethods.length" class="mt-2 mb-0 py-0">
                {{ $t('invoices.pay_invoices_subtitle') }}
            </v-card-subtitle>
            <v-card-text v-if="paymentMethods.length">
                <v-radio-group v-model="invoicePaymentKey" @change="selectPayment($event)">
                    <template v-for="paymentMethod in paymentMethods">
                        <v-radio :key="paymentMethod.id" :value="paymentMethod.invoicePaymentKey">
                            <template v-slot:label>
                                <span>
                                    {{
                                        paymentMethod.paymentMethodCode
                                            ? paymentMethod.paymentMethodCode
                                            : $t('deposit')
                                    }}
                                </span>
                                &nbsp;
                                <strong>{{ paymentMethod.paymentAmount }} {{ paymentMethod.currency }}</strong>
                            </template>
                        </v-radio>
                        <v-stripe-card
                            v-if="
                                paymentMethod.paymentMethodCode === 'stripe' &&
                                    stripeApiKey &&
                                    paymentMethod.invoicePaymentKey === invoicePaymentKey
                            "
                            ref="stripeCard"
                            :key="`${paymentMethod.invoicePaymentKey}_stripe`"
                            v-model="stripeData"
                            :api-key="stripeApiKey"
                            hide-postal-code
                        />
                    </template>
                </v-radio-group>
            </v-card-text>
            <v-alert v-if="depositError" prominent type="error" dismissible @input="closeAlert">
                <div>{{ depositError }}</div>
            </v-alert>
            <v-card-actions v-if="paymentMethods.length" class="justify-end pa-6">
                <v-btn class="black--text mr-4" color="white" :disabled="payLoading" @click="closeModal">
                    {{ $t('cancel') }}
                </v-btn>
                <v-btn
                    class="white--text"
                    color="primary"
                    :disabled="!invoicePaymentKey"
                    :loading="payLoading"
                    @click="pay"
                >
                    {{ $t('pay') }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <payment-redirect-form ref="paymentRedirectForm" />
    </v-dialog>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'
    import {EventBus, PAY_SERVICE_EVENT, PAY_ORDER_EVENT} from '@/utils/event-bus'
    import {invoicePaymentMethods, invoiceIssueInfo, issueInvoice, invoices, invoicePaymentByKey} from '~/api'
    import {persistentStore, runtimeStore} from '@/utils/store-accessor'
    import PaymentRedirectForm from '@/components/booking/forms/PaymentRedirectForm'

    @Component({
        components: {
            PaymentRedirectForm,
        },
    })
    export default class InvoicesPayModal extends Vue {
        modal = false
        loaded = false
        orderId = null
        objectId = null
        paymentMethods = []
        stripeData = {}
        stripeApiKey = null
        paymentInitiateRs = {}
        invoicePaymentKey = null
        payLoading = false
        invoiceType = 'SUPPLIER'
        depositError = false

        created() {
            EventBus.$on(PAY_SERVICE_EVENT, this.payServiceModal)
            EventBus.$on(PAY_ORDER_EVENT, this.payServiceModal)
        }

        beforeDestroy() {
            EventBus.$off(PAY_SERVICE_EVENT, this.payServiceModal)
            EventBus.$off(PAY_ORDER_EVENT, this.payServiceModal)
        }

        closeModal() {
            this.modal = false
            this.depositError = false
            this.invoicePaymentKey = false
        }

        closeAlert() {
            this.depositError = false
        }

        async payServiceModal(obj) {
            this.loaded = false
            this.modal = true
            this.orderId = obj.orderId
            this.objectId = obj.objectId

            await this.invoicePaymentMethods()

            this.loaded = true
        }

        async invoicePaymentMethods() {
            try {
                const issueInvoicesInfo = await invoiceIssueInfo.get({
                    orderId: this.objectId,
                    invoiceType: this.invoiceType,
                })

                if (issueInvoicesInfo.transactions.length) {
                    const serviceIds = issueInvoicesInfo.transactions.map(transaction => {
                        return transaction.serviceId
                    })

                    await issueInvoice.post({
                        serviceIds: serviceIds,
                        invoiceType: this.invoiceType,
                    })
                }

                const orderInvoices = await invoices.get({
                    orderId: this.objectId,
                    invoiceType: this.invoiceType,
                })

                const invoiceIds = orderInvoices.invoices
                    .filter(invoice => {
                        return invoice.paymentStatus == 'BILL'
                    })
                    .map(invoice => {
                        return invoice.invoiceId
                    })

                if (invoiceIds.length) {
                    const paymentMethods = await invoicePaymentMethods.get({
                        orderId: this.objectId,
                        invoiceId: invoiceIds,
                        invoiceType: this.invoiceType,
                    })
                    this.paymentMethods = paymentMethods.invoicePaymentMethods.filter(paymentMethod => {
                        return ['DEPOSIT_PAYMENT', 'PAYMENT_SYSTEM_PROVIDER'].includes(paymentMethod.paymentMethod)
                    })
                }
            } catch (error) {
                this.$toast.error(error.errors[0].message)
            }
        }

        async selectPayment(paymentMethodId) {
            const paymentMethod = this.paymentMethods.find(
                paymentMethod => paymentMethod.invoicePaymentKey === paymentMethodId
            )

            if (paymentMethod.paymentMethodCode === 'stripe') {
                this.paymentInitiateRs = await persistentStore.paymentInitiate({
                    invoicePaymentKey: paymentMethod.invoicePaymentKey,
                })
                this.stripeApiKey = this.paymentInitiateRs.parameters.find(
                    parameter => parameter.name === 'APIPublicKey'
                ).value
            }
        }

        get stripeOkToSubmit() {
            return this.$refs.stripeCard ? this.$refs.stripeCard[0].okToSubmit : undefined
        }

        async pay() {
            this.payLoading = true

            const paymentMethod = this.paymentMethods.find(
                paymentMethod => paymentMethod.invoicePaymentKey === this.invoicePaymentKey
            )

            // DEPOSIT
            if (!paymentMethod.paymentMethodCode) {
                try {
                    await invoicePaymentByKey.put({
                        invoicePaymentKey: this.invoicePaymentKey,
                    })
                    this.modal = false
                    this.$emit('issuedInvoice', this.invoiceType)
                } catch (error) {
                    this.depositError = this.$t('error_message.deposit_error')
                }
            } else if (paymentMethod.paymentMethodCode === 'stripe') {
                const {paymentNumber, paymentTransactionId} = this.paymentInitiateRs
                const query = {
                    ...this.paymentCompletePageQuery('success'),
                    ...{
                        stripeToken: this.stripeData.id,
                        paymentNumber,
                        paymentTransactionId,
                    },
                }
                await this.$router.push({
                    name: 'invoicePaymentComplete',
                    query,
                })
            } else {
                await this.makeOnlinePayment()
            }

            this.payLoading = false
        }

        paymentCompletePageQuery(status) {
            return {
                invoicePaymentKey: this.invoicePaymentKey,
                orderId: this.orderId,
                status: status,
            }
        }

        async makeOnlinePayment() {
            try {
                const getPaymentUrl = paymentStatus => {
                    return (
                        window.location.origin +
                        this.$router.resolve({
                            name: 'invoicePaymentComplete',
                            query: this.paymentCompletePageQuery(paymentStatus),
                        }).href
                    )
                }
                const rq = {
                    invoicePaymentKey: this.invoicePaymentKey,
                }
                const rqOnline = {
                    ...rq,
                    ...{
                        successUrl: getPaymentUrl('success'),
                        failureUrl: getPaymentUrl('failure'),
                        cancelUrl: getPaymentUrl('cancel'),
                    },
                }
                const paymentInitiateRs = await persistentStore.paymentInitiate(rqOnline)
                if (paymentInitiateRs.simpleRedirect) {
                    window.location = paymentInitiateRs.url
                } else {
                    const paymentMethod = this.paymentMethods.find(
                        paymentMethod => paymentMethod.invoicePaymentKey === this.invoicePaymentKey
                    )
                    if (paymentMethod.paymentMethodCode === 'webpay' && runtimeStore.config.webpayContractDomain) {
                        const {url, parameters, paymentNumber, paymentTransactionId} = paymentInitiateRs
                        this.$refs.paymentRedirectForm.submit({
                            url: `${runtimeStore.config.webpayContractDomain}/payment-redirect`,
                            parameters,
                            paymentNumber,
                            paymentTransactionId,
                            originUrl: url,
                        })
                    } else {
                        this.$refs.paymentRedirectForm.submit(paymentInitiateRs)
                    }
                }
            } catch (e) {
                this.$toast.error('Something wrong on payment initiate step')
            }
        }
    }
</script>
