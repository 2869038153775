<template>
    <div class="blog-block">
        <h3 class="h-light">
            <a class="blog-url" :href="blogUrl">
                {{ $t('blog') }}
            </a>
        </h3>
        <swiper :options="swiperOptions" class="swiper mt-6">
            <swiper-slide v-for="(item, index) in items" :key="index" :class="{'swiper-slide-ssr': swiperSlideSsr}">
                <blog-item :item="item" @selected="search($event)" />
            </swiper-slide>
            <div
                v-if="items.length > showCount"
                id="blogSwiperButtonPrev"
                slot="button-prev"
                class="swiper-button-prev"
            />
            <div
                v-if="items.length > showCount"
                id="blogSwiperButtonNext"
                slot="button-next"
                class="swiper-button-next"
            />
        </swiper>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import {cmsStore, hotelsRuntimeStore, runtimeStore} from '@/store'
    import BlogItem from '~~/mods/asistour-test/components/snippets/BlogItem'

    //TODO It is Swiper6 workaround
    import {Swiper as SwiperClass, Pagination, Navigation, Mousewheel, Autoplay} from 'swiper/swiper.esm'
    import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
    SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay])
    Vue.use(getAwesomeSwiper(SwiperClass))
    const {Swiper, SwiperSlide} = getAwesomeSwiper(SwiperClass)

    import 'swiper/swiper-bundle.min.css'
    import {searchRequest} from '@/utils/hotels/hotels-blank-states'

    @Component({
        components: {
            BlogItem,
            Swiper,
            SwiperSlide,
        },
    })
    export default class Blog extends Vue {
        @Prop({required: true}) items

        swiperSlideSsr = false

        created() {
            this.swiperSlideSsr = !this.$breakpoint.smAndDown
        }

        async search(cityId) {
            await this.$router.push(
                hotelsRuntimeStore.hotelsPageLink({
                    ...searchRequest(),
                    cityId,
                    citizenshipId: runtimeStore.config.defaultCitizenshipId,
                })
            )
        }

        get showCount() {
            return this.$breakpoint.smAndDown ? 1 : 4
        }

        get swiperOptions() {
            return {
                slidesPerView: 1,
                spaceBetween: 20,
                breakpoints: {
                    960: {
                        slidesPerView: 4,
                    },
                },
                navigation: {
                    nextEl: '#blogSwiperButtonNext',
                    prevEl: '#blogSwiperButtonPrev',
                },
            }
        }

        get blogUrl() {
            return cmsStore.homePageSettings.blogUrl ? cmsStore.homePageSettings.blogUrl : 'javascript: void(0);'
        }
    }
</script>

<style scoped>
    div.swiper-button-prev,
    div.swiper-button-next {
        color: white;
    }
    div.swiper-button-prev {
        left: 20px;
    }
    div.swiper-button-next {
        right: 20px;
    }
    .blog-url {
        color: #000;
        cursor: default;
    }
    /*.blog-url:hover {
        color: #6699cc;
    }*/
    .swiper-slide-ssr {
        width: 25%;
        margin-right: 20px;
        float: left;
    }
</style>
