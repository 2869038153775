<template>
    <extra-service-booking-page-layout v-if="loaded" :search-request="searchRequest" :offer="offer">
        <template v-slot:breadcrumbs>
            <expired-offers-snackbar :is-offers-expired="isOffersExpired" @refresh="refreshExpiredOffers" />
            <extra-service-breadcrumbs :search-request="searchRequest" :product="product" />
        </template>
        <template v-slot:orderCommentsForm="{bookingKey}">
            <booking-comments-form :disabled="!!bookingKey" :product-store="productStore" />
        </template>
        <template v-slot:sidebar="{bookingKey}">
            <extra-service-search-summary search-disabled :search-request="searchRequest" />
            <extra-service-order-summary :offer="offer" :info="basketItem.info" :prebooked="!!bookingKey" />
        </template>
    </extra-service-booking-page-layout>
</template>

<script>
    import {Component, mixins} from 'nuxt-property-decorator'
    import ExtraServiceBreadcrumbs from '~src/components/extraServices/ExtraServiceBreadcrumbs'
    import ExtraServiceSearchSummary from '~src/components/extraServices/search/ExtraServiceSearchSummary'
    import ExtraServiceOrderSummary from '~src/components/extraServices/booking/ExtraServiceOrderSummary'
    import {runtimeStore, extraServicesStore} from '@/utils/store-accessor'
    import ExtraServiceBookingPageLayout from '~src/components/extraServices/booking/ExtraServiceBookingPageLayout'
    import ExtraServiceStoreMixin from '@/mixins/ExtraServiceStoreMixin'
    import ExtraServicePageMixin from '~src/components/extraServices/mixins/ExtraServicePageMixin'
    import ExpiredOffersSnackbar from '~src/components/search/ExpiredOffersSnackbar'
    import {UTCToDate} from '@/utils/helpers'
    import BookingCommentsForm from '@/components/booking/forms/BookingCommentsForm'

    @Component({
        components: {
            ExpiredOffersSnackbar,
            ExtraServiceBreadcrumbs,
            ExtraServiceSearchSummary,
            ExtraServiceOrderSummary,
            ExtraServiceBookingPageLayout,
            BookingCommentsForm,
        },
        layout: 'blank',
    })
    export default class ExtraServiceBookingPage extends mixins(ExtraServiceStoreMixin, ExtraServicePageMixin) {
        loaded = false

        validate({query}) {
            return query.offerKey
        }

        async mounted() {
            await this.$store.restored
            await this.loadRuntimeData()
            //await runtimeStore.loadTouristBookingFields(this.$route.query.offerKey)
            runtimeStore.SET_DEFAULT_TOURIST_BOOKING_FIELDS()
            extraServicesStore.clearBookingAdditionalOptions()
            await extraServicesStore.getExtraServiceAdditionalOptions(this.$route.query.offerKey)
            this.loaded = true
        }

        isOffersExpired() {
            return (
                this.$dateFns.isAfter(new Date(), UTCToDate(this.$dateFns.parseISO(this.basketItem.expirationTime))) &&
                !runtimeStore.bookingActive &&
                !this.productStore.prepareBookResponse.bookingKey
            )
        }

        refreshExpiredOffers() {
            this.$router.push(this.productRuntimeStore.searchPageLink(this.searchRequest))
        }

        get basketItem() {
            return this.productStore.basket.find(item => item.offerKey === this.$route.query.offerKey)
        }

        get searchRequest() {
            return this.basketItem.searchRequest
        }

        get product() {
            return this.basketItem.product
        }

        get offer() {
            return this.product.offers.find(offer => offer.offerKey === this.$route.query.offerKey)
        }
    }
</script>
