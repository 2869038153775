<template>
    <div>
        <div ref="map" class="map" />
        <v-sheet color="amber lighten-5 d-flex align-center pa-1">
            <svg viewBox="4 1 16 22" height="40px" width="30px">
                <path
                    :d="markerIconOptions.path"
                    :fill="markerColor({own: true})"
                    :fill-opacity="markerIconOptions.fillOpacity"
                    :stroke="markerIconOptions.strokeColor"
                />
            </svg>
            <span class="pl-1 pr-2">{{ $t('mapLegend.own') }}</span>
            <svg viewBox="4 1 16 22" height="40px" width="30px">
                <path
                    :d="markerIconOptions.path"
                    :fill="markerColor({})"
                    :fill-opacity="markerIconOptions.fillOpacity"
                    :stroke="markerIconOptions.strokeColor"
                />
            </svg>
            <span class="pl-1 pr-2">{{ $t('mapLegend.other') }}</span>
        </v-sheet>
    </div>
</template>

<script>
    import {mixins, Component} from 'nuxt-property-decorator'
    import gmapsInit from '@/utils/gmaps'
    import HotelMapMixin from '~src/components/hotels/mixins/hotelMapMixin.src'
    import {Vue} from 'vue-property-decorator'
    import MarkerClusterer from '@googlemaps/markerclustererplus'
    import HotelHomeMapInfoWindow from '~/components/hotels/HotelHomeMapInfoWindow'
    import {uniqBy} from 'lodash'

    const clusterSize = 50

    @Component
    export default class HotelHomeMap extends mixins(HotelMapMixin) {
        google
        markerWithInfoWindow

        async mounted() {
            //TODO Workaround for test
            const hotels =
                this.$config.hotelHomeMap === 'test'
                    ? uniqBy(
                          (
                              await Promise.all([
                                  this.$api.hotels.get({limit: -1}),
                                  this.$api.hotels.get({limit: -1, contentProviderId: 1}),
                              ])
                          ).reduce((result, hotels) => [...result, ...hotels], []),
                          'id'
                      )
                    : await this.$api.hotels.get({limit: 0})
            this.google = await gmapsInit()
            const map = new this.google.maps.Map(this.$refs.map, {
                    disableDefaultUI: true,
                    gestureHandling: 'greedy',
                    mapTypeControl: true,
                    zoomControl: true,
                    fullscreenControl: true,
                    zoom: 8,
                    restriction: {
                        latLngBounds: {north: 80, south: -70, west: -180, east: 180},
                        strictBounds: true,
                    },
                }),
                bounds = new this.google.maps.LatLngBounds(),
                markers = hotels.map(hotel => {
                    const {lat, lng} = hotel
                    const marker = new this.google.maps.Marker({
                        position: {lat, lng},
                        icon: this.createMarkerIcon(hotel),
                        data: hotel,
                    })
                    marker.addListener('click', () => {
                        if (!marker.infoWindow) {
                            if (this.markerWithInfoWindow) {
                                this.markerWithInfoWindow.infoWindow.close()
                                delete this.markerWithInfoWindow.infoWindow
                            }
                            this.markerWithInfoWindow = marker
                            const InfoWindowComponent = Vue.extend(HotelHomeMapInfoWindow),
                                instance = new InfoWindowComponent({
                                    propsData: {
                                        offer: hotel,
                                    },
                                    parent: this,
                                })
                            instance.$mount()
                            marker.infoWindow = new this.google.maps.InfoWindow({
                                content: instance.$el,
                            })
                            this.google.maps.event.addListener(marker.infoWindow, 'closeclick', () => {
                                marker.infoWindow.close()
                                delete marker.infoWindow
                                this.markerWithInfoWindow = null
                            })
                            marker.infoWindow.open(this.map, marker)
                        }
                    })
                    bounds.extend(marker.getPosition())
                    return marker
                })
            map.fitBounds(bounds)
            new MarkerClusterer(map, markers, {
                maxZoom: 18,
                minimumClusterSize: 2,
                clusterClass: 'cluster-marker',
                styles: [
                    {width: clusterSize, height: clusterSize},
                    {width: clusterSize, height: clusterSize},
                    {width: clusterSize, height: clusterSize},
                    {width: clusterSize, height: clusterSize},
                    {width: clusterSize, height: clusterSize},
                ],
            })
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .map {
        height: 600px;
    }

    ::v-deep .cluster-marker {
        min-width: 58px;
        min-height: 58px;
        display: flex;
        align-items: center;
        border-radius: 29px;
        white-space: nowrap;
        color: var(--v-primary-base);
        border: 4px var(--v-primary-base) solid;
        background: white;
        font-size: 12px;
        font-weight: map-get($font-weights, 'black');

        > * {
            &:active,
            &:focus {
                outline: none;
            }
        }
    }
</style>
