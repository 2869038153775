<template>
    <v-list dense class="d-flex account-menu" :class="$breakpoint.smAndDown ? 'flex-column full-width' : ''">
        <account-menu-custom-items />
        <v-list-item
            v-if="person && isB2B"
            :to="{name: 'orders'}"
            data-cy="account-orders-button"
            @click="
                menu = false
                menuCompany = false
            "
        >
            <v-list-item-content :class="$breakpoint.smAndDown ? 'pa-0' : ''">
                <v-list-item-title>
                    <v-btn text :class="$breakpoint.smAndDown ? 'pa-0' : ''">
                        {{ $t('orders') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item
            v-if="person && (isB2B || isTourOperator) && !isSupplier"
            :to="{name: 'reports'}"
            data-cy="account-reports-button"
            @click="
                menu = false
                menuCompany = false
            "
        >
            <v-list-item-content :class="$breakpoint.smAndDown ? 'pa-0' : ''">
                <v-list-item-title>
                    <v-btn text :class="$breakpoint.smAndDown ? 'pa-0' : ''">
                        {{ $t('reports') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item
            v-if="person && isSupplier"
            :to="{name: 'supplierProducts'}"
            data-cy="account-supplier-products-button"
            @click="
                menu = false
                menuCompany = false
            "
        >
            <v-list-item-content>
                <v-list-item-title>
                    <v-btn text>
                        {{ $t('products') }}
                    </v-btn>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="person && (isB2B || isTourOperator) && !isSupplier">
            <v-menu v-model="menuCompany" :close-on-content-click="false" offset-y>
                <template v-slot:activator="{on}">
                    <v-btn text :class="$breakpoint.smAndDown ? 'pa-0' : ''" v-on="on">
                        {{ $t('my_company') }}
                        <v-icon right>
                            mdi-menu-down
                        </v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item
                        v-if="isB2B"
                        :to="{name: 'company-profile'}"
                        @click="
                            menu = false
                            menuCompany = false
                        "
                    >
                        <v-list-item-icon>
                            <v-icon left>
                                mdi-domain
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('my_company') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="isB2B"
                        :to="{name: 'companyPersons'}"
                        @click="
                            menu = false
                            menuCompany = false
                        "
                    >
                        <v-list-item-icon>
                            <v-icon left>
                                mdi-account-multiple
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('users') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="isB2B"
                        :to="{name: 'companyClients'}"
                        @click="
                            menu = false
                            menuCompany = false
                        "
                    >
                        <v-list-item-icon>
                            <v-icon left>
                                mdi-human-male-female
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('clients') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="isB2B && (isAgency || isTourOperator)"
                        :to="{name: 'companyCorporateClients'}"
                        @click="
                            menu = false
                            menuCompany = false
                        "
                    >
                        <v-list-item-icon>
                            <v-icon left>
                                mdi-account-tie
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('corporate_clients') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="isB2B && isTourOperator"
                        :to="{name: 'companyAgencies'}"
                        @click="
                            menu = false
                            menuCompany = false
                        "
                    >
                        <v-list-item-icon>
                            <v-icon left>
                                mdi-brightness-auto
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('agencies') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-list-item>
        <v-list-item>
            <v-menu v-model="menu" :close-on-content-click="false" offset-y>
                <template v-slot:activator="{on}">
                    <v-btn
                        text
                        block
                        data-cy="login-menu-button"
                        :class="$breakpoint.smAndDown ? 'py-0 justify-space-between' : ''"
                        v-on="on"
                    >
                        <person-label v-if="person" :person="person" />
                        <span v-else>{{ $t('log_in') }}</span>
                        <v-icon right>
                            mdi-menu-down
                        </v-icon>
                    </v-btn>
                </template>
                <v-list v-if="person" dense>
                    <v-list-item
                        :to="isB2B ? {name: 'companyPerson', params: {id: person.personId}} : {name: 'user-settings'}"
                        @click="
                            menu = false
                            menuCompany = false
                        "
                    >
                        <v-list-item-icon>
                            <v-icon left>
                                mdi-account
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('account') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="!isB2B"
                        :to="{name: 'orders'}"
                        data-cy="account-orders-button"
                        @click="
                            menu = false
                            menuCompany = false
                        "
                    >
                        <v-list-item-icon>
                            <v-icon left>
                                mdi-view-list
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('orders') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="!isB2B && showMembersButton"
                        :to="{name: 'members'}"
                        data-cy="account-members-button"
                        @click="menu = false"
                    >
                        <v-list-item-icon>
                            <v-icon left>
                                mdi-account-multiple
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('members') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-if="isB2B && !isSupplier"
                        :to="{name: 'emailTemplates'}"
                        @click="
                            menu = false
                            menuCompany = false
                        "
                    >
                        <v-list-item-icon>
                            <v-icon left>
                                mdi-cog
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('admin.settings') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="hasCMSAccess" @click="cmsLogin">
                        <v-list-item-icon>
                            <v-icon left>
                                mdi-rocket
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('admin.cms') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="logout">
                        <v-list-item-icon>
                            <v-icon left>
                                mdi-exit-to-app
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('log_out') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-card v-else-if="!forgotPassword" width="350">
                    <v-card-title class="title font-weight-light">
                        {{ $t('sign_in') }} {{ $t('or') }}&nbsp;
                        <a href="#" class="font-weight-medium text-decoration-none" @click.prevent="goToRegister">
                            {{ $t('register') }}
                        </a>
                    </v-card-title>
                    <v-card-text>
                        <a
                            v-if="!gpLoginLink && b2bLogin"
                            href="#"
                            data-cy="b2b-auth"
                            class="font-weight-medium text-decoration-none"
                            @click.prevent="goToB2BAuth"
                        >
                            {{ $t('sign_in_as_b2b') }}
                        </a>
                        <a v-else-if="b2bLogin" class="font-weight-medium text-decoration-none" :href="gpBaseUrl">
                            {{ $t('sign_in_as_b2b') }}
                        </a>
                        <authorization-form @switch-forgot-password="forgotPassword = true" />
                    </v-card-text>
                </v-card>
                <v-card v-else width="350">
                    <v-card-title class="title font-weight-light">
                        {{ $t('forgot_your_password') }}
                    </v-card-title>
                    <v-card-text>
                        <forgot-password-form
                            @switch-login="forgotPassword = false"
                            @forgot-password-success="onForgotPassword"
                        />
                    </v-card-text>
                </v-card>
            </v-menu>
        </v-list-item>
    </v-list>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import {authStore, runtimeStore} from '@/store'
    import AuthorizationForm from '~/components/account/forms/AuthorizationForm'
    import ForgotPasswordForm from '~/components/account/forms/ForgotPasswordForm'
    import {EventBus, AUTH_EVENT} from '@/utils/event-bus'
    import PersonLabel from '~src/components/account/snippets/personLabel.src'
    import AccountMenuCustomItems from '~src/components/account/snippets/accountMenuCustomItems.src'

    @Component({
        components: {
            AccountMenuCustomItems,
            PersonLabel,
            ForgotPasswordForm,
            AuthorizationForm,
        },
    })
    export default class AccountMenu extends Vue {
        menu = false
        menuCompany = false
        forgotPassword = false

        async logout() {
            this.menu = false
            this.menuCompany = false
            if (this.$route.name !== 'home') {
                await this.$router.push({name: 'home'})
            }
            authStore.logOut()
            if (this.$config.onlyRegistered) {
                await this.$router.push({
                    name: this.$config.onlyRegistered === 'client' ? 'authorization' : 'b2b-auth',
                })
            } else {
                await authStore.auth()
            }
            this.$toast.success(this.$t('messages.success.logout'))
        }

        goToRegister() {
            this.menu = false
            this.menuCompany = false
            this.$router.push({name: 'register'})
        }

        onForgotPassword() {
            this.menu = false
            this.menuCompany = false
            this.forgotPassword = false
        }

        created() {
            EventBus.$on(AUTH_EVENT, this.onAuth)
        }

        beforeDestroy() {
            EventBus.$off(AUTH_EVENT, this.onAuth)
        }

        onAuth() {
            if (this.menu) {
                this.$toast.success(this.$t('messages.success.authorized'))
            }
            this.menu = false
            this.menuCompany = false
            this.$emit('auth')
        }

        async cmsLogin() {
            try {
                const {token, user} = await this.$api.cmsAuth.post()
                const form = document.createElement('form')
                form.method = 'POST'
                form.target = '_blank'
                form.action = this.cmsLoginLink
                const jwtField = document.createElement('input')
                jwtField.type = 'hidden'
                jwtField.name = 'jwt'
                jwtField.value = token
                form.appendChild(jwtField)
                const userField = document.createElement('input')
                userField.type = 'hidden'
                userField.name = 'user'
                userField.value = JSON.stringify(user)
                form.appendChild(userField)
                document.body.appendChild(form)
                form.submit()
                document.body.removeChild(form)
                this.menu = false
                this.menuCompany = false
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        goToB2BAuth() {
            this.menu = false
            this.menuCompany = false
            this.$router.push({name: 'b2b-auth'})
        }

        get isB2B() {
            return authStore.hasCorporateAccess
        }

        get isSupplier() {
            return authStore.companyType === 'INTERNAL_SUPPLIER'
        }

        get cmsLoginLink() {
            return runtimeStore.config.cmsLoginLink
        }

        get gpBaseUrl() {
            return runtimeStore.gpBaseUrl
        }

        get gpLoginLink() {
            return runtimeStore.config.gpLoginLink
        }

        get b2bLogin() {
            return runtimeStore.config.b2bLogin
        }

        get hasCMSAccess() {
            return (
                this.isB2B &&
                this.cmsLoginLink &&
                ['to1l.supervisor', 'to1l.director', 'to1l.support'].includes(authStore.person.role)
            )
        }

        get isTourOperator() {
            return authStore.isTourOperator
        }

        get isAgency() {
            return authStore.isAgency
        }

        get person() {
            return authStore.person
        }

        // Overrides in Mods
        get showMembersButton() {
            return true
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .v-list-item:hover::before,
        .v-list-item:active::before,
        .v-list-item:focus::before,
        .v-list-item--active::before {
            opacity: 0 !important;
        }

        .v-list-item--active .v-btn::before {
            opacity: 0.04 !important;
        }
    }
</style>
