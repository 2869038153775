<template>
    <v-app>
        <add-service-snackbar />
        <notifications-snackbar />
        <header-bar />
        <v-main>
            <v-container fluid>
                <nuxt />
            </v-container>
        </v-main>
        <footer-bar />
        <div v-if="script" v-html="script" />
    </v-app>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    import AddServiceSnackbar from '~/components/snippets/AddServiceSnackbar'
    import {EventBus, LOGOUT_REDIRECT_EVENT} from '@/utils/event-bus'
    import HeaderBar from '~src/components/headerBar.src'
    import FooterBar from '~src/components/footerBar.src'
    import {cmsStore} from '@/store'
    import BaseLayout from '@/components/BaseLayout'
    import NotificationsSnackbar from '@/components/NotificationsSnackbar'

    @Component({
        components: {
            NotificationsSnackbar,
            HeaderBar,
            FooterBar,
            AddServiceSnackbar,
        },
    })
    export default class DefaultLayout extends BaseLayout {
        created() {
            EventBus.$on(LOGOUT_REDIRECT_EVENT, () => {
                if (this.$toast) {
                    this.$toast.error(this.$t('authorization_timeout'))
                }
            })
        }

        get script() {
            return cmsStore.layoutSettings.script
        }
    }
</script>
