<template>
    <vc-date-picker
        v-model="range"
        mode="range"
        :locale="locale"
        :min-date="disableMinStartDate ? null : minStartDate"
        :max-date="maxEndDate"
        :columns="$breakpoint.smAndDown ? 1 : 2"
        :rows="1"
        :popover="popover"
        @dayclick="dayclick"
        @popoverWillShow="popoverWillShow"
    >
        <v-text-field
            ref="startDateInput"
            slot-scope="{inputProps, inputEvents}"
            :class="textFieldClass"
            :label="$t('supplier.dates_range')"
            readonly
            :single-line="singleLine"
            :outlined="outlined"
            filled
            hide-details
            :background-color="color || 'white'"
            :prepend-inner-icon="showIcon ? 'mdi-calendar' : null"
            v-bind="format(inputProps)"
            v-on="inputEvents"
        />
    </vc-date-picker>
</template>

<script>
    import {Component, Prop} from 'vue-property-decorator'
    import DateRangeM from '~/components/search/forms/DaterangeM'

    @Component
    export default class DateRange extends DateRangeM {
        @Prop({default: false}) disableMinStartDate
        @Prop({default: ''}) color
    }
</script>

<style scoped lang="scss">
    .date-range-input::v-deep {
        @media (min-width: 961px) and (max-width: 1635px) {
            input {
                font-size: 16px;
            }
        }
    }
</style>
