<template>
    <v-dialog v-model="open" max-width="900px">
        <template v-slot:activator="on">
            <slot v-on="on" />
        </template>
        <v-card>
            <v-card-title>
                {{ $t('flights.price_detail') }}
            </v-card-title>
            <v-card-text>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    {{ $t('flights.flight') }}
                                </th>
                                <th class="text-left">
                                    {{ $t('flights.passenger_type') }}
                                </th>
                                <th class="text-left">
                                    {{ $t('flights.passenger_count') }}
                                </th>
                                <th class="text-left">
                                    {{ $t('flights.base_fare') }}
                                </th>
                                <th class="text-left">
                                    {{ $t('flights.taxes_and_fees') }}
                                </th>
                                <th class="text-left">
                                    {{ $t('flights.service_fees') }}
                                </th>
                                <th class="text-left">
                                    {{ $t('total') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in prices" :key="index">
                                <td>{{ item.flightName }}</td>
                                <td>{{ item.passengerType }}</td>
                                <td>{{ item.passengerTypeCount }}</td>
                                <td>
                                    {{
                                        {
                                            amount: item.baseFare,
                                            originalAmount: item.originalBaseFare,
                                            currency,
                                            originalCurrency,
                                        } | price
                                    }}
                                </td>
                                <td>
                                    {{
                                        {
                                            amount: item.taxesAndFees,
                                            originalAmount: item.originalTaxesAndFees,
                                            currency,
                                            originalCurrency,
                                        } | price
                                    }}
                                </td>
                                <td>
                                    {{
                                        {
                                            amount: item.serviceFees,
                                            originalAmount: item.originalServiceFees,
                                            currency,
                                            originalCurrency,
                                        } | price
                                    }}
                                </td>
                                <td>
                                    {{
                                        {
                                            amount: item.baseFare + item.taxesAndFees + (item.serviceFees || 0),
                                            originalAmount:
                                                item.originalBaseFare +
                                                item.originalTaxesAndFees +
                                                (item.originalServiceFees || 0),
                                            currency,
                                            originalCurrency,
                                        } | price
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t('total') }}</td>
                                <td />
                                <td>{{ total.passengers }}</td>
                                <td>
                                    {{
                                        {
                                            amount: total.baseFare,
                                            originalAmount: total.originalBaseFare,
                                            currency,
                                            originalCurrency,
                                        } | price
                                    }}
                                </td>
                                <td>
                                    {{
                                        {
                                            amount: total.taxesAndFees,
                                            originalAmount: total.originalTaxesAndFees,
                                            currency,
                                            originalCurrency,
                                        } | price
                                    }}
                                </td>
                                <td>
                                    {{
                                        {
                                            amount: total.serviceFees,
                                            originalAmount: total.originalServiceFees,
                                            currency,
                                            originalCurrency,
                                        } | price
                                    }}
                                </td>
                                <td>
                                    {{
                                        {
                                            amount: total.total,
                                            originalAmount: total.originalTotal,
                                            currency,
                                            originalCurrency,
                                        } | price
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {EventBus, SHOW_FLIGHT_PRICE_DETAIL} from '@/utils/event-bus'

    @Component
    export default class FlightPriceDetailModal extends Vue {
        open = false
        prices = []
        currency = null
        originalCurrency = null

        created() {
            EventBus.$on(SHOW_FLIGHT_PRICE_DETAIL, offer => {
                this.currency = offer.price.currency
                this.originalCurrency = offer.price.originalCurrency
                this.prices = offer.priceDetails.map(details => {
                    if (!details.baseFare) {
                        details.baseFare = offer.price.amount / offer.priceDetails.length
                    }
                    if (!details.taxesAndFees) {
                        details.taxesAndFees = 0
                    }
                    if (!details.serviceFees) {
                        details.serviceFees = 0
                    }
                    if (!details.originalBaseFare) {
                        details.originalBaseFare = offer.price.originalAmount / offer.priceDetails.length
                    }
                    if (!details.originalTaxesAndFees) {
                        details.originalTaxesAndFees = 0
                    }
                    if (!details.originalServiceFees) {
                        details.originalServiceFees = 0
                    }
                    return details
                })
                this.total = {
                    passengers: this.prices.reduce((acc, {passengerTypeCount: value}) => acc + value, 0),
                    baseFare: this.prices.reduce((acc, {baseFare: value}) => acc + value, 0),
                    taxesAndFees: this.prices.reduce((acc, {taxesAndFees: value}) => acc + value, 0),
                    serviceFees: this.prices.reduce((acc, {serviceFees: value}) => (value ? acc + value : acc), 0),
                    originalBaseFare: this.prices.reduce((acc, {originalBaseFare: value}) => acc + value, 0),
                    originalTaxesAndFees: this.prices.reduce((acc, {originalTaxesAndFees: value}) => acc + value, 0),
                    originalServiceFees: this.prices.reduce(
                        (acc, {originalServiceFees: value}) => (value ? acc + value : acc),
                        0
                    ),
                    total: this.prices.reduce(
                        (acc, {serviceFees, taxesAndFees, baseFare}) =>
                            acc + (serviceFees ? serviceFees : 0) + taxesAndFees + baseFare,
                        0
                    ),
                    originalTotal: this.prices.reduce(
                        (acc, {originalServiceFees, originalTaxesAndFees, originalBaseFare}) =>
                            acc +
                            (originalServiceFees ? originalServiceFees : 0) +
                            originalTaxesAndFees +
                            originalBaseFare,
                        0
                    ),
                }
                this.open = true
            })
        }
    }
</script>

<style scoped>
    @media (max-width: 960px) {
        ::v-deep .v-card__title {
            font-size: 16px !important;
        }
    }

    @media (max-width: 480px) {
        ::v-deep .v-card__title {
            font-size: 14px !important;
        }
    }
</style>
