<template>
    <v-dialog
        v-if="isB2B"
        persistant
        :fullscreen="$breakpoint.smAndDown ? true : false"
        hide-overlay
        width="40%"
        max-width="1000"
        @click:outside="clearInput"
    >
        <template v-slot:activator="{on, attrs}">
            <v-badge overlap class="w-100" :content="count" :value="count ? true : false" color="red">
                <v-btn
                    depressed
                    block
                    v-bind="attrs"
                    :small="$breakpoint.smAndDown"
                    color="primary"
                    class="mb-2 white--text"
                    @click="markMessagesRead"
                    v-on="on"
                >
                    {{ $t('chat') }}
                </v-btn>
            </v-badge>
        </template>

        <template v-slot:default="dialog">
            <v-card>
                <v-card-actions class="justify-end px-5 pt-5 pb-0">
                    <div class="text-h4 primary--text ma-auto">{{ $t('chat') }} {{ $t('order') }} # {{ orderId }}</div>
                    <v-icon class="v-card-close" @click="dialog.value = false">
                        mdi-close
                    </v-icon>
                </v-card-actions>
                <div class="wrapper pa-5">
                    <div ref="messageWindow" class="message-box ma-1 pa-1 rounded grey lighten-4">
                        <v-card
                            v-for="message in messages"
                            :key="message.messageDateTime + message.userId + message.messageText"
                            class="mt-1 rounded-lg"
                            :class="{'text-right': message.userId === personId}"
                        >
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon small>
                                            mdi-account
                                        </v-icon>
                                        <span :class="{'primary--text': message.userId === personId}">
                                            {{ message.senderFirstName }}
                                            {{ message.senderLastName }}
                                        </span>
                                        {{ message.messageDateTime }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="ml-5 make-new-line">
                                        {{ message.messageText }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </div>
                    <div class="mt-2 d-flex no-wrap align-end">
                        <div class="textarea mr-5">
                            <v-textarea
                                v-model="message"
                                outlined
                                rows="2"
                                :placeholder="$t('enter_message')"
                                :label="$t('enter_message')"
                                hide-details="auto"
                            />
                        </div>
                        <div class="d-flex flex-column justify-center aling-centre button-send">
                            <v-btn :disabled="!message.length" large color="primary" @click="sendMessage()">
                                {{ $t('send') }}
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator'
    import {authStore} from '~/store'

    @Component()
    export default class ChatModal extends Vue {
        @Prop({required: true}) orderId

        messages = []
        message = ''
        count = 0

        @Watch('orderId')
        async orderIdWatch(n) {
            if (n && this.isB2B) {
                await this.getMessages()
                this.countUnreadMessages()
            }
        }

        countUnreadMessages() {
            this.messages.forEach(message => {
                if (!message.messageRead) {
                    this.count++
                }
            })
        }

        async sendMessage() {
            try {
                await this.$api.message.put(this.orderId, {messageText: this.message})
            } catch (e) {
                console.error('sendMessage failed', e.message)
            }

            this.clearInput()
            await this.getMessages()
            this.scrollToLastMessage()
            await this.markMessagesRead()
        }

        async markMessagesRead() {
            try {
                await this.$api.markReadCount.put(this.orderId, {markReadMessagesCount: this.messages.length + 1})
                // eslint-disable-next-line no-empty
            } catch (e) {}
            this.count = 0
            this.scrollToLastMessage()
        }

        scrollToLastMessage() {
            this.$refs.messageWindow.scrollTop = this.$refs.messageWindow.scrollHeight
        }

        clearInput() {
            this.message = ''
        }

        async getMessages() {
            let messages
            try {
                messages = await this.$api.messages.get(this.orderId, {})
                this.messages = messages.messages.reverse()
            } catch (e) {
                messages = []
            }
        }

        get personId() {
            return authStore.person.personId
        }

        get isB2B() {
            return authStore.isB2B
        }
    }
</script>

<style lang="scss" scoped>
    @import '~vuetify/src/components/VTextField/_variables.scss';

    .w-100 {
        width: 100%;
    }

    .message-box {
        min-height: 100px;
        max-height: 400px;
        overflow-y: auto;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
        .wrapper {
            display: flex;
            flex-direction: column;
            min-height: 90vh;
        }

        .message-box {
            min-height: 75vh;
            max-height: 75vh;
        }
    }

    .make-new-line {
        white-space: pre-line;
    }

    .textarea {
        flex: 0.8 1 auto;
    }

    .button-send {
        flex: 0.2 1 auto;
    }

    ::v-deep.v-btn--fixed.v-btn--bottom {
        bottom: 48px !important;
    }
</style>
