<template>
    <search-form-layout :popup="popup" title="packages.packages" @close-popup="$emit('close-popup')">
        <template v-slot:form>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col cols="12" md="10">
                        <v-row>
                            <v-col md="4" xl="3" cols="12">
                                <city-autocomplete
                                    v-model="departurePoint"
                                    label="transfers.departure_location"
                                    single-line
                                    filled
                                    :rules="[
                                        v =>
                                            !!v || `${$t('transfers.departure_location')} ${$t('validation.required')}`,
                                    ]"
                                    product-type="PACKAGE"
                                />
                            </v-col>
                            <v-col md="4" xl="3" cols="12">
                                <city-autocomplete
                                    v-model="arrivalPoint"
                                    label="transfers.arrival_location"
                                    single-line
                                    filled
                                    :rules="[
                                        v => !!v || `${$t('transfers.arrival_location')} ${$t('validation.required')}`,
                                    ]"
                                    product-type="PACKAGE"
                                />
                            </v-col>
                            <v-col md="4" xl="2" cols="12">
                                <vc-date-picker v-model="dateTimeData" :locale="$i18n.locale" :min-date="minDateTime">
                                    <v-text-field
                                        slot-scope="{inputProps, inputEvents}"
                                        :class="{caption: $breakpoint.smAndDown}"
                                        readonly
                                        single-line
                                        filled
                                        hide-details
                                        background-color="white"
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="formatDatepickerInput(inputProps)"
                                        v-on="inputEvents"
                                    />
                                </vc-date-picker>
                            </v-col>
                            <v-col xl="2" md="4" cols="12">
                                <v-select
                                    v-model="nights"
                                    :items="nightsCount"
                                    label="Nights"
                                    single-line
                                    filled
                                    background-color="white"
                                    hide-details
                                >
                                    <template v-slot:selection="{item}">
                                        {{ item }}
                                        &nbsp;
                                        <span>
                                            {{ $tc('night', item) }}
                                        </span>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col md="4" xl="2" cols="12">
                                <tourists-select-menu
                                    :_adults.sync="adults"
                                    :_children-ages.sync="childrenAges"
                                    token="guest"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col md="2" cols="12" class="hidden-sm-and-down">
                        <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                            {{ $t('search') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
            <additional-options-wrapper ref="addForm" :options="['citizenship']" />
            <v-row class="hidden-md-and-up">
                <v-col md="2" cols="12">
                    <v-btn block dark x-large color="primary" :disabled="!valid" @click="search">
                        {{ $t('search') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </search-form-layout>
</template>

<script>
    import {Component, Prop} from 'vue-property-decorator'
    import TransferPointAutocomplete from '@/components/transfers/search/forms/TransferPointAutocomplete'
    import {runtimeStore, packagesRuntimeStore, packagesStore, authStore, persistentStore} from '@/store'
    import SearchFormLayout from '~/components/parts/SearchFormLayout'
    import CityAutocomplete from '~/components/search/forms/CityAutocomplete'
    import TouristsSelectMenu from '~src/components/search/forms/touristsSelectMenu.src'
    import AdditionalSearchOptionsMixin from '~/mixins/AdditionalSearchOptionsMixin'
    import AdditionalOptionsWrapper from '~/components/snippets/AdditionalSearchOptions/AdditionalOptionsWrapper'
    import {CHANGE_TAB_MOBILE_EVENT, CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'

    @Component({
        components: {
            TouristsSelectMenu,
            TransferPointAutocomplete,
            SearchFormLayout,
            CityAutocomplete,
            AdditionalOptionsWrapper,
        },
    })
    export default class PackagesSearchForm extends AdditionalSearchOptionsMixin {
        @Prop({default: false}) popup
        @Prop({default: false}) outlined
        @Prop({default: true}) singleLine

        valid = false
        departurePoint = {}
        arrivalPoint = {}
        //TODO Add time picker
        dateTime = null
        adults = 2
        childrenAges = []
        nights = 1

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, this.reload)
        }

        async reload() {
            if (this.departurePoint.id) {
                try {
                    const {cities} = this.$api.locations.get({id: this.departurePoint.id, limitCities: 1})
                    this.departurePoint = cities[0]
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
            if (this.arrivalPoint.id) {
                try {
                    const {cities} = this.$api.locations.get({id: this.arrivalPoint.id, limitCities: 1})
                    this.arrivalPoint = cities[0]
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
        }

        newQuery() {
            const date = new Date(this.dateTime)
            const endDate = date.setDate(date.getDate() + this.nights)
            const endDateString = this.$dateFns.format(endDate)
            return {
                startDate: this.dateTime,
                adults: this.adults,
                childrenAges: this.childrenAges,
                convertToCurrency: persistentStore.currency,
                departureCityId: this.departurePoint.id,
                arrivalCityId: this.arrivalPoint.id,
                packageId: runtimeStore.config.packageId_hotelsFlights,
                endDate: endDateString,
                directFlight: false,
            }
        }

        async search() {
            const query = this.newQuery()
            this.$emit('close-popup')
            packagesRuntimeStore.SET_ARRIVAL_POINT(this.arrivalPoint)
            packagesRuntimeStore.SET_DEPARTURE_POINT(this.departurePoint)
            const name = 'packages'
            if (this.$route.name !== name) {
                packagesRuntimeStore.newSearch()
            }
            try {
                await this.$router.push({name, query})
            } catch (e) {
                await packagesRuntimeStore.search(packagesStore.searchRequest)
            }
            EventBus.$emit(CHANGE_TAB_MOBILE_EVENT, 1)
        }

        async mounted() {
            await this.$store.restored
            const {departureCityId, arrivalCityId} = packagesStore.searchRequest
            if (departureCityId && arrivalCityId) {
                await packagesRuntimeStore.loadPoints({departureCityId, arrivalCityId})
            }
            this.arrivalPoint = Object.keys(packagesRuntimeStore.arrivalPoint).length
                ? packagesRuntimeStore.arrivalPoint
                : null
            this.departurePoint = Object.keys(packagesRuntimeStore.departurePoint).length
                ? packagesRuntimeStore.departurePoint
                : null
            this.nights = this.$dateFns.differenceInDays(
                this.$dateFns.parseISO(packagesStore.searchRequest.endDate),
                this.$dateFns.parseISO(packagesStore.searchRequest.startDate)
            )
            if (this.$dateFns.isAfter(this.$dateFns.parseISO(packagesStore.searchRequest.startDate), new Date())) {
                this.dateTime = packagesStore.searchRequest.startDate
            } else {
                this.dateTime = this.$dateFns.format(this.$dateFns.addDays(new Date(), 1))
            }
            this.adults = authStore.user.isEmployee ? 1 : packagesStore.searchRequest.adults
            this.childrenAges = authStore.user.isEmployee ? [] : [...packagesStore.searchRequest.childrenAges]
        }

        //TODO Add to mixin
        formatDatepickerInput(props) {
            let result = ''
            if (this.dateTime !== null) {
                result = this.$options.filters.dateFormat(this.dateTime)
            }
            props.value = result
            return props
        }

        get minDateTime() {
            return this.$dateFns.format(new Date())
        }

        get nightsCount() {
            const arr = Array.from(Array(31).keys())
            arr.shift()
            return arr
        }

        get citizenshipItems() {
            return runtimeStore.countries.map(({id, name}) => {
                return {
                    name,
                    value: id,
                }
            })
        }

        get defaultCitizenshipId() {
            return runtimeStore.config.defaultCitizenshipId
        }

        get dateTimeData() {
            return this.$dateFns.parseISO(this.dateTime)
        }

        set dateTimeData(val) {
            this.dateTime = val ? this.$dateFns.format(val) : null
        }
    }
</script>
