<template>
    <client-only>
        <v-card>
            <v-card-title>
                <v-text-field
                    v-model="search"
                    append-icon="search"
                    :placeholder="$t('start_typing')"
                    single-line
                    hide-details
                    :disabled="isLoading"
                />
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page="5"
                :loading="isLoading"
                :footer-props="{'items-per-page-options': [10, 20, 50]}"
                sort-by="name"
                class="elevation-1"
                data-cy="companies-table"
                :search="search"
            >
                <template v-slot:item.active="{item}">
                    <v-switch
                        v-model="item.active"
                        color="primary"
                        :label="item.active ? $t('active') : $t('inactive')"
                        @change="changeActive(item)"
                    />
                </template>
                <template v-slot:item.edit="{item}">
                    <v-icon color="primary" @click="edit(item.id)">
                        mdi-pencil
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>
    </client-only>
</template>

<script>
    import {Component, Mixins, Prop} from 'vue-property-decorator'
    import {authStore, runtimeStore} from '@/utils/store-accessor'
    import LoadMixin from '@/mixins/LoadMixin'

    @Component
    export default class CompaniesTable extends Mixins(LoadMixin) {
        @Prop({default: []}) companyType

        search = ''

        get headers() {
            return [
                {text: this.$t('company_name'), value: 'name'},
                {text: this.$t('company_alias'), value: 'alias'},
                {text: this.$t('company_code'), value: 'code'},
                {text: this.$t('company_type'), value: 'type'},
                {text: this.$t('country'), value: 'country'},
                {text: this.$t('city'), value: 'city'},
                {text: this.$t('phone'), value: 'phone'},
                {text: this.$t('email'), value: 'email'},
                {text: this.$t('registration'), value: 'date'},
                {text: this.$t('status'), value: 'active', width: '10%'},
                {text: this.$t('edit'), value: 'edit', align: 'center'},
            ]
        }

        get items() {
            return runtimeStore.companies
                .filter(company => {
                    return this.companyType.includes(company.type)
                })
                .map(company => ({
                    id: company.id,
                    name: company.name,
                    alias: company.alias,
                    code: company.companyCode,
                    type: company.type,
                    country: company.countryName,
                    city: company.cityName,
                    phone: company.phones.join(', '),
                    email: company.email,
                    date: this.$options.filters.dateShortFormat(company.creationDate),
                    active: company.active,
                }))
        }

        get serverItemsLength() {
            return runtimeStore.companies.length
        }

        get isLoading() {
            return runtimeStore.loading
        }

        load() {
            runtimeStore.loadCompanies({companyId: authStore.person.companyId, relationship: 'CLIENT', active: false})
        }

        edit(id) {
            this.$router.push({name: 'company-client-profile', params: {id}})
        }

        async changeActive(item) {
            const {id, active} = item
            try {
                await this.$api.companies.put(id, {active})
            } catch (e) {
                await runtimeStore.loadCompanies({
                    companyId: authStore.person.companyId,
                    relationship: 'CLIENT',
                    active: false,
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .v-label {
        font-size: 0.75rem !important;
    }
    ::v-deep .v-input__icon--append {
        display: none;
    }
</style>
