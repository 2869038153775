<template>
    <div>
        <component
            :is="`activity-card${$breakpoint.smAndDown ? '-mobile' : ''}`"
            v-for="product in offers"
            :key="product.info.name"
            :product="product"
        />
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import ActivityCard from '@/components/activities/search/offers/ActivityCard'
    import ActivityCardMobile from '@/components/activities/search/offers/ActivityCardMobile'

    @Component({
        components: {
            ActivityCard,
            ActivityCardMobile,
        },
    })
    export default class ActivityOffers extends Vue {
        @Prop({default: () => []}) offers
    }
</script>
