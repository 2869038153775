<template>
    <v-autocomplete
        v-model="airport"
        :items="items"
        :loading="loading"
        :search-input.sync="search"
        hide-no-data
        hide-selected
        item-value="iataCode"
        :label="`${label}*`"
        no-filter
        clearable
        return-object
        :rules="[v => !!v || `${label} ${$t('validation.required')}`]"
        :disabled="disabled"
        @change="$emit('change', $event ? $event.iataCode : null)"
    >
        <template v-slot:item="{item}">
            <v-icon small left>
                mdi-airplane-takeoff
            </v-icon>
            {{ item.name }}, {{ item.cityName }}, {{ item.countryName }}
        </template>
        <template v-slot:selection="{item}">{{ item.name }}, {{ item.cityName }}, {{ item.countryName }}</template>
    </v-autocomplete>
</template>

<script>
    import {Component, Watch, Prop, Model, Mixins} from 'vue-property-decorator'
    import AirportsSearchMixin from '~/mixins/AirportsSearchMixin'
    import LoadMixin from '@/mixins/LoadMixin'

    @Component
    export default class AirportsSearchInput extends Mixins(AirportsSearchMixin, LoadMixin) {
        @Model('change') value
        @Prop() label
        @Prop({default: false, type: Boolean}) disabled

        airport = null
        items = []
        search = null
        loading = false
        timer = null

        @Watch('search')
        onSearch(val) {
            if (!val) return
            if (this.timer) {
                clearTimeout(this.timer)
            }
            this.timer = window.setTimeout(() => {
                this.initSearch({
                    pattern: val,
                })
            }, 400)
        }

        async mounted() {
            if (this.value) {
                try {
                    this.loading = true
                    const [airport] = await this.$api.airports.get({iataCode: this.value})
                    this.items = [Object.assign(airport, {parentName: airport.countryName, type: 'airport'})]
                    // eslint-disable-next-line no-empty
                } catch (e) {
                } finally {
                    this.loading = false
                }
            }
        }

        async initSearch(val) {
            this.loading = true
            try {
                this.items = await this.searchAirports(val)
            } finally {
                this.loading = false
            }
        }

        async load() {
            if (!this.value) return
            try {
                this.loading = true
                this.items = await this.searchAirports({
                    iataCode: this.value,
                })
                this.airport = this.items[0]
            } finally {
                this.loading = false
            }
        }
    }
</script>
