<template>
    <client-only>
        <v-data-table
            :headers="headers"
            :items="services"
            :items-per-page="ordersOnPage"
            :server-items-length="ordersCount"
            :loading="isLoading"
            :options.sync="options"
            :footer-props="{'items-per-page-options': [10, ordersOnPage, 100]}"
            group-by="orderId"
            disable-sort
            :no-data-text="noDataText"
            class="elevation-1"
            data-cy="orders-table"
        >
            <template v-if="selectable" v-slot:header.id="{header}">
                <v-checkbox
                    v-model="selectAllFlag"
                    dense
                    :ripple="false"
                    hide-details
                    :value="false"
                    @change="$emit('selectAllToggle', $event)"
                >
                    <template v-slot:label>
                        <span>{{ header.text }}</span>
                    </template>
                </v-checkbox>
            </template>
            <template v-slot:group.header="{group}">
                <td :colspan="headers.length" class="text-start">
                    <div class="d-flex align-center">
                        <v-checkbox
                            v-if="selectable"
                            v-model="orderIds"
                            dense
                            :ripple="false"
                            hide-details
                            :value="group"
                        />
                        <template v-if="selectable">
                            <n-link no-prefetch :to="{name: 'order-details', params: {id: group}}">
                                <strong>{{ group }}</strong>
                            </n-link>
                            <span v-if="!orderTitleEdit[group]" class="ml-2">
                                {{ getOrder(group).title }}
                                <v-icon
                                    small
                                    color="primary"
                                    @click="
                                        $set(orderTitleEdit, group, true)
                                        $set(orderTitle, group, getOrder(group).title)
                                    "
                                >
                                    mdi-pencil
                                </v-icon>
                            </span>
                            <div v-else class="d-flex align-center ml-4">
                                <v-text-field v-model="orderTitle[group]" dense hide-details class="order-title-edit" />
                                <v-btn small class="ml-1" color="primary" @click="saveOrderTitle(group)">
                                    {{ $t('save') }}
                                </v-btn>
                            </div>
                        </template>
                        <n-link v-else no-prefetch :to="{name: 'order-details', params: {id: group}}">
                            <strong>{{ group }}</strong>
                        </n-link>
                    </div>
                </td>
            </template>
            <template v-slot:item.created="{item}">
                <div class="text-no-wrap" v-html="item.created" />
            </template>
            <template v-slot:item.title="{item}">
                <div class="d-flex flex-column">
                    <span class="d-flex justify-end justify-md-start">
                        <v-icon small class="mr-1">{{ getServiceTypeIcon(item.serviceType) }}</v-icon>
                        <div v-html="item.pureTitle" />
                    </span>
                    <div v-html="item.title + item.location" />
                </div>
            </template>
            <template v-slot:item.date="{item}">
                <div class="text-no-wrap" v-html="item.date" />
            </template>
            <template v-slot:item.travellers="{item}">
                <span v-html="item.travellers" />
            </template>
            <template v-slot:item.status="{item}">
                <span :class="`${item.statusColor}--text`">{{ item.status }}</span>
                <div v-if="isTO1">
                    <span class="commission">
                        {{ item.supplierCompanyName }}
                    </span>
                </div>
            </template>
            <template v-slot:item.supplierPrice="{item}">
                <span v-if="item.supplier.price">
                    {{ item.supplier.price.amount | priceFormat(item.supplier.price.currency) }}
                </span>
                <div
                    :class="
                        paymentStatusClass(
                            item.status,
                            item.supplier.cardGuarantee ? 'pay_at_hotel' : item.supplier.paymentStatus
                        )
                    "
                    class="text-no-wrap"
                >
                    {{
                        item.supplier.cardGuarantee
                            ? $t(`paymentStatus.PAY_AT_HOTEL`)
                            : $t(`paymentStatus.${item.supplier.paymentStatus}`)
                    }}
                </div>
            </template>
            <template v-slot:item.price="{item}">
                <div v-if="item.violation && item.customer.price">
                    <span>
                        {{ item.customer.price.amount | priceFormat(item.customer.price.currency) }}
                    </span>
                    <span>
                        <corporate-policy-violation :policies="item.corporatePolicies" />
                    </span>
                </div>
                <span v-else>
                    {{ item.customer.price.amount | priceFormat(item.customer.price.currency) }}
                </span>
                <div :class="paymentStatusClass(item.status, item.customer.paymentStatus)" class="text-no-wrap">
                    {{ $t(`paymentStatus.${item.customer.paymentStatus}`) }}
                </div>
                <div v-if="isAgency || isTO2" class="commission">
                    {{ $t('commission') }}:
                    {{ item.customer.price.commission | priceFormat(item.customer.price.currency) }}
                </div>
            </template>
            <template v-slot:item.clientCompanyName="{item}">
                <strong>{{ item.clientCompanyName }}</strong>
                <small>{{ item.agent }}</small>
            </template>
        </v-data-table>
    </client-only>
</template>

<script>
    import {Component, Prop, PropSync, Mixins} from 'vue-property-decorator'
    import {authStore, runtimeStore, persistentStore} from '@/store'
    import goTo from 'vuetify/es5/services/goto'
    import {clean, matches} from '@/utils/helpers'
    import LoadMixin from '@/mixins/LoadMixin'
    import LoadDataStatusMixin from '@/mixins/LoadDataStatusMixin'
    import CommissionAmount from '@/components/snippets/CommissionAmount'

    @Component({
        components: {
            CommissionAmount,
            CorporatePolicyViolation: () => import('~/components/snippets/CorporatePolicyViolation'),
        },
    })
    export default class Orders extends Mixins(LoadMixin, LoadDataStatusMixin) {
        @Prop({default: false}) selectable
        @PropSync('_orderIds', {default: () => []}) orderIds
        @PropSync('_selectAllFlag', {default: () => false}) selectAllFlag

        loaded = false
        orderTitleEdit = {}
        orderTitle = {}
        autoOrdersUpdate = null

        mounted() {
            this.autoOrdersUpdate = setInterval(() => {
                this.loadOrders(true)
            }, 150000)
        }

        beforeDestroy() {
            clearInterval(this.autoOrdersUpdate)
            if (this.$route.name !== 'order-details') {
                persistentStore.SET_ORDERS_TABLE_OPTIONS({
                    itemsPerPage: persistentStore.ordersTableOptions.itemsPerPage,
                    page: 1,
                })
            }
        }

        get options() {
            return persistentStore.ordersTableOptions
        }

        set options(options) {
            if (!this.loaded) return
            const prev = persistentStore.ordersTableOptions
            if (!prev || matches(options, prev)) return
            persistentStore.SET_ORDERS_TABLE_OPTIONS(options)
            this.loadOrders()
        }

        filter() {
            if (this.options.page !== 1) {
                persistentStore.SET_ORDERS_TABLE_OPTIONS(Object.assign({}, this.options, {page: 1}))
            }
            this.loadOrders()
        }

        load() {
            this.loaded = true
            this.loadOrders()
        }

        get filters() {
            return persistentStore.orderFilters
        }

        get isLoading() {
            return runtimeStore.loading
        }

        get services() {
            const orderServices = []
            const orders = runtimeStore.ordersResponse.orders
                .concat()
                .sort((o1, o2) =>
                    this.$dateFns.isAfter(this.$dateFns.parseISO(o1.modified), this.$dateFns.parseISO(o2.modified))
                        ? -1
                        : 1
                )
            const travellersLabel = service => {
                if (!service || !service.travelers || !service.travelers.length) return '-'
                let travellers = `${service.travelers[0].prefix || ''} ${service.travelers[0].name[0].firstName ||
                    ''} ${service.travelers[0].name[0].lastName || ''}`
                if (service.travelers.length > 1) {
                    travellers += ` (+${service.travelers.length - 1})`
                }
                return travellers
            }
            orders.forEach(order => {
                if (order.orderPackage) {
                    orderServices.push({
                        orderId: order.orderId,
                        created: this.$options.filters.dateShortFormat(order.created),
                        clientCompanyName: order.clientCompanyName,
                        title: `<strong>${order.orderPackage.packageName}</strong><br>`,
                        serviceType: order.orderPackage.packageType,
                        location: '',
                        date: `${this.$options.filters.dateShortFormat(
                            order.orderPackage.startDateTime
                        )}<br>${this.$options.filters.dateShortFormat(order.orderPackage.endDateTime)}`,
                        travellers: travellersLabel(order.services[0]),
                        status: this.$t(`bookingStatus.${runtimeStore.orderServiceStatus(order.orderPackage.status)}`),
                        statusColor: runtimeStore.orderServiceStatusColor(
                            runtimeStore.orderServiceStatus(order.orderPackage.status)
                        ),
                        supplierCompanyName: order.orderPackage.supplierCompanyName,
                        supplier: {
                            paymentStatus: order.orderPackage.supplierPaymentStatus,
                            price: order.orderPackage.supplierPrice,
                        },
                        customer: {
                            paymentStatus: order.orderPackage.customerPaymentStatus,
                            price: order.orderPackage.clientPrice,
                        },
                        violation: null,
                        corporatePolicies: [],
                    })
                    return
                }
                order.services.forEach(service => {
                    let location = '',
                        pureTitle = `<strong>${service.serviceName}</strong>`,
                        title = '',
                        date = this.$options.filters.dateShortFormat(service.startDateTime),
                        created = this.$options.filters.dateShortFormat(service.created),
                        countryName,
                        cityName
                    //const transferPlace = place => place.address || place.airportCode || ''
                    switch (service.serviceType) {
                        case 'ACCOMMODATION':
                            service.serviceDetailsContainer.accommodation.rooms.forEach(room => {
                                title += `${room.roomTypeName}, ${room.mealTypeName}<br>`
                            })
                            countryName = service.serviceDetailsContainer.accommodation.countryName
                                ? service.serviceDetailsContainer.accommodation.countryName
                                : ''
                            cityName = service.serviceDetailsContainer.accommodation.cityName
                                ? service.serviceDetailsContainer.accommodation.cityName
                                : ''
                            location = []
                            if (countryName) {
                                location.push(countryName)
                            }
                            if (cityName) {
                                location.push(cityName)
                            }
                            location = location.join(', ')
                            date += '<br>' + this.$options.filters.dateShortFormat(service.endDateTime)
                            break
                        case 'FLIGHT':
                            location = service.serviceDetailsContainer.flight.flightName
                            break
                        case 'ACTIVITY':
                            location = service.serviceDetailsContainer.activity.location
                            break
                        case 'TRANSFER':
                            break
                    }
                    const orderService = {
                        orderId: order.orderId,
                        created: created,
                        clientCompanyName: order.clientCompanyName,
                        pureTitle,
                        title,
                        serviceType: service.serviceType,
                        location,
                        date,
                        travellers: travellersLabel(service),
                        //TODO [API] Fix statuses
                        status: this.$t(`bookingStatus.${runtimeStore.orderServiceStatus(service.status)}`),
                        statusColor: runtimeStore.orderServiceStatusColor(
                            runtimeStore.orderServiceStatus(service.status)
                        ),
                        supplierCompanyName: service.supplierCompanyName,
                        supplier: {
                            paymentStatus: service.supplierPaymentStatus,
                            cardGuarantee: service.serviceDetailsContainer.accommodation
                                ? service.serviceDetailsContainer.accommodation.cardGuarantee
                                : false,
                            price: service.salesTerms.find(salesTerm => salesTerm.type === 'SUPPLIER').price,
                        },
                        customer: {
                            paymentStatus: service.customerPaymentStatus,
                            price: service.salesTerms.find(salesTerm => salesTerm.type === 'CLIENT').price,
                        },
                        violation: service.violation,
                        corporatePolicies: service.corporatePolicies || [],
                    }
                    orderServices.push(orderService)
                })
            })
            return orderServices
        }

        get headers() {
            const headers = [
                {text: this.$t('order'), value: 'id'},
                {text: this.$t('created_date'), value: 'created'},
                {text: this.$t('status'), value: 'status'},
                {text: this.$t('order_service'), value: 'title'},
                {text: this.$t('dates'), value: 'date', align: 'center'},
                {text: this.$t('order_travelers'), value: 'travellers'},
            ]
            if (authStore.isTourOperator || authStore.isAgency) {
                headers.push({text: this.$t(authStore.isAgency ? 'price' : 'netto'), value: 'supplierPrice'})
            }
            if (!authStore.isAgency) {
                headers.push({text: this.$t(authStore.isTourOperator ? 'brutto' : 'price'), value: 'price'})
            }
            if (this.isTO1) {
                headers.splice(2, 0, {text: this.$t('company') + ' / ' + this.$t('agent'), value: 'clientCompanyName'})
            }
            return headers
        }

        get ordersOnPage() {
            return CONFIG.account.ordersOnPage
        }

        get ordersCount() {
            return runtimeStore.ordersResponse.ordersCount
        }

        get isTO1() {
            return authStore.isTO1
        }

        get isTO2() {
            return authStore.isTO2
        }

        get isAgency() {
            return authStore.isAgency
        }

        async loadOrders(update = false) {
            const options = persistentStore.ordersTableOptions
            const rq = Object.assign(
                {
                    orderType: authStore.orderType,
                    responseType: 'SHORT',
                    limit: options.itemsPerPage,
                    offset: options.itemsPerPage * (options.page - 1),
                },
                this.filters
            )
            if (rq.orderId) {
                rq.orderId = parseInt(rq.orderId, 10)
            }
            if (rq.city) {
                rq.cityId = rq.city.id
                delete rq.city
            }
            if (rq.active === 'all') {
                delete rq.active
            }
            if (rq.paymentMethod && rq.paymentMethod.name) {
                rq.paymentMethod = rq.paymentMethod.name
            }
            const addTime = (rq, date, time) => {
                if (rq[date]) {
                    rq[date] += time
                }
            }
            addTime(rq, 'createdDateFrom', 'T00:00')
            addTime(rq, 'createdDateTo', 'T23:59')
            addTime(rq, 'serviceStartDateFrom', 'T00:00')
            addTime(rq, 'serviceStartDateTo', 'T23:59')
            addTime(rq, 'serviceEndDateFrom', 'T00:00')
            addTime(rq, 'serviceEndDateTo', 'T23:59')
            clean(rq)
            if (update) {
                await runtimeStore.loadOrders({rq, update})
            } else {
                goTo(0)
                this.loadDataError = !(await runtimeStore.loadOrders({rq}))
                this.showLoadDataStatusMessage()
                this.orderIds = []
            }
        }

        paymentStatusClass(status, paymentStatus) {
            switch (paymentStatus) {
                case 'PAID':
                case 'BILL':
                    return 'success--text'
                case 'partial_paid':
                case 'in_transit':
                case 'no_fully_bill':
                    return 'warning--text'
                case 'no_bill':
                case 'overdue':
                    return 'error--text'
                case 'pay_at_hotel':
                    return 'info--text'
                default:
                    return status === 'QUOTE' ? 'success--text' : 'warning--text'
            }
        }

        getServiceTypeIcon(serviceType) {
            switch (serviceType) {
                case 'ACCOMMODATION':
                    return 'mdi-office-building'
                case 'FLIGHT':
                    return 'mdi-airplane'
                case 'OWNCHARTER':
                    return 'mdi-airplane-takeoff'
                case 'CARRENT':
                    return 'mdi-car'
                case 'ACTIVITY':
                    return 'mdi-lightning-bolt-outline'
                case 'TRANSFER':
                    return 'mdi-bus'
                case 'EXTRASERVICE':
                case 'OWNEXTRASERVICE':
                    return 'mdi-domain-plus'
                case 'PACKAGE_TOUR':
                    return 'mdi-gift-outline'
                case 'DYNAMIC_PACKAGE':
                    return 'mdi-wallet-travel'
                case 'TRAIN':
                    return 'mdi-train'
                case 'INSURANCE':
                    return 'mdi-file-document-outline'
            }
        }

        getOrder(orderId) {
            return runtimeStore.ordersResponse.orders.find(order => order.orderId === parseInt(orderId, 10))
        }

        async saveOrderTitle(orderId) {
            this.orderTitleEdit[orderId] = false
            const data = {title: this.orderTitle[orderId]}
            try {
                await this.$api.orders.put(orderId, data)
                runtimeStore.UPDATE_ORDER({orderId, data})
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    div::v-deep {
        tr {
            .v-data-table__mobile-row:first-child {
                display: none !important ;
            }
        }
        @media #{map-get($display-breakpoints, 'xs-only')} {
            .v-row-group__header strong {
                &:before {
                    content: '#';
                }
            }
        }

        table {
            border-collapse: separate;
            border-spacing: 0 10px !important;
        }

        .v-input--selection-controls {
            margin-top: 0;
        }

        th {
            .v-input--selection-controls {
                padding-top: 0;
            }
        }

        .v-input .v-label {
            font-size: 0.75rem;
        }
    }

    .order-title-edit {
        font-size: 12px;
        margin: 0;
    }

    @media (max-width: 960px) {
        div::v-deep {
            tr {
                .v-data-table__mobile-row {
                    height: auto !important;
                }
            }
        }
    }

    .commission {
        color: grey;
        font-size: 0.65rem;
    }
</style>
