<template>
    <v-snackbar v-model="snackbar" :timeout="timeout" top :color="color">
        <div v-for="(notification, index) in notifications" :key="index">
            {{ notification }}
        </div>
        <template v-if="timeout === -1" v-slot:action="{attrs}">
            <v-icon v-bind="attrs" @click="snackbar = false">
                mdi-close
            </v-icon>
        </template>
    </v-snackbar>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {SHOW_NOTIFICATIONS, EventBus} from '@/utils/event-bus'

    @Component
    export default class NotificationsSnackbar extends Vue {
        snackbar = false
        notifications = []
        color = 'warning'

        created() {
            EventBus.$on(SHOW_NOTIFICATIONS, this.show)
        }

        beforeDestroy() {
            EventBus.$off(SHOW_NOTIFICATIONS, this.show)
        }

        show({notifications, color}) {
            this.color = color || 'warning'
            this.notifications = notifications
            this.snackbar = true
        }

        get timeout() {
            return this.color === 'error' ? 5000 : -1
        }
    }
</script>
