<template>
    <v-dialog v-model="modal" hide-overlay width="940" content-class="overflow--visible">
        <v-card v-if="reportParametersLoading" color="primary" dark>
            <v-card-text>
                {{ $t('loading') }}
                <v-progress-linear indeterminate color="white" class="mb-0" />
            </v-card-text>
        </v-card>
        <v-card v-else>
            <v-icon class="v-card-close" @click="modal = false">
                mdi-close
            </v-icon>
            <v-card-title class="title font-weight-light">
                {{ $tc(report.reportName, 2) }}
            </v-card-title>
            <v-card-text>
                <statement-of-account-report-parameters-form
                    v-model="reportParametersValid"
                    :_params.sync="reportParameters"
                />
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn
                    :loading="reportLoading['Excel']"
                    :disabled="reportLoading['Excel'] || !reportParametersValid"
                    class="primary"
                    @click="download('Excel')"
                >
                    Excel
                    <v-icon right dark>
                        mdi-file-excel
                    </v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import StatementOfAccountReportParametersForm from '@/components/account/reports/StatementOfAccountReportParametersForm'
    import {clone} from '@/utils/helpers'

    @Component({
        components: {StatementOfAccountReportParametersForm},
    })
    export default class StatementOfAccountReportModal extends Vue {
        modal = false
        report = {}
        reportParameters = {}
        reportParametersLoading = false
        reportParametersValid = true
        reportLoading = {
            Excel: false,
        }

        async loadReportParameters(report) {
            this.report = report
            this.reportParametersLoading = true
            this.modal = true
            this.reportParameters = {
                type: report.reportId === 'statement_of_account_clients' ? 'CLIENT' : 'SUPPLIER',
                transactionDateFrom: this.$dateFns.format(this.$dateFns.addMonths(new Date(), -1)),
                transactionDateTo: this.$dateFns.format(new Date()),
                transactionTypes: [],
                clientType: null,
                clientId: null,
                referenceNumber: null,
                supplierId: null,
                showZeroTransactions: false,
                fileType: 'EXCEL',
            }
            this.reportParametersLoading = false
        }

        async download(fileType) {
            this.reportLoading[fileType] = true
            try {
                await this.$api.downloadStatementOfAccount.get(
                    this.cleanEmptyParams(clone(this.reportParameters)),
                    this.$t('statement_of_account.title') + '.xlsx'
                )
            } catch (e) {
                this.$toast.error(this.$t('error_message.load_error'))
            } finally {
                this.reportLoading[fileType] = false
            }
        }

        cleanEmptyParams(obj) {
            for (let propName in obj) {
                if (obj[propName] === null || obj[propName] === undefined) {
                    delete obj[propName]
                }
            }
            return obj
        }
    }
</script>
