<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {toursRuntimeStore} from '@/utils/store-accessor'

    @Component
    export default class TourPageMixin extends Vue {
        async loadRuntimeData() {
            if (toursRuntimeStore.packageTourId !== this.packageTourId) {
                await toursRuntimeStore.loadPackageTourInfo(this.packageTourId)
            }
        }
    }
</script>
