<template>
    <span v-if="visible" class="caption text-no-nowrap text-center">
        {{ $t('supplier_price') }}:
        {{ supplierPrice | price }}
    </span>
</template>

<script>
    import {Vue, Component, Prop} from 'vue-property-decorator'
    import {authStore} from '~/store'

    @Component
    export default class SupplierPrice extends Vue {
        @Prop({required: true}) supplierPrice

        get visible() {
            return this.supplierPrice && authStore.companyType && authStore.companyType !== 'CORPORATE'
        }
    }
</script>

<style scoped>
    span.caption {
        color: rgba(0, 0, 0, 0.6);
    }
</style>
