<template>
    <div>
        <div class="font-weight-regular">
            {{ $t(title) }}
        </div>
        <div v-for="(orderPrice, index) in orderPrices" :key="index">
            <div class="info-text title text-no-wrap black--text font-weight-bold">
                {{ orderPrice.amount | priceFormat(orderPrice.currency) }}
            </div>
            <span v-if="typeof orderPrice.commission !== 'undefined'" class="commission-block">
                <span class="font-weight-regular"> {{ $t('commission') }}: </span>
                {{ orderPrice.commission | priceFormat(orderPrice.currency) }}
            </span>
        </div>
        <div :class="`${getPaymentColor(paymentStatus)}--text`" class="payment-status mt-1">
            {{ serviceCardGuarantee ? $t(`paymentStatus.PAY_AT_HOTEL`) : $t(`paymentStatus.${paymentStatus}`) }}
        </div>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'

    @Component
    export default class TotalOrderPrices extends Vue {
        @Prop({required: true}) title
        @Prop({required: true}) orderPrices
        @Prop({required: true}) paymentStatus
        @Prop({required: true}) serviceCardGuarantee

        getPaymentColor(status) {
            if (this.serviceCardGuarantee) {
                return 'info'
            }
            const failed = ['NOT_PAID', 'NO_BILL', 'OVERDUE', 'NO_SHOW']
            if (failed.includes(status)) {
                return 'error'
            }
            return 'success'
        }
    }
</script>

<style scoped>
    .payment-status {
        line-height: 1;
    }
</style>
