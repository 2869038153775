<template>
    <div>
        <breadcrumbs :text="name" class="pb-3" />
        <h1 v-text="title" />
        <div class="body-2 mt-4">
            <vue-markdown :source="content" />
        </div>
    </div>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import Breadcrumbs from '~/components/Breadcrumbs'
    import VueMarkdown from 'vue-markdown'
    import {CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'
    import {cmsStore} from '@/utils/store-accessor'
    import {clone} from '@/utils/helpers'

    //TODO SEO

    @Component({
        components: {
            Breadcrumbs,
            VueMarkdown,
        },
    })
    export default class InfoPage extends Vue {
        name
        title
        content
        seo = {}

        head() {
            const head = clone(cmsStore.layoutSettings.head)
            if (this.$config.host) {
                head.link = [
                    {
                        rel: 'canonical',
                        href: this.$config.host + this.$route.path,
                    },
                ]
            }
            if (this.seo.title) {
                head.titleTemplate = this.seo.title
            }
            this.seo.meta.forEach(meta => {
                const metaIndex = head.meta.findIndex(({hid}) => hid === meta.hid)
                if (metaIndex !== -1) {
                    this.$set(head.meta, metaIndex, meta)
                }
            })
            return head
        }

        async asyncData({$api, params: {link}, error}) {
            try {
                const {name, title, content, head: seo} = await $api.infoPage.get(link)
                return {name, title, content, seo}
            } catch (e) {
                error({statusCode: e.status, message: e.msg})
            }
        }

        async loadData() {
            try {
                const {name, title, content, head: seo} = await this.$api.infoPage.get(this.$route.params.link)
                this.name = name
                this.title = title
                this.content = content
                this.seo = seo
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        created() {
            EventBus.$on(CHANGE_LOCALE_EVENT, this.loadData)
        }

        beforeDestroy() {
            EventBus.$off(CHANGE_LOCALE_EVENT, this.loadData)
        }
    }
</script>
