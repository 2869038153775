<script>
    import {Vue, Component, Prop, Emit} from 'vue-property-decorator'
    import {filters} from '~/utils/flights/flights-blank-states'
    import {EventBus, CHANGE_TAB_MOBILE_EVENT} from '~/utils/event-bus'

    @Component
    export default class FiltersBase extends Vue {
        @Prop({required: true, default: () => filters()}) filters
        @Prop({required: true, default: () => filters()}) filterValues
        @Prop({required: true, default: 0}) filteredOffersCount
        @Prop({required: true}) currency

        @Emit()
        reset() {}

        @Emit()
        change({key, value}) {
            return {key, value}
        }

        async clearFilter({key, value}) {
            const index = this.filterValues[key].indexOf(value)
            if (index !== -1) {
                const value = this.filterValues[key].slice()
                value.splice(index, 1)
                this.change({key, value})
            }
        }

        goToOffers() {
            EventBus.$emit(CHANGE_TAB_MOBILE_EVENT, 1)
        }
    }
</script>

<style lang="scss" scoped>
    .sticky-button {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        left: 0;
    }
</style>
