<template>
    <div class="d-flex flex-row" :class="!$breakpoint.smAndDown ? alignment : ''">
        <order-price
            v-if="isTourOperator || isAgency"
            :title="isAgency ? 'price' : 'supplier_price'"
            :price="supplierPrice"
            :payment-status="supplierPaymentStatus"
            :cancel-penalties="supplierCancelPenalties"
            :show-commission="true"
            class="mr-2"
            :service-card-guarantee="serviceCardGuarantee"
        />
        <order-price
            v-if="!isAgency"
            :title="isTourOperator ? 'client_price' : null"
            :price="clientPrice"
            :payment-status="customerPaymentStatus"
            :cancel-penalties="clientCancelPenalties"
            :show-commission="isTourOperator"
            :loyalty-points="loyaltyPoints"
            :service-card-guarantee="serviceCardGuarantee"
        >
            <slot name="violation" />
        </order-price>
    </div>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import {authStore} from '@/utils/store-accessor'
    import OrderPrice from '@/components/account/snippets/OrderPrice'

    @Component({
        components: {
            OrderPrice,
            CorporatePolicyViolation: () => import('~/components/snippets/CorporatePolicyViolation'),
        },
    })
    export default class OrderPrices extends Vue {
        @Prop() supplierPrice
        @Prop() supplierPaymentStatus
        @Prop() supplierCancelPenalties
        @Prop() clientPrice
        @Prop() customerPaymentStatus
        @Prop() clientCancelPenalties
        @Prop() serviceCardGuarantee
        @Prop() loyaltyPoints
        @Prop({default: 'justify-space-around'}) alignment

        get isTourOperator() {
            return authStore.isTourOperator
        }

        get isAgency() {
            return authStore.isAgency
        }

        getPaymentColor(status) {
            const failed = ['NOT_PAID', 'NO_BILL', 'OVERDUE', 'NO_SHOW']
            if (failed.includes(status)) {
                return 'error'
            }
            return 'success'
        }
    }
</script>
