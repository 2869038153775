<template>
    <v-card class="my-2" :elevation="!fullCard ? 0 : undefined" :outlined="fullCard">
        <v-list-item three-line>
            <v-list-item-content class="align-self-start" :class="{'pl-2': !fullCard}">
                <v-list-item-title class="wrap-words">
                    <v-icon small left class="info--text" @click="offerInfo()">
                        mdi-information-outline
                    </v-icon>
                    <span class="caption">
                        {{ groupedBy === 'serviceName' ? $options.filters.dateFormat(offer.date) : offer.serviceName }}
                    </span>
                    <div v-if="!groupedBy">
                        <span v-if="offer.available" class="d-block mt-1 caption">
                            <v-icon :title="$t('available')" left small class="success--text">
                                mdi-checkbox-marked-circle
                            </v-icon>
                            <span class="success--text" v-text="$t('available')" />
                        </span>
                        <span v-else class="d-block mt-1 caption">
                            <v-icon :title="$t('on_request')" small class="warning--text">
                                mdi-alert-circle
                            </v-icon>
                            <span class="warning--text" v-text="$t('on_request')" />
                        </span>
                    </div>
                    <discount-label :price="offer.price" class="d-inline-block" />
                </v-list-item-title>
                <p v-if="groupedBy !== 'serviceName'" class="mt-1 caption">
                    <v-icon small left>
                        mdi-calendar
                    </v-icon>
                    {{ $options.filters.dateFormat(offer.date) }}
                </p>
                <span class="caption">
                    <conditions-label
                        v-if="offer"
                        :offer-key="offer.offerKey"
                        :price="offer.price"
                        @conditions="showConditionsInfo"
                    />
                </span>
            </v-list-item-content>
            <v-list-item-action class="offer-price align-self-center">
                <v-btn
                    nuxt
                    color="primary"
                    small
                    no-prefetch
                    block
                    height="36px"
                    max-height="36px"
                    :loading="!info.name"
                    :disabled="!info.name"
                    @click="selectForBooking"
                >
                    {{ offer.price | price }}
                </v-btn>
                <price-note :search-request="searchRequest" />
                <commission-amount :commission="offer.price.commission" />
                <supplier-price :supplier-price="offer.supplierPrice" />
            </v-list-item-action>
        </v-list-item>
    </v-card>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import ActivityInfoModal from '@/components/activities/info/ActivityInfoModal'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import ConditionsLabel from '~src/components/snippets/conditionsLabel.src'
    import {EventBus, SHOW_ACTIVITIES_INFO_MODAL, SHOW_ACTIVITY_CONDITIONS_INFO} from '@/utils/event-bus'
    import {activitiesStore} from '~/store'
    import PriceNote from '@/components/snippets/PriceNote'

    @Component({
        components: {
            PriceNote,
            CommissionAmount,
            DiscountLabel,
            ActivityInfoModal,
            ConditionsLabel,
            SupplierPrice: () => import('~/components/snippets/SupplierPrice'),
        },
    })
    export default class ActivitiesOfferCard extends Vue {
        @Prop() offer
        @Prop({default: true}) fullCard
        @Prop() groupedBy

        imgError = false
        info = {
            images: [],
        }

        async mounted() {
            try {
                this.info = await this.$api.activityInfo.get({offerKey: this.offer.offerKey})
                if (this.fullCard) {
                    this.$emit('info', this.info)
                }
                if (this.fullCard && this.info.images) {
                    this.$emit('images', this.info.images)
                }
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        onImgError() {
            if (this.mainImage.url) {
                this.imgError = true
            }
        }

        selectForBooking() {
            activitiesStore.ADD_TO_BASKET({info: this.info, offerKey: this.offer.offerKey})
            this.$router.push({
                name: 'activityBooking',
                query: {offerKey: this.offer.offerKey},
            })
        }

        offerInfo() {
            EventBus.$emit(SHOW_ACTIVITIES_INFO_MODAL, {offer: this.offer, info: this.info})
        }

        showConditionsInfo() {
            EventBus.$emit(SHOW_ACTIVITY_CONDITIONS_INFO, this.offer)
        }

        get mainImage() {
            let mainImage = this.info.images.find(image => image.mainImage) || {url: null}
            if (!mainImage.url && this.info.images.length > 0) {
                mainImage = this.info.images[0]
            }
            return mainImage
        }

        get isGroupedBy() {
            return !!this.groupedBy
        }

        get searchRequest() {
            return activitiesStore.searchRequest
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .offer-price {
        width: 20%;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
        .offer-price {
            width: 25%;
        }
    }

    .wrap-words {
        white-space: normal;
    }
</style>
