<template>
    <v-card class="my-2" :elevation="!fullCard ? 0 : undefined" :outlined="fullCard">
        <v-list-item three-line :class="fullCard ? 'pl-0' : ''">
            <v-list-item-avatar v-if="fullCard" class="my-0" :size="!$breakpoint.smAndDown ? '125px' : '25%'" tile>
                <v-img
                    tile
                    class="thumb"
                    :src="mainImage.url | imageUrl(200)"
                    :alt="info.serviceName"
                    lazy-src="/placeholder.png"
                    @error="onImgError"
                >
                    <template v-slot:placeholder>
                        <v-row
                            v-show="!imgError && mainImage.url"
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row>
                    </template>
                </v-img>
            </v-list-item-avatar>
            <v-list-item-content class="align-self-start" :class="{'pl-2': !fullCard}">
                <v-list-item-title class="wrap-words">
                    <span class="caption">
                        <strong>
                            {{ info.serviceName }}
                        </strong>
                        ({{ offer.info.tariff }})
                    </span>
                    <v-icon small class="info--text" @click="offerInfo()">
                        mdi-information-outline
                    </v-icon>
                    <div>
                        <span v-if="offer.available" class="d-block mt-1 caption">
                            <v-icon :title="$t('available')" small class="success--text">
                                mdi-checkbox-marked-circle
                            </v-icon>
                            <span class="success--text" v-text="$t('available')" />
                        </span>
                        <span v-else class="d-block mt-1 caption">
                            <v-icon :title="$t('on_request')" small class="warning--text">
                                mdi-alert-circle
                            </v-icon>
                            <span class="warning--text" v-text="$t('on_request')" />
                        </span>
                    </div>
                    <discount-label :price="offer.price" class="d-inline-block" />
                </v-list-item-title>
                <p class="mt-1 caption">
                    {{ $options.filters.dateFormat(offer.startDate) }}
                </p>
                <span class="caption mt-1">
                    <conditions-label
                        v-if="offer"
                        :offer-key="offer.offerKey"
                        :price="offer.price"
                        @conditions="showConditionsInfo"
                    />
                </span>
            </v-list-item-content>
            <v-list-item-action class="offer-price align-self-center">
                <v-btn
                    nuxt
                    color="primary"
                    small
                    no-prefetch
                    block
                    height="36px"
                    max-height="36px"
                    :loading="!info.productName"
                    :disabled="!info.productName"
                    @click="selectForBooking"
                >
                    {{ offer.price | price }}
                </v-btn>
                <commission-amount :commission="offer.price.commission" />
                <supplier-price :supplier-price="offer.supplierPrice" />
            </v-list-item-action>
        </v-list-item>
    </v-card>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import CommissionAmount from '@/components/snippets/CommissionAmount'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import ConditionsLabel from '~src/components/snippets/conditionsLabel.src'
    import {EventBus, SHOW_EXTRA_SERVICE_INFO_MODAL, SHOW_EXTRA_SERVICE_CONDITIONS_INFO} from '@/utils/event-bus'
    import {extraServicesStore} from '~/store'

    @Component({
        components: {
            CommissionAmount,
            DiscountLabel,
            ConditionsLabel,
            SupplierPrice: () => import('~/components/snippets/SupplierPrice'),
        },
    })
    export default class ExtraServiceOfferCard extends Vue {
        @Prop() offer
        @Prop({default: true}) fullCard
        @Prop({default: ''}) description

        imgError = false
        info = {
            images: [],
        }

        async mounted() {
            try {
                this.info = await this.$api.ownExtraServiceInfo.get({offerKey: this.offer.offerKey})
                if (this.fullCard) {
                    this.$emit('description', this.info.productDescription)
                }
                // eslint-disable-next-line no-empty
            } catch (e) {}
            this.info.description = this.description
            this.info.service = this.offer.info.service
            this.info.tariff = this.offer.info.tariff
        }

        onImgError() {
            if (this.mainImage.url) {
                this.imgError = true
            }
        }

        selectForBooking() {
            extraServicesStore.ADD_TO_BASKET({info: this.info, offerKey: this.offer.offerKey})
            this.$router.push({
                name: 'extraServiceBooking',
                query: {offerKey: this.offer.offerKey},
            })
        }

        offerInfo() {
            EventBus.$emit(SHOW_EXTRA_SERVICE_INFO_MODAL, {offer: this.offer, info: this.info})
        }

        showConditionsInfo() {
            EventBus.$emit(SHOW_EXTRA_SERVICE_CONDITIONS_INFO, this.offer)
        }

        get mainImage() {
            let mainImage = this.info.images?.find(image => image.mainImage) || {url: null}
            if (!mainImage.url && this.info.images?.length > 0) {
                mainImage = this.info.images[0]
            }
            if (
                !mainImage.linkedEntityName ||
                (mainImage.linkedEntityName && mainImage.linkedEntityName !== this.info.serviceName)
            ) {
                mainImage =
                    this.info.images?.find(image => image.linkedEntityName === this.info.serviceName) || mainImage
            }
            return mainImage
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .offer-price {
        width: 20%;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
        .offer-price {
            width: 25%;
        }
    }

    .wrap-words {
        white-space: normal;
    }
</style>
