<script>
    import {Component, Vue} from 'nuxt-property-decorator'
    import {authStore, persistentStore} from '~/store'

    @Component
    export default class ConfirmationPageMixin extends Vue {
        company = {
            phones: [],
        }

        async asyncData({$api}) {
            try {
                const rs = await $api.companies.get({relationship: this.relationship})
                return {company: rs.object[0]}
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }

        mounted() {
            persistentStore.SET_TOURISTS([])
            const person = authStore.person
            if (person && person.memberLevel !== 0 && person.memberLevel !== 'GUEST') {
                this.setUser(person)
            }
        }

        setUser(person) {
            const {prefix, firstName, lastName, email, contactPhone, citizenshipId, personId, birthdate} = person
            const user = authStore.user
            const tourist = {
                prefix,
                firstName,
                lastName,
                citizenshipId,
                email,
                phone: contactPhone,
                birthdate,
            }
            if (user.isCorporate) {
                tourist.personId = personId
            }
            if (user.isEmployee && Object.keys(user.passport).length) {
                tourist.passport = user.passport
            }
            persistentStore.SET_TOURIST({
                val: Object.assign({}, tourist),
                index: 0,
            })
        }

        get relationship() {
            return authStore.isCorporate || authStore.isTO2 || authStore.isAgency ? 'SUPPLIER' : 'OWN'
        }
    }
</script>
