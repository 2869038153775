var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.segments.length)?_c('v-form',{ref:"form"},[_c('h4',{staticClass:"text-subtitle-1 font-weight-bold text-capitalize"},[_vm._v("\n        "+_vm._s(_vm.$t('flights.extra_baggage'))+"\n    ")]),_vm._v(" "),_c('v-expansion-panels',{staticClass:"mb-5",attrs:{"accordion":"","disabled":_vm.disabled,"flat":"","focusable":""}},_vm._l((_vm.segments),function(segment){return _c('v-expansion-panel',{key:segment.rph},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('div',[_vm._v("\n                    "+_vm._s(segment.departure.airportDescription.airportName)+",\n                    "+_vm._s(segment.departure.airportDescription.cityName)+"\n                    ("+_vm._s(segment.departure.airportCode)+") - "+_vm._s(segment.arrival.airportDescription.airportName)+",\n                    "+_vm._s(segment.arrival.airportDescription.cityName)+"\n                    ("+_vm._s(segment.arrival.airportCode)+")\n                ")]),_vm._v(" "),_c('div',{staticClass:"d-flex justify-end mr-5"},[(_vm.segmentTotalPrice(segment.rph).amount)?_c('v-chip',{attrs:{"small":"","label":"","color":"info"}},[_vm._v("\n                        +"+_vm._s(_vm._f("price")(_vm.segmentTotalPrice(segment.rph)))+"\n                    ")]):(open)?_c('span',[_vm._v("\n                        "+_vm._s(_vm.$t('flights.select_extra_baggage'))+"\n                    ")]):_vm._e()],1)]}}],null,true)}),_vm._v(" "),_c('v-expansion-panel-content',_vm._l((_vm.extraBaggageOptions(segment.rph)),function(extraBaggageOption){return _c('v-row',{key:extraBaggageOption.serviceRPH,attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-checkbox',{attrs:{"label":extraBaggageOption.name,"input-value":!!_vm.selectedExtraBaggage(extraBaggageOption.serviceRPH),"disabled":_vm.disabled},on:{"change":function($event){return _vm.toggleExtraBaggage(extraBaggageOption.serviceRPH)}}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{staticClass:"price",attrs:{"value":_vm._f("price")(extraBaggageOption.price),"label":_vm.$t('price'),"disabled":""}})],1),_vm._v(" "),(_vm.selectedExtraBaggage(extraBaggageOption.serviceRPH))?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"value":_vm.selectedExtraBaggage(extraBaggageOption.serviceRPH)
                                    ? _vm.selectedExtraBaggage(extraBaggageOption.serviceRPH).quantity
                                    : 0,"type":"number","label":_vm.$t('quantity'),"min":1,"max":_vm.selectedExtraBaggage(extraBaggageOption.serviceRPH).limit || 5,"rules":[
                                function (v) { return (!_vm.selectedExtraBaggage(extraBaggageOption.serviceRPH).limit &&
                                        v <= 5 &&
                                        v > 0) ||
                                    (v <= _vm.selectedExtraBaggage(extraBaggageOption.serviceRPH).limit && v > 0); } ]},on:{"input":function($event){return _vm.changQuantity(extraBaggageOption.serviceRPH, $event)}}})],1):_vm._e(),_vm._v(" "),(_vm.selectedExtraBaggage(extraBaggageOption.serviceRPH))?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{staticClass:"price",attrs:{"value":_vm._f("price")(_vm.selectedExtraBaggageTotal(extraBaggageOption)),"label":_vm.$t('total'),"disabled":""}})],1):_vm._e(),_vm._v(" "),(_vm.segmentsCount(extraBaggageOption.serviceRPH) > 1)?_c('v-col',{staticClass:"caption d-flex align-center",attrs:{"cols":"12","md":"2"}},[_vm._v("\n                        *"+_vm._s(_vm.$t('flights.for_multiple_flights'))+"\n                    ")]):_vm._e()],1)}),1)],1)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }