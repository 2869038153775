<template>
    <v-dialog v-model="isOpen" max-width="600px">
        <template v-slot:activator="{on}">
            <v-btn text small color="info" :on="on" @click.stop="isOpen = true">
                {{ $t('flights.fare_rules') }}
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="d-flex justify-lg-space-between">
                <span>
                    {{ $t('flights.fare_rules') }}
                </span>
                <v-icon @click="isOpen = false">
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-card-text v-if="rules">
                <div v-for="rule in rules" :key="rule.name" class="mb-4">
                    <div class="subtitle-1 black--text">
                        {{ rule.name }}
                    </div>
                    <div v-html="rule.text" />
                </div>
            </v-card-text>
            <v-card-text v-else style="position: relative; min-height: 100px;">
                <flight-loader :is-loading="loading" />
                <p v-if="!loading" class="mb-0">
                    {{ $t('empty_fare_rules_message') }}
                </p>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator'
    import FlightLoader from '@/components/flights/snippets/FlightLoader'

    @Component({
        components: {
            FlightLoader,
        },
    })
    export default class FareRules extends Vue {
        @Prop() offerKey
        @Prop() processId

        isOpen = false
        loading = false
        rules = null

        @Watch('isOpen')
        onOpen(open) {
            if (open && !this.rules) {
                this.getFareRules()
            }
        }

        async getFareRules() {
            try {
                this.loading = true
                const rq = {}
                if (this.offerKey) {
                    rq.offerKey = this.offerKey
                }
                if (this.processId) {
                    rq.processId = this.processId
                }
                const rules = await this.$api.flightFares.get(rq)
                if (rules.flightRules[0].paragraphs) {
                    this.rules = rules.flightRules[0].paragraphs
                } else if (rules.flightRules[0].brandedFareName && rules.flightRules[0].brandedFareDescription) {
                    this.rules = [
                        {
                            name: rules.flightRules[0].brandedFareName,
                            text: rules.flightRules[0].brandedFareDescription,
                        },
                    ]
                }
                this.loading = false
            } catch (error) {
                this.rules = [
                    {
                        name: this.$t('flights.no_rules'),
                        text: '',
                    },
                ]
                this.loading = false
            }
        }
    }
</script>
