<template>
    <v-row no-gutters :class="{'mb-4': !minify}">
        <v-col v-if="!minify" cols="12" md="2" class="d-flex align-center flex-column justify-center justify-md-start">
            <v-img
                v-for="airline in airlines"
                :key="airline"
                :src="`${gpBaseUrl}/stat/images/airlines/${airline}.gif`"
                :max-height="50"
                contain
            />
        </v-col>
        <v-col cols="12" :md="minify ? 12 : 10">
            <v-row :no-gutters="minify" dense>
                <flight-itinerary-point direction="departure" :point="departure" :size="minify ? 4 : 4" />
                <v-col :cols="minify ? 4 : 4" class="d-flex flex-column" :class="{'px-1': minify}">
                    <v-row no-gutters>
                        <v-col v-if="itinerary.duration" cols="12" class="mb-1 text-center" align-self="end">
                            {{ itinerary.duration | durationFormat }}
                        </v-col>
                        <v-col cols="12" class="mb-1 d-flex align-center">
                            <v-icon class="body-2 left">
                                mdi-airplane-takeoff
                            </v-icon>
                            <div class="line">
                                <div v-if="hasStops" class="stops-container">
                                    <span
                                        v-for="(stop, index) in stops"
                                        :key="`stop-${index}`"
                                        :class="{'mr-2': stops.length !== index + 1}"
                                    />
                                </div>
                            </div>
                            <v-icon class="body-2" right>
                                mdi-airplane-landing
                            </v-icon>
                        </v-col>
                        <v-col v-if="hasStops" cols="12" class="mb-1 d-flex flex-column align-center">
                            <div class="red--text">
                                {{ $tc('filters_stops', stops.length) }}
                            </div>
                            <div>
                                <v-tooltip v-for="stop in stops" :key="`stops-${stop.airportCode}`" bottom>
                                    <template v-slot:activator="{on}">
                                        <div class="caption" v-on="on">
                                            {{ stop.airportCode }}
                                        </div>
                                    </template>
                                    <span>
                                        <v-icon left class="white--text">mdi-airplane</v-icon>
                                        {{ getAirportName(stop) }}
                                    </span>
                                </v-tooltip>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <flight-itinerary-point :point="arrival" direction="arrival" :size="minify ? 4 : 4" />
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import FlightItineraryPoint from '@/components/flights/snippets/FlightItineraryPoint'
    import {getOfferAirportName} from '@/utils/helpers'
    import {runtimeStore} from '@/store'

    @Component({
        components: {
            FlightItineraryPoint,
        },
    })
    export default class FlightItinerary extends Vue {
        @Prop({required: true}) itinerary
        @Prop({default: false}) minify

        get departure() {
            return this.itinerary.segments[0].departure
        }

        getAirportName(data) {
            if (data.airportCode) {
                return getOfferAirportName(data)
            }
            return ''
        }

        get arrival() {
            return this.itinerary.segments[this.itinerary.segments.length - 1].arrival
        }

        get airlines() {
            const airlines = this.itinerary.segments.map(({marketingAirline}) => marketingAirline)
            return Array.from(new Set(airlines))
        }

        get hasStops() {
            return this.itinerary.segments.length > 1
        }
        get stops() {
            const stops = this.itinerary.segments.map(({arrival}) => arrival)
            stops.pop()
            return stops
        }

        get gpBaseUrl() {
            return runtimeStore.gpBaseUrl
        }
    }
</script>

<style lang="scss" scoped>
    .line {
        width: 90%;
        border-top: 2px solid black;
        position: relative;

        & .stops-container {
            position: absolute;
            left: 50%;
            margin-top: -7px;
            transform: translateX(-50%);
            display: flex;
            justify-content: space-around;

            & span {
                border-radius: 50%;
                width: 12px;
                height: 12px;
                display: flex;
                background: red;
                border: 2px solid white;
            }
        }
    }
</style>
