<script>
    import {Component, Prop, mixins} from 'nuxt-property-decorator'
    import BookingPageBase from '~src/components/parts/bookingPageBase.src'
    import HotelStoreMixin from '@/mixins/HotelStoreMixin'
    import HotelCorporatePolicyViolationMixin from '@/mixins/HotelCorporatePolicyViolationMixin'
    import {authStore, hotelsRuntimeStore, hotelsStore, persistentStore} from '@/utils/store-accessor'
    import {clone} from '@/utils/helpers'

    @Component
    export default class HotelBookingPageLayout extends mixins(
        BookingPageBase,
        HotelStoreMixin,
        HotelCorporatePolicyViolationMixin
    ) {
        @Prop({default: () => []}) offerKeys

        get tourLead() {
            return hotelsStore.tourists[0] ? hotelsStore.tourists[0][0] : undefined
        }

        set tourLead(val) {
            hotelsStore.SET_TOURIST({val, index: 0, roomIndex: 0})
        }

        setTourists() {
            let tourists = clone(hotelsStore.tourists)
            const searchRequestTourists = hotelsRuntimeStore.searchRequestTouristsJSON(this.searchRequest)
            searchRequestTourists.forEach((room, roomIndex) => {
                if (!tourists[roomIndex]) {
                    tourists[roomIndex] = []
                }
                tourists[roomIndex] = this.initTourists(room, tourists[roomIndex], roomIndex)
            })
            hotelsStore.SET_TOURISTS(tourists.slice(0, searchRequestTourists.length))
        }

        clearCommentsAfterBooking() {
            const allTourists = this.productStore.tourists.length
            for (let i = 0; i < allTourists; i++) {
                this.productStore.SET_PREPARE_BOOK_REQUEST_PROP({prop: 'comments', val: '', index: i})
            }
        }

        async sendPrepareBookRequest(prepareBookRequest) {
            try {
                const prepareBookResponse = await this.productStore.prepareBook(prepareBookRequest)
                return prepareBookResponse.prepareBookResponses.reduce(
                    (success, prepareBookResponse, index) =>
                        success &&
                        this.prepareBookResponseProcessing(prepareBookResponse, prepareBookRequest[index].offerKey),
                    true
                )
            } catch (e) {
                return this.prepareBookErrorProcessing(e)
            }
        }

        prepareBookRequest() {
            const addCheckInOut = roomIndex => {
                const sourceExtraService = this.productStore.checkInOutExtraService[roomIndex]
                const {earlyCheckIn, lateCheckOut} = {...sourceExtraService}
                return {
                    ...(lateCheckOut && {
                        lateCheckOut: {
                            serviceRPH: lateCheckOut.serviceRPH,
                            time: lateCheckOut.time,
                            required: false,
                        },
                    }),
                    ...(earlyCheckIn && {
                        earlyCheckIn: {
                            serviceRPH: earlyCheckIn.serviceRPH,
                            time: earlyCheckIn.time,
                            required: false,
                        },
                    }),
                }
            }
            return this.tourists.map((tourists, roomIndex) => {
                const resultCheckInOutService = addCheckInOut(roomIndex)
                const rq = {
                    tourists: this.prepareBookTourists(tourists),
                    offerKey: this.offerKeys[roomIndex],
                    ...resultCheckInOutService,
                }
                if (authStore.isB2C) {
                    const person = authStore.person || authStore.registeredPerson
                    rq.personId = person.personId
                } else if (this.selectedPersonId) {
                    rq.personId = this.selectedPersonId
                }
                if (persistentStore.selectedOrderId) {
                    rq.orderId = persistentStore.selectedOrderId
                }
                if (hotelsStore.prepareBookRequest[roomIndex] && hotelsStore.prepareBookRequest[roomIndex].comments) {
                    rq.comments = hotelsStore.prepareBookRequest[roomIndex].comments
                }
                if (hotelsStore.bookingExtraServices) {
                    rq.extraServices = []
                    hotelsStore.bookingExtraServices.map(extraService => {
                        if (!extraService.isMealType && extraService.roomIndex == roomIndex) {
                            rq.extraServices.push({
                                serviceRPH: extraService.serviceRPH,
                                quantity: extraService.quantity,
                            })
                        }
                    })
                }
                return rq
            })
        }

        productBookingPageQuery(paymentStatus) {
            return {
                paymentStatus,
                offerKey: this.offerKeys,
            }
        }

        get tourists() {
            return hotelsStore.tourists
        }

        get productSearchPageName() {
            return 'hotels'
        }

        get productConfirmationPageName() {
            return 'hotelConfirmation'
        }

        get productName() {
            return 'hotel'
        }

        get productBookingPageName() {
            return 'hotelBooking'
        }

        get corporatePolicyType() {
            return 'accommodation'
        }
    }
</script>
