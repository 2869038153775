<template>
    <div class="errorContainer text-center">
        <div class="text-h3 grey--text">
            {{ error.statusCode }}
        </div>
        <template v-if="error.statusCode === 404">
            <div class="text-h4 grey--text text--lighten-1 mb-5">
                {{ $t('error_message.page_not_found') }}
            </div>
            <v-btn color="primary" @click="$router.go(-1)">{{ $t('go_back') }}</v-btn>
        </template>
        <template v-else>
            <div class="text-h4 grey--text text--lighten-1">
                {{ error.message }}
            </div>
            <div v-if="error.statusCode === 500" class="text-subtitle-1 grey--text text--lighten-1 mb-5">
                {{ $t('warning_message.service_unavailable_note') }}
            </div>
        </template>
    </div>
</template>

<script>
    import {Vue, Component, Prop} from 'nuxt-property-decorator'
    import HeaderBar from '@/components/HeaderBar'
    import FooterBar from '@/components/FooterBar'

    @Component({
        components: {FooterBar, HeaderBar},
        layout: 'centered',
    })
    export default class ErrorLayout extends Vue {
        @Prop() error
    }
</script>

<style lang="scss" scoped>
    .errorContainer {
        width: 100%;
    }
</style>
