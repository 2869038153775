<template>
    <div v-intersect.once="onIntersect" class="mt-5 mb-5">
        <div ref="map" class="map" />
        <div v-if="distance">
            <strong>{{ $t('distance') }}:</strong>
            {{ distance }} {{ $t('km_from_center') }}
        </div>
        <div v-if="district">
            <strong>{{ $t('district') }}:</strong>
            {{ district }}
        </div>
    </div>
</template>

<script>
    import {mixins, Component, Watch, Prop} from 'nuxt-property-decorator'
    import {hotelsRuntimeStore, hotelsStore} from '~/store'
    import gmapsInit from '~/utils/gmaps'
    import HotelMapMixin from '~src/components/hotels/mixins/hotelMapMixin.src'

    @Component
    export default class HotelInfoMap extends mixins(HotelMapMixin) {
        @Prop({default: null}) city

        google = null
        map = null
        markers = []

        @Watch('city')
        changeCity() {
            if (!this.map) return
            this.map.setCenter({lat: this.city.latitude, lng: this.city.longitude})
        }

        async mounted() {
            await this.$store.restored
            await this.load()
        }

        async load() {
            try {
                this.google = await gmapsInit()
                this.map = new this.google.maps.Map(this.$refs.map, {
                    disableDefaultUI: true,
                    gestureHandling: 'greedy',
                    //mapTypeControl: true,
                    //zoomControl: true,
                    fullscreenControl: true,
                    zoom: 8,
                })
                this.addMarker(this.info)
                if (this.markers.length) {
                    this.map.setZoom(16)
                    this.map.setCenter(this.markers[0].position)
                } else if (this.city) {
                    this.map.setCenter({lat: this.city.latitude, lng: this.city.longitude})
                }
            } catch (error) {
                console.error(error)
            }
        }

        addMarker(info) {
            let position
            if (info.latitude && info.longitude) {
                position = {
                    lat: info.latitude,
                    lng: info.longitude,
                }
            } else {
                return
                /*this.geocoder.geocode({address: offer.address}, (results, status) => {
                    if (status !== 'OK' || !results[0]) {
                        throw new Error(status);
                    }
                    this.addMarker(results[0].geometry.location);
                });*/
            }
            this.markers.push(
                new this.google.maps.Marker({
                    position,
                    map: this.map,
                    icon: this.createMarkerIcon(info),
                    data: info,
                })
            )
        }

        onIntersect() {}

        get info() {
            return {
                ...hotelsRuntimeStore.hotelInfoResponse.hotelDescriptionInfo,
                supplierCode: hotelsRuntimeStore.hotelInfoRequest.supplierCode,
                ...(this.$route.query.own && {own: true}),
            }
        }

        get offer() {
            return hotelsStore.singleHotelSearchResponse.offers.length
                ? hotelsStore.singleHotelSearchResponse.offers[0]
                : null
        }

        get distance() {
            return this.info.distanceFromCenter
        }

        get district() {
            return this.offer ? this.offer.district : null
        }
    }
</script>

<style scoped lang="scss">
    .map {
        height: 200px;
        z-index: 1;
    }
</style>
