<template>
    <v-list v-if="offer" dense>
        <hotel-grouped-room-offers
            :rooms="filterRoomOffers(offer.rooms)"
            :offer="offer"
            :full-card="fullCard"
            :_selected-offer-keys.sync="selectedOfferKeys"
            :_expanded.sync="expanded"
            :_can-book="canBook"
            :search-request="searchRequest"
            @groupSelected="selectedGroupBy = $event"
            @sortSelected="selectedSortBy = $event"
            @more-rooms="$emit('more-rooms', $event)"
        >
            <template v-slot:price="{room}">
                <slot name="price" :room="room" />
            </template>
        </hotel-grouped-room-offers>
        <template v-if="selectedGroupBy === 'none'">
            <div v-for="roomGroupIndex in searchRoomsCount" :key="roomGroupIndex">
                <v-card-title v-if="searchRoomsCount > 1" class="title font-weight-light">
                    {{ $tc('room', 1) }} {{ roomGroupIndex }}
                </v-card-title>
                <template
                    v-for="(room, roomIndex) in findGroupInRoomOffers(roomGroupIndex).slice(
                        0,
                        expanded[roomGroupIndex]
                    )"
                >
                    <div :key="room.groupedOffers[0].offerKey + '_0'" :class="roomGroupClass(room)">
                        <v-list-item tree-line>
                            <v-list-item-content>
                                <v-list-item-title class="wrap-words">
                                    <v-checkbox
                                        v-if="compareMode"
                                        v-model="selectedRooms"
                                        dense
                                        :ripple="false"
                                        hide-details
                                        :value="room.groupedOffers[0].offerKey"
                                    >
                                        <template v-slot:label>
                                            <v-icon
                                                left
                                                small
                                                class="info--text"
                                                @click="roomInfo(offer, room.groupedOffers[0].offerKey)"
                                            >
                                                mdi-information-outline
                                            </v-icon>
                                            <span class="body-2" @click.stop> {{ room.name }} </span>
                                        </template>
                                    </v-checkbox>
                                    <span v-else class="body-2" @click.stop>
                                        <v-icon
                                            small
                                            left
                                            class="info--text"
                                            @click="roomInfo(offer, room.groupedOffers[0].offerKey)"
                                        >
                                            mdi-information-outline
                                        </v-icon>
                                        {{ room.name }}
                                    </span>
                                    <v-row no-gutters :class="{caption: $breakpoint.smAndDown}" class="mb-3 mt-1">
                                        <v-col
                                            v-if="showRoomImage && findRoomImage(room)"
                                            cols="12"
                                            sm="2"
                                            class="mb-2 mb-sm-0"
                                        >
                                            <v-img
                                                tile
                                                class="thumb"
                                                :src="findRoomImage(room) | imageUrl(600)"
                                                :alt="room.name"
                                                :eager="true"
                                                lazy-src="/placeholder.png"
                                            />
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            :sm="showRoomImage && findRoomImage(room) ? 6 : 8"
                                            :class="showRoomImage && findRoomImage(room) ? 'pl-sm-3' : ''"
                                        >
                                            <v-row no-gutters class="caption">
                                                <v-col cols="12">
                                                    <v-icon
                                                        v-if="room.available"
                                                        :title="$t('available')"
                                                        small
                                                        left
                                                        class="success--text"
                                                    >
                                                        mdi-checkbox-marked-circle
                                                    </v-icon>
                                                    <v-icon
                                                        v-else
                                                        :title="$t('on_request')"
                                                        small
                                                        left
                                                        class="warning--text"
                                                    >
                                                        mdi-alert-circle
                                                    </v-icon>
                                                    <span
                                                        v-if="room.availableRooms && room.availableRooms === 1"
                                                        class="warning--text"
                                                        v-text="$t('available_one_room')"
                                                    />
                                                    <span
                                                        v-else-if="room.availableRooms && room.availableRooms < 5"
                                                        class="warning--text"
                                                        v-text="$tc('available_rooms', room.availableRooms)"
                                                    />
                                                    <span
                                                        v-else-if="room.availableRooms"
                                                        class="success--text"
                                                        v-text="$t('enough_available_rooms')"
                                                    />
                                                    <span
                                                        v-else-if="!room.available"
                                                        class="warning--text"
                                                        v-text="$t('on_request')"
                                                    />
                                                    <span v-else class="success--text" v-text="$t('available')" />
                                                </v-col>
                                                <v-col cols="12" class="mt-1">
                                                    <template v-if="selectedGroupBy !== 'mealTypeStandardCode'">
                                                        <hotel-meal-type-label :hotel="offer" :room="room" />
                                                    </template>
                                                    <tariff-label class="mt-1" :room="room" :hotel="offer" />
                                                </v-col>
                                                <v-col cols="12" class="mt-1">
                                                    <conditions-label
                                                        v-if="
                                                            (room && room.price) ||
                                                                (packagesNotDeltaPrice && room.notDeltaPrice)
                                                        "
                                                        :offer-key="room.groupedOffers[0].offerKey"
                                                        :price="room.price || room.notDeltaPrice"
                                                        @conditions="showConditionsInfo(room)"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            md="4"
                                            class="d-flex flex-column align-end justify-start mt-3 mt-md-0"
                                        >
                                            <supplier-price :supplier-price="room.supplierPrice" class="mr-3" />
                                            <template v-if="searchRoomsCount === 1" class="room-price">
                                                <slot name="price" :room="room">
                                                    <hotel-room-offer-price-block
                                                        :room="room"
                                                        :search-request="searchRequest"
                                                        :offer="offer"
                                                    />
                                                </slot>
                                            </template>
                                            <template v-else class="justify-start">
                                                <hotel-room-offer-price-block
                                                    :room="room"
                                                    :search-request="searchRequest"
                                                    :offer="offer"
                                                    :rph="roomGroupIndex"
                                                />
                                            </template>
                                        </v-col>
                                    </v-row>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <div
                            v-if="searchRoomsCount > 1"
                            :key="room.groupedOffers[0].offerKey + '_1'"
                            class="pb-3 px-3 text-right"
                        >
                            <v-chip
                                v-for="groupedOffer in findGroup(room, roomGroupIndex)"
                                :key="groupedOffer.offerKey"
                                small
                                label
                                :disabled="checkRestrictions(room.hotelSupplierId)"
                                class="mr-1 mt-1"
                                :class="{
                                    primary: isRoomOfferSelected(groupedOffer.offerKey),
                                    'text-capitalize': true,
                                }"
                                @click="selectRoomOffer(roomIndex, groupedOffer.rph)"
                            >
                                {{ $t('select') }}
                                <v-icon
                                    v-for="adult in searchRequestTourists[groupedOffer.rph - 1].adults"
                                    :key="`adult_${adult}`"
                                    right
                                    small
                                >
                                    mdi-account
                                </v-icon>
                                <v-icon
                                    v-for="(child, childIndex) in searchRequestTourists[groupedOffer.rph - 1]
                                        .childrenAges"
                                    :key="`child_${childIndex}`"
                                    right
                                    x-small
                                >
                                    mdi-account
                                </v-icon>
                            </v-chip>
                        </div>
                    </div>
                    <v-divider
                        v-if="roomIndex + 1 < offer.rooms.slice(0, expanded[roomGroupIndex]).length"
                        :key="room.groupedOffers[0].offerKey + '_2'"
                    />
                </template>
                <v-list-item
                    v-if="roomsTotal(roomGroupIndex) > expanded[roomGroupIndex]"
                    dense
                    class="d-flex justify-center"
                    @click.stop="$emit('more-rooms', roomGroupIndex)"
                >
                    <v-icon>mdi-chevron-down</v-icon>
                </v-list-item>
            </div>
        </template>
    </v-list>
</template>

<script>
    import {Vue, Component, Prop, PropSync} from 'vue-property-decorator'
    import {hotelsStore, runtimeStore} from '~/store'
    import CommissionAmount from '~/components/snippets/CommissionAmount'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import ConditionsLabel from '~src/components/snippets/conditionsLabel.src'
    import {EventBus, SEARCH_EVENT, SHOW_HOTEL_CONDITIONS_INFO, SHOW_ROOM_INFO_MODAL_EVENT} from '@/utils/event-bus'
    import CorporatePolicyViolation from '~/components/snippets/CorporatePolicyViolation'
    import HotelGroupedRoomOffers from '~src/components/search/offers/hotelGroupedRoomOffers.src'
    import HotelRoomOfferPriceBlock from '~src/components/search/offers/hotelRoomOfferPriceBlock.src'
    import SupplierPrice from '~/components/snippets/SupplierPrice'
    import HotelPriceNote from '@/components/hotels/snippets/HotelPriceNote'
    import HotelMealTypeLabel from '@/components/hotels/snippets/HotelMealTypeLabel'
    import TariffLabel from '~src/components/hotels/snippets/tariffLabel.src'

    @Component({
        components: {
            HotelMealTypeLabel,
            HotelPriceNote,
            DiscountLabel,
            ConditionsLabel,
            CommissionAmount,
            CorporatePolicyViolation,
            HotelGroupedRoomOffers,
            HotelRoomOfferPriceBlock,
            SupplierPrice,
            TariffLabel,
        },
    })
    export default class HotelRoomOffers extends Vue {
        @Prop() offer
        @PropSync('_expanded', {default: () => []}) expanded
        @Prop({default: false}) compareMode
        @PropSync('_selectedOfferKeys', {default: () => []}) selectedOfferKeys
        @Prop({default: true}) fullCard
        @PropSync('_canBook', {default: () => false}) canBook
        @Prop({default: true}) showRoomImage
        @Prop({required: true}) hotelInfo
        @Prop({default: 1}) searchRoomsCount

        selectedGroupBy = 'none'
        selectedOffers = []
        selectedSortBy = 'price_desc'
        roomGroupIds = []
        firstChosenRoom = null
        canBookCheck = []

        created() {
            EventBus.$on(SEARCH_EVENT, this.clearSelectedOfferKeys)
        }

        beforeDestroy() {
            EventBus.$off(SEARCH_EVENT, this.clearSelectedOfferKeys)
        }

        clearSelectedOfferKeys() {
            this.selectedOfferKeys = []
            this.canBook = false
        }

        showConditionsInfo(room) {
            EventBus.$emit(SHOW_HOTEL_CONDITIONS_INFO, this.searchRequest, this.offer, room)
        }

        get searchRequest() {
            return hotelsStore.searchRequest
        }

        get searchRequestTourists() {
            return hotelsStore.searchRequestTourists
        }

        isRoomOfferSelected(offerKey) {
            return this.selectedOfferKeys.indexOf(offerKey) !== -1
        }

        selectRoomOffer(roomIndex, groupedOfferIndex) {
            const selectedRoom = this.findGroupInRoomOffers(groupedOfferIndex)[roomIndex].groupedOffers.filter(
                roomGroup => roomGroup.rph === groupedOfferIndex
            )
            this.offer.rooms.forEach(room => {
                let selectedIndex = room.groupedOffers.findIndex(
                    groupedOffer =>
                        this.isRoomOfferSelected(groupedOffer.offerKey) && groupedOffer.rph === selectedRoom[0].rph
                )
                if (selectedIndex !== -1) {
                    this.$delete(
                        this.selectedOfferKeys,
                        this.selectedOfferKeys.indexOf(room.groupedOffers[selectedIndex].offerKey)
                    )
                }
            })

            this.selectedOfferKeys.push(selectedRoom[0].offerKey)
            this.selectedOffers.push(selectedRoom)
            if (
                !this.firstChosenRoom ||
                (this.firstChosenRoom === groupedOfferIndex && this.roomGroupIds !== selectedRoom[0].roomGroupIds)
            ) {
                this.roomGroupIds = selectedRoom[0].roomGroupIds
                this.firstChosenRoom = groupedOfferIndex
                this.canBookCheck = []
            }
            this.canBookCheck[groupedOfferIndex] = this.canBookForMultiRoom(selectedRoom[0])
            let canBookArr = this.canBookCheck.filter(el => {
                return el === true
            })
            this.canBook = canBookArr.length === this.searchRoomsCount
        }

        roomInfo(hotel, offerKey) {
            EventBus.$emit(SHOW_ROOM_INFO_MODAL_EVENT, hotel, offerKey)
        }

        get offersToCompare() {
            return hotelsStore.offersToCompare
        }

        get selectedRooms() {
            const selectedOffer = this.offersToCompare.find(el => {
                return el.hotelCode === this.offer.hotelCode
            })
            return selectedOffer ? selectedOffer.rooms : []
        }

        set selectedRooms(offerKey) {
            hotelsStore.ADD_ROOM_TO_COMPARE({hotelCode: this.offer.hotelCode, rooms: offerKey})
        }

        findGroupInRoomOffers(groupIndex) {
            let result = []
            this.filterRoomOffers(this.offer.rooms).forEach(room => {
                const findGroup = room.groupedOffers.findIndex(groupedOffer => groupedOffer.rph === groupIndex)
                if (findGroup !== -1) {
                    result.push(room)
                }
            })

            return result.sort(this.sortFns[this.selectedSortBy])
        }

        findGroup(room, groupIndex) {
            return [room.groupedOffers.filter(roomGroup => roomGroup.rph === groupIndex)[0]]
        }

        checkRestrictions(hotelSupplierId) {
            if (!this.selectedOffers.length) return false
            return hotelSupplierId && this.selectedOffers[0].hotelSupplierId === hotelSupplierId
        }

        roomsTotal(groupIndex) {
            return this.offer ? this.findGroupInRoomOffers(groupIndex).length : 0
        }

        sortFns = {
            price_desc: (a, b) => {
                const priceA = a.price || a.deltaPrice
                const priceB = b.price || b.deltaPrice
                return priceA.amount - priceB.amount
            },
            price_asc: (a, b) => {
                const priceA = a.price || a.deltaPrice
                const priceB = b.price || b.deltaPrice
                return priceB.amount - priceA.amount
            },
            name: (offer1, offer2) => {
                if (offer1.name < offer2.name) return -1
                if (offer1.name > offer2.name) return 1
                return 0
            },
        }

        canBookForMultiRoom(room) {
            if (!room.roomGroupIds) return true
            let arrIntersect = this.roomGroupIds.filter(function(n) {
                return room.roomGroupIds.indexOf(n) !== -1
            })

            return arrIntersect.length > 0
        }

        roomGroupClass(room) {
            return this.searchRoomsCount > 1 &&
                this.roomGroupIds &&
                this.roomGroupIds.length &&
                this.canBookForMultiRoom(room.groupedOffers[0])
                ? 'green lighten-5'
                : ''
        }

        filterRoomOffers(rooms) {
            if (hotelsStore.filters.mealTypes.length) {
                return rooms.filter(({mealTypeStandardCode}) => {
                    return hotelsStore.filters.mealTypes.includes(mealTypeStandardCode)
                })
            }

            return rooms
        }

        findRoomImage(room) {
            try {
                const roomType = this.hotelInfo.hotelDescriptionInfo.roomTypes.roomType.find(
                    roomType => roomType.code === room.code
                )
                return roomType.images.image.length ? roomType.images.image[0].url : false
            } catch (e) {
                return false
            }
        }

        get packagesNotDeltaPrice() {
            return runtimeStore.config.packagesNotDeltaPrice
        }
    }
</script>

<style scoped lang="scss">
    .wrap-words {
        white-space: normal;
    }

    .title {
        text-transform: capitalize;
    }

    .on-request-icon {
        line-height: 17px;
    }
</style>
