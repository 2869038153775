<template>
    <v-app>
        <header-bar />
        <v-main>
            <v-container fill-height grid-list-md text-xs-center>
                <nuxt />
            </v-container>
        </v-main>
        <footer-bar />
    </v-app>
</template>

<script>
    import {Component} from 'nuxt-property-decorator'
    const pjson = require('~~/package.json')
    import HeaderBar from '~src/components/headerBar.src'
    import FooterBar from '~src/components/footerBar.src'
    import BaseLayout from '@/components/BaseLayout'

    @Component({
        components: {
            HeaderBar,
            FooterBar,
        },
    })
    export default class DefaultLayout extends BaseLayout {
        get version() {
            return pjson.version
        }
    }
</script>
