<template>
    <v-container :fill-height="!loaded" column fluid>
        <template v-if="loaded">
            <breadcrumbs id="breadcrumbs" class="mb-4" />
            <order-details
                ref="order"
                @cancelService="loadOrder"
                @modifyService="loadOrder"
                @issuedInvoice="issuedInvoice"
                @issuedTicket="loadOrder"
            />
        </template>
        <v-layout v-else class="align-center justify-center">
            <v-progress-circular indeterminate size="64" color="primary" />
        </v-layout>
        <v-dialog v-model="confirmationStatusModal" hide-overlay width="500px">
            <v-card>
                <v-card-text class="pt-5">
                    <confirmation-status v-if="bookingConfirmation === 'order'" :company="company" />
                    <invoice-payment-status v-if="bookingConfirmation === 'invoice'" :order-id="$route.params.id" />
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-btn color="primary" class="mx-auto" text @click="confirmationStatusModal = false">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import {Vue, Component} from 'vue-property-decorator'
    import Breadcrumbs from '~/components/Breadcrumbs'
    import {runtimeStore, authStore, persistentStore} from '~/store'
    import ConfirmationStatus from '@/components/booking/ConfirmationStatus'
    import OrderDetails from '~src/components/account/orderDetails.src'
    import {EventBus, SHOW_INVOICES_MODAL_EVENT} from '@/utils/event-bus'
    import InvoicePaymentStatus from '@/components/booking/InvoicePaymentStatus'

    @Component({
        components: {
            InvoicePaymentStatus,
            ConfirmationStatus,
            OrderDetails,
            Breadcrumbs,
        },
        middleware: 'orderAuth',
        layout: 'blank',
    })
    export default class OrderDetailsPage extends Vue {
        confirmationStatusModal = false
        company = {
            phones: [],
        }
        bookingConfirmation = 'order'
        loaded = false

        async mounted() {
            await this.$store.restored
            if (persistentStore.selectedOrderId) {
                persistentStore.SET_SELECTED_ORDER_ID(null)
            }
            await this.loadOrder()
        }

        async loadOrder() {
            this.loaded = false
            await runtimeStore.loadOrder({
                orderType: authStore.orderType,
                orderId: this.$route.params.id,
            })
            this.loaded = true
        }

        beforeRouteEnter(to, from, next) {
            if (from.name && (from.name === 'paymentComplete' || from.name.indexOf('Booking') !== -1)) {
                next(async vm => {
                    try {
                        const rs = await vm.$api.companies.get({relationship: vm.relationship})
                        vm.company = rs.object[0]
                        vm.bookingConfirmation = 'order'
                        vm.confirmationStatusModal = true
                        // eslint-disable-next-line no-empty
                    } catch (e) {}
                })
            }
            if (from.name && from.name === 'invoicePaymentComplete') {
                next(async vm => {
                    vm.bookingConfirmation = 'invoice'
                    vm.confirmationStatusModal = true
                })
            }
            next()
        }

        async issuedInvoice(invoiceType) {
            await this.loadOrder()
            const order = runtimeStore.orderDetails
            EventBus.$emit(SHOW_INVOICES_MODAL_EVENT, {
                orderId: order.orderId,
                objectId: order.objectId,
                invoiceType: invoiceType,
                order,
            })
        }

        get relationship() {
            return authStore.isCorporate || authStore.isTO2 || authStore.isAgency ? 'SUPPLIER' : 'OWN'
        }
    }
</script>
