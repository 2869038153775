<script>
    import {Component, Prop, Watch} from 'vue-property-decorator'
    import LoadMixin from '@/mixins/LoadMixin'
    import HotelMap from '../../search/offers/HotelMap.vue'

    @Component({
        mixins: [LoadMixin],
    })
    export default class TransferMap extends HotelMap {
        @Prop() points
        @Prop({default: null}) parent

        directionsDisplay = null

        @Watch('points', {deep: true})
        onPointsChange() {
            if (!this.google) return
            this.setMarkers()
        }

        async load() {
            await this.initMap()
            this.onPointsChange()
        }

        async setMarkers() {
            let points = [...this.points]
            if (!points[0].latitude) {
                points[0] = await this.getCoordinates(points[0])
            }
            if (!points[1].latitude) {
                points[1] = await this.getCoordinates(points[1])
            }
            this.makeDirection(points)
        }

        getMapOptions() {
            return {
                disableDefaultUI: true,
                gestureHandling: 'greedy',
                mapTypeControl: true,
                zoomControl: true,
                fullscreenControl: true,
                zoom: 8,
            }
        }

        makeDirection(points) {
            if (this.directionsDisplay) {
                this.directionsDisplay.setMap(null)
            }
            let directionsDisplay = new this.google.maps.DirectionsRenderer()

            const request = {
                origin: new this.google.maps.LatLng(points[0].latitude, points[0].longitude), //точка старта
                destination: new this.google.maps.LatLng(points[1].latitude, points[1].longitude), //точка финиша
                travelMode: this.google.maps.DirectionsTravelMode.DRIVING, //режим прокладки маршрута
            }
            const directionsService = new this.google.maps.DirectionsService()
            directionsService.route(request, (response, status) => {
                if (status === this.google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(response)
                }
            })

            directionsDisplay.setMap(this.map)
            this.directionsDisplay = directionsDisplay
        }

        getCoordinates(point) {
            return new Promise(resolve => {
                this.geocoder.geocode({address: `${point.hotelName}, ${point.hotelAddress}`}, (results, status) => {
                    if (status !== 'OK' || !results[0]) {
                        return
                    }
                    point.latitude = results[0].geometry.location.lat()
                    point.longitude = results[0].geometry.location.lng()
                    return resolve(point)
                    // this.makeDirection(points)
                    //this.map.setCenter(marker.position)
                    //this.map.setZoom(18)
                })
            })
        }

        yPos() {
            const y = !this.isMobile
                ? this.$parent.$parent.$el.parentElement.getBoundingClientRect().y
                : this.getMobileYPosition()
            return y > 0 ? y : 0
        }

        getMobileYPosition() {
            return !this.parent ? this.$parent.$refs.mobileTabs.$el.offsetHeight : 0
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    .map {
        position: fixed;

        @media #{map-get($display-breakpoints, 'xs-only')} {
            margin-left: -$container-padding-x;
        }

        > div {
            height: 100%;
        }
    }
</style>
