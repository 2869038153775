<script>
    import {Component, Vue} from 'vue-property-decorator'
    import {flightsRuntimeStore, flightsStore} from '@/store'

    @Component
    export default class FlightStoreMixin extends Vue {
        get productStore() {
            return flightsStore
        }

        get productRuntimeStore() {
            return flightsRuntimeStore
        }

        get productType() {
            return 'flight'
        }
    }
</script>
