<template>
    <search-summary-layout
        :search-disabled="searchDisabled"
        form-name="extra-service-search-form"
        :search-request="searchRequest"
    >
        <template v-slot:title>
            {{ locationLabel }}
        </template>
        <template v-slot:content>
            <div>
                {{ dates }}
            </div>
        </template>
    </search-summary-layout>
</template>

<script>
    import {Vue, Component, Watch, Prop} from 'vue-property-decorator'
    import {extraServicesRuntimeStore} from '~/store'
    import SearchSummaryLayout from '~src/components/parts/SearchSummaryLayout'

    //TODO Hide if empty searchRequest
    @Component({
        components: {
            SearchSummaryLayout,
        },
    })
    export default class ExtraServiceSearchSummary extends Vue {
        @Prop({default: false, type: Boolean}) searchDisabled
        @Prop({required: true}) searchRequest

        searchForm = false

        @Watch('isSearchActive')
        onLoadingChange(searchActive) {
            if (searchActive) {
                this.searchForm = false
            }
        }

        get searchFormComponent() {
            return 'extra-service-search-form'
        }

        get isSearchActive() {
            return extraServicesRuntimeStore.searchActive
        }

        get locationLabel() {
            return `${extraServicesRuntimeStore.city.name}, ${extraServicesRuntimeStore.city.parentName}`
        }

        get dates() {
            return `${this.formattedDate(this.searchRequest.startDate)}`
        }

        formattedDate(date) {
            return date ? this.$options.filters.dateFormat(this.$dateFns.parseISO(date)) : ''
        }

        checkDisabled(on) {
            if (this.searchDisabled) return
            return on
        }
    }
</script>
