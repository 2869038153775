<template>
    <div>
        <breadcrumbs class="pb-3" :text="breadCrumbText" />
        <div class="pa-4">
            <v-row>
                <v-col cols="12" md="12">
                    <h1>{{ $t('supplier.calendar_title') }}</h1>
                </v-col>
            </v-row>
        </div>
        <div class="pa-4">
            <template v-if="loaded">
                <v-tabs v-model="tab">
                    <v-tab key="tab-0" class="d-none" />
                    <v-tab key="tab-1" :class="tab === 1 ? 'white' : 'grey lighten-3 grey--text text-lighten-1'">
                        {{ $t('supplier.mass_edit_prices') }}
                    </v-tab>
                    <v-tab key="tab-2" :class="tab === 2 ? 'white' : 'grey lighten-3 grey--text text-lighten-1'">
                        {{ $t('supplier.mass_edit_availability') }}
                    </v-tab>
                    <v-tab key="tab-3" :class="tab === 3 ? 'white' : 'grey lighten-3 grey--text text-lighten-1'">
                        {{ $t('supplier.open_close_rooms') }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab" style="width:100%" class="overflow--visible">
                    <v-tab-item key="tab-0" class="d-none" />
                    <v-tab-item key="tab-1">
                        <mass-edit-prices
                            :start-date="startDate"
                            :end-date="endDate"
                            :services="services"
                            :tariffs="tariffs"
                            @update="updateDailyRates"
                        />
                    </v-tab-item>
                    <v-tab-item key="tab-2">
                        <mass-edit-availability
                            :start-date="startDate"
                            :end-date="endDate"
                            :services="servicesData"
                            @update="updateMultipleAvailabilities"
                        />
                    </v-tab-item>
                    <v-tab-item key="tab-3">
                        <open-close-rooms
                            :start-date="startDate"
                            :end-date="endDate"
                            :services="servicesData"
                            @update="updateMultipleAvailabilities"
                        />
                    </v-tab-item>
                </v-tabs-items>
            </template>
            <template v-else>
                <v-skeleton-loader width="100%" type="article, actions" />
            </template>
        </div>
        <div class="pt-4 pb-0 mt-12">
            <template v-if="loaded">
                <calendar-filters
                    :services="services"
                    :start-date="startDate"
                    :end-date="endDate"
                    @update="filterCalendar"
                />
            </template>
            <template v-else>
                <v-skeleton-loader type="article" />
            </template>
        </div>
        <v-row>
            <v-col cols="12" md="12">
                <div v-if="loadedData" style="overflow-x: auto; width: 100%;">
                    <calendar-legend />
                    <table
                        v-for="service in servicesData"
                        v-show="!serviceId || serviceId === service.id"
                        :key="service.id"
                        class="v-table theme--light mb-6"
                        :loading="true"
                        style="width: 100%"
                    >
                        <thead>
                            <tr class="service-name-row">
                                <th class="pl-2 grey--text text--darken-1" :colspan="service.rates.length + 1">
                                    {{ service.name }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="td-header pl-2">
                                    {{ startDate | dateShortFormat }} - {{ endDate | dateShortFormat }}
                                </td>
                                <td v-for="day in service.rates" :key="service.id + '-' + day.date">
                                    <div class="date-cell">
                                        {{ $t(`supplier.days.${weekDay(day.date)}`) }}
                                    </div>
                                    <div class="date-cell-day">
                                        {{ dayNumber(day.date) }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="pl-2">
                                    {{ $t('supplier.room_status') }}
                                </td>
                                <template v-for="day in service.rates">
                                    <room-status-cell
                                        :key="'room-status-' + service.id + '-' + day.date"
                                        :date="day.date"
                                        :service="day.service"
                                        @update="updateAvailabilities"
                                    />
                                </template>
                            </tr>
                            <tr>
                                <td class="pl-2">
                                    {{ $t('supplier.rooms_to_sell') }}
                                </td>
                                <template v-for="day in service.rates">
                                    <room-to-sell-cell
                                        :key="'rooms-to-sell-' + service.id + '-' + day.date"
                                        :date="day.date"
                                        :service="day.service"
                                        @update="updateAvailabilities"
                                    />
                                </template>
                            </tr>
                        </tbody>

                        <template v-for="tariff in service.tariffs">
                            <tariff-rows
                                :key="'tariff-' + service.id + '-' + tariff.id"
                                :tariff="tariff"
                                :service-id="service.id"
                                :service-status="service.status"
                                @update="updateDailyRates"
                            />
                        </template>
                    </table>
                </div>
                <template v-else>
                    <v-skeleton-loader transition="fade-transition" type="table" />
                </template>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {Vue, Component} from 'nuxt-property-decorator'
    import Breadcrumbs from '~/components/Breadcrumbs'
    import {supplierStore} from '@/utils/store-accessor'
    import RoomStatusCell from '~/components/supplier/RoomStatusCell'
    import RoomToSellCell from '~/components/supplier/RoomToSellCell'
    import TariffRows from '@/components/supplier/TariffRows'
    import MassEditPrices from '@/components/supplier/MassEditPrices'
    import MassEditAvailability from '@/components/supplier/MassEditAvailability'
    import CalendarFilters from '@/components/supplier/CalendarFilters'
    import OpenCloseRooms from '@/components/supplier/OpenCloseRooms'
    import {clone, timeout} from '@/utils/helpers'
    import CalendarLegend from '@/components/supplier/CalendarLegend'

    @Component({
        components: {
            CalendarLegend,
            OpenCloseRooms,
            CalendarFilters,
            MassEditAvailability,
            MassEditPrices,
            TariffRows,
            Breadcrumbs,
            RoomStatusCell,
            RoomToSellCell,
        },
        middleware: 'auth',
    })
    export default class ContractPage extends Vue {
        contractId = null
        startDate = null
        endDate = null
        loaded = false
        loadedData = false
        tab = 0
        serviceId = 0

        async mounted() {
            this.contractId = this.$route.params.contractId

            let startDate = new Date()
            this.startDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000)
                .toISOString()
                .split('T')[0]

            let endDate = new Date()
            endDate.setMonth(endDate.getMonth() + 1)
            this.endDate = endDate.toISOString().split('T')[0]

            await supplierStore.loadContract(this.contractId)
            await this.load()

            this.loaded = true
            this.loadedData = true
        }

        async load() {
            const rq = {contractId: this.contractId, dateFrom: this.startDate, dateTo: this.endDate}

            await Promise.all([
                supplierStore.loadAvailability(rq),
                supplierStore.loadDailyRates(rq),
                supplierStore.loadPrices({contractId: this.contractId}),
            ])
        }

        get services() {
            return supplierStore.services || []
        }

        get tariffs() {
            return supplierStore.tariffs || []
        }

        get servicesData() {
            let result = supplierStore.services.map(service => {
                let availabilitiesByService = supplierStore.availabilityServices.filter(availabilityService => {
                    return availabilityService.serviceId === service.id
                })

                let rates = this.daysArray.map(day => {
                    let serviceByDate = availabilitiesByService.filter(availabilityService => {
                        return (
                            Date.parse(availabilityService.dateFrom) <= Date.parse(day) &&
                            Date.parse(availabilityService.dateTo) >= Date.parse(day)
                        )
                    })

                    return {
                        date: day,
                        service: serviceByDate[0],
                    }
                })

                let tariffs = supplierStore.tariffs.map(tariff => {
                    let dailyRatesForTariff = this.daysArray.map(tariffDay => {
                        let dailyRateForTariff = supplierStore.dailyRates.filter(dailyRate => {
                            return (
                                dailyRate.date === tariffDay &&
                                dailyRate.serviceId === service.id &&
                                dailyRate.tariffId === tariff.id
                            )
                        })

                        return {
                            date: tariffDay,
                            rate: dailyRateForTariff.length
                                ? dailyRateForTariff[dailyRateForTariff.length - 1]
                                : {
                                      active: false,
                                      serviceId: service.id,
                                      tariffId: tariff.id,
                                  },
                        }
                    })

                    return {
                        id: tariff.id,
                        name: tariff.name,
                        dailyRates: dailyRatesForTariff,
                    }
                })
                return {
                    id: service.id,
                    name: service.name,
                    categoryId: rates[0].service.categoryId,
                    rates: rates,
                    tariffs: tariffs,
                }
            })

            return result
        }

        async filterCalendar(rq) {
            this.loadedData = false

            this.startDate = rq.dateFrom
            this.endDate = rq.dateTo
            this.serviceId = rq.serviceId

            await this.load()

            this.loadedData = true
        }

        get daysArray() {
            const addDays = (date, days = 1) => {
                const result = new Date(date)
                result.setDate(result.getDate() + days)
                return result
            }

            const dateRange = (start, end, range = []) => {
                if (start > end) return range
                const next = addDays(start, 1)
                return dateRange(next, end, [...range, start])
            }

            const range = dateRange(new Date(this.startDate), new Date(this.endDate))

            return range.map(date => date.toISOString().slice(0, 10))
        }

        weekDay(date) {
            return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date(date).getDay()]
        }

        dayNumber(date) {
            return new Date(date).getUTCDate()
        }

        async updateAvailabilities(rq) {
            try {
                await this.$api.availability.put(this.contractId, rq)

                let dayOfWeeks = rq.dayOfWeek

                supplierStore.tariffs.map(async tariff => {
                    let tariffRq = clone(rq)

                    Object.assign(tariffRq, {
                        tariffId: tariff.id,
                        amount: null,
                        dayOfWeeks: dayOfWeeks,
                        active: tariffRq.operation !== 'BLOCK',
                    })

                    delete tariffRq.categoryId
                    delete tariffRq.dayOfWeek
                    delete tariffRq.operation

                    await this.$api.dailyRates.put({dailyRates: [tariffRq]})
                    await timeout(100)
                })

                this.$toast.success(this.$t('supplier.successfully_updated'))

                await timeout(300)
                await this.load()
            } catch (error) {
                this.$toast.error(error.errors[0].message)
            }
        }

        async updateDailyRates(rq) {
            try {
                let status = rq.operation !== 'BLOCK'

                delete rq.operation

                await this.$api.dailyRates.put({dailyRates: [Object.assign(rq, {active: status})]})

                this.$toast.success(this.$t('supplier.successfully_updated'))

                await this.load()
            } catch (error) {
                this.$toast.error(error.errors[0].message)
            }
        }

        async updateMultipleAvailabilities(data) {
            data.services.map(async service => {
                if (service.checked) {
                    let rq = Object.assign(service, {
                        operation: data.operation,
                        dateFrom: data.dateFrom,
                        dateTo: data.dateTo,
                        dayOfWeek: data.dayOfWeek,
                    })
                    delete rq.checked
                    await this.updateAvailabilities(rq)
                    await timeout(100)
                }
            })
        }

        get breadCrumbText() {
            return this.$t('supplier.calendar_title') + ' - ' + (supplierStore.contract.name || '...')
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep {
        table {
            margin: 0 auto;
        }
        table td {
            width: 40px !important;
            min-width: 40px;
            height: 40px !important;
            cursor: pointer;
            padding: 2px;
        }

        table td,
        table th {
            border: 1px solid #dee2e6;
        }

        table td,
        table th {
            vertical-align: middle;
        }

        table td:nth-child(1) {
            padding-left: 5px;
            font-size: 14px;
        }

        .date-cell {
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #9b9b9b;
        }

        .date-cell-day {
            font-size: 20px;
            font-weight: 400;
            color: #1c1c1c;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
        }

        .service-name-row {
            height: 35px;
            font-size: 20px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #000a12;
        }

        .service-name-row th {
            padding-left: 5px;
        }

        .td-header {
            position: relative;
            background: white;
            min-height: 77px;
            font-size: 12px !important;
            vertical-align: middle;
            font-weight: 100;
            min-width: 160px;
        }

        .v-text-field {
            padding-top: 0 !important;
        }

        table .v-input input {
            text-align: center;
        }

        table input::-webkit-outer-spin-button,
        table input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        table input[type='number'] {
            -moz-appearance: textfield;
        }
    }
</style>
