<template>
    <v-card :min-height="frontBannerHeight" tile flat :img="img" class="d-flex align-center justify-center">
        <slot name="form" />
    </v-card>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'

    @Component
    export default class MainPageFormWrapper extends Vue {
        @Prop() img

        frontBannerHeight = 500
    }
</script>
