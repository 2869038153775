<template>
    <v-row>
        <v-col cols="12" md="4">
            <city-airport-autocomplete
                v-model="from"
                single-line
                filled
                label="departure_location"
                :rules="[v => !!v || `${$t('departure_location')} ${$t('validation.required')}`]"
                :default-cities="[]"
            />
        </v-col>
        <v-col cols="12" md="4">
            <city-airport-autocomplete
                v-model="to"
                single-line
                filled
                label="arrival_location"
                :rules="[v => !!v || `${$t('arrival_location')} ${$t('validation.required')}`]"
                :default-cities="[]"
            />
        </v-col>
        <v-col cols="12" md="4">
            <daterange-m :start-date-prop.sync="startDate" :end-date-prop.sync="endDate" product="flights" />
        </v-col>
    </v-row>
</template>

<script>
    import {Component, Vue} from 'vue-property-decorator'
    import CityAirportAutocomplete from '~/components/flights/search/forms/CityAirportAutocomplete'
    import {flightsRuntimeStore} from '~/store'
    import FlightSearchFormMixin from '~/mixins/Flights/FlightSearchFormMixin'
    import DaterangeM from '~src/components/search/forms/daterangeM.src'

    @Component({
        components: {
            CityAirportAutocomplete,
            DaterangeM,
        },
        mixins: [FlightSearchFormMixin],
    })
    export default class RoundTrip extends Vue {
        startDate = null
        endDate = null

        getRoutes() {
            return [
                {
                    departure: this.from,
                    arrival: this.to,
                    date: this.startDate,
                },
                {
                    departure: this.to,
                    arrival: this.from,
                    date: this.endDate,
                },
            ]
        }

        initDates() {
            if (
                flightsRuntimeStore.routes[0] &&
                flightsRuntimeStore.routes[1] &&
                this.$dateFns.isAfter(this.$dateFns.parseISO(flightsRuntimeStore.routes[0].date), new Date())
            ) {
                this.startDate = flightsRuntimeStore.routes[0].date
                this.endDate = flightsRuntimeStore.routes[1].date
            } else {
                this.startDate = this.$dateFns.format(this.$dateFns.addDays(new Date(), 1))
                this.endDate = this.$dateFns.format(this.$dateFns.addDays(new Date(), 7))
            }
        }
    }
</script>
