<template>
    <div>
        <hotel-breadcrumbs :search-request="searchRequest" class="pa-5" />
        <div ref="container" class="overflow-x-auto mx-5">
            <v-snackbar
                ref="comporationOptins"
                vertical
                :content-class="hideComparationOptions ? 'd-flex flex-column pa-0 ma-0' : ''"
                bottom
                right
                :value="true"
                :timeout="-1"
                light
                color="cyan darken-2"
                class="hidden-sm-and-down"
            >
                <div v-if="!hideComparationOptions" class="d-flex flex-wrap">
                    <v-row>
                        <v-col :cols="12" class="py-0">
                            <h4>
                                {{ $t('comparison.options') }}
                            </h4>
                        </v-col>
                        <v-col class="pt-0">
                            <v-switch v-model="showAll" color="warning" :hide-details="true">
                                <template v-slot:label>
                                    <span class="white--text">{{ $t('comparison.show_similar') }}</span>
                                </template>
                            </v-switch>
                        </v-col>
                        <v-col class="pt-0">
                            <v-switch v-model="mapVisible" color="warning" :hide-details="true" @change="showMap">
                                <template v-slot:label>
                                    <span class="white--text">{{ $t('comparison.show_map') }}</span>
                                </template>
                            </v-switch>
                        </v-col>
                    </v-row>
                </div>
                <v-btn v-else class="mt-1 white--text cyan darken-3" @click="hideCompare">
                    {{ $t('show') }}
                </v-btn>
                <template v-if="!hideComparationOptions" v-slot:action="{attrs}">
                    <v-row v-bind="attrs">
                        <v-col class="pa-0" align-self="center">
                            <v-btn class="white--text cyan darken-3 mr-2" @click="closeCompare">
                                {{ $t('close') }}
                            </v-btn>
                            <v-btn class="white--text cyan darken-3 mr-2" @click="hideCompare">
                                {{ $t('hide') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-snackbar>
            <v-row>
                <v-expansion-panels v-model="panel" flat accordion>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="text-xs-center">
                            {{ $t('comparison.map') }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="mx-0 px-0" eager>
                            <client-only>
                                <compare-map
                                    v-if="city.latitude && city.longitude"
                                    :offers="offers"
                                    :_expanded.sync="mapExpanded"
                                    :is-fixed="false"
                                    height="40vh"
                                    :width="width"
                                    :city="city"
                                    style="position: relative"
                                />
                            </client-only>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>
            <v-row ref="width" class="odd-line flex-md-nowrap fit">
                <v-col cols="12" md="2" class="text-center text-md-left subtitle-2 field-name" style="max-width:305px">
                    {{ $t('comparison.name') }}
                </v-col>
                <v-col
                    v-for="offer in offers"
                    :key="offer.hotelCode"
                    class="d-flex justify-center px-0 mx-0"
                    :style="{'min-width': rowWidth}"
                >
                    <n-link no-prefetch :to="hotelPageLink(offer)" class="centered">
                        {{ offer.name }}
                    </n-link>
                    <v-icon right color="red" @click="removeFromComparison(offer.hotelCode)">
                        mdi-delete
                    </v-icon>
                </v-col>
            </v-row>
            <v-row class="justify-center comparison-header py-2 hidden-sm-and-down">
                {{ $t('comparison.general_info') }}
            </v-row>
            <v-row class="flex-md-nowrap">
                <v-col
                    cols="12"
                    md="2"
                    class="text-center text-md-left hidden-sm-and-down subtitle-2 field-name"
                    style="max-width:305px"
                >
                    {{ $t('comparison.image') }}
                </v-col>
                <v-col
                    v-for="offer in offers"
                    :key="offer.hotelCode"
                    class="d-flex justify-md-center justify-xs-space-between  flex-md-row px-0 mr-1 flex-wrap"
                    :style="{'min-width': rowWidth}"
                >
                    <div class="hidden-md-and-up px-5">
                        {{ offer.name }}
                    </div>
                    <v-img :src="offer.thumb | imageUrl(350)" lazy-src="/placeholder.png" :max-width="rowWidth" />
                </v-col>
            </v-row>
            <v-row class="odd-line flex-md-nowrap" :style="{width}">
                <v-col cols="12" md="2" class="text-center text-md-left subtitle-2 field-name" style="max-width:305px">
                    {{ $t('comparison.rating') }}
                </v-col>
                <v-col
                    v-for="offer in offers"
                    :key="offer.hotelCode"
                    class="d-flex justify-md-center justify-xs-space-between "
                    :style="{'min-width': rowWidth}"
                >
                    <div class="hidden-md-and-up">
                        {{ offer.name }}
                    </div>
                    <v-rating
                        class="d-inline-block mr-3 ml-auto ml-md-0"
                        small
                        dense
                        readonly
                        :value="parseInt(offer.category)"
                    />
                </v-col>
            </v-row>
            <v-row class="flex-md-nowrap">
                <v-col cols="12" md="2" class="text-center text-md-left subtitle-2 field-name" style="max-width:305px">
                    {{ $t('comparison.address') }}
                </v-col>
                <v-col
                    v-for="offer in offers"
                    :key="offer.hotelCode"
                    class="d-flex justify-md-center justify-xs-space-between  flex-xs-column "
                    :style="{'min-width': rowWidth}"
                >
                    <div class="hidden-md-and-up">
                        {{ offer.name }}
                    </div>
                    <span class="ml-auto ml-md-0 body-2">
                        <v-icon left>
                            mdi-map-marker
                        </v-icon>

                        {{ offer.address }}
                    </span>
                </v-col>
            </v-row>
            <v-row class="odd-line flex-md-nowrap" :style="{width}">
                <v-col cols="12" md="2" class="text-center text-md-left subtitle-2 field-name" style="max-width:305px">
                    {{ $t('comparison.distance') }}
                </v-col>
                <v-col
                    v-for="offer in offers"
                    :key="offer.hotelCode"
                    class="d-flex justify-md-center justify-xs-space-between  flex-xs-column "
                    :style="{'min-width': rowWidth}"
                >
                    <div class="hidden-md-and-up">
                        {{ offer.name }}
                    </div>
                    <span class="ml-auto ml-md-0">
                        <v-icon v-if="offer.distance" left>
                            mdi-map-marker-distance
                        </v-icon>
                        {{ offer.distance ? `${offer.distance} km` : '' }}
                    </span>
                </v-col>
            </v-row>
            <v-row class="justify-center comparison-header py-2 hidden-sm-and-down" :style="{width}">
                {{ $t('comparison.services') }}
            </v-row>
            <v-row
                v-for="(key, index) in filteredKeys"
                :key="`${key}-${index}`"
                :class="{'odd-line': index % 2 !== 0}"
                :style="{width}"
            >
                <v-col
                    cols="12"
                    md="2"
                    class="text-center text-md-left subtitle-2 field-name"
                    :style="$breakpoint.smAndDown ? 'max-width: 100%' : 'max-width:305px'"
                >
                    {{ key }}
                </v-col>
                <v-col :cols="12" md="10">
                    <v-row class="flex-md-nowrap">
                        <v-col
                            v-for="offer in offers"
                            :key="offer.hotelCode"
                            class="d-flex justify-md-center justify-xs-space-between px-2 mx-2 px-md-0 mx-md-0"
                            :style="{'min-width': rowWidth}"
                        >
                            <div class="hidden-md-and-up">
                                {{ offer.name }}
                            </div>
                            <v-tooltip top>
                                <template v-slot:activator="{on}">
                                    <v-icon
                                        :color="hasKey(key, offer.services) ? 'success' : 'error'"
                                        class="ml-auto ml-md-0"
                                        v-on="on"
                                    >
                                        {{ hasKey(key, offer.services) ? 'mdi-check-bold' : 'mdi-close' }}
                                    </v-icon>
                                </template>
                                <span>{{ key }}</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="justify-center comparison-header py-2 hidden-sm-and-down" :style="{width: width}">
                {{ $t('comparison.prices') }}
            </v-row>
            <v-row v-for="mealCode in mealCodes" :key="mealCode" class="flex-md-nowrap" :style="{width: width}">
                <v-col cols="12" md="2" class="text-center text-md-left subtitle-2 field-name" style="max-width:305px">
                    {{ $t(`filters_mealTypes.${mealCode}`) }}
                </v-col>
                <v-col
                    v-for="offer in offers"
                    :key="offer.hotelCode"
                    class="d-flex justify-center"
                    :style="{'min-width': rowWidth}"
                >
                    <div class="hidden-md-and-up">
                        {{ offer.name }}
                    </div>
                    <span v-if="getBestPrice(offer, mealCode)" class="ml-auto ml-md-0">
                        {{ getBestPrice(offer, mealCode) | price }}
                    </span>
                    <span v-else class="ml-auto ml-md-0">
                        <v-icon color="error">mdi-close</v-icon>
                    </span>
                </v-col>
            </v-row>
            <div class="scroll-container" @scroll="scrollContainer">
                <div ref="scrollbar" :style="{width}" @scroll="scrollContainer">
                    &nbsp;
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Vue, Component, Watch} from 'nuxt-property-decorator'
    import {hotelsStore, hotelsRuntimeStore} from '~/store'
    import Breadcrumbs from '~/components/Breadcrumbs'
    import HotelBreadcrumbs from '@/components/hotels/HotelBreadcrumbs'

    @Component({
        head() {
            return {
                title: this.$t('compare_hotels'),
            }
        },
        components: {
            HotelBreadcrumbs,
            CompareMap: () => import('~src/components/snippets/compareMap.src'),
            Breadcrumbs,
        },
        layout() {
            return 'blank'
        },
    })
    export default class ComparisonPage extends Vue {
        mapExpanded = false
        mapVisible = true
        panel = 0
        serviceKeys = []
        changeNameStyle = false
        bestValues = {
            category: null,
            distance: null,
        }
        showAll = false
        cachedOffers = []
        hideComparationOptions = false

        async created() {
            await this.prepareCompareData()
        }

        async mounted() {
            await this.$store.restored
            this.cachedOffers = await this.$localForage.getItem('hotels')
            if (hotelsRuntimeStore.city.id !== this.searchRequest.cityId) {
                await hotelsRuntimeStore.loadCity(this.searchRequest.cityId)
            }
        }

        @Watch('offers')
        onOffersChange() {
            this.prepareCompareData()
        }

        @Watch('panel')
        onPanelChange() {
            if (this.panel === 0) {
                this.mapVisible = true
            } else {
                this.mapVisible = false
            }
        }

        prepareCompareData() {
            this.getBestValues()
            this.getServiceKeys()
        }

        scrollContainer(e) {
            this.$refs.container.scroll({left: e.target.scrollLeft})
        }

        getBestValues() {
            this.offers.forEach(offer => {
                if (!this.bestValues.category || parseInt(offer.category) > this.bestValues.category) {
                    this.bestValues.category = parseInt(offer.category)
                }
                if (!this.bestValues.distance || (offer.distance && offer.distance < this.bestValues.distance)) {
                    this.bestValues.distance = offer.distance
                }
            })
        }

        getServiceKeys() {
            const keys = this.offers.map(offer => {
                return offer.services
            })
            let result = []
            keys.forEach(key => {
                result = result.concat(key)
            })
            this.serviceKeys = Array.from(new Set(result))
        }

        hasKey(key, services) {
            return services.indexOf(key) >= 0
        }

        showMap() {
            this.panel !== 0 ? (this.panel = 0) : (this.panel = null)
        }

        getBestPrice(offer, mealCode) {
            const best = offer.rooms
                .filter(room => room.mealTypeStandardCode === mealCode)
                .sort((a, b) => a.price.amount - b.price.amount)[0]
            return best ? best.price : null
        }

        hotelPageLink(offer) {
            return hotelsRuntimeStore.hotelPageLink(offer, hotelsStore.searchRequest)
        }

        removeFromComparison(hotelCode) {
            hotelsStore.selectToCompare(hotelCode)
            if (this.offers.length === 1) {
                this.$router.go(-1)
            }
        }

        closeCompare() {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
        }

        hideCompare() {
            this.$refs.comporationOptins.$el.firstChild.style.minWidth = this.hideComparationOptions ? '' : '10%'
            this.hideComparationOptions = !this.hideComparationOptions
        }

        get compareCount() {
            return hotelsStore.offersToCompare.length
        }

        get offers() {
            return this.cachedOffers.filter(offer => {
                const index = hotelsStore.offersToCompare.findIndex(el => {
                    return el.hotelCode === offer.hotelCode
                })
                return index >= 0
            })
        }

        get filteredKeys() {
            if (this.showAll) {
                return this.serviceKeys
            }
            return this.serviceKeys.filter(key => {
                const filteredOffers = this.offers.filter(offer => {
                    const index = offer.services.indexOf(key)
                    return index >= 0
                })
                return filteredOffers.length !== this.offers.length
            })
        }

        get mealCodes() {
            const keys = new Set([])
            this.offers.forEach(offer => {
                offer.rooms.forEach(room => {
                    keys.add(room.mealTypeStandardCode)
                })
            })

            return Array.from(keys)
        }

        get width() {
            const width = this.offers.length * 250 + 305 + 24
            const scr = process.client ? screen.width : 1920
            return this.$breakpoint.smAndDown ? '100%' : width > scr ? `${width}px` : '100%'
        }

        get rowWidth() {
            return this.$breakpoint.smAndDown
                ? '100%'
                : this.width === '100%'
                ? '250px'
                : `${(parseInt(this.width.replace('px', '')) - 305) / this.offers.length}px`
        }

        get searchRequest() {
            return hotelsStore.searchRequest
        }

        get city() {
            return hotelsRuntimeStore.city
        }
    }
</script>

<style scoped lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    div.v-expansion-panel-content__wrap::v-deep {
        padding: 0 !important;
    }

    .max-width-300 {
        max-width: 300px;
    }

    .width-300 {
        min-width: 250px !important;
        max-width: 650px;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
        .width-300 {
            min-width: 100% !important;
            max-width: 100%;
        }
    }

    .overflow-x-scroll {
        overflow-x: scroll;
    }

    .odd-line {
        background-color: hsl(238, 100%, 98%);
    }

    .sticky {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 4;
    }

    .comparison-header {
        background-color: #1976d2;
        color: white;
        opacity: 0.5;
    }

    .centered {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .scroll-container {
        max-width: 100vw;
        position: fixed;
        bottom: 0;
        z-index: 3;
        overflow-x: auto;
        height: 30px;
    }

    .scroll-container div {
        height: 10px;
    }

    .fit {
        min-width: fit-content;
    }

    .field-name {
        position: sticky;
        left: 0;
        background: white;
        z-index: 2;
    }
</style>
