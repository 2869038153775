<template>
    <v-list-item v-if="originalCurrency !== currency">
        <v-list-item-content class="caption">
            <i>{{ $t('original_currency_notification', {currency}) }}</i>
            <i>{{
                $t('original_currency_amount_notification', {
                    currency: originalCurrency,
                    price: $options.filters.priceFormat(originalAmount, originalCurrency),
                })
            }}</i>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
    import {Component, Vue, Prop} from 'vue-property-decorator'
    import {persistentStore} from '@/utils/store-accessor'

    @Component
    export default class OriginalCurrencyNotification extends Vue {
        @Prop({required: true}) price

        get currency() {
            return persistentStore.currency
        }

        get originalCurrency() {
            return this.price.originalCurrency || this.price.currency
        }

        get originalAmount() {
            return this.price.originalAmount || this.price.amount
        }
    }
</script>
