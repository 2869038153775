<template>
    <v-card class="my-2" outlined>
        <n-link
            no-prefetch
            :to="{name: 'transfer', query: {name: product.info.name}}"
            class="title text-decoration-none"
        >
            <v-img
                tile
                class="white--text align-end"
                height="200px"
                :src="product.info.imageUrl | imageUrl(200)"
                :alt="product.info.name"
                lazy-src="/placeholder.png"
                @error="imgError = true"
            >
                <template v-slot:placeholder>
                    <v-row
                        v-show="!imgError && product.info.imageUrl"
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-row>
                </template>
                <v-card-title>
                    <n-link no-prefetch :to="{name: 'transfer', query: {name: product.info.name}}" class="subtitle-2">
                        {{ product.info.name }}
                    </n-link>
                </v-card-title>
            </v-img>
        </n-link>
        <v-card-text>
            <n-link
                no-prefetch
                :to="{name: 'transfer', query: {name: product.info.name}}"
                class="title text-decoration-none"
            >
                <strong class="black--text">{{ $t('from') }} {{ minPriceOffer.price | price }}</strong>
            </n-link>
            <discount-label :price="minPriceOffer.price" />
            <commission-amount :commission="minPriceOffer.price.commission" />
        </v-card-text>
        <v-expand-transition>
            <div v-if="expanded">
                <transfer-offer-card
                    v-for="(offer, offerIndex) in product.offers.slice(0, expanded)"
                    :key="offerIndex"
                    :offer="offer"
                    :full-card="false"
                />
            </div>
        </v-expand-transition>
        <v-card-actions>
            <v-list-item dense class="d-flex justify-center" @click.stop="open">
                <v-icon v-text="`mdi-chevron-${!expanded || expanded < product.offers.length ? 'down' : 'up'}`" />
            </v-list-item>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {Component} from 'vue-property-decorator'
    import TransferCard from '@/components/transfers/search/offers/TransferCard'
    import TransferOfferCard from '@/components/transfers/info/TransferOfferCard'
    import DiscountLabel from '~src/components/snippets/discountLabel.src'
    import CommissionAmount from '~/components/snippets/CommissionAmount'

    @Component({
        components: {
            DiscountLabel,
            CommissionAmount,
            TransferOfferCard,
        },
    })
    export default class TransferCardMobile extends TransferCard {
        open() {
            if (!this.expanded || this.expanded >= this.product.offers.length) {
                this.toggleOffers()
            } else {
                this.showAllOffers()
            }
        }

        showAllOffers() {
            this.expanded = this.product.offers.length
        }
    }
</script>

<style scoped lang="scss">
    .v-card__title {
        background: rgba(0, 0, 0, 0.5);

        a {
            color: #ffffff;
            text-decoration: none;
        }
    }
</style>
