<template>
    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" offset-y attach>
        <template v-slot:activator="{on}">
            <v-text-field
                :value="roomsLabel"
                :single-line="singleLine"
                :outlined="outlined"
                filled
                background-color="white"
                :prepend-inner-icon="showIcon ? 'mdi-account' : null"
                hide-details
                readonly
                data-cy="rooms-selector"
                :label="getLabel"
                :placeholder="
                    $tc('room', 2)
                        .charAt(0)
                        .toUpperCase() + $tc('room', 2).slice(1)
                "
                :class="{caption: $breakpoint.smAndDown}"
                v-on="on"
            />
        </template>
        <v-card class="rooms-drop-down" max-width="fit-content" max-height="425" color="white">
            <v-list dense>
                <v-list-item v-for="(tourists, index) in rooms" :key="index" :data-cy="'room-selector-' + index">
                    <v-list-item-content>
                        <v-list-item-title class="text-capitalize">
                            {{ $tc('room', 1) }} {{ index + 1 }}
                        </v-list-item-title>
                        <tourists-select
                            :adults-prop.sync="tourists.adults"
                            :children-prop.sync="tourists.childrenAges"
                        />
                    </v-list-item-content>
                    <v-list-item-action v-if="rooms.length > 1">
                        <v-btn icon @click="removeRoom(index)">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
                <v-divider />
                <v-card-actions>
                    <div class="flex-grow-1" />
                    <v-btn text :disabled="isDisabled" @click="addRoom()">
                        {{ $t('add_room') }}
                    </v-btn>
                    <v-btn color="primary" text @click="menu = false">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
    import {Vue, Component, Watch, Model, Prop} from 'vue-property-decorator'
    import TouristsSelect from '~/components/search/forms/TouristsSelect'
    import {authStore} from '~/store'

    @Component({
        components: {
            TouristsSelect,
        },
    })
    export default class RoomsSelect extends Vue {
        @Model('change') value
        @Prop({default: false}) outlined
        @Prop({default: true}) singleLine
        @Prop({default: true}) showIcon

        menu = false
        rooms = []

        @Watch('rooms')
        onChangeRooms(rooms) {
            this.$emit('change', rooms)
        }

        @Watch('value')
        onChangeValue(val) {
            this.rooms = val
        }

        beforeDestroy() {
            this.$refs.menu.activatorNode = null
        }

        changeRooms(roomsCount) {
            if (this.rooms.length < roomsCount) {
                for (let i = 0; i < roomsCount - this.rooms.length; i++) {
                    this.rooms.push({adults: this.isDisabled ? 1 : 2, childrenAges: []})
                }
            } else {
                this.rooms = this.rooms.slice(0, roomsCount)
            }
        }

        removeRoom(index) {
            this.rooms.splice(index, 1)
        }

        addRoom() {
            this.rooms.push({adults: this.isDisabled ? 1 : 2, childrenAges: []})
        }

        get roomsLabel() {
            if (!this.rooms.length) return ''
            const touristsTotal = this.rooms.reduce((total, tourists) => {
                return total + tourists.adults + tourists.childrenAges.length
            }, 0)
            return `${this.rooms.length} ${this.$tc('room', this.rooms.length)} ${touristsTotal} ${this.$tc(
                'guest',
                touristsTotal
            )}`
        }

        get isDisabled() {
            return authStore.user.isEmployee || this.rooms.length > 19
        }

        get getLabel() {
            return `${this.$tc('room', this.rooms.length)}`
        }
    }
</script>

<style scoped lang="scss">
    @media (max-width: 960px) {
        .rooms-drop-down {
            max-width: 100% !important;
        }
    }

    ::v-deep {
        .v-list-item__content {
            overflow: visible;
        }
    }
</style>
